import React from "react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import {
    Row,
    Col,
    FormInput,
    FormCheckbox,
} from "shards-react";

export default class UpdateAuthenticatorTokenForm extends React.Component {

    constructor() {
        super();
        this.state = {
            form: {
                name: '',
                tokenAdditionalInfo: '',
                tokenState: true,
            },
            formErrors: {
                name: [],
                tokenAdditionalInfo: [],
            },
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    componentDidMount() {
        API.get(Constants.AUTHENTICATOR_TOKEN_URL + "/" + this.props.updateToken).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: {
                        name: result.data.name,
                        tokenAdditionalInfo: result.data.tokenAdditionalInfo,
                        tokenState: result.data.tokenState
                    }
                });
            }
        });
    }

    inputChangeHandler = (event) => {
        var { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form: form });
    }

    checkboxChangeHandler = (event) => {
        var { form } = this.state;
        form.tokenState = !form.tokenState;
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, tokenAdditionalInfo } = this.state.form;

        formErrors["name"] = [];
        formErrors["tokenAdditionalInfo"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (tokenAdditionalInfo.length > 100) {
            formErrors["tokenAdditionalInfo"].push("Informacje dodatkowe powinna składać się maksymalnie z 100 znaków");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = () => {
        const { form } = this.state;
        if (this.validForm()) {
            API.put(Constants.AUTHENTICATOR_TOKEN_URL + '/' + this.props.updateToken, form).then((result) => {
                if (result.status === 200) {
                    this.props.toggle();
                }
            });
        }
    }

    render() {
        const { name, tokenAdditionalInfo, tokenState } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasTokenAdditionalInfoError = Boolean(this.state.formErrors.tokenAdditionalInfo.length);

        return (
            <Row>
                <Col sm="12">
                    <label>*Nazwa Tokenu</label>
                    <FormInput name="name" value={name || ""} onChange={this.inputChangeHandler} invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>Informacje dodatkowe</label>
                    <FormInput name="tokenAdditionalInfo" value={tokenAdditionalInfo || ""} onChange={this.inputChangeHandler} invalid={hasTokenAdditionalInfoError}
                        className={hasTokenAdditionalInfoError ? "mb-0" : "mb-2"} />
                    {hasTokenAdditionalInfoError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.tokenAdditionalInfo)}</ul>}

                    <FormCheckbox name="tokenState" toggle checked={tokenState} onChange={this.checkboxChangeHandler}> Aktywny </FormCheckbox>
                </Col>
            </Row>
        );
    }
}