import React from "react";
import { Col, Row, FormGroup, DatePicker } from "shards-react";
import Select from 'react-select';

import pl from 'date-fns/locale/pl';

class ControlPanelLogFilterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFilterFields: "",
            sortOrder: "",
            sortField: "",
            form: {},
        }

        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.filterFieldsSelectChangeHandler = this.filterFieldsSelectChangeHandler.bind(this);
        this.filterSelectChangeHandler = this.filterSelectChangeHandler.bind(this);
        this.getProvidedFieldValues = this.getProvidedFieldValues.bind(this);

        const currentDate = new Date();
        this.state = {
            ...this.state,
            form: {
                ...this.state.form,
                eventStartRangeDate: new Date().setHours(currentDate.getHours() - 24),
                eventEndRangeDate: currentDate.getTime()
            }
        }
    }

    componentDidMount() {
        this.submitFilterChanges();
    }

    filterFieldsSelectChangeHandler = (selected, event) => {
        var filters;
        switch (event.action) {
            case "clear":
                filters = {
                    selectedFilterFields: "",
                }
                break;
            case "select-option":
                filters = {
                    selectedFilterFields: selected
                };
                break;
            case "remove-value":
                filters = {
                    selectedFilterFields: this.deleteValueFromSelectedFilterFields(event),
                    form: {
                        ...this.state.form,
                        [event.removedValue.value]: null
                    }
                };
                break;
            default:
                filters = {
                    selectedFilterFields: "",
                };
        }

        this.setState({
            ...filters
        }, () => this.submitFilterChanges())
    }

    deleteValueFromSelectedFilterFields(event) {
        return this.state.selectedFilterFields
            .filter(filterField => event.removedValue.value !== filterField.value)
    }

    filterSelectChangeHandler = (selected, event) => {
        const { form } = this.state;
        this.setState({
            form: {
                ...form,
                [event.name]: selected
            }
        }, () => {
            this.submitFilterChanges();
        });
    }

    setStartDate = date => {
        this.setState({
            form: {
                ...this.state.form,
                eventStartRangeDate: date.getTime()
            }
        }, () => {
            this.submitFilterChanges();
        });
    }

    setEndDate = date => {
        this.setState({
            form: {
                ...this.state.form,
                eventEndRangeDate: date
            }
        }, () => {
            this.submitFilterChanges();
        });
    }

    submitFilterChanges() {
        const { onChangeFilter } = this.props;
        onChangeFilter({
            eventStartRangeDate: this.state.form.eventStartRangeDate ? this.state.form.eventStartRangeDate : null,
            eventEndRangeDate: this.state.form.eventEndRangeDate ? this.state.form.eventEndRangeDate : null,
            description: this.state.form.description ? this.state.form.description.value : null,
            partition: this.state.form.partition ? this.state.form.partition.value : null,
            zone: this.state.form.zone ? this.state.form.zone.value : null,
            expander: this.state.form.expander ? this.state.form.expander.value : null,
            telephone: this.state.form.telephone ? this.state.form.telephone.value : null,
            user: this.state.form.user ? this.state.form.user.value : null,
        }, true)
    }

    getProvidedFieldValues(fieldName) {
        const { filterOptions } = this.props;
        return filterOptions[fieldName];
    }

    render() {
        const { selectedFilterFields, form } = this.state
        const { options, setMobileCaledar } = this.props;

        const defaultSelectProps = {
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        const renderFilterValueSelects = () => {
            return selectedFilterFields && selectedFilterFields.map((field, id) => {
                return (
                    <FormGroup key={id}>
                        <Select
                            {...defaultSelectProps}
                            name={field.value}
                            value={form[field.value]}
                            onChange={this.filterSelectChangeHandler}
                            placeholder={field.label}
                            options={this.getProvidedFieldValues(field.value)} />
                    </FormGroup>
                )
            })
        }

        return (
            <Row>
                <Col md="6" lg="6">
                    <div className="mr-2 ml-2">
                        <FormGroup>
                            <label className="filter-label" htmlFor="eventStartRangeDate">Okres wystąpień:</label>
                            <div className="input-daterange input-group">
                                <DatePicker
                                    name="eventStartRangeDate"
                                    locale={pl}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    placeholderText="Data rozpoczęcia"
                                    selected={form.eventStartRangeDate}
                                    onChange={date => this.setStartDate(date)}
                                    selectsStart
                                    startDate={form.eventStartRangeDate}
                                    endDate={form.eventEndRangeDate}
                                    withPortal={setMobileCaledar}
                                    showTimeSelect />

                                <DatePicker
                                    name="eventEndRangeDate"
                                    locale={pl}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    placeholderText="Data zakończenia"
                                    selected={form.eventEndRangeDate}
                                    onChange={date => this.setEndDate(date)}
                                    selectsEnd
                                    startDate={form.eventStartRangeDate}
                                    endDate={form.eventEndRangeDate}
                                    minDate={form.eventStartRangeDate}
                                    withPortal={setMobileCaledar}
                                    showTimeSelect />
                            </div>
                        </FormGroup>
                    </div>
                </Col>
                <Col md="6" lg="6">
                    <div className="mr-2 ml-2">
                        <label className="filter-label" htmlFor="startDate">Filtruj</label>
                        <FormGroup>
                            <Select
                                {...defaultSelectProps}
                                isMulti
                                name="filterByField"
                                value={selectedFilterFields ? selectedFilterFields : ""}
                                onChange={this.filterFieldsSelectChangeHandler}
                                placeholder="Filtruj po"
                                options={options.filterByFields}
                                closeMenuOnSelect={false} />
                        </FormGroup>

                        {renderFilterValueSelects()}
                    </div>
                </Col>
            </Row>
        );
    }

}

export default ControlPanelLogFilterForm;

ControlPanelLogFilterForm.defaultProps = {
    options: {
        filterByFields: [
            { label: "Opis", value: "description" },
            { label: "Partycja", value: "partition" },
            { label: "Strefa", value: "zone" },
            { label: "Czujnik", value: "expander" },
            { label: "Telefon", value: "telephone" },
            { label: "Użytkownik", value: "user" },
        ],
    }
}
