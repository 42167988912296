import React from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
} from "shards-react";

import ConfirmDeviceRuleModal from "./modals/ConfirmDeviceRuleModal";
import ConfirmControlPanelRuleModal from "./modals/ConfirmControlPanelRuleModal";
import ConfirmRuleModal from "./modals/ConfirmRuleModal";
import RuleList from "./lists/RuleList";
import RuleFilterForm from "./forms/RuleFilterForm";
import DeleteDeviceRuleModal from "./modals/DeleteDeviceRuleModal";
import * as AppConstants from '../../constants';
import { getActionParameters } from "../../utils/Actions";
import { Store } from "../../flux";
import withLoader from "../common/hoc/withLoader";

class DeviceRules extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isCreateDeviceRuleShow: false,
            isCreateControlPanelRuleShow: false,
            isDeleteRuleModalShow: false,
            isConfirmRuleModalShow: false,
            requiredFilterOptions: {},
            deviceRules: [],
            selectedRuleUUID: '',
            type: '',
            filters: {

            },
            sortOptions: {
                sortOrder: "",
                sortField: "",
            }
        }

        this.toggleCreateRuleModal = this.toggleCreateRuleModal.bind(this);
        this.toggleDeleteRuleModal = this.toggleDeleteRuleModal.bind(this);
        this.toggleConfirmRuleModal = this.toggleConfirmRuleModal.bind(this);
        this.updateDeviceRules = this.updateDeviceRules.bind(this);
        this.setSortOptions = this.setSortOptions.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleCreateRuleModal(uuid) {
        const { detailsType } = this.props;

        if (this._isMounted)
            // eslint-disable-next-line default-case
            switch (detailsType) {
                case "pcDevice":
                case "networkDevice": {
                    this.setState({
                        isCreateDeviceRuleShow: !this.state.isCreateDeviceRuleShow,
                        selectedRuleUUID: uuid
                    })

                    break;
                }

                case "controlPanel": {
                    this.setState({
                        isCreateControlPanelRuleShow: !this.state.isCreateControlPanelRuleShow,
                        selectedRuleUUID: uuid
                    })

                    break;
                }
            }

    }

    toggleConfirmRuleModal(uuid, action) {
        if (this._isMounted)
            this.setState({
                isConfirmRuleModalShow: !this.state.isConfirmRuleModalShow,
                selectedRuleUUID: uuid,
                type: action,
            });
    }

    toggleDeleteRuleModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteRuleModalShow: !this.state.isDeleteRuleModalShow,
                selectedRuleUUID: uuid
            });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.rules !== state.deviceRules)
            return {
                deviceRules: props.rules,
                requiredFilterOptions: props.options
            };

        return null;
    }

    updateDeviceRules(newFilters = {}, save = false) {
        if (save)
            this.setState({
                filters: newFilters
            }, () => this.props.fetchDeviceRules(this.state.filters))
        else
            this.props.fetchDeviceRules(this.state.filters)
    }

    getFilterOptions() {
        const { requiredFilterOptions, isAdminAtLeast } = this.state;

        var deviceGroups = requiredFilterOptions.deviceGroups;
        var devices = requiredFilterOptions.devices;
        var categories = requiredFilterOptions.categories;
        var statuses = requiredFilterOptions.statuses && requiredFilterOptions.statuses.map(item => {
            return {
                label: getActionParameters(item.status, item.action).content,
                value: item
            }
        })

        var hints = {
            deviceGroup: deviceGroups ? deviceGroups.map(deviceGroup => { return { label: deviceGroup, value: deviceGroup } }) : null,
            device: devices ? devices.map(device => { return { label: device, value: device } }) : null,
            category: categories ? categories.map(category => { return { label: category, value: category } }) : null,
            status: statuses,
        }

        if (isAdminAtLeast) {
            var managers = requiredFilterOptions.manager;
            hints = {
                ...hints,
                manager: managers
            }
        }

        return hints;
    }

    setSortOptions(sortOptions) {
        if (this._isMounted)
            this.setState({
                sortOptions: sortOptions,
            })
    }

    render() {
        const { isCreateDeviceRuleShow, isCreateControlPanelRuleShow, isDeleteRuleModalShow, isConfirmRuleModalShow, deviceRules, selectedRuleUUID, sortOptions, filters, type } = this.state;
        const { detailsType, appliedFilter, deviceType } = this.props;

        const showRuleList = () => (
            <RuleList className="clearfix"
                rules={deviceRules}
                sortOptions={sortOptions}
                toggleCreateRuleModal={this.toggleCreateRuleModal}
                toggleDeleteRuleModal={this.toggleDeleteRuleModal}
                toggleConfirmRuleModal={this.toggleConfirmRuleModal}
                onAction={this.updateDeviceRules}
                detailsType={detailsType} />
        )

        const showNoRulesFoundMessage = () => (
            <Row className="pt-2" style={{ justifyContent: "center" }}>
                <h6>Brak zdefiniowanych reguł</h6>
            </Row>
        )

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Reguły</h6>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={this.toggleCreateRuleModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową regułę
                            </Button>

                            <RuleFilterForm filterOptions={this.getFilterOptions()} onChangeFilter={this.updateDeviceRules}
                                setSortOptions={this.setSortOptions} appliedFilter={appliedFilter} detailsType={detailsType} />
                            {deviceRules.length ? showRuleList() : showNoRulesFoundMessage()}
                        </CardBody>
                    </Card>
                </Col>

                <ConfirmRuleModal open={isConfirmRuleModalShow} uuid={selectedRuleUUID} type={type}
                    toggle={this.toggleConfirmRuleModal} onSuccess={() => this.updateDeviceRules(filters)} />

                <ConfirmDeviceRuleModal open={isCreateDeviceRuleShow} onSuccess={() => this.updateDeviceRules(filters)}
                    uuid={selectedRuleUUID} toggle={this.toggleCreateRuleModal} detailsType={detailsType} deviceType={deviceType} />

                <ConfirmControlPanelRuleModal open={isCreateControlPanelRuleShow} onSuccess={() => this.updateDeviceRules(filters)}
                    uuid={selectedRuleUUID} toggle={this.toggleCreateRuleModal} />

                <DeleteDeviceRuleModal open={isDeleteRuleModalShow} onSuccess={() => this.updateDeviceRules(filters)}
                    uuid={selectedRuleUUID} toggle={this.toggleDeleteRuleModal} detailsType={detailsType} />
            </Row>
        );
    }
}

export default withLoader(DeviceRules);