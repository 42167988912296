import * as Constants from "../constants";

export const translateDeviceType = (value) => {
    switch (value) {
        case Constants.PC_DEVICE_TYPES.PC:
            return "Bezpieczeństwo sprzętowe";
        case Constants.NETWORK_DEVICE_TYPES.SWITCH:
            return "Switche";
        case Constants.NETWORK_DEVICE_TYPES.UTM:
            return "UTM";
        case Constants.CONTROL_PANEL_TYPES.ACCESSCONTROL:
            return "Centrala kontroli dostępu";
        case Constants.CONTROL_PANEL_TYPES.FIRE:
            return "Bezpieczeństwo fizyczne pożarowe";
        case Constants.CONTROL_PANEL_TYPES.ALARM:
            return "Bezpieczeństwo fizyczne alarmowe";
        default:
            return value;
    }
}

export const translateEventType = (value) => {
    switch (value) {
        case "COPY":
            return "Kopiowanie";
        default:
            return value;
    }
}

export const translateEventSearchValueIn = (value) => {
    switch (value) {
        case "DEVICE_NAME":
            return "Nazwa urządzenia";
        case "CONTROL_PANEL_NAME":
            return "Nazwa centrali"
        case "GROUP_NAME":
            return "Nazwa grupy";
        case "DEVICE_IP":
            return "Adres IP";
        default:
            return value;
    }
}
