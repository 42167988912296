import React, { Component } from "react";
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faVideo } from '@fortawesome/free-solid-svg-icons'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Alert
} from "shards-react";

import LinkButton from "../../buttons/LinkButton";

import { format as dateFormat } from 'date-fns'
import withLoader from "../../common/hoc/withLoader";
import ConfirmDeviceApplicationModal from './../../pc-device/safetica/modals/ConfirmDeviceApplicationModal';
import CameraSelectorModal from "../../cameras/modals/CameraSelectorModal";
import * as AppConstants from "../../../constants";

class DeviceData extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cameraLocation: '',
      isCameraSelectorModalShow: false,
      isCreateDeviceModalShow: false,
      isLoading: false,
      deviceStatus: '',
      deviceAction: '',
    }

    this.toggleCreateDeviceModal = this.toggleCreateDeviceModal.bind(this);
    this.toggleCameraSelectorModal = this.toggleCameraSelectorModal.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const hasDataProps = props && props.data;

    var isLoading = state.isLoading;
    if (props.isLoading !== state.isLoading) {
      isLoading = props.isLoading;
      props.setLoading(isLoading);
    }

    var propsReferralData = hasDataProps ? props.data.referralData : undefined;
    var referralData = state.referralData
    if (JSON.stringify(propsReferralData) !== state.referralData) {
      referralData = propsReferralData ? [...propsReferralData] : [];
      referralData.filter(referralDataItem => referralDataItem.valueType === "date").forEach(referralDataItem =>
        referralDataItem.value = referralDataItem.value ? new Date(referralDataItem.value).getTime() : undefined
      )
    }

    var propsCameraLocation = hasDataProps ? props.data.location : undefined;
    var cameraLocation = state.cameraLocation;
    if (propsCameraLocation !== cameraLocation) {
      cameraLocation = propsCameraLocation ? propsCameraLocation : '';
    }

    return {
      isLoading: isLoading,
      referralData: referralData,
      cameraLocation: cameraLocation,
      deviceStatus: hasDataProps ? props.data.status : "",
      deviceAction: hasDataProps ? props.data.action : ""
    }
  }

  toggleCreateDeviceModal() {
    this.setState({
      isCreateDeviceModalShow: !this.state.isCreateDeviceModalShow,
    });
  }

  toggleCameraSelectorModal() {
    this.setState({
      isCameraSelectorModalShow: !this.state.isCameraSelectorModalShow,
    });
  }

  renderDeviceInfoSpan(value, valueType = "") {
    var valueToPrint = value ? value : "-";

    return (
      <span className="ml-auto text-right text-semibold text-reagent-gray">
        {valueType !== "date" ? valueToPrint : value ? dateFormat(new Date(valueToPrint), "dd-MM-yyyy") : valueToPrint}
      </span>
    )
  }

  getTooltipContent() {
    const cpPrivilege = JSON.parse(localStorage.getItem('user')).privileges.includes("PHYSICAL_SECURITY");
    if(cpPrivilege) {
      if(Boolean(this.state.cameraLocation)) {
        return "Dostęp do kamery";
      } else {
        return "W celu dostępu do najbliższej kamery należy dodać lokalizację sprzętu. Pamiętaj! Kamera musi być przypisana do tej samej lokalizacji!";
      }
    }

    return "W celu dostępu do najbliższej kamery należy wykupić dostęp do modułu Bezpieczeństwo fizyczne oraz dodać kamery do systemu";
  }

  render() {
    const {
      isMobileView,
    } = this.props;
    const { referralData, isLoading, isCreateDeviceModalShow, isCameraSelectorModalShow, cameraLocation, deviceStatus, deviceAction } = this.state;

    const isPending = deviceStatus === AppConstants.STATUSES.PENDING;

    const renderDeviceDetails = () => (
      <ListGroup small flush className="list-group-small">
        {this.state.editingMode ? <Alert theme="warning" style={{ marginBottom: "0rem" }}>Uwaga - Jeżeli zostaną edytowane parametry mające wpływ
          na funkcjonowanie systemu, urządzenie przejdzie w stan "w edycji" i należące do niego reguły zostaną dezaktywowane
          do momentu zaakceptowania zmian.</Alert> : ""}
        {referralData && referralData.map((item, idx) => (
          <ListGroupItem key={idx} className="d-flex px-3 mb-1" style={{ flexWrap: "wrap" }}>
            <span className="text-semibold text-fiord-blue">{item.label}</span>
            {this.renderDeviceInfoSpan(item.value, item.valueType)}
          </ListGroupItem>
        ))}
      </ListGroup>
    )

    const renderDeviceDetailsFetchError = () => (
      <ListGroup small flush className="list-group-small text-center">
        <ListGroupItem className="d-flex px-3 m-auto without-border">
          <span className="text-semibold text-fiord-blue">Nie udało się pobrać danych o urządzeniu</span>
        </ListGroupItem>
      </ListGroup>
    )

    return (
      <Row className="mt-2 mb-2 position-relative">
        {this.props.children}
        <Col>
          <Card className="mb-3">
            <CardHeader className="border-bottom px-3">
              <div className="d-inline-flex align-items-center">
                <LinkButton id="nearest-video-link" onClick={this.toggleCameraSelectorModal} disabled={!Boolean(cameraLocation)}>
                  <Tooltip title={this.getTooltipContent()} placement="top" arrow>
                    <span><FontAwesomeIcon className="mr-2" icon={faVideo} /></span>
                  </Tooltip>
                </LinkButton>
                <h6 className="m-0">Dane urządzenia</h6>
              </div>

              {referralData && !isLoading && !isPending && <div className="float-right">
                <LinkButton onClick={this.toggleCreateDeviceModal}>
                  <FontAwesomeIcon icon={faEdit} /> Edytuj
                </LinkButton>
              </div>}
            </CardHeader>

            <CardBody className="p-0">
              {referralData ? renderDeviceDetails() : renderDeviceDetailsFetchError()}
            </CardBody>
          </Card>
        </Col>

        <ConfirmDeviceApplicationModal open={isCreateDeviceModalShow} uuid={this.props.uuid} deviceStatus={deviceStatus} deviceAction={deviceAction}
          toggle={this.toggleCreateDeviceModal} onSuccess={this.props.onUpdate} isMobileView={isMobileView} />

        {Boolean(cameraLocation) &&
          <CameraSelectorModal open={isCameraSelectorModalShow}
            toggle={this.toggleCameraSelectorModal} cameraLocation={cameraLocation} />
        }
      </Row>
    )
  }

}

export default withLoader(DeviceData);
