import React, { createRef } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
} from "shards-react";

import DashboardModal from '../dashboard/modals/DashboardModal';
import ConfirmControlPanelRuleModal from "./modals/ConfirmControlPanelRuleModal";
import ControlPanelRuleList from "./lists/ControlPanelRuleList";
import ControlPanelRuleFilterForm from "./forms/ControlPanelRuleFilterForm";
import DeleteControlPanelRuleModal from "./modals/DeleteControlPanelRuleModal";
import API from "../../api/AxiosConfiguration";
import * as AppConstants from '../../constants';
import { getActionParameters } from "../../utils/Actions";
import withLoader from "../common/hoc/withLoader";

class ControlPanelRules extends React.Component {

    _isMounted = false;

    constructor() {
        super();

        this.state = {
            isCreateControlPanelRuleShow: false,
            isDeleteRuleModalShow: false,
            isAddToDashboardModalVisible: false,
            controlPanelRules: [],
            requiredFilterOptions: {},
            selectedRuleUUID: '',
            filters: {

            },
            sortOptions: {
                sortOrder: "",
                sortField: "",
            }
        }

        this.ruleListRef = createRef();

        this.toggleCreateRuleModal = this.toggleCreateRuleModal.bind(this);
        this.toggleDeleteRuleModal = this.toggleDeleteRuleModal.bind(this);
        this.toggleAddToDashboardModal = this.toggleAddToDashboardModal.bind(this);
        this.updateControlPanelRules = this.updateControlPanelRules.bind(this);
        this.setSortOptions = this.setSortOptions.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleCreateRuleModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateControlPanelRuleShow: !this.state.isCreateControlPanelRuleShow,
                selectedRuleUUID: uuid
            })
    }

    toggleDeleteRuleModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteRuleModalShow: !this.state.isDeleteRuleModalShow,
                selectedRuleUUID: uuid
            });
    }

    toggleAddToDashboardModal() {
        if(this._isMounted)
            this.setState({
                ...this.state,
                isAddToDashboardModalVisible: !this.state.isAddToDashboardModalVisible
            });
    }

    fetchControlPanelRules(form) {
        return API.post(AppConstants.CONTROL_PANEL_RULE_URL, {
            form: form,
        }).then((result) => {
            var menuRuleInfo = result.data;
            if (this._isMounted)
                this.setState({
                    controlPanelRules: menuRuleInfo.rules,
                    requiredFilterOptions: menuRuleInfo.options
                });
            this.props.setLoading(false);
        })
    }

    updateControlPanelRules(newFilters = {}, save = false) {
        this.props.setLoading(true);
        if (save)
            this.setState({
                filters: newFilters
            }, () => this.fetchControlPanelRules(this.state.filters))
        else
            this.fetchControlPanelRules(this.state.filters)
    }

    getFilterOptions() {
        const { requiredFilterOptions } = this.state;

        var controlPanel = requiredFilterOptions.controlPanels;
        var sensorGroup = requiredFilterOptions.sensorGroups;
        var sensor = requiredFilterOptions.sensors;
        var categories = requiredFilterOptions.categories;
        var statuses = requiredFilterOptions.statuses && requiredFilterOptions.statuses.map(item => {
            return {
                label: getActionParameters(item.status, item.action).content,
                value: item
            }
        });
        var managers = requiredFilterOptions.manager;

        var hints = {
            controlPanel: controlPanel ? controlPanel.map(cp => { return { label: cp, value: cp } }) : null,
            sensorGroup: sensorGroup ? sensorGroup.map(sg => { return { label: sg, value: sg } }) : null,
            sensor: sensor ? sensor.map(s => { return { label: s, value: s } }) : null,
            category: categories ? categories.map(category => { return { label: category, value: category } }) : null,
            status: statuses,
            manager: managers
        }

        return hints;
    }

    setSortOptions(sortOptions) {
        this.setState({
            sortOptions: sortOptions,
        })
    }

    render() {
        const { isCreateControlPanelRuleShow, isAddToDashboardModalVisible, isDeleteRuleModalShow, controlPanelRules, selectedRuleUUID, sortOptions, filters } = this.state;
        const { isMobileView } = this.props;

        const showRuleList = () => (
            <ControlPanelRuleList ref={this.ruleListRef} className="clearfix"
                rules={controlPanelRules}
                sortOptions={sortOptions}
                toggleConfirmRuleModal={this.toggleCreateRuleModal}
                toggleDeleteRuleModal={this.toggleDeleteRuleModal}
                onAction={this.updateControlPanelRules} />
        )

        const showNoRulesFoundMessage = () => (
            <Row className="pt-2" style={{ justifyContent: "center" }}>
                <h6>Brak zdefiniowanych reguł</h6>
            </Row>
        )

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Reguły dla grup central / central</h6>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={this.toggleCreateRuleModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową regułę
                            </Button>
                            <Button onClick={this.toggleAddToDashboardModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj do dashboardu
                            </Button>

                            <ControlPanelRuleFilterForm ref={this.filterFormRef} filterOptions={this.getFilterOptions()} onChangeFilter={this.updateControlPanelRules}
                                setSortOptions={this.setSortOptions} />
                            {controlPanelRules.length ? showRuleList() : showNoRulesFoundMessage()}
                        </CardBody>
                    </Card>
                </Col>

                <ConfirmControlPanelRuleModal open={isCreateControlPanelRuleShow} onSuccess={() => this.updateControlPanelRules(filters)}
                    uuid={selectedRuleUUID} toggle={this.toggleCreateRuleModal} isMobileView={isMobileView} />

                <DeleteControlPanelRuleModal open={isDeleteRuleModalShow} onSuccess={() => this.updateControlPanelRules(filters)}
                    uuid={selectedRuleUUID} toggle={this.toggleDeleteRuleModal} />

                <DashboardModal 
                    open={isAddToDashboardModalVisible}
                    toggle={this.toggleAddToDashboardModal}
                    formType='addToDashboard'
                    module="controlPanels"
                    rules={controlPanelRules}
                    history={this.props.history} />
            </Row>
        );
    }
}

export default withLoader(ControlPanelRules);