import React from "react";
import { Tooltip, Row, Col, FormInput, FormCheckbox } from "shards-react";
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';

import * as AppConstants from '../../../../constants';
import API from "../../../../api/AxiosConfiguration";
import { flatten } from "../../../../utils/Arrays";
import * as socToast from '../../../../utils/SocToast';
import i18n from './../../../../i18n';
import { Store } from '../../../../flux'
import { getCustomersForSelect } from '../../../../api/ApiService';
import { hasValueUrlOrHtmlTag } from "../../../../utils/Patterns";

const components = {
    DropdownIndicator: null
};

const createOption = (label) => ({
    label,
    value: label
});

const defaultSelectProps = {
    placeholder: "Wybierz",
    className: 'react-select-container mb-2',
    classNamePrefix: "react-select",
    menuPosition: "absolute",
    menuPlacement: "auto",
    noOptionsMessage: () => "Brak dostępnych opcji",
}

class ConfirmPcRuleForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            form: {
                name: "",
                deviceGroup: "",
                device: "",
                category: "",
                procedure: "",
                dlpType: "",
                dlpTrackingType: "",
                dlpSource: "",
                dlpActions: "",
                dlpShadowing: false,
                dlpUserName: "",
                dlpActionVariant: "",
                dlpKeywords: [],
                dlpRegexCounter: "",
                dlpConfidentialPath: "",
                dlpFileType: "",
                dlpWhiteList: [],
                manager: ''
            },
            formErrors: {
                name: [],
                deviceGroup: [],
                category: [],
                dlpType: [],
                dlpTrackingType: [],
                dlpSource: [],
                dlpActions: [],
                manager: []
            },
            formHints: {
                deviceGroup: [],
                device: [],
                category: [],
                procedureGroup: [],
                procedure: [],
                dlpType: [],
                dlpTrackingType: [],
                dlpSource: [],
                dlpActions: [],
                dlpActionVariant: [],
                dlpFileType: [],
                manager: []
            },
            tooltip: {
                categoryTooltip: false,
                dlpRegexCounterTooltip: false,
                dlpSourceTooltip: false
            },
            isManagersLoading: true,
            isOptionsLoading: false,
            keywordsInputValue: "",
            whiteListInputValue: "",
            dictionary: [],
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.keywordsChangeHandler = this.keywordsChangeHandler.bind(this);
        this.keywordsChangeInputHandle = this.keywordsChangeInputHandle.bind(this);
        this.keywordsHandleKeyDown = this.keywordsHandleKeyDown.bind(this);
        this.whiteListChangeHandler = this.whiteListChangeHandler.bind(this);
        this.whiteListChangeInputHandle = this.whiteListChangeInputHandle.bind(this);
        this.whiteListHandleKeyDown = this.whiteListHandleKeyDown.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        const { isAdminAtLeast } = this.state;

        if (uuid.length) {
            this.fetchDeviceRuleForm(uuid);
            this.fetchAvailableOptions(uuid);
        } else {
            if (isAdminAtLeast) {
                this.fetchAvailableOptions();
                this.fetchCompanyCustomers();
            } else {
                this.fetchAvailableOptions();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        var prevManager = prevState.form.manager ? prevState.form.manager.value : "";
        var currentManager = this.state.form.manager ? this.state.form.manager.value : "";

        if (prevManager !== currentManager) {
            this.clearFormHints();
            this.fetchAvailableOptions();
        }
    }

    clearFormHints() {
        this.setState({
            formHints: {
                ...this.state.formHints,
                deviceGroup: [],
                device: [],
                category: [],
                procedureGroup: [],
                procedure: [],
                dlpType: [],
                dlpTrackingType: [],
                dlpSource: [],
                dlpActions: [],
                dlpActionVariant: [],
                dlpFileType: [],
            }
        })
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    checkboxChangeHandler = (event) => {
        var { form } = this.state;
        form.dlpShadowing = !form.dlpShadowing;
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event) => {
        var { form, formErrors } = this.state;

        if (Array.isArray(selected)) {
            form[event.name] = selected.map(selectedItem => selectedItem.value);
        } else {
            if (event.name !== "manager") {
                form[event.name] = selected ? selected.value : '';
            } else {
                this.setState({
                    form: {
                        ...form,
                        [event.name]: selected
                    }
                });
                return;
            }

            if (event.name === "targetGroup") form["targetDevice"] = "";
        }

        if (event.name === "dlpType") {
            form["dlpSource"] = "";
            form["dlpActions"] = "";
            form["dlpShadowing"] = false;
            form["dlpActionVariant"] = "";
            form["dlpRegexCounter"] = "";
            form["dlpConfidentialPath"] = "";
            form["dlpKeywords"] = [];
            form["dlpWhiteList"] = [];
            form["dlpFileType"] = "";
            form["dlpUserName"] = "";
        } else if (event.name === "dlpSource") {
            form["dlpActions"] = "";
            form["dlpShadowing"] = false;
            form["dlpActionVariant"] = "";
            form["dlpRegexCounter"] = "";
            form["dlpConfidentialPath"] = "";
            form["dlpKeywords"] = [];
            form["dlpWhiteList"] = [];
            form["dlpFileType"] = "";
            form["dlpUserName"] = "";
        } else if (event.name === "dlpActionVariant") {
            form["dlpRegexCounter"] = "";
            form["dlpConfidentialPath"] = "";
            form["dlpKeywords"] = [];
            form["dlpWhiteList"] = [];
            form["dlpFileType"] = "";
            form["dlpUserName"] = "";
        }

        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    keywordsChangeHandler = (dlpKeywords, actionMeta) => {
        var { form } = this.state;
        form["dlpKeywords"] = dlpKeywords;
        this.setState({ form: form });
    }

    keywordsChangeInputHandle = (keywordsInputValue) => {
        this.setState({ keywordsInputValue });
    }

    keywordsHandleKeyDown = (event) => {
        const { keywordsInputValue, form } = this.state;
        if (!keywordsInputValue) return;
        switch (event.key) {
            case "Enter":
            case "Tab":
                form["dlpKeywords"] = [...form["dlpKeywords"], createOption(keywordsInputValue)];
                this.setState({
                    keywordsInputValue: "",
                    form: form,
                });
                event.preventDefault();
            // no default
        }
    }

    whiteListChangeHandler = (dlpWhiteList, actionMeta) => {
        var { form } = this.state;
        form["dlpWhiteList"] = dlpWhiteList;
        this.setState({ form: form });
    }

    whiteListChangeInputHandle = (whiteListInputValue) => {
        this.setState({ whiteListInputValue });
    }

    whiteListHandleKeyDown = (event) => {
        const { whiteListInputValue, form } = this.state;
        if (!whiteListInputValue) return;
        switch (event.key) {
            case "Enter":
            case "Tab":
                form["dlpWhiteList"] = [...form["dlpWhiteList"], createOption(whiteListInputValue)];
                this.setState({
                    whiteListInputValue: "",
                    form: form,
                });
                event.preventDefault();
            // no default
        }
    }

    validForm() {
        const { formErrors, isAdminAtLeast, isAdmin } = this.state;
        const { name, deviceGroup, category, dlpType, dlpActions, dlpTrackingType, dlpSource, manager } = this.state.form;
        const { uuid } = this.props;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        formErrors["name"] = [];
        formErrors["deviceGroup"] = [];
        formErrors["dlpType"] = [];
        formErrors["dlpTrackingType"] = [];
        formErrors["dlpSource"] = [];
        formErrors["dlpActions"] = [];
        formErrors["category"] = [];
        formErrors["manager"] = [];

        var errorCount = 0;
        
        if (hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (deviceGroup.length <= 0) {
            formErrors["deviceGroup"].push("Grupa musi zostać wybrana");
            errorCount++;
        }

        if (dlpType.length <= 0) {
            formErrors["dlpType"].push("Urządzenie lub protokuł musi zostać wybrany");
            errorCount++;
        }

        if (dlpSource.length <= 0) {
            formErrors["dlpSource"].push("Urządzenie lub protokuł musi zostać wybrany");
            errorCount++;
        }

        if (dlpActions.length <= 0) {
            formErrors["dlpActions"].push("Musi zostać wybrana conajmniej jedna opcja");
            errorCount++;
        }

        if (dlpTrackingType.length <= 0) {
            formErrors["dlpTrackingType"].push("Shadowing musi zostać wybrany");
            errorCount++;
        }

        if (category.length <= 0) {
            formErrors["category"].push("Model powiadomienia musi zostać wybrany");
            errorCount++;
        }

        if (!isAdmin && !isEditingModeEnabled && isAdminAtLeast && (!manager || manager.length <= 0)) {
            formErrors["manager"].push("Użytkownik musi zostać wybrany");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildSubmitRuleForm() {
        const { form } = this.state;
        return {
            ...this.buildEditRuleForm(),
            manager: form.manager && form.manager.value
        }
    }

    buildEditRuleForm() {
        const { form } = this.state;
        return {
            name: form.name,
            category: form.category,
            deviceGroup: form.deviceGroup,
            device: form.device,
            procedure: form.procedure,
            dlpType: form.dlpType,
            dlpSource: form.dlpSource,
            dlpActions: form.dlpActions,
            dlpShadowing: form.dlpShadowing,
            dlpActionVariant: form.dlpActionVariant,
            dlpKeywords: form.dlpKeywords,
            dlpRegexCounter: form.dlpRegexCounter,
            dlpConfidentialPath: form.dlpConfidentialPath,
            dlpFileType: form.dlpFileType,
            dlpWhiteList: form.dlpWhiteList,
            dlpUserName: form.dlpUserName,
            dlpTrackingType: form.dlpTrackingType,
        }
    }

    submitForm = (onSuccess) => {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.PC_DEVICE_DEVICELOCK_RULE_URL + "/" + uuid, {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: this.buildEditRuleForm()
                })
            } else {
                apiPromise = API.put(AppConstants.PC_DEVICE_DEVICELOCK_RULE_URL, {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: this.buildSubmitRuleForm()
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle()
                    onSuccess();
                    socToast.success("Reguła została dodana", "Reguła została poprawnie dodana i oczekuje na zatwierdzenie.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            });
        }
    }

    showTooltip(name) {
        var { tooltip } = this.state;
        tooltip[name] = !tooltip[name];
        this.setState({
            tooltip: tooltip
        });
    }

    fetchCompanyCustomers = () => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            })
        })
    }

    fetchAvailableOptions = (uuid = "") => {
        const { isAdminAtLeast } = this.state;
        const { manager } = this.state.form;

        var axiosConfig;
        if (isAdminAtLeast) {
            if (Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
                if (!((manager && manager.value) || uuid)) return;
            }

            axiosConfig = {
                params: {
                    uuid: uuid,
                    manager: manager ? manager.value : ""
                }
            }
        }

        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.PC_DEVICE_DEVICELOCK_RULE_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                        procedure: flatten(result.data.procedureGroup.map(procedureGroup => procedureGroup.value)).map(procedure => {
                            return {
                                label: procedure.name,
                                value: procedure.id
                            }
                        }),
                    },
                    isOptionsLoading: false,
                })
            }
        });
    }

    fetchDeviceRuleForm = (uuid) => {
        API.get(AppConstants.PC_DEVICE_DEVICELOCK_RULE_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: {
                        ...this.state.form,
                        ...result.data,
                    }
                })
            }
        });
    }

    renderError = (errors) => {
        return errors.map((error, index) =>
            <li key={index}>{error}</li>
        )
    }

    findSelectLabelByValue = (name, value) => {
        var hint;
        var labels = [];

        if (Array.isArray(value)) {
            value.forEach(valueArrayItem => {
                hint = this.state.formHints[name].find(item => valueArrayItem === item.value)
                if (hint)
                    labels.push(hint.label);
            })
        } else {
            hint = this.state.formHints[name].find(item => value === item.value)
            if (hint)
                labels = hint.label;
        }
        return labels;
    }

    findSelectActionTypeByValue = (name, value) => {
        var hint;
        var actionType = "";

        hint = this.state.formHints[name].find(item => value === item.value)
        if (hint)
            actionType = hint.dlpActionType;
        return actionType;
    }

    findSelectVariantTypeByValue = (name, value) => {
        var hint;
        var variantType = "";

        hint = this.state.formHints[name].find(item => value === item.value)
        if (hint)
            variantType = hint.groupUuid;
        return variantType;
    }

    TypeDevice = () => {
        return (
            <div>
                <label>*Typ</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    name="dlpSource"
                    value={this.state.form.dlpSource ? { label: this.findSelectLabelByValue("dlpSource", this.state.form.dlpSource), value: this.state.form.dlpSource } : ""}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.dlpSource.filter(dlpSource => dlpSource.groupUuid.includes(this.state.form.dlpType))}
                    isDisabled={!this.state.form.dlpType}
                    className={Boolean(this.state.formErrors.dlpSource.length) ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                {Boolean(this.state.formErrors.dlpSource.length) && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.dlpSource)}</ul>}

                <label>*Aktywności</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    isMulti
                    name="dlpActions"
                    value={this.state.form.dlpActions ? this.state.form.dlpActions.map(dlpAction => { return { label: this.findSelectLabelByValue("dlpActions", dlpAction), value: dlpAction } }) : ""}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.dlpActions.filter(dlpAction => dlpAction.groupUuid.includes(this.state.form.dlpSource))}
                    isDisabled={!this.state.form.dlpSource}
                    className={Boolean(this.state.formErrors.dlpActions.length) ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                {Boolean(this.state.formErrors.dlpActions.length) && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.dlpActions)}</ul>}

                {this.findSelectActionTypeByValue("dlpSource", this.state.form.dlpSource) === "ACTIONVARIANTS" ? <this.ActionVariants /> : ""}
                {this.findSelectActionTypeByValue("dlpSource", this.state.form.dlpSource) === "WHITELIST" ? <this.WhiteList /> : ""}
                {this.findSelectActionTypeByValue("dlpSource", this.state.form.dlpSource) === "WITHOUTACTION" ? <this.ActionDetails /> : ""}
            </div>
        )
    }

    TypeProtocol = () => {
        return (
            <div>
                <label>*Typ Protokołu</label>  <i id="tooltipDlpSource" style={{ marginTop: '6px' }} className="float-right fas fa-info-circle"></i>
                <AsyncSelect
                    {...defaultSelectProps}
                    name="dlpSource"
                    value={this.state.form.dlpSource ? { label: this.findSelectLabelByValue("dlpSource", this.state.form.dlpSource), value: this.state.form.dlpSource } : ""}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.dlpSource.filter(dlpSource => dlpSource.groupUuid.includes(this.state.form.dlpType))}
                    isDisabled={!this.state.form.dlpType}
                    className={Boolean(this.state.formErrors.dlpSource.length) ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                {Boolean(this.state.formErrors.dlpSource.length) && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.dlpSource)}</ul>}
                <Tooltip
                    className="tooltip-modal"
                    open={this.state.tooltip.dlpSourceTooltip}
                    target={'#tooltipDlpSource'}
                    toggle={() => this.showTooltip("dlpSourceTooltip")}>{i18n.t('dlpSourceTooltip')}
                </Tooltip>

                <label>*Aktywności</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    isMulti
                    name="dlpActions"
                    value={this.state.form.dlpActions ? this.state.form.dlpActions.map(dlpAction => { return { label: this.findSelectLabelByValue("dlpActions", dlpAction), value: dlpAction } }) : ""}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.dlpActions.filter(dlpAction => dlpAction.groupUuid.includes(this.state.form.dlpSource))}
                    isDisabled={!this.state.form.dlpSource}
                    className={Boolean(this.state.formErrors.dlpActions.length) ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                {Boolean(this.state.formErrors.dlpActions.length) && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.dlpActions)}</ul>}

                {this.findSelectActionTypeByValue("dlpSource", this.state.form.dlpSource) === "ACTIONVARIANTS" ? <this.ActionVariants /> : ""}

            </div>
        )
    }

    ActionVariants = () => {
        return (
            <div>
                <label>*Warianty</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    name="dlpActionVariant"
                    value={this.state.form.dlpActionVariant ? { label: this.findSelectLabelByValue("dlpActionVariant", this.state.form.dlpActionVariant), value: this.state.form.dlpActionVariant } : ""}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.dlpActionVariant} />

                {this.findSelectVariantTypeByValue("dlpActionVariant", this.state.form.dlpActionVariant) === "KEYWORDS" ? <this.Keywords /> : ""}
                {this.findSelectVariantTypeByValue("dlpActionVariant", this.state.form.dlpActionVariant) === "CONFIDENTIALPATH" ? <this.ConfidentialPath /> : ""}
                {this.findSelectVariantTypeByValue("dlpActionVariant", this.state.form.dlpActionVariant) === "DEFINEDREGEX" ? <this.DefinedRegex /> : ""}

                <this.ActionDetails />

              <FormCheckbox name="dlpShadowing" toggle small checked={this.state.form.dlpShadowing} onChange={this.checkboxChangeHandler}>Shadowing</FormCheckbox>
            </div>
        )
    }

    Keywords = () => {
        return (
            <div>
                <label>Słowa kluczowe</label>
                <CreatableSelect
                    className="mb-2"
                    name="dlpKeywords"
                    components={components}
                    inputValue={this.state.keywordsInputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={this.keywordsChangeHandler}
                    onInputChange={this.keywordsChangeInputHandle}
                    onKeyDown={this.keywordsHandleKeyDown}
                    placeholder="Wpisz i naciśnij enter..."
                    value={this.state.form.dlpKeywords} />
            </div>
        )
    }

    DefinedRegex = () => {
        return (
            <div>
                <label>Dozwolona liczba wystąpień</label>  <i id="tooltipDlpRegexCounter" style={{ marginTop: '6px' }} className="float-right fas fa-info-circle"></i>
                <FormInput
                    className="mb-2"
                    name="dlpRegexCounter"
                    type="number"
                    min="0"
                    value={this.state.form.dlpRegexCounter}
                    onChange={this.inputChangeHandler} />
                <Tooltip
                    className="tooltip-modal"
                    open={this.state.tooltip.dlpRegexCounterTooltip}
                    target={'#tooltipDlpRegexCounter'}
                    toggle={() => this.showTooltip("dlpRegexCounterTooltip")}>{i18n.t('dlpRegexCounterTooltip')}
                </Tooltip>
            </div>
        )
    }

    ConfidentialPath = () => {
        return (
            <div>
                <label>Poufne(ścieżka)</label>
                <FormInput
                    className="mb-2"
                    name="dlpConfidentialPath"
                    value={this.state.form.dlpConfidentialPath}
                    onChange={this.inputChangeHandler} />

                <label>Typ Pliku</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    name="dlpFileType"
                    value={this.state.form.dlpFileType ? { label: this.findSelectLabelByValue("dlpFileType", this.state.form.dlpFileType), value: this.state.form.dlpFileType } : ""}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.dlpFileType} />
            </div>
        )
    }

    WhiteList = () => {
        return (
            <div>
                <label>Urządzenia dozwolone</label>
                <CreatableSelect
                    className="mb-2"
                    name="dlpWhiteList"
                    components={components}
                    inputValue={this.state.whiteListInputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={this.whiteListChangeHandler}
                    onInputChange={this.whiteListChangeInputHandle}
                    onKeyDown={this.whiteListHandleKeyDown}
                    placeholder="Wpisz i naciśnij enter..."
                    value={this.state.form.dlpWhiteList} />

                <this.ActionDetails />
            </div>
        )
    }

    ActionDetails = () => {
        return (
            <div>
                <label>Nazwa Użytkownika</label>
                <FormInput
                    className="mb-2"
                    name="dlpUserName"
                    value={this.state.form.dlpUserName}
                    onChange={this.inputChangeHandler}
                    placeholder="Wszyscy" />
            </div>
        )
    }

    render() {
        const { isAdminAtLeast, isAdmin } = this.state;
        const { name, deviceGroup, device, category, dlpType, procedure, manager } = this.state.form;
        const { categoryTooltip } = this.state.tooltip;
        const { uuid } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasDeviceGroupError = Boolean(this.state.formErrors.deviceGroup.length);
        var hasDlpTypeError = Boolean(this.state.formErrors.dlpType.length);
        var hasRuleCategoryError = Boolean(this.state.formErrors.category.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);

        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        return (
            <Row>
                <Col sm="12" md="6">
                    <label>*Nazwa reguły</label>
                    <FormInput
                        name="name"
                        value={name}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.name)}</ul>}

                    {!isEditingModeEnabled && isAdminAtLeast &&
                        <>
                            <label>*Menedżer</label>
                            <AsyncSelect
                                {...defaultSelectProps}
                                isClearable="true"
                                name="manager"
                                value={manager}
                                onChange={this.selectChangeHandler}
                                defaultOptions={this.state.formHints.manager}
                                className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")}
                                isLoading={this.state.isManagersLoading}
                                placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"} />
                            {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                        </>
                    }

                    <label>*Model powiadomienia</label>  <i id="tooltipCategory" style={{ marginTop: '6px' }} className="float-right fas fa-info-circle"></i>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="category"
                        value={category ? { label: this.findSelectLabelByValue("category", category), value: category } : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.category}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasRuleCategoryError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasRuleCategoryError && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.category)}</ul>}
                    <Tooltip
                        className="tooltip-modal"
                        open={categoryTooltip}
                        target={'#tooltipCategory'}
                        toggle={() => this.showTooltip("categoryTooltip")}>{i18n.t('categoryTooltip')}
                    </Tooltip>

                    <label>*Grupa</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="deviceGroup"
                        value={deviceGroup ? { label: this.findSelectLabelByValue("deviceGroup", deviceGroup), value: deviceGroup } : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.deviceGroup}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasDeviceGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasDeviceGroupError && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.deviceGroup)}</ul>}

                    <label>Urządzenie</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="device"
                        value={device ? { label: this.findSelectLabelByValue("device", device), value: device } : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.device.filter(device => device.groupUuid === this.state.form.deviceGroup)}
                        placeholder="Wszystkie"
                        isDisabled={!deviceGroup} />

                    <label>Procedura</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="procedure"
                        value={procedure ? { label: this.findSelectLabelByValue("procedure", procedure), value: procedure } : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.procedureGroup.map(procedureGroup => {
                            return {
                                label: procedureGroup.label,
                                options: procedureGroup.value.map(procedure => {
                                    return {
                                        label: procedure.name,
                                        value: procedure.id
                                    }
                                })
                            }
                        })}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        formatGroupLabel={formatGroupLabel} />
                </Col>

                <Col sm="12" md="6">
                    <label>*Akcje</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="dlpTrackingType"
                        value={this.state.form.dlpTrackingType ? { label: this.findSelectLabelByValue("dlpTrackingType", this.state.form.dlpTrackingType), value: this.state.form.dlpTrackingType } : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.dlpTrackingType}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={Boolean(this.state.formErrors.dlpTrackingType.length) ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {Boolean(this.state.formErrors.dlpTrackingType.length) && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.dlpTrackingType)}</ul>}

                    <label>*Rodzaj</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="dlpType"
                        value={dlpType ? { label: this.findSelectLabelByValue("dlpType", dlpType), value: dlpType } : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.dlpType}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasDlpTypeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasDlpTypeError && <ul className="mb-2 form-error-message">{this.renderError(this.state.formErrors.dlpType)}</ul>}

                    {dlpType === "DEVICE" ? <this.TypeDevice /> : ""}
                    {dlpType === "PROTOCOL" ? <this.TypeProtocol /> : ""}
                </Col>
            </Row >
        );
    }

}
export default ConfirmPcRuleForm;
