import React from "react";
import { ConfirmModal } from "../../common/Modal";

import ConfirmRuleForm from "../forms/ConfirmRuleForm"
import * as Constants from '../../../constants';

class ConfirmRuleModal extends React.Component {

    generateText = () => {
        // eslint-disable-next-line default-case
        switch (this.props.type) {
            case Constants.ACTIONS.TO_ADD:
                return <div>Spowoduje to dodanie reguły.<br></br>Jeśli jesteś zdecydowany, kliknij "Potwierdź".</div>
            case Constants.ACTIONS.TO_EDIT:
                return <div>Spowoduje to edycję reguły.<br></br>Jeśli jesteś zdecydowany, kliknij "Potwierdź".</div>
            case Constants.ACTIONS.TO_DELETE:
                return <div>Spowoduje to usunięcie reguły.<br></br>Jeśli jesteś zdecydowany, kliknij "Potwierdź".</div>
        }
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            type,
        } = this.props;

        return (
            <ConfirmModal
                title="Jesteś pewien, że chcesz dodać tą regułę?"
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.confirmRuleForm.submitForm(uuid, onSuccess); }}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                {this.generateText()}

                <ConfirmRuleForm type={type} toggle={toggle} ref="confirmRuleForm" />
            </ConfirmModal>
        )
    }
}

export default ConfirmRuleModal;