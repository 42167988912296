import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    SizePerPageDropdownStandalone,
    PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import { Col, Row, Button } from "shards-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

class SafetyProcedureList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            procedures: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { procedures } = props;
        return {
            procedures: procedures
        };
    }

    render() {
        const {
            procedures,
        } = this.state;

        const paginationOptions = {
            custom: true,
            totalSize: procedures.length
        };

        const procedureColumns = [
            {
                dataField: 'name',
                text: 'Nazwa procedury',
                headerClasses: 'border-0',
                headerAlign: "left",
                align: "left",
                sort: true,
                style: () => {
                    return {
                        verticalAlign: 'middle'
                    };
                },
            }, {
                dataField: 'owner',
                text: 'Właściciel',
                headerClasses: 'border-0',
                headerAlign: "left",
                align: "left",
                sort: false,
                style: () => {
                    return {
                        verticalAlign: 'middle'
                    };
                },
                formatter: (cell, row) => {
                    return cell + (row.isGlobal ? " - Globalna" : "")
                }
            },
            {
                dataField: '',
                text: 'Akcje',
                headerClasses: 'border-0',
                headerAlign: "right",
                align: "right",
                formatter: (cell, row) => {
                    if (row.isGlobal && JSON.parse(localStorage.getItem("user")).role === "USER") {
                        return (
                            <div>
                                <Button theme="info" size="sm" onClick={() => this.props.onPreview(row.content)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </div>
                        )
                    }

                    return (
                        <div>
                            <Button theme="info" size="sm" onClick={() => this.props.onPreview(row.content)}>
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                            <Button theme="info" size="sm" className="ml-1" onClick={() => this.props.onEdit(row.id)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                            <Button theme="danger" size="sm" className="ml-1" onClick={() => this.props.onDelete(row.id)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </div>
                    )
                }
            }
        ]

        return (
            <div>
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Row className="m-0 rounded-bottom mb-1" style={{ backgroundColor: "rgba(0,0,0,.075)" }}>
                            <Col xs="12" className="mb-2 p-0">
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField="id"
                                    data={procedures}
                                    columns={procedureColumns}
                                    classes="table mb-0"
                                    headerClasses="d-none"
                                    bordered={false}
                                    hover
                                    wrapperClasses="table-responsive"
                                    {...paginationTableProps} />
                            </Col>
                            <Col xs="6" className="d-flex justify-content-start">
                                <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                            </Col>
                            <Col xs="6" className="d-flex justify-content-end">
                                <div><PaginationListStandalone {...paginationProps} /></div>
                            </Col>
                        </Row>
                    )}
                </PaginationProvider>
            </div>
        );
    }
}

export default SafetyProcedureList;
