import React from 'react';
import { ConfirmModal } from "../../../common/Modal";
import * as AppConstants from '../../../../constants';
import API from "../../../../api/AxiosConfiguration";
import * as socToast from '../../../../utils/SocToast';

class DeleteAppCategoryModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteAppCategory = this.deleteAppCategory.bind(this);
    }

    deleteAppCategory(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(AppConstants.PC_SAFETICA_APP_CATEGORY_CONFIGURATION_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    onSuccess();
                    socToast.success("Powodzenie", "Kategoria aplikacji został usunięty.");
                    toggleFunction();
                }
            })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                title="Jesteś pewien, że chcesz usunąć kategorie aplikacji?"
                open={open}
                toggle={toggle}
                submitAction={this.deleteAppCategory}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                Spowoduje to usunięcie wszystkich danych o kategorii aplikacji.
                Jeśli jesteś zdecydowany, kliknij "Potwierdź".
            </ConfirmModal>
        )
    }

}

export default DeleteAppCategoryModal;