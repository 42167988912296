import React from "react";
import { ConfirmModal } from "../../common/Modal";
import { Alert, FormInput, FormSelect } from "shards-react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { get } from '@github/webauthn-json';
import {
    Row,
    Col,
} from "shards-react";
import Loader from 'react-loader-spinner';

export default class TwoFactorModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            acceptFunction,
            updateTwoFactorOperation
        } = this.props;

        return (
            <ConfirmModal
                title="Potwierdź operację"
                open={open}
                toggle={toggle}
                acceptFunction={acceptFunction}
                submitAction={() => this.refs.twoFactor.submitForm()}
                positiveButtonText="Prześlij"
                negativeButtonText="Anuluj">

                <TwoFactor toggle={toggle} acceptFunction={acceptFunction} updateTwoFactorOperation={updateTwoFactorOperation} ref="twoFactor" />

            </ConfirmModal>
        )

    }

}

class TwoFactor extends React.Component {

    interval = 0;

    constructor() {
        super();
        this.state = {
            stage: 1,
            token: "",
            tokenUuid: 0,
            twoFactorList: [],
            showSend: false,
            showValid: false,
            false: false,
            tokenName: "",
            twoFactorOperation: ""
        };

        API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/twoFactorList').then(response => {
            if (response.data) {
                if (response.data.length < 1) {
                    this.props.acceptFunction();
                    this.props.toggle();
                } else if (response.data.length === 1) {
                    this.setState({ twoFactorList: response.data });
                    this.setState({
                        tokenUuid: this.state.twoFactorList[0].tokenUuid,
                        tokenName: this.state.twoFactorList[0].tokenName
                    });
                    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/sendAuthenticatorToken/' + this.state.tokenUuid).then((result) => {
                        if (result.status === 200) {
                            if (result.data.assertionStartResponse) {
                                this.handleAssertionStart(result.data.assertionStartResponse);
                            } else if (result.data.twoFactorOperation !== undefined && result.data.twoFactorOperation !== null) {
                                this.setState({ twoFactorOperation: result.data.twoFactorOperation, stage: 3 });
                            } else {
                                this.setState({ stage: 2 });
                            }
                        }
                    });
                } else {
                    this.setState({ twoFactorList: response.data });
                    this.setState({
                        tokenUuid: this.state.twoFactorList[0].tokenUuid,
                        tokenName: this.state.twoFactorList[0].tokenName
                    });
                }
            }
        });

        this.refreshIntervalInMillis = 5000;
    }

    componentDidMount() {
        this._isMounted = true;
        this.interval = setInterval(() => {
            if (this._isMounted && this.state.stage === 3) {
                this.submitForm();
            }
        }, this.refreshIntervalInMillis);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.interval);
    }

    handleTokenUuidChange = (e) => {
        var token = JSON.parse(e.target.value)
        this.setState({
            tokenUuid: token.value,
            tokenName: token.name
        });
    }

    inputTokenChangeHandler = (event) => {
        this.setState({ token: event.target.value });
    }

    submitForm = () => {
        const { tokenUuid, token, twoFactorOperation } = this.state;
        if (this.state.stage === 1) {
            API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/sendAuthenticatorToken/' + this.state.tokenUuid).then((result) => {
                if (result.status === 200) {
                    if (result.data.assertionStartResponse) {
                        this.handleAssertionStart(result.data.assertionStartResponse);
                    } else if (result.data.twoFactorOperation !== undefined && result.data.twoFactorOperation !== null) {
                        this.setState({ twoFactorOperation: result.data.twoFactorOperation, stage: 3 });
                    } else {
                        this.setState({ stage: 2 });
                    }
                }
            }).catch((error) => { this.setState({ showSend: true }) });
        } else if (this.state.stage === 2) {
            API.post(Constants.AUTHENTICATOR_TOKEN_URL + "/tokenValid", { uuid: tokenUuid, token: token }).then((result) => {
                if (result.status === 200) {
                    this.props.updateTwoFactorOperation(result.data);
                    this.props.acceptFunction();
                    this.props.toggle();
                }
            }).catch((error) => { this.setState({ showValid: true }) });
        } else if (this.state.stage === 3) {
            API.post(Constants.AUTHENTICATOR_TOKEN_URL + "/tokenValid", { uuid: tokenUuid, twoFactorOperation: twoFactorOperation }).then((result) => {
                if (result.status === 200) {
                    this.props.updateTwoFactorOperation(result.data);
                    this.props.acceptFunction();
                    this.props.toggle();
                }
            }).catch((error) => { });
        }
    }

    async handleAssertionStart(response) {
        const credential = await get({
            publicKey: response.publicKeyCredentialRequestOptions
        });

        try {
            // @ts-ignore
            credential.clientExtensionResults = credential.getClientExtensionResults();
        } catch (e) {
            // @ts-ignore
            credential.clientExtensionResults = {};
        }

        const vtwofa = {
            assertionFinishRequest: { assertionId: response.assertionId, credential },
            uuid: this.state.tokenUuid
        }

        API.post(Constants.AUTHENTICATOR_TOKEN_URL + "/tokenValid", vtwofa).then(response => {
            if (response) {
                if (response.status === 200) {
                    this.props.updateTwoFactorOperation(response.data);
                    this.props.acceptFunction();
                    this.props.toggle();
                }
            }
        });
    }

    renderList() {
        const { showSend } = this.state;
        return (
            <Row>
                <Col sm="12">
                    <label>Wybierz sposób uwierzytelniania</label>
                    <FormSelect onChange={this.handleTokenUuidChange}>
                        {this.state.twoFactorList.map((token) => <option name={token.tokenName} key={token.tokenUuid} value={JSON.stringify({ "value": token.tokenUuid, "name": token.tokenName })}>{token.tokenName}</option>)}
                    </FormSelect>
                    <Alert theme="danger" open={showSend}>Próba przesłania tokenu zakończyła się niepowodzeniem</Alert>
                </Col>
            </Row>
        )
    }

    renderToken() {
        const { token, showValid, tokenName } = this.state;
        return (
            <Row>
                <Col sm="12">
                    <label>Wpisz 6-cyfrowy kod wygenerowany z : {tokenName}</label>
                    <FormInput name="token" value={token || ''} onChange={this.inputTokenChangeHandler} className="mb-2" />
                    <Alert theme="danger" open={showValid}>Niepoprawny token</Alert>
                </Col>
            </Row>
        )
    }


    renderTokenTrustedDevice() {
        const { tokenName } = this.state;
        return (
            <Row>
                <Col sm="12">
                    <label> Potwierdź operację na urządzeniu zaufanym: {tokenName}</label>
                    <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: "1" }}>
                        <Loader
                            type="ThreeDots"
                            color="#007bff"
                            height={27}
                            width={27}
                            visible={true} />
                    </div>
                </Col>
            </Row>
        )
    }

    render() {

        return this.state.stage === 1 ? this.renderList() :
            this.state.stage === 3 ? this.renderTokenTrustedDevice() :
                this.renderToken();
    }
}
