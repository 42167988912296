import React from 'react';
import { Row, Col, FormInput } from 'shards-react';

class BuySmsPackageForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                amount: 0,
                totalNetPrice: 0,
                totalGrossPrice: 0,
                tariff: this.getTariff(0),
            },
            formErrors: {
                amount: []
            }
        };

        this.amountChangeHandler = this.amountChangeHandler.bind(this);
        this.validForm = this.validForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    amountChangeHandler = (event) => {
        var { formErrors } = this.state;
        formErrors[event.target.name] = [];
        const amount = event.target.value;
        const tariff = this.getTariff(amount);
        this.setState({
            form: {
                ...this.state.form,
                amount: amount,
                totalNetPrice: amount * tariff.price,
                totalGrossPrice: amount * tariff.price * 1.23,
                tariff: tariff
            }
        });
    }

    validForm() {
        const { formErrors } = this.state;
        const { amount } = this.state.form;

        formErrors["amount"] = [];

        var errorCount = 0;

        if (amount <= 0) {
            formErrors["amount"].push("Ilość nie może być mniejsza od zera");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { amount, totalNetPrice, totalGrossPrice, tariff } = this.state.form;

        if (this.validForm()) {
            onSuccess({
                amount: amount,
                totalNetPrice: totalNetPrice,
                totalGrossPrice: totalGrossPrice,
                tariff: tariff
            })
        }
    }

    getTariff(amount = 0) {
        const { tariff } = this.props;
        return tariff.find(tariffItem => {
            return this.between(amount, tariffItem.startRange, tariffItem.endRange)
        })
    }

    between(x, min, max) {
        if (min == null) min = 0;
        if (max == null) max = Number.MAX_VALUE;
        return x >= min && x <= max;
    }

    render() {
        const {
            amount,
            totalNetPrice,
            totalGrossPrice,
            tariff
        } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const printQuota = (number) => {
            return `${(number || 0).toFixed(2).replace('.', ',')} ZŁ`;
        }

        var hasAmountError = Boolean(this.state.formErrors.amount.length);

        return (
            <Row className="justify-content-md-center">
                <Col sm="12" md="6">
                    <label>*Ilość</label>
                    <FormInput
                        name="amount"
                        value={amount || ''}
                        onChange={this.amountChangeHandler}
                        type="number"
                        min="0"
                        invalid={hasAmountError}
                        className={hasAmountError ? "mb-0" : "mb-2"} />
                    {hasAmountError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.amount)}</ul>}
                </Col>
                <Col sm="12">
                    <div className="text-center">
                        <h6 className="mb-0">Podsumowanie:</h6>

                        <div>
                            <p className="font-weight-normal mb-0">
                                {`${amount || 0} * ${printQuota(tariff ? tariff.price : 0)} = ${printQuota(totalNetPrice)} netto (${printQuota(totalGrossPrice)} brutto)`}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

}

export default BuySmsPackageForm;