import React from "react";
import { Row, Col, FormInput, DatePicker } from "shards-react";
import AsyncSelect from 'react-select/async';

import * as AppConstants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import { flatten } from "../../../utils/Arrays";
import { Store } from '../../../flux'
import { getCustomersForSelect } from '../../../api/ApiService';
import { hasValueUrlOrHtmlTag } from "../../../utils/Patterns";

import pl from 'date-fns/locale/pl';

class ConfirmControlPanelRuleForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            form: {
                name: "",
                manager: "",
                category: "",
                controlPanel: "",
                procedure: "",
                controlPanelGroup: "",
                sensorType: "",
                sensor: "",
                subSensor: "",
                trigger: "",
                conditional: "",
                ruleType: "",
                descriptions: "",
                partitions: "",
                zones: "",
                expanders: "",
                telephones: "",
                users: "",
                camera: "",
                cameraAction: "",
                fromInactivity: undefined,
                toInactivity: undefined,
                daysOfWeek: [],
            },
            formErrors: {
                name: [],
                manager: [],
                category: [],
                controlPanel: [],
                controlPanelGroup: [],
                sensorType: [],
                subSensor: [],
                conditional: [],
                trigger: [],
                cameraAction: [],
                ruleType: [],
                descriptions: [],
                fromInactivity: [],
                toInactivity: [],
                form: [],
            },
            formHints: {
                manager: [],
                category: [],
                controlPanel: [],
                procedure: [],
                controlPanelGroup: [],
                sensorType: [],
                sensor: [],
                subSensor: [],
                camera: [],
                cameraAction: [],
                descriptions: [],
                partitions: [],
                zones: [],
                expanders: [],
                telephones: [],
                users: []
            },

            isManagersLoading: true,
            isOptionsLoading: false,
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.dateChangeHandler = this.dateChangeHandler.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);
        this.fetchControlPanelRuleForm = this.fetchControlPanelRuleForm.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        const { isAdminAtLeast } = this.state;

        if (uuid && uuid.length) {
            this.fetchAvailableOptions(uuid, () => {
                this.fetchControlPanelRuleForm(uuid);
            });
        } else {
            if (isAdminAtLeast) {
                this.fetchAvailableOptions();
                this.fetchCompanyCustomers();
            } else {
                this.fetchAvailableOptions();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        var prevManager = prevState.form.manager ? prevState.form.manager.value : "";
        var currentManager = this.state.form.manager ? this.state.form.manager.value : "";

        if (prevManager !== currentManager) {
            this.clearFormHints();
            this.fetchAvailableOptions();
        }
    }

    clearFormHints() {
        this.setState({
            formHints: {
                ...this.state.formHints,
                category: [],
                controlPanel: [],
                sensorType: [],
                controlPanelGroup: [],
                sensor: [],
                subSensor: [],
                procedure: [],
                camera: [],
                cameraAction: [],
                descriptions: [],
                partitions: [],
                zones: [],
                expanders: [],
                telephones: [],
                users: [],
                trigger: [],
                conditional: []
            }
        })
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event, inputsToClearAfterChange) => {
        var { form, formErrors } = this.state;

        var clearedElements = {}
        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        var item;
        if (Array.isArray(selected)) {
            item = selected.map(selectedItem => selectedItem.value);
        } else {
            item = selected
        }

        this.setState({
            form: {
                ...form,
                ...clearedElements,
                [event.name]: item
            },
            formErrors: {
                ...formErrors,
                [event.name]: []
            }
        });
    }

    dateChangeHandler = (date, formName) => {
        var { form, formErrors } = this.state;
        form[formName] = date ? date.getTime() : undefined;
        if (formName === "fromInactivity" || formName === "toInactivity") formErrors["form"] = [];
        formErrors[formName] = [];
        this.setState({ form: form });
    }

    findSelectLabelByValue = (name, value) => {
        var hint;
        var labels = [];
        var list;

        if (name !== "daysOfWeek" && name !== "ruleType") {
            list = this.state.formHints[name];
        } else {
            list = this.props.options[name];
        }

        if (Array.isArray(value)) {
            value.forEach(valueArrayItem => {
                hint = list.find(item => valueArrayItem === item.value)
                if (hint)
                    labels.push(hint.label);
            })
        } else {
            hint = list.find(item => value === item.value)
            if (hint)
                labels = hint.label;
        }
        return labels;
    }

    validForm() {
        const { formErrors, isAdminAtLeast, isAdmin } = this.state;
        const { name, manager, category, controlPanel, sensorType, controlPanelGroup, subSensor, cameraAction, descriptions, fromInactivity, toInactivity, ruleType } = this.state.form;
        const { uuid } = this.props;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        formErrors["name"] = [];
        formErrors["manager"] = [];
        formErrors["category"] = [];
        formErrors["controlPanel"] = [];
        formErrors["sensorType"] = [];
        formErrors["controlPanelGroup"] = [];
        formErrors["subSensor"] = [];
        formErrors["trigger"] = [];
        formErrors["conditional"] = [];
        formErrors["cameraAction"] = [];
        formErrors["descriptions"] = [];
        formErrors["fromInactivity"] = [];
        formErrors["toInactivity"] = [];
        formErrors["form"] = [];

        var errorCount = 0;

        if (hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (!isAdmin && !isEditingModeEnabled && isAdminAtLeast && (!manager || manager.length <= 0)) {
            formErrors["manager"].push("Użytkownik musi zostać wybrany");
            errorCount++;
        }

        if (!category || category.value.length <= 0) {
            formErrors["category"].push("Model powiadomienia musi zostać wybrany");
            errorCount++;
        }

        if (!controlPanel || controlPanel.value.length <= 0) {
            formErrors["controlPanel"].push("Centala musi zostać wybrana");
            errorCount++;
        }

        if (controlPanel.additionalParameters === AppConstants.CONTROL_PANEL_TYPES.SENSOR) {
            if (!controlPanelGroup || controlPanelGroup.value.length <= 0) {
                formErrors["controlPanelGroup"].push("Grupa musi zostać wybrana");
                errorCount++;
            }

            if (!sensorType || sensorType.value.length <= 0) {
                formErrors["sensorType"].push("Typ urządzenia musi zostać wybrany");
                errorCount++;
            }

            if (!subSensor || subSensor.value.length <= 0) {
                formErrors["subSensor"].push("Czujnik musi zostać wybrany");
                errorCount++;
            }
        }

        if (controlPanel.additionalParameters === AppConstants.CONTROL_PANEL_TYPES.CAMERA) {
            if (!controlPanelGroup || controlPanelGroup.value.length <= 0) {
                formErrors["controlPanelGroup"].push("Grupa musi zostać wybrana");
                errorCount++;
            }

            if (!cameraAction || cameraAction.value.length <= 0) {
                formErrors["cameraAction"].push("Warunek musi zostac wybrany");
                errorCount++;
            }

            if (!fromInactivity && toInactivity) {
                formErrors["fromInactivity"].push("Pole Od nie zostało uzupełnione");
                errorCount++;
            }

            if (!toInactivity && fromInactivity) {
                formErrors["toInactivity"].push("Pole Do nie zostało uzupełnione");
                errorCount++;
            }

            if (fromInactivity && toInactivity && (fromInactivity > toInactivity)) {
                formErrors["form"].push("Pole Od nie może być większe od pola Do");
                errorCount++;
            }
        }

        if (controlPanel.additionalParameters === AppConstants.CONTROL_PANEL_TYPES.DICTIONARY && ruleType === "OTHERS") {
            if (!descriptions || descriptions.length <= 0) {
                formErrors["descriptions"].push("Opis nie może być pusty");
                errorCount++;
            }
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildSubmitRuleForm() {
        const { form } = this.state;
        return {
            ...this.buildEditRuleForm(),
            manager: form.manager && form.manager.value
        }
    }

    buildEditRuleForm() {
        const { form } = this.state;
        return {
            name: form.name,
            manager: form.manager && form.manager.value,
            category: form.category && form.category.value,
            controlPanel: form.controlPanel && form.controlPanel.value,
            sensorType: form.sensorType && form.sensorType.value,
            controlPanelGroup: form.controlPanelGroup && form.controlPanelGroup.value,
            sensor: form.sensor && form.sensor.value,
            subSensor: form.subSensor && form.subSensor.value,
            procedure: form.procedure && form.procedure.value,
            trigger: form.trigger ?
                form.trigger.value ?
                    form.trigger.value : form.trigger
                : null,
            conditional: form.conditional ? form.conditional.value : null,
            camera: form.camera ? form.camera.value : null,
            cameraAction: form.cameraAction ? form.cameraAction.value : null,
            ruleType: form.ruleType ? form.ruleType.value : null,
            descriptions: form.descriptions ? form.descriptions : null,
            partitions: form.partitions ? form.partitions : null,
            zones: form.zones ? form.zones : null,
            expanders: form.expanders ? form.expanders : null,
            telephones: form.telephones ? form.telephones : null,
            users: form.users ? form.users : null,
            fromInactivity: form.fromInactivity ? form.fromInactivity : null,
            toInactivity: form.toInactivity ? form.toInactivity : null,
            daysOfWeek: form.daysOfWeek ? form.daysOfWeek : null,
        }
    }

    submitForm = (onSuccess) => {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.CONTROL_PANEL_RULE_URL + "/" + uuid, {
                    action: AppConstants.ACTIONS.TO_EDIT,
                    form: this.buildEditRuleForm()
                })
            } else {
                apiPromise = API.put(AppConstants.CONTROL_PANEL_RULE_URL, {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: this.buildSubmitRuleForm()
                });
            }
            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle()
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    fetchCompanyCustomers = () => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            })
        })
    }

    fetchAvailableOptions = (uuid = "", callback) => {
        const { isAdminAtLeast } = this.state;
        const { manager } = this.state.form;

        var axiosConfig;
        if (isAdminAtLeast) {
            if (Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
                if (!((manager && manager.value) || uuid)) return;
            }

            axiosConfig = {
                params: {
                    uuid: uuid,
                    manager: manager ? manager.value : ""
                }
            }
        }

        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.CONTROL_PANEL_RULE_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                        procedure: flatten(result.data.procedure.map(procedureGroup => procedureGroup.value.map(procedureChildren => {
                            return {
                                label: procedureChildren.name,
                                value: procedureChildren.id
                            }
                        }))),
                    },
                    isOptionsLoading: false,
                }, callback);
            }
        });
    }

    findHintByValue(inputName, value) {
        var foundOption;
        const { formHints } = this.state;
        const { options } = this.props;
        if (inputName !== "ruleType") {
                foundOption = formHints[inputName].map(hint => {
                if (hint.options != null) {
                    return hint.options.find(option => option.value === value)
                } else {
                    return hint.value === value ? hint : undefined
                }
            }).find(option => !(option == null))
        } else {
                foundOption = options[inputName].map(hint => {
                if (hint.options != null) {
                    return hint.options.find(option => option.value === value)
                } else {
                    return hint.value === value ? hint : undefined
                }
            }).find(option => !(option == null))
        }

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchControlPanelRuleForm = (uuid) => {
        API.get(AppConstants.CONTROL_PANEL_RULE_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                var form = {
                    ...this.state.form,
                    ...result.data,
                    category: result.data.category ? this.findHintByValue("category", result.data.category) : "",
                    controlPanel: result.data.controlPanel ? this.findHintByValue("controlPanel", result.data.controlPanel) : "",
                    sensorType: result.data.sensorType ? this.findHintByValue("sensorType", result.data.sensorType) : "",
                    controlPanelGroup: result.data.controlPanelGroup ? this.findHintByValue("controlPanelGroup", result.data.controlPanelGroup) : "",
                    sensor: result.data.sensor ? this.findHintByValue("sensor", result.data.sensor) : "",
                    subSensor: result.data.subSensor ? this.findHintByValue("subSensor", result.data.subSensor) : "",
                    procedure: result.data.procedure ? this.findHintByValue("procedure", result.data.procedure) : "",

                    camera: result.data.camera ? this.findHintByValue("camera", result.data.camera) : "",
                    cameraAction: result.data.cameraAction ? this.findHintByValue("cameraAction", result.data.cameraAction) : "",
                    descriptions: result.data.descriptions ? result.data.descriptions : "",
                    partitions: result.data.partitions ? result.data.partitions : "",
                    zones: result.data.zones ? result.data.zones : "",
                    expanders: result.data.expanders ? result.data.expanders : "",
                    telephones: result.data.telephones ? result.data.telephones : "",
                    users: result.data.users ? result.data.users : "",
                    ruleType: result.data.ruleType ? this.findHintByValue("ruleType", result.data.ruleType) : "",
                }

                if (this.findHintByValue("controlPanel", result.data.controlPanel).additionalParameters === "SENSOR") {
                    const valueType = JSON.parse(form.subSensor.additionalParameters).valueType;
                    if (valueType === AppConstants.RULE_TYPES.COUNT) {
                        form = {
                            ...form,
                            trigger: result.data.trigger ? result.data.trigger : "",
                            conditional: result.data.conditional ? this.findHintByValue("conditional", result.data.conditional) : "",
                        }
                    } else if (valueType === AppConstants.RULE_TYPES.DICTIONARY) {
                        form = {
                            ...form,
                            trigger: result.data.trigger ? this.findHintByValue("trigger", result.data.trigger) : "",
                        }
                    }

                }

                this.setState({ form: form })
            }
        });
    }

    render() {
        const { isAdminAtLeast, isAdmin } = this.state;
        const { name, manager, category, controlPanel, sensorType, controlPanelGroup, sensor, subSensor, procedure, trigger, conditional, camera, cameraAction, fromInactivity, toInactivity, ruleType } = this.state.form;
        const { uuid, isMobileView, options } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);
        var hasCategoryError = Boolean(this.state.formErrors.category.length);
        var hasControlPanelError = Boolean(this.state.formErrors.controlPanel.length);
        var hasControlPanelGroupError = Boolean(this.state.formErrors.controlPanelGroup.length);
        var hasSensorTypeError = Boolean(this.state.formErrors.sensorType.length);
        var hasSubSensorError = Boolean(this.state.formErrors.subSensor.length);
        var hasConditionalError = Boolean(this.state.formErrors.conditional.length);
        var hasTriggerError = Boolean(this.state.formErrors.trigger.length);
        var hasCameraActionError = Boolean(this.state.formErrors.cameraAction.length);
        var hasRuleTypeError = Boolean(this.state.formErrors.ruleType.length);
        var hasDescriptionsError = Boolean(this.state.formErrors.descriptions.length);
        var hasToActivityError = Boolean(this.state.formErrors.toInactivity.length);
        var hasFromActivityError = Boolean(this.state.formErrors.fromInactivity.length);
        var hasFormError = Boolean(this.state.formErrors.form.length);

        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const filterOptions = (inputValue, optionName) => {
            var list;
            if (optionName !== "daysOfWeek" && optionName !== "ruleType") {
                list = this.state.formHints[optionName];
            } else {
                list = options[optionName];
            }

            return list.filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz",
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        const renderCountTypeForm = () => {
            return (
                <>
                    <label>*Wyzwalaj, gdy</label>
                    <FormInput
                        name="trigger"
                        value={trigger}
                        onChange={this.inputChangeHandler}
                        type="number"
                        className={hasTriggerError ? "has-error mb-0" : "mb-2"} />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}

                    <label>*Warunek</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="conditional"
                        value={conditional}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.conditional}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !subSensor}
                        className={hasConditionalError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "conditional")} />
                    {hasConditionalError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.conditional)}</ul>}
                    {hasFormError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.form)}</ul>}
                </>
            )
        }

        const renderDictionaryTypeForm = () => {
            return (
                <>
                    <label>*Wyzwalaj, gdy</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="trigger"
                        value={trigger}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.trigger.filter(trigger => {
                            if (subSensor) return trigger.parentValue === subSensor.value;
                            return false;
                        })}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !subSensor}
                        className={hasTriggerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "trigger")} />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}
                    {hasFormError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.form)}</ul>}
                </>
            )
        }

        const renderHomeCenterForm = () => {
            return (
                <>
                    <label>*Typ urządzenia</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="sensorType"
                        value={sensorType}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["controlPanelGroup", "sensor", "subSensor", "procedure", "trigger", "conditional"]); }}
                        defaultOptions={this.state.formHints.sensorType.filter(sensorType => sensorType.parentValue === this.state.form.controlPanel.value)}
                        isDisabled={!controlPanel}
                        className={hasSensorTypeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "sensorType")} />
                    {hasSensorTypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.sensorType)}</ul>}

                    <label>*Grupa urządzeń</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="controlPanelGroup"
                        value={controlPanelGroup}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["sensor", "subSensor", "procedure", "trigger", "conditional"]); }}
                        defaultOptions={this.state.formHints.controlPanelGroup.filter(controlPanelGroup => controlPanelGroup.parentValue === this.state.form.sensorType.value)}
                        isDisabled={!sensorType}
                        className={hasControlPanelGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "controlPanelGroup")} />
                    {hasControlPanelGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.controlPanelGroup)}</ul>}

                    <label>Urządzenie</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="sensor"
                        value={sensor}
                        onChange={this.selectChangeHandler}
                        defaultOptions={
                            this.state.formHints.sensor.filter(sensor => sensor.parentValue === this.state.form.controlPanelGroup.value &&
                                JSON.parse(sensor.additionalParameters).sensorType === this.state.form.sensorType.value
                            )
                        }
                        isDisabled={!controlPanelGroup}
                        isClearable
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "sensor")} />

                    <label>*Czujnik</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="subSensor"
                        value={subSensor}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["trigger", "conditional"]); }}
                        defaultOptions={this.state.formHints.subSensor.filter(subSensor => subSensor.parentValue === this.state.form.sensorType.value)}
                        isDisabled={!sensorType}
                        className={hasSubSensorError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "subSensor")} />
                    {hasSubSensorError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.subSensor)}</ul>}

                    {subSensor && JSON.parse(subSensor.additionalParameters).valueType === AppConstants.RULE_TYPES.COUNT && renderCountTypeForm()}
                    {subSensor && JSON.parse(subSensor.additionalParameters).valueType === AppConstants.RULE_TYPES.DICTIONARY && renderDictionaryTypeForm()}
                </>
            )
        }

        const renderMonitoringForm = () => {
            return (
                <>
                    <label>*Grupa urządzeń</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="controlPanelGroup"
                        value={controlPanelGroup}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["camera", "cameraAction"]); }}
                        defaultOptions={this.state.formHints.controlPanelGroup.filter(controlPanelGroup => controlPanelGroup.parentValue === this.state.form.sensorType.value)}
                        isDisabled={!controlPanel}
                        className={hasControlPanelGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "controlPanelGroup")} />
                    {hasControlPanelGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.controlPanelGroup)}</ul>}

                    <label>Urządzenie</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="camera"
                        value={camera}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.camera.filter(camera => camera.parentValue === this.state.form.controlPanelGroup.value)}
                        isDisabled={!controlPanelGroup}
                        isClearable
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "camera")} />

                    <label>*Wyzwalaj, gdy</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="cameraAction"
                        value={cameraAction}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.cameraAction}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !controlPanelGroup}
                        className={hasCameraActionError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "cameraAction")} />
                    {hasCameraActionError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.cameraAction)}</ul>}
                    {hasFormError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.form)}</ul>}
                </>
            )
        }

        const renderIntegraForm = () => {
            return (
                <>
                    <label>*Akcje(Description)</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        isMulti
                        name="descriptions"
                        value={this.state.form.descriptions ? this.state.form.descriptions.map(descriptions => { return { label: this.findSelectLabelByValue("descriptions", descriptions), value: descriptions } }) : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.descriptions.filter(descriptions => descriptions.parentValue === this.state.form.controlPanel.value)}
                        isDisabled={!controlPanel}
                        className={hasDescriptionsError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "descriptions")} />
                    {hasDescriptionsError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.descriptions)}</ul>}

                    <label>Partycje(Partition)</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        isMulti
                        name="partitions"
                        value={this.state.form.partitions ? this.state.form.partitions.map(partitions => { return { label: this.findSelectLabelByValue("partitions", partitions), value: partitions } }) : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.partitions.filter(partitions => partitions.parentValue === this.state.form.controlPanel.value)}
                        isDisabled={!controlPanel}
                        className={"react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "partitions")} />

                    <label>Strefy(Zone)</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        isMulti
                        name="zones"
                        value={this.state.form.zones ? this.state.form.zones.map(zones => { return { label: this.findSelectLabelByValue("zones", zones), value: zones } }) : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.zones.filter(zones => zones.parentValue === this.state.form.controlPanel.value)}
                        isDisabled={!controlPanel}
                        className={"react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "zones")} />

                    <label>Czujniki(Expander)</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        isMulti
                        name="expanders"
                        value={this.state.form.expanders ? this.state.form.expanders.map(expanders => { return { label: this.findSelectLabelByValue("expanders", expanders), value: expanders } }) : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.expanders.filter(expanders => expanders.parentValue === this.state.form.controlPanel.value)}
                        isDisabled={!controlPanel}
                        className={"react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "expanders")} />

                    <label>Zapisane numery telefonów(Telephone)</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        isMulti
                        name="telephones"
                        value={this.state.form.telephones ? this.state.form.telephones.map(telephones => { return { label: this.findSelectLabelByValue("telephones", telephones), value: telephones } }) : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.telephones.filter(telephones => telephones.parentValue === this.state.form.controlPanel.value)}
                        isDisabled={!controlPanel}
                        className={"react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "telephones")} />

                    <label>Użytkownicy(User)</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        isMulti
                        name="users"
                        value={this.state.form.users ? this.state.form.users.map(users => { return { label: this.findSelectLabelByValue("users", users), value: users } }) : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.users.filter(users => users.parentValue === this.state.form.controlPanel.value)}
                        isDisabled={!controlPanel}
                        className={"react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "users")} />
                    {hasFormError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.form)}</ul>}
                </>
            )
        }

        const renderIntegraRuleType = () => {
            return (
                <>
                    <label>*Typ reguły</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="ruleType"
                        value={ruleType}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={options.ruleType}
                        isDisabled={!controlPanel}
                        className={hasRuleTypeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "ruleType")} />
                    {hasRuleTypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ruleType)}</ul>}
                    {this.state.form.ruleType && this.state.form.ruleType.value === "OTHERS" && renderIntegraForm()}
                </>
            )
        }

        return (
            <Row>
                <Col sm="12" md="6">
                    <label>*Nazwa reguły</label>
                    <FormInput
                        name="name"
                        value={name}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    {!isEditingModeEnabled && isAdminAtLeast &&
                        <>
                            <label>*Menedżer</label>
                            <AsyncSelect
                                {...defaultSelectProps}
                                name="manager"
                                value={manager}
                                onChange={(item, event) => { this.selectChangeHandler(item, event, ["category", "controlPanel", "sensorType", "controlPanelGroup", "sensor", "subSensor", "procedure", "trigger", "conditional", "camera", "cameraAction", "descriptions", "expanders", "partitions", "telephones", "users"]); }}
                                defaultOptions={this.state.formHints.manager}
                                className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")}
                                isLoading={this.state.isManagersLoading}
                                placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"} />
                            {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                        </>
                    }

                    <label>*Model powiadomienia</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="category"
                        value={category}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.category}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasCategoryError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "category")} />
                    {hasCategoryError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.category)}</ul>}

                    <label>Procedura</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="procedure"
                        value={procedure || ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.procedure}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        formatGroupLabel={formatGroupLabel}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "procedure")} />

                    <label>*Centrala</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="controlPanel"
                        value={controlPanel}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["sensorType", "controlPanelGroup", "sensor", "subSensor", "procedure", "trigger", "conditional", "camera", "cameraAction", "descriptions", "expanders", "partitions", "telephones", "users"]); }}
                        defaultOptions={this.state.formHints.controlPanel}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasControlPanelError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "controlPanel")} />
                    {hasControlPanelError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.controlPanel)}</ul>}

                    <label>Dni tygodnia bez aktywności</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        isMulti
                        name="daysOfWeek"
                        value={this.state.form.daysOfWeek ? this.state.form.daysOfWeek.map(daysOfWeek => { return { label: this.findSelectLabelByValue("daysOfWeek", daysOfWeek), value: daysOfWeek } }) : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={options.daysOfWeek}
                        isDisabled={!controlPanel}
                        className={"react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "daysOfWeek")} />

                    <label>Brak aktywności reguły od godziny</label>
                    <DatePicker
                        selected={fromInactivity}
                        startDate={fromInactivity}
                        onChange={date => this.dateChangeHandler(date, "fromInactivity")}
                        locale={pl}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm aa"
                        timeIntervals={15}
                        timeCaption="Godzina"
                        withPortal={isMobileView}
                        popperPlacement="auto"
                        className={hasFromActivityError || hasFormError ? "date-picker has-error" : "mb-2"} />
                    {hasFromActivityError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.fromInactivity)}</ul>}

                    <label>Brak aktywności reguły do godziny</label>
                    <DatePicker
                        selected={toInactivity}
                        startDate={toInactivity}
                        onChange={date => this.dateChangeHandler(date, "toInactivity")}
                        locale={pl}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm aa"
                        timeIntervals={15}
                        timeCaption="Godzina"
                        withPortal={isMobileView}
                        popperPlacement="auto"
                        className={hasToActivityError || hasFormError ? "date-picker has-error" : "mb-2"} />
                    {hasToActivityError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.toInactivity)}</ul>}
                </Col>

                <Col sm="12" md="6">
                    {controlPanel && controlPanel.additionalParameters === AppConstants.CONTROL_PANEL_TYPES.SENSOR && renderHomeCenterForm()}
                    {controlPanel && controlPanel.additionalParameters === AppConstants.CONTROL_PANEL_TYPES.CAMERA && renderMonitoringForm()}
                    {controlPanel && controlPanel.additionalParameters === AppConstants.CONTROL_PANEL_TYPES.DICTIONARY && renderIntegraRuleType()}
                </Col>
            </Row>
        );
    }

}

export default ConfirmControlPanelRuleForm;

ConfirmControlPanelRuleForm.defaultProps = {
    options: {
        daysOfWeek: [
            { label: "Poniedziałek", value: "1" },
            { label: "Wtorek", value: "2" },
            { label: "Środa", value: "3" },
            { label: "Czwartek", value: "4" },
            { label: "Piatek", value: "5" },
            { label: "Sobota", value: "6" },
            { label: "Niedziela", value: "7" }
        ],
        ruleType: [
            { label: "Nieobecności", value: "ABSENCES" },
            { label: "Pozostałe", value: "OTHERS" },
        ],
    }
}
