import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import EventHistoryFilterForm from "../components/pc-event-history/forms/EventHistoryFilterForm";
import EventHistoryList from "../components/pc-event-history/lists/EventHistoryList";
import ScheduledReports from "../components/scheduled-reports/ScheduledReports";
import * as AppConstants from '../constants'
import { Store } from "../flux";
import { URLQueryParams } from "../utils/URLQueryParams";

class EventHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            foundEvents: [],
            deviceType: "",
            randomKeyForForceUpdate: Math.random(),
            rules: this.props.location.state ? this.props.location.state.rules : [],
        }

        this.updateSearchResult = this.updateSearchResult.bind(this);
        this.setDeviceType = this.setDeviceType.bind(this);
        this.generateRandomKeyForForceUpdate = this.generateRandomKeyForForceUpdate.bind(this);
    }


  updateSearchResult(foundEvents) {
        this.setState({
            foundEvents: foundEvents,
        });
    }

    setDeviceType(deviceType) {
        this.setState({
            deviceType: deviceType
        })
    }

    generateRandomKeyForForceUpdate() {
        this.setState({
            randomKeyForForceUpdate: Math.random()
        });
    }

    render() {
        const { foundEvents, deviceType, randomKeyForForceUpdate, isSocAdmin, rules } = this.state;

        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle title="Wygenerowane naruszenia reguł" subtitle="Raport" className="text-sm-left mb-3" />
                </Row>

                <Row className="mt-2 mb-2">
                    <Col>
                        <Card>
                            <CardBody className="p-0 pt-1 pb-3">
                                <EventHistoryFilterForm
                                  redirected={!!this.props.location.search}
                                  queryParams={this.props.location.search && URLQueryParams(this.props.location.search)}
                                  onSuccess={this.updateSearchResult}
                                  onScheduledReportSaveSuccess={this.generateRandomKeyForForceUpdate}
                                  providedFilters={this.props.location.state}
                                  onChange={this.setDeviceType} rules={rules}/>

                                <EventHistoryList history={this.props.history} foundEvents={foundEvents} deviceType={deviceType} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-2 mb-2">
                    <Col>
                        {!isSocAdmin && <ScheduledReports key={randomKeyForForceUpdate} />}
                    </Col>
                </Row>
            </Container>
        );
    }

}

export default EventHistory;
