import React from 'react';
import { Row, Col, FormInput, InputGroup, InputGroupAddon, Button } from 'shards-react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';
import * as socToast from '../../../../utils/SocToast';

class ConfirmUserGroupForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOptionsLoading: false,
            safeticaDefinedUsers: [],
            form: {
                name: "",
                user: "",
                users: [],
            },
            formErrors: {
                name: [],
                user: [],
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.fetchDeviceApplicationForm = this.fetchDeviceApplicationForm.bind(this);
        this.validUser = this.validUser.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.validForm = this.validForm.bind(this);
        this.buildForm = this.buildForm.bind(this);
    }

    componentDidMount() {
        const { uuid, safeticaDefinedUsers } = this.props;
        this.setState({
            safeticaDefinedUsers: safeticaDefinedUsers,
        });

        if (uuid.length) {
            this.fetchDeviceApplicationForm(uuid);
        }
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event) => {
        const { form, formErrors } = this.state;

        if (event.name !== "user") {
            form[event.name] = item ? item.value : '';
        } else {
            this.setState({
                form: {
                    ...form,
                    [event.name]: item
                }
            });
            return;
        }

        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name } = this.state.form;

        formErrors["name"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 255) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 255 znaków");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildForm() {
        const { name, users } = this.state.form;
        return {
            name: name,
            users: users
        }
    }

    submitForm(onSuccess) {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.patch(AppConstants.PC_SAFETICA_USERS_CONFIGURATION_URL + "/" + uuid, {
                    form: this.buildForm()
                })
            } else {
                apiPromise = API.post(AppConstants.PC_SAFETICA_USERS_CONFIGURATION_URL, {
                    form: this.buildForm()
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Grupa użytkowników została dodana.");
                }

                if (result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Grupa użytkowników została zedytowana.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            });
        }
    }

    validUser(users, user, formErrors) {
        if (!user) {
            formErrors["user"].push("Nie można dodać pustego słowa");
            return false;
        }

        if (user && user.length > 255) {
            formErrors["user"].push("Podane słowo nie może być dłuższe nić 255 znaków.");
            return false;
        }

        if (user && users.find(existingUser => existingUser.toLowerCase() === user.toLowerCase())) {
            formErrors["user"].push("Podane słowo już istnieje");
            return false;
        }

        return true;
    }

    addUser(user) {
        const { users } = this.state.form;
        const { formErrors } = this.state;

        formErrors["user"] = [];
        if (!this.validUser(users, user, formErrors)) {
            this.setState({ formErrors: formErrors });
            return;
        }

        users.push(user);
        this.setState({
            form: {
                ...this.state.form,
                user: '',
                users: users
            }
        })
    }

    deleteUser(index) {
        const { users } = this.state.form;
        if (index > -1) {
            users.splice(index, 1);
            this.setState({
                form: {
                    ...this.state.form,
                    users: users
                }
            })
        }
    }

    fetchDeviceApplicationForm(uuid) {
        API.get(AppConstants.PC_SAFETICA_USERS_CONFIGURATION_URL + "/" + uuid)
            .then(result => {
                this.setState({
                    form: {
                        ...this.state.form,
                        ...result.data
                    }
                })
            })
    }

    render() {
        const { name, user, users } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasUserError = Boolean(this.state.formErrors.user.length);

        const filterOptions = (inputValue, optionName) => {
            return this.state[optionName].filter(option =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz lub utwórz nowego",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            isClearable: true
        }

        return (
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <label>*Nazwa Grupy</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>Nazwa Użytkownika</label>
                    <InputGroup className={hasUserError ? "mb-0" : "mb-2"}>
                        <div style={{ width: "18em" }}>
                            <AsyncCreatableSelect
                                {...defaultSelectProps}
                                name="user"
                                value={user || ''}
                                onChange={this.selectChangeHandler}
                                defaultOptions={this.state.safeticaDefinedUsers}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "safeticaDefinedUsers")}
                                isLoading={this.state.isOptionsLoading}
                                invalid={hasUserError} />
                        </div>
                        <InputGroupAddon type="append">
                            <Button className={"mb-2"} onClick={() => this.addUser(user.value)}><FontAwesomeIcon icon={faPlus} /></Button>
                        </InputGroupAddon>
                    </InputGroup>
                    {hasUserError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.user)}</ul>}

                    {users.map((user, idx) => (
                        <InputGroup key={idx} className="mb-1">
                            <FormInput value={user} disabled />
                            <InputGroupAddon type="append">
                                <Button theme="danger" onClick={() => this.deleteUser(idx)}><FontAwesomeIcon icon={faMinus} /></Button>
                            </InputGroupAddon>
                        </InputGroup>
                    ))}
                </Col>
            </Row>
        );
    }

}

export default ConfirmUserGroupForm;