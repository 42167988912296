import React from "react";
import { ConfirmModal } from "../../common/Modal";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import {
    Row,
    Col,
} from "shards-react";

export default class DeleteAuthenticatorTokenModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            deleteTokenUuid,
            twoFactorOperation
        } = this.props;

        return (
            <ConfirmModal
                title="Usuwanie opcji autoryzacji"
                open={open}
                toggle={toggle}
                deleteTokenId={deleteTokenUuid}
                submitAction={() => this.refs.deleteAuthenticatorToken.submitForm()}
                positiveButtonText="Usuń Token"
                negativeButtonText="Anuluj">

                <DeleteAuthenticatorToken toggle={toggle} deleteTokenUuid={deleteTokenUuid} twoFactorOperation={twoFactorOperation} ref="deleteAuthenticatorToken" />

            </ConfirmModal>
        )

    }
    
}

class DeleteAuthenticatorToken extends React.Component {

    submitForm = () => {
        API.post(Constants.AUTHENTICATOR_TOKEN_URL + '/' + this.props.deleteTokenUuid, {twoFactorOperation: this.props.twoFactorOperation}).then((result) => {
            if (result.status === 200) {
                this.props.toggle();
            }
        });
    }

    render() {

        return (
            <Row>
                <Col sm="12">
                    <label>Czy na pewno usunąć Token?</label>
                </Col>
            </Row>
        );
    }
}
