import React from 'react';

import Select from 'react-select';
import { FormInput } from 'shards-react';
import Validator from './validators/Validator';

class FormElement extends React.Component {

    state = {
        element: this.props.formItem,
        form: {
            [this.props.formItem.name]: ""
        },
        formErrors: {
            [this.props.formItem.name]: []
        }
    }

    componentDidMount() {
        const { initialValue } = this.props;
        const { name } = this.props.formItem;
        this.setState({
            form: {
                [name]: initialValue ? this.state.element.type === 'select' ?
                    FormElement.findHintByValue(initialValue, this.state.element.options) : initialValue : ""
            }
        })
    }

    static getDerivedStateFromProps(props, state) {
        const { errors } = props;
        const { name } = props.formItem;

        var nextState = null;
        if (JSON.stringify(errors) !== JSON.stringify(state.errors)) {
            nextState = {
                ...nextState,
                formErrors: {
                    [name]: props.errors ? props.errors : []
                }
            }
        }

        return nextState;
    }

    static findHintByValue(value, options) {
        const foundOption = options.filter(hint => hint.value === value);
        if (foundOption.length <= 0) {
            return undefined;
        }

        return foundOption[0];
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: { ...form } });
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        form[event.name] = item
        formErrors[event.name] = [];
        this.setState({ form: { ...form } });
    }

    renderError = (errors) => {
        return errors.map((error, index) =>
            <li key={index}>{error}</li>
        )
    }

    renderRequiredInputTypeField = (inputName, inputType, errors = [], isDisabled = false, options = []) => {
        var inputValue = this.state.form[inputName];
        var isError = errors ? Boolean(errors.length) : false

        switch (inputType) {
            case 'input-numeric':
                return (
                    <React.Fragment>
                        <FormInput
                            name={inputName}
                            value={inputValue || ''}
                            onChange={this.inputChangeHandler}
                            type="number"
                            invalid={isError}
                            disabled={isDisabled}
                            className={isError ? "mb-0" : "mb-2"} />
                        {isError && <ul className="mb-2 form-error-message">{this.renderError(errors)}</ul>}
                    </React.Fragment>
                )
            case 'input-password':
                return (
                    <React.Fragment>
                        <FormInput
                            name={inputName}
                            value={inputValue || ''}
                            onChange={this.inputChangeHandler}
                            type="password"
                            invalid={isError}
                            disabled={isDisabled}
                            className={isError ? "mb-0" : "mb-2"} />
                        {isError && <ul className="mb-2 form-error-message">{this.renderError(errors)}</ul>}
                    </React.Fragment>
                )
            case 'select':
                return (
                    <React.Fragment>
                        <Select
                            name={inputName}
                            value={inputValue}
                            onChange={this.selectChangeHandler}
                            placeholder="Wybierz"
                            options={options}
                            noOptionsMessage={() => "Brak dostępnych opcji"}
                            loadingMessage={() => "Ładowanie"}
                            className={isError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                            classNamePrefix="react-select"
                            style={{ width: "100%" }}
                            isClearable />
                        {isError && <ul className="mb-2 form-error-message">{this.renderError(errors)}</ul>}
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <FormInput
                            name={inputName}
                            value={inputValue || ''}
                            onChange={this.inputChangeHandler}
                            invalid={isError}
                            disabled={isDisabled}
                            className={isError ? "mb-0" : "mb-2"} />
                        {isError && <ul className="mb-2 form-error-message">{this.renderError(errors)}</ul>}
                    </React.Fragment>
                )
        }
    }

    valid() {
        const { element, form, formErrors } = this.state;

        formErrors[element.name] = [];

        var errorCount = 0;

        if (element.required && (form[element.name] == null || form[element.name].length === 0)) {
            formErrors[element.name].push(element.requiredErrorMessage);
            errorCount++;
        }

        element.validators && element.validators.forEach(elementValidator => {
            const validator = Validator.getValidator(elementValidator);
            if (!validator.valid(form[element.name])) {
                formErrors[element.name].push(elementValidator.errorMessage);
                errorCount++;
            }
        });

        this.setState({ FormSelect: formErrors });

        return {
            isValid: errorCount <= 0,
            errorCount: errorCount,
            errors: formErrors[element.name]
        }
    }

    validAndGetValue() {
        const { element } = this.state;

        const validationResult = this.valid();
        const result = {
            name: element.name,
            isValid: validationResult.isValid,
        }

        if (validationResult.isValid) {
            const value = element.type === "select" ? this.state.form[element.name].value : this.state.form[element.name]
            return { ...result, value: value }
        }

        return { ...result, errors: validationResult.errors }
    }

    getValue() {
        const { element } = this.state;
        const result = {
            name: element.name,
        }

        const value = element.type === "select" ?
            this.state.form[element.name] ?
                this.state.form[element.name].value : null :
            this.state.form[element.name]

        return { ...result, value: value };
    }

    render() {
        const { element, formErrors } = this.state;
        const { isDisabled } = this.props;

        return (
            <>
                <label>{element.required && "*"}{element.label}</label>
                {this.renderRequiredInputTypeField(element.name, element.type, formErrors[element.name], isDisabled, element.options)}
            </>
        );
    }

}

export default FormElement;

