import React from "react";
import { Row, Col } from "shards-react";
import PasswordChange from "../components/password-change/PasswordChange";

export class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeAttemptUuid: "",
    }
  }

  static getDerivedStateFromProps(props, state) {
    var changeAttemptUuid = props.match.params.uuid;

    if (changeAttemptUuid !== state.changeAttemptUuid) {
      return { changeAttemptUuid: changeAttemptUuid }
    }

    return null;
  }

  render() {
    const {
      changeAttemptUuid
    } = this.state;

    const renderPasswordChange = () => <PasswordChange changeAttemptUuid={changeAttemptUuid} />;

    return (
      <Row>
        <Col xs={12} md={{ size: 4, offset: 4 }}>
          {renderPasswordChange()}
        </Col>
      </Row>
    );
  }
}
