import React from "react";
import {
  Container,
  Row
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import BillingHistoryPanel from "../components/billing-history/BillingHistoryPanel";

class BillingHistory extends React.Component {

  render() {
    const { siteTitle } = this.props;

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={siteTitle} subtitle="Dashboard" className="text-sm-left mb-3" />
        </Row>
        <BillingHistoryPanel />
      </Container>
    )
  }

}

export default BillingHistory;
