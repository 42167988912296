import React, { Component } from 'react';
import { Container, Row, Col } from "shards-react";
import API from "../api/AxiosConfiguration";
import * as Constants from '../constants';
import {Store} from '../flux'
import {
  Button,
  ButtonGroup
} from "shards-react";

import { SECURE_STRING } from "../utils/Patterns";
import PageTitle from "../components/common/PageTitle";
import AllTaskDisplay from "../components/help-desc/AllTaskDisplay"
import AddTaskForm from '../components/help-desc/forms/AddTaskForm';


class HelpDesc extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      isSocAdmin: Store.getUserRole(),
      tasks: []
    }


  }

  viewButton = (id, status) => {
    if (this.state.isSocAdmin===Constants.Roles.SOCADMIN)
        return (
            <div>
                <ButtonGroup>
                    <Button onClick={() => this.reportsTable.viewDetails(id, status)}>Wyświetl</Button>
                    {(status !== "done") ? <Button theme="success" onClick={() => this.reportsTable.endTask(id)}>Zakończ</Button> : <></>}
                </ButtonGroup>
            </div>
        )
    else
        return (
            <div>
                <Button onClick={() => this.reportsTable.viewDetails(id)}>Wyświetl</Button>
            </div>
        )
}

viewStatus = (status) => {
    switch (status){
        case "done": return 'Zakończony'
        case "todo": return 'W trakcie'
        default: return null
    }
}




  fetchAllReports = async () => {
    const tempArr = [];
    API.get(Constants.REPORTS_URL)
    .then(response => {
            const data = response.data;

            data.forEach(report => {
                const tempReport = {
                  value:{
                    id: report.jiraId,
                    sortStatus: report.status,
                    category: report.category,
                    title: report.title,
                    status: (this.viewStatus(report.status)),
                    action: (this.viewButton(report.jiraId, report.status))
                  }
                };
                tempArr.push(tempReport);
            });


            // tempArr.sort((a, b) => {
            //     if(a.value.sortStatus < b.value.sortStatus) return 1;
            //     else return -1;
            // });


            this.setState({
              tasks: tempArr
            });

    });
  }

  updateReportTable = () => {
    this.fetchAllReports();
  }

  searchReports = (query) => {
    const isQuerySecure = SECURE_STRING.test(query);

    if(query.length > 0 && isQuerySecure){
      this.fetchSearchedReports(query);
    } else {
      this.updateReportTable();
    }
  }

  fetchSearchedReports = (query) => {
    const tempArr = [];
    API.get(`${Constants.REPORTS_URL}/search?q=${query}`)
    .then(response => {
            const data = response.data;

            data.forEach(report => {
                const tempReport = {
                  value:{
                    id: report.jiraId,
                    sortStatus: report.status,
                    category: report.category,
                    title: report.title,
                    status: (this.viewStatus(report.status)),
                    action: (this.viewButton(report.jiraId, report.status))
                  }
                };
                tempArr.push(tempReport);
            });


            // tempArr.sort((a, b) => {
            //     if(a.value.sortStatus < b.value.sortStatus) return 1;
            //     else return -1;
            // });


            this.setState({
              tasks: tempArr
            });

    });
  }

  componentDidMount() {
    this.updateReportTable();
  }

  render() {
    return (
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title="Pomoc techniczna" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col lg="4">
              <AddTaskForm refresh={this.updateReportTable}/>
            </Col>
            <Col lg="8">
              <AllTaskDisplay childRef={ref => (this.reportsTable = ref)} refresh={this.updateReportTable} tasks={this.state.tasks} search={this.searchReports}></AllTaskDisplay>
            </Col>
          </Row>
        </Container>
    );
  }

}
export default HelpDesc;
