import React from 'react';
import { Container, Row, Col } from "shards-react";
import DashboardModal from "../components/dashboard/modals/DashboardModal";
import { Store, Constants, Dispatcher } from "../flux";

import PageTitle from "../components/common/PageTitle";
import DashboardSelectDashboard from "../components/dashboard/DashboardSelectDashboard" ;
import DashboardGridLayout from "../components/dashboard/DashboardGridLayout";

class Dashboard extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.GridLayoutRef = React.createRef();

        this.state = {
                layout: [],
                dashboardModalVisible: false,
                formType: "",
                dashboards: [],
                selectedDashboard: null,
                isMobileView: false,
                tileUuid: '',
            }

            this.onLayoutChange = this.onLayoutChange.bind(this);
            this.onChange = this.onChange.bind(this);
            this.updateDashboardGridLayoutTiles = this.updateDashboardGridLayoutTiles.bind(this);
            this.onDashboardChange = this.onDashboardChange.bind(this);
        }
      
        componentWillMount() {
          Store.addToggleDashboardModalListener(this.onChange);
          Store.addUpdateDashboadListener(this.onDashboardChange);
        }
      
        componentWillUnmount() {
          Store.removeToggleDashboardModalListener(this.onChange);
          Store.removeUpdateDashboadListener(this.onDashboardChange);
          this._isMounted = false;
        }

        onDashboardChange() {
          const selectedDashboard = Store.getSelectedDashboard();
          this.setState({
            selectedDashboard
          })
        }
      
        onChange() {
            if(this._isMounted)
                this.setState({
                    dashboardModalVisible: Store.getDashboardModalState(),
                    selectedDashboard: Store.getSelectedDashboard(),
                    formType: Store.getDashboardFormType(),
                    tileUuid: Store.getSelectedTile(),
                    isMobileView: Store.isMobileView()
                });
        }

    componentDidMount() {
        this._isMounted = true;
    }

    onLayoutChange(layout) {
        this.setState({ layout: layout });
      }

    toggleModal(formType = "", uuid = null) {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_DASHBOARD_MODAL,
          payload: {
              formType,
              uuid
          }
      });
        
    }


    updateDashboardGridLayoutTiles(form) {
      const tileConfig = form,
            dashboardUuid = Store.getSelectedDashboard().value;

            this.GridLayoutRef.current.configureTile( { tileConfig, dashboardUuid }, this.toggleModal );
    }

    render() {
      const { dashboardModalVisible, formType, isMobileView, selectedDashboard, tileUuid } = this.state;
       
        return (
        <Container fluid className="main-content-container px-4 d-flex flex-column">
          <Row noGutters className="page-header py-4">
              <PageTitle title="Dashboard" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row> 
          { isMobileView ? 
          <Row>
            <Col>
              <DashboardSelectDashboard toggle={this.toggleModal}  isMobileView={isMobileView}/>
            </Col>
          </Row>
          : null  }
          <Row className="flex-grow-1 py-2" style={ { minHeight: '85vh' } }>
            <Col>
              { (selectedDashboard !== '' && selectedDashboard !== undefined && selectedDashboard !== null) ?
                <DashboardGridLayout ref={this.GridLayoutRef} onLayoutChange={this.onLayoutChange} toggle={this.toggleModal} history={this.props.history}/>
                :
                <div style={ { width: '100%', height: '100%', position: 'relative' } }>
                  <p style={ { position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -30%)', textAlign: 'center', opacity: '0.7', fontSize: '1.5rem' } }>Wybierz lub utwórz nowy dashboard, aby wyświetlić jego zawartość</p>
                </div>
              }
            </Col>
          </Row>

          <DashboardModal 
          open={dashboardModalVisible}
          toggle={this.toggleModal}
          formType={formType}
          tile={tileUuid}
          setTileConfig={this.updateDashboardGridLayoutTiles} />


        </Container>
        );
    }
}

export default Dashboard;