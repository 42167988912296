import React from 'react';
import { ConfirmModal } from "../../../common/Modal";
import * as AppConstants from '../../../../constants';
import API from "../../../../api/AxiosConfiguration";
import * as socToast from '../../../../utils/SocToast';

class DeleteCategoryModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteCategory = this.deleteCategory.bind(this);
    }

    deleteCategory(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(AppConstants.PC_SAFETICA_CATEGORY_CONFIGURATION_URL + "/" + uuid)
        .then(result => {
            if(result.status === 200) {
                onSuccess();
                socToast.success("Powodzenie", "Kategoria została usunięta.");
                toggleFunction();
            }
        })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz usunąć kategorie?"
                    open={open}
                    toggle={toggle}
                    submitAction={this.deleteCategory}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie wszystkich danych o kategorii.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          
            </ConfirmModal>
        )
    }
    
}

export default DeleteCategoryModal;
