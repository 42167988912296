import React, { Component } from "react";
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import API from "../../api/AxiosConfiguration";
import * as AppConstants from "../../constants";
import { Store, Dispatcher, Constants } from "../../flux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import * as SocToast from '../../utils/SocToast';
import Tooltip from '@material-ui/core/Tooltip';
import { hasValueUrlOrHtmlTag } from '../../utils/Patterns'


class DashboardSelectDashboard extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            dashboards: [],
            dashboard: null
        };

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.toggleDeleteDashboardModal = this.toggleDeleteDashboardModal.bind(this);
        this.fetchDashboards = this.fetchDashboards.bind(this);
        this.onFluxChange = this.onFluxChange.bind(this);
    }

    componentWillMount() {
        Store.addUpdateDashboadListener(this.onFluxChange);
    }

    componentWillUnmount() {
        Store.removeUpdateDashboadListener(this.onFluxChange);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchDashboards();
    }

    onFluxChange() {
        var selectedDashboard = Store.getSelectedDashboard();
        var availableDashboards = Store.getDashboardList();

        this.setState({
            dashboards: availableDashboards,
            dashboard: selectedDashboard
        })
    }

    fetchDashboards() {
        Dispatcher.dispatch({ actionType: Constants.UPDATE_DASHBOARD_LIST })
    }


        selectChangeHandler = (selected, event) => {
            Dispatcher.dispatch({
                actionType: Constants.SET_SELECTED_DASHBOARD,
                payload: {
                    selectedDashboard: selected
                }
            });
        }

    toggleDeleteDashboardModal(uuid) {
        Dispatcher.dispatch({
            actionType: Constants.SET_DASHBOARD_TO_DELETE,
            payload: {
                formType: 'deleteDashboard',
                uuid
            }
        })
    }


    render() {
        const { isMobileView } = this.props;
        const { dashboard } = this.state;
        const { SingleValue } = components;

        const DashboardOptionWithIcons = props => {
            const { innerProps, innerRef, children } = props;

            const optionStyles = {
                backgroundColor: Boolean(innerRef) ? "#DEEBFF" : "transparent",
                display: "flex",
                justifyContent: "space-between"
            }

            return (
                <div ref={innerRef} {...innerProps} className="react-select-option" style={optionStyles} >
                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>{children}</div>
                     <div className="react-select-option-trash-container">
                        { props.data.owned && <FontAwesomeIcon icon={faTimes} size="lg" style={ { cursor: 'pointer' } } onClick={(e) => { e.stopPropagation(); this.toggleDeleteDashboardModal(props.data.value)}} />}
                    </div>
                </div>
            );
        };

        const ValueOption = (props) => (
            <SingleValue {...props} className="react-select-single-value">
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>{props.children}</div>

            </SingleValue>
        );

        const createOption = (label) => {
            return { label, value: "none" };
        }

        const isValidNewOption = (inputValue) => {
            const processedInputValue = inputValue ||  ""
            return processedInputValue.length >= 3 && processedInputValue.length <= 64 && !hasValueUrlOrHtmlTag(processedInputValue)
        }

        const handleCreateDashboard = (inputValue) => {
            const newOption = createOption(inputValue);


            if(Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
                API.post(AppConstants.DASHBOARDS_URL, { dashboardName: newOption.label, sharedFor: [] }).then(result => {
                    if(result.status === 201) {
                        SocToast.success("Pomyślnie utworzono nowy dashboard.");
                        Dispatcher.dispatch({
                            actionType: Constants.UPDATE_DASHBOARD_LIST,
                            payload: {
                                dashboardName: newOption.label
                            } });
                    } else {
                        SocToast.error("Nie udało się utworzyć dashboardu", "Spróbuj ponownie za chwilę, lub skontaktuj się z administratorem.");
                    }

                }).catch(e => {
                    SocToast.error("Nie udało się utworzyć dashboardu", "Spróbuj ponownie za chwilę, lub skontaktuj się z administratorem.");
                });
            } else {
                Dispatcher.dispatch({
                    actionType: Constants.SET_NEW_DASHBOARD_NAME,
                    payload: {
                        newDashboardName: newOption.label
                    }
                })
            }


        }

        return (

                    <div className="d-flex align-items-center py-2" style={ { width: isMobileView ? '100%' : '45%' } }>
                            <CreatableSelect
                                name="dashboard"
                                value={dashboard}
                                onChange={this.selectChangeHandler}
                                isValidNewOption={isValidNewOption}
                                placeholder="Wybierz dashboard"
                                options={this.state.dashboards}
                                onCreateOption={handleCreateDashboard}
                                noOptionsMessage={() => "Brak dostępnych dashboardów"}
                                defaultOptions={this.state.dashboard}
                                components={{ Option: DashboardOptionWithIcons, SingleValue: ValueOption }}
                                className='react-select-container'
                                classNamePrefix="react-select" />

                        <Tooltip title="Aby utworzyć nowy dashboard wpisz jego nazwę w polu wybierania, a następnie wybierz odpowiednią opcję na liście" placement="bottom" arrow>
                            <span style={ { marginLeft: '1rem' } }>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </span>
                        </Tooltip>
                    </div>
        );
    }

}

export default DashboardSelectDashboard;
