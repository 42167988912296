import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button } from "shards-react";
import LogRetentionForm from './forms/LogRetentionForm';

class LogRetention extends React.Component {

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    onSaveClick() {
        this.formRef.current.submitForm();
    }

    render() {
        return (
            <Card>
                <CardHeader className="border-bottom d-inline">
                    <h6 className="m-0">Czas przechowywania logów</h6>
                </CardHeader>
                <CardBody>
                    <LogRetentionForm ref={this.formRef} />
                </CardBody>
                <CardFooter>
                    <Button onClick={this.onSaveClick} outline theme="info" className="mb-2 mr-1 float-right">Zapisz</Button>
                </CardFooter>
            </Card>
        );
    }

}

export default LogRetention;
