import React from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    ButtonGroup,
    InputGroup,
    FormInput,
    InputGroupAddon
} from "shards-react";

import { faFileDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { trackPromise } from 'react-promise-tracker';
import BillingHistoryTable from "./tables/BillingHistoryTable";
import DeleteInvoiceModal from "./modals/DeleteInvoiceModal";
import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import * as SocToast from '../../utils/SocToast';
import SetArchivePasswordModal from './modals/SetArchivePasswordModal';

class BillingHistoryPanel extends React.Component {

    constructor() {
        super();

        this.state = {
            isDeleteInvoiceModalShow: false,
            invoices: [],
            checkboxes: {},
            filteredInvoices: [],
            requiredFilterOptions: {},
            selectedInvoiceUUIDs: [],
            filters: {},
            isPasswordModalShow: false,
            randomKeyForForceUpdate: Math.random()
        }

        this.toggleDeleteInvoiceModal = this.toggleDeleteInvoiceModal.bind(this);
        this.updateBillingHistory = this.updateBillingHistory.bind(this);
        this.getInvoicePdf = this.getInvoicePdf.bind(this);
        this.toggleReportPasswordModal = this.toggleReportPasswordModal.bind(this);
    }

    componentDidMount() {
        this.updateBillingHistory();
    }

    updateBillingHistory() {
        API.post(Constants.BILLING_HISTORY_URL).then((result) => {
            const data = result.data;

            const newCheckboxes = {};

            data.forEach(invoice => {
                const invoiceUuid = invoice.uuid.split(' ').join('_').split('/').join('s');
                newCheckboxes[invoiceUuid] = false;
            });

            this.setState({
                invoices: data,
                checkboxes: newCheckboxes
            });
        });
    }

    getInvoicePdf() {

        const checkboxesFiltered = Object.entries(this.state.checkboxes).filter(([key, value]) => value === true);
        const checkboxesFilteredObj = Object.fromEntries(checkboxesFiltered);

        const finalCheckedUuids = Object.keys(checkboxesFilteredObj).map(uuid => uuid.split('_').join(' ').split('s').join('/'));

        trackPromise(API({
            url: Constants.BILLING_HISTORY_URL + "/pdf",
            method: 'POST',
            data: {
                invoiceIds: finalCheckedUuids
            },
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const [, filename] = response.headers['content-disposition'].split('filename=');
            const link = document.createElement('a');
            link.href = url;

            link.download = filename.slice(1, -1);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            var response = error.response;
            if (response) {
                if (response.status === 423) {
                    this.toggleReportPasswordModal(() => {
                        SocToast.success("Nadano hasło do archiwum", "Za chwilę plik/i zostaną pobrane.")
                        this.getInvoicePdf()
                    });
                }
            }
        }));

    }

    searchQuery(query) {
        const pattern = /(\w+(\s)*\d+\/\d+)/g;
        const uuids = query.match(pattern);
        const tempArr = [];
        this.state.invoices.forEach(invoice => {
            uuids.forEach(uuid => {
                if (invoice.uuid.toLowerCase().includes(uuid.toLowerCase()) || invoice.uuid.split(' ').join('').toLowerCase().includes(uuid.toLowerCase())) {
                    tempArr.push(invoice);
                }
            });
        });

        this.setState({ filteredInvoices: tempArr });
    }

    clearSearch(query) {
        if (query === "") {
            this.setState({ filteredInvoices: this.state.invoices });
        }
    }

    handleCheck = (e, uuid) => {
        this.setState({
            checkboxes: {
                ...this.state.checkboxes,
                [uuid]: !this.state.checkboxes[uuid]
            }
        });
    }

    deselectAllCheckboxes = () => {
        const newCheckboxes = {};

        Object.keys(this.state.checkboxes).forEach(property => {
            newCheckboxes[property] = false;
        });

        this.setState({ checkboxes: newCheckboxes });
    }

    toggleDeleteInvoiceModal() {
        this.setState({
            isDeleteInvoiceModalShow: !this.state.isDeleteInvoiceModalShow,
        });
    }

    toggleReportPasswordModal(action = () => { }) {
        this.setState({
            isPasswordModalShow: !this.state.isPasswordModalShow,
            continuationAction: action,
        })
    }

    onPasswordSet() {
        this.state.continuationAction();
        SocToast.success("Hasło ustawione", "Od tej chwili wszystkie pliki będą wymagały podanie tego hasła.");
        this.toggleReportPasswordModal();
    }

    render() {
        const { isDeleteInvoiceModalShow, selectedInvoiceUUIDs, invoices, filteredInvoices, isPasswordModalShow, checkboxes } = this.state;

        const showInvoiceTable = () => (
            <BillingHistoryTable className="clearfix"
                invoices={(filteredInvoices.length > 0) ? filteredInvoices : invoices}
                handleCheck={this.handleCheck}
                toggleDeleteInvoiceModal={this.toggleDeleteInvoiceModal}
                selectedInvoiceUUIDs={selectedInvoiceUUIDs}
                checkboxes={checkboxes}
            />
        )

        const showNoInvoiceFoundMessage = () => (
            <Row className="pt-2" style={{ justifyContent: "center" }}>
                <h6>Brak Rachunków</h6>
            </Row>
        )

        return (
            <Row className="mt-2 mb-2">
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <Row className="mr-0 ml-0">
                                <Col xs="8" className="d-flex justify-content-start">
                                    <h6 className="d-flex align-items-center m-0">Faktury</h6>
                                </Col>
                                <Col xs="4" className="d-flex justify-content-end">
                                    <InputGroup>
                                        <FormInput placeholder="np. id1 id2 id3" onInput={e => this.clearSearch(e.target.value)} />
                                        <InputGroupAddon type="append">
                                            <Button theme="secondary" onClick={e => this.searchQuery(e.target.parentNode.previousSibling.value)}>Szukaj</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {invoices.length ? showInvoiceTable() : showNoInvoiceFoundMessage()}
                        </CardBody>
                        <Row className="mr-0 ml-0">
                            <Col xs="12" className="d-flex justify-content-end">
                                <div>
                                    <ButtonGroup className="mr-2 mb-3">
                                        <Button theme="info" onClick={() => this.getInvoicePdf()} className="d-flex align-middle" disabled={!Object.values(this.state.checkboxes).includes(true)}><FontAwesomeIcon icon={faFileDownload} className="mr-1" />Pobierz</Button>
                                        <Button theme="info" onClick={() => this.toggleDeleteInvoiceModal()} disabled={!Object.values(this.state.checkboxes).includes(true)} className="d-flex align-middle" ><FontAwesomeIcon icon={faTrash} className="mr-1" />Usuń</Button>
                                    </ButtonGroup>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <DeleteInvoiceModal open={isDeleteInvoiceModalShow} onSuccess={this.updateBillingHistory}
                    uuids={selectedInvoiceUUIDs} toggle={this.toggleDeleteInvoiceModal} checkboxes={checkboxes} />
                <SetArchivePasswordModal open={isPasswordModalShow}
                    toggle={this.toggleReportPasswordModal} onSuccess={this.onPasswordSet} />
            </Row>
        );
    }
}

export default BillingHistoryPanel;
