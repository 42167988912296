import React from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "shards-react";
import Select from 'react-select';

import PageTitle from "../components/common/PageTitle";
import SafetyProcedureManagementPanel from "../components/safety-procedures/SafetyProcedureManagementPanel";
import { getCustomersForSelect } from '../api/ApiService';
import { Dispatcher, Store, Constants } from '../flux';
import * as AppConstants from '../constants';

class SafetyProcedures extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            form: {
                manager: ""
            },
            managers: [],
        }

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.setManager = this.setManager.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateComponent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectChangeHandler = (selected, event) => {
        var { form } = this.state;
        form[event.name] = selected;
        if (this._isMounted) {
            this.setManager(selected);
        }
    }

    setManager(manager) {
        var { form } = this.state;
        this.setState({
            form: {
                ...form,
                manager: manager
            }
        })

        Dispatcher.dispatch({
            actionType: Constants.SET_SELECTED_MANAGER,
            payload: {
                selectedManager: manager
            }
        })
    }

    updateComponent() {
        const { isAdminAtLeast } = this.state;
        if (isAdminAtLeast) {
            getCustomersForSelect().then(customers => {
                if (this._isMounted)
                    this.setState({ managers: customers })
            })
        }
    }

    render() {
        const { siteTitle } = this.props;
        const { isAdminAtLeast, isAdmin, managers } = this.state;
        const { manager } = this.state.form;

        const managerIsSelected = Boolean(manager);

        const defaultSelectProps = {
            className: 'react-select-container',
            classNamePrefix: "react-select",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        const renderUserSelect = () => (
            <Col className="align-self-end">
                <div className="text-sm-right text-md-right text-center mb-3 mb-sm-0 col-md-8 offset-md-4">
                    <label>Fitruj po użytkowniku</label>
                    <Select
                        {...defaultSelectProps}
                        name="manager"
                        value={manager}
                        onChange={this.selectChangeHandler}
                        placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz użytkownika"}
                        isClearable={true}
                        options={managers} />
                </div>
            </Col>
        )

        const renderContent = () => (
            <SafetyProcedureManagementPanel onCreate={this.setManager} />
        )

        const renderSelectCustomerRequiredMessage = () => (
            <Row className="mt-2 mb-2">
                <Col>
                    <Card>
                        <CardBody>
                            <div className="text-center">
                                Wybierz użytkownika, żeby uzyskać dostęp do zawartości.
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )

        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <Col>
                        <PageTitle title={siteTitle} subtitle="Dashboard" className="text-sm-left mb-3" />
                    </Col>

                    {isAdminAtLeast && renderUserSelect()}
                </Row>

                {(managerIsSelected || !isAdminAtLeast || isAdmin) ?
                    renderContent() : renderSelectCustomerRequiredMessage()}
            </Container>
        )
    }

}

export default SafetyProcedures;
