import MinValidator from './MinValidator';
import MaxValidator from './MaxValidator';
import RegexpValidator from './RegexpValidator';

class Validator {

    static getValidator(elementValidator) {
        switch (elementValidator.type) {
            case 'MIN': return new MinValidator(elementValidator.value);
            case 'MAX': return new MaxValidator(elementValidator.value);
            case 'REGEXP': return new RegexpValidator(elementValidator.value);
            default: return null;
        }
    }

}

export default Validator;