import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classNames from "classnames";

import "../../assets/multi-state-icon-checkbox.css"

class MultiStateIconCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: [{ value: "NONE", icon: null, iconColor: "white", backgroundColor: "white" }],
        };

        this.nextValue = this.nextValue.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.values) !== JSON.stringify(state.values)) {
            return {
                values: [{ value: "NONE", icon: null, iconColor: "white", backgroundColor: "white" }, ...props.values]
            }
        }
    
        return null;
    }

    nextValue(event, currentValue) {
        const { values } = this.state;
        const { onChange } = this.props;
        var valueIndex;
       
        if (currentValue) {
            valueIndex = values.findIndex(value => currentValue.value === value.value);
        } else {
            valueIndex = 0;
        }
       
        if (valueIndex + 1 >= values.length) {
            valueIndex = 0;
        } else if (valueIndex < 0) {
            valueIndex = 1;
        } else {
            valueIndex += 1;
        }

        onChange(values[valueIndex], event)
    }

    render() {
        const { value } = this.props;

        const checkboxClasses = classNames(
            "multi-state-checkbox",
            this.props.invalid && "invalid"
        )

        return (
            <div onClick={(event) => this.nextValue(event, value)}
                className={checkboxClasses}
                style={{ backgroundColor: value && value.backgroundColor ? value.backgroundColor : "none", marginBottom: "-5px" }}
                tabIndex="120">
                {value && value.icon &&
                    <FontAwesomeIcon className="icon" style={{ color: value.iconColor }} icon={value.icon} />
                }
            </div>
        );
    }

}

export default MultiStateIconCheckbox;