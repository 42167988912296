import * as Patterns from './../../../utils/Patterns';

class RegexpValidator {

    constructor(pattern) {
        this.pattern = pattern;
        this.regexp = null;
    }

    valid(value) {
        const pattern = Patterns.getPatternByName(this.pattern);
        if (pattern != null) {
            this.regexp = pattern;
        } else {
            this.regexp = new RegExp(this.pattern);
        }

        return !value || this.regexp.test(value);
    }

}

export default RegexpValidator;