import React from 'react';
import { Row, Col, ListGroup, ListGroupItem, Button } from 'shards-react';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinus } from '@fortawesome/free-solid-svg-icons';
import ConfirmPolicyModal from './../modals/ConfirmPolicyModal';
import DeletePolicyModal from './../modals/DeletePolicyModal';

class SafeticaConfigurationPolicyTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isConfirmPolicyModalShow: false,
            isDeletePolicyModalShow: false,
            selectedPolicyUuid: '',
            policies: []
        };

        this.updateComponent = this.updateComponent.bind(this);
        this.fetchSafeticaPolicies = this.fetchSafeticaPolicies.bind(this);
        this.toggleConfirmPolicyModal = this.toggleConfirmPolicyModal.bind(this);
        this.toggleDeletePolicyModal = this.toggleDeletePolicyModal.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    toggleConfirmPolicyModal(uuid = "") {
        this.setState({
            isConfirmPolicyModalShow: !this.state.isConfirmPolicyModalShow,
            selectedPolicyUuid: uuid
        })
    }

    toggleDeletePolicyModal(uuid = "") {
        this.setState({
            isDeletePolicyModalShow: !this.state.isDeletePolicyModalShow,
            selectedPolicyUuid: uuid
        })
    }

    fetchSafeticaPolicies() {
        return API.get(AppConstants.PC_SAFETICA_POLICY_CONFIGURATION_URL);
    }

    updateComponent() {
        this.props.onFetchStart();
        this.fetchSafeticaPolicies()
            .then(result => {
                this.setState({
                    policies: result.data.policies
                })
                this.props.onFetchComplete();
            })
    }

    render() {
        const { policies, isConfirmPolicyModalShow, isDeletePolicyModalShow, selectedPolicyUuid } = this.state;

        const paginationOptions = {
            custom: true,
            totalSize: policies.length,
            sizePerPage: 10
        };

        const policyColumns = [{
            dataField: 'name',
            align: "left",
            headerAlign: "left",
            text: "Nazwa",
        }, {
            dataField: 'policyType',
            align: "left",
            headerAlign: "left",
            text: "Typ aplikacji",
        }, {
            dataField: 'zone',
            align: "center",
            headerAlign: "center",
            text: "Strefa",
            formatter: (cell, row) => cell ? cell : '-',
        }, {
            dataField: 'policyData',
            align: "right",
            headerAlign: "right",
            text: "Dane",
            formatter: (cell, row) => (
                Array.isArray(cell) ? cell
                    .map(value => AppConstants.SAFETICA_POLICY_DATA[value] ? AppConstants.SAFETICA_POLICY_DATA[value] : cell)
                    .map(value => (<div>{value}</div>)) : cell
            ),
        }, {
            dataField: 'actions',
            align: "right",
            headerAlign: "right",
            text: 'Akcje',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => (
                <div className="bootstrap-table-actions-col">
                    <Button size="sm" onClick={() => this.toggleConfirmPolicyModal(row.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>

                    <Button size="sm" theme="danger" className="ml-1" onClick={() => this.toggleDeletePolicyModal(row.id)}>
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                </div>
            ),
        }]

        const renderPolicies = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={policies}
                                columns={policyColumns}
                                classes="table mb-0"
                                bordered={false}
                                hover
                                rowStyle={{ height: '10px' }}
                                wrapperClasses="table-responsive"
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderPoliciesFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <h6>Brak danych</h6>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <React.Fragment>
                <Button onClick={this.toggleConfirmPolicyModal} outline theme="info" className="mb-3 mr-1 mt-2">
                    Dodaj polityke
                </Button>

                {policies.length ? renderPolicies() : renderPoliciesFetchError()}

                <ConfirmPolicyModal open={isConfirmPolicyModalShow} uuid={selectedPolicyUuid}
                    toggle={this.toggleConfirmPolicyModal} onSuccess={this.updateComponent} />
                <DeletePolicyModal open={isDeletePolicyModalShow} uuid={selectedPolicyUuid}
                    toggle={this.toggleDeletePolicyModal} onSuccess={this.updateComponent} />
            </React.Fragment>
        );
    }

}

export default SafeticaConfigurationPolicyTable;