import axios from 'axios';
import * as AppConstants from '../constants';
import { Store } from '../flux'
import * as SocToast from '../utils/SocToast';

const instance = axios.create({
    baseURL: AppConstants.API_URL,
});

instance.defaults.xsrfCookieName = 'XSRF-TOKEN';
instance.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';
instance.defaults.withCredentials = true;

instance.interceptors.request.use((config) => {
    if (Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
        config.params = {
            company: Store.getSelectedCompany() ? Store.getSelectedCompany().value : "",
            manager: Store.getSelectedManager() ? Store.getSelectedManager().value : "",
            ...config.params
        }
    }

    if (Store.getUserRole() === AppConstants.Roles.ADMIN) {
        config.params = {
            manager: Store.getSelectedManager() ? Store.getSelectedManager().value : "",
            ...config.params
        }
    }

    return config
})

const pathIgnoringUnathorized = ["/login", "/forgot-password", "/dashboard"]

instance.interceptors.response.use(
    res => res,
    err => {
        if (err.response) {
            if (err.response.status === 403 || err.response.status === 401) {

                if(!pathIgnoringUnathorized.includes(window.location.pathname)) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("companySelected");
                    window.location = '/login';
                }

            }

            if (err.response.status === 500) {
                SocToast.error("Błąd serwera", "Spróbuj ponownie później lub skontaktuj się z administatorem")
            }

            if (err.response.status === 503) {
                const errorCode = err.response.data.code;
                if (errorCode) {
                    const message = AppConstants.ERROR_CODES[errorCode];
                    SocToast.error("Nie odnaleziono konfiguracji", message ? message : errorCode);
                } else {
                    SocToast.error("Serwis niedostępny", "Spróbuj ponownie później lub skontaktuj się z administatorem")
                }
            }

            return Promise.reject(err);
        }
    }
)

export default instance;
