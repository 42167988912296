// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import PcDeviceDeviceLock from "./views/PcDeviceDeviceLock";
import PcDeviceSafetica from "./views/PcDeviceSafetica";

import PcDeviceDeviceLockDetails from "./views/PcDeviceDeviceLockDetails";
import PcDeviceSafeticaDetails from "./views/PcDeviceSafeticaDetails";
import PcEventHistory from "./views/PcEventHistory";
import BillingHistory from "./views/BillingHistory";

import NetworkDevice from "./views/NetworkDevice";
import NetworkDeviceDetails from "./views/NetworkDeviceDetails";
import NetworkEventHistory from "./views/NetworkEventHistory";

import AuthenticatorToken from "./views/AuthenticatorToken";
import Callback from './Callback';
import ControlPanelEventHistory from "./views/ControlPanelEventHistory";
import Customer from "./views/Customer";
import Company from "./views/Company";
import CompanySettings from './views/CompanySettings';
import Profile from "./views/Profile";
import EditProfile from "./views/EditProfile";
import Dashboard from "./views/Dashboard";
import NotFoundError from "./views/NotFoundError";
import SafetyProcedures from "./views/SafetyProcedures";
import ControlPanel from "./views/ControlPanel";
import ControlPanelDetails from "./views/ControlPanelDetails";
import NotificationManagement from "./views/NotificationManagement";
import Chat from "./views/Chat";
import Notification from "./views/Notification";
import HelpDesc from "./views/HelpDesc";

import { Roles } from "./constants"
import { Privileges } from './constants/index';

import React from "react";
import { Redirect } from "react-router-dom";

export default [{
    path: "/",
    exact: true,
    layout: DefaultLayout,
    group: "dashboard",
    component: () => <Redirect to="/dashboard"/>,
    siteTitle: "Dashboard",
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard,
    group: "dashboard",
    siteTitle: "Dashboard",
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/pc-device-dl",
    layout: DefaultLayout,
    component: PcDeviceDeviceLock,
    siteTitle: "Bezpieczeństwo sprzętowe",
    group: "pcDevices",
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.DEVICE_SECURITY_DEVICELOCK]
},
{
    path: "/pc-device-s",
    layout: DefaultLayout,
    component: PcDeviceSafetica,
    siteTitle: "Bezpieczeństwo sprzętowe",
    group: "pcDevices",
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.DEVICE_SECURITY_SAFETICA]
},
{
    path: "/network-device",
    layout: DefaultLayout,
    component: NetworkDevice,
    group: "networkDevices",
    siteTitle: "Bezpieczeństwo sieciowe",
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.NETWORK_SECURITY]
},
{
    path: "/safety-procedures",
    layout: DefaultLayout,
    component: SafetyProcedures,
    group: "safetyProcedures",
    siteTitle: "Procedury Bezpieczeństwa",
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/security-control-panel",
    layout: DefaultLayout,
    component: ControlPanel,
    group: "securityControlPanel",
    siteTitle: "Bezpieczeństwo fizyczne",
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.PHYSICAL_SECURITY]
},
{
    path: "/notification-management",
    layout: DefaultLayout,
    group: "notificationManagement",
    component: NotificationManagement,
    siteTitle: "Zarządzanie Powiadomieniami",
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/billing-history",
    layout: DefaultLayout,
    component: BillingHistory,
    siteTitle: "Historia Zamówień",
    authorities: [Roles.SOCADMIN, Roles.ADMIN]
},
{
    path: "/authenticator-token",
    layout: DefaultLayout,
    component: AuthenticatorToken,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/companies",
    layout: DefaultLayout,
    group: "companies",
    component: Company,
    authorities: [Roles.SOCADMIN]
},
{
    path: "/company/settings",
    layout: DefaultLayout,
    group: "companySettings",
    component: CompanySettings,
    authorities: [Roles.SOCADMIN, Roles.ADMIN]
},
{
    path: "/customers",
    layout: DefaultLayout,
    group: "customers",
    component: Customer,
    authorities: [Roles.SOCADMIN, Roles.ADMIN]
},
{
    path: "/pc-event-history",
    layout: DefaultLayout,
    group: "pcDevice",
    component: PcEventHistory,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.DEVICE_SECURITY_DEVICELOCK, Privileges.DEVICE_SECURITY_SAFETICA]
},
{
    path: "/network-event-history",
    layout: DefaultLayout,
    group: "networkDevices",
    component: NetworkEventHistory,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.NETWORK_SECURITY]
},
{
    path: "/control-panel-event-history",
    layout: DefaultLayout,
    group: "securityControlPanel",
    component: ControlPanelEventHistory,
    privileges: [Privileges.PHYSICAL_SECURITY]
},
{
    path: "/pc-details-dl",
    layout: DefaultLayout,
    group: "pcDevices",
    component: PcDeviceDeviceLockDetails,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.DEVICE_SECURITY_DEVICELOCK]
},
{
    path: "/pc-details-s",
    layout: DefaultLayout,
    group: "pcDevices",
    component: PcDeviceSafeticaDetails,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.DEVICE_SECURITY_SAFETICA]
},
{
    path: "/network-details",
    layout: DefaultLayout,
    group: "networkDevices",
    component: NetworkDeviceDetails,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER],
    privileges: [Privileges.NETWORK_SECURITY]
},
{
    path: "/control-panel-details",
    layout: DefaultLayout,
    group: "securityControlPanel",
    component: ControlPanelDetails,
    privileges: [Privileges.PHYSICAL_SECURITY]
},
{
    path: "/callback",
    layout: DefaultLayout,
    component: Callback,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/user-profile",
    layout: DefaultLayout,
    component: Profile,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/edit-user-profile",
    layout: DefaultLayout,
    component: EditProfile,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/chat",
    layout: DefaultLayout,
    component: Chat,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/notifications",
    layout: DefaultLayout,
    component: Notification,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/help-desc",
    layout: DefaultLayout,
    component: HelpDesc,
    authorities: [Roles.SOCADMIN, Roles.ADMIN, Roles.USER]
},
{
    path: "/not-found",
    layout: DefaultLayout,
    component: NotFoundError,
}
];