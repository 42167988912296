import React from "react";
import {
    Row,
    Col,
    FormInput,
    FormTextarea
} from "shards-react";

import * as AppConstants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import { Store } from '../../../flux';


class TaskDetailsForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textAreaHeight: 0,
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            form: {
                name: '',
                content: '',
                group: '',
                isGlobal: false,
                manager: ''
            },
            formErrors: {
                name: [],
                content: [],
                group: [],
                manager: []
            },
            formHints: {
                group: [],
                manager: [],
            },
            isgroupSet: false,
            isOptionsLoading: false,
            isManagersLoading: true,
            taskUuid: props.uuid,
            title: '',
            category: '',
            customer: '',
            status: '',
            description: '',
            compareDescription: ''
        };
    }

    fetchReport = () => {
        API.get(`${AppConstants.REPORTS_URL}/${this.state.taskUuid}`)
        .then(response => {
            if(response.status === 200) {
                const report = response.data;
                this.setState({ title: report.title });
                this.setState({ category: report.category });
                this.setState({ customer: report.customer });
                this.setState({ status: report.status });
                this.setState({ description: report.description });
                this.setState({ compareDescription: report.description });
            }
        });
    }

    // textareaFitContent = () => {
    //         const descField = document.querySelector('.reportDescTextArea');
    //         this.setState({
    //             textAreaHeight: descField.scrollHeight
    //         });
    //         console.log("fitted");
    // };

    componentDidMount(){
        this.fetchReport(); 
    };

    componentDidUpdate() {
        if(this.state.description !== this.state.compareDescription) {
            const descField = document.querySelector('.reportDescTextArea');
            this.setState({
                textAreaHeight: descField.scrollHeight,
                compareDescription: this.state.description
            });
        }
    }


    render() {
            return (
                    <Row>
                    <Col sm="12">
                        <Row>
                            <Col>
                                <label>Kategoria</label>
                                <FormInput
                                    style={{ height: '38px', cursor: 'default' }}
                                    name="name"
                                    value={this.state.category}
                                    onChange={null}
                                    invalid={null}
                                    className={"mb-0"}
                                    readOnly />
                            </Col>
                            <Col>
                                <label>Tytuł</label>
                                <FormInput
                                    style={{ height: '38px', cursor: 'default' }}
                                    name="name"
                                    value={this.state.title}
                                    onChange={null}
                                    invalid={null}
                                    className={"mb-0"}
                                    readOnly />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: '1rem' }}>
                            <Col>
                                <label>Użytkownik</label>
                                <FormInput
                                    style={{ height: '38px', cursor: 'default' }}
                                    name="name"
                                    value={this.state.customer}
                                    onChange={null}
                                    invalid={null}
                                    className={"mb-0"}
                                    readOnly />
                            </Col>
                            <Col>
                                <label>Status</label>
                                <FormInput
                                    style={{ height: '38px', cursor: 'default', color: (this.state.status === "todo") ? 'orange' : 'green' }}
                                    name="name"
                                    value={(this.state.status === "todo") ? "W trakcie" : "Zakończony"}
                                    onChange={null}
                                    invalid={null}
                                    className={"mb-0 status"}
                                    readOnly />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: '1rem' }}>
                            <Col>
                                <label>Opis</label>
                                <FormTextarea
                                    className={'reportDescTextArea'} 
                                    style={{ resize: 'none', cursor: 'default', height: `${this.state.textAreaHeight+5}px`, overflowY: 'hidden' }}
                                    value={this.state.description} 
                                    readOnly/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
    }
}

export default TaskDetailsForm;