import React, { createRef } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import API from '../../api/AxiosConfiguration';
import * as AppConstants from '../../constants';
import { Button } from 'shards-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as SocToast from '../../utils/SocToast';
import { Store, Constants, Dispatcher } from "../../flux";
import 'react-grid-layout/css/styles.css';

import Tile from './Tile/Tile';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class AddRemoveLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    cols: { uhd8k: 24, uhd4k: 20, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 150
  };

  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      items: [],
      tileRefs: {},
      isMoreMenuShow: false,
      compactType: null,
      onLayoutChange: function() {},
      isOwned: false,
      isShared: false
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.calcBreakpoint = this.calcBreakpoint.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.configureTile = this.configureTile.bind(this);
    this.onFluxChange = this.onFluxChange.bind(this);
    this.saveChangedTile = this.saveChangedTile.bind(this);
    this.refHandler = this.refHandler.bind(this);
    this.onTileForceUpdate = this.onTileForceUpdate.bind(this);
    this.createTile = this.createTile.bind(this);
  }

  componentDidMount() {
    Store.addUpdateDashboadListener(this.onFluxChange);
    Store.addTileForceUpdateListener(this.onTileForceUpdate);
    // this.interval();
    this.setState({ mounted: true });
    this.onFluxChange();
  }

  componentWillUnmount() {
    Store.removeUpdateDashboadListener(this.onFluxChange);
    Store.removeTileForceUpdateListener(this.onTileForceUpdate);
  }

  onTileForceUpdate() {
    const { tileRefs } = this.state;

    const tileUuid = Store.getTileToForceUpdate();

    const tempTileRefs = tileRefs;

    this.setState({
      ...this.state,
      tileRefs: tempTileRefs
    }, () => tileRefs[tileUuid].ref.current.onForceUpdate());

  }


    onFluxChange() {
        var selectedDashboard = Store.getSelectedDashboard();
        if(selectedDashboard && selectedDashboard.value !== "none" ) {

          document.documentElement.style.setProperty(
              '--tile-resize-handle-display',
              (selectedDashboard.owned) ? 'block' : 'none'
            );


          API.post(`${AppConstants.DASHBOARD_TILES_URL}?dashboard=${selectedDashboard.value}`).then(result => {
            if(result.status === 200) {
              const data = result.data;
              this.setState({
                ...this.state,
                items: data,
                isOwned: selectedDashboard.owned,
                isShared: selectedDashboard.shared
              })
            }
          });
        }
    }


  calcBreakpoint(breakpointWidth) {
      if(this.state.mounted)
        return breakpointWidth-(document.body.offsetWidth-(document.querySelector('.layout').clientWidth-10));
    }


  onAddItem(tileUuid) {

    const newTile = {
      i: tileUuid,
      x: (this.state.items.length * 4) % (this.state.cols || 12),
      y: Infinity, // puts it at the bottom
      w: 4,
      h: 2,
      tileConfig: null
    };

    return newTile;
  }

  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  toggleModal(formType = "", dashboardUuid, tile) {
      Dispatcher.dispatch({
            actionType: Constants.TOGGLE_DASHBOARD_MODAL,
            payload: {
              formType,
              uuid: dashboardUuid,
              tile
          }
      })
  }

  onLayoutChange(layout) {
    this.props.onLayoutChange(layout);
    this.setState({ items: layout });
  }

  onRemoveItem(i) {

    if(this.state.items.find(item => item.i === i).tileConfig !== null ) {
      API.delete(`${AppConstants.DASHBOARD_TILES_URL}/${i}`)
      .then( response => {
        if(response.status === 200) {
          SocToast.success('Operacja wykonana pomyślnie');
          Dispatcher.dispatch({ actionType: Constants.UPDATE_DASHBOARD_LIST })
        }
      } )
      .catch( error => {
        SocToast.error('Nie udało się wykonać operacji', 'Spróbuj ponownie za chwilę lub skontaktuj się z administratorem');
      } );
    }

      this.setState({ items: this.state.items.filter(item => item.i !== i) }, () => this.refHandler(i, 'delete'));
  }

  moreMenuToggle() {
    this.setState({ isMoreMenuShow: !this.state.isMoreMenuShow })
}


  configureTile(data, toggleModal) {
    let isConfigureSaved = false;

    if(data !== null && data !== undefined
       && data.tileConfig !== null && data.tileConfig !== undefined) {
        let tempRequestBody = data;

        API.put(AppConstants.DASHBOARD_TILES_URL, tempRequestBody)
          .then( response => {
            if(response.status === 200) {
              isConfigureSaved = true;
              SocToast.success('Pomyślnie zapisano ustawienia');
            }
          })
          .catch( error => {
            SocToast.error('Nie udało się zapisać ustawień', 'Spróbuj ponownie za chwilę lub skontaktuj się z administratorem');
          })
          .finally( () => {
            toggleModal();

            if(isConfigureSaved)
              Dispatcher.dispatch({ actionType: Constants.UPDATE_DASHBOARD_LIST });
          });
    }


  }

  createTile(el) {
    const { tileRefs, isOwned, isShared } = this.state;

    return (
      <div key={el.i} data-grid={el} style={{ boxShadow: '2px 2px 15px 5px rgba(0,0,0,0.09)' }}>
        <Tile ref={tileRefs[el.i].ref} item={el} removeTile={this.onRemoveItem} toggleModal={this.toggleModal}  history={this.props.history} isDashboardOwned={isOwned} isDashboardShared={isShared} />
      </div>
      );
  }

  refHandler(data, mode) {
    const { tileRefs } = this.state;

    const tempTileRefs = tileRefs;

    if(mode === 'create') {
      data.forEach( tile => {
        if(tempTileRefs[tile.i] === undefined || tempTileRefs[tile.i] === null) {

          tempTileRefs[tile.i] = {
            ref: createRef(),
            random: Math.random()
          };

        }
      });
    } else if( mode === 'remove') {
      delete tempTileRefs[data];
    } else if( mode === 'single-create') {
      if(tempTileRefs[data] === undefined || tempTileRefs[data] === null) {
          tempTileRefs[data] = {
            ref: createRef(),
            random: Math.random()
          };
      }
    }

    this.setState({
      ...this.state,
      tileRefs: tempTileRefs
    });

  }


  saveChangedTile(newItem, element) {
    const requestBody = [];

    newItem.forEach( tile => {
      const tempTileData = {};

      tempTileData.i = tile.i;
      tempTileData.x = tile.x;
      tempTileData.y = tile.y;
      tempTileData.h = tile.h;
      tempTileData.w = tile.w;

      requestBody.push(tempTileData);
    });

    const currentDashboardUuid = Store.getSelectedDashboard().value;

    API.put(`${AppConstants.DASHBOARD_TILES_URL}/${currentDashboardUuid}`, requestBody).catch(error => {
      console.log('FAIL')
    });
  }

  render() {

    return (
      <div>
        {this.state.isOwned && <Button theme="info" outline
                 onClick={() => this.toggleModal('configureTile', Store.getSelectedDashboard().value)}
                 className="mb-3">
          {<FontAwesomeIcon icon={faPlus} size="lg"
                            style={{cursor: 'pointer'}}/>}
        </Button>}
        {this.state.items && this.refHandler(this.state.items, 'create')}
        <ResponsiveReactGridLayout
          breakpoints={{uhd8k: this.calcBreakpoint(7680), uhd4k: this.calcBreakpoint(3840), lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
          onLayoutChange={this.onLayoutChange}
          draggableHandle=".tileDragHandle"
          onDragStop={this.saveChangedTile}
          onResizeStop={this.saveChangedTile}
          onBreakpointChange={this.onBreakpointChange}
          measureBeforeMount={false}
          {...this.props}
        >
          {_.map(this.state.items, el => this.createTile(el))}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}
