import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-grid-layout/css/styles.css';
import Chart from 'react-apexcharts';
import { Tooltip as ShardsTooltip } from 'shards-react';
import Tooltip from '@material-ui/core/Tooltip';
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from "../../../constants";
import PortIndicator from '../PortIndicator';
import '../../../assets/dashboard-grid.css';
import moment from 'moment';

class Tile extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            showHint: false,
            isMoreMenuShow: false,
            clientX: 0,
            clientY: 0,
            tileMoved: false,
            error: false,
            tileConfig: null,
            isFetched: false,
            item: null,
            content: [],
            options: null,
            title: '',
            deviceName: '',
            threshold: '',
            textInfoNode: 'Brak wybranych danych.',
        }

        this.interval = undefined;

        this.toggleHintIfTitleIsTooLong = this.toggleHintIfTitleIsTooLong.bind(this);
        this.moreMenuToggle = this.moreMenuToggle.bind(this);
        this.renderChart = this.renderChart.bind(this);
        this.moreTileMenuParentRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.checkIfTileMovedOnMouseDown = this.checkIfTileMovedOnMouseDown.bind(this);
        this.checkAndToggleConfigureModalOnMouseUp = this.checkAndToggleConfigureModalOnMouseUp.bind(this);
        // this.fetchRuleEvents = this.fetchRuleEvents.bind(this);
        this.onForceUpdate = this.onForceUpdate.bind(this);
        this.redirectToDetails = this.redirectToDetails.bind(this);
        this.fetchChartData = this.fetchChartData.bind(this);
        this.calcPercentage = this.calcPercentage.bind(this);
        //this.forceUpdateHandler = this.forceUpdateHandler.bind(this);


        this.setTileContentRef = element => {
            this.tileContentRef = element;
        }

        // eslint-disable-next-line no-extend-native
        Number.prototype.between = function (a, b, inclusive) {
            var min = Math.min(a, b),
                max = Math.max(a, b);

            return inclusive ? this >= min && this <= max : this > min && this < max;
        }
    }

    static formatDate = (date, format) => {

        const formatMap = {
            MM: date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
            DD: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
            YY: date.getFullYear().toString().slice(-2),
            YYYY: date.getFullYear(),
            HH: date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
            mm: date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
            ss: date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
        };

        return format.replace(/MM|DD|YYYY|YY|HH|mm|ss/gi, matched => formatMap[matched]);

    }

    onForceUpdate() {
        const { tileConfig } = this.state;

        if (tileConfig !== null) {
            this.setState({
                ...this.state,
                isFetched: false
            }, () => this.fetchChartData());
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { item } = props;

        if (item !== state.item) {

            const newState = {};

            newState.item = item;
            if (item.tileConfig !== null) {
                newState.tileConfig = item.tileConfig;
                if (item.tileConfig.title !== null && item.tileConfig.title !== '' && item.tileConfig.title !== undefined) newState.title = item.tileConfig.title;
            }

            return {
                isFetched: false,
                ...newState
            }
        }

        return state;
    }

    toggleHintIfTitleIsTooLong() {
        const { item } = this.state;
        const element = document.getElementById(`tileTitle-${item.i}`);
        if (element.clientHeight < element.scrollHeight ||
            element.clientWidth < element.scrollWidth)
            this.setState({
                showHint: !this.state.showHint
            })
    }

    moreMenuToggle() {
        if (this._isMounted)
            this.setState({ isMoreMenuShow: !this.state.isMoreMenuShow })
    }

    componentDidUpdate(prevProps, prevState) {
        const { content, tileConfig } = this.state;

        if (!this.state.isFetched && tileConfig != null && ((content.length === 0 || content == null) && tileConfig.dataPresentationType !== 'TABLE')) {

        }

        if ((this.props.item.tileConfig !== prevProps.item.tileConfig) || (this.state.tileConfig !== prevState.tileConfig)) {
            this.setState({
                ...this.state,
                isFetched: false
            }, () => this.fetchChartData());
        }
    }


    fetchChartData(isForceUpdate = false) {
        const { tileConfig } = this.state;
        const { item } = this.props;

        const now = moment().format("YYYYMMDDhhmmss")
        
        const chartOption = {
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: this.state.title + " - " + now,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    svg: {
                        filename: this.state.title + " - " + now,
                    },
                    png: {
                        filename: this.state.title + " - " + now,
                    }
                },
                autoSelected: 'zoom'
            }
        }

        const noData = {
            text: "Brak danych",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: '#000',
                fontSize: '14px',
                opacity: '0.7'
            }
        }

        const requestBody = {
            dashboardUuid: `${item.dashboardUuid}`,
            model: `${tileConfig.model}`,
            group: `${tileConfig.group}`,
            devices: tileConfig.devices,
            dataPresentationType: tileConfig.dataPresentationType,
            dataType: tileConfig.dataType,
            components: tileConfig.components,
            timePeriod: parseInt(tileConfig.timePeriod),
            timePeriodType: tileConfig.timePeriodType
        };

        let data, options, deviceName = '';

        if (!this.state.isFetched || isForceUpdate) {

            if (tileConfig.dataType === "RULE") requestBody.rules = tileConfig.rules;

            let endpoint;

            // eslint-disable-next-line default-case
            switch (tileConfig.module) {
                case 'pcDevices': {
                    endpoint = AppConstants.DASHBOARD_PCDEVICES_TILE_DATA;
                    break;
                }
                case 'networkDevices': {
                    endpoint = AppConstants.DASHBOARD_NETWORK_TILE_DATA;
                    break;
                }

                case 'controlPanels': {
                    endpoint = AppConstants.DASHBOARD_CONTROLPANEL_TILE_DATA;
                    break;
                }

                default:
                    this.setState({
                        ...this.state,
                        textInfoNode:
                            <div>
                                Niepoprawne dane dashboarda
                                <br/>
                                Prosimy o kontakt z&nbsp;
                                <a href="/help-desc">pomocą techniczną</a>
                            </div>
                    });
                    return;
            }

            API.post(endpoint, requestBody).then(response => {
                if (response.status === 200) {
                    const responseData = response.data;
                    if (!(Object.entries(responseData).length === 0 && responseData.constructor === Object)) {
                        // eslint-disable-next-line default-case
                        switch (tileConfig.dataPresentationType) {
                            case 'TABLE': {
                                const responseData = response.data;

                                if (this._isMounted) {

                                    if (responseData.deviceName) {
                                        deviceName = responseData.deviceName;
                                    }

                                    const data = [];

                                    responseData.series.forEach(event => {
                                        data.push({
                                            id: event.id,
                                            desc: event.desc,
                                            datetime: Tile.formatDate(new Date(event.datetime), "DD.MM.YYYY HH:mm:ss"),
                                            device: event.device
                                        })
                                    });

                                    this.setState({
                                        ...this.state,
                                        deviceName: deviceName,
                                        content: data
                                    });

                                }

                                break;
                            }
                            case 'PIE': {
                                data = [...responseData.series];


                                if (responseData.deviceName) {
                                    deviceName = responseData.deviceName;
                                }


                                options = {
                                    ...noData,
                                    chart: {
                                        ...chartOption,
                                        id: tileConfig.dataPresentationType
                                    },
                                    labels: [...responseData.labels]
                                }

                                this.setState({
                                    ...this.state,
                                    deviceName: deviceName,
                                    content: data,
                                    options: options
                                });

                                break;
                            }
                            case 'LINE': {
                                data = [...responseData.series];

                                data.forEach(arr => {
                                    arr = arr.data.sort((x, y) => x[0] - y[0]);
                                });

                                if (responseData.deviceName) {
                                    deviceName = responseData.deviceName;
                                }


                                options = {
                                    ...noData,
                                    chart: {
                                        ...chartOption,
                                        type: tileConfig.dataPresentationType.toLowerCase,
                                        zoom: {
                                            enabled: true
                                        }
                                    },
                                    xaxis: {
                                        type: 'datetime',
                                        labels: {
                                            datetimeUTC: false
                                        }
                                    },
                                }


                                options.markers = (tileConfig.timePeriodType === 'DAYS' || tileConfig.timePeriodType === 'MONTHS') ? {
                                    size: 5,
                                    strokeColors: '#fff',
                                    strokeWidth: 2,
                                    strokeOpacity: 0.9,
                                    fillOpacity: 1,
                                    offsetX: 0,
                                    offsetY: 0,
                                    shape: 'circle',
                                    radius: 2,
                                    hover: {
                                        size: 6
                                    }
                                } : {};

                                this.setState({
                                    ...this.state,
                                    deviceName: deviceName,
                                    content: data,
                                    options: options
                                });

                                break;
                            }
                            case 'COLUMN': {
                                break;
                            }
                            case 'NUMBER': {

                                if (responseData.deviceName) {
                                    deviceName = responseData.deviceName;
                                }

                                data = [];

                                let threshold = '',
                                    tempSum = 0;

                                (this.state.item.tileConfig.dataType.match(/RAMUSAGE+/g)) && responseData.series.forEach(el => tempSum += parseInt(el));

                                responseData.labels.forEach((element, index, array) => {
                                    const value = responseData.series[index];
                                    if (index === 0) {

                                        if (tileConfig.warningThreshold !== null && tileConfig.warningThreshold !== undefined
                                            && tileConfig.warningThreshold !== '' && tileConfig.criticalThreshold !== null
                                            && tileConfig.criticalThreshold !== undefined && tileConfig.criticalThreshold !== '') {
                                            if (tileConfig.dataType.match(/RAMUSAGE+/g)) {
                                                if (this.calcPercentage(value, tempSum) >= tileConfig.warningThreshold) threshold = "warning";
                                                if (this.calcPercentage(value, tempSum) >= tileConfig.criticalThreshold) threshold = "critical";
                                            } else {
                                                if (value >= tileConfig.warningThreshold) threshold = "warning";
                                                if (value >= tileConfig.criticalThreshold) threshold = "critical";
                                            }
                                        }
                                    }

                                    data.push({
                                        label: element,
                                        value
                                    });
                                });


                                this.setState({
                                    ...this.state,
                                    deviceName: deviceName,
                                    threshold: threshold,
                                    content: data
                                });


                                break;
                            }
                            case 'GRAPHIC': {

                                if (responseData.deviceName) {
                                    deviceName = responseData.deviceName;
                                }

                                data = [];


                                responseData.series.forEach((element) => {
                                    const value = element.value.toUpperCase();

                                    data.push({
                                        label: element.label,
                                        value
                                    })
                                });

                                this.setState({
                                    ...this.state,
                                    deviceName: deviceName,
                                    content: data
                                });
                            }
                        }
                    }
                }
            }).catch(error => {
                if (error.response.status === 412) {
                    this.setState({
                        ...this.state,
                        textInfoNode: 'Urządzenie zostało usunięte.'
                    });
                } else 
                if ([401, 403].includes(error.response.status)) {
                    this.setState({
                        ...this.state,
                        textInfoNode: 'Brak uprawnień do tych danych.'
                    });
                }
            });
        }

        this.setState({
            ...this.state,
            isFetched: true
        })
    }

    checkIfTileMovedOnMouseDown(e) {
        this.setState({
            tileMoved: false,
            clientX: e.clientX,
            clientY: e.clientY
        });
    }

    redirectToDetails() {
        const { tileConfig } = this.state;

        let path, state = {};

        if (tileConfig.isFilteredRules) {
            // eslint-disable-next-line default-case
            switch (tileConfig.module) {
                case 'pcDevices': {
                    path = '/pc-event-history';
                    break;
                }
                case 'networkDevices': {
                    path = '/network-event-history';
                    break;
                }
                case 'controlPanels': {
                    path = '/control-panel-event-history';
                    break;
                }
            }

            //return new Date(date.getTime() - minutes*60000);

            let eventStartRangeDate,
                eventEndRangeDate = moment().valueOf();

            state.rules = this.state.tileConfig.rules;

            if (tileConfig.dataPresentationType === 'NUMBER') {
                let unit;

                // eslint-disable-next-line default-case
                switch (tileConfig.timePeriodType) {
                    case 'MINUTES': {
                        unit = 'minutes';
                        break;
                    }
                    case 'HOURS': {
                        unit = 'hours';
                        break;
                    }
                    case 'DAYS': {
                        unit = 'days';
                        break;
                    }
                    case 'MONTHS': {
                        unit = 'months';
                        break;
                    }
                }

                eventStartRangeDate = moment().subtract(parseInt(tileConfig.timePeriod), unit).valueOf()

                state.eventStartRangeDate = eventStartRangeDate;
                state.eventEndRangeDate = eventEndRangeDate;
            }
        } else {
            // eslint-disable-next-line default-case
            switch (tileConfig.module) {
                case 'pcDevices': {
                    path = '/pc-details-s';
                    break;
                }
                case 'networkDevices': {
                    path = '/network-details';
                    break;
                }
                case 'controlPanels': {
                    path = '/control-panel-details';
                    break;
                }
            }

            state.uuid = this.state.tileConfig.devices[0];
        }

        this.props.history.push({
            pathname: path,
            state: state
        });


    }

    checkAndToggleConfigureModalOnMouseUp(e) {
        const { clientX, clientY, isMoreMenuShow, item } = this.state;
        const { toggleModal } = this.props;

        const moved = (e.clientX.between(clientX, clientX + 20, true) && e.clientY.between(clientY, clientY + 20, true)) ? false : true;

        this.setState({
            tileMoved: moved,
            clientX: 0,
            clientY: 0
        }, () => {
            if (this._isMounted && !moved && !isMoreMenuShow) {
                toggleModal('configureTile', item.dashboardUuid, item.i);
            }
        });
    }

    componentDidMount() {
        const { tileConfig, content } = this.state;
        const { isDashboardOwned, isDashboardShared } = this.props;

        this._isMounted = true;

        this.interval = setInterval(() => this.fetchChartData(true), 300000);

        this.setState({

        })

        if (isDashboardOwned || isDashboardShared)
            document.addEventListener('click', this.handleClickOutside);

        if (tileConfig != null && (content.length === 0 || content == null)) {
            this.setState({
                ...this.state,
                isFetched: false
            }, () => this.fetchChartData())
        }
    }

    calcPercentage(num, sum) {
        if (num === 0 && sum === 0) {
            return 0;
        } else {
            return ((num / sum) * 100).toFixed(0);
        }
    }

    renderChart = () => {
        const { content, options, item } = this.state;

        switch (item.tileConfig.dataPresentationType) {
            case 'NUMBER': {
                let sum = 0;

                if (item.tileConfig.dataType.match(/RAMUSAGE+/g)) content.forEach(el => sum += parseInt(el.value));

                return (
                    <div className="d-flex align-items-center justify-content-around" style={{ width: '100%', overflowY: 'auto' }}>
                        {content !== null && content.map((element, index) => {
                            let unit;

                            const labelStyle = {
                                fontSize: '5rem'
                            },

                                unitStyle = {
                                    fontSize: '2.5rem'
                                };

                            if (item.tileConfig.dataType.match(/TEMP+/g)) {
                                unit = "°C";
                            } else if (item.tileConfig.dataType.match(/RAMUSAGE+/g) || item.tileConfig.dataType.match(/CPULOAD+/g)) {
                                unit = "%";
                            }

                            return (
                                <div key={`${index}`} style={{ textAlign: 'center', wordWrap: 'break-word', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    {(element.label && element.label !== "0") ? (<p>{element.label} :</p>) : null}
                                    {item.tileConfig.dataType.match(/RAMUSAGE+/g)
                                        ?
                                        <Tooltip title={`${element.value} MB`} placement={index % 2 === 0 ? 'right' : 'left'} arrow>
                                            <p style={labelStyle}>{item.tileConfig.dataType.match(/RAMUSAGE+/g) ? this.calcPercentage(element.value, sum) : element.value}<span style={unitStyle}>{unit}</span></p>
                                        </Tooltip>
                                        :
                                        <p style={labelStyle}>{item.tileConfig.dataType.match(/RAMUSAGE+/g) ? this.calcPercentage(element.value, sum) : element.value}<span style={unitStyle}>{unit}</span></p>
                                    }
                                </div>
                            );
                        })}
                    </div>
                );
            }
            case 'TABLE': {
                const columns = [
                    {
                        dataField: 'id',
                        text: 'ID',
                        hidden: true
                    },
                    {
                        dataField: 'desc',
                        text: 'Opis'
                    },
                    {
                        dataField: 'datetime',
                        style: { width: '50%' },
                        text: 'Data/czas wystąpienia'
                    },
                    {
                        dataField: 'device',
                        hidden: true
                    }
                ];

                return (
                    <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
                        <BootstrapTable
                            keyField='id'
                            data={content}
                            columns={columns}
                            bordered={false}
                            noDataIndication={() => <p>Brak wybranych danych</p>}
                            bootstrap4={true}
                            defaultSorted={[{ dataField: "datetime", order: "desc" }]}
                            classes="table mb-0"
                            wrapperClasses="table-responsive"
                            rowStyle={{ padding: '0', margin: '0' }}
                            style={{ fontSize: '.8rem' }}
                            rowEvents={
                                {
                                    onClick: (e, row, rowIndex) => {
                                        this.redirectToDetails(row.device);
                                    }
                                }
                            }
                        />
                    </div>
                );
            }
            case 'GRAPHIC': {

                if (Object.entries(content).length === 0) {
                    return (
                        <div style={{ color: "red", fontSize: '0.9rem', textAlign: 'center' }}>Nie odnaleziono mapowania portów dla tego urządzenia. Prosimy skontaktować się z administartorem.</div>
                    )
                }

                const containerStyle = {
                    maxWidth: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    alignSelf: 'center',
                    padding: '0 2rem',
                    overflow: 'hidden'
                };

                return (
                    <div style={containerStyle}>
                        {content.map(port => {
                            return (
                                <PortIndicator key={`${this.state.tileConfig.dataType}-${port.label}`} label={port.label} status={port.value} icon={this.state.tileConfig.dataType} />
                            );
                        })}
                    </div>
                );

            }
            default: {
                return (content !== null && options !== null) ? <Chart series={content} height='100%' type={item.tileConfig.dataPresentationType.toLowerCase()} options={options} style={{ width: '100%' }} /> : null;
            }
        }

    };

    handleClickOutside(e) {
        if (this.moreTileMenuParentRef && !this.moreTileMenuParentRef.current.contains(e.target))
            this.setState({ isMoreMenuShow: false });
    }

    componentWillUnmount() {
        const { isDashboardOwned, isDashboardShared } = this.props;

        if (isDashboardOwned || isDashboardShared)
            document.removeEventListener('click', this.handleClickOutside);

        clearInterval(this.interval);

        this._isMounted = false;
    }



    render() {
        const { removeTile, toggleModal, isDashboardOwned, isDashboardShared } = this.props;
        const { isMoreMenuShow, tileConfig, item } = this.state;

        const moreParentStyle = {
            position: "absolute",
            right: "20px",
            top: "20px",
            cursor: "pointer",
            width: '1.5rem',
            height: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 5
        };

        const moreMenuStyle = {
            position: "absolute",
            display: isMoreMenuShow ? "block" : "none",
            backgroundColor: '#fff',
            boxShadow: '2px 2px 15px 5px rgba(0,0,0,0.09)',
            zIndex: 5,
            color: '#000',
            right: 0,
            padding: '15px',
            top: '25px'
        };

        const dragHandleStyle = {
            cursor: 'pointer',
            position: 'absolute',
            top: '20px',
            left: '20px',
            width: '1.5rem',
            height: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }

        const moreMenuLiStyle = {
            listStyleType: 'none'
        };

        const tileTitleStyle = {
            position: 'absolute',
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 0,
            width: 'calc(calc(100% - calc(calc(20px + 1.5rem) * 2)) - 30%)',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }

        const moreMenuSeparatorStyle = {
            width: "100%",
            margin: 0,
            marginTop: '15px',
            marginBottom: '10px'
        };

        const checkThreshold = () => {
            const { threshold } = this.state;

            let bgResult, colorResult;

            if (threshold === 'warning') {
                bgResult = "orange";
                colorResult = "#fff";
            } else if (threshold === 'critical') {
                bgResult = "red";
                colorResult = "#fff";
            } else {
                bgResult = "#fff";
                colorResult = "inherit";
            }

            return { bg: bgResult, color: colorResult };
        }

        const checkThresholdResult = checkThreshold();

        const wrapperStyle = {
            backgroundColor: checkThresholdResult.bg,
            borderRadius: '0.2rem',
            overflow: 'hidden',
            height: '100%',
            position: 'relative',
            color: checkThresholdResult.color,

        }

        const deviceFooterStyle = {
            cursor: 'pointer',
            zIndex: '5'
        }



        return (
            <div key={this.state.key} style={wrapperStyle} className={this.state.threshold && 'threshold-shine'} >
                <div style={{ height: '100%', padding: '3rem 1rem .5rem' }}>
                    {(isDashboardOwned || isDashboardShared) && <span className="tileDragHandle" style={dragHandleStyle}>
                        <FontAwesomeIcon icon={faArrowsAlt} />
                    </span>}

                    {tileConfig === null ?
                        <div className="tileContent" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onMouseDown={this.checkIfTileMovedOnMouseDown} onMouseUp={this.checkAndToggleConfigureModalOnMouseUp}>
                            <div style={{ textAlign: 'center', opacity: '0.4' }}>
                                <p className="pt-3" ><FontAwesomeIcon icon={faEllipsisV} />  {'>'}  <u>Konfiguruj</u> lub kliknij tutaj, <br /> aby dodać zawartość</p>
                            </div>
                        </div>
                        :
                        <>
                            <ShardsTooltip
                                open={this.state.showHint}
                                target={`#tileTitle-${item.i}`}
                                toggle={this.toggleHintIfTitleIsTooLong}>
                                {this.state.title}
                            </ShardsTooltip>
                            <p style={tileTitleStyle} id={`tileTitle-${item.i}`} >{this.state.title}</p>
                            <div style={{ position: 'relative', height: this.state.deviceName !== '' ? '90%' : '100%', width: '100%', display: 'flex', alignItems: 'center', zIndex: 4, justifyContent: 'center', cursor: tileConfig.isFilteredRules && 'pointer' }} onClick={() => tileConfig.isFilteredRules && this.redirectToDetails()}>
                                {(this.state.isFetched && this.state.content.length > 0) || this.state.tileConfig.dataPresentationType === 'TABLE' ? this.renderChart()
                                    : <p style={{ textAlign: 'center', opacity: '0.4' }}>{this.state.textInfoNode}</p>}
                            </div>
                            {this.state.deviceName !== '' &&
                                <div style={{ height: '10%' }}>
                                    <span style={deviceFooterStyle} onClick={() => !tileConfig.isFilteredRules && this.redirectToDetails()} >{this.state.deviceName}</span>
                                </div>
                            }
                        </>
                    }

                    {(isDashboardOwned || isDashboardShared) && <div className="moreTileMenuParent" data-key={item.i} style={moreParentStyle} ref={this.moreTileMenuParentRef} onClick={(e) => this.moreMenuToggle(e)} >
                        <FontAwesomeIcon icon={faEllipsisV} />
                        <div className="moreTileMenu" style={moreMenuStyle} >
                            <ul style={{ margin: 0, padding: 0 }}>
                                <li style={moreMenuLiStyle} onClick={() => toggleModal((tileConfig === null || tileConfig === undefined) ? 'configureTile' : tileConfig.isFilteredRules ? 'addToDashboardDetails' : 'editTile', item.dashboardUuid, item.i)}> {tileConfig.isFilteredRules ? 'Szczegóły' : 'Konfiguruj'}</li>
                                <li style={moreMenuLiStyle}><hr style={moreMenuSeparatorStyle} /></li>
                                <li style={moreMenuLiStyle} onClick={() => removeTile(item.i)}>Usuń</li>
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default Tile;
