import React from "react";
import { FormInput, Button } from "shards-react";
import { Redirect } from 'react-router-dom';
import API from "../../../api/AxiosConfiguration";
import * as Constants from "../../../constants";
import { SECURE_PASSWORD_REGEXP } from "../../../utils/Patterns";

class PasswordChangeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChangeRequestSent: false,
      form: {
        password: "",
        repeatPassword: ""
      },
      formErrors: {
        password: [],
        repeatPassword: []
      }
    };

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  inputChangeHandler = event => {
    var { form, formErrors } = this.state;
    form[event.target.name] = event.target.value;
    formErrors[event.target.name] = [];
    this.setState({ form: form });
  };

  validForm() {
    const { formErrors } = this.state;
    const { password, repeatPassword } = this.state.form;

    formErrors["password"] = [];
    formErrors["repeatPassword"] = [];

    var errorCount = 0;

    if (!SECURE_PASSWORD_REGEXP.test(password)) {
      formErrors["password"].push(
        "Hasło musi zawierać co najmniej: jedną małą literę, " +
        "jedną dużą literę, jedną cyfrę, jeden znak specjalny, 8 znaków."
      );
      errorCount++;
    }

    if (password !== repeatPassword) {
      formErrors["repeatPassword"].push("Podane hasła muszą być identyczne");
      errorCount++;
    }

    this.setState({ formErrors: formErrors });
    return !errorCount;
  }

  submitForm() {
    const { form, formErrors } = this.state;
    const { changeAttemptUuid } = this.props;

    var requestBody = {
      password: form.password,
      repeatPassword: form.repeatPassword,
      uuid: changeAttemptUuid
    }

    if (this.validForm()) {
      API.post(Constants.CUSTOMERS_URL + "/changePassword", requestBody)
        .then((result) => {
          this.setState({ isChangeRequestSent: true })
        }).catch((error) => {
          var response = error.response;
          if (response && response.status === 400) {
            response.data.errors.forEach(error => {
              formErrors[error.field.split(".").pop()].push(error.defaultMessage);
            })
            this.setState({ formErrors: formErrors });
          }
        });
    }
  }

  render() {
    const { password, repeatPassword } = this.state.form;
    const { isChangeRequestSent } = this.state;

    const redirectToLogin = () => {
      return <Redirect to={{
        pathname: "/login",
        state: { message: "Hasło zostało poprawnie zresetowane. Możesz się teraz zalogować." }
      }} />;
    }

    const renderError = errors => {
      return errors.map((error, index) => <li key={index}>{error}</li>);
    };

    const hasPasswordError = Boolean(this.state.formErrors.password.length);
    const hasRepeatPasswordError = Boolean(
      this.state.formErrors.repeatPassword.length
    );


    return (
      isChangeRequestSent === true ?
        redirectToLogin() :
        <div>
          <label>Nowe hasło:</label>
          <FormInput
            name="password"
            value={password || ""}
            onChange={this.inputChangeHandler}
            type="password"
            invalid={hasPasswordError}
            className={hasPasswordError ? "mb-0" : "mb-2"}
          />
          {hasPasswordError && (
            <ul className="mb-2 form-error-message">
              {renderError(this.state.formErrors.password)}
            </ul>
          )}

          <label>Powtórz hasło:</label>
          <FormInput
            name="repeatPassword"
            value={repeatPassword || ""}
            onChange={this.inputChangeHandler}
            type="password"
            invalid={hasRepeatPasswordError}
            className={hasRepeatPasswordError ? "mb-0" : "mb-2"}
          />
          {hasRepeatPasswordError && (
            <ul className="mb-2 form-error-message">
              {renderError(this.state.formErrors.repeatPassword)}
            </ul>
          )}

          <Button
            onClick={this.submitForm}
            outline
            theme="info"
            className="mt-2 ml-1 float-right"
          >
            Zmień hasło
        </Button>
        </div>
    );
  }
}

export default PasswordChangeForm;
