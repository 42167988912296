import React from 'react';
import { Row, Col, ListGroup, ListGroupItem, Button } from 'shards-react';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinus } from '@fortawesome/free-solid-svg-icons';

import ConfirmUserGroupModal from './../modals/ConfirmUserGroupModal';
import DeleteUserGroupModal from './../modals/DeleteUserGroupModal';

class SafeticaConfigurationUserTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isConfirmUserGroupModalShow: false,
            isDeleteUserGroupModalShow: false,
            selectedUserGroupUuid: '',
            userGroups: [],
            safeticaDefinedUsers: []
        };

        this.updateComponent = this.updateComponent.bind(this);
        this.fetchSafeticaUserGroups = this.fetchSafeticaUserGroups.bind(this);
        this.toggleConfirmUserGroupModal = this.toggleConfirmUserGroupModal.bind(this);
        this.toggleDeleteUserGroupModal = this.toggleDeleteUserGroupModal.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    toggleConfirmUserGroupModal(uuid = "") {
        this.setState({
            isConfirmUserGroupModalShow: !this.state.isConfirmUserGroupModalShow,
            selectedUserGroupUuid: uuid
        })
    }

    toggleDeleteUserGroupModal(uuid = "") {
        this.setState({
            isDeleteUserGroupModalShow: !this.state.isDeleteUserGroupModalShow,
            selectedUserGroupUuid: uuid
        })
    }

    fetchSafeticaUserGroups() {
        return API.get(AppConstants.PC_SAFETICA_USERS_CONFIGURATION_URL);
    }

    updateComponent() {
        this.props.onFetchStart();
        this.fetchSafeticaUserGroups()
            .then(result => {
                this.setState({
                    userGroups: result.data.users,
                    safeticaDefinedUsers: result.data.safeticaDefinedUsers
                })
                this.props.onFetchComplete();
            })
    }

    render() {
        const { userGroups, isConfirmUserGroupModalShow, isDeleteUserGroupModalShow, selectedUserGroupUuid, safeticaDefinedUsers } = this.state;
        const paginationOptions = {
            custom: true,
            totalSize: userGroups.length,
            sizePerPage: 10
        };

        const userGroupColumns = [{
            dataField: 'name',
            align: "left",
            headerAlign: "left",
            text: "Nazwa grupy",
        }, {
            dataField: 'userCount',
            align: "center",
            headerAlign: "center",
            text: "Ilość użytkowników",
        }, {
            dataField: 'actions',
            align: "right",
            headerAlign: "right",
            text: 'Akcje',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => (
                <div className="bootstrap-table-actions-col">
                    <Button size="sm" onClick={() => this.toggleConfirmUserGroupModal(row.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>

                    <Button size="sm" theme="danger" className="ml-1" onClick={() => this.toggleDeleteUserGroupModal(row.id)}>
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                </div>
            ),
        }]

        const renderUserGroups = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={userGroups}
                                columns={userGroupColumns}
                                classes="table mb-0"
                                bordered={false}
                                hover
                                rowStyle={{ height: '10px' }}
                                wrapperClasses="table-responsive"
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderUserGroupsFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <h6>Brak danych</h6>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <React.Fragment>
                <Button onClick={this.toggleConfirmUserGroupModal} outline theme="info" className="mb-3 mr-1 mt-2">Dodaj grupę użytkowników</Button>
                {userGroups.length ? renderUserGroups() : renderUserGroupsFetchError()}

                <ConfirmUserGroupModal open={isConfirmUserGroupModalShow} uuid={selectedUserGroupUuid} safeticaDefinedUsers={safeticaDefinedUsers}
                    toggle={this.toggleConfirmUserGroupModal} onSuccess={this.updateComponent} />
                <DeleteUserGroupModal open={isDeleteUserGroupModalShow} uuid={selectedUserGroupUuid}
                    toggle={this.toggleDeleteUserGroupModal} onSuccess={this.updateComponent} />
            </React.Fragment>
        );
    }

}

export default SafeticaConfigurationUserTable;