import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";
import { PrivateRoute } from './PrivateRoute';
import { LoginLayout } from "./layouts";
import { TwoFactorLogin } from "./views/TwoFactorLogin";
import { RegisterCustomer } from './views/RegisterCustomer';
import { ChangePassword } from './views/ChangePassword';
import { ToastContainer, Zoom } from 'react-toastify';
import ResetPassword from "./views/ResetPassword";
import Regulations from './views/Regulations';
import ForgotPassword from "./views/ForgotPassword";
import DeleteAccount from "./views/DeleteAccount";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "./assets/shards-dashboard/shards-dashboards.1.1.0.min.css";
import "./assets/global.css";
import 'react-toastify/dist/ReactToastify.css';
import { Store } from "./flux";
import API from "./api/AxiosConfiguration";
import * as Constants from './constants';

import LoadingSpinnerComponent from "./components/common/LoadingSpinerComponent"

export default () => {

  API.get(Constants.SERVICE_STATUS);

  function getSelectionText() {
    var text = "";
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== "Control") {
      text = document.selection.createRange().text;
    }
    return text;
  }

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scroll(0, 0);
    }, []);

    return null;
  }

  if (localStorage.getItem('readingText') === "true") {
    document.onmouseup = (e) => {
      let text = getSelectionText();
      if (text !== null) {
        window.speechSynthesis.cancel();
        var msg = new SpeechSynthesisUtterance(text)
        msg.lang = "pl-PL"
        window.speechSynthesis.speak(msg)
      }
    }
  }

  const [selectedCompany, setSelectedCompany] = useState("");

  useEffect(() => {
    function onFluxChange() {
      setSelectedCompany(Store.getSelectedCompany());
    }

    Store.addChangeListener(onFluxChange);

    return () => {
      Store.removeChangeListener(onFluxChange);
    };
  }, []);

  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
        <Route exact path="/login" layout={LoginLayout} component={TwoFactorLogin} />
        <Route exact path="/register/:uuid" layout={LoginLayout} component={RegisterCustomer} />
        <Route exact path="/forgot-password" layout={LoginLayout} component={ForgotPassword} />
        <Route path="/change-pass/:uuid" layout={LoginLayout} component={ChangePassword} />
        <Route path="/reset-pass/:uuid" layout={LoginLayout} component={ResetPassword} />
        <Route path="/delete/account/:token" layout={LoginLayout} component={DeleteAccount} />
        <Route exact path="/regulations" layout={LoginLayout} component={Regulations} />

        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <PrivateRoute location={props.location} authorities={route.authorities} privileges={route.privileges}>
                      <ScrollToTopOnMount />
                      <route.layout {...props}>
                        <route.component {...props} siteTitle={route.siteTitle} selectedCompany={selectedCompany} />
                      </route.layout>
                  </PrivateRoute>
                );
              })}
            />
          );
        })}
        <LoadingSpinnerComponent />
        <ToastContainer transition={Zoom} newestOnTop rtl={false} pauseOnFocusLoss />
      </div>
    </Router>
  );
}
