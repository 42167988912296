import React from 'react';
import { ConfirmModal } from "../../../common/Modal";
import * as AppConstants from '../../../../constants';
import API from "../../../../api/AxiosConfiguration";
import * as socToast from '../../../../utils/SocToast';

class DeleteZoneModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteZone = this.deleteZone.bind(this);
    }

    deleteZone(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(AppConstants.PC_SAFETICA_ZONE_CONFIGURATION_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    onSuccess();
                    socToast.success("Powodzenie", "Strefa została usunięta.");
                    toggleFunction();
                }
            })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                title="Jesteś pewien, że chcesz usunąć strefe?"
                open={open}
                toggle={toggle}
                submitAction={this.deleteZone}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                Spowoduje to usunięcie wszystkich danych o strefach.
                Jeśli jesteś zdecydowany, kliknij "Potwierdź".
            </ConfirmModal>
        )
    }

}

export default DeleteZoneModal;