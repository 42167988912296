import React from "react";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import withLoader from "../../common/hoc/withLoader";
import DeviceSearcherForm from "./forms/DeviceSearcherForm";
import DeviceSearcherResultModal from "./modals/DeviceSearcherResultModal";

class PcDeviceSearcher extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFormError: false,
            isFilterResultModalShow: false,
            searchFormResult: [],
            isLoading: false,
        };

        this.toggleFilterResultModal = this.toggleFilterResultModal.bind(this);
        this.setFormError = this.setFormError.bind(this);
        this.updateSearchResult = this.updateSearchResult.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isLoading !== state.isLoading) {
            props.setLoading(props.isLoading);
            return {
                isLoading: props.isLoading
            }
        }

        return null;
    }

    toggleFilterResultModal() {
        this.setState({
            isFilterResultModalShow: !this.state.isFilterResultModalShow
        });
    }

    setFormError(isFormError) {
        this.setState({
            isFormError: isFormError,
        })
    }

    updateSearchResult(foundDevices) {
        this.setState({
            searchFormResult: foundDevices
        }, () => {
            this.toggleFilterResultModal();
            this.props.setLoading(false);
        });
    }

    handleFormSubmit() {
        this.refs.filterFormRef.submitForm();
        this.props.setLoading(true);
    }

    render() {
        const {
            isFilterResultModalShow,
            isFormError,
            searchFormResult
        } = this.state;

        const {
            filterOptions,
            isMobileView,
            deviceType,
            history
        } = this.props;

        return (
            <Row className="mt-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Znajdź urządzenie</h6>
                        </CardHeader>
                        <CardBody>
                            <DeviceSearcherForm
                                setFormError={this.setFormError}
                                isMobileView={isMobileView}
                                filterOptions={filterOptions}
                                onSuccess={this.updateSearchResult}
                                deviceType={deviceType}
                                ref="filterFormRef" />

                            <Button onClick={this.handleFormSubmit} disabled={isFormError}
                                outline theme="info" className="mb-2 mr-1 float-right">
                                Szukaj
                            </Button>
                        </CardBody>
                    </Card>
                </Col>

                <DeviceSearcherResultModal open={isFilterResultModalShow} toggle={this.toggleFilterResultModal}
                    foundDevices={searchFormResult} history={history} deviceType={deviceType} />
            </Row>
        );
    }
}

export default withLoader(PcDeviceSearcher);
