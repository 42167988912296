import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  InputGroup,
  InputGroupAddon,
  Button
} from "shards-react";

import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import styled from 'styled-components';
import BootstrapTable from "react-bootstrap-table-next";
import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import * as SocToast from '../../utils/SocToast';
import TaskDetailsModal from './modals/TaskDetailsModal';

const JIRA_TASK_URL = 'https://perceptus.atlassian.net/jira/software/c/projects/EID/issues/EID-';

class AllTaskDisplay extends Component {

    constructor(props){
        super(props);

        this.state = {
            tasks: [],
            isViewTaskDetailsModalShow: false,
            selectedTask: '',
            selectedTaskStatus: '',
            search: '',
            paginationOptions: {
                custom: true,
                totalSize: 0,
                sizePerPage: 10
            }
        }

        this.jiraUrl = this.jiraUrl.bind(this);
        this.searchInputHandler = this.searchInputHandler.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { tasks } = props;

        if (tasks !== state.tasks) {
            return {
                tasks: tasks,
                paginationOptions: {
                    custom: true,
                    totalSize: tasks.length,
                    sizePerPage: 10
                }
            };
        }

        return null;
    }

    searchInputHandler(value) {

      if(value === '') this.props.search('');

      this.setState({
          ...this.state,
          search: value
      })
    }

    jiraUrl(jiraId) {
      const JiraURL = styled.a`
        cursor: pointer !important;
        &:hover {
          text-decoration: underline !important;
        }
      `;

      return <JiraURL key={jiraId} target="_blank" href={`${JIRA_TASK_URL}${jiraId}`}>{jiraId}</JiraURL>;
    }

    endTask = (id) => {
        this.closeReportRequest(id);

        if(this.state.isViewTaskDetailsModalShow){
            this.closeModal();
        }
    }

    viewDetails = (id, status) => {
        this.setState({
            selectedTask: id,
            selectedTaskStatus: status,
            isViewTaskDetailsModalShow: true
        });
    }

    closeModal = () => {
        this.setState({
            selectedTask: '',
            selectedTaskStatus: '',
            isViewTaskDetailsModalShow: false
        });
    };


    viewStatus = (status) => {
        switch (status){
            case "done": return <div style={{color:'green'}}>Zakończony</div>
            case "todo": return <div style={{color:'orange'}}>W trakcie</div>
            default: return null
        }
    }


    closeReportRequest = (jiraId) => {
        API.put(`${Constants.REPORTS_URL}/${jiraId}`)
        .then(response => {
                if(response.status === 200) {
                    SocToast.success(`Zgłosznenie #${jiraId} zostało zamknięte`);
                    this.props.refresh();
                } else {
                    SocToast.error("Nie udało się zamknąć zgłoszenia");
                }
        });
    }


    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    detectedEventColumns = [
                     {
                        dataField: 'value.id',
                        headerAlign: 'center',
                        align: "center",
                        sort: true,
                        text: "Identyfikator",
                        headerClasses: "column-header",
                        style: () => {
                            return {
                                verticalAlign: 'middle'
                            };
                        },
                       formatter: (cellContent, row) => {
                          return this.jiraUrl(cellContent);
                       }
                    },{
                        dataField: 'value.category',
                        headerAlign: 'center',
                        align: "center",
                        text: "Kategoria",
                        sort: true,
                        headerClasses: "column-header",
                        style: () => {
                            return {
                                verticalAlign: 'middle'
                            };
                        },
                    }, {
                        dataField: 'value.title',
                        headerAlign: 'center',
                        align: "center",
                        text: "Tytuł",
                        sort: true,
                        headerClasses: "column-header",
                        style: () => {
                            return {
                                verticalAlign: 'middle'
                            };
                        },
                    }, {
                        dataField: 'value.status',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        sort: true,
                        headerClasses: "column-header",
                        style: (cell) => {
                            if(cell === "W trakcie"){
                                return {
                                  color: 'orange'
                                };
                            } else if(cell === "Zakończony"){
                                return {
                                    color: 'green'
                                };
                            }
                        }
                    }, {
                        dataField: 'value.action',
                        headerAlign: 'center',
                        align: "center",
                        text: "Akcja",
                        headerClasses: "column-header",
                        style: () => {
                            return {
                                verticalAlign: 'middle'
                            };
                        },
                    }]

    render() {

        return(
            <Card small className="mb-4">
              <CardHeader className="border-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6 className="m-0">{this.props.title}</h6>
                <InputGroup style={{ width: '35%', marginRight: '7%' }}>
                    <FormInput placeholder="Szukaj na liście zgłoszeń" value={this.state.search} onChange={e => this.searchInputHandler(e.target.value)} />
                    <InputGroupAddon type="append">
                        <Button theme="secondary" onClick={e => this.state.search ? this.props.search(this.state.search) : null}>Szukaj</Button>
                    </InputGroupAddon>
                </InputGroup>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                          <PaginationProvider pagination={paginationFactory(this.state.paginationOptions)}>
                              {({ paginationProps, paginationTableProps }) => (
                                  <Row className="mr-0 ml-0">
                                      <Col xs="12" className="mb-2 pr-0 pl-0">
                                          <BootstrapTable
                                              bootstrap4={true}
                                              keyField='value.id'
                                              data={this.props.tasks}
                                              columns={this.detectedEventColumns}
                                              defaultSorted={ [{ dataField: "value.status", order: "asc" }] }
                                              classes="table mb-0"
                                              headerClasses="column-header text-right"
                                              bordered={false}
                                              wrapperClasses="table-responsive"
                                              {...paginationTableProps} />
                                      </Col>
                                      <Col xs="6" className="d-flex justify-content-start">
                                          <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                                      </Col>
                                      <Col xs="6" className="d-flex justify-content-end">
                                          <div><PaginationListStandalone {...paginationProps} /></div>
                                      </Col>
                                  </Row>
                              )}
                          </PaginationProvider>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
              <TaskDetailsModal open={this.state.isViewTaskDetailsModalShow} uuid={this.state.selectedTask}
                          toggle={this.closeModal} closeReportAction={this.endTask} status={this.state.selectedTaskStatus} />
            </Card>

          );
    }

};


AllTaskDisplay.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

AllTaskDisplay.defaultProps = {
  title: "Lista zgłoszeń"
};

export default AllTaskDisplay;
