import React from "react";
import { ConfirmModal } from "../../common/Modal";
import * as Constants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import * as socToast from '../../../utils/SocToast';

class CustomerTwoFactorModal extends React.Component {

    constructor(props) {
        super(props);

        this.twoFactorDeactivate = this.twoFactorDeactivate.bind(this);
    }

    twoFactorDeactivate(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.put(Constants.AUTHENTICATOR_TOKEN_URL + "/twoFactor/deactivate/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    onSuccess();
                    socToast.success("Operacja przebiegła pomyślnie", "Uwierzytelnianie dwuskładnikowe zostało wyłączone.");
                    toggleFunction();
                }
            }).catch(error => {
                socToast.error("Operacja niepowiodła się", "Wyłączenie uwierzytelniania dwuskładnikowego nie jest możliwe.")
            })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                title={"Jesteś pewien, że chcesz wyłączyć uwierzytelnianie dwuskładnikowe?"}
                open={open}
                toggle={toggle}
                submitAction={this.twoFactorDeactivate}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                Spowoduje to wyłączenie uwierzytelniania dwuskładnikowego temu użytkownikowi.
                Pamiętaj, że opcja ta powinna być używana tylko w wypadku kiedy użytkownik straci dostęp do konta.<br></br><br></br>
                Jeśli jesteś zdecydowany, kliknij "Potwierdź".

            </ConfirmModal>
        )
    }

}

export default CustomerTwoFactorModal;