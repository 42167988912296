import React from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "../assets/login.css";
import API from "../api/AxiosConfiguration";
import * as Constants from '../constants';
import { get } from '@github/webauthn-json';
import * as socToast from '../utils/SocToast'
import { Alert } from "shards-react";
import {
    Row,
    Col,
    Container
} from "shards-react";

import SocLogo from '../images/logo_SOC.png';
import ProjectLogos from '../images/loga_projektowe.png';
import Loader from 'react-loader-spinner';
import Regulations from '../images/REGULAMIN__v.1.2.pdf';
import GoogleReCaptcha from "../components/recaptcha/GoogleReCaptcha"

export class TwoFactorLogin extends React.Component {

    interval = 0;

    constructor(props) {
        super(props);
        this.state = {
            message: '',
            stage: 1,
            email: '',
            password: '',
            tokenUuid: 0,
            tokenName: 0,
            twoFactorOperation: '',
            token: '',
            twoFactorList: [],
            isMobile: false,
            redirect: this.props.location.state ? this.props.location.state.redirect : null
        };
        this.refreshIntervalInMillis = 5000;
        this.reCaptchaComponentRef = React.createRef();
    }


    componentDidMount() {
        this._isMounted = true;
        this.interval = setInterval(() => {
            if (this._isMounted && this.state.stage === 4) {
                this.validTrustedDevice();
            }
        }, this.refreshIntervalInMillis);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.interval);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.location.state) {
            var message = props.location.state.message;
            if (message !== state.message) {
                return { message: message }
            }
        }

        return null;
    }

    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleTokenUuidChange = (e) => {
        var token = JSON.parse(e.target.value)
        this.setState({
            tokenUuid: token.value,
            tokenName: token.name
        });
    }

    handleTokenChange = (e) => {
        this.setState({
            token: e.target.value
        });
    }

    redirectToPath = () => {
        this.props.history.push(this.state.redirect !== null ? this.state.redirect : '/');
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    MakeItem = function (X) {
        return <option value={X}>{X}</option>;
    };

    signIn = async () => {
        const userToLogin = {
            email: this.state.email,
            password: this.state.password
        }
        
        let reCaptchaToken = ''
    
        try {
            reCaptchaToken = await this.reCaptchaComponentRef.current.executeCaptcha()
        } catch (error) {
            console.error(error)
        }
        
        const config = { 
            headers: { 'g-recaptcha': reCaptchaToken }
        }

        API.post(Constants.LOGIN_URL, userToLogin, config).then(response => {
            if (response) {
                if (response.data.twoFA) {
                    this.setState({ twoFactorList: response.data.authenticatorTokenList });
                    this.setState({
                        tokenUuid: this.state.twoFactorList[0].tokenUuid,
                        tokenName: this.state.twoFactorList[0].tokenName
                    });
                    if (response.data.authenticatorTokenList.length === 1) {
                        const send = {
                            email: this.state.email,
                            password: this.state.password,
                            tokenUuid: this.state.tokenUuid
                        }
                        API.post(Constants.LOGIN_SEND_TWO_FACTOR_URL, send).then(response => {
                            if (response) {
                                if (response.data.twoFA) {
                                    if (response.data.assertionStartResponse) {
                                        this.handleAssertionStart(response.data.assertionStartResponse);
                                    } else if (response.data.twoFactorOperation !== undefined && response.data.twoFactorOperation !== null) {
                                        this.setState({ twoFactorOperation: response.data.twoFactorOperation, stage: 4 });
                                    } else {
                                        this.setState({ stage: 3 });
                                    }
                                } else {
                                    this.props.history.push('/login');
                                }
                            }
                        });
                    } else {
                        this.setState({ stage: 2 });
                    }
                } else {
                    localStorage.setItem('user', JSON.stringify({ "email": this.state.email, "name": response.data.name, "role": response.data.role, "privileges": response.data.privileges, "company": response.data.company }));
                    this.redirectToPath();
                }
            }
        }).catch((error) => { socToast.error("Błąd logowania", "Błędny login lub hasło. Być może twoje konto zostało zablokowane.") });
    }

    sendToken = () => {

        const send = {
            email: this.state.email,
            password: this.state.password,
            tokenUuid: this.state.tokenUuid
        }
        API.post(Constants.LOGIN_SEND_TWO_FACTOR_URL, send).then(response => {
            if (response) {
                if (response.data.twoFA) {
                    if (response.data.assertionStartResponse) {
                        this.handleAssertionStart(response.data.assertionStartResponse);
                    } else if (response.data.twoFactorOperation !== undefined && response.data.twoFactorOperation !== null) {
                        this.setState({ twoFactorOperation: response.data.twoFactorOperation, stage: 4 });
                    } else {
                        this.setState({ stage: 3 });
                    }
                } else {
                    this.props.history.push('/login');
                }
            }
        });
    }

    validToken = () => {

        const vtwofa = {
            email: this.state.email,
            password: this.state.password,
            token: this.state.token,
            tokenUuid: this.state.tokenUuid
        }
        API.post(Constants.LOGIN_TWO_FACTOR_URL, vtwofa).then(response => {
            if (response) {
                if (response.data.statusAndCodeResponse.status === "Success") {
                    localStorage.setItem('user', JSON.stringify({ "email": this.state.email, "name": response.data.name, "role": response.data.role, "privileges": response.data.privileges, "company": response.data.company }));
                    this.redirectToPath();
                } else {
                    this.props.history.push('/login');
                }
            }
        }).catch((error) => { socToast.error("Błąd Logowania", "Niepoprawny token") });
    }

    validTrustedDevice = () => {

        const vtwofa = {
            email: this.state.email,
            password: this.state.password,
            twoFactorOperation: this.state.twoFactorOperation,
            tokenUuid: this.state.tokenUuid
        }
        API.post(Constants.LOGIN_TWO_FACTOR_URL, vtwofa).then(response => {
            if (response) {
                if (response.data.statusAndCodeResponse.status === "Success") {
                    localStorage.setItem('user', JSON.stringify({ "email": this.state.email, "name": response.data.name, "role": response.data.role, "privileges": response.data.privileges, "company": response.data.company }));
                    this.redirectToPath();
                } else {
                    this.props.history.push('/login');
                }
            }
        }).catch((error) => { });
    }

    async handleAssertionStart(response) {
        const credential = await get({
            publicKey: response.publicKeyCredentialRequestOptions
        });

        try {
            // @ts-ignore
            credential.clientExtensionResults = credential.getClientExtensionResults();
        } catch (e) {
            // @ts-ignore
            credential.clientExtensionResults = {};
        }

        const vtwofa = {
            email: this.state.email,
            password: this.state.password,
            assertionFinishRequest: { assertionId: response.assertionId, credential },
            tokenUuid: this.state.tokenUuid
        }

        API.post(Constants.LOGIN_TWO_FACTOR_URL, vtwofa).then(response => {
            if (response) {
                if (response.data.statusAndCodeResponse.status === "Success") {
                    localStorage.setItem('user', JSON.stringify({ "email": this.state.email, "name": response.data.name, "role": response.data.role, "privileges": response.data.privileges, "company": response.data.company }));
                    this.redirectToPath();
                } else {
                    this.props.history.push('/login');
                }
            }
        });
    }

    renderStageOne() {
        return (
            <div className="Login" >
                <form onSubmit={this.handleSubmit} >
                    {this.state.message && <Alert theme="primary">{this.state.message}</Alert>}

                    <FormGroup controlId="email" bsSize="large">
                        <ControlLabel> Email </ControlLabel>
                        <FormControl autoFocus type="email" value={this.state.email} onChange={this.handleEmailChange} />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large" >
                        <ControlLabel > Hasło </ControlLabel>
                        <FormControl value={this.state.password} onChange={this.handlePasswordChange} type="password" />
                    </FormGroup>
                    <Button onClick={this.signIn} bsStyle="primary" block bsSize="large" disabled={!this.validateForm()} type="submit" > Zaloguj </Button>
                    <a href={Regulations} target="_blank"  rel="noopener noreferrer" className="float-left" style={{ fontSize: "12px" }}>Regulamin</a>
                    <a href="/forgot-password" className="float-right" style={{ fontSize: "12px" }}>Zapomniałeś hasła?</a>
                </form >
            </div>
        )
    }

    renderStageTwo() {
        return (
            <div className="Login" >
                <form onSubmit={this.handleSubmit} >
                    <FormGroup controlId="changeToken" bsSize="large" >
                        <ControlLabel > Wybierz sposób uwierzytelnienia</ControlLabel><br></br>
                        <FormControl componentClass="select" placeholder="select" value={JSON.stringify({ "value": this.state.tokenUuid, "name": this.state.tokenName })} onChange={this.handleTokenUuidChange}>
                            {this.state.twoFactorList.map((token) => <option name={token.tokenName} key={token.tokenUuid} value={JSON.stringify({ "value": token.tokenUuid, "name": token.tokenName })}>{token.tokenName}</option>)}
                        </FormControl>
                    </FormGroup>
                    <Button onClick={this.sendToken} block bsStyle="primary" bsSize="large" type="submit" > Wybierz </Button>
                </form >
            </div>
        )
    }

    renderStageThree() {
        const { tokenName } = this.state;
        return (
            <div className="Login" >
                <form onSubmit={this.handleSubmit} >
                    <FormGroup controlId="token" bsSize="large">
                        <ControlLabel> Wpisz kod wygenerowany z : {tokenName}</ControlLabel>
                        <FormControl autoFocus value={this.state.token} onChange={this.handleTokenChange} />
                    </FormGroup>
                    <Button onClick={this.validToken} block bsStyle="primary" bsSize="large" type="submit" > Sprawdź Token </Button>
                </form >
            </div>
        )
    }

    renderStageFour() {
        const { tokenName } = this.state;
        return (
            <div className="Login" >
                <form>
                    <FormGroup controlId="token" bsSize="large">
                        <ControlLabel> Potwierdź operację na urządzeniu zaufanym: {tokenName}</ControlLabel>
                        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: "1" }}>
                            <Loader
                                type="ThreeDots"
                                color="#007bff"
                                height={27}
                                width={27}
                                visible={true} />
                        </div>
                    </FormGroup>
                </form >
            </div>
        )
    }

    renderCurrentStage() {
        switch (this.state.stage) {
            case 1:  return this.renderStageOne()
            case 2:  return this.renderStageTwo()
            case 3:  return this.renderStageThree()
            case 4:  return this.renderStageFour()
            default: return <></>;
        }
    }

    render() {

        return (
            <Container className="d-flex flex-column py-3" style={{ height: '100vh', position: 'relative' }}>

                <Row style={{ flex: "0 1 150px" }}>
                    <Col xs={{ size: 8, offset: 2 }} md={{ size: 4, offset: 4 }} className="text-center px-md-5">
                        <img src={SocLogo} alt="SOC logo" className="pb-1" style={{ height: "inherit", maxWidth: "100%", objectFit: "contain" }} />
                    </Col>
                </Row>

                <Row className="overflow-auto pb-1" style={{ flex: "1 1 auto", minHeight: "100px" }}>
                    <Col xs={12} md={{ size: 4, offset: 4 }} className="overflow-auto">
                        {this.renderCurrentStage()}
                    </Col>
                </Row>

                <Row style={{ flex: "0 1 100px" }}>
                    <Col xs={12} md={{ size: 8, offset: 2 }} className="d-flex flex-column justify-content-end align-items-center">
                        <a href="https://soc.perceptus.pl/o-projekcie/" target="_blank" without rel="noopener noreferrer">
                            <img src={ProjectLogos} alt="Loga projektowe" style={{ height: "inherit", maxWidth: "100%", objectFit: "contain" }} />
                        </a>
                    </Col>
                </Row>

                <GoogleReCaptcha ref={this.reCaptchaComponentRef} />

            </Container>
        );
    }
}
