import React, { useState } from 'react';
import { FormInput, InputGroup, InputGroupAddon, Button } from 'shards-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const DictionaryInput = ({ name, onAdd, invalid = false, className, style }) => {
    const [value, setValue] = useState('');

    return (
        <React.Fragment>
            <InputGroup className={className} style={style}>
                <FormInput
                    name={name}
                    value={value || ''}
                    onChange={(event) => setValue(event.target.value)}
                    invalid={invalid} />
                <InputGroupAddon type="append">
                    <Button onClick={() => { onAdd(name, value) && setValue('') }}><FontAwesomeIcon icon={faPlus} /></Button>
                </InputGroupAddon>
            </InputGroup>
        </React.Fragment>
    )
}

export default DictionaryInput;