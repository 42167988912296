import React from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
} from "shards-react";

import DynamicMenu from "../../menu/DynamicMenu";
import DeviceCard from "../../cards/DeviceCard";
import ConfirmDeviceApplicationModal from "./modals/ConfirmDeviceApplicationModal";
import DeleteDeviceApplicationModal from "./modals/DeleteDeviceApplicationModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from '../../../constants';
import ConfirmDeviceGroupModal from './../../device-group/modals/ConfirmDeviceGroupModal';
import { Store } from "../../../flux";
import DeleteDeviceGroupModal from "../../device-group/modals/DeleteDeviceGroupModal";
import withLoader from "../../common/hoc/withLoader";

class PcDeviceMenu extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isDeleteDeviceModalShow: false,
            isCreateDeviceModalShow: false,
            isCreateDeviceGroupModalShow: false,
            isDeleteDeviceGroupModalShow: false,
            deviceGroupPath: '',
            isGroupSelected: false,
            selectedGroup: {},
            selectedDeviceUUID: '',
            selectedDeviceGroupUUID: '',
        };

        this.selectDeviceGroup = this.selectDeviceGroup.bind(this);
        this.backDeviceGroup = this.backDeviceGroup.bind(this);
        this.toggleCreateDeviceGroupModal = this.toggleCreateDeviceGroupModal.bind(this);
        this.toggleDeleteDeviceGroupModal = this.toggleDeleteDeviceGroupModal.bind(this);
        this.toggleDeleteDeviceModal = this.toggleDeleteDeviceModal.bind(this);
        this.toggleCreateDeviceModal = this.toggleCreateDeviceModal.bind(this);
        this.updateDeviceMenu = this.updateDeviceMenu.bind(this);
        this.updateDeviceGroupName = this.updateDeviceGroupName.bind(this);
        this.redirectToDetails = this.redirectToDetails.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.showDeviceGroupContext();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    redirectToDetails(uuid) {
        this.props.history.push({
            pathname: "/pc-details-dl",
            state: {
                uuid: uuid,
            }
        });
    }

    toggleDeleteDeviceModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteDeviceModalShow: !this.state.isDeleteDeviceModalShow,
                selectedDeviceUUID: uuid,
            });
    }

    toggleCreateDeviceGroupModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateDeviceGroupModalShow: !this.state.isCreateDeviceGroupModalShow,
                selectedDeviceGroupUUID: uuid,
            });
    }

    toggleDeleteDeviceGroupModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteDeviceGroupModalShow: !this.state.isDeleteDeviceGroupModalShow,
                selectedDeviceGroupUUID: uuid,
            });
    }

    toggleCreateDeviceModal() {
        if (this._isMounted)
            this.setState({
                isCreateDeviceModalShow: !this.state.isCreateDeviceModalShow,
            });
    }

    fetchDeviceGroups() {
        return API.post(AppConstants.PC_DEVICE_DEVICELOCK_URL, {
        })
    }

    fetchDevicesByUUID(groupUUID) {
        return API.post(AppConstants.PC_DEVICE_DEVICELOCK_URL, {
            uuid: groupUUID,
        })
    }

    showDeviceGroupContext() {
        this.props.setLoading(true);
        this.fetchDeviceGroups().then((result) => {
            var menu = result.data;
            if (this._isMounted)
                this.setState({
                    selectedGroup: menu
                })
            this.props.setLoading(false);
        });
    }

    updateDeviceGroupName(form, uuid) {
        return API.put(AppConstants.DEVICE_GROUPS_URL + "/" + uuid, {
            form: form,
        })
    }

    updateDeviceMenu() {
        this.props.setLoading(true);
        var deviceGroupCurrentUUID = this.state.deviceGroupPath.split("/").pop();
        if (deviceGroupCurrentUUID) {
            this.fetchDevicesByUUID(deviceGroupCurrentUUID).then((result) => {
                var menu = result.data;
                this.setDeviceGroupContent(menu, deviceGroupCurrentUUID);
                this.props.setLoading(false);
            });
        } else {
            this.fetchDeviceGroups(deviceGroupCurrentUUID).then((result) => {
                var menu = result.data;
                if (this._isMounted)
                    this.setState({
                        selectedGroup: menu
                    });
                this.props.setLoading(false);
            })
        }

        this.props.onDeviceUpdate();
    }

    selectDeviceGroup(groupUUID) {
        this.props.setLoading(true);
        this.fetchDevicesByUUID(groupUUID).then((result) => {
            var menu = result.data;
            this.setDeviceGroupContent(menu, groupUUID);
            this.props.setLoading(false);
        })
    }

    setDeviceGroupContent(menu, deviceGroupUUID) {
        if (this._isMounted) {
            var foundGroup = menu.deviceGroups.find((item) => {
                if (item.uuid === deviceGroupUUID) {
                    if (!this.state.deviceGroupPath.includes(deviceGroupUUID)) {
                        this.setState({
                            deviceGroupPath: this.state.deviceGroupPath + "/" + item.uuid
                        })
                    }
                    return true;
                }
                return false;
            });

            if (!Boolean(foundGroup))
                this.setState({
                    isGroupSelected: false,
                    selectedGroup: menu,
                    deviceGroupPath: ''
                });
            else
                this.setState({
                    isGroupSelected: true,
                    selectedGroup: foundGroup
                });
        }
    }

    backDeviceGroup() {
        this.props.setLoading(true);
        var deviceGroupMenuPath = this.state.deviceGroupPath;
        deviceGroupMenuPath = deviceGroupMenuPath.substr(0, deviceGroupMenuPath.lastIndexOf("/"));

        var requestPromise;
        var deviceGroupparentValue = deviceGroupMenuPath.split("/").pop();
        if (deviceGroupparentValue) {
            requestPromise = this.fetchDevicesByUUID(deviceGroupparentValue);
        } else {
            requestPromise = this.fetchDeviceGroups();
        }

        requestPromise.then((result) => {
            var selectedGroupMenu = result.data;
            if (this._isMounted)
                this.setState({
                    deviceGroupPath: deviceGroupMenuPath,
                    isGroupSelected: deviceGroupMenuPath !== "",
                    selectedGroup: selectedGroupMenu
                });
            this.props.setLoading(false);
        });
    }

    acceptDevice(uuid) {
        API.get(AppConstants.PC_DEVICE_DEVICELOCK_URL + "/" + uuid + "/accept")
            .then(result => {
                if (result.status === 200) {
                    this.updateDeviceMenu();
                }
            });
    }

    declineDevice(uuid) {
        API.get(AppConstants.PC_DEVICE_DEVICELOCK_URL + "/" + uuid + "/decline")
            .then(result => {
                if (result.status === 200) {
                    this.updateDeviceMenu();
                }
            });
    }

    render() {
        const {
            isAdminAtLeast,
            isDeleteDeviceModalShow,
            isCreateDeviceModalShow,
            isCreateDeviceGroupModalShow,
            isDeleteDeviceGroupModalShow,
            isGroupSelected,
            selectedGroup,
            selectedDeviceUUID,
            selectedDeviceGroupUUID,
        } = this.state;

        const {
            isMobileView,
        } = this.props;

        const buildDeviceMenuStructure = () => {
            const { selectedGroup } = this.state;
            if (selectedGroup.deviceGroups) {
                var subGroups = selectedGroup.deviceGroups.map((item) => {
                    var smallTitle = '';
                    if (item.uuid !== "PENDING" && item.uuid !== "REJECTED" && item.allDevices)
                        smallTitle = 'dostępnych: ' + item.activeDevices + ' z ' + item.allDevices;

                    if (item.uuid !== "PENDING" && item.uuid !== "REJECTED" && !item.allDevices)
                        smallTitle = 'Brak urządzeń'

                    return (
                        <DynamicMenu key={item.uuid} title={item.name} uuid={item.uuid}
                            smallTitle={smallTitle}
                            onClick={() => this.selectDeviceGroup(item.uuid)} updateNameApiCall={this.toggleCreateDeviceGroupModal}
                            onEditClick={this.toggleCreateDeviceGroupModal}
                            onDeleteClick={this.toggleDeleteDeviceGroupModal}
                            deleteEnable={isAdminAtLeast && (item.uuid !== "PENDING" && item.uuid !== "REJECTED")}
                            noTitleEdit={!isAdminAtLeast || (item.uuid === "PENDING" || item.uuid === "REJECTED")}></DynamicMenu>
                    );
                })
            }

            if (selectedGroup.devices) {
                var devices = selectedGroup.devices.map((item, idx) => {
                    return (
                        <Col key={idx} md="6" lg="4" >
                            <DeviceCard
                                name={item.name}
                                status={item.status}
                                action={item.action}
                                isPending={item.status === "PENDING"}
                                isRejected={item.status === "REJECTED"}
                                deviceDetails={item.details}
                                withoutButtons={!item.modifiable}
                                onDetails={() => this.redirectToDetails(item.uuid)}
                                onDelete={() => this.toggleDeleteDeviceModal(item.uuid)}
                                onAcceptDevice={() => this.acceptDevice(item.uuid)}
                                onDeclineDevice={() => this.declineDevice(item.uuid)} />
                        </Col>
                    );
                });
            }

            if (!(selectedGroup.deviceGroups && selectedGroup.deviceGroups.length > 0)
                && !(selectedGroup.devices && selectedGroup.devices.length > 0)) {
                return (
                    <Row className="pt-2" style={{ justifyContent: "center" }}>
                        <h6>Brak dostępnych urządzeń</h6>
                    </Row>
                )
            }

            return (
                <div>
                    {subGroups}
                    <Row className="pt-2" xs="1" sm="2" md="3">
                        {devices}
                    </Row>
                </div>
            )
        }

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom d-inline">
                            <div className="back-button no-select">
                                {isGroupSelected && <FontAwesomeIcon className="back-button-icon" icon={faChevronLeft} onClick={this.backDeviceGroup} />}
                            </div>
                            <h6 className="m-0">Urządzenia <small>{isGroupSelected && '(należące do ' + selectedGroup.name + ')'}</small></h6>
                        </CardHeader>
                        <CardBody>
                            {isAdminAtLeast && <Button onClick={this.toggleCreateDeviceGroupModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową grupę
                            </Button>}

                            <Button onClick={this.toggleCreateDeviceModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nowe urządzenie
                            </Button>
                            {buildDeviceMenuStructure()}
                        </CardBody>
                    </Card>
                </Col>

                <DeleteDeviceApplicationModal open={isDeleteDeviceModalShow} uuid={selectedDeviceUUID}
                    toggle={this.toggleDeleteDeviceModal} onSuccess={this.updateDeviceMenu} />

                <ConfirmDeviceApplicationModal open={isCreateDeviceModalShow} toggle={this.toggleCreateDeviceModal} onSuccess={this.updateDeviceMenu}
                    isMobileView={isMobileView} />

                <ConfirmDeviceGroupModal open={isCreateDeviceGroupModalShow} uuid={selectedDeviceGroupUUID}
                    toggle={this.toggleCreateDeviceGroupModal} onSuccess={this.updateDeviceMenu} isMobileView={isMobileView} />

                <DeleteDeviceGroupModal open={isDeleteDeviceGroupModalShow} uuid={selectedDeviceGroupUUID}
                    toggle={this.toggleDeleteDeviceGroupModal} onSuccess={this.updateDeviceMenu} isMobileView={isMobileView} />
            </Row>
        )
    }

}

export default withLoader(PcDeviceMenu);
