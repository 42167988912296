import React from "react";
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'shards-react';
import DeleteAccountConfirmation from './../components/delete-account/DeleteAccountConfirmation';

class DeleteAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAccountDeleted: false,
            deleteAccountToken: ''
        };

        this.setAccountDeletedTrue = this.setAccountDeletedTrue.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var deleteAccountToken = props.match.params.token;

        if (deleteAccountToken !== state.deleteAccountToken) {
            return { deleteAccountToken: deleteAccountToken }
        }

        return null;
    }

    setAccountDeletedTrue() {
        this.setState({
            isAccountDeleted: true
        });
    }

    render() {
        const { isAccountDeleted, deleteAccountToken } = this.state;

        if (!isAccountDeleted) {
            return (
                <Row>
                    <Col xs={12} md={{ size: 4, offset: 4 }}>
                        <DeleteAccountConfirmation deleteAccountToken={deleteAccountToken} onSuccess={this.setAccountDeletedTrue} />
                    </Col>
                </Row>
            );
        } else {
            return <Redirect to={{
                pathname: "/login",
                state: { message: "Konto zostało usunięte" }
            }} />;
        }
    }

}

export default DeleteAccount;