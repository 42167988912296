import React from "react";
import { Card, CardBody, CardHeader } from "shards-react";
import WebPushActivityForm from './forms/WebPushActivityForm';

class CustomerWebPushActivity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Card className="mb-3 w-100">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Konfiguracja aktywności web push</h6>
                    <div className="block-handle" />
                </CardHeader>

                <CardBody className="py-1">
                    <div>Jeżeli chcesz otrzymywać powiadomienia web push na tym urządzeniu wciśnij przycisk "Aktywuj".
                    W razie rezygnacji z tej usługi wciśnij przycisk "Wyłącz".
                    Pamiętaj, że usługa ta jest przypisana do konkretnej przeglądarki internetowej.
                    W przypadku zmiany lub ponownej instalacji przeglądarki należy ponowić aktywację.
                    </div>
                </CardBody>
                <WebPushActivityForm />
            </Card>
        );
    }

}

export default CustomerWebPushActivity;