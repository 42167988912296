import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card, CardBody } from "shards-react";

class SmallCard extends React.Component {

  render() {
    const { label, children, withoutStyling } = this.props;

    const cardClasses = classNames(
      "stats-small",
      "stats-small--1"
    );

    const cardBodyClasses = classNames(
      "p-0 d-flex"
    );

    const innerWrapperClasses = classNames(
      "d-flex",
      "flex-column mt-3 mr-auto ml-auto mb-auto",
    );

    const dataFieldClasses = classNames(
      "stats-small__data",
      "text-center"
    );

    const labelClasses = classNames(
      "stats-small__label",
      "text-uppercase",
    );

    const valueClasses = classNames(
      !withoutStyling && "stats-small__value",
      !withoutStyling && "count",
      "my-3"
    );

    return (
      <Card small className={cardClasses}>
        <CardBody className={cardBodyClasses}>
          <div className={innerWrapperClasses}>
            <div className={dataFieldClasses}>
              <span className={labelClasses}>{label}</span>
              <div className={valueClasses}>{children}</div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

SmallCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withoutStyling: PropTypes.bool
};

SmallCard.defaultProps = {
  value: 0,
  label: "Label",
  withoutStyling: false
};

export default SmallCard;
