import React from "react";
import PasswordResetForm from "./forms/PasswordResetForm";

class PasswordReset extends React.Component {

    render() {
        const {
            resetPasswordToken,
            onSuccess
        } = this.props;

        return (
            <div>
                <h4 className="text-center">Zmiana hasła</h4>
                <PasswordResetForm resetPasswordToken={resetPasswordToken} onSuccess={onSuccess} />
            </div>
        );
    }

}

export default PasswordReset;
