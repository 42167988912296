import React from "react";
import { Container } from "shards-react";
import Customers from "../components/customers/Customers";
import AccessGroups from "../components/customers/AccessGroups";

class User extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            randomKeyForForceUpdate: Math.random()
        };

        this.updateComponent = this.updateComponent.bind(this);
    }

    updateComponent() {
        this.setState({
            randomKeyForForceUpdate: Math.random()
        });
    }

    render() {
        const { randomKeyForForceUpdate } = this.state;

        return (
            <Container fluid className="main-content-container px-4">
                <AccessGroups onUpdate={this.updateComponent} />
                <Customers key={randomKeyForForceUpdate} />
            </Container>
        );
    }

}

export default User;
