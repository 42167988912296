import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
    this._isMounted = false;
  }

  onChange() {
    if (this._isMounted)
      this.setState({
        ...this.state,
        navItems: Store.getSidebarItems()
      });
  }

  checkRole(item) {
    var user = JSON.parse(localStorage.getItem('user'));
    var userRole = user.role;

    if (typeof item.authorities !== 'undefined' && item.authorities !== null)
      return item.authorities && item.authorities.indexOf(userRole) !== -1

    return true;
  }

  checkPrivileges(item) {
    var user = JSON.parse(localStorage.getItem('user'));
    var userPrivileges = user.privileges;

    var containsPrivilege = false;
    if (item.privileges) {
      item.privileges.forEach((itemPrivilege) => {
        if (userPrivileges && userPrivileges.includes(itemPrivilege)) {
          containsPrivilege = true;
        }
      });
    } else {
      containsPrivilege = true;
    }

    return containsPrivilege;
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {
            items.filter(item => item.authorities ? this.checkRole(item) : true)
              .filter(item => item.privileges ? this.checkPrivileges(item) : true)
              .map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
              ))
          }
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
