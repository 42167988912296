import React from 'react';
import { Col, FormInput, Row } from 'shards-react';
import MultiSelect from "@kenshooui/react-multi-select";
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from "../../../constants";
import { getCustomersForSelect, getDeviceGroupForm } from "../../../api/ApiService";
import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'

import "@kenshooui/react-multi-select/dist/style.css"
import "../../../assets/two-sides-multiselect.css"

class ConfirmDeviceGroupForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: '',
                managers: [],
            },
            formErrors: {
                name: [],
                managers: [],
            },
            formHints: {
                managers: [],
            },
            isOptionsLoading: false,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.fetchCustomersAndForm = this.fetchCustomersAndForm.bind(this);
        this.fetchDeviceGroupForm = this.fetchDeviceGroupForm.bind(this);
        this.multiselectChangeHandler = this.multiselectChangeHandler.bind(this);
    }

    componentDidMount() {
        this.fetchCustomersAndForm();
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    multiselectChangeHandler(selectedItems) {
        this.setState({
            form: {
                ...this.state.form,
                managers: selectedItems
            }
        });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name } = this.state.form;

        formErrors["name"] = [];

        var errorCount = 0;

        if (hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;
        this.setState({
            formErrors: []
        })

        var requestBody = {
            form: {
                ...form,
                managers: form.managers.map(manager => manager.id)
            }
        }

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.DEVICE_GROUPS_URL + "/" + uuid, requestBody)
            } else {
                apiPromise = API.put(AppConstants.DEVICE_GROUPS_URL, requestBody);
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }

                if (response && response.status === 409) {
                    this.setState({
                        formErrors: {
                            ...formErrors,
                            name: ["Nazwa grupy nie jest unikatowa"]
                        }
                    });
                }
            });
        }
    }

    fetchCustomersAndForm() {
        const { uuid } = this.props;

        getCustomersForSelect(AppConstants.Roles.USER).then(customers => {
            this.setState({
                formHints: {
                    ...this.state.formHints,
                    managers: customers.map(customer => { return { id: customer.value, label: customer.label } })
                }
            }, () => {
                if (typeof uuid === 'string' || uuid instanceof String)
                    this.fetchDeviceGroupForm()
            })
        })
    }

    fetchDeviceGroupForm() {
        const { uuid } = this.props;
        const { managers } = this.state.formHints;

        getDeviceGroupForm(uuid).then(deviceGroupForm => {
            this.setState({
                form: {
                    ...this.state.form,
                    name: deviceGroupForm.name,
                    managers: deviceGroupForm.managers.map(selectedManagerUuid => {
                        var foundManager = managers.find(manager => manager.id === selectedManagerUuid);
                        return foundManager;
                    })
                }
            })
        });
    }

    render() {
        const { name, managers } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasManagersError = Boolean(this.state.formErrors.managers.length);

        const multiSelectMessages = {
            searchPlaceholder: "Szukaj...",
            noItemsMessage: "Nie znaleziono użytkowników...",
            noneSelectedMessage: "Nie wybrano",
            selectedMessage: "wybrany/ch",
            selectAllMessage: "Wybierz Wszystkich",
            clearAllMessage: "Wyczyść",
        }

        return (
            <Row>
                <Col sm="12">
                    <label>*Nazwa grupy</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>Menedżerowie</label>
                    <MultiSelect
                        items={this.state.formHints.managers}
                        selectedItems={managers}
                        onChange={this.multiselectChangeHandler}
                        messages={multiSelectMessages}
                        wrapperClassName={hasManagersError ? "invalid" : ""} />
                    {hasManagersError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.managers)}</ul>}

                </Col>
            </Row>
        );
    }

}

export default ConfirmDeviceGroupForm;