import React from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    Alert
} from "shards-react";

import CreateCategoryModal from "./modals/CreateCategoryModal";
import CategoryTable from "./tables/CategoryTable";
import CategoryFilterForm from "./forms/CategoryFilterForm";
import DeleteCategoryModal from "./modals/DeleteCategoryModal";
import API from "../../api/AxiosConfiguration";
import * as AppConstants from '../../constants';
import { Store } from '../../flux';
import withLoader from "../common/hoc/withLoader";

class NotificationManagementPanel extends React.Component {

    _isMounted = false;

    constructor() {
        super();

        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN, isCreateCategoryModalShow: false,
            isDeleteCategoryModalShow: false,
            categories: [],
            requiredFilterOptions: {},
            selectedCategoryUUID: '',
            filters: {},
            manager: {},
        }

        this.toggleCreateCategoryModal = this.toggleCreateCategoryModal.bind(this);
        this.toggleDeleteCategoryModal = this.toggleDeleteCategoryModal.bind(this);
        this.updateCategories = this.updateCategories.bind(this);
        this.fetchCategories = this.fetchCategories.bind(this);
        this.editNotificationType = this.editNotificationType.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({
                manager: Store.getSelectedManager()
            }, () => this.updateCategories())
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const storedSelectedManager = Store.getSelectedManager();
        const selectedManager = this.state.manager;

        if (JSON.stringify(storedSelectedManager) !== JSON.stringify(selectedManager)) {
            if (this._isMounted)
                this.setState({
                    manager: storedSelectedManager
                }, () => this.updateCategories())
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateCategories(filters = {}) {
        this.props.setLoading(true);
        this.fetchCategories(filters).then((result) => {
            if (this._isMounted)
                this.setState({
                    categories: result.data,
                });
            this.props.setLoading(false);
        })
    }

    fetchCategories(form) {
        const { manager } = this.state;

        return API.post(AppConstants.NOTIFICATION_CATEGORY_URL + "/filters", {
            form: form,
        }, {
            params: {
                manager: manager && manager.value
            }
        })
    }

    toggleCreateCategoryModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateCategoryModalShow: !this.state.isCreateCategoryModalShow,
                selectedCategoryUUID: uuid
            })
    }

    toggleDeleteCategoryModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteCategoryModalShow: !this.state.isDeleteCategoryModalShow,
                selectedCategoryUUID: uuid
            });
    }

    editNotificationType(uuid, form) {
        API.put(AppConstants.NOTIFICATION_CATEGORY_URL + "/notificationtype/" + uuid, {
            form: form,
        }).then(result => {
            if (result.status === 200) {
                this.updateCategories();
            }
        });
    }

    render() {
        const { isCreateCategoryModalShow, isDeleteCategoryModalShow, selectedCategoryUUID, categories } = this.state;
        const { onCreate, hasEmailBeenConfigured, emailConfigurationChecked } = this.props;

        const showCategoryTable = () => (
            <CategoryTable className="clearfix"
                categories={categories}
                toggleCreateCategoryModal={this.toggleCreateCategoryModal}
                toggleDeleteCategoryModal={this.toggleDeleteCategoryModal}
                editNotificationType={this.editNotificationType} />
        )

        const showNoCategoryFoundMessage = () => (
            <Row className="pt-2" style={{ justifyContent: "center" }}>
                <h6>Brak zdefiniowanych modeli powiadomienia</h6>
            </Row>
        )

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Modele powiadomienia</h6>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <Button onClick={this.toggleCreateCategoryModal} outline theme="info" className="mb-2 mr-1">
                                        Dodaj nowy model
                                    </Button>
                                </Col>

                                <Col md="4" className="offset-md-4">
                                    <CategoryFilterForm onChangeFilter={this.updateCategories} />
                                </Col>
                            </Row>

                            {emailConfigurationChecked && !hasEmailBeenConfigured &&
                                <Alert theme="warning">
                                    Adres Email w firmie nie jest skonfigurowany. Żeby otrzymywać powiadomienia Email administrator musi skonfigurować dostęp do skrzynki emailowej.
                                </Alert>
                            }
                            {categories.length ? showCategoryTable() : showNoCategoryFoundMessage()}
                        </CardBody>
                    </Card>
                </Col>

                <CreateCategoryModal open={isCreateCategoryModalShow} onSuccess={(manager) => { if (manager) { onCreate(manager) }; this.updateCategories() }}
                    uuid={selectedCategoryUUID} toggle={this.toggleCreateCategoryModal} />

                <DeleteCategoryModal open={isDeleteCategoryModalShow} onSuccess={this.updateCategories}
                    uuid={selectedCategoryUUID} toggle={this.toggleDeleteCategoryModal} />
            </Row>
        );
    }
}

export default withLoader(NotificationManagementPanel);