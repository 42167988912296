import React from "react";
import CustomerProfileActivationForm from './forms/CustomerProfileActivationForm';

class CustomerAccountActivation extends React.Component {

    render() {
        const {
            customerUUID,
            customerDetails,
            onSuccess
        } = this.props;

        return (
            <div>
                <h4 className="text-center">Aktualizacja profilu</h4>
                <CustomerProfileActivationForm uuid={customerUUID} customerDetails={customerDetails} onSuccess={onSuccess} />
            </div>
        );
    }

}

export default CustomerAccountActivation;