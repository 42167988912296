import React from "react";
import {
    Row,
    Col,
    FormInput,
} from "shards-react";

import Select from 'react-select';
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { Store } from "../../../flux";
import * as SocToast from '../../../utils/SocToast'

class UploadAbsenceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                separatorTypeList: [{label:"Przecinek", value:"1"}, {label:"Średnik", value:"2"}],
                separatorType: {label:"Przecinek", value:"1"},
                csvFile: undefined
            },
            formErrors: {
                separatorType: [],
                csvFile: [],
            },
            isMobileView: false
        };

        this.onWindowResizeFluxListener = this.onWindowResizeFluxListener.bind(this);

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        Store.addChangeListener(this.onWindowResizeFluxListener);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onWindowResizeFluxListener);
    }

    onWindowResizeFluxListener() {
        this.setState({
            ...this.state,
            isMobileView: Store.isMobileView()
        })
    }

    selectChangeHandler = (item, event) => {
        var { form } = this.state;
        form[event.name].label = item ? item.label : '';
        form[event.name].value = item ? item.value : '';
        this.setState({ form: form });
    }

    handleChange = (event) => {
        var { form } = this.state;
        form["csvFile"] = event.target.files[0];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { separatorType, csvFile } = this.state.form;

        formErrors["separatorType"] = [];
        formErrors["csvFile"] = [];

        var errorCount = 0;

        if (separatorType.value <= 0) {
            formErrors["separatorType"].push("Typ separatora musi zostać wybrany");
            errorCount++;
        }

        if (!csvFile) {
            formErrors["csvFile"].push("Plik csv musi zostać dodany");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form } = this.state;

        let formData = new FormData();
        formData.append('file', form.csvFile);

        if (this.validForm()) {
            API.post(Constants.CONTROL_PANEL_ABSENCES_URL + "/upload/" + form.separatorType.value, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((result) => {
                if (result.status === 200) {
                    SocToast.success("Import zakończony", "Nieobecności zostały dodane pomyślnie.");
                } else if (result.status === 204){
                    SocToast.success("Import zakończony", "Plik nie zawierał treści.");
                }
                this.props.toggle();
                onSuccess();
            })
            .catch((error) => {
                var response = error.response;
                if (response && response.status === 415) {
                    SocToast.error("Wystąpił błąd", "Plik posiada nieodpowiedni format.");
                } else if (response && response.status === 422){
                    SocToast.error("Wystąpił błąd", "Plik został uzupełniony niepoprawnymi wartościami, bądź wybrano zły typ separatora.");
                }
            });
        }
    }

    render() {
        const { separatorType, separatorTypeList } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasCsvFileError = Boolean(this.state.formErrors.csvFile.length);
        var hasSeparatorTypeError = Boolean(this.state.formErrors.separatorType.length);

        return (
            <Row className="justify-content-md-center">
                <Col sm="12" md="6">
                    <label>*Typ separatora</label>
                    <Select
                        name="separatorType"
                        value={separatorType ? { label: separatorType.label, value: separatorType.value } : undefined}
                        onChange={this.selectChangeHandler}
                        options={separatorTypeList}
                        invalid={hasSeparatorTypeError}
                        className={hasSeparatorTypeError ? "mb-0" : "mb-2"} />
                    {hasSeparatorTypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.separatorType)}</ul>}
                </Col>
                <Col sm="12" md="6">
                    <label>*Plik csv</label>
                    <FormInput
                        type="file"
                        accept=".csv"
                        ref={(input) => { this.filesInput = input }}
                        name="csvFile"
                        onChange={this.handleChange}
                        invalid={hasCsvFileError}
                        className={hasCsvFileError ? "mb-0" : "mb-2"} />
                    {hasCsvFileError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.csvFile)}</ul>}
                </Col>
            </Row>
        );
    }
}

export default UploadAbsenceForm;