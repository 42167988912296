import React from "react";
import { FormInput, Col, Row, FormGroup } from "shards-react";

class CategoryFilterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                searchValue: ""
            },
            formErrors: {
                searchValue: []
            }
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form }, () => {
            this.submitFilterChanges();
        });
    }

    submitFilterChanges() {
        const { onChangeFilter } = this.props;
        if (this.validFilters()) {
            onChangeFilter({
                searchValue: this.state.form.searchValue,
            })
        }
    }

    validFilters() {
        const { formErrors } = this.state;
        const { searchValue } = this.state.form;

        formErrors["searchValue"] = [];

        var errorCount = 0;
        if (searchValue.length !== 0) {
            if (searchValue.length < 3) {
                formErrors["searchValue"].push("Nazwa powinna składać się co najmniej z 3 znaków");
                errorCount++;
            }
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    render() {
        const { form } = this.state

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasSearchValueError = Boolean(this.state.formErrors.searchValue.length);

        return (
            <Row>
                <Col>
                    <FormGroup>
                        <FormInput
                            name="searchValue"
                            value={form.searchValue}
                            onChange={this.inputChangeHandler}
                            invalid={hasSearchValueError}
                            className={hasSearchValueError ? "mb-0" : "mb-2"}
                            placeholder="Wprowadź szukaną wartość" />
                        {hasSearchValueError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.searchValue)}</ul>}

                    </FormGroup>
                </Col>
            </Row>
        );
    }

}

export default CategoryFilterForm;