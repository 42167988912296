import React from 'react';

const DictionaryGroup = ({ children, className, style }) => {
    return (
        <div className={className} style={style}>
            {children}
        </div>
    )
}

export default DictionaryGroup;