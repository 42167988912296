import React from "react";
import { ConfirmModal } from "../../../common/Modal";
import * as Constants from '../../../../constants';
import API from "../../../../api/AxiosConfiguration";
import * as socToast from '../../../../utils/SocToast';

class DeleteDeviceRuleModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteRule = this.deleteRule.bind(this);
    }

    deleteRule(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(Constants.PC_DEVICE_SAFETICA_RULE_URL + "/" + uuid)
        .then(result => {
            if(result.status === 200) {
                onSuccess();
                socToast.success("Reguła zostanie usunięta", "Reguła została dezaktywowana i oczekuje na usunięcie.");
                toggleFunction();
            }
        })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz wysłać prośbę o usunięcie reguły?"
                    open={open}
                    toggle={toggle}
                    submitAction={this.deleteRule}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie wszystkich danych o regule.
                        Twoja prośba zostanie rozpatrzona w ciągu kilku dni roboczych.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          
            </ConfirmModal>
        )
    }
    
}

export default DeleteDeviceRuleModal;