import React from 'react';
import Select from 'react-select';
import API from "../../../api/AxiosConfiguration";
import * as Constants from "../../../constants";

class forgotPasswordMethodsSelect extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            forgotPasswordMethods: [],
            form: {
                selectedMethod: ""
            },
            formErrors: {
                selectedMethod: []
            },
        };

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.validForm = this.validForm.bind(this);
        this.submitForm = this.submitForm.bind(this );

        this.fetchSupportedRecoveryOptionsAndSelectedCustomerOption = this.fetchSupportedRecoveryOptionsAndSelectedCustomerOption.bind(this);
        this.fetchCustomerSelectedRecoveryOption = this.fetchCustomerSelectedRecoveryOption.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchSupportedRecoveryOptionsAndSelectedCustomerOption();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectChangeHandler = (item, event) => {
        const { form } = this.state;
        form[event.name] = item;
        if (this._isMounted)
            this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { selectedMethod } = this.state.form;

        formErrors["selectedMethod"] = [];
        var errorCount = 0;

        if (selectedMethod.value !== "SMS" && selectedMethod.value !== "MAIL") {
            formErrors["selectedMethod"].push("Wybrana opcja nie jest poprawna");
            errorCount++;
        }

        if (this._isMounted)
            this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm() {
        const { form, formErrors } = this.state;

        if (this.validForm()) {
            API.put(Constants.CUSTOMERS_URL + "/passwordRecoveryOption", {
                recoveryOption: form.selectedMethod.value,
            }).then((result) => {
                if (result.status === 200) {
                    this.props.onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors && response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    if (this._isMounted)
                        this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    fetchSupportedRecoveryOptionsAndSelectedCustomerOption() {
        API.get(Constants.CUSTOMERS_URL + "/passwordRecoveryOptions")
            .then((result) => {
                if (result.status === 200) {
                    if (this._isMounted)
                        this.setState({
                            forgotPasswordMethods: result.data
                        }, () => {
                            this.fetchCustomerSelectedRecoveryOption()
                        })
                }
            });
    }

    fetchCustomerSelectedRecoveryOption() {
        API.get(Constants.CUSTOMERS_URL + "/passwordRecoveryOption")
            .then((result) => {
                if (result.status === 200) {
                    if (this._isMounted)
                        this.setState({
                            form: {
                                selectedMethod: this.findOptionByValue(result.data)
                            }
                        })
                }
            });
    }

    findOptionByValue(value) {
        const { forgotPasswordMethods } = this.state;
        var option = forgotPasswordMethods.find(option => option.value === value)
        return option;
    }

    render() {
        const { selectedMethod } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasSelectedMethodErrors = Boolean(this.state.formErrors.selectedMethod.length);

        const defaultSelectProps = {
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        return (
            <div className="mt-2">
                <label>Metoda:</label>
                <Select
                    {...defaultSelectProps}
                    name="selectedMethod"
                    value={selectedMethod}
                    onChange={this.selectChangeHandler}
                    placeholder="Wybierz"
                    options={this.state.forgotPasswordMethods}
                    className={hasSelectedMethodErrors ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                {hasSelectedMethodErrors && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.selectedMethod)}</ul>}
            </div>
        );
    }

}

export default forgotPasswordMethodsSelect;