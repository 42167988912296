import React from "react";
import {
    Row,
    Col,
} from "shards-react";

import Pagination from "react-js-pagination";
import CompanyCard from "../../cards/CompanyCard";

class CompanyList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            companies: [],
            pagination: {
                activePage: 1,
                itemsPerPage: 9,
                totalItemsCount: 0,
                pageRangeDisplayed: 5,
            }
        }

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var companies = props.companies;

        if (props.companies !== state.companies) {
            return {
                companies: companies,
                pagination: {
                    ...state.pagination,
                    totalItemsCount: companies.length
                }
            }
        }

        return null;
    }

    handlePageChange(pageNumber) {
        this.setState({
            pagination: {
                ...this.state.pagination,
                activePage: pageNumber
            }
        })
    }

    render() {
        const { toggleConfirmCompanyModal, toggleDeleteCompanyModal } = this.props;
        const { companies } = this.state;
        const { activePage, itemsPerPage, totalItemsCount, pageRangeDisplayed } = this.state.pagination;

        const firstIndexToShow = (activePage * itemsPerPage) - itemsPerPage;
        const lastIndexToShow = activePage * itemsPerPage;

        var companyPage = companies;
        if (companies.length > firstIndexToShow)
            companyPage = companies.slice(firstIndexToShow, lastIndexToShow);

        return (
            <Row>
                {companyPage.map((company, idx) => {
                    return (
                        <Col key={idx} md="6" lg="4" >
                            <CompanyCard
                                id={idx}
                                name={company.name}
                                withEditButton={true}
                                withDeleteButton={!company.master}
                                ruleDetails={company.details ? company.details : []}
                                onDelete={() => toggleDeleteCompanyModal(company.uuid)}
                                onEdit={() => toggleConfirmCompanyModal(company.uuid)} />
                        </Col>
                    )
                })}

                <Col xs="12" className="d-flex justify-content-end mt-2">
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={pageRangeDisplayed}
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        hideDisabled={true}
                        innerClass="pagination react-bootstrap-table-page-btns-ul mb-0" />
                </Col>
            </Row>
        )
    }

}

export default CompanyList;