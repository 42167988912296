import React from "react";
import { Container, Row, Col } from "shards-react";

const Regulations = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 text-center">
            <Col style={{ fontSize: "28px" }}>Regulamin</Col>
        </Row>

        <Row noGutters className="page-header py-4">
            <Col sm="12" md="8" className="offset-md-2 text-justify">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id tortor elementum, imperdiet enim eu, luctus orci. Morbi a felis sit amet eros vulputate pellentesque. Suspendisse vitae ex at nisl condimentum ornare. Donec id justo sed ligula scelerisque pretium et nec nulla. Etiam eget sapien sed sapien tincidunt tempus ac at risus. Nulla gravida erat a lectus luctus malesuada. Morbi at eros eu leo vulputate pulvinar id sit amet lectus. Curabitur in lacus at odio ultrices imperdiet id sit amet libero.</p>
                <p>Nulla tristique laoreet nibh, eu porttitor nibh auctor sed. Duis cursus sapien nisl, vitae tempus nibh laoreet quis. Cras vitae libero maximus, faucibus neque quis, congue lectus. Ut convallis ex in placerat laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vulputate ut elit eget convallis. Integer malesuada nec tortor ut finibus.</p>
                <p>Cras tincidunt vel lorem a pellentesque. Proin imperdiet maximus justo, sed sodales mauris rutrum eu. Quisque quis varius turpis. Suspendisse volutpat enim eu tincidunt consequat. Aliquam non varius nibh, in hendrerit risus. Nunc vestibulum dictum neque ac auctor. Mauris quis egestas massa. Nullam commodo nulla ipsum, in ultricies est consequat et. Nulla volutpat dapibus maximus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam erat volutpat. Morbi iaculis dapibus fringilla. Cras tincidunt, risus ac tincidunt rutrum, nibh ligula iaculis ipsum, vitae tristique ligula mi sit amet enim.</p>
                <p>Etiam et metus sed nisl tempus gravida. Aliquam ullamcorper felis dignissim, mollis sem et, aliquet dolor. Quisque lacinia magna quis enim tincidunt interdum interdum eget velit. Duis sit amet volutpat diam, vitae ultricies nunc. Nullam mi orci, aliquam sed erat ut, lacinia facilisis neque. Nulla urna libero, finibus et ligula eget, gravida faucibus massa. Ut sed aliquet lectus. Donec dictum pellentesque felis a ullamcorper. Morbi lacinia turpis sed justo placerat tristique. Aliquam ligula augue, interdum sed porta vitae, luctus id sapien. In tempor felis massa, eget posuere ipsum blandit et. Nulla facilisi.</p>
                <p>Suspendisse sit amet hendrerit lorem. Etiam gravida sed tortor a efficitur. Vestibulum dapibus magna sit amet lacus hendrerit, sed bibendum nulla mollis. Curabitur eget arcu purus. Aliquam egestas non lorem in consectetur. Mauris pretium magna sed diam tincidunt, ut elementum nunc mollis. Curabitur porta dolor sit amet libero consequat, at dignissim magna convallis. Etiam pulvinar elit turpis, non varius neque condimentum et. Maecenas ac ligula vitae massa dignissim commodo. Maecenas ante nisl, egestas a ultrices sit amet, egestas vel nisl. Vivamus interdum ex nisi, et blandit justo lobortis a. Mauris malesuada augue vitae nisl laoreet cursus. Etiam dictum ultricies risus, sed tempor purus lacinia eu.</p>
                <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean nulla dui, interdum eget purus sed, convallis congue tellus. Nullam volutpat, lacus vel ultrices vehicula, turpis ante bibendum massa, vitae consequat neque justo ut ipsum. Maecenas at metus vitae ex commodo feugiat. Vivamus luctus at orci eu dapibus. Nam nec justo ut mauris ultrices interdum vel a purus. Cras quis leo elementum, dapibus augue eu, venenatis lacus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Praesent sollicitudin auctor neque, sit amet hendrerit justo placerat ac. Praesent non dictum ex. Nunc quis purus id leo iaculis euismod eget eget elit. Integer lacinia urna lorem, ac mollis ex bibendum at. Cras venenatis tincidunt egestas.</p>
                <p>Duis vel ligula in tortor fermentum hendrerit. Suspendisse potenti. Nunc iaculis elementum turpis, faucibus sagittis ante porta nec. Suspendisse tristique in sem in laoreet. Ut eget justo enim. Ut rutrum sed sem finibus posuere. Aenean ut mattis risus. Duis eget tempor justo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi vitae leo id leo maximus elementum. Suspendisse ut suscipit ante. Duis sodales finibus erat in euismod.</p>
                <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer diam libero, blandit pretium feugiat in, tristique ac sem. Vestibulum bibendum vitae erat sed gravida. Aliquam pulvinar lectus ac diam dignissim, ultricies condimentum neque luctus. Mauris interdum auctor convallis. Donec tincidunt consectetur turpis, sit amet ornare mi aliquam ac. Morbi at pulvinar nulla, sed tristique justo. Duis nec ipsum quis libero imperdiet cursus ut vitae ligula. Donec a commodo nulla, non malesuada felis. Quisque lacinia nisi velit, a maximus neque hendrerit in. Duis porttitor urna sit amet dolor tempus dapibus. Maecenas ac dapibus sapien. Proin viverra scelerisque fermentum. Cras at venenatis dolor. Morbi finibus aliquet libero ac laoreet. Nullam placerat orci a dolor commodo varius.</p>
                <p>Pellentesque quis ultrices augue. Vivamus eget mollis mi. Curabitur eget rhoncus nulla. Praesent eros ex, vehicula in tristique at, congue sit amet orci. Integer in cursus lectus, quis pulvinar felis. Nullam sed erat imperdiet sem hendrerit lacinia nec sit amet odio. Quisque hendrerit sollicitudin arcu. Quisque eget vestibulum neque, eu malesuada elit. In ut eros vel magna euismod placerat vel eu mauris. Mauris sagittis dui justo, ac dapibus eros rhoncus mollis. Nullam maximus nisl ac blandit rutrum. In cursus rhoncus neque eu viverra. Maecenas dui nulla, fermentum eget convallis in, pharetra vitae elit.</p>
                <p>Vivamus ornare, diam a pretium eleifend, ante tellus eleifend turpis, sed faucibus velit diam id mi. Curabitur consectetur urna ipsum, eget bibendum odio iaculis ac. Donec eget pharetra augue, a sollicitudin nulla. Donec sodales enim sed dui rhoncus, in elementum dolor dapibus. Duis tincidunt, felis in volutpat iaculis, velit tortor auctor felis, quis lacinia erat turpis lacinia eros. Nam accumsan diam non metus consectetur porttitor. Duis enim metus, ultrices ac venenatis ut, porttitor ut purus. Praesent imperdiet tellus nec augue tempor posuere. In hac habitasse platea dictumst. Curabitur ut bibendum ex, sit amet ultrices enim.</p>
                <p>Morbi nec nisi ex. Fusce mollis eget diam a molestie. Quisque mollis tortor sed porttitor consectetur. Vestibulum ac sapien ante. Sed sodales sollicitudin dapibus. Sed risus metus, hendrerit id malesuada eu, consequat vitae ipsum. Maecenas pharetra lectus ut ligula ullamcorper, vitae tempus lacus pharetra. In laoreet pulvinar erat in aliquet.</p>
                <p>Suspendisse finibus leo id dictum tincidunt. Sed quis velit consequat velit convallis aliquam sit amet eu justo. Integer vestibulum enim vel congue porta. Vivamus consequat mattis tempus. Nam ultricies a enim eu mollis. Mauris ut tellus tempus, aliquet urna quis, mattis quam. Quisque viverra nunc in ultrices rhoncus. Proin molestie turpis nunc, euismod faucibus dui blandit non.</p>
                <p>Nullam semper velit eu condimentum aliquam. Nam scelerisque ipsum sed justo blandit mattis. Integer quis leo sit amet purus accumsan pretium sed venenatis lorem. Etiam vulputate est sed eleifend luctus. Vestibulum fringilla ipsum elit, quis congue urna condimentum quis. Pellentesque eleifend ac enim eu laoreet. In lacinia nibh non convallis elementum. Ut viverra mattis ante, vel vestibulum justo maximus eu.</p>
                <p>Curabitur maximus id tellus ac interdum. In ac ultricies ex. Nulla diam nulla, dictum eu elit in, consequat scelerisque ipsum. Ut quis molestie sem. Suspendisse potenti. Phasellus interdum aliquet suscipit. Praesent efficitur libero vitae justo sollicitudin fringilla.</p>
                <p>Nulla congue odio a est fermentum, eget luctus eros blandit. Proin molestie vehicula rutrum. Quisque auctor quam at ligula sagittis congue. Vestibulum in tempus magna. Ut aliquet, arcu porttitor suscipit sodales, ipsum tortor volutpat purus, nec faucibus turpis erat et risus. Vestibulum non metus finibus, maximus leo ac, auctor magna. Nullam pulvinar est in lectus semper rutrum. Vestibulum pharetra varius mattis. In tincidunt est ac dolor dignissim dapibus. In commodo velit in eros ultricies mattis. Nullam placerat tempus tempus. Phasellus fermentum id risus ut tincidunt.</p>
            </Col>
        </Row>
    </Container>
);

export default Regulations;