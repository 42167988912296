import React from "react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { Row, Col, FormInput, FormCheckbox, FormRadio } from "shards-react";

import { EMAIL_REGEXP } from "../../../utils/Patterns";

class ConfirmCompanyForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: '',
                fullName: '',
                subiektSymbol: '',
                nip:'',
                email: '',
                address: '',
                houseNumber: '',
                postCode: '',
                city: '',
                deviceSecurity: false,
                deviceSecuritySoftware: "",
                networkSecurity: false,
                physicalSecurity: false,
            },
            formErrors: {
                deviceSecuritySoftware: [],
                name: [],
                fullName: [],
                subiektSymbol: [],
                nip: [],
                email: [],
                address: [],
                houseNumber: [],
                postCode: [],
                city: [],
            },
            editionMode: false
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.validatenip = this.validatenip.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    checkboxChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = !form[event.target.name];
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    radioChangeHandler = (name, value) => {
        var { form, formErrors } = this.state;
        form[name] = value;
        formErrors[name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors, editionMode } = this.state;
        const { deviceSecurity, deviceSecuritySoftware, name, fullName, subiektSymbol, email, address, houseNumber, postCode, city, nip } = this.state.form;

        formErrors["deviceSecuritySoftware"] = [];
        formErrors["name"] = [];
        formErrors["fullName"] = [];
        formErrors["subiektSymbol"] = [];
        formErrors["nip"] = [];
        formErrors["email"] = [];
        formErrors["address"] = [];
        formErrors["houseNumber"] = [];
        formErrors["postCode"] = [];
        formErrors["city"] = [];

        var errorCount = 0;

        if (deviceSecurity && !(deviceSecuritySoftware in Constants.DEVICE_SECURITY_SOFTWARES)) {
            formErrors["deviceSecuritySoftware"].push("Pole musi zostać wybrane");
            errorCount++;
        }

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (fullName.length < 3 || fullName.length > 40) {
            formErrors["fullName"].push("Pełna nazwa powinna składać się co najmniej z 3 znaków, a maksymalnie z 40 znaków");
            errorCount++;
        }

        if (subiektSymbol.length < 3 || subiektSymbol.length > 20) {
            formErrors["subiektSymbol"].push("Symbol firmy powinien składać się co najmniej z 3 znaków, a maksymalnie z 20 znaków");
            errorCount++;
        }

        if (!editionMode && !this.validatenip(nip)) {
            formErrors["nip"].push("Nip nie jest poprawny");
            errorCount++;
        }

        if (!EMAIL_REGEXP.test(email)) {
            formErrors["email"].push("Podany e-mail jest niepoprawny");
            errorCount++;
        }

        if (address.length < 3 || address.length > 60) {
            formErrors["address"].push("Adres powinien składać się co najmniej z 3 znaków, a maksymalnie z 60 znaków");
            errorCount++;
        }

        if (houseNumber.length < 1 || houseNumber.length > 10) {
            formErrors["houseNumber"].push("Numer budynku/lokalu powinien składać się co najmniej z 1 znaku, a maksymalnie z 10 znaków");
            errorCount++;
        }

        if (postCode.length < 3 || postCode.length > 6) {
            formErrors["postCode"].push("Kod pocztowy powinien składać się co najmniej z 3 znaków, a maksymalnie z 6 znaków");
            errorCount++;
        }

        if (city.length < 3 || city.length > 60) {
            formErrors["city"].push("Nazwa miasta powinna składać się co najmniej z 3 znaków, a maksymalnie z 60 znaków");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    validatenip = (nip) => {
        var nipWithoutDashes = nip.replace(/-/g, "");
        var reg = /^[0-9]{10}$/;
        if (reg.test(nipWithoutDashes) === false) {
            return false;
        } else {
            var digits = ("" + nipWithoutDashes).split("");
            var checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11;

            return (parseInt(digits[9]) === checksum);
        }
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;
        this.setState({
            formErrors: []
        })

        var requestBody = {
            form: form
        }

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(Constants.COMPANIES_URL + "/" + uuid, requestBody)
            } else {
                apiPromise = API.put(Constants.COMPANIES_URL, requestBody);
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }

                if (response && response.status === 409) {
                    this.setState({
                        formErrors: {
                            ...formErrors,
                            deviceSecuritySoftware: ["Przed zmianą oprogramowania należy usunąc wszystkie Bezpieczeństwo sprzętowe należące do tej firmy", ...formErrors.deviceSecuritySoftware]
                        }
                    });
                }
            });
        }
    }

    fetchCompanyForm(uuid) {
        API.get(Constants.COMPANIES_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: result.data
                })
            }
        });
    }

    updateComponent() {
        const { uuid } = this.props;
        if (uuid.length) {
            this.setState({
                editionMode: true
            })

            this.fetchCompanyForm(uuid);
        }
    }

    render() {
        const { name, fullName, subiektSymbol, nip, email, address, houseNumber, postCode, city, deviceSecurity, networkSecurity, physicalSecurity } = this.state.form;
        const { uuid } = this.props;
        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasDeviceSecuritySoftwareError = Boolean(this.state.formErrors.deviceSecuritySoftware.length);
        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasFullNameError = Boolean(this.state.formErrors.fullName.length);
        var hasSubiektSymbolError = Boolean(this.state.formErrors.subiektSymbol.length);
        var hasNipError = Boolean(this.state.formErrors.nip.length);
        var hasEmailError = Boolean(this.state.formErrors.email.length);
        var hasAddressError = Boolean(this.state.formErrors.address.length);
        var hasHouseNumberError = Boolean(this.state.formErrors.houseNumber.length);
        var hasPostCodeError = Boolean(this.state.formErrors.postCode.length);
        var hasCityError = Boolean(this.state.formErrors.city.length);

        const DeviceSecuritySoftwareSelector = () => {
            const softwareRadios = Object.entries(Constants.DEVICE_SECURITY_SOFTWARES).map(([key, value]) => {
                const formName = "deviceSecuritySoftware";
                return (
                    <FormRadio
                        key={key}
                        name={formName}
                        checked={this.state.form.deviceSecuritySoftware === key}
                        onChange={() => { this.radioChangeHandler(formName, key); }}
                        invalid={hasDeviceSecuritySoftwareError}>
                        {value}
                    </FormRadio>
                )
            });

            return (
                <div className="ml-4">
                    {softwareRadios}
                </div>
            )
        }

        return (
            <Row>
                <Col sm="12">
                    <label>*Nazwa firmy</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="name"
                        value={name || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}
                </Col>

                <Col sm="12">
                    <label>*Pełna nazwa firmy</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="fullName"
                        value={fullName || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasFullNameError}
                        className={hasFullNameError ? "mb-0" : "mb-2"} />
                    {hasFullNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.fullName)}</ul>}
                </Col>

                <Col sm="12">
                    <label>*Symbol firmy</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="subiektSymbol"
                        value={subiektSymbol || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasSubiektSymbolError}
                        className={hasSubiektSymbolError ? "mb-0" : "mb-2"} />
                    {hasSubiektSymbolError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.subiektSymbol)}</ul>}
                </Col>

                <Col sm="12">
                    <label>*Nip firmy</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="nip"
                        value={nip || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasNipError}
                        disabled={uuid && uuid.length}
                        className={hasNipError ? "mb-0" : "mb-2"} />
                    {hasNipError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.nip)}</ul>}
                </Col>

                <Col sm="12">
                    <label>*Email</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="email"
                        value={email || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasEmailError}
                        className={hasEmailError ? "mb-0" : "mb-2"} />
                    {hasEmailError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.email)}</ul>}
                </Col>

                <Col sm="12">
                    <label>*Ulica</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="address"
                        value={address || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasAddressError}
                        className={hasAddressError ? "mb-0" : "mb-2"} />
                    {hasAddressError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.address)}</ul>}
                </Col>

                <Col sm="12">
                    <label>*Numer domu/lokalu</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="houseNumber"
                        value={houseNumber || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasHouseNumberError}
                        className={hasHouseNumberError ? "mb-0" : "mb-2"} />
                    {hasHouseNumberError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.houseNumber)}</ul>}
                </Col>

                <Col sm="12">
                    <label>*Kod pocztowy</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="postCode"
                        value={postCode || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasPostCodeError}
                        className={hasPostCodeError ? "mb-0" : "mb-2"} />
                    {hasPostCodeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.postCode)}</ul>}
                </Col>

                <Col sm="12">
                    <label>*Miejscowość</label>
                    <FormInput
                        style={{ height: '38px' }}
                        name="city"
                        value={city || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasCityError}
                        className={hasCityError ? "mb-0" : "mb-2"} />
                    {hasCityError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.city)}</ul>}
                </Col>

                <Col sm="12">
                    <label>Uprawnienia do</label>
                    <div>
                        <FormCheckbox
                            name="deviceSecurity"
                            checked={deviceSecurity}
                            onChange={this.checkboxChangeHandler}
                            invalid={hasDeviceSecuritySoftwareError}>
                            Bezpieczeństwo Sprzętowe
                        </FormCheckbox>

                        {deviceSecurity && <DeviceSecuritySoftwareSelector />}
                        {hasDeviceSecuritySoftwareError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.deviceSecuritySoftware)}</ul>}

                        <FormCheckbox
                            name="networkSecurity"
                            checked={networkSecurity}
                            onChange={this.checkboxChangeHandler}>
                            Bezpieczeństwo Sieciowe
                        </FormCheckbox>

                        <FormCheckbox
                            name="physicalSecurity"
                            checked={physicalSecurity}
                            onChange={this.checkboxChangeHandler}>
                            Bezpieczeństwo Fizyczne
                        </FormCheckbox>
                    </div>
                </Col>
            </Row>
        );
    }

}

export default ConfirmCompanyForm;