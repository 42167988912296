import React from "react";
import { ConfirmModal } from "../../../common/Modal";

import DeleteDeviceApplicationForm from "../forms/DeleteDeviceApplicationForm"

class DeleteDeviceApplicationModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz wysłać prośbę o usunięcie urządzenia?"
                    open={open}
                    toggle={toggle}
                    submitAction={() => {this.refs.deleteDeviceApplicationForm.submitForm(uuid, onSuccess);}}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie wszystkich danych o urządzeniu.
                        Twoja prośba zostanie rozpatrzona w ciągu kilku dni roboczych.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          

                    <DeleteDeviceApplicationForm toggle={toggle} ref="deleteDeviceApplicationForm" />
                </ConfirmModal>
        )
    }
}

export default DeleteDeviceApplicationModal;