import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Store, Constants, Dispatcher } from "../../../flux";

const SidebarHamburger = () => {

    const [isMainSidebarVisible, setIsMainSidebarVisible] = useState(Store.getMenuState());

useEffect(() => {
    Store.addToggleSidebarListener(onMainSidebarToggle);
    return () => {
        Store.removeToggleSidebarListener(onMainSidebarToggle);
    }
}, [])


    const onMainSidebarToggle = () => {
        const isVisible = Store.getMenuState();
        setIsMainSidebarVisible(isVisible);
    }

    const handleToggle = () => {
        Dispatcher.dispatch({
            actionType: Constants.TOGGLE_SIDEBAR
        });
    }

    return ( 
        <div className={`sidebar-burger-wrapper ${!isMainSidebarVisible && 'hidden'}`} onClick={handleToggle} >
            <FontAwesomeIcon icon={ isMainSidebarVisible ? faBars : faChevronRight } />
        </div>
     );
}
 
export default SidebarHamburger;