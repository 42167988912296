import React from 'react';
import { ConfirmModal } from '../../../common/Modal';
import ConfirmDictionaryForm from './../forms/ConfirmDictionaryForm';

class ConfirmUserGroupModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            isMobileView,
        } = this.props;

        return (
            <ConfirmModal
                title={uuid && typeof (uuid) === "string" ? "Edycja słownika" : "Dodawanie słownika"}
                open={open}
                toggle={toggle}
                submitAction={() => { this.childRef.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj">

                <ConfirmDictionaryForm
                    toggle={toggle}
                    ref={this.setRef}
                    uuid={uuid}
                    isMobileView={isMobileView} />

            </ConfirmModal>
        )
    }

}

export default ConfirmUserGroupModal;