import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
} from "shards-react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../api/AxiosConfiguration";
import * as Constants from "../../constants";

import dateFormat from "dateformat";
import withLoader from "../common/hoc/withLoader";

class DeviceEvents extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            detectedEventRows: [],
        }

        this.fetchDeviceEvents = this.fetchDeviceEvents.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchDeviceEvents();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchDeviceEvents() {
        const { uuid } = this.props;
        this.props.setLoading(true);
        API.get(Constants.NETWORK_DEVICE_EVENTS_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    var events = result.data;
                    if (this._isMounted)
                        this.setState({
                            detectedEventRows: events,
                        })
                }

                this.props.setLoading(false);
            })
    }

    render() {
        const { detectedEventRows } = this.state;

        const expandRow = {
            renderer: row => (
                <EventDetailsContainer row={row} />
            ),
        };

        const EventDetailsContainer = ({ row }) => {
            return (
                <Row className="mb-2 mx-1">
                    <Col className="mt-2" sm="12">
                        <EventDetailsCard details={row.eventDetails} ruleDetails={row.ruleDetails} />
                    </Col>
                </Row>
            )
        }

        const EventDetailsCard = ({ details, ruleDetails }) => {
            return (
                <Card className="h-100 mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        <EventCardRows rows={details.rows} />
                        <ProcedureCardRow ruleDetails={ruleDetails} />
                    </CardBody>
                </Card>
            )
        }

        const EventCardRows = ({ rows }) => {
            return rows.map((element, index) => {
                if (element.value.length) {
                    return <EventCardRow key={index} title={element.label} content={element.value} />
                }

                return null;
            })
        }

        const EventCardRow = ({ title, content, withoutBottomDivider }) => {
            return (
                <div className="detail-row-container">
                    <div className="small-padding">
                        <div className="details-row-title">{title}</div>
                        <div className="details-row-content">{content}</div>
                    </div>
                    {!withoutBottomDivider && <div className="divider" />}
                </div>
            )
        }

        const styles = {
            float: "left",
            display: "inline",
            marginTop: "8px",
        }

        const ProcedureCardRow = ({ ruleDetails, withoutBottomDivider }) => {
            return (
                <div className="detail-row-container">
                    <div className="small-padding">
                        <div className="details-row-title"></div>
                        <div className="details-row-content" style={styles}>Procedury</div>
                        <div className="text-right">
                            <Button onClick={() => this.props.proceduresModal(ruleDetails)} outline theme="info" className="mb-1 mr-1">
                                Wyświetl Procedurę
                            </Button>
                        </div>
                    </div>
                    {!withoutBottomDivider && <div className="divider" />}
                </div>
            )
        }

        const paginationOptions = {
            custom: true,
            totalSize: detectedEventRows.length,
            sizePerPage: 10
        };

        function eventDateFormatter(cell, row) {
            return (
                <span className="text-semibold text-fiord-blue" style={{ fontSize: ".8125rem" }}>{dateFormat(cell, "dd-mm-yyyy HH:MM")}</span>
            );
        }

        function eventDescriptionFormatter(cell, row) {
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell}</span>
            );
        }

        const detectedEventColumns = [{
            dataField: 'eventDate',
            align: "left",
            text: "",
            formatter: eventDateFormatter
        }, {
            dataField: 'description',
            align: "right",
            text: "",
            formatter: eventDescriptionFormatter
        }]


        const renderDeviceEvents = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={detectedEventRows}
                                columns={detectedEventColumns}
                                classes="table mb-0"
                                headerClasses="d-none"
                                bordered={false}
                                hover
                                expandRow={expandRow}
                                rowStyle={{ height: '10px' }}
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderDeviceEventsFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Brak danych</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card className="mb-3">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Naruszenia reguł</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="p-0">
                            {detectedEventRows.length ? renderDeviceEvents() : renderDeviceEventsFetchError()}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default withLoader(DeviceEvents);
