import React from "react";
import { Card, CardBody, CardHeader } from "shards-react";
import FacilitiesForDisabled from './forms/FacilitiesForDisabled';

class CustomerFacilitiesForDisabled extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Card className="mb-3 w-100">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Ułatwienia dostępu dla osób niepełnosprawnych</h6>
                    <div className="block-handle" />
                </CardHeader>

                <CardBody className="py-1">
                    <div>
                        Konfiguracja poniższych ustawień pozwala na ułatwienie korzystania z portalu użytkownikom niepełnosprawnym oraz osobom mającym problem ze wzrokiem.
                    </div>
                </CardBody>
                <FacilitiesForDisabled />
            </Card>
        );
    }

}

export default CustomerFacilitiesForDisabled;
