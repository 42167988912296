import React from "react";
import {
    Row,
    Col,
    FormTextarea,
} from "shards-react";
import { InfoModal } from "../../common/Modal";

class PreviewProcedureModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            content
        } = this.props;

        const renderContent = () => (
            <Row>
                <Col>
                    <FormTextarea
                        style={{ height: "300px" }}
                        className="py-1 px-2"
                        value={content} />
                </Col>
            </Row>
        )

        return (
            <InfoModal
                title="Szczegóły procedury"
                open={open}
                toggle={() => toggle()}
                closeButtonText="Zamknij">
                {renderContent()}
            </InfoModal>
        )
    }
}

export default PreviewProcedureModal;
