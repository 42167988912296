import React from 'react';
import Thumbnail from '../../common/Thumbnail';
import { InfoModal } from '../../common/Modal';
import { Row, Col, ListGroup, ListGroupItem } from 'shards-react';

import API from "../../../api/AxiosConfiguration";
import * as AppConstants from '../../../constants';
import ControlPanelCameraStream from '../../controlPanel-details/ControlPanelCameraStream';

class CameraSelectorModal extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            cameras: [],
            selectedCamera: ''
        };

        this.fetchCamerasOfLocation = this.fetchCamerasOfLocation.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.showCameraStream = this.showCameraStream.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateComponent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchCamerasOfLocation() {
        const { cameraLocation } = this.props;

        if (cameraLocation) {
            API.post(AppConstants.CONTROL_PANEL_CAMERAS_URL + '/location/' + cameraLocation)
                .then((result) => {
                    if (this._isMounted)
                        this.setState({
                            cameras: result.data.cameras,
                        })
                });
        }
    }

    updateComponent() {
        if (this._isMounted)
            this.setState({ selectedCamera: '' })
        this.fetchCamerasOfLocation();
    }

    showCameraStream(camera) {
        if (this._isMounted)
            this.setState({
                selectedCamera: camera
            })
    }

    onBackButtonClick() {
        if (this._isMounted)
            this.setState({
                selectedCamera: ''
            })
    }

    render() {
        const {
            open,
            toggle,
            cameraLocation
        } = this.props;
        const { selectedCamera, cameras } = this.state;

        const renderCameraPlayer = () => {
            return (
                <ControlPanelCameraStream
                    className="position-absolute"
                    controlPanelUuid={selectedCamera.controlPanelId}
                    cameraUuid={selectedCamera.id} />
            )
        }

        const renderCameras = () => {
            return cameras.map(camera => (
                <Col key={camera.id} sm={12} md={4}>
                    <Thumbnail
                        cameraUuid={camera.id}
                        locationUuid={cameraLocation}
                        onClick={() => this.showCameraStream(camera)}>
                        <div>
                            {camera.name}
                        </div>
                        <small>({camera.group})</small>
                    </Thumbnail>
                </Col>
            ))
        }

        const renderNoCameraFoundMessage = () => {
            return (
                <ListGroup small flush className="list-group-small w-100 text-center">
                    <ListGroupItem className="d-flex px-3 m-auto without-border">
                        <span className="text-semibold text-fiord-blue">Nie odnaleziono żadnej kamery dla podanej lokalizacji</span>
                    </ListGroupItem>
                </ListGroup>
            )
        }

        return (
            <InfoModal
                title={selectedCamera ? `Podgląd z kamery: ${selectedCamera.name}` : "Lista kamer znajdujących się w podanej lokalizacji"}
                open={open}
                showModal={() => { this.updateComponent() }}
                toggle={toggle}
                closeButtonText="Zamknij"
                secondButtonText={selectedCamera ? "Cofnij" : undefined}
                secondButtonClick={this.onBackButtonClick}
                bodyClassName="p-0">

                <Row className="mx-0 my-2">
                    {selectedCamera ? renderCameraPlayer() : cameras && cameras.length > 0 ? renderCameras() : renderNoCameraFoundMessage()}
                </Row>

            </InfoModal>
        )
    }

}

export default CameraSelectorModal;