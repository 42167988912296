import React, { useState, useEffect } from "react";
import WindowSizeListener from "react-window-size-listener";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import { Store, Dispatcher, Constants } from "../flux";
import { TYPE_OF_THEME } from "./themes/Themes";
import { TYPE_OF_FONTS_FAMILY } from "./fonts/FontsFamily";
import { TYPE_OF_FONTS_SIZE } from "./fonts/FontsSize";

const LightTheme = React.lazy(() => import('./themes/DefaultLight'));
const HighContrastTheme = React.lazy(() => import('./themes/DefaultHighContrast'));

const VerdanaFontFamily = React.lazy(() => import('./fonts/VerdanaFontFamily'));
const LargeFontSize = React.lazy(() => import('./fonts/LargeFontSize'));

const DefaultLayout = ({ children, noNavbar, noFooter }) => {


  const [isMainSidebarVisible, setIsMainSidebarVisible] = useState(Store.getMenuState());

  useEffect(() => {
    Store.addToggleSidebarListener(onMainSidebarToggle);
    return () => {
      Store.removeToggleSidebarListener(onMainSidebarToggle);
    }
  }, [])

  const onMainSidebarToggle = () => {
    const sidebarStatus = Store.getMenuState();
    setIsMainSidebarVisible(sidebarStatus);
  }

  const ThemeSelector = ({ children }) => {
    const CHOSEN_THEME = localStorage.getItem('theme') || TYPE_OF_THEME.DEFAULT;
    return (
      <>
        <React.Suspense fallback={<></>}>
          {(CHOSEN_THEME === TYPE_OF_THEME.LIGHT_MODE) && <LightTheme />}
          {(CHOSEN_THEME === TYPE_OF_THEME.HIGH_CONTRAST_MODE) && <HighContrastTheme />}
        </React.Suspense>
        {children}
      </>
    )
  }

  const FontSizeSelector = ({ children }) => {
    const CHOSEN_FONT_SIZE = localStorage.getItem('fontSize') || TYPE_OF_FONTS_SIZE.DEFAULT;
    return (
      <>
        <React.Suspense fallback={<></>}>
          {(CHOSEN_FONT_SIZE === TYPE_OF_FONTS_SIZE.LARGE) && <LargeFontSize />}
        </React.Suspense>
        {children}
      </>
    )
  }

  const FontFamilySelector = ({ children }) => {
    const CHOSEN_FONT_FAMILY = localStorage.getItem('fontFamily') || TYPE_OF_FONTS_FAMILY.DEFAULT;

    return (
      <>
        <React.Suspense fallback={<></>}>
          {(CHOSEN_FONT_FAMILY === TYPE_OF_FONTS_FAMILY.VERDANA) && <VerdanaFontFamily />}
        </React.Suspense>
        {children}
      </>
    )
  }
  return (
    <FontFamilySelector>
      <FontSizeSelector>
        <ThemeSelector>
          <Container fluid>
            <WindowSizeListener
              onResize={(windowSize) => {
                if (!(windowSize.windowWidth > 480)) {
                  Dispatcher.dispatch({
                    actionType: Constants.SET_MOBILE_VIEW,
                    payload: {
                      isMobileView: true
                    }
                  })
                } else {
                  Dispatcher.dispatch({
                    actionType: Constants.SET_MOBILE_VIEW,
                    payload: {
                      isMobileView: false
                    }
                  })
                }
              }}
            />
            <Row>
              <MainSidebar />
              <Col
                className="main-content p-0 pb-4"
                lg={isMainSidebarVisible ? { size: 10, offset: 2 } : {size: 12}}
                md={isMainSidebarVisible ? { size: 9, offset: 3 } : {size: 12}}
                sm="12"
                tag="main"
              >
                {!noNavbar && <MainNavbar />}
                {children}
                {/*!noFooter && <MainFooter />*/}
              </Col>
            </Row>
          </Container>
        </ThemeSelector>
      </FontSizeSelector>
    </FontFamilySelector>
  );
}

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
