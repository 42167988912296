import React, { Suspense } from "react";
import { ConfirmModal } from "../../common/Modal";
import ConfirmDeviceRuleForm from "../forms/ConfirmDeviceRuleForm";
import ConfirmNetworkRuleForm from "../forms/ConfirmNetworkRuleForm";
import { withTranslation } from 'react-i18next';

class ConfirmDeviceRuleModal extends React.Component {

    constructor(props) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            deviceType,
            uuid,
            detailsType
        } = this.props;

        const renderConfirmFormForSpecifiedDeviceType = () => {
            return <Suspense fallback="loading">{detailsType === "pcDevice" ? <ConfirmDeviceRuleForm toggle={toggle} ref={this.setRef} deviceType={deviceType} uuid={uuid} t={this.props.t}/> : <ConfirmNetworkRuleForm toggle={toggle} ref={this.setRef} deviceType={deviceType} uuid={uuid} />}</Suspense>
        }

        return (
            <ConfirmModal
                title={uuid && typeof (uuid) === "string" ? "Edytowanie reguły" : "Dodawanie nowej reguły"}
                open={open}
                toggle={toggle}
                submitAction={() => { this.childRef.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj" >

                {renderConfirmFormForSpecifiedDeviceType()}

            </ConfirmModal>
        );
    }
}

export default withTranslation()(ConfirmDeviceRuleModal);