import React from "react";
import {
    Card,
    CardBody,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
    FormCheckbox,
} from "shards-react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import WindowSizeListener from 'react-window-size-listener';

class CategoryTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
        }

        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    }

    checkboxChangeHandler = (event, uuid, formName, cell) => {
        const { editNotificationType } = this.props;
        editNotificationType(uuid, {
            [formName]: cell
        });
        this.setState({
            checkboxHandler: cell
        })
    }

    render() {
        const { toggleCreateCategoryModal, toggleDeleteCategoryModal } = this.props;
        const { categories } = this.props
        var categoryColumns;

        const paginationOptions = {
            custom: true,
            totalSize: categories.length,
            sizePerPage: 10
        };

        function textFormatter(cell, row) {
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell}</span>
            );
        }

        function CheckboxFormatter(cell, row) {
            return (
                <FormCheckbox
                    name={this.dataField}
                    checked={cell}
                    onChange={e => this.formatExtraData.checkboxChangeHandler(e, row.uuid, this.dataField, !cell)}>
                </FormCheckbox>
            );
        }

        function MailCheckboxFormatter(cell, row) {
            const hasEmailBeenConfigured = Boolean(row.hasEmailBeenConfigured);

            return (
                <FormCheckbox
                    name={this.dataField}
                    checked={cell}
                    onChange={e => this.formatExtraData.checkboxChangeHandler(e, row.uuid, this.dataField, !cell)}
                    disabled={!hasEmailBeenConfigured}>
                </FormCheckbox>
            );
        }

        function buttonFormatter(cell, row) {
            return (
                <span>
                    <Button onClick={() => toggleCreateCategoryModal(row.uuid)} outline theme="info" className="mb-1 mr-1">
                        Edytuj
                    </Button>
                    <Button onClick={() => toggleDeleteCategoryModal(row.uuid)} outline theme="info" className="mb-1 mr-1">
                        Usuń
                    </Button>
                </span>
            );
        }

        if (!this.state.isMobile) {
            categoryColumns = [{
                dataField: 'name',
                align: "left",
                text: "Nazwa",
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: 'priority',
                align: "left",
                text: "Priorytet",
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: 'mail',
                align: "left",
                text: "Email",
                headerClasses: "column-header",
                formatExtraData: this,
                formatter: MailCheckboxFormatter
            }, {
                dataField: 'sms',
                align: "left",
                text: "Sms",
                headerClasses: "column-header",
                formatExtraData: this,
                formatter: CheckboxFormatter
            }, {
                dataField: 'push',
                align: "left",
                text: "Push",
                headerClasses: "column-header",
                formatExtraData: this,
                formatter: CheckboxFormatter
            }, {
                dataField: 'webPush',
                align: "left",
                text: "WebPush",
                headerClasses: "column-header",
                formatExtraData: this,
                formatter: CheckboxFormatter
            }, {
                dataField: '',
                align: "left",
                text: "Akcja",
                headerClasses: "column-header",
                formatter: buttonFormatter
            }]
        } else {
            categoryColumns = [{
                dataField: 'name',
                align: "left",
                text: "Nazwa",
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: 'priority',
                align: "left",
                text: "Priorytet",
                headerClasses: "column-header",
                formatter: textFormatter
            }]
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                e.preventDefault();
                if (!(window.getSelection && window.getSelection().type === 'Range')) {
                    toggleCreateCategoryModal(row.uuid);
                }
            }
        };

        const renderCategories = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='uuid'
                                data={categories}
                                columns={categoryColumns}
                                classes="table mb-0"
                                headerClasses="column-header"
                                bordered={false}
                                rowStyle={{ height: '10px' }}
                                {...paginationTableProps}
                                rowEvents={this.state.isMobile ? rowEvents : {}} />
                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderCategoriesError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Brak danych</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <Card medium="true">
                <WindowSizeListener onResize={(windowSize) =>
                    windowSize.windowWidth > 630 ? this.setState({ isMobile: false }) : this.setState({ isMobile: true })}>
                </WindowSizeListener>

                <CardBody className="p-0">
                    {categories.length ? renderCategories() : renderCategoriesError()}
                </CardBody>
            </Card>
        )
    }
}

export default CategoryTable;
