import React from 'react';
import { ConfirmModal } from '../../common/Modal';
import SaveArchivePasswordForm from '../../edit-profile/forms/SaveArchivePasswordForm';

class SetArchivePasswordModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            isMobileView,
        } = this.props;

        return (
            <ConfirmModal
                title="Nadaj hasło do archiwum"
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.saveReportsPasswordForm.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj">

                Przypisz hasło dostępu do wygenerowanego archiwum. Będzie ono wykorzystywane podczas generowania kolejnych plików.
                Możesz je zmienić w zakładce "Edycja Profilu".

                <SaveArchivePasswordForm
                    toggle={toggle}
                    isMobileView={isMobileView}
                    onSuccess={onSuccess}
                    ref="saveReportsPasswordForm" />

            </ConfirmModal>
        )
    }

}

export default SetArchivePasswordModal;
