import React from 'react'
import { Button, CardFooter } from 'shards-react';
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import * as SocToast from '../../../utils/SocToast';
import { messaging } from '../../../firebase';

class WebPushActivityForm extends React.Component {

  activationWebPush = async () => {
    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        API.post(Constants.CUSTOMERS_URL + "/webpush", { token: currentToken }).then(result => {
          if (result.status === 200) {
            SocToast.success("Powiadomienia aktywowane", "Atywacja powiadomień web push przebiegła pomyślnie.");
          } else {
            SocToast.error("Wystąpił Błąd", "Nie udało się aktywować powiadomień web push.");
          }
        });
      } else {
        SocToast.error("Wystąpił Błąd", "Nie udało się aktywować powiadomień web push.");
      }
    }).catch((err) => {
      SocToast.error("Wystąpił Błąd", "Nie udało się aktywować powiadomień web push.");
    });
  }

  deactivationWebPush = () => {
    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        API.delete(Constants.CUSTOMERS_URL + "/webpush/" + currentToken).then(result => {
          if (result.status === 200) {
            messaging.deleteToken();
            SocToast.success("Powiadomienia wyłączone", "Powiadomienia web push zostały wyłączone dla tej przeglądarki.");
          } else {
            SocToast.error("Wystąpił Błąd", "Nie udało się wyłączyć powiadomień web push.");
          }
        });
      } else {
        SocToast.error("Wystąpił Błąd", "Nie udało się wyłączyć powiadomień web push.");
      }
    }).catch((err) => {
      SocToast.error("Wystąpił Błąd", "Nie udało się wyłączyć powiadomień web push.");
    });
  }

  render() {
    return (
      <CardFooter>
        <Button outline theme="danger" className="mb-2 mr-1 float-right"
          onClick={() => { this.deactivationWebPush(); }}>
          Wyłącz
                    </Button>
        <Button outline theme="info" className="mb-2 mr-1 float-right"
          onClick={() => { this.activationWebPush(); }}>
          Aktywuj
                    </Button>
      </CardFooter>
    );
  }

}

export default WebPushActivityForm;