import React from "react";
import { NavItem, NavLink, Badge } from "shards-react";
import { Redirect } from 'react-router-dom'

import API from "../../../../api/AxiosConfiguration"
import * as APIConstants from "../../../../constants";

import { Dispatcher, Constants, Store } from "../../../../flux";


export default class ChatWidget extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      newMessagesCount: 0
    }

    this.refreshIntervalInMillis = 15000;

    this.setRedirect = this.setRedirect.bind(this);
    this.countNewMessages = this.countNewMessages.bind(this);
    this.shouldUpdateNewMessagesCount = this.shouldUpdateNewMessagesCount.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.shouldUpdateNewMessagesCount);
  }

  componentWillUnmount() {
    this._isMounted = false;
    Store.removeChangeListener(this.shouldUpdateNewMessagesCount);
  }

  componentDidMount() {
    this._isMounted = true;
    this.countNewMessages();
    this.interval = setInterval(() => {
      if (this._isMounted) {
        this.countNewMessages();
      }
    }, this.refreshIntervalInMillis);
  }

  setRedirect = () => {
    if (this._isMounted)
      this.setState({
        redirect: true
      })
  }

  redirectToChat() {
    if (window.location.pathname !== "/chat" && this.state.redirect)
      return <Redirect to='/chat' />
  }

  countNewMessages() {
    Dispatcher.dispatch({
      actionType: Constants.REFRESH_NEW_MESSAGES_COUNT,
    })
  }

  shouldUpdateNewMessagesCount() {
    API.get(APIConstants.CHAT_URL + "/users/me")
      .then(response => response.data)
      .then(userSummary => {
        if (this._isMounted)
          this.setState({
            newMessagesCount: userSummary.newMessagesCount
          })
      })
      .catch(error => {
        if (this._isMounted)
          this.setState({ newMessagesCount: 0 })
        console.error("Connection refused.");
      })
  }

  render() {
    const { newMessagesCount } = this.state;

    return (
      <>
        <NavItem className="border-right dropdown notifications">
          <NavLink
            className="nav-link-icon text-center"
            onClick={this.setRedirect}>

            <div className="nav-link-icon__wrapper" style={{ cursor: "pointer" }}>
              <i className="material-icons">speaker_notes</i>
              {newMessagesCount > 0 && <Badge pill theme="danger">
                {newMessagesCount}
              </Badge>}
            </div>
          </NavLink>
        </NavItem>
        {this.redirectToChat()}
      </>
    );
  }

}
