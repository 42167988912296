import React from "react";
import { InfoModal } from "../../common/Modal";

class ProcedureModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            content
        } = this.props;

        const renderContent = () => (
            <div className="mx-2">
                <ProcedureRows rows={content} />
            </div>
        )

        const ProcedureRows = ({ rows }) => {
            if (rows.length > 0) {
                return rows.map((element, index) => {
                    return <ProcedureRow key={index} title={element.procedureName} content={element.procedureContent} />
                })
            } else {
                return (<div className="text-center py-3">Brak procedur</div>)
            }
        }

        const ProcedureRow = ({ title, content }) => {
            return (
                <div className="container">
                    <br></br>
                    <h4>{title}</h4>
                    <div className="mx-2" style={{ whiteSpace: 'pre-wrap' }}>{content}</div>
                </div>
            )
        }

        return (
            <InfoModal
                title="Procedury"
                open={open}
                toggle={() => toggle()}
                closeButtonText="Zamknij"
                bodyClassName="p-0">
                {content !== undefined ? renderContent() : <div className="text-center py-3">Brak procedur</div>}
            </InfoModal>
        )
    }
}

export default ProcedureModal;