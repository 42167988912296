class MaxValidator {

    constructor(maxValue) {
        this.maxValue = maxValue;
    }

    valid(value) {
        return !value || value <= this.maxValue;
    }

}

export default MaxValidator;