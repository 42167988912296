import React from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
} from "shards-react";

import ControlPanelAbsencesTables from "./tables/ControlPanelAbsencesTables";
import ConfirmAbsenceModal from "./modals/ConfirmAbsenceModal";
import DeleteAbsenceModal from "./modals/DeleteAbsenceModal";
import UploadAbsenceModal from "./modals/UploadAbsenceModal";
import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import withLoader from "../common/hoc/withLoader";

class ControlPanelAbsences extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isDeleteAbsenceModalShow: false,
            isCreateAbsenceModalShow: false,
            isUploadAbsenceModalShow: false,
            absences: [],
            selectedAbsenceUUID: '',
        };

        this.toggleDeleteAbsenceModal = this.toggleDeleteAbsenceModal.bind(this);
        this.toggleCreateAbsenceModal = this.toggleCreateAbsenceModal.bind(this);
        this.toggleUploadAbsenceModal = this.toggleUploadAbsenceModal.bind(this);
        this.showAbsencesList = this.showAbsencesList.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.showAbsencesList();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showAbsencesList() {
        this.props.setLoading(true);
        API.get(Constants.CONTROL_PANEL_ABSENCES_URL)
            .then(result => {
                if (result.status === 200) {
                    if (this._isMounted)
                        this.setState({
                            absences: result.data,
                        })
                }
                this.props.setLoading(false);
            })
    }

    toggleDeleteAbsenceModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteAbsenceModalShow: !this.state.isDeleteAbsenceModalShow,
                selectedAbsenceUUID: uuid,
            });
    }

    toggleCreateAbsenceModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateAbsenceModalShow: !this.state.isCreateAbsenceModalShow,
                selectedAbsenceUUID: uuid,
            });
    }

    toggleUploadAbsenceModal() {
        if (this._isMounted)
            this.setState({
                isUploadAbsenceModalShow: !this.state.isUploadAbsenceModalShow,
            });
    }

    render() {
        const {
            isDeleteAbsenceModalShow,
            isCreateAbsenceModalShow,
            isUploadAbsenceModalShow,
            selectedAbsenceUUID,
            absences,
        } = this.state;

        const {
            isMobileView,
        } = this.props;

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Nieobecności</h6>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={this.toggleCreateAbsenceModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową nieobecność
                            </Button>
                            <Button onClick={this.toggleUploadAbsenceModal} outline theme="info" className="mb-2 mr-1">
                                Importuj nieobecności
                            </Button>
                            <ControlPanelAbsencesTables absences={absences} toggleCreateAbsenceModal={this.toggleCreateAbsenceModal}
                                toggleDeleteAbsenceModal={this.toggleDeleteAbsenceModal} />
                        </CardBody>
                    </Card>
                </Col>

                <DeleteAbsenceModal open={isDeleteAbsenceModalShow} uuid={selectedAbsenceUUID}
                    toggle={this.toggleDeleteAbsenceModal} onSuccess={this.showAbsencesList} />

                <ConfirmAbsenceModal open={isCreateAbsenceModalShow} uuid={selectedAbsenceUUID}
                    toggle={this.toggleCreateAbsenceModal} onSuccess={this.showAbsencesList} isMobileView={isMobileView} />

                <UploadAbsenceModal open={isUploadAbsenceModalShow} toggle={this.toggleUploadAbsenceModal}
                    onSuccess={this.showAbsencesList} isMobileView={isMobileView} />
            </Row>
        )
    }

}

export default withLoader(ControlPanelAbsences);
