import React from "react";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import CompanyList from "./lists/CompanyList";
import API from "../../api/AxiosConfiguration";
import * as AppConstants from '../../constants';
import DeleteCompanyModal from "./modals/DeleteCompanyModal";
import ConfirmCompanyModal from "./modals/ConfirmCompanyModal";
import { Dispatcher, Constants } from '../../flux';
import withLoader from "../common/hoc/withLoader";

class Companies extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            isConfirmCompanyShow: false,
            isDeleteCompanyModalShow: false,
            companies: [],
            requiredFilterOptions: {},
            selectedCompanyUUID: '',
        }

        this.toggleConfirmCompanyModal = this.toggleConfirmCompanyModal.bind(this);
        this.toggleDeleteCompanyModal = this.toggleDeleteCompanyModal.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateComponent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleConfirmCompanyModal(uuid) {
        if (this._isMounted)
            this.setState({
                isConfirmCompanyShow: !this.state.isConfirmCompanyShow,
                selectedCompanyUUID: uuid
            })
    }

    toggleDeleteCompanyModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteCompanyModalShow: !this.state.isDeleteCompanyModalShow,
                selectedCompanyUUID: uuid
            });
    }

    fetchCompanies(form) {
        return API.post(AppConstants.COMPANIES_URL, {
            form: form,
        })
    }

    updateComponent(filters = {}) {
        this.props.setLoading(true);
        this.fetchCompanies(filters).then((result) => {
            var menuCompanyInfo = result.data;
            if (this._isMounted)
                this.setState({
                    companies: menuCompanyInfo.companies,
                });

            Dispatcher.dispatch({ actionType: Constants.UPDATE_COMPANY_LIST })
            this.props.setLoading(false);
        })
    }

    render() {
        const { companies, isConfirmCompanyShow, isDeleteCompanyModalShow, selectedCompanyUUID } = this.state;

        const showCompanyList = () => (
            <CompanyList className="clearfix"
                companies={this.state.companies}
                toggleConfirmCompanyModal={this.toggleConfirmCompanyModal}
                toggleDeleteCompanyModal={this.toggleDeleteCompanyModal} />
        )

        const showNoCompaniesFoundMessage = () => (
            <Row className="pt-2" style={{ justifyContent: "center" }}>
                <h6>Nie odnaleziono żadnych firm</h6>
            </Row>
        )

        return (
            <Row className="mt-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Firmy</h6>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={this.toggleConfirmCompanyModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową firmę
                            </Button>

                            {companies.length ? showCompanyList() : showNoCompaniesFoundMessage()}

                        </CardBody>
                    </Card>
                </Col>

                <ConfirmCompanyModal open={isConfirmCompanyShow} onSuccess={this.updateComponent}
                    uuid={selectedCompanyUUID} toggle={this.toggleConfirmCompanyModal} />

                <DeleteCompanyModal open={isDeleteCompanyModalShow} onSuccess={this.updateComponent}
                    uuid={selectedCompanyUUID} toggle={this.toggleDeleteCompanyModal} />
            </Row>
        );
    }

}

export default withLoader(Companies);