import React from "react";
import { ConfirmModal } from "../../common/Modal";

import ConfirmControlPanelApplicationForm from "../forms/ConfirmControlPanelApplicationForm";

class ConfirmControlPanelApplicationModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            isMobileView,
            deviceStatus,
            deviceAction
        } = this.props;

        return (
            <ConfirmModal
                title={uuid && typeof (uuid) === "string" ? "Edytowanie centrali" : "Dodawanie centrali"}
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.addControlPanelApplicationForm.submitForm(onSuccess); }}
                middleButtonAction={() => this.refs.addControlPanelApplicationForm.submitTemplate()}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj"
                middleButtonText="Zapisz szablon">

                <ConfirmControlPanelApplicationForm
                    toggle={toggle}
                    ref="addControlPanelApplicationForm"
                    uuid={uuid || ""}
                    isMobileView={isMobileView}
                    deviceStatus={deviceStatus}
                    deviceAction={deviceAction} />

            </ConfirmModal>
        )
    }
}

export default ConfirmControlPanelApplicationModal;