import React, { Component } from 'react';
import { Row, Col, FormInput } from "shards-react";
import * as AppConstants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import MultiSelect from "@kenshooui/react-multi-select";
import * as SocToast from '../../../utils/SocToast';
import { Store, Dispatcher, Constants } from "../../../flux";
import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns';

import "@kenshooui/react-multi-select/dist/style.css";

class CreateNewDashboardForm extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: '',
                users: [],
            },
            formErrors: {
                name: []
            },
            formHints: {
                users: [],
            },
            isOptionsLoading: false,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        // this.fetchCustomersAndForm = this.fetchCustomersAndForm.bind(this);
        // this.fetchDeviceGroupForm = this.fetchDeviceGroupForm.bind(this);
        this.multiselectChangeHandler = this.multiselectChangeHandler.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;



        if(this._isMounted) {
            const newDashboardName = Store.getNewDashboardName();

            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    name: newDashboardName
                }
            });

            this.fetchCompanyCustomers();
        }

    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    multiselectChangeHandler(selectedItems) {
        this.setState({
            form: {
                ...this.state.form,
                users: selectedItems
            }
        });
    }

    fetchCompanyCustomers() {
        API.get(`${AppConstants.CUSTOMERS_URL}/company`).then(result => {
            this.setState({
                formHints: {
                    users: result.data.map( customer => { return { id: customer.value, label: customer.label } } )
                }
            })
        });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name } = this.state.form;

        formErrors["name"] = [];

        var errorCount = 0;

        
        if (hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm(onSuccess) {
        const { toggle } = this.props;

        this.setState({
            formErrors: {
                name: []
            }
        })

        if(this.validForm()) {
            API.post(AppConstants.DASHBOARDS_URL, { dashboardName: this.state.form.name, sharedFor: this.state.form.users.map(user => user.id) }).then(result => {
                if(result.status === 201) {
                    SocToast.success("Pomyślnie utworzono nowy dashboard.");
                  Dispatcher.dispatch({
                    actionType: Constants.UPDATE_DASHBOARD_LIST,
                    payload: {
                      dashboardName: this.state.form.name
                    } });
                    toggle();
                } else {
                    SocToast.error("Nie udało się utworzyć dashboardu", "Spróbuj ponownie za chwilę, lub skontaktuj się z administratorem.");
                }

            });
        }

    }

    render() {
        const { name, users } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);

        const multiSelectMessages = {
            searchPlaceholder: "Szukaj...",
            noItemsMessage: "Nie znaleziono użytkowników...",
            noneSelectedMessage: "Nie wybrano",
            selectedMessage: "wybrany/ch",
            selectAllMessage: "Wybierz Wszystkich",
            clearAllMessage: "Wyczyść",
        }

        return (
            <Row>
                <Col sm="12">
                    <label>Nazwa dashboardu</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <>
                        <label>Użytkownicy</label>
                        <MultiSelect
                            items={this.state.formHints.users}
                            selectedItems={users}
                            onChange={this.multiselectChangeHandler}
                            messages={multiSelectMessages}
                            wrapperClassName={""} />
                    </>
                </Col>
            </Row>
        );
    }

}

export default CreateNewDashboardForm;
