import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import ControlPanelData from "../components/controlPanel-details/ControlPanelData";
import ControlPanelEvents from "../components/controlPanel-details/ControlPanelEvents";
import ControlPanelLogs from "../components/controlPanel-details/ControlPanelLogs";
import ControlPanelCameras from "../components/controlPanel-details/ControlPanelCameras";
import ControlPanelUsageCards from "../components/controlPanel-details/ControlPanelUsageCards";
import SetArchivePasswordModal from "../components/billing-history/modals/SetArchivePasswordModal";

import API from "../api/AxiosConfiguration";
import * as Constants from "../constants";
import * as Actions from "../utils/Actions";
import ProcedureModal from "../components/network-device-details/modals/ProcedureModal";
import ControlPanelStatus from "../components/controlPanel-details/ControlPanelStatus";
import ControlPanelSensors from "../components/controlPanel-details/ControlPanelSensors";
import ControlPanelDictionaries from "../components/controlPanel-details/ControlPanelDictionaries";
import DeviceRules from "../components/device-rules/DeviceRules";

class ControlPanelDetails extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      uuid: this.props.location.state ? this.props.location.state.uuid : "",
      name: this.props.location.state ? this.props.location.state.name : "",
      controlPanel: undefined,
      controlPanelStatus: undefined,
      controlPanelStatusLabel: "",
      controlPanelStats: [],
      appliedFilter: {},
      rules: [],
      requiredFilterOptions: {},
      isProceduresModalShow: false,
      isArchivePasswordModalShow: false,
      selectedEventRuleDetails: "",
      isDeviceDataLoading: false,
      isDeviceStatusLoading: false,
    }

    this.fetchControlPanelReferralData = this.fetchControlPanelReferralData.bind(this);
    this.toggleProceduresModal = this.toggleProceduresModal.bind(this);
    this.toggleArchivePasswordModal = this.toggleArchivePasswordModal.bind(this);
    this.onPasswordSet = this.onPasswordSet.bind(this);
    this.fetchControlPanelRules = this.fetchControlPanelRules.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchControlPanelReferralData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleArchivePasswordModal(action = () => { }) {
    this.setState({
      isArchivePasswordModalShow: !this.state.isArchivePasswordModalShow,
        continuationAction: action,
    })
  }

  onPasswordSet() {
    this.state.continuationAction();
    this.toggleArchivePasswordModal();
  }

  fetchControlPanelReferralData() {
    const { uuid } = this.state;
    if (this._isMounted)
      this.setState({
        isDeviceDataLoading: true,
        isDeviceStatusLoading: true
      }, () => {
        API.get(Constants.CONTROL_PANEL_URL + "/" + uuid)
          .then(result => {
            if (result.status === 200) {
              var controlPanel = result.data;
              var controlPanelStatus = Actions.getActionParameters(controlPanel.status, controlPanel.action);
              if (this._isMounted)
                this.setState({
                  controlPanel: controlPanel,
                  controlPanelStatus: controlPanelStatus,
                  appliedFilter: {
                    controlPanel: {
                      label: controlPanel.referralData.find(data => data.label === "Nazwa centrali").value,
                      value: controlPanel.referralData.find(data => data.label === "Nazwa centrali").value
                    }
                 },
                  isDeviceDataLoading: false,
                  isDeviceStatusLoading: false
                })
            }
          })
      });
  }

  fetchControlPanelRules(form) {
    return API.post(Constants.CONTROL_PANEL_RULE_URL, {
        form: form,
    }).then((result) => {
        var menuRuleInfo = result.data;
        if (this._isMounted) {
            this.setState({
                rules: menuRuleInfo.rules,
                requiredFilterOptions: menuRuleInfo.options
            });
        }
    })
}

  toggleProceduresModal(ruleDetails) {
    if (this._isMounted)
      this.setState({
        isProceduresModalShow: !this.state.isProceduresModalShow,
        selectedEventRuleDetails: ruleDetails,
      });
  }

  render() {
    const { isDeviceDataLoading, isDeviceStatusLoading, name, uuid, controlPanel, controlPanelStatus, isProceduresModalShow, isArchivePasswordModalShow, selectedEventRuleDetails, rules, requiredFilterOptions, appliedFilter } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={name} subtitle="Centrala" className="text-sm-left mb-3" />
        </Row>
        <Row>
          <ControlPanelUsageCards />
        </Row>
        <Row>
          <Col lg="4" md="12" sm="12" className="mb-3">
            <ControlPanelStatus
              status={controlPanelStatus}
              isLoading={isDeviceStatusLoading} />

            <ControlPanelData
              uuid={uuid}
              data={controlPanel}
              onUpdate={this.fetchControlPanelReferralData}
              isLoading={isDeviceDataLoading} />
          </Col>
          <Col lg="8" md="12" sm="12" className="mb-3">
            {controlPanel && controlPanel.sensorSupported && <ControlPanelSensors uuid={uuid} />}
            {controlPanel && controlPanel.cameraSupported && <ControlPanelCameras uuid={uuid} />}
            {controlPanel && controlPanel.dictionarySupported && <ControlPanelDictionaries uuid={uuid} />}

            <ControlPanelEvents controlPanel={controlPanel} uuid={uuid} proceduresModal={this.toggleProceduresModal} passwordModal={ this.toggleArchivePasswordModal } />
            {controlPanel && controlPanel.dictionarySupported && <ControlPanelLogs uuid={uuid}  passwordModal={ this.toggleArchivePasswordModal } />}
          </Col>
        </Row>
        <Row>
          <Col>
            <DeviceRules rules={rules} options={requiredFilterOptions} fetchDeviceRules={this.fetchControlPanelRules} appliedFilter={appliedFilter} detailsType={'controlPanel'} /> {/* DetailsType: networkDevice, controlPanel, pcDevice */}
          </Col>
        </Row>
        <ProcedureModal open={isProceduresModalShow} content={selectedEventRuleDetails}
          toggle={this.toggleProceduresModal} />
        <SetArchivePasswordModal open={isArchivePasswordModalShow} toggle={this.toggleArchivePasswordModal} onSuccess={this.onPasswordSet} />
      </Container>
    )
  }
}

export default ControlPanelDetails;
