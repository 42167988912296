import React from 'react'
import { Tooltip, FormCheckbox, CardFooter, FormSelect, Row, Col } from 'shards-react';
import { TYPE_OF_THEME } from './../../../layouts/themes/Themes';
import { TYPE_OF_FONTS_FAMILY } from './../../../layouts/fonts/FontsFamily';
import { TYPE_OF_FONTS_SIZE } from './../../../layouts/fonts/FontsSize';
import i18n from './../../../i18n';

class WebPushActivityForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            highContrastEnabled: localStorage.getItem('theme') === TYPE_OF_THEME.HIGH_CONTRAST_MODE ? true : false,
            readingTextEnabled: localStorage.getItem('readingText') === "true" ? true : false,
            font: {
                fontSize: localStorage.getItem('fontSize') !== null ? localStorage.getItem('fontSize') : TYPE_OF_FONTS_SIZE.NORMAL,
                fontFamily: localStorage.getItem('fontFamily') !== null ? localStorage.getItem('fontFamily') : TYPE_OF_FONTS_FAMILY.NORMAL
            },
            tooltip: {
                readingText: false,
            },
        };

        this.handleChangeHighContrast = this.handleChangeHighContrast.bind(this);
        this.handleChangeReadingText = this.handleChangeReadingText.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
    }

    handleChangeHighContrast() {
        const highContrastEnabled = !this.state.highContrastEnabled;
        this.setState({
            highContrastEnabled: !this.state.highContrastEnabled
        });

        localStorage.setItem('theme', highContrastEnabled ? TYPE_OF_THEME.HIGH_CONTRAST_MODE : "");
        window.location.reload();
    }

    handleChangeReadingText() {
        const readingTextEnabled = !this.state.readingTextEnabled;
        this.setState({
            readingTextEnabled: !this.state.readingTextEnabled
        });

        localStorage.setItem('readingText', readingTextEnabled);
        window.location.reload();
    }

    selectChangeHandlerFontSize = (e) => {
        var { font } = this.state;
        font["fontSize"] = e.target.value;
        this.setState({ font: font });
        localStorage.setItem("fontSize", e.target.value);
        window.location.reload();
    }

    selectChangeHandlerFontFamily = (e) => {
        var { font } = this.state;
        font["fontFamily"] = e.target.value;
        this.setState({ font: font });
        localStorage.setItem("fontFamily", e.target.value);
        window.location.reload();
    }

    showTooltip(name) {
        var { tooltip } = this.state;
        tooltip[name] = !tooltip[name];
        this.setState({
            tooltip: tooltip
        });
    }

    render() {
        return (
            <CardFooter style={{ paddingTop: "0.33375rem" }}>
                <Row>
                    <Col sm="12" md="6">
                        <FormCheckbox
                            toggle
                            small
                            checked={this.state.highContrastEnabled}
                            onChange={this.handleChangeHighContrast}>
                            Wysoki kontrast
                        </FormCheckbox>
                    </Col>
                    <Col sm="12" md="6">
                        <FormCheckbox
                            toggle
                            small
                            checked={this.state.readingTextEnabled}
                            onChange={this.handleChangeReadingText}>
                            Czytanie strony <i id="tooltipReadingText" style={{ marginTop: '6px' }} className="fas fa-info-circle"></i>
                        </FormCheckbox>
                        <Tooltip
                            className="tooltip-modal"
                            open={this.state.tooltip.readingText}
                            target={'#tooltipReadingText'}
                            toggle={() => this.showTooltip("readingText")}>{i18n.t('readingText')}
                        </Tooltip>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6">
                        <label>Rozmiar czcionki</label>
                        <FormSelect name="" onChange={this.selectChangeHandlerFontSize} value={this.state.font.fontSize}>
                            <option value="NORMAL">Standardowy</option>
                            <option value="LARGE">Duży</option>
                        </FormSelect>
                    </Col>
                    <Col sm="12" md="6">
                        <label>Styl czcionki</label>
                        <FormSelect name="" onChange={this.selectChangeHandlerFontFamily} value={this.state.font.fontFamily}>
                            <option value="NORMAL">Standardowy</option>
                            <option value="VERDANA">Verdana</option>
                        </FormSelect>
                    </Col>
                </Row>
            </CardFooter>
        );
    }

}

export default WebPushActivityForm;