import React from 'react';
import videojs from 'video.js'
import videoJsPolish from 'video.js/dist/lang/pl.json'
import 'video.js/dist/video-js.min.css'

class VideoPlayer extends React.Component {

    isRetryingPlaylist = false;
    maxAttempts = 3;
    attempts = 0;
    modal = null;

    componentDidMount() {
        videojs.addLanguage('pl', videoJsPolish);
        this.initializePlayer();
    }

    componentDidUpdate() {
        if (Array.isArray(this.props.sources) && this.props.sources.length > 0) {
            this.player.loadingSpinner.show();
            this.player.src(
                this.props.sources[0]
            );
        }
    }

    componentWillUnmount() {
        this.disposePlayer();
    }

    initializePlayer() {
        this.player = videojs(this.videoNode, { ...this.props, language: 'pl' }, function onPlayerReady() {
            this.loadingSpinner.show();
        });

        this.initializePlayerListners();
    }

    initializePlayerListners() {
        this.player.on('ended', () => {
            if (this.props.onEndStream) {
                this.props.onEndStream((media) => {
                    this.player.loadingSpinner.show();
                    this.player.src({
                        type: media.type,
                        src: media.src
                    });
                });
            }
        });

        this.player.tech().on('retryplaylist', () => {
            this.player.reset();

            if (!this.modal || !this.modal.opened) {
                this.modal = this.player.createModal('Zamknij okienko jeżeli chcesz nadal oglądać');

                this.modal.on('modalclose', () => {
                    this.initializePlayerListners();
                    this.props.onEndStream((media) => {
                        this.player.src({
                            type: media.type,
                            src: media.src
                        });
                        this.modal = null;
                    });
                });
            }
        })
    }

    disposePlayer() {
        if (this.player) {
            this.player.dispose()
        }
    }

    render() {
        return (
            <div className='w-100'>
                <div data-vjs-player>
                    <video ref={node => this.videoNode = node} className="video-js"></video>
                </div>
            </div>
        )
    }

}

export default VideoPlayer;