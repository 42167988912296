import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Collapse,
} from "shards-react";
import Select from 'react-select';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import classNames from "classnames";

import API from "../../api/AxiosConfiguration";
import * as Constants from "../../constants";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'

class DeviceStatusTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                unit: '',
            },
            deviceStatusRows: [],
            collapse: false,
        }

        this.fetchNetworkDevicePortStatus = this.fetchNetworkDevicePortStatus.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.toggle = this.toggle.bind(this);
        this.stopToggle = this.stopToggle.bind(this);
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    stopToggle(e) {
        e.stopPropagation();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.form.unit.length <= 0) {
            return {
                form: {
                    ...state.form,
                    unit: props.units[1]
                }
            }
        }

        return null;
    }

    componentDidMount() {
        this.fetchNetworkDevicePortStatus();
    }

    selectChangeHandler = (item, event) => {
        const { form } = this.state;
        form[event.name] = item;
        this.setState({ form: form });
    }

    fetchNetworkDevicePortStatus() {
        const { uuid, type } = this.props;
        API.get(Constants.NETWORK_DEVICE_STATUS_TABLE_URL + "/" + uuid + "/" + type)
            .then(result => {
                if (result.status === 200) {
                    var deviceStatusRows = result.data;
                    this.setState({
                        deviceStatusRows: deviceStatusRows,
                    })
                }
            })
    }

    render() {
        const { deviceStatusRows, collapse } = this.state;
        const { type, deviceModel } = this.props;
        const { unit } = this.state.form;

        var detectedEventColumns;

        const defaultSelectProps = {
            placeholder: "Wybierz",
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        const paginationOptions = {
            custom: true,
            totalSize: deviceStatusRows.length,
            sizePerPage: 10
        };

        function dataUnitFormatter(cell, row) {
            var kilobytes = cell ? (parseInt(cell.toString().replace(/\D/g, '')) / unit.value).toFixed(2) + " " + unit.label : "-";
            if (cell !== "Brak danych") {
                return (
                    <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{kilobytes ? kilobytes : "-"}</span>
                );
            } else {
                return (
                    <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell}</span>
                );
            }
        }

        function macFormatter(cell, row) {
            cell = cell.replace(/\s/g, ':').substring(1, cell.length - 2);
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell ? cell : "-"}</span>
            );
        }

        function octetsFormatter(cell, row) {
            cell = cell.replace('-', '');
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell ? cell : "-"}</span>
            );
        }

        function textFormatter(cell, row) {
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell ? cell : "-"}</span>
            );
        }

        if (deviceModel === "S4112F") {
            switch (type) {
                case Constants.TableStatusType.PHYSICAL_PORTS:
                case Constants.TableStatusType.PORT_CHANNEL:
                case Constants.TableStatusType.VLAN:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas ostatniej zmiany",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status Portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.PORT_BANDWIDTH:
                    detectedEventColumns = [{
                        dataField: 'value.ifDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.ifOutOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość wychodząca [b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }, {
                        dataField: 'value.ifInOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość przychodząca[b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }]
                    break;
                case Constants.TableStatusType.CONNECTED_DEVICES:
                    detectedEventColumns = [{
                        dataField: 'value.ipNetToMediaNetAddress',
                        headerAlign: 'center',
                        align: "center",
                        text: "Adres IP",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.ipNetToMediaPhysAddress',
                        headerAlign: 'center',
                        align: "center",
                        text: "Adres MAC",
                        headerClasses: "column-header",
                        formatter: macFormatter
                    }]
                    break;
                case Constants.TableStatusType.SERVICES:
                    detectedEventColumns = [{
                        dataField: 'value.hrSWRunName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.hrSWRunStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.RAM_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.memAvailReal',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dostępna",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.memTotalReal',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowita",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.memPercentagesReal',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                default:
            }
        } else if (deviceModel === "SN310A" || deviceModel === "SN510A") {
            switch (type) {
                case Constants.TableStatusType.ETHERNET:
                case Constants.TableStatusType.IPSEC:
                case Constants.TableStatusType.VLAN:
                case Constants.TableStatusType.SSL_VPN:
                    detectedEventColumns = [{
                        dataField: 'value.snsifTable_SnsifUserName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.snsifTable_SnsifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.snsifTable_SnsifAddr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Adres odbiorcy",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status portu",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.PORT_BANDWIDTH:
                    detectedEventColumns = [{
                        dataField: 'value.snsifTable_SnsifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.snsifOutCurThroughput',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość wychodząca [b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }, {
                        dataField: 'value.snsifInCurThroughput',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość przychodząca[b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }]
                    break;
                case Constants.TableStatusType.SERVICES:
                    detectedEventColumns = [{
                        dataField: 'value.snsServicesTable_SnsServicesName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa Serwisu",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.snsServicesTable_SnsServicesState',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.ROUTES:
                    detectedEventColumns = [{
                        dataField: 'value.snsRouteType',
                        headerAlign: 'center',
                        align: "center",
                        text: "Typ",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsRouteRouterName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa bramy",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsRouteGatewayAddr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Adres bramy",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsRouteState',
                        headerAlign: 'center',
                        align: "center",
                        text: "Stan",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsRouteStateLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas aktywności",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.IPS:
                    detectedEventColumns = [{
                        dataField: 'value.snsATime',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsAProto',
                        headerAlign: 'center',
                        align: "center",
                        text: "Typ",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsADaddr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dest",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsASaddr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Source",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsADport',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port Dest",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsASport',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port Source",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsAMessage',
                        headerAlign: 'center',
                        align: "center",
                        text: "Message",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.SYSTEM_EVENTS:
                    detectedEventColumns = [{
                        dataField: 'value.snsATime',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.snsAMessage',
                        headerAlign: 'center',
                        align: "center",
                        text: "Message",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.TEMP:
                    detectedEventColumns = [{
                        dataField: 'value.snsCpuIndex',
                        headerAlign: 'center',
                        align: "center",
                        text: "Unit",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.snsCpuTemp',
                        headerAlign: 'center',
                        align: "center",
                        text: "Temperatura",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.hrProcessorLoad',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie procesora",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.CPU_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.snsFirewallIndex',
                        headerAlign: 'center',
                        align: "center",
                        text: "Unit",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.snsCpuHealth',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.RAM_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.memAvailReal',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dostępna",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.memTotalReal',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowita",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.systemPercentagesMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                default:
            }
        } else if (deviceModel === "N1124T") {
            switch (type) {
                case Constants.TableStatusType.ETHERNET:
                case Constants.TableStatusType.OPTICAL_FIBER:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas ostatniej zmiany",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Stan portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.PORT_BANDWIDTH:
                    detectedEventColumns = [{
                        dataField: 'value.ifDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.ifOutOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość wychodząca [b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }, {
                        dataField: 'value.ifInOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość przychodząca[b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }]
                    break;
                case Constants.TableStatusType.PROCESSES:
                    detectedEventColumns = [{
                        dataField: 'value.agentSwitchCpuProcessName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa Procesu",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.RAM_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.agentSwitchCpuProcessMemFree',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dostępna",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.agentSwitchCpuProcessMemAvailable',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowita",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.systemPercentagesMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                default:
            }
        } else if (deviceModel === "N1124P") {
            switch (type) {
                case Constants.TableStatusType.ETHERNET:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '200px' };
                        }
                    }, {
                        dataField: 'value.ifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas ostatniej zmiany",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '200px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Stan portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '200px' };
                        }
                    }, {
                        dataField: 'value.pethPsePortDetectionStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Stan POE",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.OPTICAL_FIBER:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas ostatniej zmiany",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Stan portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '200px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.PORT_BANDWIDTH:
                    detectedEventColumns = [{
                        dataField: 'value.ifDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.ifOutOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość wychodząca [b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }, {
                        dataField: 'value.ifInOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość przychodząca[b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }]
                    break;
                case Constants.TableStatusType.PROCESSES:
                    detectedEventColumns = [{
                        dataField: 'value.agentSwitchCpuProcessName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa Procesu",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.RAM_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.agentSwitchCpuProcessMemFree',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dostępna",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.agentSwitchCpuProcessMemAvailable',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowita",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.systemPercentagesMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                default:
            }
        } else if (deviceModel === "TS-883XU-RP") {
            switch (type) {
                case Constants.TableStatusType.PHYSICAL_PORTS:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '200px' };
                        }
                    }, {
                        dataField: 'value.ifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Stan portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.FANS:
                    detectedEventColumns = [{
                        dataField: 'value.systemFanIndex',
                        headerAlign: 'center',
                        align: "center",
                        text: "ID",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.systemFanStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.systemFanSpeed',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość [obr/min]",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.VOLUME:
                    detectedEventColumns = [{
                        dataField: 'value.sysVolumeIndex',
                        headerAlign: 'center',
                        align: "center",
                        text: "Numer",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.sysVolumeDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.sysVolumeFreeSize',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wolne miejsce",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.sysVolumeTotalSize',
                        headerAlign: 'center',
                        align: "center",
                        text: "Pojemność",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.sysVolumeStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.POWER:
                    detectedEventColumns = [{
                        dataField: 'value.systemPowerIndex',
                        headerAlign: 'center',
                        align: "center",
                        text: "Numer",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.systemPowerStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.DISK:
                    detectedEventColumns = [{
                        dataField: 'value.hdIndex',
                        headerAlign: 'center',
                        align: "center",
                        text: "Numer",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.diskModel',
                        headerAlign: 'center',
                        align: "center",
                        text: "Model",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.diskTemperature',
                        headerAlign: 'center',
                        align: "center",
                        text: "Temperatura [°C]",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.hdCapacity',
                        headerAlign: 'center',
                        align: "center",
                        text: "Pojemność",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.hdStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.RAM_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.systemFreeMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dostępne",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.systemTotalMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowite",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.systemPercentagesMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                default:
            }
        } else if (deviceModel === "SG200-26" || deviceModel === "SRW224G4") {
            switch (type) {
                case Constants.TableStatusType.PHYSICAL_PORTS:
                case Constants.TableStatusType.PORT_CHANNEL:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas ostatniej zmiany",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status Portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.PORT_BANDWIDTH:
                    detectedEventColumns = [{
                        dataField: 'value.ifDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.ifOutOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość wychodząca [b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }, {
                        dataField: 'value.ifInOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość przychodząca[b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }]
                    break;
                case Constants.TableStatusType.CONNECTED_DEVICES:
                    detectedEventColumns = [{
                        dataField: 'value.dot1dTpFdbPort',
                        headerAlign: 'center',
                        align: "center",
                        text: "Numer portu",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.dot1dTpFdbAddress',
                        headerAlign: 'center',
                        align: "center",
                        text: "Adres MAC",
                        headerClasses: "column-header",
                        formatter: macFormatter
                    }]
                    break;
                case Constants.TableStatusType.FANS:
                    detectedEventColumns = [{
                        dataField: 'value.rlEnvMonFanStatusDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "ID",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.rlEnvMonFanState',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                default:
            }
        } else if (deviceModel === "DS3615xs" || deviceModel === "DS412+") {
            switch (type) {
                case Constants.TableStatusType.PHYSICAL_PORTS:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas ostatniej zmiany",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status Portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.PORT_BANDWIDTH:
                    detectedEventColumns = [{
                        dataField: 'value.ifDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.ifOutOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość wychodząca [b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter,
                    }, {
                        dataField: 'value.ifInOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość przychodząca[b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter,
                    }]
                    break;
                case Constants.TableStatusType.CONNECTED_USERS:
                    detectedEventColumns = [{
                        dataField: 'value.serviceUsers',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa użytkownika",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.serviceName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Połączony za pomocą",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }]
                    break;
                case Constants.TableStatusType.DISK_MEMORY:
                    detectedEventColumns = [{
                        dataField: 'value.storageIODevice',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa dysku",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.storageIONWritten',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dane zapisane",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.storageIONRead',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dane odczytane",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.storageIOLA5',
                        headerAlign: 'center',
                        align: "center",
                        text: "Obciążenie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }]
                    break;
                case Constants.TableStatusType.PARTITION_MEMORY:
                    detectedEventColumns = [{
                        dataField: 'value.spaceIODevice',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa partycji",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.spaceIONWritten',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dane zapisane",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                    }, {
                        dataField: 'value.spaceIONRead',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dane odczytane",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                    }, {
                        dataField: 'value.spaceIOLA5',
                        headerAlign: 'center',
                        align: "center",
                        text: "Obciążenie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }]
                    break;
                case Constants.TableStatusType.RAID:
                    detectedEventColumns = [{
                        dataField: 'value.raidName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa RAID",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.raidStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.raidFreeSize',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wolne miejsce",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                    }, {
                        dataField: 'value.raidTotalSize',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowite miejsce",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                    }]
                    break;
                case Constants.TableStatusType.DISK:
                    detectedEventColumns = [{
                        dataField: 'value.diskID',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa dysku",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.diskModel',
                        headerAlign: 'center',
                        align: "center",
                        text: "Model",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.diskType',
                        headerAlign: 'center',
                        align: "center",
                        text: "Typ",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.diskTemperature',
                        headerAlign: 'center',
                        align: "center",
                        text: "Temperatura",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.diskStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }]
                    break;
                case Constants.TableStatusType.GRAPHIC_CARDS:
                    detectedEventColumns = [{
                        dataField: 'value.gpuMemoryUsed',
                        headerAlign: 'center',
                        align: "center",
                        text: "Użyta pamięć",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                    }, {
                        dataField: 'value.gpuMemoryTotal',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowita pamięć",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                    }, {
                        dataField: 'value.gpuMemoryUtilization',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }]
                    break;
                case Constants.TableStatusType.RAM_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.systemFreeMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dostępna pamięć RAM",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                    }, {
                        dataField: 'value.systemTotalMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowita pamięć RAM",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter,
                    }, {
                        dataField: 'value.systemPercentagesMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }]
                    break;
                case Constants.TableStatusType.GENERAL:
                    detectedEventColumns = [{
                        dataField: 'value.systemStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status systemu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.powerStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status zasilaczy",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.systemFanStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Status wentylatorów",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.temperature',
                        headerAlign: 'center',
                        align: "center",
                        text: "Temperatura urządzenia",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }, {
                        dataField: 'value.laLoadInt',
                        headerAlign: 'center',
                        align: "center",
                        text: "Obciążenie procesora w %",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                    }]
                    break;
                default:
            }
        } else if (deviceModel === "N1548") {
            switch (type) {
                case Constants.TableStatusType.ETHERNET:
                case Constants.TableStatusType.OPTICAL_FIBER:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas ostatniej zmiany",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Stan portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.PORT_BANDWIDTH:
                    detectedEventColumns = [{
                        dataField: 'value.ifDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.ifOutOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość wychodząca [b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }, {
                        dataField: 'value.ifInOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość przychodząca[b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }]
                    break;
                case Constants.TableStatusType.PROCESSES:
                    detectedEventColumns = [{
                        dataField: 'value.agentSwitchCpuProcessName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa Procesu",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.RAM_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.agentSwitchCpuProcessMemFree',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dostępna",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.agentSwitchCpuProcessMemAvailable',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowita",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.systemPercentagesMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                default:
            }
        } else if (deviceModel === "N3024") {
            switch (type) {
                case Constants.TableStatusType.ETHERNET:
                case Constants.TableStatusType.OPTICAL_FIBER:
                    detectedEventColumns = [{
                        dataField: 'value.ifAlias',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Interfejs",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifLastChange',
                        headerAlign: 'center',
                        align: "center",
                        text: "Czas ostatniej zmiany",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }, {
                        dataField: 'value.ifOperStatus',
                        headerAlign: 'center',
                        align: "center",
                        text: "Stan portu",
                        headerClasses: "column-header",
                        formatter: textFormatter,
                        headerStyle: (colum, colIndex) => {
                            return { width: '150px' };
                        }
                    }]
                    break;
                case Constants.TableStatusType.PORT_BANDWIDTH:
                    detectedEventColumns = [{
                        dataField: 'value.ifDescr',
                        headerAlign: 'center',
                        align: "center",
                        text: "Port",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }, {
                        dataField: 'value.ifOutOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość wychodząca [b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }, {
                        dataField: 'value.ifInOctets',
                        headerAlign: 'center',
                        align: "center",
                        text: "Prędkość przychodząca[b/s]",
                        headerClasses: "column-header",
                        formatter: octetsFormatter
                    }]
                    break;
                case Constants.TableStatusType.PROCESSES:
                    detectedEventColumns = [{
                        dataField: 'value.agentSwitchCpuProcessName',
                        headerAlign: 'center',
                        align: "center",
                        text: "Nazwa Procesu",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                case Constants.TableStatusType.RAM_LOAD:
                    detectedEventColumns = [{
                        dataField: 'value.agentSwitchCpuProcessMemFree',
                        headerAlign: 'center',
                        align: "center",
                        text: "Dostępna",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.agentSwitchCpuProcessMemAvailable',
                        headerAlign: 'center',
                        align: "center",
                        text: "Całkowita",
                        headerClasses: "column-header",
                        formatExtraData: this.state.form.unit,
                        formatter: dataUnitFormatter
                    }, {
                        dataField: 'value.systemPercentagesMem',
                        headerAlign: 'center',
                        align: "center",
                        text: "Wykorzystanie w %",
                        headerClasses: "column-header",
                        formatter: textFormatter
                    }]
                    break;
                default:
            }
        }


        const renderNetworkDevicePortStatus = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='uniqueId'
                                data={deviceStatusRows}
                                columns={detectedEventColumns}
                                classes="table mb-0"
                                headerClasses="column-header text-right"
                                bordered={false}
                                rowStyle={{ height: '10px' }}
                                {...paginationTableProps} />
                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderDevicePortStatusFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Brak danych</span>
                </ListGroupItem>
            </ListGroup>
        )

        const cardHeaderClasses = classNames(
            "border-bottom",
            "justify-content-between",
            "d-flex",
            "flex-row",
            "align-items-center",
            typeof (faChevronDown) !== "undefined" ?
                faChevronDown ? "rounded" : "rounded-top" : "rounded"
        );

        return (
            <Card className="mb-3">
                <CardHeader className={cardHeaderClasses} onClick={this.toggle}>
                    <h6 className="m-0 float-left">{Constants.TableStatusTypeDescription[type]}</h6>
                    <div className="block-handle" />
                    {(type === Constants.TableStatusType.RAM_LOAD || type === Constants.TableStatusType.DISK_MEMORY
                        || type === Constants.TableStatusType.PARTITION_MEMORY || type === Constants.TableStatusType.RAID
                        || type === Constants.TableStatusType.GRAPHIC_CARDS) &&
                        <div className="float-right" style={{ width: "80px" }} onClick={this.stopToggle}>
                            {collapse ?
                                <Select
                                    {...defaultSelectProps}
                                    name="unit"
                                    value={unit}
                                    onChange={this.selectChangeHandler}
                                    className="react-select-container mb-2"
                                    options={this.props.units} />
                                : ""}
                        </div>
                    }
                    <div className="icon-container">                    {collapse ?
                        <FontAwesomeIcon className="nav-collapse-icon" icon={faChevronDown} /> :
                        <FontAwesomeIcon className="nav-collapse-icon" icon={faChevronRight} />}</div>
                </CardHeader>
                <Collapse open={this.state.collapse}>
                    <CardBody className="p-0">
                        {deviceStatusRows.length ? renderNetworkDevicePortStatus() : renderDevicePortStatusFetchError()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default DeviceStatusTable;

DeviceStatusTable.defaultProps = {
    units: [{ label: "GB", value: 1048576 }, { label: "MB", value: 1024 }, { label: "KB", value: 1 }]
}