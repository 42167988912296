import React from "react";
import {
    Card,
    CardBody,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
} from "shards-react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import WindowSizeListener from 'react-window-size-listener';
import dateFormat from "dateformat";

class ControlPanelAbsencesTables extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false
        }

    }

    render() {
        const { absences, toggleCreateAbsenceModal, toggleDeleteAbsenceModal } = this.props;
        var absenceColumns;

        const paginationOptions = {
            custom: true,
            totalSize: absences.length,
            sizePerPage: 10
        };

        function fullNameFormatter(cell, row) {
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{row.firstName} {row.lastName}</span>
            );
        }

        function eventDateFormatter(cell, row) {
            return (
                <span className="text-semibold text-fiord-blue" style={{ fontSize: ".8125rem" }}>{dateFormat(new Date(row.fromDate), "dd.mm.yyyy")} - {dateFormat(new Date(row.toDate), "dd.mm.yyyy")}</span>
            );
        }

        function textFormatter(cell, row) {
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell}</span>
            );
        }

        function buttonFormatter(cell, row) {
            return (
                <span>
                    <Button onClick={() => toggleCreateAbsenceModal(row.uuid)} outline theme="info" className="mb-1 mr-1">
                        Edytuj
                    </Button>
                    <Button onClick={() => toggleDeleteAbsenceModal(row.uuid)} outline theme="info" className="mb-1 mr-1">
                        Usuń
                    </Button>
                </span>
            );
        }

        if (!this.state.isMobile) {
            absenceColumns = [{
                dataField: 'name',
                align: "left",
                text: "Imię i Nazwisko",
                headerClasses: "column-header",
                formatter: fullNameFormatter
            }, {
                dataField: 'absenceTime',
                align: "left",
                text: "Czas Nieobecności",
                headerClasses: "column-header",
                formatter: eventDateFormatter
            }, {
                dataField: 'absenceType',
                align: "left",
                text: "Typ",
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: 'phoneNumber',
                align: "left",
                text: "Numer Telefonu",
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: '',
                align: "left",
                text: "Akcja",
                headerClasses: "column-header",
                formatter: buttonFormatter
            }]
        } else {
            absenceColumns = [{
                dataField: '',
                align: "left",
                text: "Imię i Nazwisko",
                headerClasses: "column-header",
                formatter: fullNameFormatter
            }, {
                dataField: '',
                align: "left",
                text: "Czas Nieobecności",
                headerClasses: "column-header",
                formatter: eventDateFormatter
            }]
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                e.preventDefault();
                if (!(window.getSelection && window.getSelection().type === 'Range')) {
                    toggleCreateAbsenceModal(row.uuid);
                }
            }
        };

        const renderAbsences = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='uuid'
                                data={absences}
                                columns={absenceColumns}
                                classes="table mb-0"
                                headerClasses="column-header"
                                bordered={false}
                                rowStyle={{ height: '10px' }}
                                rowEvents={this.state.isMobile ? rowEvents : null}
                                {...paginationTableProps} />
                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderAbsencesError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Brak danych</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <Card medium="true">
                <WindowSizeListener onResize={(windowSize) =>
                    windowSize.windowWidth > 610 ? this.setState({ isMobile: false }) : this.setState({ isMobile: true })}>
                </WindowSizeListener>

                <CardBody className="p-0">
                    {absences.length ? renderAbsences() : renderAbsencesError()}
                </CardBody>
            </Card>
        )
    }
}

export default ControlPanelAbsencesTables;
