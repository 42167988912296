import React from "react";
import { Row, Col } from "shards-react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone
} from 'react-bootstrap-table2-paginator';

import { InfoModal } from "../../common/Modal";

class DeviceSearcherResultModal extends React.Component {

    redirectToDetails(uuid) {
        this.props.history.push({
            pathname: "/network-details",
            state: {
                uuid: uuid,
            }
        });
    }

    render() {
        const {
            open,
            toggle,
            foundDevices,
            foundDevicesColumns,
        } = this.props;

        const paginationOptions = {
            custom: true,
            totalSize: foundDevices.length,
            sizePerPage: 6
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                e.preventDefault();
                if (!(window.getSelection && window.getSelection().type === 'Range')) {
                    this.redirectToDetails(row.uuid);
                }
            },
        };

        const BoostrapTableWithPagination = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='uuid'
                                data={foundDevices}
                                columns={foundDevicesColumns}
                                classes="table mb-0"
                                headerClasses="react-bootstrap-table-header"
                                bordered={false}
                                hover
                                rowEvents={rowEvents}
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="12" className="d-flex justify-content-end">
                            <div className="mr-2 pr-3"><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        return (
            <InfoModal
                title="Wyniki wyszukiwania"
                open={open}
                toggle={toggle}
                closeButtonText="Zamknij"
                bodyClassName="p-0">

                {foundDevices.length > 0 ? <BoostrapTableWithPagination /> : <div className="text-center py-3">Nie znaleziono żadnych urządzeń</div>}
            
            </InfoModal>
        )
    }
}

export default DeviceSearcherResultModal;

DeviceSearcherResultModal.defaultProps = {
    foundDevicesColumns: [{
        dataField: 'name',
        text: 'Nazwa urządzenia',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true
    }, {
        dataField: 'mac',
        text: 'MAC',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true
    }, {
        dataField: 'user',
        text: 'Użytkownik',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row) => {
            return cell ? cell : "-"
        }
    }],
}
