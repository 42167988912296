import React from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    CardFooter,
    Tooltip,
} from "shards-react";

import * as AppConstants from "../../constants";
import * as Actions from "../../utils/Actions";
import LinkButton from './../buttons/LinkButton';
import { Store } from "../../flux"

class RuleCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showHint: false
        }

        this.toggleHintIfTitleIsTooLong = this.toggleHintIfTitleIsTooLong.bind(this);
    }


    toggleHintIfTitleIsTooLong() {
        const element = document.getElementById('ruleName-' + this.props.id);
        if (element.clientHeight < element.scrollHeight ||
            element.clientWidth < element.scrollWidth)
            this.setState({
                showHint: !this.state.showHint
            })
    }

    render() {
        const { name, status, action, ruleDetails, withoutButtons, onlyEditButton, onDelete, onEdit, id, onAcceptDevice, onDeclineDevice } = this.props;

        const SocAdminActions = () => (
            <Row style={{ marginTop: "-5px" }}>
                <Col><LinkButton onClick={onAcceptDevice} className="" style={{ color: "green", textDecoration: "underline", fontSize: "small" }}>Zaakceptuj</LinkButton></Col>
                {action !== AppConstants.ACTIONS.TO_DELETE &&
                    <Col><LinkButton onClick={onDeclineDevice} className="" style={{ color: "red", textDecoration: "underline", fontSize: "small" }}>Odrzuć</LinkButton></Col>}
            </Row>
        )

        const separateArrayByComma = (value) => {
            return Array.isArray(value) ? value.join(", ") : value;
        }

        const DeviceDetails = () => {
            return ruleDetails.map((element, index) => {
                if (ruleDetails && element.label)
                    return (
                        <Row key={index}>
                            <Col>{element.label}</Col>
                            <Col>
                                {typeof element.value !== "boolean" ?
                                    element.value ? separateArrayByComma(element.value) : "-" :
                                    element.value ? "Włączony" : "Wyłączony"}
                            </Col>
                        </Row>
                    )
                return null;
            })
        }

        const threeDotsNameStyles = {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        }

        const isSocAdmin = Store.getUserRole() === AppConstants.Roles.SOCADMIN;
        const isPending = status === AppConstants.STATUSES.PENDING;

        return (
            <div>
                <Card className="mt-2">
                    <CardBody className="small-padding">
                        <Tooltip
                            open={this.state.showHint}
                            target={`#ruleName-${id}`}
                            toggle={this.toggleHintIfTitleIsTooLong}>
                            {name}
                        </Tooltip>

                        <CardTitle id={`ruleName-${id}`}
                            className="text-center pb-2"
                            style={threeDotsNameStyles} >{name}</CardTitle>
                        <CardSubtitle className="text-center d-flex justify-content-center flex-column">
                            <Row>
                                <Col>
                                    {Actions.getActionParameters(status, action).content}
                                </Col>
                            </Row>

                            {isPending && isSocAdmin && <SocAdminActions />}
                        </CardSubtitle>
                        <hr className="mt-2 mb-2" />
                        <div className="device-card-details">
                            <DeviceDetails />
                        </div>
                    </CardBody>
                    <CardFooter>
                        {(!withoutButtons && !onlyEditButton) &&
                            <div>
                                <Button onClick={onEdit} outline theme="info" className="mb-2 mr-1 float-right">Edytuj</Button>
                                <Button onClick={onDelete} outline theme="danger" className="mb-2 mr-1 float-right">Usuń</Button>
                            </div>}

                        {(!withoutButtons && onlyEditButton) &&
                            <div>
                                <Button onClick={onEdit} outline theme="info" className="mb-2 mr-1 float-right">Edytuj</Button>
                            </div>}
                    </CardFooter>
                </Card>
            </div>
        );
    }
}

export default RuleCard;
