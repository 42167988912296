import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";
import { Store, Constants, Dispatcher } from "../../../flux";
import { NavItem } from "shards-react";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";
import DashboardSelectDashboard from "../../dashboard/DashboardSelectDashboard";


const MainNavbar = ({ layout, stickyTop }) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  const [isMobileView, setIsMobileView] = useState(false);

  const onChange = () => {
    setIsMobileView(Store.isMobileView);
  }

  useEffect(() => {
    Store.addChangeListener(onChange);
    return () => {
      Store.removeChangeListener(onChange);
    }
  }, [])

  const toggleModal = (formType = "") => {
    Dispatcher.dispatch({
        actionType: Constants.TOGGLE_DASHBOARD_MODAL,
        payload: {
            formType
        }
    })
  }

  const isDashboardActive = () => {
    switch(window.location.pathname) {
      case '/':
      case '/dashboard': {
        return true;
      }
      default: return false;
    } 
   }



  return (
    <div className={classes}>
      <Container className="p-0" style={{ maxWidth: "100%" }}>
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0" style={{ justifyContent: "flex-end" }}>
          { (isDashboardActive() && !isMobileView) ? 
            <NavItem className="pl-4" style={ { width: '100%' } }>
                <DashboardSelectDashboard toggle={toggleModal} isMobileView={isMobileView}/>
             </NavItem>
           : null }
          <NavbarNav />
          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
