import React from "react";
import { Col } from "shards-react";
import SmallCard from "../common/SmallCard";
import API from "../../api/AxiosConfiguration";
import * as Constants from "../../constants";

class DeviceStatusCards extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            deviceStats: []
        }

        this.fetchDeviceStatusCards = this.fetchDeviceStatusCards.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchDeviceStatusCards();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchDeviceStatusCards() {
        const { uuid } = this.props;

        API.get(Constants.NETWORK_DEVICE_URL + "/" + uuid + "/condition")
            .then(result => {
                if (result.status === 200) {
                    var deviceStats = result.data.stats;
                    if (this._isMounted)
                        this.setState({
                            deviceStats: deviceStats ? deviceStats : []
                        })
                }
            })
    }

    render() {
        const renderUsageCards = () => {
            const { deviceStats } = this.state;
            return deviceStats.map((stats, idx) => (
                <Col className="mb-3" sm="3" key={idx}>
                    <SmallCard label={stats.label}>{`${stats.value}${stats.unit==="C" ? "°C" : stats.unit}`}</SmallCard>
                </Col>
            ))
        }

        return renderUsageCards();
    }
}

export default DeviceStatusCards;