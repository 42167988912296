import * as Constants from "../constants"

export const getUserReadableStatus = (status) => {
    var result = {
        status: status,
    }

    switch (status) {
        case Constants.STATUSES.INVITED: return { ...result, content: "Zaproszony", color: "#FFA500" }
        case Constants.STATUSES.ACCEPTED: return { ...result, content: "Aktywny", color: "#007bff" }
        case Constants.STATUSES.BLOCKED: return { ...result, content: "Zablokowany", color: "#ff0000" }
        default: return undefined
    }

}
