import React from 'react';
import { FormTextarea, CardBody, CardHeader, Card } from 'shards-react';
import dateFormat from "dateformat";
import { getUserReadableFieldName } from '../../utils/CustomerTranslation';

class CustomerLogs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { logs } = this.props

        const textAreaStyles = {
            resize: "none",
            cursor: "context-menu",
            borderTopRightRadius: "0",
            borderTopLeftRadius: "0",
        }

        const renderLogs = () => {

            return logs.map(log => {
                var date = new Date(log.commitDate * 1000);
                const formattedDate = dateFormat(date, "dd-mm-yyyy HH:MM");
                var singleLog = formattedDate + " - " + log.author + " zmienił następujące dane osobowe:\n"

                log.changes.forEach(singleChange => {
                    var oldValue = singleChange.left ? singleChange.left : "-";
                    var newValue = singleChange.right ? singleChange.right : "-";

                    if (oldValue === "-" && newValue === "-") {
                        return ""
                    }

                    singleLog += "\t " + getUserReadableFieldName(singleChange.propertyName) + " z \"" + oldValue + "\" na \"" + newValue + "\"\n"
                });

                singleLog += "\n";
                return singleLog;
            }).join("");
        }

        return (
            <Card className="mb-3 w-100 h-100">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Logi</h6>
                    <div className="block-handle" />
                </CardHeader>

                <CardBody className="px-0 py-0 text-center">
                    <FormTextarea className="h-100" style={textAreaStyles} disabled value={renderLogs()} />
                </CardBody>
            </Card>
        );
    }

}

export default CustomerLogs;
