import React from 'react';
import { PropTypes } from 'prop-types';
import { reCaptchaSiteKey } from '../../config/Configuration'

class GoogleReCaptcha extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.loadScript = this.loadScript.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.executeCaptcha = this.executeCaptcha.bind(this);

        this.reCaptchaSiteKey = reCaptchaSiteKey
        this.script = null;
        this.widget = null;
    }

    componentDidMount() {
        this.loadScript();
    }

    componentWillUnmount() {
        this.widget && document.body.removeChild(this.widget)
        this.script && document.body.removeChild(this.script)
    }

    loadScript() {
        window.captchaOnLoad = this.onLoad

        this.script = document.createElement("script")
        this.script.type = 'text/javascript'
        this.script.src = "https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit"
        this.script.async = true
        this.script.defer = true

        this.script = document.body.appendChild(this.script)
    }

    onLoad() {
        this.widget = document.createElement('div')
        this.widget.id = 'g-recaptcha'
        this.widget = document.body.appendChild(this.widget)

        window.grecaptcha.render('g-recaptcha', {
            sitekey: this.reCaptchaSiteKey,
            size: 'invisible'
        })
    }

    async executeCaptcha() {
        return await window.grecaptcha.execute({ action: this.props.action })
            .then(token => {
                return token;
            })
    }

    render() {
        return (
            <div className="g-recaptcha"
                data-sitekey={this.reCaptchaSiteKey}
                data-size="invisible"></div>
        );
    }

}

export default GoogleReCaptcha;

GoogleReCaptcha.propTypes = {
    action: PropTypes.string,
};