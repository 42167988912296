import React from 'react';
import { Card, CardHeader, FormCheckbox } from 'shards-react';
import CustomerTwoFactorModal from "./modals/CustomerTwoFactorModal";

class CustomerTwoFactor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isCustomerTwoFactorModalShow: false
        }

        this.toggleCustomerDetailsModal = this.toggleCustomerDetailsModal.bind(this);
    }

    checkboxChangeHandler() {
        if (this.props.twoFactor) {
            this.toggleCustomerDetailsModal()
        }
    }

    toggleCustomerDetailsModal() {
        this.setState({
            isCustomerTwoFactorModalShow: !this.state.isCustomerTwoFactorModalShow,
        });
    }

    render() {
        const renderTwoFactorStatus = () => (
            <div className="float-right">
                <FormCheckbox toggle name="tokenState" checked={this.props.twoFactor || false} disabled={!this.props.twoFactor} onChange={(e) => this.checkboxChangeHandler()}></FormCheckbox>
            </div>
        )

        return (
            <Card className="mb-3 w-100">
                <CardHeader className="border-bottom" style={{ borderRadius: ".625rem .625rem .625rem .625rem" }}>
                    <div className="d-inline-flex align-items-center">
                        <h6 className="m-0">Uwierzytelnianie dwuskładnikowe</h6>
                    </div>
                    {renderTwoFactorStatus()}
                </CardHeader>
                <CustomerTwoFactorModal open={this.state.isCustomerTwoFactorModalShow} uuid={this.props.uuid} onSuccess={this.props.onSuccess}
                    toggle={this.toggleCustomerDetailsModal} />
            </Card>
        );
    }

}

export default CustomerTwoFactor;
