import React from 'react';
import { Redirect } from 'react-router-dom'
import { Col, Row } from 'shards-react';
import PasswordReset from '../components/reset-password/PasswordReset';

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPasswordReseted: false,
            resetPasswordToken: ''
        };

        this.setPasswordResetedTrue = this.setPasswordResetedTrue.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var resetPasswordToken = props.match.params.uuid;

        if (resetPasswordToken !== state.resetPasswordToken) {
            return { resetPasswordToken: resetPasswordToken }
        }

        return null;
    }

    setPasswordResetedTrue() {
        this.setState({
            isPasswordReseted: true
        });
    }

    render() {
        const { isPasswordReseted, resetPasswordToken } = this.state;

        if (!isPasswordReseted) {
            return (
                <Row>
                    <Col xs={12} md={{ size: 4, offset: 4 }}>
                        <PasswordReset resetPasswordToken={resetPasswordToken} onSuccess={this.setPasswordResetedTrue} />
                    </Col>
                </Row>
            );
        } else {
            return <Redirect to={{
                pathname: "/login",
                state: { message: "Hasło zostało poprawnie zresetowane. Możesz się teraz zalogować." }
            }} />;
        }
    }

}

export default ResetPassword;