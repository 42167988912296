import React, { Component, Suspense } from 'react';
import ConfigureDashboardTileForm from '../forms/ConfigureDashboardTileForm';
import CreateNewDashboardForm from '../forms/CreateNewDashboardForm';
import { ConfirmModal } from '../../common/Modal';
import { Store, Constants, Dispatcher } from '../../../flux';
import API from '../../../api/AxiosConfiguration';
import * as AppConstants from '../../../constants';
import * as SocToast from '../../../utils/SocToast';
import AddToDashboardForm from '../forms/AddToDashboardForm';

class DashboardModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dashboardToDeleteUuid: ''
        };

        this.setRef = this.setRef.bind(this);
        this.deleteDashboard = this.deleteDashboard.bind(this);
        this.onDeleteModalChange = this.onDeleteModalChange.bind(this);
        this.editTile = this.editTile.bind(this);
        this.addToDashboard = this.addToDashboard.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    componentDidMount() {
        Store.addDashboardToDeleteChangeListener(this.onDeleteModalChange);
    }



    componentWillUnmount() {
        Store.removeDashboardToDeleteChangeListener(this.onDeleteModalChange);
    }


    onDeleteModalChange() {
        var dashboardToDeleteUuid = Store.getDashboardToDelete();
        this.setState({
            dashboardToDeleteUuid
        })
    }

    deleteDashboard() {
        const { dashboardToDeleteUuid } = this.state;

        if(dashboardToDeleteUuid !== '') {
            API.delete(`${AppConstants.DASHBOARDS_URL}/${dashboardToDeleteUuid}`).then(result => {
                if(result.status === 200) {
                    SocToast.success("Dashboard usunięty pomyślnie");
                    Dispatcher.dispatch({ actionType: Constants.UPDATE_DASHBOARD_LIST });
                }
            }).catch(error => {
               if(error.response.status === 409) {
                    SocToast.error("Nie można usunąć dashboardu", "Nie możesz usunąć dashboardu, którego nie jesteś właścicielem");
                } else {
                    SocToast.error("Nie udało się usunąć dashboardu", "Spróbuj później lub skontaktuj się z administratorem");
                }
            }).finally(() => {
                this.props.toggle();
            });
        }
    }

    addToDashboard(data) {
            let isConfigureSaved = false;

            if(data !== null && data !== undefined
               && data.tileConfig !== null && data.tileConfig !== undefined) {
                let tempRequestBody = data;

                API.put(AppConstants.DASHBOARD_TILES_URL, tempRequestBody)
                  .then( response => {
                    if(response.status === 200) {
                      isConfigureSaved = true;
                      SocToast.success('Pomyślnie zapisano ustawienia');
                    }
                  })
                  .catch( error => {
                    SocToast.error('Nie udało się zapisać ustawień', 'Spróbuj ponownie za chwilę lub skontaktuj się z administratorem');
                  })
                  .finally( () => {
                    this.props.toggle();

                    if(isConfigureSaved) {
                        Dispatcher.dispatch({
                            actionType: Constants.SET_SELECTED_DASHBOARD,
                            payload: {
                                selectedDashboard: Store.getDashboardList().find( dashboard => dashboard.value === data.dashboardUuid)
                            }
                        })

                        this.props.history.push({
                            pathname: '/',
                        });
                    }

                  });
            }
    }

    editTile(tileUuid, form) {
        let isConfigureSaved = false;


        if(tileUuid !== '' && tileUuid !== null && tileUuid !== undefined) {
            API.post(`${AppConstants.DASHBOARD_TILES_URL}/${tileUuid}`, form).then( response => {
                if(response.status === 200) {
                    SocToast.success("Pomyślnie zapisano ustawienia.");
                    isConfigureSaved = true;
                }
            }).catch( error => {
                if(error.response.status === 409) {
                    SocToast.error("Nie udało się zapisać ustawień", "Nie możesz zmienić ustawień, jeżeli nie jesteś właścicielem dashboardu");
                } else {
                    SocToast.error("Nie udało się zapisać ustawień", "Spróbuj później lub skontaktuj się z administratorem");
                }
            }).finally(() => {
                this.props.toggle();

                if(isConfigureSaved) {
                    Dispatcher.dispatch({ actionType: Constants.UPDATE_DASHBOARD_LIST });

                    Dispatcher.dispatch({
                        actionType: Constants.TILE_FORCE_UPDATE,
                        payload: {
                            uuid: tileUuid
                        }
                      });
                }
            });
        }
    }

    render() {
        const {
            open,
            toggle,
            module,
            rules,
            uuid,
            formType,
            setTileConfig
        } = this.props;

        const renderConfirmFormForSpecifiedDeviceType = () => {
            const { formType } = this.props;


            return <Suspense fallback="loading">
                    { formType === 'createDashboard' ?
                        <CreateNewDashboardForm toggle={toggle} ref={this.setRef}  uuid={uuid} />
                    :
                        formType === 'addToDashboard' || formType === 'addToDashboardDetails' ?
                            <AddToDashboardForm toggle={toggle} ref={this.setRef} uuid={Store.getSelectedTile()} formType={formType} rules={rules} module={module} />
                        :
                            <ConfigureDashboardTileForm toggle={toggle} ref={this.setRef} uuid={Store.getSelectedTile()} formType={formType} />
                    }

            </Suspense>
        }



        const getTitle = () => {
            let title;

            // eslint-disable-next-line default-case
            switch(formType) {
                case 'createDashboard': {
                    title = 'Dodawanie nowego dashboardu';
                    break;
                }

                case 'addToDashboard': {
                    title = 'Dodawanie do dashboardu';
                    break;
                }

                case 'addToDashboardDetails': {
                    title = 'Szczegóły';
                    break;
                }

                case 'editTile':
                case 'configureTile': {
                    title = 'Konfiguracja'
                    break;
                }

                case 'deleteDashboard': {
                    title = 'Jesteś pewien, że chcesz usunąć dashboard?';
                    break;
                }

            }

            return title;
        }

        const getButton = () => {
            let btn;

            // eslint-disable-next-line default-case
            switch(formType) {

                case 'addToDashboard':
                case 'createDashboard': {
                    btn = 'Dodaj'
                    break;
                }

                case 'editTile':
                case 'configureTile': {
                    btn = 'Zapisz'
                    break;
                }

                case 'deleteDashboard': {
                    btn = 'Potwierdź'
                    break;
                }

            }

            return btn;
        }

        const getSubmitMethod = () => {
            switch(formType) {
                case 'deleteDashboard': return () => this.deleteDashboard();
                case 'editTile': return () => this.childRef.submitForm(this.editTile);
                case 'addToDashboard': return () => this.childRef.submitForm(this.addToDashboard);
                default: return () => this.childRef.submitForm(setTileConfig);
            }
        }

        return (
            <ConfirmModal
                title={ getTitle() }
                open={open}
                toggle={toggle}
                submitAction={ getSubmitMethod() }
                positiveButtonText={ formType !== 'addToDashboardDetails' && getButton() }
                negativeButtonText={ formType !== 'addToDashboardDetails' ? 'Anuluj' : 'Zamknij' } >

                {formType === 'deleteDashboard' ?
                    `Spowoduje to usunięcie wszystkich danych o dashboardzie.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".`
                :
                    renderConfirmFormForSpecifiedDeviceType()}

            </ConfirmModal>
        );
    }

}

export default DashboardModal;
