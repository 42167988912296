import React from "react";
import { Card, CardBody, CardFooter, Button, Row, Col } from "shards-react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    SizePerPageDropdownStandalone,
    PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import dateFormat from "dateformat";

class ControlPanelEventHistoryList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            events: []
        };

        this.redirectToDetails = this.redirectToDetails.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            events: nextProps.foundEvents ? nextProps.foundEvents : []
        })
    }

    redirectToDetails(uuid, type) {
        this.props.history.push({
            pathname: "/control-panel-details",
            state: {
                uuid: uuid
            }
        });
    }

    render() {
        const {
            events,
        } = this.state;

        const paginationOptions = {
            custom: true,
            totalSize: events.length
        };

        const expandRow = {
            renderer: row => (
                <EventDetailsContainer row={row} />
            ),
        };

        const EventDetailsContainer = ({ row }) => {
            return (
                <Row className="mb-2 mx-1">
                    <Col className="mt-2" sm="12" md="7">
                        <EventDetailsCard details={row.eventDetails} />
                    </Col>
                    <Col className="mt-2" sm="12" md="5">
                        <DeviceDetailsCard controlPanelUuid={row.controlPanelUuid} deviceDetails={row.deviceDetails} />
                    </Col>
                </Row>
            )
        }

        const EventCardRows = ({ rows }) => {
            return rows.map((element, index) => {
                return <EventCardRow key={index} title={element.label} content={element.value} />
            })
        }

        const DeviceDetailsCard = ({ controlPanelUuid, deviceDetails }) => {
            return (
                <Card className="h-100 mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        <EventCardRows rows={deviceDetails.rows} />

                        <CardFooter className="text-center mt-auto">
                            <Button onClick={() => this.redirectToDetails(controlPanelUuid)} outline theme="info">
                                Szczegóły centrali
                            </Button>
                        </CardFooter>
                    </CardBody>
                </Card>
            )
        }

        const EventDetailsCard = ({ details }) => {
            return (
                <Card className="h-100 mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        <EventCardRows rows={details.rows} />
                    </CardBody>
                </Card>
            )
        }

        const EventCardRow = ({ title, content, withoutBottomDivider }) => {
            return (
                <div className="detail-row-container">
                    <div className="small-padding">
                        <div className="details-row-title">{title}</div>
                        <div className="details-row-content">{content}</div>
                    </div>
                    {!withoutBottomDivider && <div className="divider" />}
                </div>
            )
        }

        const BoostrapTableWithPagination = () => {
            return (
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Row>
                            <Col xs="12" className="mb-2">
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField="id"
                                    data={events}
                                    columns={this.props.foundEventsColumns}
                                    expandRow={expandRow}
                                    classes="table mb-0"
                                    headerClasses="react-bootstrap-table-header"
                                    bordered={false}
                                    hover
                                    wrapperClasses="table-responsive"
                                    {...paginationTableProps} />
                            </Col>
                            <Col xs="6" className="d-flex justify-content-start">
                                <div className="ml-2 pl-3"><SizePerPageDropdownStandalone {...paginationProps} /></div>
                            </Col>
                            <Col xs="6" className="d-flex justify-content-end">
                                <div className="mr-2 pr-3"><PaginationListStandalone {...paginationProps} /></div>
                            </Col>
                        </Row>
                    )}
                </PaginationProvider>
            )
        }

        return (
            <div>
                {events.length > 0 ? <BoostrapTableWithPagination /> :
                    <div className="text-center py-3">Nie znaleziono żadnych zdarzeń</div>}
            </div>
        );
    }
}

ControlPanelEventHistoryList.defaultProps = {
    foundEventsColumns: [{
        dataField: 'eventDate',
        text: 'Data',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row) => {
            var eventDate = new Date(cell);
            return dateFormat(eventDate, "dd-mm-yyyy HH:MM");
        }
    }, {
        dataField: 'controlPanelGroupName',
        text: 'Nazwa Grupy',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true
    }, {
        dataField: 'controlPanelName',
        text: 'Nazwa urządzenia',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true
    }]
}

export default ControlPanelEventHistoryList;
