import React from 'react';
import { Row, Col, ListGroup, ListGroupItem, Button } from 'shards-react';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinus, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';

import ConfirmZoneModal from './../modals/ConfirmZoneModal';
import DeleteZoneModal from './../modals/DeleteZoneModal';

class SafeticaConfigurationUserTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isConfirmZonesModalShow: false,
            isDeleteZonesModalShow: false,
            selectedZonesUuid: '',
            zones: []
        };

        this.updateComponent = this.updateComponent.bind(this);
        this.fetchSafeticaZones = this.fetchSafeticaZones.bind(this);
        this.toggleConfirmZoneModal = this.toggleConfirmZoneModal.bind(this);
        this.toggleDeleteZoneModal = this.toggleDeleteZoneModal.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    toggleConfirmZoneModal(uuid = "") {
        this.setState({
            isConfirmZoneModalShow: !this.state.isConfirmZoneModalShow,
            selectedZoneUuid: uuid
        })
    }

    toggleDeleteZoneModal(uuid = "") {
        this.setState({
            isDeleteZoneModalShow: !this.state.isDeleteZoneModalShow,
            selectedZoneUuid: uuid
        })
    }

    fetchSafeticaZones() {
        return API.get(AppConstants.PC_SAFETICA_ZONE_CONFIGURATION_URL);
    }

    updateComponent() {
        this.props.onFetchStart();
        this.fetchSafeticaZones()
            .then(result => {
                this.setState({
                    zones: result.data.zones
                })
                this.props.onFetchComplete();
            })
    }

    render() {
        const { zones, isConfirmZoneModalShow, isDeleteZoneModalShow, selectedZoneUuid } = this.state;

        const paginationOptions = {
            custom: true,
            totalSize: zones.length,
            sizePerPage: 10
        };

        const booleanFormatter = (cell, row) => (
            cell ?
                <FontAwesomeIcon size="lg" icon={faCheckCircle} style={{ color: "#007bff" }} /> :
                <FontAwesomeIcon size="lg" icon={faTimesCircle} style={{ color: "#ff0000" }} />
        )

        const zoneColumns = [{
            dataField: 'name',
            align: "left",
            headerAlign: "left",
            text: "Nazwa",
        }, {
            dataField: 'secure',
            align: "center",
            headerAlign: "center",
            text: "Bezpieczna",
            formatter: booleanFormatter
        }, {
            dataField: 'removableDevices',
            align: "center",
            headerAlign: "center",
            text: "Urządzenia wymienne",
            formatter: booleanFormatter
        }, {
            dataField: 'ipAddress',
            align: "center",
            headerAlign: "center",
            text: "Adresy IP",
            formatter: booleanFormatter
        }, {
            dataField: 'path',
            align: "center",
            headerAlign: "center",
            text: "Ścieżki",
            formatter: booleanFormatter
        }, {
            dataField: 'email',
            align: "center",
            headerAlign: "center",
            text: "Adresy email",
            formatter: booleanFormatter
        }, {
            dataField: 'printer',
            align: "center",
            headerAlign: "center",
            text: "Drukarki",
            formatter: booleanFormatter
        }, {
            dataField: 'networkAddress',
            align: "center",
            headerAlign: "center",
            text: "Adresy Sieciowe",
            formatter: booleanFormatter
        }, {
            dataField: 'actions',
            align: "right",
            headerAlign: "right",
            text: 'Akcje',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => (
                <div className="bootstrap-table-actions-col">
                    <Button size="sm" onClick={() => this.toggleConfirmZoneModal(row.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>

                    <Button size="sm" theme="danger" className="ml-1" onClick={() => this.toggleDeleteZoneModal(row.id)}>
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                </div>
            ),
        }]

        const renderZones = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={zones}
                                columns={zoneColumns}
                                classes="table mb-0"
                                bordered={false}
                                hover
                                rowStyle={{ height: '10px' }}
                                wrapperClasses="table-responsive"
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderZonesFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <h6>Brak danych</h6>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <React.Fragment>
                <Button onClick={this.toggleConfirmZoneModal} outline theme="info" className="mb-3 mr-1 mt-2">
                    Dodaj strefę
                </Button>

                {zones.length ? renderZones() : renderZonesFetchError()}

                <ConfirmZoneModal open={isConfirmZoneModalShow} uuid={selectedZoneUuid}
                    toggle={this.toggleConfirmZoneModal} onSuccess={this.updateComponent} />
                <DeleteZoneModal open={isDeleteZoneModalShow} uuid={selectedZoneUuid}
                    toggle={this.toggleDeleteZoneModal} onSuccess={this.updateComponent} />
            </React.Fragment>
        );
    }

}

export default SafeticaConfigurationUserTable;