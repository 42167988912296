import React from "react";
import { Row, Col, FormInput, Alert } from "shards-react";
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import * as AppConstants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import { flatten } from "../../../utils/Arrays";
import { Store } from '../../../flux'
import { getCustomersForSelect } from '../../../api/ApiService';
import MultiStateIconCheckbox from '../../common/MultiStateIconCheckbox';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { trackPromise } from 'react-promise-tracker';
import { MAC_REGEXP, hasValueUrlOrHtmlTag } from "../../../utils/Patterns";

class ConfirmNetworkRuleForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            form: {
                name: "",
                deviceGroup: "",
                device: "",
                category: "",
                procedure: "",
                manager: '',
                type: '',
                model: '',
                ruleType: '',
                trigger: '',
                conditional: '',
                component: '',
                ports: [],
            },
            formErrors: {
                name: [],
                device: [],
                deviceGroup: [],
                category: [],
                manager: [],
                type: [],
                model: [],
                ruleType: [],
                trigger: [],
                conditional: [],
                component: [],
                ports: []
            },
            formHints: {
                deviceGroup: [],
                device: [],
                category: [],
                procedure: [],
                manager: [],
                model: [],
                ruleType: [],
                trigger: [],
                conditional: [],
                component: []
            },
            showComponentAlert: false,
            isManagersLoading: true,
            isOptionsLoading: false,
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.fetchComponents = this.fetchComponents.bind(this);
        this.onCheckboxStateChange = this.onCheckboxStateChange.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        const { isAdminAtLeast } = this.state;

        if (uuid.length) {
            this.fetchAvailableOptions(uuid, () => {
                this.fetchDeviceRuleForm(uuid);
            });
        } else {
            if (isAdminAtLeast) {
                this.fetchAvailableOptions();
                this.fetchCompanyCustomers();
            } else {
                this.fetchAvailableOptions();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        var prevManager = prevState.form.manager ? prevState.form.manager.value : "";
        var currentManager = this.state.form.manager ? this.state.form.manager.value : "";

        if (prevManager !== currentManager) {
            this.clearFormHints();
            this.fetchAvailableOptions();
        }

        var prevRuleTypeValue = prevState.form.ruleType ? prevState.form.ruleType.value : ""
        var currentRuleTypeValue = this.state.form.ruleType ? this.state.form.ruleType.value : ""
        if (prevRuleTypeValue !== currentRuleTypeValue) {
            const ruleValueType = this.state.form.ruleType && JSON.parse(this.state.form.ruleType.additionalParameters).valueType;
            if (ruleValueType === AppConstants.RULE_TYPES.COMPONENT_SELECT_WTH_OTHER_THAN ||
                ruleValueType === AppConstants.RULE_TYPES.DEVICE_SELECT || ruleValueType === AppConstants.RULE_TYPES.SYSTEM_EVENT_SELECT) {
                this.fetchComponents();
            }
        }
    }

    clearFormHints() {
        this.setState({
            formHints: {
                ...this.state.formHints,
                deviceGroup: [],
                device: [],
                category: [],
                procedure: [],
                type: [],
                model: [],
                ruleType: [],
                trigger: [],
                conditional: [],
            }
        })
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event, inputsToClearAfterChange) => {
        var { form, formErrors } = this.state;

        var clearedElements = {}
        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        var item;
        if (Array.isArray(selected)) {
            item = selected.map(selectedItem => selectedItem.value);
        } else {
            item = selected
        }

        var showComponentAlert;
        if (event.name === "ruleType") {
            showComponentAlert = false;
        }

        this.setState({
            form: {
                ...form,
                ...clearedElements,
                [event.name]: item
            },
            formErrors: {
                ...formErrors,
                [event.name]: []
            },
            showComponentAlert: showComponentAlert == null ? showComponentAlert : this.state.showComponentAlert
        });
    }

    onCheckboxStateChange(value, event, index) {
        const { ports } = this.state.form;
        ports[index] = value;

        this.setState({
            form: {
                ...this.state.form,
                ports: ports,
                trigger: JSON.stringify(ports.map((port, idx) => { return { portIndex: idx, trigger: port.value } }).filter(port => port.trigger !== "NONE"))
            }
        })
    }

    validForm() {
        const { formErrors, isAdminAtLeast, isAdmin } = this.state;
        const { name, device, deviceGroup, category, manager, model, ruleType, trigger, conditional, component, ports } = this.state.form;
        const { uuid } = this.props;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        formErrors["name"] = [];
        formErrors["device"] = [];
        formErrors["deviceGroup"] = [];
        formErrors["category"] = [];
        formErrors["manager"] = [];
        formErrors["model"] = [];
        formErrors["ruleType"] = [];
        formErrors["trigger"] = [];
        formErrors["conditional"] = [];
        formErrors["component"] = [];
        formErrors["ports"] = [];

        var errorCount = 0;

        if (hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (!deviceGroup || deviceGroup.value.length <= 0) {
            formErrors["deviceGroup"].push("Grupa musi zostać wybrana");
            errorCount++;
        }

        if (!category || category.length <= 0) {
            formErrors["category"].push("Model powiadomienia musi zostać wybrany");
            errorCount++;
        }

        if (!isAdmin && !isEditingModeEnabled && isAdminAtLeast && (!manager || manager.value.length <= 0)) {
            formErrors["manager"].push("Użytkownik musi zostać wybrany");
            errorCount++;
        }

        if (!model || model.length <= 0) {
            formErrors["model"].push("Model urządzenia musi zostać wybrany");
            errorCount++;
        }

        const additionalParameters = JSON.parse(ruleType.additionalParameters);
        if (ruleType) {
            if (additionalParameters.valueType === AppConstants.RULE_TYPES.COUNT) {
                if (!trigger || trigger.length <= 0) {
                    formErrors["trigger"].push("Wyzwalanie nie zostało ustawione poprawnie");
                    errorCount++;
                }

                if (!conditional || conditional.value.length <= 0) {
                    formErrors["conditional"].push("Nie wybranu warunku");
                    errorCount++;
                }
            } else if (additionalParameters.valueType === AppConstants.RULE_TYPES.PERCENTAGE) {
                const triggerPercentage = parseInt(trigger);
                if (!trigger || triggerPercentage <= 0 || triggerPercentage > 100) {
                    formErrors["trigger"].push("Wyzwalanie nie zostało ustawione poprawnie");
                    errorCount++;
                }

                if (!conditional || conditional.value.length <= 0) {
                    formErrors["conditional"].push("Nie wybranu warunku");
                    errorCount++;
                }
            } else if (additionalParameters.valueType === AppConstants.RULE_TYPES.DICTIONARY || additionalParameters.valueType === AppConstants.RULE_TYPES.OTHER_THAN) {
                if (!trigger || trigger.value.length <= 0) {
                    formErrors["trigger"].push("Wyzwalanie nie zostało ustawione poprawnie");
                    errorCount++;
                }
            } else if (additionalParameters.valueType === AppConstants.RULE_TYPES.PORTS_CHECK) {
                if (!ports || ports.length <= 0 || !ports.some((element, index, array) => element.value !== "NONE")) {
                    formErrors["ports"].push("Conajmniej jeden port musi zostać wybrany");
                    errorCount++;
                }
            } else if (additionalParameters.valueType === AppConstants.RULE_TYPES.COMPONENT_SELECT_WTH_OTHER_THAN) {
                if (!component || component.value.length <= 0) {
                    formErrors["component"].push("Komponent nie został ustawiony poprawnie");
                    errorCount++;
                }

                if (!trigger || trigger.value.length <= 0) {
                    formErrors["trigger"].push("Wyzwalanie nie zostało ustawione poprawnie");
                    errorCount++;
                }

                if (!device || device.value.length <= 0) {
                    formErrors["device"].push("Urządzenie musi zostać wybrane");
                    errorCount++;
                }
            } else if (additionalParameters.valueType === AppConstants.RULE_TYPES.SYSTEM_EVENT_SELECT) {
                if (!component || component.value.length <= 0) {
                    formErrors["component"].push("Zdarzenie nie został ustawiony poprawnie");
                    errorCount++;
                }

                if (!trigger || trigger.value.length <= 0) {
                    formErrors["trigger"].push("Wyzwalanie nie zostało ustawione poprawnie");
                    errorCount++;
                }

                if (!device || device.value.length <= 0) {
                    formErrors["device"].push("Urządzenie musi zostać wybrane");
                    errorCount++;
                }       
            } else if (additionalParameters.valueType === AppConstants.RULE_TYPES.DEVICE_SELECT) {
                if (component && component.value !== "ALL" && !MAC_REGEXP.test(component.value)) {
                    formErrors["component"].push("Wprowadzono niepoprawny adres MAC");
                    errorCount++;
                }

                if (!trigger || trigger.value.length <= 0) {
                    formErrors["trigger"].push("Wyzwalanie nie zostało ustawione poprawnie");
                    errorCount++;
                }
            } else {
                formErrors["ruleType"].push("Wybrany typ zdarzenia nie jest obsługiwany");
                errorCount++;
            }
        } else {
            formErrors["ruleType"].push("Typ zdarzenia musi zostać wybrany");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildSubmitRuleForm() {
        const { form } = this.state;
        return {
            ...this.buildEditRuleForm(),
            manager: form.manager && form.manager.value
        }
    }

    buildEditRuleForm() {
        const { form } = this.state;
        return {
            name: form.name,
            category: form.category.value,
            deviceGroup: form.deviceGroup.value,
            device: form.device ? form.device.value : null,
            procedure: form.procedure ? form.procedure.value : null,
            model: form.model.value,
            ruleType: form.ruleType.value,
            trigger: form.trigger ?
                form.trigger.value ?
                    form.trigger.value : form.trigger
                : null,
            conditional: form.conditional ? form.conditional.value : null,
            component: form.component ? form.component.value : null
        }
    }

    submitForm = (onSuccess) => {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.NETWORK_DEVICE_RULE_URL + "/" + uuid, {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: this.buildEditRuleForm()
                })
            } else {
                apiPromise = API.put(AppConstants.NETWORK_DEVICE_RULE_URL, {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: this.buildSubmitRuleForm()
                });
            }

            trackPromise(
                apiPromise.then((result) => {
                    if (result.status === 201) {
                        this.props.toggle()
                        onSuccess();
                    }
                }).catch((error) => {
                    var response = error.response;
                    if (response && response.status === 400) {
                        response.data.errors.forEach(error => {
                            formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                        })
                        this.setState({ formErrors: formErrors });
                    }
                })
            )
        }
    }

    fetchCompanyCustomers = () => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            })
        })
    }

    fetchAvailableOptions = (uuid = "", callback) => {
        const { isAdminAtLeast } = this.state;
        const { manager } = this.state.form;

        var axiosConfig;
        if (isAdminAtLeast) {
            if (Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
                if (!((manager && manager.value) || uuid)) return;
            }

            axiosConfig = {
                params: {
                    uuid: uuid,
                    manager: manager ? manager.value : ""
                }
            }
        }

        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.NETWORK_DEVICE_RULE_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                        procedure: flatten(result.data.procedure.map(procedureGroup => procedureGroup.value.map(procedureChildren => {
                            return {
                                label: procedureChildren.name,
                                value: procedureChildren.id
                            }
                        }))),
                    },
                    isOptionsLoading: false,
                }, callback)
            }
        });
    }

    fetchComponents() {
        const { device, ruleType } = this.state.form;
        const service = JSON.parse(ruleType.additionalParameters).componentService;
        if (device && device.value) {
            API.get(AppConstants.NETWORK_DEVICE_RULE_URL + "/components/" + device.value, {
                params: {
                    service: service,
                }
            }).then((result) => {
                if (result.data.length <= 0) {
                    this.setState({
                        showComponentAlert: true,
                        formHints: { ...this.state.formHints, component: [] }
                    });
                } else {
                    this.setState({
                        showComponentAlert: false,
                        formHints: { ...this.state.formHints, component: result.data }
                    });
                }
            })
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    parsePorts(value) {
        const { portOptions } = this.props.options;
        var ports = []

        JSON.parse(value).forEach(port => {
            ports[port.portIndex] = portOptions.find(portOption => portOption.value === port.trigger);
        })

        return ports;
    }

    fetchDeviceRuleForm = (uuid) => {
        API.get(AppConstants.NETWORK_DEVICE_RULE_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                var form = {
                    ...this.state.form,
                    ...result.data,
                    deviceGroup: result.data.deviceGroup ? this.findHintByValue("deviceGroup", result.data.deviceGroup) : "",
                    device: result.data.device ? this.findHintByValue("device", result.data.device) : "",
                    category: result.data.category ? this.findHintByValue("category", result.data.category) : "",
                    procedure: result.data.procedure ? this.findHintByValue("procedure", result.data.procedure) : "",
                    model: result.data.model ? this.findHintByValue("model", result.data.model) : "",
                    ruleType: result.data.ruleType ? this.findHintByValue("ruleType", result.data.ruleType) : "",
                }

                const valueType = JSON.parse(form.ruleType.additionalParameters).valueType;
                if (valueType === AppConstants.RULE_TYPES.COUNT || valueType === AppConstants.RULE_TYPES.PERCENTAGE) {
                    form = {
                        ...form,
                        trigger: result.data.trigger ? result.data.trigger : "",
                        conditional: result.data.conditional ? this.findHintByValue("conditional", result.data.conditional) : "",
                    }
                } else if (valueType === AppConstants.RULE_TYPES.DICTIONARY || valueType === AppConstants.RULE_TYPES.OTHER_THAN) {
                    form = {
                        ...form,
                        trigger: result.data.trigger ? this.findHintByValue("trigger", result.data.trigger) : "",
                    }
                } else if (valueType === AppConstants.RULE_TYPES.PORTS_CHECK) {
                    form = {
                        ...form,
                        ports: result.data.trigger ? this.parsePorts(result.data.trigger) : [],
                        trigger: result.data.trigger,
                    }
                } else if (valueType === AppConstants.RULE_TYPES.COMPONENT_SELECT_WTH_OTHER_THAN) {
                    form = {
                        ...form,
                        trigger: result.data.trigger ? this.findHintByValue("trigger", result.data.trigger) : "",
                        component: result.data.component ? { label: result.data.component.slice(1, -1), value: result.data.component } : ""
                    }
                } else if (valueType === AppConstants.RULE_TYPES.DEVICE_SELECT || valueType === AppConstants.RULE_TYPES.SYSTEM_EVENT_SELECT) {
                    form = {
                        ...form,
                        trigger: result.data.trigger ? this.findHintByValue("trigger", result.data.trigger) : "",
                        component: result.data.component ? result.data.component === "ALL" ?
                            { label: "Wszystkie", value: result.data.component } : { label: result.data.component, value: result.data.component } : ""
                    }
                }

                this.setState({ form: form })
            }
        });
    }

    sortPorts(portA, portB) {
        if (portB.row > portA.row) return -1;
        if (portB.row < portA.row) return 0;

        if (portB.row === portA.row) {
            if (portB.col > portA.col) return -1;
            if (portB.col === portA.col) return 0;
            if (portB.col < portA.col) return 0;
        }

        return 0;
    }

    render() {
        const { isAdminAtLeast, isAdmin, showComponentAlert } = this.state;
        const { name, deviceGroup, device, category, procedure, manager, type, model, ruleType, trigger, conditional, component } = this.state.form;
        const { uuid } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasDeviceError = Boolean(this.state.formErrors.device.length);
        var hasDeviceGroupError = Boolean(this.state.formErrors.deviceGroup.length);
        var hasRuleCategoryError = Boolean(this.state.formErrors.category.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);
        var hasModelError = Boolean(this.state.formErrors.model.length);
        var hasRuleTypeError = Boolean(this.state.formErrors.ruleType.length);
        var hasTriggerError = Boolean(this.state.formErrors.trigger.length);
        var hasConditionalError = Boolean(this.state.formErrors.conditional.length);
        var hasComponentError = Boolean(this.state.formErrors.component.length);
        var havePortsError = Boolean(this.state.formErrors.ports.length);

        const ruleValueType = ruleType && JSON.parse(ruleType.additionalParameters).valueType;
        var deviceShouldBeRequired = ruleType && (ruleValueType === AppConstants.RULE_TYPES.COMPONENT_SELECT_WTH_OTHER_THAN || ruleValueType === AppConstants.RULE_TYPES.SYSTEM_EVENT_SELECT);

        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz",
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "fixed",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`
        }

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        const renderCountTypeForm = () => {
            return (
                <>
                    <label>*Wyzwalaj, gdy</label>
                    <FormInput
                        name="trigger"
                        value={trigger}
                        onChange={this.inputChangeHandler}
                        type="number"
                        invalid={hasTriggerError}
                        className={hasTriggerError ? "mb-0" : "mb-2"} />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}

                    <label>*Warunek</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="conditional"
                        value={conditional}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.conditional}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasConditionalError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasConditionalError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.conditional)}</ul>}
                </>
            )
        }

        const renderPercentageTypeForm = () => {
            return (
                <>
                    <label>*Wyzwalaj, gdy</label>
                    <FormInput
                        name="trigger"
                        value={trigger}
                        onChange={this.inputChangeHandler}
                        type="number"
                        invalid={hasTriggerError}
                        className={hasTriggerError ? "mb-0" : "mb-2"}
                        min="0"
                        max="100"
                        step="1" />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}

                    <label>*Warunek</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="conditional"
                        value={conditional}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.conditional}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasConditionalError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasConditionalError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.conditional)}</ul>}
                </>
            )
        }

        const renderDictionaryTypeForm = () => {
            return (
                <>
                    <label>*Wyzwalaj, gdy</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="trigger"
                        value={trigger}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.trigger.filter(trigger => {
                            if (ruleType) return trigger.parentValue === ruleType.value;
                            return false;
                        })}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasTriggerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}
                </>
            )
        }

        const renderPortsCheckTypeForm = () => {
            const additionalParameters = JSON.parse(model.additionalParameters);
            const portsMap = JSON.parse(additionalParameters.ports);

            const getCountOfRows = (ports) => {
                const rowsOfPorts = ports.map(port => port.row);
                return Math.max(...rowsOfPorts) + 1;
            }

            return (
                <>
                    {portsMap.portGroups.map(portGroup => {
                        portGroup.ports.sort(this.sortPorts);
                        const countOfRows = getCountOfRows(portGroup.ports);

                        return (
                            <div key={portGroup.name} className="w-100 multi-state-checkbox-container">
                                <label>{portGroup.name}</label>
                                {[...Array(countOfRows)].map((none, idx) =>
                                    <Row key={idx} className="m-0">
                                        {portGroup.ports.filter(port => port.row === idx).map((port, idx) => {
                                            return (
                                                <div key={port.index} className="text-center">
                                                    <MultiStateIconCheckbox
                                                        value={this.state.form.ports[port.index]}
                                                        onChange={(value, event) => this.onCheckboxStateChange(value, event, port.index)}
                                                        values={this.props.options.portOptions}
                                                        invalid={havePortsError} />
                                                    <label className="multi-state-checkbox-label">{port.alias}</label>
                                                </div>
                                            )
                                        })}
                                    </Row>
                                )}
                            </div>
                        )
                    })}
                    {havePortsError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ports)}</ul>}
                </>
            )
        }

        const renderOtherThanTypeForm = () => {
            return (
                <>
                    <label>*Wyzwalaj, gdy inny jak</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="trigger"
                        value={trigger}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.trigger.filter(trigger => {
                            if (ruleType) return trigger.parentValue === ruleType.value;
                            return false;
                        })}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasTriggerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}
                </>
            )
        }

        const renderComponentSelectWithOtherThanTypeForm = () => {
            return (
                <>
                    {showComponentAlert && <Alert theme="warning" style={{ marginBottom: "0rem" }}>
                        Nie znaleziono żadnych komponentów. Prawdopodobnie nie zostały jeszcze pobrane. Spróbuj ponownie później.
                    </Alert>}
                    <label>*Komponent</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="component"
                        value={component}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.component}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "component")}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasComponentError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasComponentError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.component)}</ul>}

                    <label>*Wyzwalaj, gdy inny jak</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="trigger"
                        value={trigger}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.trigger.filter(trigger => {
                            if (ruleType) return trigger.parentValue === ruleType.value;
                            return false;
                        })}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasTriggerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}
                </>
            )
        }

        const renderDeviceSelectTypeForm = () => {
            return (
                <>
                    {showComponentAlert && <Alert theme="warning" style={{ marginBottom: "0rem" }}>
                        Nie znaleziono żadnych urządzeń. Prawdopodobnie nie zostały jeszcze pobrane. Spróbuj ponownie później.
                    </Alert>}
                    <label>*Adres MAC urządzenia</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="component"
                        value={component}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={[
                            { label: "Wszystkie", value: "ALL" },
                            ...this.state.formHints.component.map(option => {
                                return {
                                    label: option.label.trim().replace(/\s/g, ':'),
                                    value: option.value.replaceAll('"', '').trim().replace(/\s/g, ':')
                                }
                            })
                        ]}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "component")}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasComponentError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable
                        placeholder="Wybierz lub dodaj własny"
                        allowCreateWhileLoading={true}
                        formatCreateLabel={(inputText) => inputText} />
                    {hasComponentError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.component)}</ul>}

                    <label>*Wyzwalaj, gdy</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="trigger"
                        value={trigger}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.trigger.filter(trigger => {
                            if (ruleType) return trigger.parentValue === ruleType.value;
                            return false;
                        })}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasTriggerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}
                </>
            )
        }

        const renderSystemEventTypeForm = () => {
            return (
                <>
                    {showComponentAlert && <Alert theme="warning" style={{ marginBottom: "0rem" }}>
                        Nie znaleziono żadnych zdarzeń. Prawdopodobnie nie zostały jeszcze pobrane. Spróbuj ponownie później.
                    </Alert>}
                    <label>*Zdarzenie systemowe</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="component"
                        value={component}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={[
                            { label: "Wszystkie", value: "ALL" },
                            ...this.state.formHints.component
                        ]}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "component")}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasComponentError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable
                        placeholder="Wybierz lub dodaj własny"
                        allowCreateWhileLoading={true}
                        formatCreateLabel={(inputText) => inputText} />
                    {hasComponentError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.component)}</ul>}

                    <label>*Wyzwalaj, gdy</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="trigger"
                        value={trigger}
                        onChange={(item, event) => { this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.trigger.filter(trigger => {
                            if (ruleType) return trigger.parentValue === ruleType.value;
                            return false;
                        })}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !ruleType}
                        className={hasTriggerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasTriggerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.trigger)}</ul>}
                </>
            )
        }

        return (
            <Row>
                <Col sm="12" md="6">
                    <label>*Nazwa reguły</label>
                    <FormInput
                        name="name"
                        value={name}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    {!isEditingModeEnabled && isAdminAtLeast &&
                        <>
                            <label>*Menedżer</label>
                            <AsyncSelect
                                {...defaultSelectProps}
                                name="manager"
                                value={manager}
                                onChange={(item, event) => { this.selectChangeHandler(item, event, ["category", "type", "model", "deviceGroup", "device", "ruleType", "trigger", "conditional", "procedure"]); }}
                                defaultOptions={this.state.formHints.manager}
                                className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")}
                                isLoading={this.state.isManagersLoading}
                                placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"} />
                            {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                        </>
                    }

                    <label>*Model powiadomienia</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="category"
                        value={category}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.category}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasRuleCategoryError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasRuleCategoryError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.category)}</ul>}

                    <label>Typ</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="type"
                        value={type}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["model", "deviceGroup", "device", "ruleType", "trigger", "conditional"]); }}
                        defaultOptions={this.state.formHints.type}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className="react-select-container mb-2"
                        isClearable />

                    <label>*Model</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="model"
                        value={model}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["deviceGroup", "device", "ruleType", "trigger", "conditional"]); }}
                        defaultOptions={JSON.parse(JSON.stringify(this.state.formHints.model)).filter(modelGroup => {
                            if (type) {
                                modelGroup.options = modelGroup.options.filter(model => {
                                    return model.parentValue === type.value;
                                });
                            }

                            if (modelGroup.options.length <= 0) {
                                return false;
                            }

                            return true;
                        })}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasModelError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        formatGroupLabel={formatGroupLabel}
                        isClearable />
                    {hasModelError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.model)}</ul>}

                    <label>*Grupa</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="deviceGroup"
                        value={deviceGroup}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["device"]); }}
                        defaultOptions={this.state.formHints.deviceGroup.filter(group => {
                            const supportedModels = JSON.parse(group.parentValue);
                            return supportedModels.includes(model.value);
                        })}
                        isDisabled={(isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected) || !model}
                        className={hasDeviceGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasDeviceGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.deviceGroup)}</ul>}

                    <label>{deviceShouldBeRequired && <>*</>}Urządzenie</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="device"
                        value={device}
                        onChange={(item, event) => {this.selectChangeHandler(item, event, ["ruleType", "trigger", "conditional"])}}
                        defaultOptions={
                            this.state.formHints.device
                                .filter(device => device.parentValue === (this.state.form.deviceGroup ? this.state.form.deviceGroup.value : false))
                                .filter(device => {
                                    const deviceParams = JSON.parse(device.additionalParameters);
                                    var shouldBeShow = true;

                                    if (type) shouldBeShow = deviceParams.type === type.value;
                                    if (model) shouldBeShow = deviceParams.model === model.value;

                                    return shouldBeShow;
                                })

                        }
                        isDisabled={isEditingModeEnabled || !deviceGroup}
                        isClearable
                        className={hasDeviceError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasDeviceError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.device)}</ul>}

                </Col>

                <Col sm="12" md="6">
                    <label>Procedura</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="procedure"
                        value={procedure || ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.procedure}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        formatGroupLabel={formatGroupLabel} />

                    <label>*Typ Zdarzenia</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="ruleType"
                        value={ruleType}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ["trigger", "conditional", "component"]); }}
                        defaultOptions={this.state.formHints.ruleType.filter(ruleType => {
                            if (model) return ruleType.parentValue === model.value;
                            return false;
                        }).filter(ruleType => {
                            if (JSON.parse(ruleType.additionalParameters).valueType === AppConstants.RULE_TYPES.COMPONENT_SELECT_WTH_OTHER_THAN || 
                            JSON.parse(ruleType.additionalParameters).valueType === AppConstants.RULE_TYPES.SYSTEM_EVENT_SELECT)
                                return Boolean(device);
                            return true;
                        })}
                        isDisabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) || !model}
                        className={hasRuleTypeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isClearable />
                    {hasRuleTypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ruleType)}</ul>}

                    {ruleType && ruleValueType === AppConstants.RULE_TYPES.COUNT && renderCountTypeForm()}
                    {ruleType && ruleValueType === AppConstants.RULE_TYPES.PERCENTAGE && renderPercentageTypeForm()}
                    {ruleType && ruleValueType === AppConstants.RULE_TYPES.DICTIONARY && renderDictionaryTypeForm()}
                    {ruleType && ruleValueType === AppConstants.RULE_TYPES.PORTS_CHECK && renderPortsCheckTypeForm()}
                    {ruleType && ruleValueType === AppConstants.RULE_TYPES.OTHER_THAN && renderOtherThanTypeForm()}
                    {ruleType && ruleValueType === AppConstants.RULE_TYPES.COMPONENT_SELECT_WTH_OTHER_THAN && renderComponentSelectWithOtherThanTypeForm()}
                    {ruleType && ruleValueType === AppConstants.RULE_TYPES.DEVICE_SELECT && renderDeviceSelectTypeForm()}
                    {ruleType && ruleValueType === AppConstants.RULE_TYPES.SYSTEM_EVENT_SELECT && renderSystemEventTypeForm()}
                </Col>
            </Row >
        );
    }

}

export default ConfirmNetworkRuleForm;

ConfirmNetworkRuleForm.defaultProps = {
    options: {
        portOptions: [
            { value: "UP", icon: faChevronUp, iconColor: "green" },
            { value: "DOWN", icon: faChevronDown, iconColor: "red" }
        ]
    }
};
