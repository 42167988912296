import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallCard from "../components/common/SmallCard";
import ControlPanelMenu from "../components/controlPanel/ControlPanelMenu";
import ControlPanelRules from "../components/controlPanel/ControlPanelRules";
import ControlPanelAbsences from "../components/controlPanel/ControlPanelAbsences";
import { Store } from "../flux";

import API from "../api/AxiosConfiguration";
import * as AppConstants from '../constants';

class ControlPanel extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props)

    this.state = {
      isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
      isMobileView: false,
      devicesCondition: {
        activeControlPanels: 0,
        allControlPanels: 0,
        pendingControlPanels: 0
      },
    }

    this.onWindowResizeFluxListener = this.onWindowResizeFluxListener.bind(this);

    this.updateControlPanelData = this.updateControlPanelData.bind(this);
    this.fetchCountOfDevices = this.fetchCountOfDevices.bind(this);

    this.redirectToEventHistory = this.redirectToEventHistory.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateControlPanelData();
  }

  componentWillMount() {
    Store.addChangeListener(this.onWindowResizeFluxListener);
  }

  componentWillUnmount() {
    this._isMounted = false;
    Store.removeChangeListener(this.onWindowResizeFluxListener);
  }

  redirectToEventHistory() {
    const currentDate = new Date();

    var eventStartRangeDate = new Date().setHours(currentDate.getHours() - 24);
    var eventEndRangeDate = currentDate.getTime();
    this.props.history.push({
      pathname: "/control-panel-event-history",
      state: {
        eventStartRangeDate: eventStartRangeDate,
        eventEndRangeDate: eventEndRangeDate,
      }
    });
  }

  onWindowResizeFluxListener() {
    if (this._isMounted)
      this.setState({
        ...this.state,
        isMobileView: Store.isMobileView()
      })
  }

  updateControlPanelData() {
    this.fetchCountOfDevices();
  }

  fetchCountOfDevices() {
    API.get(AppConstants.CONTROL_PANEL_URL + "/stats")
      .then(result => {
        if (result.status === 200)
          if (this._isMounted)
            this.setState({
              devicesCondition: result.data
            })
      })
  }

  render() {
    const { isMobileView, devicesCondition, isAdminAtLeast } = this.state;
    const { controlPanelType, siteTitle } = this.props;

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={siteTitle} subtitle="Dashboard" className="text-sm-left mb-3" />
        </Row>

        <Row>
          <Col md="6" lg="3" className="mt-2">
            <SmallCard label="Aktywne centrale">{devicesCondition.activeControlPanels}</SmallCard>
          </Col>
          <Col md="6" lg="3" className="mt-2">
            <SmallCard label="Wszystkie centrale">{devicesCondition.allControlPanels}</SmallCard>
          </Col>
          <Col md="6" lg="3" className="mt-2">
            <SmallCard label="Oczekujące centrale">{devicesCondition.pendingControlPanels}</SmallCard>
          </Col>
          <Col sm={{ size: 12, offset: 0 }}
            md={{ size: 6, offset: 3 }}
            lg={{ size: 3, offset: 0 }}
            className="mt-2">
            <SmallCard label="Naruszenia reguł z ostatnich 24h">
              <Button onClick={this.redirectToEventHistory} outline theme="info">
                Wygeneruj raport
              </Button>
            </SmallCard>
          </Col>
        </Row>

        <ControlPanelMenu
          controlPanelType={controlPanelType}
          history={this.props.history}
          isMobileView={isMobileView}
          onControlPanelUpdate={this.updateControlPanelData} />

        <ControlPanelRules
          isMobileView={isMobileView}
          history={this.props.history} />

        {isAdminAtLeast && <ControlPanelAbsences />}
      </Container>
    )
  }

}

export default ControlPanel;
