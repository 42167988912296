import React from "react";
import { ConfirmModal } from "../../common/Modal";

import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import * as socToast from '../../../utils/SocToast';

class DeleteControlPanelApplicationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.deleteControlPanel = this.deleteControlPanel.bind(this);
    }

    deleteControlPanel() {
        const {
            onSuccess,
            uuid,
            toggle
        } = this.props;

        API.delete(Constants.CONTROL_PANEL_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    onSuccess();
                    socToast.success("Centrala została usunięta", "Pomyślnie usunięto centrale.");
                    toggle();
                }
            }).catch(error => {
                var response = error.response;
                if (response) {
                    if (response.status === 409) {
                        socToast.error("Centrala nie została usunięta", "Przed usunięciem centrali należy upewnić się, że urządzenia i reguły, które się w niej znajdują zostały usunięte")
                    }
                }
            })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz wysłać prośbę o usunięcie centrali?"
                    open={open}
                    toggle={toggle}
                    submitAction={this.deleteControlPanel}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie wszystkich danych o centrali.
                        Twoja prośba zostanie rozpatrzona w ciągu kilku dni roboczych.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          
            </ConfirmModal>
        )
    }
}

export default DeleteControlPanelApplicationModal;