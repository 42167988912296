import React from 'react';
import { Row, Col, Card, CardHeader, CardBody, ButtonGroup, Button, Collapse } from "shards-react";
import SafeticaConfigurationCategories from './bookmarks/SafeticaConfigurationCategories';
import SafeticaConfigurationAppCategories from './bookmarks/SafeticaConfigurationAppCategories';
import SafeticaConfigurationUsers from './bookmarks/SafeticaConfigurationUsers';
import SafeticaConfigurationPolities from './bookmarks/SafeticaConfigurationPolities';
import withLoader from "../../common/hoc/withLoader";
import DynamicMenu from "../../menu/DynamicMenu"

class SafeticaConfiguration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggledBookmark: undefined,
            isCategoriesBookmarkOpened: false,
            isAppCategoriesBookmarkOpened: false,
            isUsersBookmarkOpened: false,
            isPolitiesBookmarkOpened: false
        };

        this.toggleBookmark = this.toggleBookmark.bind(this);
    }

    toggleBookmark(event, key, bookmarkComponent, props = {}) {
        const { toggledBookmark } = this.state;

        let bookmark;

        // eslint-disable-next-line default-case
        switch(key) {
            case 'categories': {
                bookmark = "isCategoriesBookmarkOpened";
                break;
            }
            case 'appCategories': {
                bookmark = "isAppCategoriesBookmarkOpened";
                break;
            }
            case 'users': {
                bookmark = "isUsersBookmarkOpened";
                break;
            }
            case 'polities': {
                bookmark = "isPolitiesBookmarkOpened";
                break;
            }
        }

        Array.from(document.getElementsByClassName("bookmark-button"))
            .forEach((bookmarkBtn) => bookmarkBtn.classList.remove("active"));

        if (toggledBookmark) {
            this.setState({ toggledBookmark: undefined, 
                isCategoriesBookmarkOpened: false,
                isAppCategoriesBookmarkOpened: false,
                isUsersBookmarkOpened: false,
                isPolitiesBookmarkOpened: false
            });

            if(toggledBookmark.key === key) return;
        }

        event.currentTarget.classList.add("active")
        this.setState({
            toggledBookmark: {
                key: key,
                component: bookmarkComponent,
                props: props,
            },
            [bookmark]: true
        })
    }

    render() {
        const { isMobileView } = this.props;
        const { isBookmarkOpened, toggledBookmark, isCategoriesBookmarkOpened, isAppCategoriesBookmarkOpened, isUsersBookmarkOpened, isPolitiesBookmarkOpened } = this.state;

        const renderFullWidthButtonGroup = () => (
            <>
                        <div>
            <DynamicMenu
               title={'Kategorie'}
                noTitleEdit={true}
                downChevron={!isCategoriesBookmarkOpened}
                onClick={(event) => this.toggleBookmark(event, 'categories', SafeticaConfigurationCategories)}>
            </DynamicMenu>
            <Collapse open={isCategoriesBookmarkOpened}>
                                {toggledBookmark && toggledBookmark.key === 'categories' && <toggledBookmark.component {...toggledBookmark.props} setLoading={this.props.setLoading} />}
            </Collapse>
            </div>
            <div>
            <DynamicMenu
               title={'Kategorie Aplikacji'}
                noTitleEdit={true}
                downChevron={!isAppCategoriesBookmarkOpened}
                onClick={(event) => this.toggleBookmark(event, 'appCategories', SafeticaConfigurationAppCategories)}>
            </DynamicMenu>
            <Collapse open={isAppCategoriesBookmarkOpened}>
                                {toggledBookmark && toggledBookmark.key === 'appCategories' && <toggledBookmark.component {...toggledBookmark.props} setLoading={this.props.setLoading} />}
            </Collapse>
            </div>
            <div>
            <DynamicMenu
               title={'Grupy użytkowników'}
                noTitleEdit={true}
                downChevron={!isUsersBookmarkOpened}
                onClick={(event) => this.toggleBookmark(event, 'users', SafeticaConfigurationUsers)}>
            </DynamicMenu>
            <Collapse open={isUsersBookmarkOpened}>
                                {toggledBookmark && toggledBookmark.key === 'users' && <toggledBookmark.component {...toggledBookmark.props} setLoading={this.props.setLoading} />}
            </Collapse>
            </div>
            <div>
            <DynamicMenu
               title={'Polityki'}
                noTitleEdit={true}
                downChevron={!isPolitiesBookmarkOpened}
                onClick={(event) => this.toggleBookmark(event, 'polities', SafeticaConfigurationPolities)}>
            </DynamicMenu>
            <Collapse open={isPolitiesBookmarkOpened}>
                                {toggledBookmark && toggledBookmark.key === 'polities' && <toggledBookmark.component {...toggledBookmark.props} setLoading={this.props.setLoading} />}
            </Collapse>
            </div>
            </>
            // <ButtonGroup className="w-100">
            //     <Button onClick={(event) => this.toggleBookmark(event, 'categories', SafeticaConfigurationCategories)} className="bookmark-button">Kategorie</Button>
            //     <Button onClick={(event) => this.toggleBookmark(event, 'appCategiories', SafeticaConfigurationAppCategories)} className="bookmark-button">Kategorie Aplikacji</Button>
            //     <Button onClick={(event) => this.toggleBookmark(event, 'users', SafeticaConfigurationUsers)} className="bookmark-button">Grupy Użytkowników</Button>
            //     <Button onClick={(event) => this.toggleBookmark(event, 'polities', SafeticaConfigurationPolities)} className="bookmark-button">Polityki</Button>
            // </ButtonGroup >
        )

        const renderMobileButtonGroup = () => (
            <React.Fragment>
                <ButtonGroup className="w-100">
                    <Button onClick={(event) => this.toggleBookmark(event, 'categories', SafeticaConfigurationCategories)} className="bookmark-button">Kategorie</Button>
                    <Button onClick={(event) => this.toggleBookmark(event, 'appCategiories', SafeticaConfigurationAppCategories)} className="bookmark-button">Kategorie Aplikacji</Button>
                </ButtonGroup>
                <ButtonGroup className="w-100 mt-1">
                    <Button onClick={(event) => this.toggleBookmark(event, 'users', SafeticaConfigurationUsers)} className="bookmark-button">Grupy Użytkowników</Button>
                    <Button onClick={(event) => this.toggleBookmark(event, 'polities', SafeticaConfigurationPolities)} className="bookmark-button">Polityki</Button>
                </ButtonGroup>
            </React.Fragment>
        )

        return (
            <Row className="mt-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Konfiguracja</h6>
                        </CardHeader>
                        <CardBody>
                            {isMobileView ? renderMobileButtonGroup() : renderFullWidthButtonGroup()}

                            <Collapse open={isBookmarkOpened}>
                                {toggledBookmark && <toggledBookmark.component {...toggledBookmark.props} setLoading={this.props.setLoading} />}
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

}

export default withLoader(SafeticaConfiguration);