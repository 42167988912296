/* eslint-disable no-unused-vars */
import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormTextarea
} from "shards-react";
import { SECURE_STRING } from "../../../utils/Patterns";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import * as SocToast from '../../../utils/SocToast';
import AsyncCreatableSelect from 'react-select/async-creatable';

const CATEGORY_MAX_LENGTH = 50;
const TITLE_DESC_MIN_LENGTH = 3;
const TITLE_MAX_LENGTH = 1000;
const DESCRIPTION_MAX_LENGTH = 10000;

function AddTaskForm (props){
    const [description, setDescription] = useState('');
    const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);
    const [title, setTitle] = useState('');
    const [isTitleEmpty, setIsTitleEmpty] = useState(false);
    const [isCategoryEmpty, setIsCategoryEmpty] = useState(false);
    const [categories, setCategories] = useState([{key:1001, categoryName:"Niepoprawne działanie aplikacji"},
                                                    {key:1002, categoryName:"Reklamacja"},
                                                    {key:1003, categoryName:"Pomoc techniczna"},
                                                    {key:1004, categoryName:"Pozostałe"},
                                                    {key:1005, categoryName:"Usługa inżyniera, wykonanie analizy systemu"}]);
    const [usedCategory, setUsedCategory] = useState('');
    const [isCategoryNameUnSecureFlag, setIsCategoryNameUnSecureFlag] = useState(false)
    const [categoryTooLong, setCategoryTooLong] = useState(false)
    const [titleTooLong, setTitleTooLong] = useState(false);
    const [titleTooShort, setTitleTooShort] = useState(false);
    const [descriptionTooLong, setDescriptionTooLong] = useState(false);
    const [descriptionTooShort, setDescriptionTooShort] = useState(false);
    const [isTitleUnSecureFlag, setIsTitleUnSecureFlag] = useState(false)
    const [isDescriptionUnSecureFlag, setIsDescriptionUnSecureFlag] = useState(false)
    const [attachment, setAttachment] = useState(null);

    const error = {
      color: "red",
      fontSize: 12,
      fontFamily: "Arial"
    };

    const descriptionHandler = (desc) =>{
        setDescriptionTooLong(desc.length > DESCRIPTION_MAX_LENGTH)
        setDescriptionTooShort(desc.length < TITLE_DESC_MIN_LENGTH)
        setIsDescriptionUnSecureFlag(!SECURE_STRING.test(desc))
        setDescription(desc);
    }

    const titleHandler = (title) => {
        setTitleTooLong(title.length > TITLE_MAX_LENGTH)
        setTitleTooShort(title.length < TITLE_DESC_MIN_LENGTH)
        setIsTitleUnSecureFlag(!SECURE_STRING.test(title))
        setTitle(title)
    }

    const categoryHandler = (categoryName) => {
        setIsCategoryNameUnSecureFlag(!SECURE_STRING.test(categoryName));
        setCategoryTooLong(categoryName.length>CATEGORY_MAX_LENGTH)
        setUsedCategory(categoryName);
    }

    const filterOptions = (inputValue) => {
            return categories.filter(template =>
                template.categoryName.toLowerCase().includes(inputValue.toLowerCase())
            ).map((category) => {
                return {
                    value: category.categoryName,
                    label: category.categoryName
                }
            });

        }

    const filterOptionsPromise = (inputValue) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue));
                }, 500);
            });

    const validForm = () => {
      var errorCount = 0;

      if(usedCategory.length > CATEGORY_MAX_LENGTH || usedCategory.length === 0) {
        errorCount++;
      }

      if(title.length > TITLE_MAX_LENGTH || title.length === 0 || title.length < TITLE_DESC_MIN_LENGTH) {
        errorCount++;
      }

      if(description.length > DESCRIPTION_MAX_LENGTH || description.length === 0 || description.length < TITLE_DESC_MIN_LENGTH) {
        errorCount++;
      }

      if(!SECURE_STRING.test(title)) {
        errorCount++;
      }

      if(!SECURE_STRING.test(description)) {
        errorCount++;
      }

      if(!SECURE_STRING.test(usedCategory)) {
        errorCount++;
      }

      return errorCount === 0;
    }



    const sendHandler = (form) => {
        if (validForm()) {

          const formData = new FormData(form);

          API.post(Constants.REPORTS_URL, formData)
          .then((response) => {
              if (response.status === 200) {
                   SocToast.success("Zgłoszenie zostało zarejestrowane.", "Odpowiedź zostanie przesłana na adres e-mail przypisany do konta, z którego zgłoszenie zostało wysłane.");
                   setDescription('');
                   setTitle('');
                   setAttachment(null);
                   form.querySelector('input[type="file"]').value = null;
                   props.refresh();
              }
          });
        } else {
          SocToast.error("Weryfikacja danych nie powiodła się.");
        }
    }



    return(
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{props.title}</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="fePassword">*Kategoria</label>
                      <AsyncCreatableSelect
                      required
                        cacheOptions
                        defaultOptions
                        name="category"
                        placeholder="Wybierz lub utwórz nowy"
                        onChange={(item) => {categoryHandler(item.label)}}
                        loadOptions={filterOptionsPromise} />
                      {isCategoryNameUnSecureFlag?<div style={error}>Nazwa kategorii może zawierać tylko małe i duże litery oraz cyfry. Dopuszczalne znaki specjalne to ,.?!;</div>:''}
                      {categoryTooLong?<div style={error}>Nazwa kategorii jest zbyt długa.</div>:''}
                      {isCategoryEmpty?<div style={error}>Pole Kategoria nie może być puste.</div>:""}
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="fePassword">*Tytuł</label>
                      <FormInput
                      required
                        type="text"
                        id="description"
                        name="title"
                        style={null}
                        placeholder="Wpisz tytuł"
                        value={title}
                        maxLength={TITLE_MAX_LENGTH}
                        onChange={(item) => {titleHandler(item.target.value)}}
                      />
                      {isTitleUnSecureFlag?<div style={error}>Tytuł może zawierać tylko małe i duże litery oraz cyfry. Dopuszczalne znaki specjalne to ,.?!;</div>:''}
                      {isTitleEmpty?<div style={error}>Pole Tytuł nie może być puste.</div>:""}
                      {titleTooLong?<div style={error}>Tytuł jest zbyt długi.</div>:""}
                      { titleTooShort ? <div style={error}>Tytuł jest zbyt krótki.</div> : '' }
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="fePassword">*Opis</label>
                     <FormTextarea
                        required
                        name="description"
                        size='300px'
                        maxLength={DESCRIPTION_MAX_LENGTH}
                        style={{ height: '10em' }}
                        placeholder="Wpisz opis"
                        onChange={(item) => {descriptionHandler(item.target.value)}}
                        value={description} />
                      {isDescriptionUnSecureFlag?<div style={error}>Nazwa kategorii może zawierać tylko małe i duże litery oraz cyfry. Dopuszczalne znaki specjalne to ,.?!;</div>:''}
                      {isDescriptionEmpty?<div style={error}>Pole Opis nie może być puste.</div>:''}
                      {descriptionTooLong?<div style={error}>Opis jest zbyt długi.</div>:''}
                      { descriptionTooShort ? <div style={error}>Opis jest zbyt krótki.</div> : '' }
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="10" className="form-group">
                      <FormInput
                        type="file"
                        accept=".pdf,.jpg,.png,.gif,.avi,.7z,.zip,.rar"
                        onChange={(item) => setAttachment(item.target.value)}
                        name="attachment"
                         />
                    </Col>
                  </Row>
                  <Button onClick={(e) => sendHandler(e.target.parentNode)} theme="accent">Wyślij zgłoszenie</Button>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
}


AddTaskForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

AddTaskForm.defaultProps = {
  title: "Wyślij zgłoszenie serwisowe"
};

export default AddTaskForm;
