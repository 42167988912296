import React from "react";
import { ConfirmModal } from "../../../common/Modal";

import ConfirmDeviceForm from "../forms/ConfirmDeviceForm"
import * as Constants from '../../../../constants';

class ConfirmDeviceModal extends React.Component {

    generateText = () => {
        // eslint-disable-next-line default-case
        switch (this.props.type) {
            case Constants.ACTIONS.TO_ADD:
                return <div>Spowoduje to dodanie urządzenia oraz rozpatrywanie aktywnych reguł.<br></br>Jeśli jesteś zdecydowany, kliknij "Potwierdź".</div>
            case Constants.ACTIONS.TO_EDIT:
                return <div>Spowoduje to edycję urządzenia oraz wznowienie rozpatrywania aktywnych reguł.<br></br>Jeśli jesteś zdecydowany, kliknij "Potwierdź".</div>
            case Constants.ACTIONS.TO_DELETE:
                return <div>Spowoduje to usunięcie urządzenia oraz zaprzestanie rozpatrywania aktywnych reguł.<br></br>Jeśli jesteś zdecydowany, kliknij "Potwierdź".</div>
        }
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            type,
        } = this.props;

        return (
            <ConfirmModal
                title="Jesteś pewien, że chcesz dodać to urządzenie?"
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.confirmDeviceForm.submitForm(uuid, onSuccess); }}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                {this.generateText()}

                <ConfirmDeviceForm type={type} toggle={toggle} ref="confirmDeviceForm" />
            </ConfirmModal>
        )
    }
}

export default ConfirmDeviceModal;