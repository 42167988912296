import React from "react";
import { Card, CardBody, CardHeader } from "shards-react";
import ScheduledReportList from "./lists/ScheduledReportList";

import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';

class ScheduledReports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scheduledReports: [],
        };

        this.updateComponent = this.updateComponent.bind(this);
        this.fetchScheduledReports = this.fetchScheduledReports.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    fetchScheduledReports() {
        API.post(Constants.SCHEDULED_EVENTS_URL)
            .then((result) => {
                if (result.status === 200) {
                    this.setState({
                        scheduledReports: result.data.map(scheduledReport => { 
                            scheduledReport.filters = JSON.parse(scheduledReport.filters);
                            return scheduledReport;
                        }),
                    });
                }
            });
    }

    updateComponent() {
        this.fetchScheduledReports();
    }

    render() {
        const { scheduledReports } = this.state;

        return (
            <Card>
                <CardHeader>
                    Zdefiniowane automatyczne raporty
                </CardHeader>
                <CardBody className="p-0 pt-1 pb-3">
                    <ScheduledReportList scheduledReports={scheduledReports} onUpdate={this.updateComponent} />
                </CardBody>
            </Card>
        );

    }
}

export default ScheduledReports;
