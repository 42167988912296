import React from "react";
import { Tooltip, Row, Col, FormInput, FormCheckbox } from "shards-react";
import AsyncSelect from 'react-select/async';

import * as AppConstants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import { flatten } from "../../../utils/Arrays";
import * as socToast from '../../../utils/SocToast';
import i18n from './../../../i18n';
import { Store } from '../../../flux'
import { getCustomersForSelect } from '../../../api/ApiService';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { hasValueUrlOrHtmlTag } from "../../../utils/Patterns";

const renderError = (errors) => {
    return errors.map((error, index) =>
        <li key={index}>{error}</li>
    )
}

const defaultSelectProps = {
    placeholder: "Wybierz",
    className: 'react-select-container mb-2',
    classNamePrefix: "react-select",
    menuPosition: "fixed",
    noOptionsMessage: () => "Brak dostępnych opcji",
}

const Input = props => {
    if (props.isHidden) {
        return <components.Input {...props} />;
    }
    return (
        <components.Input {...props} type="number" />
    );
};

const DropdownIndicator = props => {
    return null
};

const createOption = (label) => ({
    label,
    value: label
});

class ConfirmDeviceRuleForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            form: {
                name: "",
                deviceGroup: "",
                device: "",
                notificationModel: "",
                procedure: "",
                manager: '',

                ruleType: '',
                ruleMode: '',
                category: '',
                appCategory: '',
                shadowing: false,
                policy: '',
                userGroup: '',
                safeticaIds: [],
            },
            formErrors: {
                name: [],
                deviceGroup: [],
                notificationModel: [],
                manager: [],
                ruleType: [],
                ruleMode: [],
                category: [],
                appCategory: [],
                policy: [],
                userGroup: [],
                safeticaIds: []
            },
            formHints: {
                deviceGroup: [],
                device: [],
                notificationModel: [],
                procedureGroup: [],
                procedure: [],
                manager: [],
                ruleType: [],
                ruleMode: [],
                category: [],
                appCategory: [],
                policy: [],
                userGroup: [],
            },
            tooltip: {
                categoryTooltip: false,
            },
            safeticaIdsInputValue: '',
            isManagersLoading: true,
            isOptionsLoading: false,
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);

        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.fetchDeviceRuleForm = this.fetchDeviceRuleForm.bind(this);
        this.findHintByValue = this.findHintByValue.bind(this);

        this.clearFormHints = this.clearFormHints.bind(this);
        this.showTooltip = this.showTooltip.bind(this);

        this.CategoriesForm = this.CategoriesForm.bind(this);
        this.AppCategoriesForm = this.AppCategoriesForm.bind(this);
        this.ShadowingForm = this.ShadowingForm.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        const { isAdminAtLeast } = this.state;

        if (uuid.length) {
            this.fetchAvailableOptions(uuid, () => {
                this.fetchDeviceRuleForm(uuid);
            });
        } else {
            if (isAdminAtLeast) {
                this.fetchAvailableOptions();
                this.fetchCompanyCustomers();
            } else {
                this.fetchAvailableOptions();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        var prevManager = prevState.form.manager ? prevState.form.manager.value : "";
        var currentManager = this.state.form.manager ? this.state.form.manager.value : "";

        if (prevManager !== currentManager) {
            this.clearFormHints();
            this.fetchAvailableOptions();
        }
    }

    clearFormHints() {
        this.setState({
            formHints: {
                ...this.state.formHints,
                deviceGroup: [],
                device: [],
                category: [],
                procedureGroup: [],
                procedure: [],
            }
        })
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event) => {
        var { form, formErrors } = this.state;

        formErrors[event.name] = [];
        if (event.name === "targetGroup") form["targetDevice"] = "";

        this.setState({
            form: {
                ...form,
                [event.name]: selected
            }
        });
    }

    checkboxChangeHandler = (name, event) => {
        var { form, formErrors } = this.state;
        form[name] = !form[name];
        formErrors[name] = [];
        this.setState({ form: form });
    }

    keywordsChangeHandler = (safeticaIds) => {
        var { form } = this.state;
        form["safeticaIds"] = safeticaIds;
        this.setState({ form: form });
    }

    keywordsChangeInputHandle = (safeticaIdsInputValue) => {
        const { formErrors } = this.state;
        formErrors["safeticaIds"] = [];
        this.setState({
            safeticaIdsInputValue,
            formErrors: formErrors
        });
    }

    keywordsHandleKeyDown = (event) => {
        const { safeticaIdsInputValue, form, formErrors } = this.state;
        if (!safeticaIdsInputValue) return;

        if (form["safeticaIds"] != null && form["safeticaIds"].length > 0) {
            if (form["safeticaIds"].some(safeticaId => safeticaId.label === safeticaIdsInputValue)) {
                formErrors["safeticaIds"].push("Istnieje już taki Id reguły.");
                this.setState({ formErrors: formErrors });
                return;
            }
        }

        switch (event.key) {
            case "Enter":
            case "Tab":
                if (!form["safeticaIds"]) form["safeticaIds"] = [];
                form["safeticaIds"] = [...form["safeticaIds"], createOption(safeticaIdsInputValue)];
                this.setState({
                    safeticaIdsInputValue: "",
                    form: form,
                });
                event.preventDefault();
            // no default
        }
    }

    validForm() {
        const { formErrors, isAdminAtLeast, isAdmin, isSocAdmin } = this.state;
        const { name, deviceGroup, notificationModel, manager, ruleType, ruleMode, category, appCategory, policy, userGroup, safeticaIds } = this.state.form;
        const { uuid } = this.props;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        formErrors["name"] = [];
        formErrors["deviceGroup"] = [];
        formErrors["notificationModel"] = [];
        formErrors["manager"] = [];
        formErrors["ruleType"] = [];
        formErrors["category"] = [];
        formErrors["appCategory"] = [];
        formErrors["ruleMode"] = [];
        formErrors["policy"] = [];
        formErrors["userGroup"] = [];

        var errorCount = 0;
        
        if (hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }
        
        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (!deviceGroup || !deviceGroup.value || deviceGroup.value.length <= 0) {
            formErrors["deviceGroup"].push("Grupa musi zostać wybrana");
            errorCount++;
        }

        if (!notificationModel || !notificationModel.value || notificationModel.value.length <= 0) {
            formErrors["notificationModel"].push("Model powiadomień musi zostać wybrany");
            errorCount++;
        }

        if (!isAdmin && !isEditingModeEnabled && isAdminAtLeast && (!manager || manager.length <= 0)) {
            formErrors["manager"].push("Użytkownik musi zostać wybrany");
            errorCount++;
        }

        if (!ruleType || !ruleType.value || !Object.keys(AppConstants.SAFETICA_RULE_TYPES).includes(ruleType.value)) {
            formErrors["ruleType"].push("Typ reguły nie został wybrany");
            errorCount++;
        } else {
            switch (ruleType.value) {
                case AppConstants.SAFETICA_RULE_TYPES.DATA.name:
                    if (!category || !category.value || category.value.length <= 0) {
                        formErrors["category"].push("Kategoria nie została wybrana");
                        errorCount++;
                    }
                    break;
                case AppConstants.SAFETICA_RULE_TYPES.APPLICATION_CATEGORY.name:
                    if (!appCategory || !appCategory.value || appCategory.value.length <= 0) {
                        formErrors["appCategory"].push("Kategoria aplikacji nie została wybrana");
                        errorCount++;
                    }
                    break;
                case AppConstants.SAFETICA_RULE_TYPES.GENERAL.name:
                    break;
                default:
                    formErrors["ruleType"].push("Typ reguły jest niepoprawny");
                    errorCount++;
            }
        }

        if (!ruleMode || !ruleMode.value || !Object.keys(AppConstants.SAFETICA_RULE_MODES).includes(ruleMode.value)) {
            formErrors["ruleMode"].push("Tryb reguły nie został wybrany");
            errorCount++;
        }

        if (!policy || !policy.value || policy.value.length <= 0) {
            formErrors["policy"].push("Polityka nie została wybrana");
            errorCount++;
        }

        if (!userGroup || !userGroup.value || userGroup.value.length <= 0) {
            formErrors["userGroup"].push("Grupa użytkowników nie została wybrana");
            errorCount++;
        }

        if (isSocAdmin && isEditingModeEnabled) {
            safeticaIds != null && safeticaIds.forEach(safeticaId => {
                if (safeticaId != null && (!safeticaId && safeticaId.length <= 0)) {
                    formErrors["safeticaIds"].push("Id nie zostało ustawione poprawnie");
                    errorCount++;
                }
            });
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildSubmitRuleForm() {
        const { form } = this.state;
        return {
            ...this.buildEditRuleForm(),
            manager: form.manager && form.manager.value
        }
    }

    buildEditRuleForm() {
        const { form, isSocAdmin } = this.state;
        return {
            name: form.name,
            notificationModel: form.notificationModel.value,
            deviceGroup: form.deviceGroup.value,
            device: form.device ? form.device.value : "",
            procedure: form.procedure ? form.procedure.value : "",
            ruleType: form.ruleType.value,
            ruleMode: form.ruleMode.value,
            category: form.category ? form.category.value : "",
            appCategory: form.appCategory ? form.appCategory.value : "",
            policy: form.policy.value,
            userGroup: form.userGroup.value,
            shadowing: form.shadowing,
            safeticaIds: isSocAdmin && form.safeticaIds ? form.safeticaIds : [],
        }
    }

    submitForm = (onSuccess) => {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.PC_DEVICE_SAFETICA_RULE_URL + "/" + uuid, {
                    action: AppConstants.ACTIONS.TO_EDIT,
                    form: this.buildEditRuleForm()
                })
            } else {
                apiPromise = API.put(AppConstants.PC_DEVICE_SAFETICA_RULE_URL, {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: this.buildSubmitRuleForm()
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle()
                    onSuccess();
                    socToast.success("Reguła została dodana", "Reguła została poprawnie dodana i oczekuje na zatwierdzenie.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            });
        }
    }

    showTooltip(name) {
        var { tooltip } = this.state;
        tooltip[name] = !tooltip[name];
        this.setState({
            tooltip: tooltip
        });
    }

    fetchCompanyCustomers = () => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            })
        })
    }

    fetchAvailableOptions = (uuid = "", callback = () => { }) => {
        const { isAdminAtLeast } = this.state;
        const { manager } = this.state.form;

        var axiosConfig;
        if (isAdminAtLeast) {
            if (Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
                if (!((manager && manager.value) || uuid)) return;
            }

            axiosConfig = {
                params: {
                    uuid: uuid,
                    manager: manager ? manager.value : ""
                }
            }
        }

        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.PC_DEVICE_SAFETICA_RULE_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                        procedure: flatten(result.data.procedureGroup.map(procedureGroup => procedureGroup.value)).map(procedure => {
                            return {
                                label: procedure.name,
                                value: procedure.id
                            }
                        }),
                        ruleType: Object.entries(AppConstants.SAFETICA_RULE_TYPES).map(([key, type]) => {
                            return { label: type.displayName, value: type.name }
                        }),
                        ruleMode: Object.entries(AppConstants.SAFETICA_RULE_MODES).map(([key, mode]) => {
                            return { label: mode.displayName, value: mode.name }
                        })
                    },
                    isOptionsLoading: false,
                }, () => callback())
            }
        });
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchDeviceRuleForm = (uuid) => {
        API.get(AppConstants.PC_DEVICE_SAFETICA_RULE_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: {
                        ...this.state.form,
                        ...result.data,
                        notificationModel: this.findHintByValue("notificationModel", result.data.notificationModel),
                        deviceGroup: this.findHintByValue("deviceGroup", result.data.deviceGroup),
                        device: result.data.device ? this.findHintByValue("device", result.data.device) : "",
                        procedure: result.data.procedure ? this.findHintByValue("procedure", result.data.procedure) : "",
                        ruleType: this.findHintByValue("ruleType", result.data.ruleType),
                        ruleMode: this.findHintByValue("ruleMode", result.data.ruleMode),
                        category: result.data.category ? this.findHintByValue("category", result.data.category) : "",
                        appCategory: result.data.appCategory ? this.findHintByValue("appCategory", result.data.appCategory) : "",
                        policy: this.findHintByValue("policy", result.data.policy),
                        userGroup: this.findHintByValue("userGroup", result.data.userGroup),
                    }
                })
            }
        });
    }

    CategoriesForm() {
        const { isAdminAtLeast, isAdmin } = this.state;
        const { manager, category } = this.state.form;
        const { uuid } = this.props;

        var hasCategoryError = Boolean(this.state.formErrors.category.length);

        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        return (
            <React.Fragment>
                <label>*Kategoria</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    name="category"
                    value={category}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.category}
                    className={hasCategoryError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                    isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected} />
                {hasCategoryError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.category)}</ul>}
            </React.Fragment>
        )
    }

    AppCategoriesForm() {
        const { isAdminAtLeast, isAdmin } = this.state;
        const { manager, appCategory } = this.state.form;
        const { uuid } = this.props;

        var hasAppCategoryError = Boolean(this.state.formErrors.appCategory.length);

        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        return (
            <React.Fragment>
                <label>*Kategoria aplikacji</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    name="appCategory"
                    value={appCategory}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.appCategory}
                    className={hasAppCategoryError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                    isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected} />
                {hasAppCategoryError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.appCategory)}</ul>}
            </React.Fragment>
        )
    }

    ShadowingForm() {
        const { shadowing } = this.state.form;

        return (
            <React.Fragment>
                <FormCheckbox
                    name="shadowing"
                    toggle
                    small
                    checked={shadowing}
                    onChange={(event) => this.checkboxChangeHandler('shadowing', event)}>
                    Shadowing
                </FormCheckbox>
            </React.Fragment>
        )
    }

    render() {
        const { isAdminAtLeast, isAdmin, isSocAdmin, safeticaIdsInputValue } = this.state;
        const { name, deviceGroup, device, notificationModel, procedure, manager, ruleType, ruleMode, policy, userGroup, safeticaIds } = this.state.form;
        const { categoryTooltip } = this.state.tooltip;
        const { uuid } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasDeviceGroupError = Boolean(this.state.formErrors.deviceGroup.length);
        var hasNotificationModelError = Boolean(this.state.formErrors.notificationModel.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);

        var hasRuleTypeError = Boolean(this.state.formErrors.ruleType.length);
        var hasRuleModeError = Boolean(this.state.formErrors.ruleMode.length);
        var hasPolicyError = Boolean(this.state.formErrors.policy.length);
        var hasUserGroupError = Boolean(this.state.formErrors.userGroup.length);
        var hasSafeticaIdsError = Boolean(this.state.formErrors.safeticaIds.length);

        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        return (
            <Row>
                <Col sm="12" md="6">
                    <label>*Nazwa reguły</label>
                    <FormInput
                        name="name"
                        value={name}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    {!isEditingModeEnabled && isAdminAtLeast &&
                        <>
                            <label>*Menedżer</label>
                            <AsyncSelect
                                {...defaultSelectProps}
                                isClearable="true"
                                name="manager"
                                value={manager}
                                onChange={this.selectChangeHandler}
                                defaultOptions={this.state.formHints.manager}
                                className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")}
                                isLoading={this.state.isManagersLoading}
                                placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"} />
                            {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                        </>
                    }

                    <label>*Model powiadomienia</label>  <i id="tooltipCategory" style={{ marginTop: '6px' }} className="float-right fas fa-info-circle"></i>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="notificationModel"
                        value={notificationModel}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.notificationModel}
                        isDisabled={isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasNotificationModelError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasNotificationModelError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.notificationModel)}</ul>}
                    <Tooltip
                        className="tooltip-modal"
                        open={categoryTooltip}
                        target={'#tooltipCategory'}
                        toggle={() => this.showTooltip("categoryTooltip")}>{i18n.t('categoryTooltip')}
                    </Tooltip>

                    <label>*Grupa</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="deviceGroup"
                        value={deviceGroup}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.deviceGroup}
                        isDisabled={isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected}
                        className={hasDeviceGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasDeviceGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.deviceGroup)}</ul>}

                    <label>Urządzenie</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="device"
                        value={device}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.device.filter(device => device.parentValue === this.state.form.deviceGroup.value)}
                        placeholder="Wszystkie"
                        isDisabled={isEditingModeEnabled || !deviceGroup} />

                    <label>Procedura</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="procedure"
                        value={procedure}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.procedureGroup.map(procedureGroup => {
                            return {
                                label: procedureGroup.label,
                                options: procedureGroup.value.map(procedure => {
                                    return {
                                        label: procedure.name,
                                        value: procedure.id
                                    }
                                })
                            }
                        })}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        formatGroupLabel={formatGroupLabel} />

                    {isEditingModeEnabled && isSocAdmin &&
                        <>
                            <label>Id reguł w Safetica:</label>
                            <CreatableSelect
                                name="safeticaIds"
                                components={{ Input, DropdownIndicator }}
                                inputValue={safeticaIdsInputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={this.keywordsChangeHandler}
                                onInputChange={this.keywordsChangeInputHandle}
                                onKeyDown={this.keywordsHandleKeyDown}
                                placeholder="____Wpisz i naciśnij enter..."
                                value={safeticaIds}
                                className={hasSafeticaIdsError ? "react-select-container has-error mb-0 react-select__control" : "react-select-container mb-2"}
                                classNamePrefix="react-select" />
                            {hasSafeticaIdsError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.safeticaIds)}</ul>}
                        </>
                    }
                </Col>

                <Col sm="12" md="6">
                    <label>*Typ reguły</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="ruleType"
                        value={ruleType}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.ruleType}
                        className={hasRuleTypeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected} />
                    {hasRuleTypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ruleType)}</ul>}

                    {ruleType && ruleType.value === AppConstants.SAFETICA_RULE_TYPES.DATA.name && <this.CategoriesForm />}
                    {ruleType && ruleType.value === AppConstants.SAFETICA_RULE_TYPES.APPLICATION_CATEGORY.name && <this.AppCategoriesForm />}

                    <label>*Tryb reguły</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="ruleMode"
                        value={ruleMode}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.ruleMode}
                        className={hasRuleModeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected} />
                    {hasRuleModeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ruleMode)}</ul>}

                    {ruleType && (ruleType.value === AppConstants.SAFETICA_RULE_TYPES.GENERAL.name ||
                        ruleType.value === AppConstants.SAFETICA_RULE_TYPES.DATA.name) && <this.ShadowingForm />}

                    <label>*Polityki</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="policy"
                        value={policy}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.policy}
                        className={hasPolicyError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected} />
                    {hasPolicyError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.policy)}</ul>}

                    <label>*Grupa użytkowników</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="userGroup"
                        value={userGroup}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.userGroup}
                        className={hasUserGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected} />
                    {hasUserGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.userGroup)}</ul>}
                </Col>
            </Row >
        );
    }

}
export default ConfirmDeviceRuleForm;
