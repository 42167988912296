import React from "react";
import VideoPlayer from "../common/VideoPlayer";
import API from "../../api/AxiosConfiguration";
import * as AppConstants from '../../constants';

class ControlPanelCameraStream extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            controlPanelUuid: '',
            cameraUuid: '',
            streamUrl: ''
        };

        this.getCameraStream = this.getCameraStream.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.controlPanelUuid !== state.controlPanelUuid ||
            props.cameraUuid !== state.cameraUuid) {
            return {
                controlPanelUuid: props.controlPanelUuid,
                cameraUuid: props.cameraUuid
            }
        }

        return null;
    }

    componentDidMount() {
        const { controlPanelUuid, cameraUuid } = this.state;
        this.getCameraStream(controlPanelUuid, cameraUuid)
    }

    getCameraStream(controlPanelUuid, cameraUuid, callback = () => { }) {
        var axiosConfig = {
            params: {
                uuid: controlPanelUuid,
            }
        }

        API.get(AppConstants.CONTROL_PANEL_CAMERAS_URL + "/" + cameraUuid + "/stream", axiosConfig)
            .then(result => {
                this.setState({ streamUrl: AppConstants.API_URL + "/onvif" + result.data }, () => { callback({ type: 'application/x-mpegURL', src: this.state.streamUrl }) });
            });
    }

    render() {
        const { controlPanelUuid, cameraUuid, streamUrl } = this.state;

        var videoJsOptions = {
            fluid: true,
            liveui: true,
            autoplay: 'muted',
            controls: true,
            width: '100%',
            height: 'auto',
            liveTracker: false,
            preload: 'none',
            bigPlayButton: false,
            controlBar: {
                liveDisplay: true,
                pictureInPictureToggle: false
            }
        }

        if (streamUrl) {
            videoJsOptions = {
                ...videoJsOptions,
                sources: [{
                    src: streamUrl || "",
                    type: 'application/x-mpegURL',
                    withCredentials: true
                }]
            }
        }

        return (
            <VideoPlayer
                {...videoJsOptions}
                onEndStream={(cb) => { this.getCameraStream(controlPanelUuid, cameraUuid, cb) }} />
        );
    }

}

export default ControlPanelCameraStream;
