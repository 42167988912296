import React from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
} from "shards-react";

import DynamicMenu from "../menu/DynamicMenu";
import ControlPanelCard from "../cards/ControlPanelCard";
import ConfirmControlPanelApplicationModal from "./modals/ConfirmControlPanelApplicationModal";
import DeleteControlPanelApplicationModal from "./modals/DeleteControlPanelApplicationModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import API from "../../api/AxiosConfiguration";
import * as AppConstants from '../../constants';
import ConfirmDeviceGroupModal from './../device-group/modals/ConfirmDeviceGroupModal';
import { Store } from "../../flux";
import DeleteDeviceGroupModal from "../device-group/modals/DeleteDeviceGroupModal";
import withLoader from "../common/hoc/withLoader";

class ControlPanelMenu extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isDeleteControlPanelModalShow: false,
            isCreateControlPanelModalShow: false,
            isCreateDeviceGroupModalShow: false,
            isDeleteDeviceGroupModalShow: false,
            controlPanelGroupPath: '',
            isGroupSelected: false,
            selectedGroup: {},
            selectedControlPanelUUID: '',
            selectedDeviceGroupUUID: ''
        };

        this.selectControlPanelGroup = this.selectControlPanelGroup.bind(this);
        this.backControlPanelGroup = this.backControlPanelGroup.bind(this);
        this.toggleCreateDeviceGroupModal = this.toggleCreateDeviceGroupModal.bind(this);
        this.toggleDeleteDeviceGroupModal = this.toggleDeleteDeviceGroupModal.bind(this);
        this.toggleDeleteControlPanelModal = this.toggleDeleteControlPanelModal.bind(this);
        this.toggleCreateControlPanelModal = this.toggleCreateControlPanelModal.bind(this);
        this.updateControlPanelMenu = this.updateControlPanelMenu.bind(this);
        this.redirectToDetails = this.redirectToDetails.bind(this);
        this.updateControlPanelGroupName = this.updateControlPanelGroupName.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.showControlPanelGroupContext();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    redirectToDetails(uuid, name, type) {
        this.props.history.push({
            pathname: "/control-panel-details",
            state: {
                uuid: uuid,
                name: name,
                type: type
            }
        });
    }

    toggleDeleteControlPanelModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteControlPanelModalShow: !this.state.isDeleteControlPanelModalShow,
                selectedControlPanelUUID: uuid,
            });
    }

    toggleCreateControlPanelModal() {
        if (this._isMounted)
            this.setState({
                isCreateControlPanelModalShow: !this.state.isCreateControlPanelModalShow,
            });
    }

    toggleCreateDeviceGroupModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateDeviceGroupModalShow: !this.state.isCreateDeviceGroupModalShow,
                selectedDeviceGroupUUID: uuid,
            });
    }

    toggleDeleteDeviceGroupModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteDeviceGroupModalShow: !this.state.isDeleteDeviceGroupModalShow,
                selectedDeviceGroupUUID: uuid,
            });
    }

    fetchControlPanelGroups() {
        return API.post(AppConstants.CONTROL_PANEL_URL)
    }

    fetchControlPanelsByUUID(groupUUID) {
        return API.post(AppConstants.CONTROL_PANEL_URL, {
            uuid: groupUUID,
        })
    }

    showControlPanelGroupContext() {
        this.props.setLoading(true);
        this.fetchControlPanelGroups().then((result) => {
            var menu = result.data;
            if (this._isMounted)
                this.setState({
                    selectedGroup: menu
                })
            this.props.setLoading(false);
        });
    }

    updateControlPanelGroupName(form, uuid) {
        return API.put(AppConstants.DEVICE_GROUPS_URL + "/" + uuid, {
            form: form,
        })
    }

    updateControlPanelMenu() {
        this.props.setLoading(true);
        var controlPanelGroupCurrentUUID = this.state.controlPanelGroupPath.split("/").pop();
        if (controlPanelGroupCurrentUUID) {
            this.fetchControlPanelsByUUID(controlPanelGroupCurrentUUID).then((result) => {
                var menu = result.data;
                this.setControlPanelGroupContent(menu, controlPanelGroupCurrentUUID);
                this.props.setLoading(false);
            });
        } else {
            this.fetchControlPanelGroups(controlPanelGroupCurrentUUID).then((result) => {
                var menu = result.data;
                if (this._isMounted)
                    this.setState({
                        selectedGroup: menu
                    });
                this.props.setLoading(false);
            })
        }

        this.props.onControlPanelUpdate();
    }

    selectControlPanelGroup(groupUUID) {
        this.props.setLoading(true);
        this.fetchControlPanelsByUUID(groupUUID).then((result) => {
            var menu = result.data;
            this.setControlPanelGroupContent(menu, groupUUID);
            this.props.setLoading(false);
        })
    }

    setControlPanelGroupContent(menu, controlPanelGroupUUID) {
        if (this._isMounted) {
            var foundGroup = menu.controlPanelGroups.find((item) => {
                if (item.uuid === controlPanelGroupUUID) {
                    if (!this.state.controlPanelGroupPath.includes(controlPanelGroupUUID)) {
                        this.setState({
                            controlPanelGroupPath: this.state.controlPanelGroupPath + "/" + item.uuid
                        })
                    }
                    return true;
                }
                return false;
            });

            if (!Boolean(foundGroup))
                this.setState({
                    isGroupSelected: false,
                    selectedGroup: menu,
                    controlPanelGroupPath: ''
                });
            else
                this.setState({
                    isGroupSelected: true,
                    selectedGroup: foundGroup
                });
        }
    }

    backControlPanelGroup() {
        this.props.setLoading(true);
        var controlPanelGroupMenuPath = this.state.controlPanelGroupPath;
        controlPanelGroupMenuPath = controlPanelGroupMenuPath.substr(0, controlPanelGroupMenuPath.lastIndexOf("/"));

        var requestPromise;
        var controlPanelGroupparentValue = controlPanelGroupMenuPath.split("/").pop();
        if (controlPanelGroupparentValue) {
            requestPromise = this.fetchControlPanelsByUUID(controlPanelGroupparentValue);
        } else {
            requestPromise = this.fetchControlPanelGroups();
        }

        requestPromise.then((result) => {
            var selectedGroupMenu = result.data;
            if (this._isMounted)
                this.setState({
                    controlPanelGroupPath: controlPanelGroupMenuPath,
                    isGroupSelected: controlPanelGroupMenuPath !== "",
                    selectedGroup: selectedGroupMenu
                });
            this.props.setLoading(false);
        });
    }

    acceptDevice(uuid) {
        API.get(AppConstants.CONTROL_PANEL_URL + "/" + uuid + "/accept")
            .then(result => {
                if (result.status === 200) {
                    this.updateControlPanelMenu();
                }
            });
    }

    declineDevice(uuid) {
        API.get(AppConstants.CONTROL_PANEL_URL + "/" + uuid + "/decline")
            .then(result => {
                if (result.status === 200) {
                    this.updateControlPanelMenu();
                }
            });
    }

    render() {
        const {
            isAdminAtLeast,
            isCreateDeviceGroupModalShow,
            isDeleteControlPanelModalShow,
            isCreateControlPanelModalShow,
            isDeleteDeviceGroupModalShow,
            isGroupSelected,
            selectedGroup,
            selectedControlPanelUUID,
            selectedDeviceGroupUUID
        } = this.state;

        const {
            isMobileView,
        } = this.props;

        const buildControlPanelMenuStructure = () => {
            const { selectedGroup } = this.state;
            if (selectedGroup.controlPanelGroups) {
                var subGroups = selectedGroup.controlPanelGroups.map((item) => {
                    var smallTitle = '';
                    if (item.uuid !== "PENDING" && item.uuid !== "REJECTED" && item.allControlPanels)
                        smallTitle = 'dostępnych: ' + item.activeControlPanels + ' z ' + item.allControlPanels

                    if (item.uuid !== "PENDING" && item.uuid !== "REJECTED" && !item.allControlPanels)
                        smallTitle = 'Brak urządzeń'

                    return (
                        <DynamicMenu key={item.uuid} title={item.name} uuid={item.uuid}
                            smallTitle={smallTitle}
                            onClick={() => this.selectControlPanelGroup(item.uuid)}
                            onEditClick={this.toggleCreateDeviceGroupModal}
                            onDeleteClick={this.toggleDeleteDeviceGroupModal}
                            deleteEnable={isAdminAtLeast && (item.uuid !== "PENDING" && item.uuid !== "REJECTED")}
                            noTitleEdit={!isAdminAtLeast || (item.uuid === "PENDING" || item.uuid === "REJECTED")}></DynamicMenu>
                    );
                })
            }

            if (selectedGroup.controlPanels) {
                var controlPanels = selectedGroup.controlPanels.map((item, idx) => {
                    return (
                        <Col key={idx} md="6" lg="4" >
                            <ControlPanelCard
                                name={item.name}
                                status={item.status}
                                action={item.action}
                                isPending={item.status === "PENDING"}
                                isRejected={item.status === "REJECTED"}
                                controlPanelDetails={item.details}
                                withoutButtons={!item.modifiable}
                                onDetails={() => this.redirectToDetails(item.uuid, item.name, item.type)}
                                onDelete={() => this.toggleDeleteControlPanelModal(item.uuid)}
                                onAcceptDevice={() => this.acceptDevice(item.uuid)}
                                onDeclineDevice={() => this.declineDevice(item.uuid)} />
                        </Col>
                    );
                });
            }

            if (!(selectedGroup.controlPanelGroups && selectedGroup.controlPanelGroups.length > 0)
                && !(selectedGroup.controlPanels && selectedGroup.controlPanels.length > 0)) {
                return (
                    <Row className="pt-2" style={{ justifyContent: "center" }}>
                        <h6>Brak dostępnych central</h6>
                    </Row>
                )
            }

            return (
                <div>
                    {subGroups}
                    <Row className="pt-2" xs="1" sm="2" md="3">
                        {controlPanels}
                    </Row>
                </div>
            )
        }

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom d-inline">
                            <div className="back-button no-select">
                                {isGroupSelected && <FontAwesomeIcon className="back-button-icon" icon={faChevronLeft} onClick={this.backControlPanelGroup} />}
                            </div>
                            <h6 className="m-0">Bezpieczeństwo fizyczne <small>{isGroupSelected && '(należące do ' + selectedGroup.name + ')'}</small></h6>
                        </CardHeader>
                        <CardBody>
                            {isAdminAtLeast && <Button onClick={this.toggleCreateDeviceGroupModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową grupę
                            </Button>}
                            <Button onClick={() => this.toggleCreateControlPanelModal()} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową centralę
                            </Button>
                            {buildControlPanelMenuStructure()}
                        </CardBody>
                    </Card>
                </Col>

                <DeleteControlPanelApplicationModal open={isDeleteControlPanelModalShow} uuid={selectedControlPanelUUID}
                    toggle={this.toggleDeleteControlPanelModal} onSuccess={this.updateControlPanelMenu} />

                <ConfirmControlPanelApplicationModal open={isCreateControlPanelModalShow} toggle={this.toggleCreateControlPanelModal}
                    onSuccess={this.updateControlPanelMenu} isMobileView={isMobileView} />

                <ConfirmDeviceGroupModal open={isCreateDeviceGroupModalShow} uuid={selectedDeviceGroupUUID}
                    toggle={this.toggleCreateDeviceGroupModal} onSuccess={this.updateControlPanelMenu} isMobileView={isMobileView} />

                <DeleteDeviceGroupModal open={isDeleteDeviceGroupModalShow} uuid={selectedDeviceGroupUUID}
                    toggle={this.toggleDeleteDeviceGroupModal} onSuccess={this.updateControlPanelMenu} isMobileView={isMobileView} />
            </Row>
        )
    }

}

export default withLoader(ControlPanelMenu);
