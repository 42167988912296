import React from "react";
import { ConfirmModal } from "../../common/Modal";
import * as Constants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import * as SocToast from "../../../utils/SocToast";

class DeleteInvoiceModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteInvoice = this.deleteInvoice.bind(this);
    }

    deleteInvoice(toggleFunction) {
        const {
            onSuccess,
            checkboxes
        } = this.props;

        
        const checkboxesFiltered = Object.entries(checkboxes).filter( ([key, value]) => value===true);
        const checkboxesFilteredObj = Object.fromEntries(checkboxesFiltered);

        const finalCheckedUuids = Object.keys(checkboxesFilteredObj).map( uuid => uuid.split('_').join(' ').split('s').join('/'));

        API.post(Constants.BILLING_HISTORY_URL + "/delete",
        {
            invoiceIds: finalCheckedUuids
        },)
        .then(result => {
            if(result.status === 200) {
                onSuccess();
                toggleFunction();
            }
        }).catch(error => {
            var response = error.response;
            if (response && response.status === 404) {
                SocToast.error("Wystąpił błąd", "Nie znaleziono faktur/y!")
                toggleFunction();
            }
        })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz usunąć zaznaczone faktury?"
                    open={open}
                    toggle={toggle}
                    submitAction={this.deleteInvoice}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie zaznaczonych faktur.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          
            </ConfirmModal>
        )
    }
}

export default DeleteInvoiceModal;
