import React from "react";
import { ConfirmModal } from "../../common/Modal";

import CategoryForm from "../forms/CategoryForm"

class CreateCategoryModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
        } = this.props;

        return (
            <ConfirmModal
                title="Dodanie nowego modelu powiadomienia"
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.addCategoryForm.submitForm(onSuccess); }}
                positiveButtonText="Zapisz"
                negativeButtonText="Anuluj">

                <CategoryForm
                    toggle={toggle}
                    ref="addCategoryForm"
                    uuid={uuid} />

            </ConfirmModal>
        )
    }
}

export default CreateCategoryModal;