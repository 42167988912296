import React from "react";
import { ConfirmModal } from "../../common/Modal";
import ConfirmCompanyForm from "../forms/ConfirmCompanyForm";

class ConfirmCompanyModal extends React.Component {

    constructor(props) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid
        } = this.props;

        return (
            <ConfirmModal
                title="Dodaj nową firmę"
                open={open}
                toggle={toggle}
                submitAction={() => { this.childRef.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj" >

                <ConfirmCompanyForm toggle={toggle} ref={this.setRef} uuid={uuid} />

            </ConfirmModal>
        );
    }

}

export default ConfirmCompanyModal;