import React from "react";
import { ConfirmModal } from "../../common/Modal";

import ConfirmProcedureForm from "../forms/ConfirmProcedureForm"

class ConfirmProcedureModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            isMobileView,
            name,
            group,
            content
        } = this.props;

        return (
            <ConfirmModal
                title={uuid && uuid.length ? "Edytuj procedurę" : "Dodaj nową procedurę"}
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.addSafetyProcedureForm.submitForm(onSuccess); }}
                positiveButtonText="Zapisz"
                negativeButtonText="Anuluj">

                <ConfirmProcedureForm
                    toggle={toggle}
                    ref="addSafetyProcedureForm"
                    uuid={uuid}
                    isMobileView={isMobileView}
                    procedureName={name}
                    procedureGroup={group}
                    procedureContent={content} />

            </ConfirmModal>
        )
    }
}

export default ConfirmProcedureModal;