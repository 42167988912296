import React from "react";
import { FormInput, Col, Row, FormGroup } from "shards-react";
import Select from 'react-select';

import * as AppConstants from "../../../constants";
import { Store } from "../../../flux";

class RuleFilterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN, selectedFilterFields: "",
            sortOrder: "",
            sortField: "",
            form: {
                searchValue: ""
            },
            formErrors: {
                searchValue: []
            },
            firstApply: true
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        // this.filterFieldsSelectChangeHandler = this.filterFieldsSelectChangeHandler.bind(this);
        this.selectSortChangeHandler = this.selectSortChangeHandler.bind(this);
        this.filterSelectChangeHandler = this.filterSelectChangeHandler.bind(this);
        this.getProvidedFieldValues = this.getProvidedFieldValues.bind(this);
    }


    static getDerivedStateFromProps(props, state) {

        // eslint-disable-next-line default-case
        switch(props.detailsType) {
            case 'pcDevice': 
            case 'networkDevice': {
                return {
                    filters: {
                        selectedFilterFields: [...state.selectedFilterFields, { label: "Urządzenie", value: "device" }, { label: "Grupa", value: "deviceGroup" }]
                    },
                  form: {
                        ...state.form,
                        device: props.appliedFilter.device,
                        deviceGroup: props.appliedFilter.deviceGroup
                  }
                }
            }

            case 'controlPanel': {
                return {
                    filters: {
                        selectedFilterFields:  [...state.selectedFilterFields, { label: "Centrala", value: "controlPanel" }],
                    },
                    form: {
                          ...state.form,
                          controlPanel: props.appliedFilter.controlPanel
                    }
                }
            }
        }

        return null;
    }

    componentDidUpdate() {
        const { firstApply, form } = this.state;

        if(firstApply && ((form.device && form.deviceGroup) || form.controlPanel)){
            this.setState({ firstApply: false });
            this.submitFilterChanges();
        } 
    }

    componentDidMount() {
        const { isSocAdmin } = this.state;
        const customer = JSON.parse(localStorage.getItem('user'));
        if (!isSocAdmin) {
            this.setState({
                selectedFilterFields: [...this.state.selectedFilterFields, { label: "Menedżer", value: "manager" }],
                form: {
                    ...this.state.form,
                    manager: { label: customer.name, value: customer.email }
                }
            }, () => {
                this.submitFilterChanges();
            })
        } else {
            this.submitFilterChanges();
        }
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form }, () => {
            this.submitFilterChanges();
        });
    }

    filterFieldsSelectChangeHandler = (selected, event) => {
        var filters;
        switch (event.action) {
            case "clear":
                filters = {
                    selectedFilterFields: "",
                    form: {
                        searchValue: "",
                    }
                }
                break;
            case "select-option":
                filters = {
                    selectedFilterFields: selected
                };
                break;
            case "remove-value":
                filters = {
                    selectedFilterFields: this.deleteValueFromSelectedFilterFields(event),
                    form: {
                        ...this.state.form,
                        [event.removedValue.value]: null
                    }
                };

                break;
            default:
                filters = {
                    selectedFilterFields: "",
                    form: {
                        searchValue: this.state.form.searchValue,
                    }
                };
        }

        this.setState({
            ...filters
        }, () => {
            if(event.action === "remove-value") {
                this.props.onChangeFilter(this.state.form, true);
            }
        })
    }

    deleteValueFromSelectedFilterFields(event) {
        return this.state.selectedFilterFields
            .filter(filterField => event.removedValue.value !== filterField.value)
    }

    filterSelectChangeHandler = (selected, event) => {
        const { form } = this.state;
        this.setState({
            form: {
                ...form,
                [event.name]: selected
            }
        }, () => {
            this.submitFilterChanges();
        });
    }

    validFilters() {
        const { formErrors } = this.state;
        const { searchValue } = this.state.form;

        formErrors["searchValue"] = [];

        var errorCount = 0;
        if (searchValue.length !== 0) {
            if (searchValue.length < 3) {
                formErrors["searchValue"].push("Nazwa powinna składać się co najmniej z 3 znaków");
                errorCount++;
            }
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitFilterChanges() {
        const { form } = this.state;
        const { onChangeFilter, detailsType } = this.props;

        const filtersForm = {
            searchValue: form.searchValue,
            categoryFilter: form.category ? form.category.value : null,
            applicationCondition: form.status ? {
                action: form.status.value.action,
                status: form.status.value.status
            } : null,
            manager: this.state.form.manager ? this.state.form.manager.value : null
        };

        // eslint-disable-next-line default-case
        switch(detailsType) {
            case 'pcDevice':
            case 'networkDevice': {
                filtersForm.deviceGroupFilter = form.deviceGroup ? form.deviceGroup.value : null;
                filtersForm.deviceFilter = form.device ? form.device.value : null;
                break;
            }

            case 'controlPanel': {
                filtersForm.controlPanelFilter = form.controlPanel ? form.controlPanel.value : null;
                filtersForm.sensorGroupFilter = form.sensorGroup ? form.sensorGroup.value : null;
                filtersForm.sensorFilter = form.sensor ? form.sensor.value : null;
                break;
            }
        }

        if (this.validFilters()) {
            onChangeFilter(filtersForm, true);
        }
    }

    selectSortChangeHandler = (selected, event) => {
        const { state } = this;
        state[event.name] = selected;
        this.setState({
            ...this.state,
            state
        });

        var sortOrder = state.sortOrder;
        var sortField = state.sortField;
        if (sortOrder && sortOrder.value.length && sortField && sortField.value.length) {
            this.props.setSortOptions({
                sortOrder: sortOrder.value,
                sortField: sortField.value,
            });
        }
    }

    getProvidedFieldValues(fieldName) {
        const { filterOptions } = this.props;
        return filterOptions[fieldName];
    }

    render() {
        const { selectedFilterFields, sortOrder, sortField, form } = this.state
        const { options } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasSearchValueError = Boolean(this.state.formErrors.searchValue.length);

        const defaultSelectProps = {
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "fixed",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        const renderFilterValueSelects = () => {
            return selectedFilterFields && selectedFilterFields.map((field, id) => {
                return (
                    <FormGroup key={id}>
                        <Select
                            {...defaultSelectProps}
                            name={field.value}
                            value={form[field.value]}
                            onChange={this.filterSelectChangeHandler}
                            placeholder={field.label}
                            options={this.getProvidedFieldValues(field.value)} />
                    </FormGroup>
                )
            })
        }

        return (
            <Row>
                <Col md="6" lg="4">
                    <label className="filter-label" htmlFor="startDate">Sortuj:</label>
                    <FormGroup>
                        <Select
                            {...defaultSelectProps}
                            name="sortField"
                            value={sortField}
                            onChange={this.selectSortChangeHandler}
                            placeholder="Sortuj po"
                            options={options.sortField} />
                    </FormGroup>

                    <FormGroup>
                        <Select
                            {...defaultSelectProps}
                            name="sortOrder"
                            value={sortOrder}
                            onChange={this.selectSortChangeHandler}
                            placeholder="Typ sortowania"
                            options={options.sortOrder} />
                    </FormGroup>
                </Col>
                <Col md="6" lg="4">
                    <label className="filter-label" htmlFor="startDate">Wyszukaj:</label>
                    <FormGroup>
                        <FormInput
                            name="searchValue"
                            value={form.searchValue}
                            onChange={this.inputChangeHandler}
                            invalid={hasSearchValueError}
                            className={hasSearchValueError ? "mb-0" : "mb-2"}
                            placeholder="Wpisz wyszukiwaną wartość" />
                        {hasSearchValueError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.searchValue)}</ul>}

                    </FormGroup>
                </Col>
                <Col md="6" lg="4">
                    <label className="filter-label" htmlFor="startDate">Filtruj</label>
                    <FormGroup>
                        <Select
                            {...defaultSelectProps}
                            isMulti
                            name="filterByField"
                            value={selectedFilterFields ? selectedFilterFields : ""}
                            onChange={this.filterFieldsSelectChangeHandler}
                            placeholder="Filtruj po"
                            options={options.filterByFields}
                            closeMenuOnSelect={false} />
                    </FormGroup>

                    {renderFilterValueSelects()}
                </Col>
            </Row>
        );
    }

}

export default RuleFilterForm;

RuleFilterForm.defaultProps = {
    options: {
        sortOrder: [{ label: "Malejąco", value: "DESC" }, { label: "Rosnąco", value: "ASC" }],
        sortField: [
            { label: "Nazwa", value: "name" },
            { label: "Model powiadomienia", value: "category" },
            { label: "Status", value: "status" },
            // { label: "Grupa", value: "deviceGroup" },
            // { label: "Urządzenie", value: "device" },
        ],
        filterByFields: [
            { label: "Model powiadomienia", value: "category" },
            { label: "Status", value: "status" },
            // { label: "Grupa", value: "deviceGroup" },
            // { label: "Urządzenie", value: "device" },
            { label: "Menedżer", value: "manager" },
        ],
    }
}
