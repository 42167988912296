import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

class DynamicMenu extends React.Component {

    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    getDownChevron() {
        return this.props.downChevron === undefined ? null : this.props.downChevron;
    }

    getDownUpChevron() {
        return this.props.downUpChevron === undefined ? null : this.props.downUpChevron;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    onClick(event) {
        this.props.onClick(event);
    }

    render() {
        const { title, smallTitle, noTitleEdit, deleteEnable, onDeleteClick, onEditClick } = this.props;

        let downChevron = this.getDownChevron(); 

        const headerRoundedClasses = classNames(
            "collapse-header-nav",
            "border-left",
            "border-top",
            "border-right",
            "d-flex",
            "flex-row",
            "justify-content-between",
            "align-items-center",
            typeof (downChevron) !== "undefined" ?
                downChevron ? "rounded" : "rounded-top" : "rounded"
        );

        const renderTitle = () => {
            return <span>{title} <small>{smallTitle && '(' + smallTitle + ')'}</small></span>
        }

        return (
            <div ref={this.setWrapperRef} onClick={this.onClick} className={headerRoundedClasses}>
                {renderTitle()}

                <div className="icon-container">
                    {deleteEnable && (title !== "Oczekujące" || title !== "Odrzucone") && <FontAwesomeIcon onClick={(event) => { event.stopPropagation(); onDeleteClick(this.props.uuid) }} className="nav-collapse-icon" color='#c4183c' icon={faTrash} />}
                    {!noTitleEdit && <FontAwesomeIcon onClick={(event) => { event.stopPropagation(); onEditClick(this.props.uuid) }} className="nav-collapse-icon" icon={faPencilAlt} />}
                    {downChevron ?
                        <FontAwesomeIcon className="nav-collapse-icon" icon={faChevronDown} /> :
                        <FontAwesomeIcon className="nav-collapse-icon" icon={faChevronUp} />}    
                </div>
            </div>
        );
    }

}

export default DynamicMenu;
