import React from "react";
import { Button, Card, CardBody, CardFooter, CardHeader } from "shards-react";
import * as SocToast from '../../utils/SocToast';
import ForgotPasswordMethodSelect from './forms/ForgotPasswordMethodSelect';

class CustomerPasswordRevoveryMethod extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onPasswordRecoveryMethodSave() {
        SocToast.success("Odzyskiwanie", "Sposób odzyskiwania hasła został zapisany.")
    }

    render() {
        return (
            <Card className="mb-3 w-100">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Konfiguracja sposobu odzyskiwania hasła</h6>
                    <div className="block-handle" />
                </CardHeader>

                <CardBody className="py-1">
                    <div>Wybierz kanał komunikacyjny umożliwiający odzyskiwanie dostępu do konta 
                        w przypadku utraty hasła. Upewnij się, ze posiadasz dostęp do wybranej opcji.</div>

                    <ForgotPasswordMethodSelect
                        onSuccess={this.onPasswordRecoveryMethodSave}
                        ref="savePasswordRecoveryMethod" />

                </CardBody>
                <CardFooter>
                    <Button outline theme="info" className="mb-2 mr-1 float-right"
                        onClick={() => { this.refs.savePasswordRecoveryMethod.submitForm(); }}>
                        Zapisz
                    </Button>
                </CardFooter>
            </Card>
        );
    }

}

export default CustomerPasswordRevoveryMethod;