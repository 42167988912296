import React from 'react';
import { Row, Col } from 'shards-react';
import SafeticaConfigurationAppCategoryTable from '../tables/SafeticaConfigurationAppCategoryTable';

class SafeticaConfigurationAppCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            countOfStartedFetches: 0,
            countOfCompletedFetches: 0
        }

        this.onFetchStart = this.onFetchStart.bind(this);
        this.onFetchComplete = this.onFetchComplete.bind(this);
    }

    onFetchStart() {
        const { isLoading, countOfStartedFetches } = this.state;

        if (!isLoading) {
            this.setState({
                isLoading: true,
                countOfStartedFetches: countOfStartedFetches + 1
            }, () => this.props.setLoading(true))
        }
    }

    onFetchComplete() {
        const { isLoading, countOfStartedFetches, countOfCompletedFetches } = this.state;

        if (isLoading) {
            if (countOfStartedFetches < countOfCompletedFetches) {
                this.setState({
                    countOfCompletedFetches: countOfCompletedFetches + 1
                })
            } else {
                this.setState({
                    isLoading: false,
                    countOfStartedFetches: 0,
                    countOfCompletedFetches: 0
                }, () => this.props.setLoading(false))
            }
        }
    }

    render() {
        return (
            <Row className="py-1">
                <Col>
                    <div className="table-card">
                        <SafeticaConfigurationAppCategoryTable onFetchStart={this.onFetchStart} onFetchComplete={this.onFetchComplete} />
                    </div>
                </Col>
            </Row>
        );
    }

}

export default SafeticaConfigurationAppCategories;