import React from "react";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import DynamicMenu from "../menu/DynamicMenu"
import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import ConfirmAccessGroupModal from './modals/ConfirmAccessGroupModal';
import AccessGroupCard from './../cards/AccessGroupCard';
import DeleteAccessGroupModal from "./modals/DeleteAccessGroupModal";
import withLoader from "../common/hoc/withLoader";

class AccessGroups extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isConfirmAccessGroupModalShow: false,
            isDeleteAccessGroupModalShow: false,
            selectedAccessGroupUUID: "",
            companies: [],
            selectedCompany: {},
        };

        this.updateComponent = this.updateComponent.bind(this);
        this.toggleConfirmAccessGroupModal = this.toggleConfirmAccessGroupModal.bind(this);
        this.toggleDeleteAccessGroupModal = this.toggleDeleteAccessGroupModal.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateComponent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleConfirmAccessGroupModal(uuid) {
        if (this._isMounted)
            this.setState({
                isConfirmAccessGroupModalShow: !this.state.isConfirmAccessGroupModalShow,
                selectedAccessGroupUUID: uuid,
            });
    }

    toggleDeleteAccessGroupModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteAccessGroupModalShow: !this.state.isDeleteAccessGroupModalShow,
                selectedAccessGroupUUID: uuid,
            });
    }

    fetchAccessGroups(form) {
        return API.post(Constants.ACCESS_GROUPS_URL, {
            form: form,
        })
    }

    updateComponent(filters = {}) {
        this.props.setLoading(true);
        this.fetchAccessGroups(filters).then((result) => {
            var companies = result.data;
            if (this._isMounted)
                this.setState({
                    companies: companies,
                });
            this.props.setLoading(false);
        })
    }

    render() {
        const {
            companies,
            selectedCompany,
            isConfirmAccessGroupModalShow,
            isDeleteAccessGroupModalShow,
            selectedAccessGroupUUID
        } = this.state;

        const generateTable = (display, accessGroups) => {
            if (display) {
                return (
                    <Row className="m-0 rounded-bottom mb-1" style={{ backgroundColor: "rgba(0,0,0,.075)" }}>
                        {accessGroups.map((accessGroup, idx) =>
                            <Col key={idx} md="6" lg="4" >
                                <AccessGroupCard
                                    id={idx}
                                    name={accessGroup.name}
                                    customerDetails={accessGroup.details ? accessGroup.details : []}
                                    withEditButton
                                    withDeleteButton
                                    onEdit={() => this.toggleConfirmAccessGroupModal(accessGroup.uuid)}
                                    onDelete={() => this.toggleDeleteAccessGroupModal(accessGroup.uuid)} />
                            </Col>
                        )}
                    </Row>
                );
            }
        }

        const showNoRecordsFoundMessage = () => (
            <Row className="pt-2" style={{ justifyContent: "center" }}>
                <h6>Nie odnaleziono żadnych grup dostępowych</h6>
            </Row>
        )

        return (
            <Row className="mt-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Grupy dostępowe <small>(Podzielone na firmy)</small></h6>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={this.toggleConfirmAccessGroupModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową grupę
                            </Button>

                            {companies.filter(company => company.accessGroups.length).map((company, idx) =>
                                <div key={idx}>
                                    <DynamicMenu
                                        title={company.name}
                                        noTitleEdit={true}
                                        downChevron={selectedCompany !== company.name}
                                        onClick={() => {
                                            if (selectedCompany !== company.name) {
                                                this.setState({ selectedCompany: company.name })
                                            } else {
                                                this.setState({ selectedCompany: "" })
                                            }
                                        }}>
                                    </DynamicMenu>
                                    {generateTable(selectedCompany === company.name, company.accessGroups)}
                                </div>
                            )}

                            {!companies.flatMap(company => company.accessGroups.length).reduce((a, b) => a + b, 0) && showNoRecordsFoundMessage()}

                        </CardBody>
                    </Card>
                </Col>

                <ConfirmAccessGroupModal open={isConfirmAccessGroupModalShow} onSuccess={() => { this.updateComponent(); this.props.onUpdate(); }}
                    uuid={selectedAccessGroupUUID} toggle={this.toggleConfirmAccessGroupModal} />

                <DeleteAccessGroupModal open={isDeleteAccessGroupModalShow} onSuccess={() => { this.updateComponent(); this.props.onUpdate(); }}
                    uuid={selectedAccessGroupUUID} toggle={this.toggleDeleteAccessGroupModal} />
            </Row>
        );
    }

}

export default withLoader(AccessGroups);