import React from 'react';
import classNames from 'classnames';

import { Button, Collapse, DropdownItem } from 'shards-react';
import ScrollToBottom from "react-scroll-to-bottom";

import * as SocToast from '../utils/SocToast';
import API from "../api/AxiosConfiguration";
import * as Constants from "../constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faChevronDown, faChevronRight, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

import "../assets/chat.css"
import LinkButton from '../components/buttons/LinkButton';
import { getUserReadableGroup } from './../utils/ChatTranslation';
import { Store, Dispatcher, Constants as FluxConstants } from "../flux";
import { ChatSortDropdown, ChatSettingsDropdown } from '../components/common/Dropdowns';
import {withCookies} from 'react-cookie';
import dateFormat from "dateformat";


class Chat extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.stompClient = null;

        this.state = {
            currentUser: undefined,
            activeContact: undefined,
            text: "",
            contacts: [],
            messages: [],
            selectedCompanyKey: "",
            isContextMenuShow: false,
            isSortMenuShow: false,
            selectedSortMethod: this.props.sortOptions[0]
        };

        this.toggleContextMenu = this.toggleContextMenu.bind(this);
        this.toggleSortMenu = this.toggleSortMenu.bind(this);
        this.setText = this.setText.bind(this);
        this.setContacts = this.setContacts.bind(this);
        this.setActiveContact = this.setActiveContact.bind(this);
        this.setMessages = this.setMessages.bind(this);
        this.setSelectedCompanyKey = this.setSelectedCompanyKey.bind(this);
        this.endConversation = this.endConversation.bind(this);
        this.openExportToEmailModal = this.openExportToEmailModal.bind(this);
        this.connect = this.connect.bind(this);
        this.onConnected = this.onConnected.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.loadContacts = this.loadContacts.bind(this);
        this.sort = this.sort.bind(this);
    }

    componentDidMount() {
        const { activeContact } = this.state;
        var { currentUser } = this.state;

        this._isMounted = true;
        this.getCurrentUser().then(user => {
            currentUser = user;
            if (this._isMounted)
                this.setState({ currentUser: currentUser }, () => {
                    this.connect();
                    this.loadContacts();

                    if (activeContact === undefined) return;
                    this.findChatMessages(activeContact.id, currentUser.id).then((msgs) =>
                        this.setMessages(msgs)
                    );
                    this.loadContacts();
                });
        });

        var toggleContactsButton = document.getElementsByClassName("toggle-contacts");
        var sidePanel = document.getElementById("sidepanel");
        var toggleContactButton = Array.from(toggleContactsButton)[0]
        toggleContactButton.addEventListener("click", () => {

            if (!toggleContactButton.style.right || toggleContactButton.style.right === "0px") {
                toggleContactButton.style.right = sidePanel.offsetWidth - 1 + "px";
                sidePanel.style.marginRight = "0";
            } else {
                toggleContactButton.style.right = "0";
                sidePanel.style.marginRight = "-320px";
            }

        })

        window.addEventListener('resize', () => {
            if (window.innerWidth > 767.98) {
                toggleContactButton.style.right = sidePanel.offsetWidth - 1 + "px";
                sidePanel.style.marginRight = "0";
            } else {
                toggleContactButton.style.right = "0";
                sidePanel.style.marginRight = "-320px";
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentUser, activeContact } = this.state;

        if (activeContact === undefined) return;

        if (prevState.currentUser !== currentUser || prevState.activeContact !== activeContact) {
            this.findChatMessages(activeContact.id, currentUser.id).then((msgs) =>
                this.setMessages(msgs)
            );
            this.loadContacts();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleContextMenu() {
        this.setState({
            isContextMenuShow: !this.state.isContextMenuShow
        });
    }

    toggleSortMenu() {
        this.setState({
            isSortMenuShow: !this.state.isSortMenuShow
        });
    }

    setText(value) {
        if (this._isMounted)
            this.setState({ text: value })
    }

    setContacts(value) {
        if (this._isMounted)
            this.setState({ contacts: value })
    }

    setActiveContact(value) {
        if (this._isMounted)
            this.setState({ activeContact: value })
    }

    setMessages(value) {
        if (this._isMounted)
            this.setState({ messages: value }, () =>
                Dispatcher.dispatch({
                    actionType: FluxConstants.REFRESH_NEW_MESSAGES_COUNT,
                })
            )
    }

    setSelectedCompanyKey(value) {
        if (this._isMounted)
            this.setState({ selectedCompanyKey: value !== this.state.selectedCompanyKey ? value : "" })
    }

    endConversation() {
        const { messages, currentUser, activeContact } = this.state;

        if (messages.length > 0) {
            this.clearChatMessages(currentUser.id, activeContact.id)
                .then(result => {
                    if (result.status === 200) {
                        this.findChatMessages(activeContact.id, currentUser.id).then((msgs) =>
                            this.setMessages(msgs)
                        );
                        this.loadContacts();
                        SocToast.success("Powodzenie", "Pomyślnie zakończono konwersację");
                    }
                });
        }
    }

    openExportToEmailModal() {
        const { messages, currentUser, activeContact } = this.state;

        if (messages.length > 0) {
            this.exportAndSendEmail(currentUser.id, activeContact.id)
                .then(result => {
                    if (result.status === 200) {
                        SocToast.success("Powodzenie", "Na twój adres email wysłana została wiadomość z konwersacją.");
                    }
                });
        }
    }

    sort(options) {
        this.setState({
            selectedSortMethod: options,
        });
    }

    getCurrentUser() {
        return API.get(Constants.CHAT_URL + "/users/me").then(response => response.data);
    }

    getUsers() {
        return API.get(Constants.CHAT_URL + "/users/summaries").then(response => response.data);
    }

    countNewMessages(senderId, recipientId) {
        return API.get(Constants.CHAT_URL + "/messages/" + senderId + "/" + recipientId + "/count").then(response => response.data);
    }

    findChatMessages(senderId, recipientId) {
        return API.get(Constants.CHAT_URL + "/messages/" + senderId + "/" + recipientId).then(response => response.data);
    }

    findChatMessage(id) {
        return API.get(Constants.CHAT_URL + "/messages/" + id).then(response => response.data);
    }

    clearChatMessages(senderId, recipientId) {
        return API.get(Constants.CHAT_URL + "/messages/" + senderId + "/" + recipientId + "/clear");
    }

    exportAndSendEmail(senderId, recipientId) {
        return API.get(Constants.CHAT_URL + "/messages/" + senderId + "/" + recipientId + "/email/export");
    }

    connect() {
        const {cookies} = this.props;
        const Stomp = require("stompjs");
        var SockJS = require("sockjs-client");
        SockJS = new SockJS(Constants.API_URL + Constants.CHAT_URL + "/ws", null, { transports: ["xhr-streaming", "xhr-polling"]});
        this.stompClient = Stomp.over(SockJS);
        this.stompClient.connect({'X-CSRF-TOKEN': cookies.get('XSRF-TOKEN')}, this.onConnected, this.onError);
    };

    onConnected() {
        const {cookies} = this.props;
        const { currentUser } = this.state;
        this.stompClient.subscribe(
            "/chat/user/" + currentUser.id + "/queue/messages",
            this.onMessageReceived, {'X-CSRF-TOKEN':cookies.get('XSRF-TOKEN')}
        );
    };

    onError(err) {
        console.log(err);
    };

    onMessageReceived = (msg) => {
        const { activeContact, messages } = this.state;
        const notification = JSON.parse(msg.body);
        const active = activeContact;

        if (active !== undefined && (active.id === notification.senderId ||
            (active.group === Constants.Roles.ADMIN && notification.senderId === Constants.Roles.SOCADMIN))) {
            this.findChatMessage(notification.id).then((message) => {
                const newMessages = messages;
                newMessages.push(message);
                this.setMessages(newMessages);
            });
        } else {
            SocToast.success("Received a new message from " + notification.senderName);
        }
        this.loadContacts();
    };

    sendMessage = (msg) => {
        const {cookies} = this.props;
        const { currentUser, activeContact, messages } = this.state;

        if (msg.trim() !== "") {
            const message = {
                senderId: currentUser.id,
                recipientId: activeContact.id,
                senderName: currentUser.name,
                recipientName: activeContact.name,
                content: msg,
                timestamp: new Date(),
            };
            this.stompClient.send("/app/api/chat", {'X-CSRF-TOKEN':cookies.get('XSRF-TOKEN')}, JSON.stringify(message));

            const newMessages = [...messages];
            newMessages.push(message);
            this.setMessages(newMessages);
        }
    };

    loadContacts = () => {
        const { currentUser } = this.state;

        const promise = this.getUsers().then((companySummary) => {
            var contacts = {};
            companySummary.forEach((company) => {
                var contactPromises = company.userSummary.map((contact) => {
                    return this.countNewMessages(contact.id, currentUser.id).then((count) => {
                        contact.newMessages = count;
                        return contact;
                    })
                })

                const companyKey = JSON.stringify({ id: company.id, name: company.name });
                contacts[companyKey] = contactPromises;
            })

            return contacts;
        });

        promise.then((companies) => {
            Object.keys(companies).forEach((companyKey, index, keysOfCompanies) => {
                Promise.all(companies[companyKey]).then((groupedContacts) => {
                    this.setContacts({
                        ...this.state.contacts,
                        [companyKey]: this.groupBy(groupedContacts, (item) => { return item.group }),
                    });

                    const companiesWithoutSocAdmins = keysOfCompanies.filter(keyOfCompany => JSON.parse(keyOfCompany).id !== "socadmins");
                    if (companiesWithoutSocAdmins.length === 1 && !this.state.selectedCompanyKey)
                        this.setSelectedCompanyKey(companiesWithoutSocAdmins.shift());
                })
            })
        });
    };

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    render() {
        const { currentUser, activeContact, contacts, messages, text, selectedSortMethod } = this.state;

        const chatContentClasses = classNames({
            'content': true,
            'd-flex': !activeContact,
            'align-items-center': !activeContact,
            'justify-content-center': !activeContact,
            'h-100': !activeContact
        });

        const renderChatContent = () => (
            <>
                <div className="contact-profile px-2">
                    <div className="float-left">
                        <p>{activeContact && activeContact.name}</p>
                    </div>

                    <div className="float-right d-flex flex-row">
                        <ChatSortDropdown open={this.state.isSortMenuShow} toggle={this.toggleSortMenu} title={selectedSortMethod.label} icon={selectedSortMethod.icon}>
                            {this.props.sortOptions.map((sortOption, idx) => (
                                <DropdownItem key={idx} onClick={() => this.sort(sortOption)}>{sortOption.label}</DropdownItem>
                            ))}
                        </ChatSortDropdown>

                        <ChatSettingsDropdown open={this.state.isContextMenuShow} toggle={this.toggleContextMenu}>
                            <DropdownItem onClick={this.endConversation} disabled={this.state.messages.length === 0}>Zakończ konwersację</DropdownItem>
                            <DropdownItem onClick={this.openExportToEmailModal} disabled={this.state.messages.length === 0}>Eksportuj na emaila</DropdownItem>
                        </ChatSettingsDropdown>
                    </div>
                </div>

                <ScrollToBottom className="messages">
                    <ul style={{ padding: "0" }}>
                        {messages.sort((x, y) => selectedSortMethod.sort(x, y)).map((msg, index) => (
                            <li key={index} className={msg.senderId === currentUser.id || (Store.getUserRole() === msg.senderId && currentUser.name === msg.senderName) ? "d-flex flex-column align-items-end" : "d-flex flex-column align-items-start"}>
                                <small className="message-owner">{dateFormat(msg.timestamp, "dd-mm-yyyy HH:MM")} - {msg.senderName}</small>
                                <div className={msg.senderId === currentUser.id || (Store.getUserRole() === msg.senderId && currentUser.name === msg.senderName) ? "sent" : "replies"}>
                                    <p>{msg.content}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </ScrollToBottom>

                <div className="message-input">
                    <div className="wrap">
                        <input
                            name="user_input"
                            size="large"
                            placeholder="Napisz wiadomość"
                            value={text}
                            onChange={(event) => this.setText(event.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.sendMessage(text);
                                    this.setText("");
                                }
                            }}
                        />

                        <Button squared
                            onClick={() => {
                                this.sendMessage(text);
                                this.setText("");
                            }}
                        ><FontAwesomeIcon color="#cacedb" size={'1x'} icon={faPaperPlane} /></Button>
                    </div>
                </div>
            </>
        )

        const renderChooseCustomerMessage = () => (
            <h3 className="text-center">Wybierz użytkownika</h3>
        )

        const renderContactItem = (contact, contactIndex) => (
            <li key={contactIndex}
                onClick={() => {
                    this.setActiveContact(contact);
                }}
                className={
                    activeContact && contact.id === activeContact.id
                        ? "contact active pl-4"
                        : "contact pl-4"
                }>
                <div className="wrap">
                    <div className="meta">
                        <p className="name">{contact.name}</p>
                        {contact.newMessages !== undefined &&
                            contact.newMessages > 0 && (
                                <p className="preview">
                                    Nowe wiadomości
                                </p>
                            )}
                    </div>
                </div>
            </li>
        )

        const renderContactGroup = (companyKeyIndex, group, contactsOfGroup) => (
            <div key={companyKeyIndex}>
                {group !== Constants.Roles.SOCADMIN &&
                    <>
                        <div className="contact-group text-right w-100 pb-1 pr-2" style={{ background: "#3a6692" }}>{getUserReadableGroup(group)}</div>
                        <div className="clearfix"></div>
                    </>
                }

                <ul key={companyKeyIndex}>
                    {contactsOfGroup.map((contact, contactIndex) => { return renderContactItem(contact, contactIndex) })}
                </ul>
            </div>
        )

        return (
            <div id="frame">
                <div className="toggle-contacts d-lg-none vertical-text">
                    Kontakty
                        </div>
                <div id="sidepanel">
                    <div id="profile">
                        <div className="wrap">
                            <p>{currentUser && currentUser.name}</p>
                        </div>
                    </div>
                    <div id="search" />
                    <div id="contacts">
                        {Object.keys(contacts)
                            .filter(companyKey => JSON.parse(companyKey).id === "socadmins")
                            .map((companyKey, companyKeyIndex) => {
                                return Array.from(contacts[companyKey]).map(([group, contactsOfGroup], index) => (
                                    <div key={index} style={{ borderBottom: "1px solid #758ca4" }}>
                                        {renderContactGroup(companyKeyIndex, group, contactsOfGroup)}
                                    </div>
                                ))
                            })
                        }

                        {Object.keys(contacts)
                            .filter(companyKey => JSON.parse(companyKey).id !== "socadmins")
                            .map((companyKey, companyKeyIndex) => (
                                <div key={companyKeyIndex} style={{ borderBottom: "1px solid #758ca4", borderRight: "5px solid #3a6692" }}>
                                    <div className="wrap">
                                        <div className="meta">
                                            <LinkButton className={`w-100 text-left px-1 ${this.state.selectedCompanyKey === companyKey && "active"}`}
                                                onClick={() => this.setSelectedCompanyKey(companyKey)}>
                                                <div className="ml-3 pr-3" style={{ width: "inherit", color: "#fff", fontSize: "18px" }}>
                                                    {JSON.parse(companyKey).name}
                                                    <div className="float-right">
                                                        <FontAwesomeIcon className="nav-collapse-icon" style={{ fontZize: "15px" }} icon={this.state.selectedCompanyKey === companyKey ? faChevronRight : faChevronDown} />
                                                    </div>
                                                </div>
                                                {Array.from(contacts[companyKey]).map(([group, contactsOfGroup], index) => contactsOfGroup.map((contact, contactIndex) =>
                                                    contact.newMessages !== undefined && contact.newMessages > 0).includes(true))[0] && (<p className="preview ml-4 pr-3" style={{ color: "#1d2e6d", fontSize: "12px", fontWeight: "700", marginBottom: "2px" }}> Nowe wiadomości </p>)}
                                            </LinkButton>
                                        </div>
                                    </div>
                                    <Collapse open={this.state.selectedCompanyKey === companyKey}>
                                        {Array.from(contacts[companyKey]).map(([group, contactsOfGroup], index) =>
                                            renderContactGroup(companyKeyIndex, group, contactsOfGroup, index)
                                        )}
                                    </Collapse>
                                </div>
                            ))}
                    </div>
                </div>

                <div className={chatContentClasses}>
                    {activeContact !== undefined ? renderChatContent() : renderChooseCustomerMessage()}
                </div>
            </div>
        );
    }

}

Chat.defaultProps = {
    sortOptions: [
        {
            label: "Od najnowszych",
            icon: faSortUp,
            sort: (x, y) => {
                return x.timestamp - y.timestamp;
            }
        }, {
            label: "Od najstarszych",
            icon: faSortDown,
            sort: (x, y) => {
                return y.timestamp - x.timestamp;
            }
        }
    ]
}

export default withCookies(Chat);
