
import React from 'react';
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Button } from 'shards-react';

import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import * as SocToast from '../../utils/SocToast'

class CustomerStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resendShouldBeDisabled: false,
        };

        this.blockButton = this.blockButton.bind(this);
    }

    resendCustomerInvication(uuid) {
        API.get(Constants.CUSTOMERS_URL + "/reinvite?uuid=" + uuid)
            .then(result => {
                SocToast.success("Wiadomość wysłana", "Wiadomość z kodem aktywacyjnym została wysłana do użytkownika");
            })
            .catch(error => {
                if (error.response) {
                    SocToast.error("Błąd zapytania", "Spróbuj odświerzyć stronę i ponów próbę. Jeżeli nie pomogło, skontaktuj się z administratorem");
                } else {
                    SocToast.error("Błąd połączenia z serwerem", "Wystąpił problem z połączeniem");
                }
            })
    }

    blockButton(millis, disabledStateName) {
        this.setState({ [disabledStateName]: true })
        setTimeout(() => {
            this.setState({
                [disabledStateName]: false
            })
        }, millis);
    }

    render() {
        const { status, uuid } = this.props;
        const { resendShouldBeDisabled } = this.state;

        const renderResendEmailInvitationButton = () => (
            <ListGroupItem className="d-flex px-3 m-auto without-border">
                <Button
                    onClick={() => {
                        this.blockButton(10000, "resendShouldBeDisabled");
                        this.resendCustomerInvication(uuid);
                    }}
                    disabled={resendShouldBeDisabled}
                    outline
                    theme="info"
                    className="mb-2 mr-1 float-right">Ponów zaproszenie</Button>
            </ListGroupItem>
        )

        const renderDeviceStatus = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <h6 className="m-0 font-weight-bold" style={{ color: status.color }}>{status.content}</h6>
                </ListGroupItem>

                {status.status === Constants.STATUSES.INVITED && renderResendEmailInvitationButton()}
            </ListGroup>
        )

        const renderDeviceStatusFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Nie udało się pobrać danych o użytkowniku</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <Card className="mb-3 w-100">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Status</h6>
                    <div className="block-handle" />
                </CardHeader>

                <CardBody className=" py-0 text-center">
                    {
                        status ?
                            renderDeviceStatus() :
                            renderDeviceStatusFetchError()
                    }
                </CardBody>
            </Card>
        );
    }

}

export default CustomerStatus;
