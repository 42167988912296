import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import ControlPanelEventHistoryFilterForm from "../components/controlPanel/event-history/forms/ControlPanelEventHistoryFilterForm";
import ControlPanelEventHistoryList from "../components/controlPanel/event-history/lists/ControlPanelEventHistoryList";
import ScheduledReports from './../components/scheduled-reports/ScheduledReports';
import * as AppConstants from '../constants'
import { Store } from "../flux";
import {URLQueryParams} from "../utils/URLQueryParams";

class ControlPanelEventHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            foundEvents: [],
            randomKeyForForceUpdate: Math.random(),
            rules: this.props.location.state ? this.props.location.state.rules : []
        }

        this.updateSearchResult = this.updateSearchResult.bind(this);
        this.generateRandomKeyForForceUpdate = this.generateRandomKeyForForceUpdate.bind(this);
    }

    updateSearchResult(foundEvents) {
        this.setState({
            foundEvents: foundEvents,
        });
    }

    generateRandomKeyForForceUpdate() {
        this.setState({
            randomKeyForForceUpdate: Math.random()
        });
    }

    render() {
        const { foundEvents, randomKeyForForceUpdate, isSocAdmin, rules } = this.state;

        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle title="Wygenerowane naruszenia reguł" subtitle="Raport" className="text-sm-left mb-3" />
                </Row>

                <Row className="mt-2 mb-2">
                    <Col>
                        <Card>
                            <CardBody className="p-0 pt-1 pb-3">
                                <ControlPanelEventHistoryFilterForm
                                  redirected={!!this.props.location.search}
                                  queryParams={this.props.location.search && URLQueryParams(this.props.location.search)}
                                  onSuccess={this.updateSearchResult}
                                  rules={rules}
                                  onScheduledReportSaveSuccess={this.generateRandomKeyForForceUpdate}
                                  providedFilters={this.props.location.state} />
                                <ControlPanelEventHistoryList history={this.props.history} foundEvents={foundEvents} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-2 mb-2">
                    <Col>
                        {!isSocAdmin && <ScheduledReports key={randomKeyForForceUpdate} />}
                    </Col>
                </Row>
            </Container>
        );
    }

}

export default ControlPanelEventHistory;
