// export const API_URL = "https://api.soc-dev.perceptus.pl"
export const API_URL = "https://api.soc.perceptus.pl"

export const COMPANIES_URL = "/api/companies"
export const ACCESS_GROUPS_URL = "/api/accessgroups"
export const DEVICE_GROUPS_URL = "/api/groups"
export const DEVICES_URL = "/api/devices"
export const CUSTOMERS_URL = "/api/customers"
export const SCHEDULED_EVENTS_URL = "/api/events/scheduled"
export const RULES_URL = "/api/rules"
export const SAFETY_PROCEDURES_URL = "/api/safetyprocedures"
export const SAFETY_PROCEDURE_GROUPS_URL = "/api/safetyproceduregroups"
export const PROFILE_URL = "/api/profile"

export const DASHBOARDS_URL = "/api/dashboards";
export const SHARED_DASHBOARDS_URL = `${DASHBOARDS_URL}/shared`;
export const OWNED_DASHBOARDS_URL = `${DASHBOARDS_URL}/owned`;

export const DASHBOARD_TILES_URL = `${DASHBOARDS_URL}/tiles`;

export const DASHBOARD_TILES_RULES_URL = `${DASHBOARD_TILES_URL}/rules`;

export const DASHBOARD_TILE_FORM_DATA_URL = `${DASHBOARD_TILES_URL}/config`;

export const DASHBOARD_NETWORK_TILE_DATA = '/api/dashboard/network';
export const DASHBOARD_PCDEVICES_TILE_DATA = '/api/dashboard/pcsafetica';
export const DASHBOARD_CONTROLPANEL_TILE_DATA = '/api/dashboard/controlpanel';

export const PC_DEVICE_DEVICELOCK_URL = "/api/devices/pc/devicelock"
export const PC_DEVICE_DEVICELOCK_TEMPLATE_URL = "/api/devices/pc/devicelock/form/templates"
export const PC_DEVICE_DEVICELOCK_RULE_URL = "/api/rules/pc/devicelock"

export const PC_SAFETICA_DICTIONARY_CONFIGURATION_URL = "/api/safetica/dictionaries"
export const PC_SAFETICA_CATEGORY_CONFIGURATION_URL = "/api/safetica/categories"
export const PC_SAFETICA_USERS_CONFIGURATION_URL = "/api/safetica/users"
export const PC_SAFETICA_APP_CATEGORY_CONFIGURATION_URL = "/api/safetica/appcategories"
export const PC_SAFETICA_ZONE_CONFIGURATION_URL = "/api/safetica/zones"
export const PC_SAFETICA_POLICY_CONFIGURATION_URL = "/api/safetica/policies"

export const PC_DEVICE_SAFETICA_URL = "/api/devices/pc/safetica"
export const PC_DEVICE_SAFETICA_TEMPLATE_URL = "/api/devices/pc/safetica/form/templates"
export const PC_DEVICE_SAFETICA_RULE_URL = "/api/rules/pc/safetica"
export const PC_DEVICE_EVENTS_URL = "/api/pc/events"
export const PC_DEVICE_SINGLE_EVENT_URL = '/api/pc/event'

export const NETWORK_DEVICE_URL = "/api/devices/network"
export const NETWORK_DEVICE_TEMPLATE_URL = "/api/devices/network/form/templates"
export const NETWORK_DEVICE_EVENTS_URL = "/api/network/events"
export const NETWORK_DEVICE_SINGLE_EVENT_URL = '/api/network/event'
export const NETWORK_DEVICE_RULE_URL = "/api/rules/network"
export const NETWORK_DEVICE_STATUS_TABLE_URL = "/api/network/status/table"

export const CONTROL_PANEL_URL = "/api/controlpanel"
export const CONTROL_PANEL_TEMPLATE_URL = "/api/devices/controlpanel/form/templates"
export const CONTROL_PANEL_SENSORS_URL = "/api/controlpanel/sensors"
export const CONTROL_PANEL_CAMERAS_URL = "/api/controlpanel/cameras"
export const CONTROL_PANEL_DICTIONARIES_URL = "/api/controlpanel/dictionaries"
export const CONTROL_PANEL_EVENTS_URL = "/api/controlpanel/events"
export const CONTROL_PANEL_SINGLE_EVENT_URL = '/api/controlpanel/event'
export const CONTROL_PANEL_DICTIONARY_EVENTS_URL = "/api/controlpanel/events/events"
export const CONTROL_PANEL_LOGS_URL = "/api/controlpanel/events/logs"
export const CONTROL_PANEL_RULE_URL = "/api/controlpanel/rules"
export const CONTROL_PANEL_ABSENCES_URL = "/api/controlpanel/absences"

export const COMPANY_LOGS_URL = "/api/company/logs";

export const NOTIFICATION_CATEGORY_URL = "/api/categories"

export const BILLING_HISTORY_URL = "/api/billing/history/invoices"

export const LOGOUT_URL = "/api/logout"
export const LOGIN_URL = "/api/login"
export const LOGIN_SEND_TWO_FACTOR_URL = "/api/login/choiceTwoFactor"
export const LOGIN_TWO_FACTOR_URL = "/api/login/twoFactorValid"
export const CHANGE_PASSWORD = '/api/customer/change/password'
export const SERVICE_STATUS = '/api/service/status'

export const AUTHENTICATOR_TOKEN_URL = "/api/authenticatortoken"
export const TRUSTED_DEVICES_URL = "api/trusted/devices"
export const SECURITY_KEY_URL = "/api/securitykey/registration"
export const CHAT_URL = "/api/chat"
export const NOTIFICATIONS_URL = "/api/notifications/webpush"
export const PROPERTIES_URL = "/api/properties"

export const COMPANY_SETTINGS_EMAIL = "/api/company/settings/email"
export const COMPANY_SETTINGS_EMAIL_CHECK = "/api/company/settings/email/check"
export const COMPANY_SETTINGS_SMS_PACKAGE_TARIFF = "/api/company/settings/sms/tariff"
export const COMPANY_SETTINGS_SMS_AMOUNT = "/api/company/settings/sms/amount"
export const COMPANY_SETTINGS_NAGIOS = "/api/company/settings/nagios"
export const COMPANY_SETTINGS_RETENTION = "/api/company/settings/retention"

export const COMPANY_SMS_PAYMENT_EXCECUTION = "/api/sms/checkout"

export const REPORTS_URL = "/api/helpdesk/reports";

export const DEVICE_TYPES = Object.freeze({
    PC: "PC",
    NETWORK: "NETWORK",
    CONTROLPANEL: 'CONTROLPANEL'
});

export const PC_DEVICE_TYPES = Object.freeze({
    PC: "PC",
});

export const NETWORK_DEVICE_TYPES = Object.freeze({
    UTM: "UTM",
    SWITCH: "SWITCH",
});

export const NETWORK_DEVICE_SUPPORTED_PROTOCOLS = Object.freeze({
    SNMP_V2: { name: "SNMP_V2", displayName: "SNMPv2" },
    SNMP_V3: { name: "SNMP_V3", displayName: "SNMPv3" },
});

export const CONTROL_PANEL_TYPES = Object.freeze({
    CAMERA: "CAMERA",
    SENSOR: "SENSOR",
    DICTIONARY: 'DICTIONARY'
});

export const ACTIONS = Object.freeze({
    TO_ADD: "TO_ADD",
    TO_DELETE: "TO_DELETE",
    TO_EDIT: "TO_EDIT"
});

export const WARNING = Object.freeze({
    DEAD: "DEAD",
    LOW_BATTERY: "LOW_BATTERY"
});

export const STATUSES = Object.freeze({
    ACCEPTED: "ACCEPTED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    INVITED: "INVITED",
    BLOCKED: "BLOCKED",
});

export const Roles = Object.freeze({
    USER: "USER",
    ADMIN: "ADMIN",
    SOCADMIN: "SOCADMIN"
});

export const Privileges = Object.freeze({
    DEVICE_SECURITY_DEVICELOCK: "DEVICE_SECURITY_DEVICELOCK",
    DEVICE_SECURITY_SAFETICA: "DEVICE_SECURITY_SAFETICA",
    NETWORK_SECURITY: "NETWORK_SECURITY",
    PHYSICAL_SECURITY: "PHYSICAL_SECURITY"
});

export const RULE_TYPES = Object.freeze({
    COUNT: "COUNT",
    PERCENTAGE: "PERCENTAGE",
    DICTIONARY: "DICTIONARY",
    PORTS_CHECK: "PORTS_CHECK",
    OTHER_THAN: "OTHER_THAN",
    COMPONENT_SELECT_WTH_OTHER_THAN: "COMPONENT_SELECT_WTH_OTHER_THAN",
    DEVICE_SELECT: "DEVICE_SELECT",
    SYSTEM_EVENT_SELECT: "SYSTEM_EVENT_SELECT",
    PING: "PING"
});

export const TableStatusType = Object.freeze({
    PHYSICAL_PORTS: "PHYSICAL_PORTS",
    PORT_CHANNEL: "PORT_CHANNEL",
    VLAN: "VLAN",
    ETHERNET: "ETHERNET",
    IPSEC: "IPSEC",
    SSL_VPN: "SSL_VPN",
    SERVICES: "SERVICES",
    ROUTES: "ROUTES",
    IPS: "IPS",
    SYSTEM_EVENTS: "SYSTEM_EVENTS",
    TEMP: "TEMP",
    CPU_LOAD: "CPU_LOAD",
    RAM_LOAD: "RAM_LOAD",
    CONNECTED_DEVICES: "CONNECTED_DEVICES",
    OPTICAL_FIBER: "OPTICAL_FIBER",
    PROCESSES: "PROCESSES",
    FANS: "FANS",
    VOLUME: "VOLUME",
    POWER: "POWER",
    DISK: "DISK",
    PORT_BANDWIDTH: "PORT_BANDWIDTH",
    MEMORY_USAGE: "MEMORY_USAGE",
    TEMPERATURE: "TEMPERATURE",
    CPU_TEMPERATURE: "CPU_TEMPERATURE",
    CPU_USAGE: "CPU_USAGE",
    CONNECTED_USERS: "CONNECTED_USERS",
    DISK_MEMORY: "DISK_MEMORY",
    PARTITION_MEMORY: "PARTITION_MEMORY",
    RAID: "RAID",
    GRAPHIC_CARDS: "GRAPHIC_CARDS",
    GENERAL: "GENERAL"
});

export const TableStatusTypeDescription = Object.freeze({
    PHYSICAL_PORTS: "Porty fizyczne",
    PORT_CHANNEL: "Port channel",
    VLAN: "VLAN",
    ETHERNET: "Ethernet",
    IPSEC: "IPsec",
    SSL_VPN: "SSL VPN",
    SERVICES: "Usługi",
    ROUTES: "Trasy",
    IPS: "IPS",
    SYSTEM_EVENTS: "Eventy systemowe",
    TEMP: "Temperatura",
    CPU_LOAD: "Wykorzystanie procesora",
    RAM_LOAD: "Wykorzystanie pamięci RAM",
    CONNECTED_DEVICES: "Połączone urządzenia",
    OPTICAL_FIBER: "Światłowód",
    PROCESSES: "Procesy",
    FANS: "Wentylatory",
    VOLUME: "Utworzone partycje",
    POWER: "Status zasilaczy",
    DISK: "Zainstalowane dyski",
    PORT_BANDWIDTH: "Przepustowość portów",
    MEMORY_USAGE: "Dostępna pamięć RAM",
    TEMPERATURE: "Temperatura",
    CPU_TEMPERATURE: "Temperatura procesora",
    CONNECTED_USERS: "Połączeni użytkownicy",
    DISK_MEMORY: "Pamięć dysków",
    PARTITION_MEMORY: "Pamięć partycji",
    RAID: "Macierze RAID",
    GRAPHIC_CARDS: "Karta graficzna",
    GENERAL: "Ogólne"
});

export const DEVICE_SECURITY_SOFTWARES = Object.freeze({
    DEVICELOCK: "DeviceLock",
    SAFETICA: "Safetica",
})

export const SAFETICA_CATEGORY_TYPES = Object.freeze({
    SENSITIVE_CONTENT: "Wrażliwa zawartość",
    REGULAR_EXPRESSION: "Wyrażenie regularne",
    DICTIONARY: "Słownik"
})

export const SAFETICA_CATEGORY_SENSITIVE_CONTENTS = Object.freeze({
    CREDIT_CARD_NUMBERS: "Numery kart kredytowych",
    IBAN: "Numery IBAN",
    BRAZILIAN_LEGAL_ENTITY_NUMBERS: "Numery brazylijskich podmiotów prawnych",
    BRAZILIAN_NATURAL_PERSON_NUMBERS: "Numery brazylijskich osób fizycznych",
    CANADIAN_SOCIAL_INSURANCE_NUMBERS: "Kanadyjskie numery ubezpieczenia społecznego",
    CZECH_SLOVAK_BIRTH_NUMBERS: "Czeskie/Słowackie numery urodzenia",
    DANISH_NATIONAL_IDENTIFICATION_NUMBERS: "Duńskie krajowe numery identyfikacyjne",
    ECUADORIAN_CITIZENSHIP_CARD_NUMBERS: "Numery ekwadorskiego dokumentu potwierdzającego obywatelstwo",
    GERMAN_VAT_IDENTIFICATION_NUMBERS: "Niemieckie numery identyfikacyjne VAT",
    NORWEGIAN_NATIONAL_IDENTIFICATION_NUMBERS: "Norweskie krajowe numery identyfikacyjne",
    POLISH_ID_NUMBERS: "Polskie numery dowodu osobistego",
    POLISH_PERSONAL_NUMBERS: "PESEL",
    POLISH_PASSPORT_NUMBERS: "Polskie numery paszportu",
    SINGAPOREAN_IDENTIFICATION_CARD_NUMBERS: "Numery singapurskich kart identyfikacyjnych",
    SPANISH_VAT_IDENTIFICATION_NUMBERS: "Hiszpańskie numery identyfikacyjne VAT",
    SWEDISH_NATIONAL_IDENTIFICATION_NUMBERS: "Szwedzkie krajowe numery identyfikacyjne",
    TURKISH_IDENTIFICATION_NUMBERS: "Tureckie numery identyfikacyjne",
    UK_NATIONAL_INSURANCE_NUMBERS: "Krajowe numery ubezpieczenia w Wielkiej Brytanii",
    US_SOCIAL_SECURITY_NUMBERS: "Amerykańskie numery ubezpieczenia społecznego",
    US_SOCIAL_SECURITY_NUMBERS_AND_HIPPA: "Amerykańskie numery ubezpieczenia społecznego & HIPAA"
})

export const SAFETICA_POLICY_TYPES = Object.freeze({
    GENERAL: { name: "GENERAL", displayName: "Ogólne" },
    ZONED: { name: "ZONED", displayName: "Strefowe" }
})

export const SAFETICA_POLICY_DATA = Object.freeze({
    BOX_SYNC: "Box Sync",
    DROPBOX: "Dropbox",
    GOOGLE_DRIVE: "Google Drive",
    ONE_DRIVE_BUSINESS: "OneDrive Business",
    ONE_DRIVE_PERSONAL: "OneDrive Personal",
    SHARE_POINT: "SharePoint",
    UPLOAD_TO_FILE_SHARE: "Przesyłanie do udziału plików",
    UPLOAD_TO_WEB_MAIL: "Przesyłanie do poczty internetowej",
    INSTANT_MESSAGING: "Wiadomości natychmiastowe",
    REMOTE_TRANSFER: "Zdalny transfer",
    VIRTUAL_PRINT: "Wirtualne drukowanie",
    UPLOAD: "Przesyłanie",
    EMAIL: "Email",
    EXTERNAL_DEVICES: "Urządzenia zewnętrzne",
    NETWORK_FILE_SHARE: "Udostępnianie sieciowe",
    PRINTING: "Drukowanie"
})

export const SAFETICA_RULE_TYPES = Object.freeze({
    GENERAL: { name: "GENERAL", displayName: "Ogólne" },
    DATA: { name: "DATA", displayName: "Dane" },
    APPLICATION_CATEGORY: { name: "APPLICATION_CATEGORY", displayName: "Kategorie Aplikacji" }
})

export const SAFETICA_RULE_MODES = Object.freeze({
    SAVE_TO_JOURNAL: { name: "SAVE_TO_JOURNAL", displayName: "Zapisz w dzienniku" },
    NOTIFY: { name: "NOTIFY", displayName: "Powiadom" },
    BLOCK: { name: "BLOCK", displayName: "Zablokuj" }
})

export const ERROR_CODES = Object.freeze({
    NAGIOS_CONFIGURATION_NOT_FOUND: "Należy skonfigurować dostęp do Nagios XI. Możesz to zrobić w zakładce \"Ustawienia Firmy\"",
    EMAIL_CONFIGURATION_NOT_FOUND: "Należy skonfigurować dostęp do skrzynki pocztowej. Możesz to zrobić w zakładce \"Ustawienia Firmy\""
})
