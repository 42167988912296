import React from "react";
import {
    Row,
    Col,
    FormInput,
} from "shards-react";

import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';

class ResetPinTrustedDeviceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                newPin: '',
            },
            formErrors: {
                newPin: [],
            },
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { newPin } = this.state.form;

        formErrors["newPin"] = [];

        var errorCount = 0;

        if (!newPin.length === 6) {
            formErrors["newPin"].push("Pin musi składać się z sześciu cyfr");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            API.put(Constants.TRUSTED_DEVICES_URL + "/" + uuid, form).then((result) => {
                if (result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    render() {
        const { newPin } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNewPinError = Boolean(this.state.formErrors.newPin.length);

        return (
            <Row className="justify-content-md-center">
                <Col sm="12" md="6">
                    <label>*Nowy Pin</label>
                    <FormInput
                        name="newPin"
                        value={newPin || ''}
                        type="number"
                        onChange={this.inputChangeHandler}
                        invalid={hasNewPinError}
                        className={hasNewPinError ? "mb-0" : "mb-2"} />
                    {hasNewPinError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.newPin)}</ul>}
                </Col>
            </Row>
        );
    }
}

export default ResetPinTrustedDeviceForm;