import React from "react";
import { ConfirmModal } from "../../common/Modal";

import UploadDictionaryForm from "../forms/UploadDictionaryForm"

class UploadDictionaryModal extends React.Component {

    render() {
        const {
            open,
            controlPanelUuid,
            dictionaryType,
            toggle,
            onSuccess,
        } = this.props;

        return (
            <ConfirmModal
                title="Importuj Słownik"
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.uploadDictionaryForm.submitForm(onSuccess); }}
                positiveButtonText="Importuj"
                negativeButtonText="Anuluj">

                <UploadDictionaryForm
                    toggle={toggle}
                    controlPanelUuid={controlPanelUuid}
                    dictionaryType={dictionaryType}
                    ref="uploadDictionaryForm" />

            </ConfirmModal>
        )
    }
}

export default UploadDictionaryModal;
