import React from 'react';
import { FormInput, InputGroup, InputGroupAddon, Button } from 'shards-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

const DictionaryPreview = ({ name, dictionary, onDelete, className, style }) => {
    return (
        <div className={className} style={style}>
            {dictionary.map((singleValue, idx) => (
                <InputGroup key={idx} size="sm" className="mb-1">
                    <FormInput value={singleValue} disabled />
                    <InputGroupAddon type="append">
                        <Button theme="danger" onClick={() => onDelete(name, idx)}>
                            <FontAwesomeIcon icon={faMinus} />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            ))}
        </div>
    )
}

export default DictionaryPreview;