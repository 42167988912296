import React from 'react';
import { Row, Col } from 'shards-react';
import Select from 'react-select';
import * as SocToast from './../../../utils/SocToast';
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from "../../../constants";

const renderError = (errors) => {
    return errors.map((error, index) =>
        <li key={index}>{error}</li>
    )
}

class LogRetentionForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                retention: ''
            },
            formErrors: {
                retention: []
            },
            formHints: {
                retention: this.props.formHints.retention
            }

        };

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.validForm = this.validForm.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.fetchRetentionConfigurationForm = this.fetchRetentionConfigurationForm.bind(this);
        this.findHintByValue = this.findHintByValue.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    updateComponent() {
        this.fetchRetentionConfigurationForm();
    }

    fetchRetentionConfigurationForm() {
        API.get(AppConstants.COMPANY_SETTINGS_RETENTION)
            .then((result) => {
                if (result.status === 200) {
                    this.setState({
                        form: {
                            retention: this.findHintByValue("retention", result.data.retention)
                        }
                    })
                }
            });
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        formErrors[event.name] = [];
        this.setState({
            form: {
                ...form,
                [event.name]: item,
            }
        });
    }

    validForm(formErrors) {
        const { retention } = this.state.form;

        formErrors["retention"] = [];

        var errorCount = 0;

        if (retention.length <= 0) {
            formErrors["retention"].push("Retencja nie została wybrana");
            errorCount++;
        }

        return !errorCount;
    }

    submitForm = (onSuccess = () => { }) => {
        const { form, formErrors } = this.state;

        (async () => {
            if (this.validForm(formErrors)) {
                API.post(AppConstants.COMPANY_SETTINGS_RETENTION, {
                    form: {
                        retention: form.retention && form.retention.value
                    }
                }).then((result) => {
                    if (result.status === 200) {
                        SocToast.success("Powodzenie", "Czas przechowywania logów został zapisany");
                    }
                }).catch((error) => {
                    var response = error.response;
                    if (response && response.status === 400) {
                        response.data.errors.forEach(error => {
                            formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                        })
                        this.setState({ formErrors: formErrors });
                    }
                })
            }
        })();
    }

    render() {
        const { retention } = this.state.form;

        var hasRetentionError = Boolean(this.state.formErrors.retention.length);

        const defaultSelectProps = {
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            noOptionsMessage: () => "Brak dostępnych opcji",
            placeholder: "Wybierz"
        }

        return (
            <Row>
                <Col>
                    <label>*Retencja</label>
                    <Select
                        {...defaultSelectProps}
                        name="retention"
                        value={retention || ""}
                        onChange={this.selectChangeHandler}
                        options={this.state.formHints["retention"]}
                        className={hasRetentionError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasRetentionError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.retention)}</ul>}
                </Col>
            </Row>
        );
    }

}

export default LogRetentionForm;

LogRetentionForm.defaultProps = {
    formHints: {
        retention: [
            { label: "1 Miesiąc", value: "1_MONTH" },
            { label: "2 Miesiące", value: "2_MONTH" },
            { label: "3 Miesiące", value: "3_MONTH" },
        ]
    }
}
