import React from 'react';
import {
  CardBody,
  CardHeader,
  Card,
  DatePicker
} from 'shards-react';
import pl from 'date-fns/locale/pl'
import { Container, Row, Col, Button } from "shards-react";
import Api from '../../api/AxiosConfiguration';
import * as AppConstants from '../../constants';
import TooltippedSelect from "../dashboard/forms/TooltippedSelect";
import {Store} from "../../flux";

class CompanyLogs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          logStartDatetime: undefined,
          logEndDatetime: undefined,
          customer: null,
          customers: [],
          isMobileView: false,
        }

        this.onWindowResizeFluxListener = this.onWindowResizeFluxListener.bind(this);
        this.setLogStartDatetime = this.setLogStartDatetime.bind(this);
        this.setLogEndDatetime = this.setLogEndDatetime.bind(this);
        this.selectCustomerHandler = this.selectCustomerHandler.bind(this);

    }

  componentWillMount() {
    Store.addChangeListener(this.onWindowResizeFluxListener);
    Api.get(`${AppConstants.CUSTOMERS_URL}/company`)
      .then(response => {
        if(response.status === 200) {
          this.setState({
            ...this.state,
            customers: response.data
          })
        }
      })
  }

  componentWillUnmount() {
    this._isMounted = false;
    Store.removeChangeListener(this.onWindowResizeFluxListener);
  }


  onWindowResizeFluxListener() {
    if (this._isMounted)
      this.setState({
        ...this.state,
        isMobileView: Store.isMobileView()
      })
  }

  setLogStartDatetime = (date) => {
    this.setState({
      ...this.state,
      logStartDatetime: date
    })
  }

  setLogEndDatetime = (date) => {
    this.setState({
      ...this.state,
      logEndDatetime: date
    })
  }

  selectCustomerHandler(selected, event) {

    var item;
    if (Array.isArray(selected)) {
      item = selected.map(selectedItem => selectedItem.value);
    } else {
      item = selected
    }


    this.setState({
      ...this.state,
      customer: item
    });

  }

  render() {

        return (
            <Card className="mb-3 w-100 h-100">
                <CardHeader className="border-bottom">
                  <Container className="mw-100">
                    <Row>
                      <Col sm={12} lg={12} className="d-flex align-items-center">
                        <h6 className="m-0">Logi firmy</h6>
                      </Col>
                    </Row>
                  </Container>
                    <div className="block-handle" />
                </CardHeader>

                <CardBody className="px-0 py-0 text-center">
                  <Container>
                    <Row className="d-flex justify-content-center w-100">
                      <Col sm={12} lg={8} className="d-flex align-items-center justify-content-center flex-column mb-3 mt-4 w-100">
                            <p style={{padding: 0, margin: 0, paddingBottom: '1.5rem'}}>Okres:</p>
                            <DatePicker
                              locale={pl}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="Data od"
                              selected={this.state.logStartDatetime}
                              onChange={this.setLogStartDatetime}
                              selectsStart
                              startDate={this.state.logStartDatetime}
                              endDate={this.state.logEndDatetime}
                              withPortal={this.state.isMobileView} />
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center w-100">
                      <Col sm={12} lg={8} className="d-flex align-items-center justify-content-center my-1 mb-2 w-100">
                        <DatePicker
                          locale={pl}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Data do"
                          selected={this.state.logEndDatetime}
                          onChange={ el => {this.setLogEndDatetime(el);}}
                          selectsEnd
                          startDate={this.state.logStartDatetime}
                          endDate={this.state.logEndDatetime}
                          minDate={this.state.logStartDatetime}
                          withPortal={this.state.isMobileView} />
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center w-100">
                      <Col sm={12} lg={8} className="align-items-center justify-content-center my-3 w-100">
                        <p style={{padding: 0, margin: 0}}>Użytkownik:</p>
                        <TooltippedSelect
                          placeholder="Wybierz użytkownika"
                          noOptionsMsg="Brak dostępnych opcji"
                          name="customer"
                          value={this.state.customer}
                          onChange={(item, event) => { this.selectCustomerHandler(item, event); }}
                          defaultOptions={this.state.customers}
                          loadOptions={null}/>
                      </Col>

                    </Row>
                    <Row className="d-flex justify-content-center w-100">
                      <Col sm={12} lg={8} className="align-items-center justify-content-center my-3 w-100">
                        <p style={{padding: 0, margin: 0, color: "red", fontSize: '0.9rem'}}>Niewybranie filtrów skutkować będzie pobraniem wszystkich logów</p>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center w-100">
                      <Col sm={12} lg={8} className="d-flex align-items-center justify-content-center my-4 w-100">
                        <div className="align-items-center w-100">
                          <Button theme="primary" onClick={() => {

                            let from, to;

                            if(this.state.logStartDatetime && this.state.logEndDatetime) {
                              // eslint-disable-next-line no-unused-expressions
                              from = `${this.state.logStartDatetime.getFullYear()}-${this.state.logStartDatetime.getMonth()+1}-${this.state.logStartDatetime.getDate()} 00:00:00`;
                                to = `${this.state.logEndDatetime.getFullYear()}-${this.state.logEndDatetime.getMonth()+1}-${this.state.logEndDatetime.getDate()} 23:59:59`;
                            }

                            Api.post(AppConstants.COMPANY_LOGS_URL, {
                              customer: this.state.customer ? this.state.customer.value : null,
                              datetimeFrom: this.state.logStartDatetime ? new Date(from) : null,
                              datetimeTo: this.state.logEndDatetime ? new Date(to) : null
                            })
                              .then(response => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const [, filename] = response.headers['content-disposition'].split('filename=');
                                const link = document.createElement('a');
                                link.href = url;

                                link.download = filename.slice(1, -1);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              })
                              .catch(err => {
                                console.error(err);
                              });
                          }}>Pobierz logi</Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
            </Card>
        );
    }

}

export default CompanyLogs;
