import React from 'react';
import { Row, Col, FormInput, Alert } from 'shards-react';
import { trackPromise } from 'react-promise-tracker';
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from "../../../constants";
import * as SocToast from './../../../utils/SocToast';

const renderError = (errors) => {
    return errors.map((error, index) =>
        <li key={index}>{error}</li>
    )
}

class NagiosConfigurationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                serviceUrl: '',
                apiKey: ''
            },
            formErrors: {
                global: [],
                serviceUrl: [],
                apiKey: []
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.validForm = this.validForm.bind(this);
        this.buildForm = this.buildForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.fetchNagiosConfigurationForm = this.fetchNagiosConfigurationForm.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm(formErrors) {
        const { serviceUrl, apiKey } = this.state.form;

        formErrors["serviceUrl"] = [];
        formErrors["apiKey"] = [];
        formErrors["global"] = [];

        var errorCount = 0;

        if (serviceUrl.length <= 0) {
            formErrors["serviceUrl"].push("Adres serwisu nie został uzupełniony");
            errorCount++;
        }
        
        if (apiKey.length <= 0) {
            formErrors["apiKey"].push("Klucz API nie został uzupełniony");
            errorCount++;
        }

        return !errorCount;
    }

    buildForm() {
        const { form } = this.state;
        return form;
    }

    submitForm = (onSuccess = () => { }) => {
        const { formErrors } = this.state;

        (async () => {
            if (this.validForm(formErrors)) {
                var apiPromise = API.post(AppConstants.COMPANY_SETTINGS_NAGIOS, {
                    form: {
                        ...this.buildForm(),
                    }
                });

                await trackPromise(
                    apiPromise.then((result) => {
                        if (result.status === 200) {
                            SocToast.success("Powodzenie", "Zapisano konfigurację Nagios");
                            onSuccess();
                        }
                    }).catch((error) => {
                        var response = error.response;
                        if (response && response.status === 400) {
                            response.data.errors.forEach(error => {
                                formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                            })
                        }

                        if (response && response.status === 409) {
                            formErrors["global"].push("Połączenie z Nagios XI nie powiodło się");
                        }
                    })
                );
            }

            this.setState({ formErrors: formErrors });
        })();
    }

    updateComponent() {
        this.fetchNagiosConfigurationForm();
    }

    fetchNagiosConfigurationForm() {
        API.get(AppConstants.COMPANY_SETTINGS_NAGIOS)
            .then((result) => {
                if (result.status === 200) {
                    this.setState({
                        form: {
                            ...result.data,
                        }
                    })
                }
            });
    }

    render() {
        const { serviceUrl, apiKey } = this.state.form;

        var hasGlobalError = Boolean(this.state.formErrors.global.length);
        var hasApiKeyError = Boolean(this.state.formErrors.apiKey.length);
        var hasServiceUrlError = Boolean(this.state.formErrors.serviceUrl.length);

        return (
            <Row>
                <Col>
                    {hasGlobalError &&
                        <Alert theme="danger">
                            <ul style={{ listStyleType: "none", paddingInlineStart: "0", marginBottom: "0" }}>
                                {renderError(this.state.formErrors.global)}
                            </ul>
                        </Alert>
                    }

                    <label>*Klucz API</label>
                    <FormInput
                        name="apiKey"
                        value={apiKey || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasApiKeyError}
                        className={hasApiKeyError ? "mb-0" : "mb-2"} />
                    {hasApiKeyError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.apiKey)}</ul>}

                    <label>*Nagios API URL</label>
                    <FormInput
                        name="serviceUrl"
                        value={serviceUrl || ""}
                        onChange={this.inputChangeHandler}
                        invalid={hasServiceUrlError}
                        className={hasServiceUrlError ? "mb-0" : "mb-2"}
                        placeholder="{protokół}://{adres}:{port}" />
                    {hasServiceUrlError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.serviceUrl)}</ul>}
                </Col>
            </Row>
        );
    }

}

export default NagiosConfigurationForm;