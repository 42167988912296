import React from "react";
import { ConfirmModal } from "../../common/Modal";
import DeleteCompanyForm from './../forms/DeleteCompanyForm';

class DeleteCompanyModal extends React.Component {

    constructor(props) {
        super(props);

        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
        } = this.props;

        return (
            <ConfirmModal
                title="Jesteś pewien, że chcesz usunąć firmę?"
                open={open}
                toggle={toggle}
                submitAction={() => { this.childRef.submitForm(onSuccess); }}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                Spowoduje to usunięcie wszystkich danych o firmie.
                Jeśli jesteś zdecydowany, kliknij "Potwierdź".

                <DeleteCompanyForm toggle={toggle} ref={this.setRef} uuid={uuid} />

            </ConfirmModal>
        )
    }

}

export default DeleteCompanyModal;