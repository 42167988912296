import React from "react";
import classNames from "classnames";

const LinkButton = ({ id, onClick, children, className, style, disabled }) => {

    const linkClasses = classNames(
        "link-button",
        className
    )

    return (
        <button id={id} onClick={onClick} className={linkClasses} style={style} disabled={disabled}>{children}</button>
    )

}

export default LinkButton;
