import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'shards-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

export const ChatSortDropdown = ({ open, toggle, children, title = "Sortowanie", icon = faSort }) => {
    return (
        <Dropdown open={open} toggle={toggle}>
            <DropdownToggle outline theme="default">
                <div>
                    <FontAwesomeIcon icon={icon} />
                </div>
                <p className="p-0 m-0">{title}</p>
            </DropdownToggle>
            <DropdownMenu right>
                {children}
            </DropdownMenu>
        </Dropdown>
    )
}

export const ChatSettingsDropdown = ({ open, toggle, children }) => {
    return (
        <Dropdown open={open} toggle={toggle}>
            <DropdownToggle outline theme="default">
                <div>
                    <FontAwesomeIcon icon={faEllipsisV} />
                </div>
                <p className="p-0 m-0">Ustawienia</p>
            </DropdownToggle>
            <DropdownMenu right>
                {children}
            </DropdownMenu>
        </Dropdown>
    )
}
