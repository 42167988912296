import React from "react";
import {
    Card,
    CardBody,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
} from "shards-react";
import BootstrapTable from "react-bootstrap-table-next";
import ResetPinTrustedDeviceModal from "../modals/ResetPinTrustedDeviceModal";
import DeleteTrustedDeviceModal from "../modals/DeleteTrustedDeviceModal";
import WindowSizeListener from 'react-window-size-listener';
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';

class TrustedDevicesTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            trustedDevices: "",
            isDeleteTrustedDeviceShow: false,
            isResetPinTrustedDeviceModalShow: false,
            selectedTrustedDeviceUUID: '',
        }

        this.getTrustedDeviceList = this.getTrustedDeviceList.bind(this);
        this.toggleDeleteTrustedDeviceModal = this.toggleDeleteTrustedDeviceModal.bind(this);
        this.toggleResetPinTrustedDeviceModal = this.toggleResetPinTrustedDeviceModal.bind(this);
    }

    componentDidMount() {
        this.getTrustedDeviceList();
    }

    getTrustedDeviceList() {
        API.get(Constants.TRUSTED_DEVICES_URL)
            .then(result => {
                if (result.status === 200) {
                    this.setState({
                        trustedDevices: result.data,
                    })
                }
            })
    }

    toggleDeleteTrustedDeviceModal(uuid) {
        this.setState({
            isDeleteTrustedDeviceShow: !this.state.isDeleteTrustedDeviceShow,
            selectedTrustedDeviceUUID: uuid,
        });
    }

    toggleResetPinTrustedDeviceModal(uuid) {
        this.setState({
            isResetPinTrustedDeviceModalShow: !this.state.isResetPinTrustedDeviceModalShow,
            selectedTrustedDeviceUUID: uuid,
        });
    }

    render() {
        const {
            trustedDevices,
            isDeleteTrustedDeviceShow,
            isResetPinTrustedDeviceModalShow,
            selectedTrustedDeviceUUID
        } = this.state;
        var trustedDeviceColumns;

        function textFormatter(cell, row) {
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell}</span>
            );
        }

        function buttonFormatter(cell, row) {
            return (
                <span><Button onClick={() => this.formatExtraData.toggleResetPinTrustedDeviceModal(row.uuid)} outline theme="info" className="mb-1 mr-1">
                    Resetuj Pin
          </Button>
                    <Button onClick={() => this.formatExtraData.toggleDeleteTrustedDeviceModal(row.uuid)} outline theme="info" className="mb-1 mr-1">
                        Usuń
          </Button></span>
            );
        }

        if (!this.state.isMobile) {
            trustedDeviceColumns = [{
                dataField: 'name',
                align: "left",
                text: "Nazwa urządzenia",
                headerClasses: "column-header",
                formatter: textFormatter,
            }, {
                dataField: '',
                align: "left",
                text: "Akcja",
                headerClasses: "column-header",
                formatExtraData: this,
                formatter: buttonFormatter
            }]
        } else {
            trustedDeviceColumns = [{
                dataField: 'name',
                align: "left",
                text: "Nazwa urządzenia",
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: '',
                align: "left",
                text: "Akcja",
                headerClasses: "column-header",
                formatExtraData: this,
                formatter: buttonFormatter
            }]
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                e.preventDefault();
                if (!(window.getSelection && window.getSelection().type === 'Range')) {
                    this.toggleCreateAbsenceModal(row.uuid);
                }
            }
        };

        const renderAbsences = () => (
            <Row className="mr-0 ml-0">
                <Col xs="12" className="mb-2 pr-0 pl-0">
                    <BootstrapTable
                        bootstrap4={true}
                        keyField='uuid'
                        data={trustedDevices}
                        columns={trustedDeviceColumns}
                        classes="table mb-0"
                        headerClasses="column-header bg-light"
                        bordered={false}
                        rowStyle={{ height: '10px' }}
                        rowEvents={this.state.isMobile ? rowEvents : false} />
                </Col>
            </Row>
        )

        const renderAbsencesError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Nie dodano żadnych urządzeń</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <Card medium="true">
                <WindowSizeListener onResize={(windowSize) =>
                    windowSize.windowWidth > 610 ? this.setState({ isMobile: false }) : this.setState({ isMobile: true })}>
                </WindowSizeListener>

                <CardBody className="p-0">
                    {trustedDevices.length ? renderAbsences() : renderAbsencesError()}
                </CardBody>
                <DeleteTrustedDeviceModal open={isDeleteTrustedDeviceShow} uuid={selectedTrustedDeviceUUID}
                    toggle={this.toggleDeleteTrustedDeviceModal} onSuccess={this.getTrustedDeviceList} />

                <ResetPinTrustedDeviceModal open={isResetPinTrustedDeviceModalShow} uuid={selectedTrustedDeviceUUID}
                    toggle={this.toggleResetPinTrustedDeviceModal} onSuccess={this.getTrustedDeviceList} />
            </Card>
        )
    }
}

export default TrustedDevicesTable;
