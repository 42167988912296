import React from 'react';
import { Row, Col, FormInput, Alert } from 'shards-react';

import AsyncCreatableSelect from 'react-select/async-creatable';
import { trackPromise } from 'react-promise-tracker';

import API from "../../../api/AxiosConfiguration";
import * as AppConstants from '../../../constants';

import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'


class ConfirmControlPanelCameraForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                group: "",
                type: "",
                location: "",
                ip: "",
                port: "80",
                username: "",
                password: "",
            },
            formErrors: {
                global: [],
                name: [],
                group: [],
                type: [],
                ip: [],
                port: [],
                username: [],
                password: [],
            },
            formHints: {
                group: [],
                location: [],
            },
            isOptionsLoading: true
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);

        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);
        this.fetchControlPanelCameraForm = this.fetchControlPanelCameraForm.bind(this);
    }

    componentDidMount() {
        const { controlPanelUuid, uuid } = this.props;

        this.fetchAvailableOptions(controlPanelUuid, () => {
            if (uuid && uuid.length) {
                this.fetchControlPanelCameraForm(controlPanelUuid);
            }
        });
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    checkboxChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = !form[event.target.name];
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        formErrors[event.name] = [];
        this.setState({
            form: {
                ...form,
                [event.name]: item
            }
        });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, group, ip, port, username, password } = this.state.form;
        const { uuid } = this.props;

        formErrors["name"] = [];
        formErrors["group"] = [];
        formErrors["ip"] = [];
        formErrors["port"] = [];
        formErrors["username"] = [];
        formErrors["password"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (group == null || group.length <= 0) {
            formErrors["group"].push("Nie wybrano grupy");
            errorCount++;
        }

        if (ip.length <= 0) {
            formErrors["ip"].push("Pole Adres IP/Domena nie zostało uzupełnione");
            errorCount++;
        }

        if (!port || port < 1 || port > 65535) {
            formErrors["port"].push("Pole Port powinno znajdować się w zakresie od 1 do 65535");
            errorCount++;
        }

        if (username.length <= 0) {
            formErrors["username"].push("Pole Nazwa użytkownika nie zostało uzupełnione");
            errorCount++;
        }

        if (!uuid || uuid.length === 0) {
            if (password.length <= 0) {
                formErrors["password"].push("Pole Hasło nie zostało uzupełnione");
                errorCount++;
            }
        }

        this.setState({ formErrors: { ...formErrors } });
        return !errorCount;
    }

    buildForm() {
        const { form } = this.state;

        return {
            ...form,
            port: parseInt(form.port),
            group: form.group && form.group.value,
            type: form.type && form.type.value,
            location: form.location && form.location.value,
        }
    }

    submitForm = (onSuccess) => {
        const { controlPanelUuid } = this.props;
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var axiosConfig = {
                params: {
                    uuid: controlPanelUuid,
                }
            }

            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.CONTROL_PANEL_CAMERAS_URL + "/" + uuid, {
                    action: AppConstants.ACTIONS.TO_EDIT,
                    form: this.buildForm()
                }, axiosConfig)
            } else {
                apiPromise = API.post(AppConstants.CONTROL_PANEL_CAMERAS_URL, {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: this.buildForm()
                }, axiosConfig);
            }

            trackPromise(
                apiPromise.then((result) => {
                    if (result.status === 201) {
                        this.props.toggle();
                        onSuccess();
                    }
                }).catch((error) => {
                    var response = error.response;
                    if (response && response.status === 409) {
                        this.setState({
                            formErrors: {
                                ...formErrors,
                                global: ["Nie udało połączyć się z kamerą. Spróbuj ponownie później lub skontaktuj się z administratorem"]
                            }
                        });
                    }
                })
            )
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchAvailableOptions = (controlPanelUuid = "", callback) => {
        var axiosConfig = {
            params: {
                uuid: controlPanelUuid,
            }
        }

        API.get(AppConstants.CONTROL_PANEL_CAMERAS_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                    },
                    isOptionsLoading: false,
                }, () => callback())
            }
        });
    }

    fetchControlPanelCameraForm = (controlPanelUuid = "") => {
        const { uuid } = this.props;

        var axiosConfig = {
            params: {
                uuid: controlPanelUuid,
            }
        }

        API.get(AppConstants.CONTROL_PANEL_CAMERAS_URL + "/" + uuid, axiosConfig).then((result) => {
            if (result.status === 200) {
                var form = {
                    ...this.state.form,
                    ...result.data,
                    type: result.data.type ? this.findHintByValue("type", result.data.type) : "",
                    group: result.data.group ? this.findHintByValue("group", result.data.group) : "",
                    location: result.data.location ? this.findHintByValue("location", result.data.location) : "",
                    password: ""
                }

                this.setState({ form: form })
            }
        });
    }

    render() {
        const { name, group, location, ip, port, username, password } = this.state.form;
        const { uuid } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasGlobalError = Boolean(this.state.formErrors.global.length);
        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasGroupError = Boolean(this.state.formErrors.group.length);
        var hasIpError = Boolean(this.state.formErrors.ip.length);
        var hasPortError = Boolean(this.state.formErrors.port.length);
        var hasUsernameError = Boolean(this.state.formErrors.username.length);
        var hasPasswordError = Boolean(this.state.formErrors.password.length);

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz lub utwórz nowy",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            isClearable: true
        }

        return (
            <Row>
                <Col sm="12">
                    {hasGlobalError &&
                        <Alert theme="danger">
                            <ul style={{ listStyleType: "none", paddingInlineStart: "0", marginBottom: "0" }}>
                                {renderError(this.state.formErrors.global)}
                            </ul>
                        </Alert>
                    }
                </Col>

                <Col sm="12" md="6">
                    <label>*Nazwa</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>*Grupa urządzeń</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="group"
                        value={group}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.group}
                        className={hasGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={isEditingModeEnabled}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "group")}
                        isLoading={this.state.isOptionsLoading} />
                    {hasGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.group)}</ul>}

                    <label>Lokalizacja urządzenia</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="location"
                        value={location}
                        isValidNewOption={(inputValue) => {
                            const processedInputValue = inputValue ||  ""
                            
                            return processedInputValue.length >= 3 && processedInputValue.length <= 50 && !hasValueUrlOrHtmlTag(processedInputValue)
                        }}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.location}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "location")}
                        isLoading={this.state.isOptionsLoading} />
                </Col>

                <Col sm="12" md="6">
                    <label>*Adres IP/Domena</label>
                    <FormInput
                        name="ip"
                        value={ip || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasIpError}
                        className={hasIpError ? "mb-0" : "mb-2"} />
                    {hasIpError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ip)}</ul>}

                    <label>*Port</label>
                    <FormInput
                        name="port"
                        value={port || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasPortError}
                        className={hasPortError ? "mb-0" : "mb-2"}
                        type="number"
                        min="0"
                        max="65535" />
                    {hasPortError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.port)}</ul>}

                    <label>*Nazwa użytkownika</label>
                    <FormInput
                        name="username"
                        value={username || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasUsernameError}
                        className={hasUsernameError ? "mb-0" : "mb-2"} />
                    {hasUsernameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.username)}</ul>}

                    <label>*Hasło</label>
                    <FormInput
                        name="password"
                        value={password || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasPasswordError}
                        className={hasPasswordError ? "mb-0" : "mb-2"}
                        type="password" />
                    {hasPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.password)}</ul>}
                </Col>
            </Row>
        );
    }

}

export default ConfirmControlPanelCameraForm;
