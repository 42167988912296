import React from "react";
import { Card, CardBody, CardFooter, Button, Row, Col, ListGroup, ListGroupItem, ButtonGroup } from "shards-react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    SizePerPageDropdownStandalone,
    PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import * as APIConstants from "../../constants";
import dateFormat from "dateformat";
import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen, faTrash } from "@fortawesome/free-solid-svg-icons";

class NotificationHistoryList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            selected: [],
        };

        this.redirectToDetails = this.redirectToDetails.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.deleteNotifications = this.deleteNotifications.bind(this);
        this.changeStatusNotifications = this.changeStatusNotifications.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            notifications: nextProps.notifications ? nextProps.notifications : []
        })
    }

    redirectToDetails(deviceTypeUrl, uuid) {
        this.props.history.push({
            pathname: deviceTypeUrl,
            state: {
                uuid: uuid,
            }
        });
    }

    onRowSelect({ uniqueId }, isSelected) {
        if (isSelected) {
            this.setState({
                selected: [...this.state.selected, uniqueId].sort(),
            });
        } else {
            this.setState({ selected: this.state.selected.filter(it => it !== uniqueId) });
        }
        return false;
    }

    onSelectAll(isSelected, rows) {
        var listUniqueId = rows.map(row => row.uniqueId);
        if (isSelected) {
            this.setState({
                selected: [...new Set([...this.state.selected, ...listUniqueId])].sort(),
            });
        } else {
            this.setState({ selected: this.state.selected.filter(it => !listUniqueId.includes(it)) });
        }
    }

    deleteNotifications(selectedNotifications) {
        API.post(Constants.NOTIFICATIONS_URL + "/delete", { notifications: selectedNotifications }).then((result) => {
            this.props.updateNotificationHistory()
        });
    }

    changeStatusNotifications(selectedNotifications) {
        API.post(Constants.NOTIFICATIONS_URL + "/status", { notifications: selectedNotifications }).then((result) => {
            this.props.updateNotificationHistory()
        });
    }

    render() {
        const { notifications, selected } = this.state;

        const paginationOptions = {
            custom: true,
            totalSize: notifications.length
        };

        const expandRow = {
            renderer: row => (
                <NotificationDetailsContainer row={row} />
            ),
        };

        const NotificationDetailsContainer = ({ row }) => {
            return (
                <>
                    <Row className="mb-2 mx-1">
                        <Col className="mt-2" sm="12" md="12">
                            <NotificationDescriptionCard description={row.description} />
                        </Col>
                    </Row>
                    <Row className="mb-2 mx-1">
                        <Col className="mt-2" sm="12" md="7">
                            <EventDetailsCard details={deviceTypeDetails(row)} />
                        </Col>
                        <Col className="mt-2" sm="12" md="5">
                            <DeviceDetailsCard deviceTypeUrl={deviceTypeDetailsUrl(row)} deviceUuid={deviceUuid(row)} details={deviceTypeDetails(row)} />
                        </Col>
                    </Row>
                </>
            )
        }

        const deviceTypeDetails = (row) => {
            // eslint-disable-next-line default-case
            switch (row.deviceTypes) {
                case APIConstants.DEVICE_TYPES.PC:
                    return row.pcEventRow;
                case APIConstants.DEVICE_TYPES.NETWORK:
                    return row.networkEventRow;
                case APIConstants.DEVICE_TYPES.CONTROLPANEL:
                    return row.controlPanelEventRow;
            }
        }

        const deviceUuid = (row) => {
            // eslint-disable-next-line default-case
            switch (row.deviceTypes) {
                case APIConstants.DEVICE_TYPES.PC:
                    return row.pcEventRow.deviceUuid;
                case APIConstants.DEVICE_TYPES.NETWORK:
                    return row.networkEventRow.deviceUuid;
                case APIConstants.DEVICE_TYPES.CONTROLPANEL:
                    return row.controlPanelEventRow.controlPanelUuid;
            }
        }

        const deviceTypeDetailsUrl = (row) => {
            // eslint-disable-next-line default-case
            switch (row.deviceTypes) {
                case APIConstants.DEVICE_TYPES.PC:
                    var pcdevice = row.pcEventRow.software === "SAFETICA" ? "/pc-details-s" : "/pc-details-dl"
                    return pcdevice
                case APIConstants.DEVICE_TYPES.NETWORK:
                    return "/network-details";
                case APIConstants.DEVICE_TYPES.CONTROLPANEL:
                    return "/control-panel-details";
            }
        }

        const NotificationDescriptionCard = ({ description, withoutBottomDivider }) => {
            return (
                <Card className="mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2 mb-2">
                        <div className="detail-row-container">
                            <div className="small-padding">
                                <div className="details-row-title">Opis</div>
                                <div className="details-row-content">{description}</div>
                                <div className="details-row-title"> </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )
        }

        const EventDetailsCard = ({ details }) => {
            return (
                <Card className="mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        <EventCardRows rows={details.eventDetails.rows} />

                        <CardFooter className="text-center">
                            <Button onClick={() => this.props.proceduresModal(details.ruleDetails)} outline theme="info">
                                Wyświetl Procedurę
                            </Button>
                        </CardFooter>
                    </CardBody>
                </Card>
            )
        }

        const EventCardRows = ({ rows }) => {
            return rows.map((element, index) => {
                if (element.value.length) {
                    return <EventCardRow key={index} title={element.label} content={element.value} />
                }

                return null;
            })
        }

        const EventCardRow = ({ title, content, withoutBottomDivider }) => {
            return (
                <div className="detail-row-container">
                    <div className="small-padding">
                        <div className="details-row-title">{title}</div>
                        <div className="details-row-content">{content}</div>
                    </div>
                    {!withoutBottomDivider && <div className="divider" />}
                </div>
            )
        }

        const DeviceDetailsCard = ({ deviceTypeUrl, deviceUuid, details }) => {
            return (
                <Card className="mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        <EventCardRows rows={details.deviceDetails.rows} />

                        <CardFooter className="text-center">
                            <Button onClick={() => this.redirectToDetails(deviceTypeUrl, deviceUuid)} outline theme="info">
                                Szczegóły urządzenia
                            </Button>
                        </CardFooter>
                    </CardBody>
                </Card>
            )
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (!row.status) {
                    this.changeStatusNotifications([row.uniqueId]);
                }
            }
        };

        const selectRowProp = {
            mode: 'checkbox',
            clickToExpand: true,
            headerColumnStyle: { border: '0' },
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
            selected: selected
        };

        const BoostrapTableWithPagination = () => {
            return (
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Row className="mr-0 ml-0">
                            <Col xs="12" className="mb-2 pr-0 pl-0">
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField="uniqueId"
                                    data={notifications}
                                    columns={this.props.notificationColumns}
                                    expandRow={expandRow}
                                    classes="table mb-0"
                                    headerClasses="column-header"
                                    bordered={false}
                                    defaultSorted={[{ dataField: "uuid", order: "asc" }]}
                                    rowStyle={{ height: '10px' }}
                                    selectRow={selectRowProp}
                                    hover
                                    wrapperClasses="table-responsive"
                                    rowEvents={rowEvents}
                                    {...paginationTableProps} />
                            </Col>
                            <Col xs="6" className="d-flex justify-content-start">
                                <div className="ml-2 pl-3"><SizePerPageDropdownStandalone {...paginationProps} /></div>
                            </Col>
                            <Col xs="6" className="d-flex justify-content-end">
                                <div className="mr-2 pr-3"><PaginationListStandalone {...paginationProps} /></div>
                            </Col>
                        </Row>
                    )}
                </PaginationProvider>
            )
        }


        const renderInvoicesError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto">
                    <span className="text-semibold text-fiord-blue">Brak danych</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <div>
                {notifications.length > 0 ? BoostrapTableWithPagination() : renderInvoicesError()}
                <Col xs="12" className="d-flex justify-content-end">
                    <div>
                        <ButtonGroup className="mr-2 mb-3">
                            <Button theme="info" onClick={() => this.changeStatusNotifications(selected)} className="d-flex align-middle" disabled={!selected.length > 0} style={{outline: "none"}}><FontAwesomeIcon icon={faEnvelopeOpen} className="mr-1" />Odczytaj wybrane</Button>
                            <Button theme="info" onClick={() => this.deleteNotifications(selected)} className="d-flex align-middle" disabled={!selected.length > 0}><FontAwesomeIcon icon={faTrash} className="mr-1" />Usuń wybrane</Button>
                        </ButtonGroup>
                    </div>
                </Col>
            </div>
        );
    }
}

NotificationHistoryList.defaultProps = {
    notificationColumns: [{
        dataField: 'eventDate',
        text: 'Data',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row) => {
            var eventDate = new Date(cell);
            return dateFormat(eventDate, "dd-mm-yyyy HH:MM");
        }
    }, {
        dataField: 'title',
        text: 'Tutuł',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true
    }, {
        dataField: 'deviceTypes',
        text: 'Typ urządzenia',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row) => {
            // eslint-disable-next-line default-case
            switch (cell) {
                case APIConstants.DEVICE_TYPES.PC:
                    return "Urządzenie PC"
                case APIConstants.DEVICE_TYPES.NETWORK:
                    return "Urządzenie Sieciowe"
                case APIConstants.DEVICE_TYPES.CONTROLPANEL:
                    return "Centrala"
            }
        }
    }, {
        dataField: 'status',
        text: 'Status',
        headerClasses: 'border-0',
        headerAlign: "center",
        align: "center",
        sort: true,
        formatter: (cell, row) => {
            // eslint-disable-next-line default-case
            switch (cell) {
                case true:
                    return <i className="far fa-envelope-open"></i>
                case false:
                    return <i className="far fa-envelope"></i>
            }
        }
    }]
}


export default NotificationHistoryList;