import React from "react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { PHONE_NUMBER_REGEXP } from './../../../utils/Patterns';

import {
    Row,
    Col,
    FormInput,
    FormCheckbox,
    Alert
} from "shards-react";

export default class AddSmsAuthenticatorTokenForm extends React.Component {

    constructor() {
        super();
        this.state = {
            stage: 1,
            uuid: 0,
            token: "",
            show: false,
            form: {
                name: '',
                phoneNumber: '',
                tokenAdditionalInfo: '',
                tokenState: true,
                tokenType: "SMS"
            },
            formErrors: {
                name: [],
                phoneNumber: [],
                tokenAdditionalInfo: []
            },
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    inputTokenChangeHandler = (event) => {
        this.setState({ token: event.target.value });
    }

    inputChangeHandler = (event) => {
        var { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form: form });
    }

    checkboxChangeHandler = (event) => {
        var { form } = this.state;
        form.tokenState = !form.tokenState;
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, tokenAdditionalInfo, phoneNumber } = this.state.form;

        formErrors["name"] = [];
        formErrors["phoneNumber"] = [];
        formErrors["tokenAdditionalInfo"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (tokenAdditionalInfo.length > 100) {
            formErrors["tokenAdditionalInfo"].push("Informacje dodatkowe powinya składać się maksymalnie z 100 znaków");
            errorCount++;
        }

        if (!PHONE_NUMBER_REGEXP.test(phoneNumber)) {
            formErrors["phoneNumber"].push("Podany numer telefonu jest niepoprawny");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = () => {
        const { form, token, uuid } = this.state;

        if (this.state.stage === 1) {
            if (this.validForm()) {
                API.post(Constants.AUTHENTICATOR_TOKEN_URL, {twoFactorOperation: this.props.twoFactorOperation, form: form}).then((result) => {
                    if (result.status === 200) {
                        this.setState({ stage: 2, uuid: result.data.uuid });
                    }
                });
            }
        } else if (this.state.stage === 2) {
            API.post(Constants.AUTHENTICATOR_TOKEN_URL + "/validation", { uuid: uuid, token: token }).then((result) => {
                if (result.status === 200) {
                    this.setState({ stage: 3 });
                }
            }).catch((error) => { this.setState({ show: true }) });
        } else {
            this.props.toggle();
        }
    }

    renderSucces() {
        return (
            <Row>
                <Col sm="12">
                    <label>Token został dodany pomyślnie</label>
                </Col>
            </Row>
        )
    }

    renderToken() {
        const { token, show, form } = this.state;
        return (
            <Row>
                <Col sm="12">
                    <label>Wpisz 6-cyfrowy kod wygenerowany z : {form.name}</label>
                    <FormInput name="token" value={token || ''} onChange={this.inputTokenChangeHandler} className="mb-2" />
                    <Alert theme="danger" open={show}>Niepoprawny token</Alert>
                </Col>
            </Row>
        )
    }

    renderAdd() {
        const { name, phoneNumber, tokenAdditionalInfo, tokenState } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasPhoneNumberError = Boolean(this.state.formErrors.phoneNumber.length);
        var hasTokenAdditionalInfoError = Boolean(this.state.formErrors.tokenAdditionalInfo.length);

        return (
            <Row>
                <Col sm="12">
                    <label>*Nazwa Tokenu</label>
                    <FormInput name="name" value={name || ''} onChange={this.inputChangeHandler} invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>*Numer</label>
                    <FormInput name="phoneNumber" value={phoneNumber || ''} onChange={this.inputChangeHandler} invalid={hasPhoneNumberError}
                        className={hasPhoneNumberError ? "mb-0" : "mb-2"} />
                    {hasPhoneNumberError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.phoneNumber)}</ul>}


                    <label>Informacje dodatkowe</label>
                    <FormInput name="tokenAdditionalInfo" value={tokenAdditionalInfo || ''} onChange={this.inputChangeHandler} invalid={hasTokenAdditionalInfoError}
                        className={hasTokenAdditionalInfoError ? "mb-0" : "mb-2"} />
                    {hasTokenAdditionalInfoError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.tokenAdditionalInfo)}</ul>}

                    <FormCheckbox name="tokenState" toggle checked={tokenState} onChange={this.checkboxChangeHandler}> Aktywny </FormCheckbox>
                </Col>
            </Row>
        );
    }

    render() {

        return this.state.stage === 1 ?
            this.renderAdd() :
            this.state.stage === 2 ? this.renderToken() :
                this.renderSucces();
    }
}