import React from 'react';
import { ReactComponent as PhysicalPortIcon } from '../../images/icons/physical-port.svg';
import { ReactComponent as PortChannelIcon } from '../../images/icons/port-channel.svg';
import { ReactComponent as OpticalIcon } from '../../images/icons/optical.svg';
import { ReactComponent as VlanIcon } from '../../images/icons/vlan.svg';


const PortIndicator = ({ label, status, icon }) => {

    const iconWrapperStyle = {
        width: '100%',
        height: '100%',
        padding: '10%',
        border: `.2rem solid ${status === 'UP' ? 'green' : 'red'}`,
        borderRadius: '.3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    const containerPhysicalStyle = {
        width: `3rem`,
        height: `3rem`,
        maxWidth: '5rem',
        maxHeight: '5rem',
        margin: '1rem .5rem 1.5rem'
    };

    const svgStyle = {
        // width: '100%',
        // height: '100%'
    };

    const getIcon = () => {
        // eslint-disable-next-line default-case
        switch(icon) {
            case 'PHISICAL': return <PhysicalPortIcon style={svgStyle} />;

            case 'OPTICAL': return <OpticalIcon style={svgStyle} />;

            case 'PORTCHANNEL': return <PortChannelIcon style={svgStyle} />;

            case 'VLAN': return <VlanIcon style={svgStyle} />;
        }
    }

    return (
            <div style={containerPhysicalStyle} >
                <div style={iconWrapperStyle} >
                   {getIcon()}
                </div>
                <p style={{fontSize: '1rem', textAlign: 'center', paddinTop: '0.5rem'}}>{label}</p>
            </div>
    );
};

export default PortIndicator;