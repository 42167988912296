import React from "react";
import { Button, Card, CardBody, CardFooter, CardHeader } from "shards-react";
import DeleteAccountRequest from "./forms/DeleteAccountRequest";

class DeleteAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.formRef = input;
    }

    render() {
        return (
            <Card className="mb-3 w-100">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Usuwanie konta</h6>
                    <div className="block-handle" />
                </CardHeader>

                <CardBody className="py-1">
                    <div>
                        Usunięcie konta spowoduje utratę dostępu do serwisu.
                        Aby to wykonać należy kliknąć w link potwierdzający wysyłany na adres email użytkownika, następnie podać hasło do konta i potwierdzić.
                    </div>

                    <DeleteAccountRequest ref={this.setRef} />
                </CardBody>
                <CardFooter>
                    <Button outline theme="danger" className="mb-2 mr-1 float-right"
                        onClick={() => { this.formRef.submitForm(); }}>
                        Usuń
                    </Button>
                </CardFooter>
            </Card>
        );
    }

}

export default DeleteAccount;