import { toast } from 'react-toastify';
import React from "react";

function defaultMessage(title, text) {
    return <div className="toast-error">
      <div className="toast-title">{title}</div>
      <div className="toast-message">{text}</div>
      </div>
  }

export function error(title, text){
    toast.error(defaultMessage(title, text), {
        position: "top-right",
        autoClose: 7500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        }); 
}

export function success(title, text){
    toast.success(defaultMessage(title, text), {
        position: "top-right",
        autoClose: 7500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}