import React from 'react';
import * as Constants from "../constants"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faEdit, faMinusCircle, faCheckCircle, faTimesCircle, faExclamationTriangle, faBatteryEmpty } from '@fortawesome/free-solid-svg-icons';

export const getActionParameters = (status, action) => {
    var result = {
        status: status,
        action: action,
    }

    if (status === Constants.STATUSES.PENDING) {
        switch (action) {
            case Constants.ACTIONS.TO_ADD: return result = { ...result, content: "Oczekiwanie na dodanie", color: "#FFA500" }
            case Constants.ACTIONS.TO_DELETE: return { ...result, content: "Oczekiwanie na usunięcie", color: "#FFA500" }
            case Constants.ACTIONS.TO_EDIT: return { ...result, content: "Oczekiwanie na edycję", color: "#FFA500" }
            default: return;
        }
    } else {
        switch (status) {
            case Constants.STATUSES.ACCEPTED: return { ...result, content: "Dodany", color: "#009900" }
            case Constants.STATUSES.REJECTED: return { ...result, content: "Odrzucony", color: "#ff0000" }
            default: return { ...result, content: "", color: "#ffffff00" }
        }
    }
}

export const getIconByActionOrStatus = (value) => {
    switch (value) {
        case Constants.ACTIONS.TO_ADD: return React.createElement(FontAwesomeIcon, { icon: faPlusCircle, size: "lg", style: { color: "#FFA500" } }, null)
        case Constants.ACTIONS.TO_DELETE: return React.createElement(FontAwesomeIcon, { icon: faMinusCircle, size: "lg", style: { color: "#FFA500" } }, null)
        case Constants.ACTIONS.TO_EDIT: return React.createElement(FontAwesomeIcon, { icon: faEdit, size: "lg", style: { color: "#FFA500" } }, null)
        case Constants.STATUSES.ACCEPTED: return React.createElement(FontAwesomeIcon, { icon: faCheckCircle, size: "lg", style: { color: "#007bff" } }, null)
        case Constants.STATUSES.REJECTED: return React.createElement(FontAwesomeIcon, { icon: faTimesCircle, size: "lg", style: { color: "#ff0000" } }, null)
        default: return;
    }
}

export const getIconByWarningStatus = (value) => {
    switch (value) {
        case Constants.WARNING.DEAD: return React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle, size: "lg", style: { color: "#ff0000" } }, null)
        case Constants.WARNING.LOW_BATTERY: return React.createElement(FontAwesomeIcon, { icon: faBatteryEmpty, size: "lg", style: { color: "#ff0000" } }, null)
        default: return;
    }
}
