import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { pl } from './language/pl';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      pl: {
        translation: pl
      }
    },
    lng: "pl",
    interpolation: {
      escapeValue: false
    }
  }, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
  });

  export default i18n;
  