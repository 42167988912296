import React from 'react';
import DeleteAccountConfirmationForm from './forms/DeleteAccountConfirmationForm';

class DeleteAccountConfirmation extends React.Component {

    render() {
        const {
            deleteAccountToken,
            onSuccess
        } = this.props;

        return (
            <div>
                <h4 className="text-center">Usuwanie konta</h4>
                <DeleteAccountConfirmationForm deleteAccountToken={deleteAccountToken} onSuccess={onSuccess} />
            </div>
        );
    }

}

export default DeleteAccountConfirmation;