import React from "react";
import {
    Form,
} from "shards-react";
import Select from 'react-select';

import * as AppConstants from "../../../constants";
import { Store, Dispatcher, Constants } from "../../../flux";

class SidebarSelectCompany extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            form: {
                company: ''
            },
            formHints: {
                company: []
            }
        };

        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.onFluxChange = this.onFluxChange.bind(this);
        this.setCustomerCompanyFromLocalStorage = this.setCustomerCompanyFromLocalStorage.bind(this);
    }

    componentWillMount() {
        Store.addChangeListener(this.onFluxChange);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onFluxChange);
    }

    componentDidMount() {
        const { isSocAdmin } = this.state;
        if (isSocAdmin)
            this.fetchCompanies();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isSocAdmin } = this.state;


        if (isSocAdmin) {
            if ((JSON.stringify(prevState.formHints.company) !== JSON.stringify(this.state.formHints.company) || !this.state.formHints.company)) {
                this.fetchCompanies();
            }

            if (this.state.formHints.company.length > 0 && !this.state.formHints.company.map(company => company.value).includes(this.state.form.company.value)) {
                const customer = JSON.parse(localStorage.getItem("user"));
                const companySelected = localStorage.getItem("companySelected");

                const customerCompany = companySelected !== null && companySelected !== undefined ? this.state.formHints.company
                    .find(company => company.value === companySelected) : this.state.formHints.company
                        .find(company => company.value === customer.company.uuid);

                this.setState({ form: { ...this.state.form, company: '' } }, () => {
                    this.setCustomerCompanyFromLocalStorage({ uuid: customerCompany.value, name: customerCompany.label, privileges: customerCompany.privileges });
                })
            }
        }
    }

    onFluxChange() {
        var selectedCompany = Store.getSelectedCompany();
        var availableCompanies = Store.getCompanyList();

        this.setState({
            form: {
                ...this.state.form,
                company: selectedCompany
            },
            formHints: {
                ...this.state.formHints,
                company: availableCompanies
            }
        })
    }

    selectChangeHandler = (item, event) => {
        Dispatcher.dispatch({
            actionType: Constants.SET_SELECTED_COMPANY,
            payload: {
                selectedCompany: item
            }
        })
        localStorage.setItem("companySelected", item.value);
    }

    setCustomerCompanyFromLocalStorage(company) {
        if (!this.state.form.company && company) {
            Dispatcher.dispatch({
                actionType: Constants.SET_SELECTED_COMPANY,
                payload: {
                    selectedCompany: {
                        label: company.name,
                        value: company.uuid,
                        privileges: company.privileges
                    }
                }
            })
        }
    }

    fetchCompanies() {
        Dispatcher.dispatch({ actionType: Constants.UPDATE_COMPANY_LIST })
    }

    render() {
        const { isSocAdmin } = this.state;
        if (!isSocAdmin) return null;

        return (
            <Form className="main-sidebar__search w-100 d-sm-flex px-2 py-1" style={{ display: "flex", minHeight: "45px" }}>
                <Select
                    name="company"
                    value={this.state.form.company}
                    onChange={this.selectChangeHandler}
                    placeholder="Wybierz firmę"
                    options={this.state.formHints.company}
                    noOptionsMessage={() => "Brak dostępnych opcji"}
                    className='react-select-container mb-2'
                    classNamePrefix="react-select"
                    style={{ width: "100%" }} />
            </Form>
        );
    }

}

export default SidebarSelectCompany;
