import firebase from 'firebase/app';
import '@firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCaMBlwmAGVH9rzBPuiw1LDj2-y0lldDUY",
    authDomain: "soc-push.firebaseapp.com",
    databaseURL: "https://soc-push.firebaseio.com",
    projectId: "soc-push",
    storageBucket: "soc-push.appspot.com",
    messagingSenderId: "724022956283",
    appId: "1:724022956283:web:a41e118edd4e1163f5605e"
};

firebase.initializeApp(firebaseConfig);

let messaging;

// we need to check if messaging is supported by the browser
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
    messaging.onMessage((payload) => {
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
            registration.showNotification(
                payload.notification.title,
                payload.notification
            )
        });
    });
}

export {
    messaging
};
