import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button, Row, Col, ListGroup, ListGroupItem } from 'shards-react';
import { Store } from "../../flux";
import * as AppConstants from '../../constants';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../api/AxiosConfiguration";
import { getIconByWarningStatus, getIconByActionOrStatus, getActionParameters } from '../../utils/Actions';

import ConfirmControlPanelCameraModal from './modals/ConfirmControlPanelCameraModal';
import DeleteControlPanelCameraModal from './modals/DeleteControlPanelCameraModal';
import withLoader from '../common/hoc/withLoader';
import LinkButton from './../buttons/LinkButton';

import ControlPanelCameraStream from './ControlPanelCameraStream';

class ControlPanelCameras extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isCreateControlPanelCameraModalShow: false,
            isDeleteControlPanelCameraModalShow: false,
            selectedControlPanelCameraUUID: "",
            camerasData: [],
        };

        this.toggleCreateControlPanelCameraModal = this.toggleCreateControlPanelCameraModal.bind(this);
        this.toggleDeleteControlPanelCameraModal = this.toggleDeleteControlPanelCameraModal.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.fetchCameras = this.fetchCameras.bind(this);
        this.acceptCamera = this.acceptCamera.bind(this);
        this.declineCamera = this.declineCamera.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateComponent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleCreateControlPanelCameraModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateControlPanelCameraModalShow: !this.state.isCreateControlPanelCameraModalShow,
                selectedControlPanelCameraUUID: uuid,
            });
    }

    toggleDeleteControlPanelCameraModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteControlPanelCameraModalShow: !this.state.isDeleteControlPanelCameraModalShow,
                selectedControlPanelCameraUUID: uuid,
            });
    }

    updateComponent() {
        this.fetchCameras();
    }

    fetchCameras() {
        const { uuid } = this.props;
        this.props.setLoading(true);
        API.post(AppConstants.CONTROL_PANEL_CAMERAS_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    var camerasData = result.data;
                    if (this._isMounted)
                        this.setState({
                            camerasData: camerasData.cameras,
                        })
                }

                this.props.setLoading(false);
            })
    }

    acceptCamera(uuid) {
        API.get(AppConstants.CONTROL_PANEL_CAMERAS_URL + "/" + uuid + "/accept", {
            params: { uuid: this.props.uuid }
        }).then(result => {
            if (result.status === 200) {
                this.updateComponent();
            }
        });
    }

    declineCamera(uuid) {
        API.get(AppConstants.CONTROL_PANEL_CAMERAS_URL + "/" + uuid + "/decline", {
            params: { uuid: this.props.uuid }
        }).then(result => {
            if (result.status === 200) {
                this.updateComponent();
            }
        });
    }

    render() {
        const {
            isSocAdmin,
            isCreateControlPanelCameraModalShow,
            isDeleteControlPanelCameraModalShow,
            selectedControlPanelCameraUUID,
        } = this.state;

        const {
            uuid
        } = this.props;

        const paginationOptions = {
            custom: true,
            totalSize: this.state.camerasData.length,
            sizePerPage: 10
        };

        const CameraDetails = ({ details }) => {
            return (
                <Row>
                    <Col sm={12}>
                        <DetailsCard uuid={details.id} details={details.details} status={details.status} action={details.action} >
                            <Button onClick={() => this.toggleDeleteControlPanelCameraModal(details.id)}
                                disabled={details.action === AppConstants.ACTIONS.TO_DELETE} outline theme="danger" className="mb-2 mr-1">
                                Usuń urządzenie
                            </Button>
                            <Button onClick={() => this.toggleCreateControlPanelCameraModal(details.id)}
                                disabled={details.action === AppConstants.ACTIONS.TO_DELETE} outline theme="info" className="mb-2 mr-1">
                                Edytuj urządzenie
                            </Button>
                        </DetailsCard>
                    </Col>
                </Row>
            )
        }

        const SocAdminActions = ({ uuid, action }) => (
            <Row>
                <Col className="text-center">
                    <LinkButton onClick={() => this.acceptCamera(uuid)} style={{ color: "green", textDecoration: "underline", fontSize: "small" }}>Zaakceptuj</LinkButton>
                </Col>

                {action !== AppConstants.ACTIONS.TO_DELETE &&
                    <Col className="text-center">
                        <LinkButton onClick={() => this.declineCamera(uuid)} style={{ color: "red", textDecoration: "underline", fontSize: "small" }}>Odrzuć</LinkButton>
                    </Col>
                }
            </Row>
        )

        const DetailsCard = ({ uuid: cameraUuid, details, status, action, children }) => {
            const cameraStatus = getActionParameters(status, action);
            const isPending = status === AppConstants.STATUSES.PENDING;

            return (
                <Card className="h-100 mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        <CardRow title="Status" content={[cameraStatus.content]} contentColor={cameraStatus.color}>
                            {isSocAdmin && isPending && <SocAdminActions uuid={cameraUuid} action={action} />}
                        </CardRow>
                        <CardRows rows={details.rows} />

                        <ControlPanelCameraStream
                            controlPanelUuid={uuid}
                            cameraUuid={cameraUuid} />

                        <div className="divider" />

                        <div className="ml-auto mr-0">
                            {children}
                        </div>
                    </CardBody>
                </Card>
            )
        }

        const CardRows = ({ rows }) => {
            return rows.map((element, index) => {
                return <CardRow key={index} title={element.label} content={element.value} />
            })
        }

        const CardRow = ({ title, content, contentColor, withoutBottomDivider, children }) => {
            var isArrayWithSingleValue = Array.isArray(content) && content.length === 1;

            return (
                <div className="detail-row-container">
                    <div className="small-padding">
                        <div className="details-row-title">{title}</div>
                        {isArrayWithSingleValue ? <div className="details-row-content" style={{ color: contentColor }}>{content[0] ? content[0] : "-"}</div> : <ul>{renderArrayContent(content)}</ul>}
                        {children}
                    </div>
                    {!withoutBottomDivider && <div className="divider" />}
                </div>
            )
        }

        const renderArrayContent = (content) => {
            return content.map((contentItem, idx) => {
                return <li key={idx}>{contentItem}</li>
            })
        }

        const expandRow = {
            renderer: row => {
                return (
                    <CameraDetails details={row} />
                )
            }
        };

        const renderDeviceList = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={this.state.camerasData}
                                columns={this.props.deviceColumns}
                                classes="table mb-0"
                                bordered={false}
                                hover
                                expandRow={expandRow}
                                rowStyle={{ height: '10px' }}
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderEmptyDeviceListMessage = () => (
            <ListGroup flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Nie znaleziono żadnych kamer</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <React.Fragment>
                <Row className="mt-2 mb-2 position-relative">
                    {this.props.children}
                    <Col>
                        <Card className="mb-3">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Kamery</h6>
                                <div className="block-handle" />
                            </CardHeader>

                            <CardBody className="pt-2 pb-0 px-0">
                                <div className="mx-1">
                                    <Button onClick={() => this.toggleCreateControlPanelCameraModal()} className="mb-2" style={{ marginLeft: "16px" }} outline theme="info">
                                        Dodaj nową kamere
                                    </Button>
                                </div>

                                {this.state.camerasData.length ? renderDeviceList() : renderEmptyDeviceListMessage()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ConfirmControlPanelCameraModal open={isCreateControlPanelCameraModalShow} controlPanelUuid={uuid} uuid={selectedControlPanelCameraUUID}
                    toggle={this.toggleCreateControlPanelCameraModal} onSuccess={() => this.updateComponent()} />
                <DeleteControlPanelCameraModal open={isDeleteControlPanelCameraModalShow} controlPanelUuid={uuid} uuid={selectedControlPanelCameraUUID}
                    toggle={this.toggleDeleteControlPanelCameraModal} onSuccess={() => this.updateComponent()} />
            </React.Fragment>
        );
    }

}

export default withLoader(ControlPanelCameras);

ControlPanelCameras.propTypes = {
    deviceColumns: PropTypes.array
}

ControlPanelCameras.defaultProps = {
    deviceColumns: [
        {
            dataField: 'action',
            headerAlign: "center",
            align: "center",
            text: "Status",
            formatter: (cell, row) => {
                return <React.Fragment>{getIconByActionOrStatus(cell)} {getIconByWarningStatus(row.warning)}</React.Fragment>;
            }
        }, {
            dataField: 'name',
            headerAlign: "left",
            align: "left",
            text: "Nazwa",
        }, {
            dataField: 'group',
            headerAlign: "center",
            align: "center",
            text: "Grupa",
        }]
};