import React from "react";
import {
    Row,
    Col,
    FormInput,
    FormTextarea,
    FormCheckbox
} from "shards-react";

import * as AppConstants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import * as Constants from '../../../constants';
import { Store } from '../../../flux'
import { getCustomersForSelect } from '../../../api/ApiService';
import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'


class ConfirmProcedureApplicationForm extends React.Component {

    constructor() {
        super();
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            form: {
                name: '',
                content: '',
                group: '',
                isGlobal: false,
                manager: ''
            },
            formErrors: {
                name: [],
                content: [],
                group: [],
                manager: []
            },
            formHints: {
                group: [],
                manager: [],
            },
            isgroupSet: false,
            isOptionsLoading: false,
            isManagersLoading: true
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.findHintByValue = this.findHintByValue.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        const { isAdminAtLeast } = this.state;

        if (uuid.length) {
            this.fetchAvailableOptions(uuid, () => {
                if (isAdminAtLeast) {
                    this.fetchCompanyCustomers(() => {
                        this.fetchSafetyProcedureForm(uuid);
                    });
                } else {
                    this.fetchSafetyProcedureForm(uuid);
                }
            });
        } else {
            if (isAdminAtLeast) {
                this.fetchAvailableOptions(null, () => {
                    this.fetchCompanyCustomers();
                });
            } else {
                this.fetchAvailableOptions();
            }
        }
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        form[event.name] = item;
        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    checkboxChangeHandler(inputsToClearAfterChange) {
        var clearedElements = {}
        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        this.setState({
            form: {
                ...this.state.form,
                ...clearedElements,
                isGlobal: !this.state.form.isGlobal
            }
        });
    }

    validForm() {
        const { formErrors, isAdminAtLeast, isAdmin } = this.state;
        const { name, content, group, isGlobal, manager } = this.state.form;

        formErrors["name"] = [];
        formErrors["content"] = [];
        formErrors["group"] = [];
        formErrors["manager"] = [];

        var errorCount = 0;

        
        if (hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 50) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 50 znaków");
            errorCount++;
        }
        
        if (hasValueUrlOrHtmlTag(content)) {
            formErrors["content"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (content.length < 1) {
            formErrors["content"].push("Opis procedury nie powinien być pusty");
            errorCount++;
        }

        if (group && group.value.length < 3) {
            formErrors["group"].push("Grupa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }
        
        if (hasValueUrlOrHtmlTag(group)) {
            formErrors["group"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }


        if (isAdminAtLeast && !isAdmin) {
            if (!isGlobal && (!manager || manager == null)) {
                formErrors["manager"].push("Menedżer musi zostać wybrany");
                errorCount++;
            }
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;
        this.setState({
            formErrors: []
        })

        var requestBody = {
            form: {
                ...form,
                group: form.group ? form.group.value : '',
                manager: form.manager ? form.manager.value : ''
            }
        }

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(Constants.SAFETY_PROCEDURES_URL + "/" + uuid, requestBody)
            } else {
                apiPromise = API.put(Constants.SAFETY_PROCEDURES_URL, requestBody);
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const hintArray = formHints[inputName] ? formHints[inputName].filter(hint => hint.value === value) : ""
        return hintArray.length > 0 ? hintArray[0] : hintArray;
    }

    fetchAvailableOptions(uuid, callback) {
        API.get(Constants.SAFETY_PROCEDURES_URL + "/form/options/create").then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: result.data,
                    isOptionsLoading: false,
                }, callback)
            }
        });
    }

    fetchSafetyProcedureForm(uuid) {
        API.get(Constants.SAFETY_PROCEDURES_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: {
                        ...result.data,
                        group: this.findHintByValue("group", result.data.group),
                        manager: !result.data.isGlobal ? this.findHintByValue("manager", result.data.manager) : ""
                    }
                })
            }
        });
    }

    fetchCompanyCustomers = (callback) => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            }, callback)
        })
    }

    render() {
        const { uuid } = this.props;
        const { isAdminAtLeast, isAdmin } = this.state;
        const { name, group, content, isGlobal, manager } = this.state.form;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasContentError = Boolean(this.state.formErrors.content.length);
        var hasGroupError = Boolean(this.state.formErrors.group.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz lub utwórz nowy",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            isClearable: true
        }

        return (
            <Row>
                <Col sm="12">
                    <Row>
                        <Col>
                            <label>*Nazwa procedury</label>
                            <FormInput
                                style={{ height: '38px' }}
                                name="name"
                                value={name}
                                onChange={this.inputChangeHandler}
                                invalid={hasNameError}
                                className={hasNameError ? "mb-0" : "mb-2"} />
                            {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}
                        </Col>
                        <Col>
                            <label>*Grupa</label>
                            <AsyncCreatableSelect
                                {...defaultSelectProps}
                                name="group"
                                value={group}
                                isValidNewOption={(inputValue) => {
                                    const processedInputValue = inputValue ||  ""
                                    
                                    return processedInputValue.length >= 3 && processedInputValue.length <= 50 && !hasValueUrlOrHtmlTag(processedInputValue)
                                }}
                                onChange={this.selectChangeHandler}
                                defaultOptions={this.state.formHints.group}
                                className={hasGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "group")}
                                isLoading={this.state.isOptionsLoading} />
                            {hasGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.group)}</ul>}
                        </Col>
                    </Row>

                    {isAdminAtLeast &&
                        <Row>
                            <Col>
                                <label>Globalna?</label>
                                <FormCheckbox
                                    toggle
                                    small
                                    checked={isGlobal}
                                    onChange={() => this.checkboxChangeHandler(["manager"])}
                                    disabled={isEditingModeEnabled}>
                                </FormCheckbox>
                            </Col>
                            <Col>
                                <label>*Menedżer</label>
                                <AsyncSelect
                                    {...defaultSelectProps}
                                    name="manager"
                                    value={manager}
                                    onChange={this.selectChangeHandler}
                                    defaultOptions={this.state.formHints.manager}
                                    className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                    loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")}
                                    isLoading={this.state.isManagersLoading}
                                    placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"}
                                    isDisabled={isGlobal || isEditingModeEnabled} />
                                {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                            </Col>
                        </Row>
                    }

                    <label>*Opis procedury</label>
                    <FormTextarea
                        name="content"
                        size='300px'
                        style={{ height: '10em' }}
                        onChange={this.inputChangeHandler}
                        invalid={hasContentError}
                        value={content} />
                    {hasContentError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.content)}</ul>}

                </Col>
            </Row>
        );
    }
}

export default ConfirmProcedureApplicationForm;