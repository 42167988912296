import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import API from "../../../../api/AxiosConfiguration";
import * as Constants from '../../../../constants';
import { Store } from '../../../../flux';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      redirect: false,
      userName:""
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount(){
    this.setState({
      userName: JSON.parse(localStorage.getItem("user")).name
    });
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  
  signOut(){
    API.get(Constants.LOGOUT_URL).then(response => {
        localStorage.removeItem("user");
        localStorage.removeItem("companySelected");
        this.setState({redirect: true});
    }).catch((error) => {
      localStorage.removeItem("user");
      localStorage.removeItem("companySelected");
      this.setState({redirect: true});
    });
  }
  render() {
    const { redirect, userName } = this.state;

    const billingHistory = () => (
      <DropdownItem tag={Link} to="/billing-history">
      <i className="material-icons">&#xE896;</i> Historia Zamówień
      </DropdownItem>
    )

    if (redirect) {
      return <Redirect to='/login'/>;
    }
    return (
      <div>
        <div style={{height:'17%'}}></div>
        <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
          <DropdownToggle caret tag={NavLink} className="text-nowrap px-3 avatar-icon">
            <FontAwesomeIcon style={{marginBottom:'-4px'}} color="#CCCCCC" size={'2x'}  icon={faUserCircle}/>{" "}
            <span className="d-none d-md-inline-block">{userName}</span>
          </DropdownToggle>
          <Collapse tag={DropdownMenu} right small open={this.state.visible}>
            <DropdownItem tag={Link} to="/user-profile">
              <i className="material-icons">&#xE7FD;</i> Zmień Hasło
            </DropdownItem>
            <DropdownItem tag={Link} to="/edit-user-profile">
              <i className="material-icons">&#xE8B8;</i> Edycja Profilu
            </DropdownItem>
            <DropdownItem tag={Link} to="/authenticator-token">
              <i className="material-icons">&#xE896;</i> Tokeny
            </DropdownItem>
            <DropdownItem tag={Link} to="/help-desc">
              <i className="material-icons">&#xE2C7;</i> Pomoc Techniczna
            </DropdownItem>
            {((Store.getUserRole() === Constants.Roles.ADMIN) || (Store.getUserRole() === Constants.Roles.SOCADMIN))? billingHistory() : ""}
            <DropdownItem href={Constants.API_URL + Constants.CUSTOMERS_URL + "/userguide/pdf"} target="_blank">
              <i className="material-icons">&#xe85d;</i> Podręcznik użytkownika
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => {this.signOut() }}  className="text-danger">
              <i className="material-icons text-danger">&#xE879;</i> Wyloguj
            </DropdownItem>
          </Collapse>
        </NavItem>
      </div>
    );
  }
}
