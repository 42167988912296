import React from 'react';
import { ConfirmModal } from "../../common/Modal";
import BuySmsPackageForm from './../forms/BuySmsPackageForm';

class BuySmsPackageModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            tariff
        } = this.props;

        return (
            <ConfirmModal
                title="Kupowanie pakietu SMS"
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.buySmsPackageForm.submitForm(onSuccess); }}
                positiveButtonText="Przejdź do podsumowania"
                negativeButtonText="Anuluj">

                <BuySmsPackageForm
                    toggle={toggle}
                    ref="buySmsPackageForm"
                    uuid={uuid}
                    tariff={tariff} />

            </ConfirmModal>
        );
    }

}

export default BuySmsPackageModal;