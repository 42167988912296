import React from "react";
import { ConfirmModal } from "../../common/Modal";
import ConfirmControlPanelRuleForm from "../forms/ConfirmControlPanelRuleForm";

class ConfirmControlPanelRuleModal extends React.Component {

    constructor(props) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            isMobileView
        } = this.props;

        return (
            <ConfirmModal
                title={uuid && typeof (uuid) === "string" ? "Edytowanie reguły" : "Dodawanie nowej reguły"}
                open={open}
                toggle={toggle}
                submitAction={() => { this.childRef.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj" >

                <ConfirmControlPanelRuleForm toggle={toggle} ref={this.setRef} uuid={uuid} isMobileView={isMobileView}/>

            </ConfirmModal>
        );
    }
}

export default ConfirmControlPanelRuleModal;