import React from "react";
import { ConfirmModal } from "../../common/Modal";
import ConfirmNetworkRuleForm from "../forms/ConfirmNetworkRuleForm";

class ConfirmDeviceRuleModal extends React.Component {

    constructor(props) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            deviceType,
            uuid
        } = this.props;

        const renderConfirmFormForSpecifiedDeviceType = () => {
            return <ConfirmNetworkRuleForm toggle={toggle} ref={this.setRef} deviceType={deviceType} uuid={uuid} />
        }

        return (
            <ConfirmModal
                title={uuid && typeof (uuid) === "string" ? "Edytowanie reguły" : "Dodawanie nowej reguły"}
                open={open}
                toggle={toggle}
                submitAction={() => { this.childRef.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj" >

                {renderConfirmFormForSpecifiedDeviceType()}

            </ConfirmModal>
        );
    }
}

export default ConfirmDeviceRuleModal;