import React from 'react';

import { Row, Col, ListGroup, ListGroupItem, Button } from 'shards-react';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinus } from '@fortawesome/free-solid-svg-icons';

import ConfirmAppCategoryModal from './../modals/ConfirmAppCategoryModal';
import DeleteAppCategoryModal from './../modals/DeleteAppCategoryModal';

class SafeticaConfigurationAppCategoryTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isConfirmAppCategoryModalShow: false,
            isDeleteAppCategoryModalShow: false,
            selectedAppCategoryUuid: '',
            appCategories: []
        };

        this.updateComponent = this.updateComponent.bind(this);
        this.fetchSafeticaAppCategories = this.fetchSafeticaAppCategories.bind(this);
        this.toggleConfirmAppCategoryModal = this.toggleConfirmAppCategoryModal.bind(this);
        this.toggleDeleteAppCategoryModal = this.toggleDeleteAppCategoryModal.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    toggleConfirmAppCategoryModal(uuid = "") {
        this.setState({
            isConfirmAppCategoryModalShow: !this.state.isConfirmAppCategoryModalShow,
            selectedAppCategoryUuid: uuid
        })
    }

    toggleDeleteAppCategoryModal(uuid = "") {
        this.setState({
            isDeleteAppCategoryModalShow: !this.state.isDeleteAppCategoryModalShow,
            selectedAppCategoryUuid: uuid
        })
    }

    fetchSafeticaAppCategories() {
        return API.get(AppConstants.PC_SAFETICA_APP_CATEGORY_CONFIGURATION_URL);
    }

    updateComponent() {
        this.props.onFetchStart();
        this.fetchSafeticaAppCategories()
            .then(result => {
                this.setState({
                    appCategories: result.data.appCategories
                })
                this.props.onFetchComplete();
            })
    }

    render() {
        const { appCategories, isConfirmAppCategoryModalShow, isDeleteAppCategoryModalShow, selectedAppCategoryUuid } = this.state;

        const paginationOptions = {
            custom: true,
            totalSize: appCategories.length,
            sizePerPage: 10
        };

        const appCategoryColumns = [{
            dataField: 'name',
            align: "left",
            headerAlign: "left",
            text: "Nazwa",
        }, {
            dataField: 'appCount',
            align: "right",
            headerAlign: "right",
            text: "Ilość aplikacji",
        }, {
            dataField: 'actions',
            align: "right",
            headerAlign: "right",
            text: 'Akcje',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => (
                <div className="bootstrap-table-actions-col">
                    <Button size="sm" onClick={() => this.toggleConfirmAppCategoryModal(row.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>

                    <Button size="sm" theme="danger" className="ml-1" onClick={() => this.toggleDeleteAppCategoryModal(row.id)}>
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                </div>
            ),
        }]

        const renderAppCategories = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={appCategories}
                                columns={appCategoryColumns}
                                classes="table mb-0"
                                bordered={false}
                                hover
                                rowStyle={{ height: '10px' }}
                                wrapperClasses="table-responsive"
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderAppCategoriesFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <h6>Brak danych</h6>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <React.Fragment>
                <Button onClick={this.toggleConfirmAppCategoryModal} outline theme="info" className="mb-3 mr-1 mt-2">
                    Dodaj kategorie aplikacji
                </Button>

                { appCategories.length ? renderAppCategories() : renderAppCategoriesFetchError()}

                <ConfirmAppCategoryModal open={isConfirmAppCategoryModalShow} uuid={selectedAppCategoryUuid}
                    toggle={this.toggleConfirmAppCategoryModal} onSuccess={this.updateComponent} />
                <DeleteAppCategoryModal open={isDeleteAppCategoryModalShow} uuid={selectedAppCategoryUuid}
                    toggle={this.toggleDeleteAppCategoryModal} onSuccess={this.updateComponent} />
            </React.Fragment>
        );
    }

}

export default SafeticaConfigurationAppCategoryTable;