import React from 'react';
import { ConfirmModal } from "../../common/Modal";
import * as Constants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import * as socToast from '../../../utils/SocToast';

class DeleteDeviceGroupModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.deleteDeviceGroup = this.deleteDeviceGroup.bind(this);
    }

    deleteDeviceGroup(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(Constants.DEVICE_GROUPS_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    onSuccess();
                    socToast.success("Grupa została usunięta", "Pomyślnie usunięto grupę.");
                    toggleFunction();
                }
            }).catch(error => {
                var response = error.response;
                if (response) {
                    if (response.status === 409) {
                        socToast.error("Grupa nie została usunięta", "Przed usunięciem grupy należy upewnić się, że urządzenia, które się w niej znajdują zostały usunięte")
                    }
                }
            })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                title="Jesteś pewien, że chcesz usunąć grupę urządzeń?"
                open={open}
                toggle={toggle}
                submitAction={this.deleteDeviceGroup}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                Spowoduje to usunięcie wszystkich danych o grupie.
                Jeśli jesteś zdecydowany, kliknij "Potwierdź".
            </ConfirmModal>
        );
    }
}

export default DeleteDeviceGroupModal;
