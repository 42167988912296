import React from "react";
import {
    Row,
    Col,
    Button,
    FormGroup,
    FormInput,
    FormRadio,
    FormCheckbox,
    DatePicker,
} from "shards-react";

import Select from 'react-select';
import pl from 'date-fns/locale/pl';
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import SetArchivePasswordModal from "../modals/SetArchivePasswordModal";
import * as SocToast from '../../../utils/SocToast';
import { trackPromise } from 'react-promise-tracker';
import { Store } from "../../../flux";

class EventHistoryFilterForm extends React.Component {

    constructor(props) {
        super(props);
        this.allValuesLabel = 'Wszystkie'
        this.state = {
            form: {
                searchValue: '',
                searchValueIn: '',
                eventStartRangeDate: undefined,
                eventEndRangeDate: undefined,
                eventType: "",
                deviceType: "",
                userGroup: [],
                location: [],
                scheduleInterval: '',
                rules: []
            },
            formHints: {
                eventType: [],
                deviceType: [],
                userGroup: [],
                location: [],
                rules: []
            },
            formErrors: {
                searchValue: [],
                searchValueIn: [],
                scheduleInterval: [],
            },
            formErrorCount: 0,
            isReportPasswordModalShow: false,
            continuationAction: '',
            scheduledReportsEnabled: false,
        }

        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.multiSelectChangeHandler = this.multiSelectChangeHandler.bind(this);
        this.onScheduledReportsEnabled = this.onScheduledReportsEnabled.bind(this);
        this.fetchFilterOptions = this.fetchFilterOptions.bind(this);
        this.toggleReportPasswordModal = this.toggleReportPasswordModal.bind(this);
        this.onPasswordSet = this.onPasswordSet.bind(this);
        this.clearSchedulingForm = this.clearSchedulingForm.bind(this);

        var providedFilters = props.providedFilters;
        if (providedFilters) {
            this.state = {
                ...this.state,
                form: {
                    ...this.state.form,
                    eventStartRangeDate: providedFilters.eventStartRangeDate,
                    eventEndRangeDate: providedFilters.eventEndRangeDate,
                }
            }

            this.props.onChange(providedFilters.deviceType);
        }
    }

    componentDidMount() {
        this.fetchFilterOptions();

        if(this.props.rules && this.props.rules.length > 0) {
            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    rules: this.props.rules
                }
            }, () => this.fetchEvents());
        } else {
            this.fetchEvents();
        }


    }

    toggleReportPasswordModal(action = () => { }) {
        this.setState({
            isReportPasswordModalShow: !this.state.isReportPasswordModalShow,
            continuationAction: action,
        })
    }

    setStartDate = date => {
        this.setState({
            form: {
                ...this.state.form,
                eventStartRangeDate: date
            }
        })
    }

    setEndDate = date => {
        this.setState({
            form: {
                ...this.state.form,
                eventEndRangeDate: date
            }
        })
    }

    inputChangeHandler = (event) => {
        var { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form: form });
        this.validForm();
    }

    multiSelectChangeHandler = (selected, event, inputsToClearAfterChange) => {
        var { form } = this.state;

        var clearedElements = {}
        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        var item;
        if (Array.isArray(selected)) {
            item = selected.map(selectedItem => selectedItem.value);
        } else {
            item = selected
        }
        this.setState({
            form: {
                ...form,
                ...clearedElements,
                [event.name]: item
            },
        });
    }

    selectChangeHandler = (item, event) => {
        var { form } = this.state;
        form[event.name] = item ? item.value : '';
        this.setState({ form: form });
    }

    onScheduledReportsEnabled = () => {
        this.setState({ scheduledReportsEnabled: !this.state.scheduledReportsEnabled });
    }

    onPasswordSet() {
        this.state.continuationAction();
        this.toggleReportPasswordModal();
    }

    clearSchedulingForm() {
        const { form, formErrors } = this.state;
        this.setState({
            form: {
                ...form,
                scheduleInterval: ""
            },
            formErrors: {
                ...formErrors,
                scheduleInterval: []
            },
            formErrorCount: 0,
        });
    }

    validForm() {
        const { formErrors, scheduledReportsEnabled } = this.state;
        const { searchValue, searchValueIn, scheduleInterval } = this.state.form;

        formErrors["searchValue"] = [];
        formErrors["searchValueIn"] = [];
        formErrors["scheduleInterval"] = [];

        var errorCount = 0;
        if (searchValueIn.length > 0) {
            if (searchValue.length < 3 && searchValue.length > 0) {
                formErrors["searchValue"].push("Wyszukiwana wartość powinna składać się co najmniej z 3 znaków");
                errorCount++;
            }
        } else {
            if (searchValue.length > 0) {
                formErrors["searchValueIn"].push("Jedno z pól musi zostać wybrane");
                errorCount++;
            }
        }

        if (scheduledReportsEnabled) {
            if (scheduleInterval !== "12" && scheduleInterval !== "24" && scheduleInterval !== "48") {
                formErrors["scheduleInterval"].push("Jedno z pól musi zostać wybrane");
                errorCount++;
            }
        }

        this.setState({
            formErrors: formErrors,
            formErrorCount: errorCount,
        });
        return !errorCount;
    }

    fetchEvents = () => {
        const { form, formErrors } = this.state;
        const { redirected, queryParams } = this.props;

        if (this.validForm()) {
            let apiPromise;

            if(!redirected) {
              apiPromise = API.post(Constants.NETWORK_DEVICE_EVENTS_URL, {
                form: {
                  ...form,
                  eventType: form.eventType === this.allValuesLabel ? '' : form.eventType,
                  deviceType: form.deviceType === this.allValuesLabel ? '' : form.deviceType,
                  location: form.location === this.allValuesLabel ? '' : form.location,
                  userGroup: form.userGroup === this.allValuesLabel ? '' : form.userGroup,
                  rules: form.rules ? form.rules : []
                }
              });
            } else {
              if(queryParams.event) {
                apiPromise = API.post(`${Constants.NETWORK_DEVICE_SINGLE_EVENT_URL}/${queryParams.event}`);
              }
            }

            trackPromise(
                apiPromise.then((result) => {
                    if (result.status === 200) {
                        this.props.onSuccess(result.data);
                    }
                }).catch((error) => {
                    var response = error.response;
                    if (response && response.status === 400) {
                        response.data.errors.forEach(error => {
                            formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                        })
                        this.setState({ formErrors: formErrors });
                    }
                })
            );
        }
    }

    downloadXlsDocument = () => {
        if (this.validForm()) {
            this.dowloadFile(Constants.NETWORK_DEVICE_EVENTS_URL + "/xls");
        }
    }

    downloadPdfDocument = () => {
        if (this.validForm()) {
            this.dowloadFile(Constants.NETWORK_DEVICE_EVENTS_URL + "/pdf");
        }
    }

    dowloadFile(url) {
        const { form } = this.state;

        API({
            url: url,
            method: 'POST',
            data: {
                form: {
                    ...form,
                    eventType: form.eventType === this.allValuesLabel ? '' : form.eventType,
                    deviceType: form.deviceType === this.allValuesLabel ? '' : form.deviceType,
                    location: form.location === this.allValuesLabel ? '' : form.location,
                    userGroup: form.userGroup === this.allValuesLabel ? '' : form.userGroup,
                }
            },
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const [, filename] = response.headers['content-disposition'].split('filename=');
            const link = document.createElement('a');
            link.href = url;

            link.download = filename.slice(1, -1);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            var response = error.response;
            if (response) {
                if (response.status === 423) {
                    this.toggleReportPasswordModal(() => {
                        SocToast.success("Nadano hasło do archiwum", "Za chwilę plik zostanie pobrany")
                        this.dowloadFile(url)
                    });
                }
            }
        });
    }

    submitForm = () => {
        const { form, formErrors } = this.state;

        var requestBody = {
            scheduleInterval: parseInt(form.scheduleInterval, 10),
            networkEventFilters: {
                searchValue: form.searchValue,
                searchValueIn: form.searchValueIn,
                eventType: form.eventType === this.allValuesLabel ? '' : form.eventType,
                deviceType: form.deviceType === this.allValuesLabel ? '' : form.deviceType,
                location: form.location === this.allValuesLabel ? '' : form.location,
                userGroup: form.userGroup === this.allValuesLabel ? '' : form.userGroup,
            }
        }

        if (this.validForm()) {
            var apiPromise = API.put(Constants.SCHEDULED_EVENTS_URL, requestBody);
            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.onScheduledReportSaveSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }

                if (response && response.status === 423) {
                    this.toggleReportPasswordModal(() => {
                        SocToast.success("Nadano hasło do archiwum", "Za chwilę automatyczny raport zostanie zapisany")
                        this.submitForm();
                    });
                }

                if (response && response.status === 500) {
                    SocToast.error("Coś poszło nie tak", "Niestety nie udało się dodać automatycznego raportu. Spróbuj później lub skontaktuj się z administracją.")
                }
            });
        }
    }

    fetchFilterOptions = () => {
        API.get(Constants.NETWORK_DEVICE_EVENTS_URL + "/form/options/filter")
            .then((result) => {
                if (result.status === 200) {
                    this.setState({
                        formHints: result.data,
                    })
                }
            })
    }

    findOptionLabelByValue(propsName, value) {
        const options = this.state.formHints[propsName];
        var option = options.find(option => option.value === value)
        var label = option ? option.label : null;
        return label ? label : value;
    }

    findSelectLabelByValue = (name, value) => {
        var hint;
        var labels = [];
        var list;

        list = this.state.formHints[name];

        if (Array.isArray(value)) {
            value.forEach(valueArrayItem => {
                hint = list.find(item => valueArrayItem === item.value)
                if (hint)
                    labels.push(hint.label);
            })
        } else {
            hint = list.find(item => value === item.value)
            if (hint)
                labels = hint.label;
        }
        return labels;
    }

    render() {
        const { form, formErrorCount, isReportPasswordModalShow, scheduledReportsEnabled } = this.state;
        const { setMobileCaledar } = this.props;

        const hasSearchValueError = Boolean(this.state.formErrors.searchValue.length);
        const hasSearchValueInError = Boolean(this.state.formErrors.searchValueIn.length);
        const hasScheduleIntervalError = Boolean(this.state.formErrors.scheduleInterval.length);

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const UserGroupSelect = () => (
            <FormGroup>
                <label className="filter-label" htmlFor="userGroup">Użytkownicy:</label>
                <Select
                    isMulti
                    name="userGroup"
                    value={form.userGroup ? form.userGroup.map(userGroup => { return { label: this.findSelectLabelByValue("userGroup", userGroup), value: userGroup } }) : ""}
                    onChange={this.multiSelectChangeHandler}
                    placeholder="Wybierz"
                    formatCreateLabel={(inputText) => `Utwórz: "${inputText}"`}
                    options={this.state.formHints.userGroup}
                    noOptionsMessage={() => "Brak dostępnych opcji"}
                    loadingMessage={() => "Ładowanie"}
                    className='react-select-container mb-2'
                    classNamePrefix="react-select"
                    style={{ width: "100%" }}
                    isClearable />
            </FormGroup>
        )

        const RuleMultiSelect = () => (
            <FormGroup>
                <label className="filter-label" htmlFor="rules">Reguły:</label>
                <Select
                    isMulti
                    name="rules"
                    value={form.rules ? form.rules.map(rule => { return { label: this.findSelectLabelByValue("rules", rule), value: rule } }) : ""}
                    onChange={this.multiSelectChangeHandler}
                    placeholder="Wybierz"
                    options={this.state.formHints.rules}
                    noOptionsMessage={() => "Brak dostępnych opcji"}
                    loadingMessage={() => "Ładowanie"}
                    className='react-select-container mb-2'
                    classNamePrefix='react-select'
                    style={{ width: '100%' }} />
            </FormGroup>
        )

        return (
            <>
                <Row className="px-3 pt-3">
                    <Col sm="12" md="4">
                        <div className="mr-2 ml-2">
                            <label className="filter-label" htmlFor="searchValue">Wyszukaj:</label>
                            <FormGroup>
                                <FormInput
                                    name="searchValue"
                                    value={form.searchValue || ''}
                                    onChange={this.inputChangeHandler}
                                    placeholder="Wpisz wyszukiwaną wartość"
                                    invalid={hasSearchValueError}
                                    className={hasSearchValueError ? "mb-0" : "mb-2"} />
                                {hasSearchValueError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.searchValue)}</ul>}
                            </FormGroup>

                            <FormGroup>
                                <label className="filter-label" htmlFor="searchValueIn">Uwzględnij w:</label>
                                <FormRadio
                                    name="searchValueIn"
                                    value="DEVICE_NAME"
                                    onChange={this.inputChangeHandler}
                                    invalid={hasSearchValueInError}
                                    checked={form.searchValueIn === "DEVICE_NAME"} >Nazwa urządzenia</FormRadio>

                                <FormRadio
                                    name="searchValueIn"
                                    value="GROUP_NAME"
                                    onChange={this.inputChangeHandler}
                                    invalid={hasSearchValueInError}
                                    checked={form.searchValueIn === "GROUP_NAME"}
                                    className={hasSearchValueError ? "mb-0" : "mb-2"}>Nazwa grupy </FormRadio>

                                <FormRadio
                                    name="searchValueIn"
                                    value="DEVICE_IP"
                                    onChange={this.inputChangeHandler}
                                    invalid={hasSearchValueInError}
                                    checked={form.searchValueIn === "DEVICE_IP"}
                                    className={hasSearchValueError ? "mb-0" : "mb-2"}>Adres IP </FormRadio>
                                {hasSearchValueInError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.searchValueIn)}</ul>}
                            </FormGroup>
                        </div>
                    </Col>

                    <Col sm="12" md="8">
                        <FormGroup>
                            <label className="filter-label" htmlFor="eventStartRangeDate">Okres wystąpień zdarzeń:</label>
                            <div className="input-daterange input-group">
                                <DatePicker
                                    name="eventStartRangeDate"
                                    locale={pl}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    placeholderText="Data rozpoczęcia"
                                    selected={form.eventStartRangeDate}
                                    onChange={date => this.setStartDate(date)}
                                    selectsStart
                                    startDate={form.eventStartRangeDate}
                                    endDate={form.eventEndRangeDate}
                                    withPortal={setMobileCaledar}
                                    showTimeSelect />

                                <DatePicker
                                    name="eventEndRangeDate"
                                    locale={pl}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    placeholderText="Data zakończenia"
                                    selected={form.eventEndRangeDate}
                                    onChange={date => this.setEndDate(date)}
                                    selectsEnd
                                    startDate={form.eventStartRangeDate}
                                    endDate={form.eventEndRangeDate}
                                    minDate={form.eventStartRangeDate}
                                    withPortal={setMobileCaledar}
                                    showTimeSelect />
                            </div>
                        </FormGroup>

                        <Row>
                            <Col sm="12" md="6">
                                {Store.getUserRole() !== Constants.Roles.USER ? <UserGroupSelect /> : ""}

                                <FormGroup>
                                    <label className="filter-label" htmlFor="deviceType">Typ urządzenia:</label>
                                    <Select
                                        name="deviceType"
                                        value={{ label: this.findOptionLabelByValue("deviceType", form.deviceType), value: form.deviceType }}
                                        onChange={this.selectChangeHandler}
                                        placeholder="Wybierz"
                                        formatCreateLabel={(inputText) => `Utwórz: "${inputText}"`}
                                        options={this.state.formHints.deviceType}
                                        noOptionsMessage={() => "Brak dostępnych opcji"}
                                        loadingMessage={() => "Ładowanie"}
                                        className='react-select-container mb-2'
                                        classNamePrefix="react-select"
                                        style={{ width: "100%" }}
                                        isClearable />
                                </FormGroup>

                                <RuleMultiSelect />
                            </Col>
                            <Col sm="12" md="6">
                                <FormGroup>
                                    <label className="filter-label" htmlFor="location">Lokalizacja:</label>
                                    <Select
                                        isMulti
                                        name="location"
                                        value={form.location ? form.location.map(location => { return { label: this.findSelectLabelByValue("location", location), value: location } }) : ""}
                                        onChange={this.multiSelectChangeHandler}
                                        placeholder="Wybierz"
                                        formatCreateLabel={(inputText) => `Utwórz: "${inputText}"`}
                                        options={this.state.formHints.location}
                                        noOptionsMessage={() => "Brak dostępnych opcji"}
                                        loadingMessage={() => "Ładowanie"}
                                        className='react-select-container mb-2'
                                        classNamePrefix="react-select"
                                        style={{ width: "100%" }}
                                        isClearable />
                                </FormGroup>
                                <FormGroup>
                                    <label className="filter-label" htmlFor="typeOfEvent">Typ zdarzenia:</label>
                                    <Select
                                        name="eventType"
                                        value={{ label: this.findOptionLabelByValue("eventType", form.eventType), value: form.eventType }}
                                        onChange={this.selectChangeHandler}
                                        placeholder="Wybierz"
                                        formatCreateLabel={(inputText) => `Utwórz: "${inputText}"`}
                                        options={this.state.formHints.eventType}
                                        noOptionsMessage={() => "Brak dostępnych opcji"}
                                        loadingMessage={() => "Ładowanie"}
                                        className='react-select-container mb-2'
                                        classNamePrefix="react-select"
                                        style={{ width: "100%" }}
                                        isClearable />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="px-3 pt-3 mb-1">
                    <Col sm="12" md="5" >
                        {scheduledReportsEnabled &&
                            <>
                                <div className="d-flex justify-content-start align-items-center schedule-timing-form">
                                    <label label className="m-0 mr-1">Częstotliwość odświeżania:</label>
                                    <FormRadio inline
                                        name="scheduleInterval"
                                        value="12"
                                        onChange={this.inputChangeHandler}
                                        checked={form.scheduleInterval === "12"}
                                        invalid={hasScheduleIntervalError}
                                        className={hasScheduleIntervalError ? "mb-0" : "mb-2"}>12h</FormRadio>

                                    <FormRadio inline
                                        name="scheduleInterval"
                                        value="24"
                                        onChange={this.inputChangeHandler}
                                        checked={form.scheduleInterval === "24"}
                                        invalid={hasScheduleIntervalError}
                                        className={hasScheduleIntervalError ? "mb-0" : "mb-2"}>24h</FormRadio>

                                    <FormRadio inline
                                        name="scheduleInterval"
                                        value="48"
                                        onChange={this.inputChangeHandler}
                                        checked={form.scheduleInterval === "48"}
                                        invalid={hasScheduleIntervalError}
                                        className={hasScheduleIntervalError ? "mb-0" : "mb-2"}>48h</FormRadio>
                                </div>
                                {hasScheduleIntervalError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.scheduleInterval)}</ul>}
                            </>
                        }
                    </Col>

                    <Col sm="12" md="7" className="d-flex justify-content-end align-items-center">
                        <FormCheckbox
                            inline
                            className="m-0 mr-1"
                            name="scheduledReportsEnabled"
                            checked={scheduledReportsEnabled}
                            onChange={() => { this.onScheduledReportsEnabled(); this.clearSchedulingForm(); }}>Automatyczny</FormCheckbox>

                        <Button
                            onClick={() => {
                                if (!scheduledReportsEnabled) {
                                    this.fetchEvents();
                                    this.props.onChange(this.state.form.deviceType);
                                } else {
                                    this.submitForm();
                                }
                            }}
                            disabled={formErrorCount > 0}
                            outline
                            theme="info"
                            className="mr-1">{scheduledReportsEnabled ? "Zapisz" : "Filtruj"}</Button>

                        {!scheduledReportsEnabled &&
                            <>
                                <Button
                                    onClick={() => { this.fetchEvents(); this.props.onChange(this.state.form.deviceType); this.downloadPdfDocument() }}
                                    outline
                                    theme="info"
                                    disabled={formErrorCount > 0}
                                    className="mr-1">Wygeneruj PDF</Button>

                                <Button
                                    onClick={() => { this.fetchEvents(); this.props.onChange(this.state.form.deviceType); this.downloadXlsDocument() }}
                                    outline
                                    theme="info"
                                    disabled={formErrorCount > 0}>Wygeneruj XLS</Button>
                            </>
                        }
                    </Col>
                </Row>

                <SetArchivePasswordModal open={isReportPasswordModalShow}
                    toggle={this.toggleReportPasswordModal} onSuccess={this.onPasswordSet} />
            </>
        )
    }
}

export default EventHistoryFilterForm;
