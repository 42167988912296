class MinValidator {

    constructor(minValue) {
        this.minValue = minValue;
    }

    valid(value) {
        return !value || value >= this.minValue
    }

}

export default MinValidator;