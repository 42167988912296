export default {
  CHANGE: "CHANGE",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  SET_MOBILE_VIEW: "SET_MOBILE_VIEW",
  REFRESH_NEW_MESSAGES_COUNT: "REFRESH_NEW_MESSAGES_COUNT",
  REFRESH_NEW_NOTIFICATIONS_COUNT: "REFRESH_NEW_NOTIFICATIONS_COUNT",
  UPDATE_COMPANY_LIST: "UPDATE_COMPANY_LIST",
  UPDATE_DASHBOARD_LIST: "UPDATE_DASHBOARD_LIST",
  SET_DASHBOARD_TO_DELETE: "SET_DASHBOARD_TO_DELETE",
  SET_SELECTED_COMPANY: "SET_SELECTED_COMPANY",
  SET_SELECTED_MANAGER: "SET_SELECTED_MANAGER",
  SET_SELECTED_DASHBOARD: "SET_SELECTED_DASHBOARD",
  SET_USER_ROLE: "SET_USER_ROLE",
  TOGGLE_DASHBOARD_MODAL: "TOGGLE_DASHBOARD_MODAL",
  SET_NEW_DASHBOARD_NAME: "SET_NEW_DASHBOARD_NAME",
  TILE_FORCE_UPDATE: "TILE_FORCE_UPDATE"
};
