import React from "react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { Row, Col } from "shards-react";
import Select from 'react-select';

class ConfirmCompanyForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                customer: '',
            },
            formErrors: {
                customer: [],
            },
            formHints: {
                customer: [],
            },
            isOptionsLoading: true,
        };

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        form[event.name] = item;
        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { customer } = this.state.form;

        formErrors["customer"] = [];

        var errorCount = 0;

        if (customer.length <= 0) {
            formErrors["customer"].push("Użytkownik musi zostać wybrany");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;
        this.setState({
            formErrors: []
        })

        var requestBody = {
            form: {
                customer: form.customer.value
            }
        }

        if (this.validForm()) {
            var apiPromise;

            apiPromise = API.post(Constants.CUSTOMERS_URL + "/delete/" + uuid, requestBody)

            apiPromise.then((result) => {
                if (result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    fetchOptions(uuid) {
        API.get(Constants.CUSTOMERS_URL + "/form/options/delete?uuid=" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        customer: result.data.customer
                    }
                })
            }
        });
    }

    updateComponent() {
        const { uuid } = this.props;
        if (uuid.length) {
            this.fetchOptions(uuid);
        }
    }

    findSelectItemByValue(optionName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[optionName].find(option => option.uuid === value);
        if (foundOption)
            return {
                label: foundOption.name,
                value: foundOption.uuid
            }

        return ''
    }

    render() {
        const { customer } = this.state.form;
        const { formHints } = this.state;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasCustomerError = Boolean(this.state.formErrors.customer.length);

        const defaultSelectProps = {
            placeholder: "Wybierz",
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        return (
            <Row>
                <Col sm="12">
                    <label>*Użytkownik</label>
                    <Select
                        {...defaultSelectProps}
                        name="customer"
                        value={customer}
                        onChange={this.selectChangeHandler}
                        className={hasCustomerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        options={formHints.customer} />
                    {hasCustomerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.customer)}</ul>}
                </Col>
            </Row>
        );
    }

}

export default ConfirmCompanyForm;