import React from "react";
import { ConfirmModal } from "../../common/Modal";

import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import * as socToast from '../../../utils/SocToast';

class DeleteControlPanelCameraModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.deleteControlPanelCamera = this.deleteControlPanelCamera.bind(this);
    }

    deleteControlPanelCamera() {
        const {
            onSuccess,
            controlPanelUuid,
            uuid,
            toggle
        } = this.props;

        var axiosConfig = {
            params: {
                uuid: controlPanelUuid,
            }
        }

        API.delete(Constants.CONTROL_PANEL_CAMERAS_URL + "/" + uuid, axiosConfig)
            .then(result => {
                if (result.status === 200) {
                    onSuccess();
                    socToast.success("Powodzenie", "Urządzenie zostało usunięte");
                    toggle();
                }
            })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                title="Jesteś pewien, że chcesz wysłać prośbę o usunięcie urządzenia?"
                open={open}
                toggle={toggle}
                submitAction={this.deleteControlPanelCamera}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                Spowoduje to usunięcie wszystkich danych o urządzeniu.
                Twoja prośba zostanie rozpatrzona w ciągu kilku dni roboczych.
                Jeśli jesteś zdecydowany, kliknij "Potwierdź".
            </ConfirmModal>
        )
    }
}

export default DeleteControlPanelCameraModal;