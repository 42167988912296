import * as AppConstants from "../constants";

export const hasPrivilege = (wantedPrivilege) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user.privileges.some(privilege => privilege === wantedPrivilege);
}

export const hasPrivilegeToDlpSoftware = (wantedDlpSoftware) => {
    var wantedPrivilege;
    switch (wantedDlpSoftware) {
        case "DEVICELOCK":
            wantedPrivilege = AppConstants.Privileges.DEVICE_SECURITY_DEVICELOCK
            break;
        case "SAFETICA":
            wantedPrivilege = AppConstants.Privileges.DEVICE_SECURITY_SAFETICA
            break;
        default:
            wantedPrivilege = "";
    }

    return hasPrivilege(wantedPrivilege);
}
