import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Dispatcher, Constants, Store } from './flux';

export const PrivateRoute = (props) => {

    const checkRole = (user) => {
        var userRole = user.role;

        if (typeof props.authorities !== 'undefined' && props.authorities !== null)
            return props.authorities && props.authorities.indexOf(userRole) !== -1

        return true;
    }

    const checkPrivileges = (user) => {
        var userPrivileges = user.privileges;

        var containsPrivilege = false;
        if (props.privileges) {
            props.privileges.forEach((itemPrivilege) => {
                if (userPrivileges && userPrivileges.includes(itemPrivilege)) {
                    containsPrivilege = true;
                }
            });
        } else {
            containsPrivilege = true;
        }

        return containsPrivilege;
    }

    const authorizeUser = () => {
        var user = JSON.parse(localStorage.getItem('user'));

        if (user && user.role) {
            if (user.role !== Store.getUserRole()) {
                Dispatcher.dispatch({
                    actionType: Constants.SET_USER_ROLE,
                    payload: {
                        role: user.role
                    }
                });
            }

            if (checkRole(user) && checkPrivileges(user)) {
              return props.children;
            } else {
                return <Redirect to='/not-found' />
            }
        }

        return <Redirect to={{
          pathname: '/login',
          state: {
            redirect: {
              pathname: props.location.pathname,
              search: props.location.search
            }
          }
        }} />
    }

    return (
        <Fragment>
            {authorizeUser()}
        </Fragment>
    )

}
