import { getGlobalProperties } from "../api/ApiService";

export const reCaptchaSiteKey = "6LfP6o0dAAAAAFoKtY_7DubkYP1OqhErui4-yL6Z"

export const PROPERTIES = {
    DEVICE_SCANNING_ENABLED: "DEVICE_SCANNING_ENABLED"
}

class Configuration {

    constructor() {
        this.propertiesPromise = getGlobalProperties()
    }

    async getProperties() {
        const props = await this.propertiesPromise;
        return props;
    }

    async getProperty(name) {
        const props = await this.propertiesPromise;
        return props.filter(property => property.name === name)[0];
    }

}

export default new Configuration();
