import React from "react";
import {
    Row,
    Col,
    FormInput,
    DatePicker,
} from "shards-react";

import API from "../../../../api/AxiosConfiguration";
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import pl from 'date-fns/locale/pl';
import * as AppConstants from '../../../../constants';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { MAC_REGEXP } from "../../../../utils/Patterns";
import { IP_REGEXP } from './../../../../utils/Patterns';
import * as socToast from '../../../../utils/SocToast';
import { Store } from '../../../../flux'
import { getCustomersForSelect } from '../../../../api/ApiService';
import { trackPromise } from 'react-promise-tracker';

import { hasValueUrlOrHtmlTag } from '../../../../utils/Patterns'

class ConfirmDeviceApplicationForm extends React.Component {

    constructor() {
        super();
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            form: {
                name: '',
                ip: '',
                mac: '',
                user: '',
                group: '',
                location: '',
                endWarranty: undefined,
                manufacturer: '',
                model: '',
                support: '',
                manager: '',
                safeticaId: null
            },
            formErrors: {
                name: [],
                ip: [],
                mac: [],
                group: [],
                manager: [],
                safeticaId: []
            },
            formHints: {
                group: [],
                location: [],
                manufacturer: [],
                model: [],
                support: [],
                manager: []
            },
            selectedTemplate: "",
            formTemplates: [],
            formTemplateErrors: [],
            isTemplatesLoading: true,
            isManagersLoading: true,
            isOptionsLoading: false,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.dateChangeHandler = this.dateChangeHandler.bind(this);
        this.selectTemplateChangeHandler = this.selectTemplateChangeHandler.bind(this);
        this.deleteCustomerTemplate = this.deleteCustomerTemplate.bind(this);
        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
        this.findHintByValue = this.findHintByValue.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        const { isAdminAtLeast } = this.state;

        if (uuid.length) {
            this.fetchAvailableOptions(uuid, () => {
                this.fetchDeviceApplicationForm(uuid);
            });
        } else {
            if (isAdminAtLeast) {
                this.fetchAvailableOptions();
                this.fetchCompanyCustomers();
            } else {
                this.fetchAvailableOptions();
            }
        }

        this.fetchCustomerTemplates();
    }

    componentDidUpdate(prevProps, prevState) {
        var prevManager = prevState.form.manager ? prevState.form.manager.value : "";
        var currentManager = this.state.form.manager ? this.state.form.manager.value : "";

        if (prevManager !== currentManager) {
            this.clearFormHints();
            this.fetchAvailableOptions();
        }
    }

    clearFormHints() {
        this.setState({
            formHints: {
                ...this.state.formHints,
                group: [],
                location: [],
                manufacturer: [],
                model: [],
                support: [],
            }
        })
    }

    selectTemplateChangeHandler = (template, event) => {
        const { uuid } = this.props;

        if (event.action !== "clear" && event.action !== "create-option")
            if (uuid && uuid.length) {
                this.setState({
                    form: {
                        ...this.state.form,
                        endWarranty: template.value.endWarranty,
                        manufacturer: template.value.manufacturer,
                        model: template.value.model,
                        support: template.value.support,
                    }
                });
            } else {
                this.setState({
                    form: template.value
                });
            }

        this.setState({
            selectedTemplate: (event.action !== "clear") ? template.label : "",
            formTemplateErrors: []
        });
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event) => {
        const { form, formErrors } = this.state;

        if (event.name !== "manager" && event.name !== "group" && event.name !== "location") {
            form[event.name] = item ? item.value : '';
        } else {
            this.setState({
                form: {
                    ...form,
                    [event.name]: item
                }
            });
            return;
        }

        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    dateChangeHandler = (date, formName) => {
        var { form, formErrors } = this.state;
        form[formName] = date ? date.getTime() : undefined;
        formErrors[formName] = [];
        this.setState({ form: form });
    }

    validTemplate() {
        const { selectedTemplate, formTemplateErrors } = this.state;

        formTemplateErrors.length = 0;

        var errorCount = 0;
        if (selectedTemplate.length <= 0) {
            formTemplateErrors.push("Nazwa szablonu nie może być pusta");
            errorCount++;
        }

        this.setState({ formTemplateErrors: errorCount ? formTemplateErrors : [] });
        return !errorCount;
    }

    validForm() {
        const { formErrors, isAdminAtLeast, isAdmin, isSocAdmin } = this.state;
        const { name, ip, mac, group, manager, safeticaId } = this.state.form;
        const { uuid } = this.props;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        formErrors["name"] = [];
        formErrors["group"] = [];
        formErrors["ip"] = [];
        formErrors["mac"] = [];
        formErrors["manager"] = [];
        formErrors["safeticaId"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if(hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (!group || !group.value) {
            formErrors["group"].push("Nie wybrano grupy");
            errorCount++;
        }

        if (!IP_REGEXP.test(ip)) {
            formErrors["ip"].push("Adres IP jest niepoprawny");
            errorCount++;
        }

        if (!MAC_REGEXP.test(mac)) {
            formErrors["mac"].push("Adres MAC jest niepoprawny");
            errorCount++;
        }

        if (!isAdmin && !isEditingModeEnabled && isAdminAtLeast && (!manager || manager.length <= 0)) {
            formErrors["manager"].push("Użytkownik musi zostać wybrany");
            errorCount++;
        }

        if (isSocAdmin && isEditingModeEnabled) {
            if (safeticaId != null && safeticaId.length !== 0 && (!safeticaId && safeticaId.length <= 0)) {
                formErrors["safeticaId"].push("Id nie zostało ustawione poprawnie");
                errorCount++;
            }
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors, isSocAdmin } = this.state;
        const { uuid } = this.props;
        this.setState({
            formTemplateErrors: []
        })

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.PC_DEVICE_SAFETICA_URL + "/" + uuid, {
                    action: AppConstants.ACTIONS.TO_EDIT,
                    form: {
                        ...form,
                        safeticaId: isSocAdmin && form.safeticaId && form.safeticaId !== 0 ? form.safeticaId : null,
                        group: form.group && form.group.value,
                        location: form.location && form.location.value
                    }
                })
            } else {
                apiPromise = API.put(AppConstants.PC_DEVICE_SAFETICA_URL + "/create", {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: {
                        ...form,
                        group: form.group && form.group.value,
                        manager: form.manager && form.manager.value,
                        location: form.location && form.location.value
                    }
                });
            }

            trackPromise(apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Urządzenie zostało dodane", "Urządzenie zostało dodane poprawnie i oczekuje na zatwierdzenie.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            }));
        }
    }

    submitTemplate = () => {
        const { selectedTemplate, form, formTemplateErrors } = this.state;
        this.setState({
            formErrors: {
                name: [],
                ip: [],
                mac: [],
                group: [],
                manager: [],
                safeticaId:[]
            }
        })

        if (this.validTemplate()) {
            API.put(AppConstants.PC_DEVICE_SAFETICA_TEMPLATE_URL, {
                name: selectedTemplate,
                action: AppConstants.ACTIONS.TO_ADD,
                template: form
            }).then((result) => {
                this.setState({ isTemplatesLoading: true });
                this.fetchCustomerTemplates();
                socToast.success("Powodzenie", "Udało się zapisać szablon.")
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    formTemplateErrors.length = 0;
                    response.data.errors.forEach(error => {
                        formTemplateErrors.push(error.defaultMessage);
                    })
                    this.setState({ formTemplateErrors: formTemplateErrors });
                }
            });
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchDeviceApplicationForm = (uuid) => {
        API.get(AppConstants.PC_DEVICE_SAFETICA_URL + "/form/" + uuid).then((result) => {
            if (result.status === 200) {
                var responseBody = result.data;

                if (responseBody.endWarranty) {
                    responseBody.endWarranty = new Date(responseBody.endWarranty).getTime();
                }

                this.setState({
                    form: {
                        ...result.data,
                        group: this.findHintByValue("group", result.data.group),
                        location: this.findHintByValue("location", result.data.location)
                    }
                })
            }
        });
    }

    fetchCompanyCustomers = () => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            })
        })
    }

    fetchCustomerTemplates = () => {
        API.get(AppConstants.PC_DEVICE_SAFETICA_TEMPLATE_URL).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formTemplates: result.data,
                    isTemplatesLoading: false
                })
            }
        });
    }

    fetchAvailableOptions = (uuid = "", callback = () => { }) => {
        const { isAdminAtLeast } = this.state;
        const { manager } = this.state.form;

        var axiosConfig;
        if (isAdminAtLeast) {
            if (Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
                if (!((manager && manager.value) || uuid)) return;
            }

            axiosConfig = {
                params: {
                    uuid: uuid,
                    manager: manager ? manager.value : ""
                }
            }
        }

        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.PC_DEVICE_SAFETICA_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                    },
                    isOptionsLoading: false,
                }, callback)
            }
        });
    }

    deleteCustomerTemplate = (uuid) => {
        API.delete(AppConstants.PC_DEVICE_SAFETICA_TEMPLATE_URL + `/${uuid}`).then((result) => {
            if (result.status === 200) {
                var templates = [...this.state.formTemplates];
                var index = templates.findIndex(template => template.uuid === uuid)
                if (index !== -1) {
                    templates.splice(index, 1);
                    this.setState({
                        selectedTemplate: "",
                        formTemplates: templates,
                        isTemplatesLoading: false
                    });
                }
            }
        });
    }

    render() {
        const { isAdminAtLeast, isAdmin, isSocAdmin } = this.state;
        const { name, ip, mac, user, group, location, endWarranty, manufacturer, model, support, manager, safeticaId } = this.state.form;
        const { uuid, isMobileView, deviceStatus, deviceAction } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasTemplateNameError = Boolean(this.state.formTemplateErrors.length);
        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasIpError = Boolean(this.state.formErrors.ip.length);
        var hasMacError = Boolean(this.state.formErrors.mac.length);
        var hasGroupError = Boolean(this.state.formErrors.group.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);
        var hasSafeticaIdError = Boolean(this.state.formErrors.safeticaId.length);

        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        const isEditingModeEnabled = Boolean(uuid && uuid.length);

        const isRejected = deviceStatus === AppConstants.STATUSES.REJECTED;
        const isRejectedWithToAddAction = isRejected && deviceAction === AppConstants.ACTIONS.TO_ADD;

        const TemplateOptionWithTrashComponent = props => {
            const { innerProps, innerRef, children } = props;

            const deleteItem = (event, itemName) => {
                event.stopPropagation();
                this.setState({
                    isTemplatesLoading: true
                })
                this.deleteCustomerTemplate(props.data.uuid);
                props.clearValue();
            }

            const optionStyles = {
                backgroundColor: Boolean(innerRef) ? "#DEEBFF" : "transparent",
                display: "flex",
                justifyContent: "space-between"
            }

            return (
                <div ref={innerRef} {...innerProps} className="react-select-option" style={optionStyles} >
                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>{children}</div>
                    <div className="react-select-option-trash-container">
                        {!children.startsWith("Utwórz") && <div onClick={event => deleteItem(event, children)}>
                            <FontAwesomeIcon icon={faTrash} className="react-select-option-trash-icon" />
                        </div>}
                    </div>
                </div>
            );
        };

        const filterTemplates = (inputValue) => {
            return this.state.formTemplates.filter(template =>
                template.name.toLowerCase().includes(inputValue.toLowerCase())
            ).map((template) => {
                return {
                    value: template.template,
                    label: template.name,
                    uuid: template.uuid
                }
            });
        }

        const filterTemplatesPromise = inputValue =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterTemplates(inputValue));
                }, 500);
            });

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz lub utwórz nowy",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            isClearable: true
        }

        return (
            <Row>
                <Col sm="12">
                    <label>Szablon</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        defaultOptions={this.state.formTemplates.map(item => { return { value: item.template, label: item.name, uuid: item.uuid } })}
                        onChange={this.selectTemplateChangeHandler}
                        className={hasTemplateNameError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isLoading={this.state.isTemplatesLoading}
                        isValidNewOption={(inputValue) => {
                            const processedInputValue = inputValue ||  ""
                            
                            return processedInputValue.length >= 3 && processedInputValue.length <= 50 && !hasValueUrlOrHtmlTag(processedInputValue)
                        }}
                        loadOptions={filterTemplatesPromise}
                        components={{ Option: TemplateOptionWithTrashComponent }} />
                    {hasTemplateNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formTemplateErrors)}</ul>}
                </Col>

                <Col sm="12" className="p-0">
                    <h6 className="m-0">Konfiguracja urządzenia</h6>
                    <div className="divider"></div>
                </Col>

                <Col sm="12" md="6">
                    <label>*Nazwa urządzenia</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        disabled={isEditingModeEnabled && !isRejectedWithToAddAction}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    {!isEditingModeEnabled && isAdminAtLeast &&
                        <>
                            <label>*Menedżer</label>
                            <AsyncSelect
                                {...defaultSelectProps}
                                name="manager"
                                value={manager}
                                onChange={this.selectChangeHandler}
                                defaultOptions={this.state.formHints.manager}
                                className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")}
                                isLoading={this.state.isManagersLoading}
                                placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"} />
                            {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                        </>
                    }

                    <label>*Adres IP</label>
                    <FormInput
                        name="ip"
                        value={ip || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasIpError}
                        disabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        className={hasIpError ? "mb-0" : "mb-2"} />
                    {hasIpError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ip)}</ul>}

                    <label>*Adres MAC</label>
                    <FormInput
                        name="mac"
                        value={mac || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasMacError}
                        disabled={(isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected) && !isRejectedWithToAddAction}
                        className={hasMacError ? "mb-0" : "mb-2"} />
                    {hasMacError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.mac)}</ul>}

                    <label>Użytkownik</label>
                    <FormInput
                        name="user"
                        value={user || ''}
                        onChange={this.inputChangeHandler}
                        disabled={(isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected) && !isRejectedWithToAddAction}
                        className="mb-2" />

                    <label>*Grupa</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="group"
                        value={group}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.group}
                        className={hasGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={(isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected) && !isRejectedWithToAddAction}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "group")}
                        isLoading={this.state.isOptionsLoading}
                        placeholder="Wybierz" />
                    {hasGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.group)}</ul>}

                    <label>Lokalizacja urządzenia</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="location"
                        value={location}
                        isValidNewOption={(inputValue) => {
                            const processedInputValue = inputValue ||  ""
                            
                            return processedInputValue.length >= 3 && processedInputValue.length <= 50 && !hasValueUrlOrHtmlTag(processedInputValue)
                        }}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.location}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "location")}
                        isLoading={this.state.isOptionsLoading} />
                </Col>

                <Col sm="12" md="6">
                    <label>Data zakończenia gwarancji urządzenia</label>
                    <DatePicker
                        selected={endWarranty}
                        startDate={endWarranty}
                        onChange={date => this.dateChangeHandler(date, "endWarranty")}
                        locale={pl}
                        dateFormat="dd-MM-yyyy"
                        withPortal={isMobileView}
                        className="mb-2"
                        disabled={(!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected) && !isRejectedWithToAddAction} />

                    <label>Producent</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="manufacturer"
                        value={manufacturer ? { label: manufacturer, value: manufacturer } : undefined}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.manufacturer.map(hint => { return { value: hint, label: hint } })}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manufacturer")}
                        isLoading={this.state.isOptionsLoading} />

                    <label>Model</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="model"
                        value={model ? { label: model, value: model } : undefined}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.model.map(hint => { return { value: hint, label: hint } })}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "model")}
                        isLoading={this.state.isOptionsLoading} />

                    <label>Kontakt do supportu</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="support"
                        value={support ? { label: support, value: support } : undefined}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.support.map(hint => { return { value: hint, label: hint } })}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "support")}
                        isLoading={this.state.isOptionsLoading} />

                    {isEditingModeEnabled && isSocAdmin &&
                        <>
                            <label>Id w Safetica</label>
                            <FormInput
                                type="number"
                                name="safeticaId"
                                value={safeticaId || ''}
                                onChange={this.inputChangeHandler}
                                disabled={!isEditingModeEnabled && !isSocAdmin}
                                className={hasSafeticaIdError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                            />
                            {hasSafeticaIdError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.safeticaId)}</ul>}
                        </>
                    }
                </Col>
            </Row>
        );
    }
}

export default ConfirmDeviceApplicationForm;