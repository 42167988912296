import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import DynamicMenu from "../menu/DynamicMenu";
import Select from 'react-select';
import ConfirmProcedureModal from "../safety-procedures/modals/ConfirmProcedureModal";
import SafetyProcedureList from "../safety-procedures/lists/SafetyProcedureList";
import API from "../../api/AxiosConfiguration";
import * as Constants from "../../constants";
import DeleteProcedureModal from "../safety-procedures/modals/DeleteProcedureModal";
import PreviewProcedureModal from "../safety-procedures/modals/PreviewProcedureModal";
import { Store } from '../../flux';
import withLoader from "../common/hoc/withLoader";

class SafetyProcedureManagementPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: '',
            },
            selectedGroup: "",
            isCreateSafetyProcedureModalShow: false,
            isDeleteSafetyProcedureModalShow: false,
            isPreviewSafetyProcedureModalShow: false,
            selectedSafetyProcedureUUID: '',
            selectedSafetyProcedureContent: '',
            procedureGroups: [],
            procedures: [],
        }

        this.toggleCreateSafetyProcedureModal = this.toggleCreateSafetyProcedureModal.bind(this);
        this.toggleDeleteSafetyProcedureModal = this.toggleDeleteSafetyProcedureModal.bind(this);
        this.togglePreviewSafetyProcedureModal = this.togglePreviewSafetyProcedureModal.bind(this);
        this.updateSafetyProcedures = this.updateSafetyProcedures.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({
                manager: Store.getSelectedManager()
            }, () => this.updateSafetyProcedures())
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const storedSelectedManager = Store.getSelectedManager();
        const selectedManager = this.state.manager;

        if (JSON.stringify(storedSelectedManager) !== JSON.stringify(selectedManager)) {
            if (this._isMounted)
                this.setState({
                    manager: storedSelectedManager
                }, () => this.updateSafetyProcedures())
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleCreateSafetyProcedureModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateSafetyProcedureModalShow: !this.state.isCreateSafetyProcedureModalShow,
                selectedSafetyProcedureUUID: uuid,
            });
    }

    toggleDeleteSafetyProcedureModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteSafetyProcedureModalShow: !this.state.isDeleteSafetyProcedureModalShow,
                selectedSafetyProcedureUUID: uuid,
            });
    }

    togglePreviewSafetyProcedureModal(procedureContent) {
        if (this._isMounted)
            this.setState({
                isPreviewSafetyProcedureModalShow: !this.state.isPreviewSafetyProcedureModalShow,
                selectedSafetyProcedureContent: procedureContent,
            });
    }

    selectChangeHandler = (item, event) => {
        var { form } = this.state;
        form[event.name] = item;
        var procedure = item.value;
        if (this._isMounted)
            this.setState({ form: form }, this.setActiveGroup(procedure.groupId));
    }

    setActiveGroup = (groupId) => {
        if (this.state.form.name) {
            this.state.procedureGroups.forEach((group) => {
                if (this._isMounted)
                    return group.uuid === groupId ? this.setState({ selectedGroup: group.name }) : ""
            });
        }
    }

    fetchSafetyProcedures() {
        return API.post(Constants.SAFETY_PROCEDURES_URL);
    }

    updateProcedureGroupName(form, uuid) {
        return API.put(Constants.SAFETY_PROCEDURE_GROUPS_URL + "/" + uuid, {
            form: form,
        })
    }

    updateSafetyProcedures() {
        this.props.setLoading(true);
        this.fetchSafetyProcedures().then((result => {
            if (result.status === 200) {
                var procedureGroups = result.data;
                var procedures = [].concat.apply([], procedureGroups.map(procedureGroup => procedureGroup.procedures));

                if (this._isMounted)
                    this.setState({
                        procedureGroups: procedureGroups,
                        procedures: procedures
                    })
                this.props.setLoading(false);
            }
        }));
    }

    render() {
        const {
            isCreateSafetyProcedureModalShow,
            isDeleteSafetyProcedureModalShow,
            isPreviewSafetyProcedureModalShow,
            selectedSafetyProcedureUUID,
            selectedSafetyProcedureContent,
            form,
            procedureGroups,
            procedures
        } = this.state;

        const defaultSelectProps = {
            placeholder: "Wybierz",
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        const shouldShowNoResultMessage = !procedures || procedures.length === 0;

        const generateTable = (display, procedures) => {
            if (display) {
                return (
                    <SafetyProcedureList
                        procedures={procedures}
                        onEdit={this.toggleCreateSafetyProcedureModal}
                        onDelete={this.toggleDeleteSafetyProcedureModal}
                        onPreview={this.togglePreviewSafetyProcedureModal} />
                );
            }
        }

        const renderNoResultMessage = () => (
            <div className="text-center py-3">
                Brak zdefiniowanych procedur bezpieczeństwa
            </div>
        )

        return (
            <Container fluid className="main-content-container">
                <Row className="mt-2 mb-2 position-relative">
                    {this.props.children}
                    <Col>
                        <Card>
                            <CardHeader className="border-bottom d-inline">
                                <h6 className="m-0">Procedury Bezpieczeństwa</h6>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="4">
                                        <Button
                                            outline theme="info"
                                            className="mb-2 mr-1"
                                            onClick={this.toggleCreateSafetyProcedureModal}>
                                            Dodaj nową procedurę
                                        </Button>
                                    </Col>

                                    <Col md="4" className="offset-md-4">
                                        <Select
                                            {...defaultSelectProps}
                                            name="name"
                                            options={procedures ? procedures.map(procedure => { return { label: procedure.name, value: procedure } }) : ""}
                                            placeholder="Wyszukaj..."
                                            value={form.name}
                                            onChange={this.selectChangeHandler}
                                            isDisabled={false} />
                                    </Col>
                                </Row>

                                {procedureGroups.map((procedureGroup, id) => {
                                    var group = procedureGroup;

                                    if (group.procedures && group.procedures.length > 0) {
                                        return (
                                            <div key={id}>
                                                <DynamicMenu
                                                    title={group.name}
                                                    noTitleEdit={true}
                                                    deleteEnable={false}
                                                    downChevron={this.state.selectedGroup !== group.name}
                                                    onClick={() => {
                                                        if (this.state.selectedGroup !== group.name) {
                                                            this.setState({ selectedGroup: group.name, form: { ...form, name: "" } })
                                                        } else {
                                                            this.setState({ selectedGroup: "", form: { ...form, name: "" } })
                                                        }
                                                    }}
                                                    uuid={group.uuid}>
                                                </DynamicMenu>
                                                {generateTable(this.state.selectedGroup === group.name, group.procedures)}
                                            </div>
                                        )
                                    }
                                    return ""
                                }
                                )}

                                {shouldShowNoResultMessage && renderNoResultMessage()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ConfirmProcedureModal open={isCreateSafetyProcedureModalShow} uuid={selectedSafetyProcedureUUID}
                    toggle={this.toggleCreateSafetyProcedureModal} onSuccess={this.updateSafetyProcedures} />

                <DeleteProcedureModal open={isDeleteSafetyProcedureModalShow} uuid={selectedSafetyProcedureUUID}
                    toggle={this.toggleDeleteSafetyProcedureModal} onSuccess={this.updateSafetyProcedures} />

                <PreviewProcedureModal open={isPreviewSafetyProcedureModalShow} content={selectedSafetyProcedureContent}
                    toggle={this.togglePreviewSafetyProcedureModal} />
            </Container>
        );
    }

}

export default withLoader(SafetyProcedureManagementPanel);