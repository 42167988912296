import React from "react";
import { InfoModal, ConfirmModal } from "../../common/Modal";


import TaskDetailsForm from "../forms/TaskDetailsForm"

class TaskDetailsModal extends React.Component {

    SelectModal(props) {
        if (props.status === "todo") {
            return <ConfirmModal {...props} />
        } else {
            return <InfoModal {...props} />
        }
    }

    render() {
        const {
            open,
            toggle,
            uuid,
            status,
            isMobileView,
            name,
            group,
            content,
            closeReportAction
        } = this.props;

        return (
            <this.SelectModal
                title={`Zgłoszenie #${uuid}`}
                open={open}
                toggle={toggle}
                status={status}
                submitAction={() => closeReportAction(uuid)}
                positiveButtonText="Zakończ"
                negativeButtonText="Zamknij"
                closeButtonText="Zamknij">

                <TaskDetailsForm
                    toggle={toggle}
                    ref="addSafetyProcedureForm"
                    uuid={uuid}
                    isMobileView={isMobileView}
                    procedureName={name}
                    procedureGroup={group}
                    procedureContent={content} />

            </this.SelectModal>
        )
    }
}

export default TaskDetailsModal;