import React from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";

import API from "../../../../api/AxiosConfiguration"
import * as APIConstants from "../../../../constants";
import { Redirect } from 'react-router-dom'

import { Dispatcher, Constants, Store } from "../../../../flux";
import RSC from "react-scrollbars-custom";

export default class NotificationsWidget extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      newNotificationsCount: 0,
      newNotificationsList: [],
      visible: false
    };

    this.refreshIntervalInMillis = 15000;

    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.shouldUpdateNewMessagesCount = this.shouldUpdateNewMessagesCount.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.shouldUpdateNewMessagesCount);
  }

  componentWillUnmount() {
    this._isMounted = false;
    Store.removeChangeListener(this.shouldUpdateNewMessagesCount);
  }

  componentDidMount() {
    this._isMounted = true;
    this.countNewMessages();
    this.interval = setInterval(() => {
      if (this._isMounted) {
        this.countNewMessages();
      }
    }, this.refreshIntervalInMillis);
  }

  setRedirect = () => {
    if (this._isMounted)
      this.setState({
        redirect: true,
        visible: false
      })
  }

  redirectToChat() {
    if (window.location.pathname !== "/notifications" && this.state.redirect)
      return <Redirect to='/notifications' />
  }

  countNewMessages() {
    Dispatcher.dispatch({
      actionType: Constants.REFRESH_NEW_NOTIFICATIONS_COUNT,
    })
  }

  shouldUpdateNewMessagesCount() {
    API.get(APIConstants.NOTIFICATIONS_URL + "/unreadcount")
      .then(response => response.data)
      .then(userSummary => {
        if (this._isMounted)
          this.setState({
            newNotificationsCount: userSummary.count,
            newNotificationsList: userSummary.notifications
          })
      })
      .catch(error => {
        if (this._isMounted)
          this.setState({ newNotificationsCount: 0 })
      })
  }

  toggleNotifications() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    const { newNotificationsCount, newNotificationsList } = this.state;

    const renderIcon = (type) => {
      // eslint-disable-next-line default-case
      switch (type) {
        case APIConstants.DEVICE_TYPES.PC:
          return <i className="fas fa-desktop"></i>
        case APIConstants.DEVICE_TYPES.NETWORK:
          return <i className="fas fa-network-wired"></i>
        case APIConstants.DEVICE_TYPES.CONTROLPANEL:
          return <i className="far fa-bell"></i>
      }
    }

    const renderNotification = (notification, index) => (
      <DropdownItem onClick={this.setRedirect}>
        <div className="notification__icon-wrapper">
          <div className="notification__icon">
            {renderIcon(notification.deviceTypes)}
          </div>
        </div>
        <div className="notification__content">
          <span className="notification__category">{notification.title}</span>
          <p>{notification.description.length > 111 ? notification.description.substr(0, 108) + '...' : notification.description}</p>
        </div>
      </DropdownItem>
    )

    return (
      <>
        <NavItem className="border-right dropdown notifications">
          <NavLink
            className="nav-link-icon text-center"
            onClick={newNotificationsCount > 0 ? this.toggleNotifications : this.setRedirect}
          >
            <div className="nav-link-icon__wrapper" style={{ cursor: "pointer" }}>
              <i className="material-icons">&#xE7F4;</i>
              {newNotificationsCount > 0 && <Badge pill theme="danger">
                {newNotificationsCount > 99 ? '99+' : newNotificationsCount}
              </Badge>}
            </div>
          </NavLink>
          <Collapse
            open={this.state.visible}
            className="dropdown-menu dropdown-menu-small"
          >
            <RSC style={{ height: '20rem', overflowY: 'auto' }}>
              {newNotificationsList.length > 0 && newNotificationsList.map((element, index) => renderNotification(element, index))}
            </RSC>
            <DropdownItem className="notification__all text-center" onClick={this.setRedirect}>
              Zobacz wszystkie powiadomienia
            </DropdownItem>
          </Collapse>
        </NavItem>
        {this.redirectToChat()}
      </>
    );
  }
}
