import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";
import { SECURE_PASSWORD_REGEXP } from "../../utils/Patterns";
import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import * as SocToast from '../../utils/SocToast';

function UserAccountDetails (props){
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] =  useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [newPasswordEqual, setNewPasswordEqual] = useState(true);
    const [strongPassword, setStrongPassword] = useState(true);
    const [wrongPassword, setWrongPassword] = useState(false);
    const [oldPasswordIsNull, setOldPasswordIsNull] = useState(false);

  

    const error = {
      color: "red",
      fontSize: 12,
      fontFamily: "Arial"
    };
    const redBorder = {
      border: `1px solid red`,
    };

    const newPasswordHandler = (value) =>{
      setNewPassword(value);
      setStrongPassword(SECURE_PASSWORD_REGEXP.test(value))
    }

    const repeatNewPasswordHandler = (value) =>{
      setRepeatNewPassword(value);
      checkNewPasswordEqual(value);
    }

    const checkNewPasswordEqual = (value) => {
      setNewPasswordEqual(newPassword === value) 
    }

    const validForm = () => {
      setNewPasswordEqual(repeatNewPassword === newPassword)
      setStrongPassword(SECURE_PASSWORD_REGEXP.test(newPassword))
      setOldPasswordIsNull(oldPassword==='')
      return (repeatNewPassword === newPassword && SECURE_PASSWORD_REGEXP.test(newPassword))
    }

    const sendHandler = () => {
        if (validForm()) {
            API.put(Constants.CHANGE_PASSWORD, {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                    repeatNewPassword: repeatNewPassword
            }).then((result) => {
                if (result.status === 200) {
                     SocToast.success("Hasło zostało zmienione.")
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 404) {
                  setWrongPassword(true)
                  SocToast.error("Wpisane hasło jest niepoprawne.")
                }
            });
        } else {
          SocToast.error("Weryfikacja danych nie powiadła się.");
        }
    }

    return(
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{props.title}</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="fePassword">Stare hasło</label>
                      <FormInput
                        type="password"
                        id="oldPassword"
                        style={wrongPassword?redBorder:null}
                        placeholder="Obecne hasło..."
                        value={oldPassword}
                        onChange={(item) => {setOldPassword(item.target.value); setOldPasswordIsNull(false); setWrongPassword(false)}}
                      />
                      {wrongPassword?<div style={error}>Wpisane hasło jest niepoprawne!</div>:''}
                      {oldPasswordIsNull ?<div style={error}>Pole nie może być puste!</div>:''}
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="fePassword">Nowe hasło</label>
                      <FormInput
                        type="password"
                        id="newPassword"
                        style={strongPassword?null:redBorder}
                        placeholder="Nowe hasło..."
                        value={newPassword}
                        onChange={(item) => {newPasswordHandler(item.target.value)}}
                      />
                      {strongPassword?'':<div style={error}>Hasło powinno zawierać przynajmniej 8 znaków w tym przynajmniej jedną mała oraz wielką literę, cyfrę oraz znak specjalny</div>}
                    </Col>
                  </Row> 
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="fePassword">Powtórz nowe hasło</label>
                      <FormInput
                        type="password"
                        id="repeatNewPassword"
                        style={newPasswordEqual?null:redBorder}
                        placeholder="Powtórz nowe hasło..."
                        value={repeatNewPassword}
                        onChange={(item) => {repeatNewPasswordHandler(item.target.value)}}
                      />
                      {newPasswordEqual?'':<div style={error}>Hasła nie są identyczne</div>}
                    </Col>
                  </Row> 
                  <Button onClick={() => sendHandler()} theme="accent">Zmień hasło</Button>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
}


UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Zmiana hasła"
};

export default UserAccountDetails;