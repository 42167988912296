import React from "react";
import { ConfirmModal } from "../../common/Modal";
import * as Constants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import { trackPromise } from 'react-promise-tracker';

class DeleteDeviceRuleModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteRule = this.deleteRule.bind(this);
    }

    deleteRule(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        trackPromise(
            API.delete(Constants.NETWORK_DEVICE_RULE_URL + "/" + uuid)
            .then(result => {
                if(result.status === 200) {
                    onSuccess();
                    toggleFunction();
                }
            })
        );
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz usunąć regułe?"
                    open={open}
                    toggle={toggle}
                    submitAction={this.deleteRule}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie wszystkich danych o regule.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          
            </ConfirmModal>
        )
    }
    
}

export default DeleteDeviceRuleModal;