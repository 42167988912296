import React from "react";
import {
    FormInput,
} from "shards-react";

import API from "../../../../api/AxiosConfiguration";
import * as Constants from '../../../../constants';
import * as socToast from '../../../../utils/SocToast';

class ConfirmDeviceForm extends React.Component {

    constructor() {
        super();
        this.state = {
            form: {
                safeticaId: null,
            },
            formErrors: {
                safeticaId: []
            },
        };
    }

    inputChangeHandler = (event) => {
        var { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form: form });
    }

    validForm() {
        const { type } = this.props
        const { formErrors } = this.state;
        const { safeticaId } = this.state.form;

        formErrors["safeticaId"] = [];

        var errorCount = 0;

        if (type === Constants.ACTIONS.TO_ADD) {
            if (safeticaId != null && safeticaId.length !== 0 && (!safeticaId && safeticaId.length <= 0)) {
                formErrors["safeticaId"].push("Id nie zostało ustawione poprawnie");
                errorCount++;
            }
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (uuid, onSuccess) => {
        const { form } = this.state;

        if (this.validForm()) {
            API.post(Constants.PC_DEVICE_SAFETICA_URL + "/" + uuid + "/accept", {
                safeticaId: form.safeticaId && form.safeticaId !== 0 ? form.safeticaId : null
            }).then((result) => {
                if (result.status === 200) {
                    // eslint-disable-next-line default-case
                    switch (this.props.type) {
                        case Constants.ACTIONS.TO_ADD:
                            socToast.success("Operacja przebiegła pomyślnie", "Urządzenie zostało dodane pomyślnie.");
                            break;
                        case Constants.ACTIONS.TO_EDIT:
                            socToast.success("Operacja przebiegła pomyślnie", "Urządzenie zostało zedytowane pomyślnie.");
                            break;
                        case Constants.ACTIONS.TO_DELETE:
                            socToast.success("Operacja przebiegła pomyślnie", "Urządzenie zostało usunięte pomyślnie.");
                            break;
                    }
                    this.props.toggle(uuid);
                    onSuccess();
                }
            }).catch(error => {
                var response = error.response;
                if (response) {
                    socToast.error("Błąd serwera", "Wystąpił błąd podczas wykonywania operacji.")
                }
            });
        }
    }

    render() {
        const {
            type
        } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasSafeticaIdError = Boolean(this.state.formErrors.safeticaId.length);

        return (
            <div>
                {type === Constants.ACTIONS.TO_ADD &&
                    <div className="mt-4 mr-4">
                        <label>Id urządzenia w Safetica:</label>
                        <FormInput name="safeticaId" type="number" onChange={this.inputChangeHandler}
                            className={hasSafeticaIdError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        />
                        {hasSafeticaIdError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.safeticaId)}</ul>}
                    </div>}
            </div>
        );
    }
}

export default ConfirmDeviceForm;