import React from "react";
import {
    Row,
    Col,
} from "shards-react";

import RuleCard from "../../../cards/RuleCard";
import Pagination from "react-js-pagination";

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';

class RuleList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rules: [],
            pagination: {
                activePage: 1,
                itemsPerPage: 9,
                totalItemsCount: 0,
                pageRangeDisplayed: 5,
            }
        }

        this.handlePageChange = this.handlePageChange.bind(this);
        this.declineDevice = this.declineDevice.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { sortOrder, sortField } = props.sortOptions;
        var rules = props.rules;
        if (sortOrder.length > 0 && sortField.length > 0) {
            rules = RuleList.sortRules(rules, sortOrder, sortField);
        }

        if (props.rules !== state.rules) {
            return {
                rules: rules,
                pagination: {
                    ...state.pagination,
                    totalItemsCount: rules.length
                }
            }
        }

        return null;
    }

    static sortRules(rules, order, field) {
        if (order === "ASC") {
            return this.sortAscending(rules, field);
        } else {
            return this.sortDescending(rules, field)
        }
    }

    static sortAscending(rules, field) {
        rules.sort((rule, nextRule) => {
            var ruleValueToCompare = String(this.getValueByField(rule, field));
            var nextRuleValueToCompare = String(this.getValueByField(nextRule, field));

            if (ruleValueToCompare < nextRuleValueToCompare)
                return -1;
            if (ruleValueToCompare > nextRuleValueToCompare)
                return 1;
            return 0;
        });

        return rules;
    }

    static sortDescending(rules, field) {
        rules.sort((rule, nextRule) => {
            var ruleValueToCompare = String(this.getValueByField(rule, field));
            var nextRuleValueToCompare = String(this.getValueByField(nextRule, field));

            if (nextRuleValueToCompare < ruleValueToCompare)
                return -1;
            if (nextRuleValueToCompare > ruleValueToCompare)
                return 1;
            return 0;
        });

        return rules
    }

    static getValueByField(rule, field) {
        switch (field) {
            case "name": return rule.name;
            case "status": return rule.status
            case "deviceGroup":
                const deviceGroup = this.getRuleDetailByKey(rule, "DEVICE_GROUP");
                return deviceGroup ? deviceGroup.value : "";
            case "device":
                const device = this.getRuleDetailByKey(rule, "DEVICE");
                return device ? device.value : "";
            default: return;
        }
    }

    static getRuleDetailByKey(deviceRule, key) {
        return deviceRule.details.find(details => details.key === key);
    }

    handlePageChange(pageNumber) {
        this.setState({
            pagination: {
                ...this.state.pagination,
                activePage: pageNumber
            }
        })
    }

    declineDevice(uuid) {
        API.get(AppConstants.PC_DEVICE_SAFETICA_RULE_URL + "/" + uuid + "/decline")
            .then(result => {
                if (result.status === 200) {
                    this.props.onAction();
                }
            });
    }

    render() {
        const { toggleConfirmRuleModal, toggleDeleteRuleModal, toggleCreateRuleModal } = this.props;
        const { rules } = this.state;
        const { activePage, itemsPerPage, totalItemsCount, pageRangeDisplayed } = this.state.pagination;

        const firstIndexToShow = (activePage * itemsPerPage) - itemsPerPage;
        const lastIndexToShow = activePage * itemsPerPage;

        var rulePage = rules;
        if (rules.length > firstIndexToShow)
            rulePage = rules.slice(firstIndexToShow, lastIndexToShow);

        return (
            <Row>
                {rulePage.map((rule, idx) => {
                    return (
                        <Col key={idx} md="6" lg="4" >
                            <RuleCard
                                id={idx}
                                name={rule.name}
                                status={rule.status}
                                action={rule.action}
                                withoutButtons={rule.status === "PENDING"}
                                ruleDetails={rule.details ? rule.details : []}
                                onDelete={() => toggleDeleteRuleModal(rule.uuid)}
                                onEdit={() => toggleCreateRuleModal(rule.uuid)}
                                onAcceptDevice={() => toggleConfirmRuleModal(rule.uuid, rule.action)}
                                onDeclineDevice={() => this.declineDevice(rule.uuid)} />
                        </Col>
                    )
                })}

                <Col xs="12" className="d-flex justify-content-end mt-2">
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={pageRangeDisplayed}
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        hideDisabled={true}
                        innerClass="pagination react-bootstrap-table-page-btns-ul mb-0" />
                </Col>
            </Row>
        )
    }
}

export default RuleList;
