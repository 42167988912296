import React from 'react';
import { Container, Row, Col } from "shards-react";
import EmailClientConfiguration from "../components/company-settings/EmailClientConfiguration"
import SmsPackage from '../components/company-settings/SmsPackage';
import NagiosConfiguration from './../components/company-settings/NagiosConfiguration';
import { hasPrivilege } from './../utils/PrivilegeUtils';
import { Store } from "../flux";
import * as AppConstants from './../constants';
import LogRetention from './../components/company-settings/LogRetention';
import CompanyLogs from "../components/company-settings/CompanyLogs";

class CompanySettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasNetworkSecurityPrivilege: false,
        };
    }

    componentDidMount() {
        this.setState({
            hasNetworkSecurityPrivilege: hasPrivilege(AppConstants.Privileges.NETWORK_SECURITY)
        });
    }

    render() {
        const { hasNetworkSecurityPrivilege } = this.state;

        const isSocAdmin = Store.getUserRole() === AppConstants.Roles.SOCADMIN;

        return (
            <Container fluid className="main-content-container px-4">
                <Row className="mt-2 mb-2 position-relative">
                    <Col className="mb-2" sm="12" md={isSocAdmin ? "12" : "6"}>
                        <EmailClientConfiguration />
                    </Col>

                  { !isSocAdmin && <Col className="mb-2" sm="12" md="6">
                    <SmsPackage/>
                  </Col>}
                </Row>
              <Row className="mt-2 mb-2 position-relative">
                    {hasNetworkSecurityPrivilege && <Col className="mb-2" sm="12" md="6">
                        <NagiosConfiguration />
                    </Col>}

                    <Col className="mb-2" sm="12" md="6">
                      { isSocAdmin && <LogRetention />}
                    </Col>
                </Row>
              <Row className="mt-2 mb-2 position-relative">
                <Col className="mb-2" sm="12" md="6" >
                  { isSocAdmin && <CompanyLogs/>}
                </Col>
              </Row>
            </Container>
        );
    }

}

export default CompanySettings;
