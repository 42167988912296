import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
} from "shards-react";
import BootstrapTable from "react-bootstrap-table-next";
import * as SocToast from '../../utils/SocToast';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../api/AxiosConfiguration";
import * as Constants from "../../constants";

import dateFormat from "dateformat";
import withLoader from "../common/hoc/withLoader";
import ControlPanelEventFilterForm from "./forms/ControlPanelEventFilterForm";
import { Store } from "../../flux";

class ControlPanelEvents extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            isAdminAtLeast: Store.getUserRole() !== Constants.Roles.USER,
            detectedEventRows: [],
            requiredFilterOptions: {},
            filters: {},
        }

        this.fetchDeviceEvents = this.fetchDeviceEvents.bind(this);
        this.updateControlPanelEvents = this.updateControlPanelEvents.bind(this);
        this.downloadPdfDocument = this.downloadPdfDocument.bind(this);
        this.downloadXlsDocument = this.downloadXlsDocument.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchDeviceEvents(this.state.filters);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchDeviceEvents(form) {
        const { uuid, controlPanel } = this.props;
        this.props.setLoading(true);
        if (controlPanel && controlPanel.dictionarySupported) {
            API.post(Constants.CONTROL_PANEL_DICTIONARY_EVENTS_URL + "/" + uuid, {
                form: form,
            }).then(result => {
                if (result.status === 200) {
                    var eventsInfo = result.data;
                    if (this._isMounted)
                        this.setState({
                            detectedEventRows: eventsInfo.row,
                            requiredFilterOptions: eventsInfo.options
                        })
                }

                this.props.setLoading(false);
            })
        } else {
            API.get(Constants.CONTROL_PANEL_EVENTS_URL + "/" + uuid)
                .then(result => {
                    if (result.status === 200) {
                        var events = result.data;
                        if (this._isMounted)
                            this.setState({
                                detectedEventRows: events,
                            })
                    }

                    this.props.setLoading(false);
                })
        }
    }

    downloadXlsDocument = () => {
            this.downloadFile("xls");
    }

    downloadPdfDocument = () => {
            this.downloadFile("pdf");
    }

    downloadFile(fileType) {
        const { filters } = this.state;
        const { uuid, passwordModal } = this.props;

        API({
            url: `${Constants.CONTROL_PANEL_EVENTS_URL}/details/events/${uuid}/${fileType}`,
            method: 'POST',
            data: {
                form: filters
            },
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const [, filename] = response.headers['content-disposition'].split('filename=');
            const link = document.createElement('a');
            link.href = url;

            link.download = filename.slice(1, -1);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            var response = error.response;
            if (response) {
                if (response.status === 423) {
                    passwordModal(() => {
                        SocToast.success("Nadano hasło do archiwum", "Za chwilę plik zostanie pobrany")
                        this.downloadFile(fileType)
                    });
                }
            }
        });
    }

    updateControlPanelEvents(newFilters = {}, save = false) {
        this.props.setLoading(true);
        if (save)
            this.setState({
                filters: newFilters
            }, () => this.fetchDeviceEvents(this.state.filters))
        else
            this.fetchDeviceEvents(this.state.filters)
    }

    getFilterOptions() {
        const { requiredFilterOptions, isAdminAtLeast } = this.state;

        var descriptions = requiredFilterOptions.descriptions;
        var partitions = requiredFilterOptions.partitions;
        var zones = requiredFilterOptions.zones;
        var expanders = requiredFilterOptions.expanders;
        var telephones = requiredFilterOptions.telephones;
        var users = requiredFilterOptions.users;

        var hints = {
            description: descriptions ? descriptions.map(description => { return { label: description, value: description } }) : null,
            partition: partitions ? partitions.map(partition => { return { label: partition, value: partition } }) : null,
            zone: zones ? zones.map(zone => { return { label: zone, value: zone } }) : null,
            expander: expanders ? expanders.map(expander => { return { label: expander, value: expander } }) : null,
            telephone: telephones ? telephones.map(telephone => { return { label: telephone, value: telephone } }) : null,
            user: users ? users.map(user => { return { label: user, value: user } }) : null,
        }

        if (isAdminAtLeast) {
            var managers = requiredFilterOptions.manager;
            hints = {
                ...hints,
                manager: managers
            }
        }

        return hints;
    }

    render() {
        const { detectedEventRows } = this.state;
        const { controlPanel } = this.props;

        const expandRow = {
            renderer: row => (
                <EventDetailsContainer row={row} />
            ),
        };

        const EventDetailsContainer = ({ row }) => {
            return (
                <Row className="mb-2 mx-1">
                    <Col className="mt-2" sm="12">
                        <EventDetailsCard details={row.eventDetails} ruleDetails={row.ruleDetails} />
                    </Col>
                </Row>
            )
        }

        const EventDetailsCard = ({ details, ruleDetails }) => {
            return (
                <Card className="h-100 mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        <EventCardRows rows={details.rows} />
                        <ProcedureCardRow ruleDetails={ruleDetails} />
                    </CardBody>
                </Card>
            )
        }

        const EventCardRows = ({ rows }) => {
            return rows.map((element, index) => {
                return <EventCardRow key={index} title={element.label} content={element.value} />
            })
        }

        const EventCardRow = ({ title, content, withoutBottomDivider }) => {
            return (
                <div className="detail-row-container">
                    <div className="small-padding">
                        <div className="details-row-title">{title}</div>
                        <div className="details-row-content">{content}</div>
                    </div>
                    {!withoutBottomDivider && <div className="divider" />}
                </div>
            )
        }

        const styles = {
            float: "left",
            display: "inline",
            marginTop: "8px",
        }

        const ProcedureCardRow = ({ ruleDetails, withoutBottomDivider }) => {
            return (
                <div className="detail-row-container">
                    <div className="small-padding">
                        <div className="details-row-title"></div>
                        <div className="details-row-content" style={styles}>Procedury</div>
                        <div className="text-right">
                            <Button onClick={() => this.props.proceduresModal(ruleDetails)} outline theme="info" className="mb-1 mr-1">
                                Wyświetl Procedurę
                            </Button></div>
                    </div>
                    {!withoutBottomDivider && <div className="divider" />}
                </div>
            )
        }

        const paginationOptions = {
            custom: true,
            totalSize: detectedEventRows.length,
            sizePerPage: 10
        };

        function eventDateFormatter(cell, row) {
            return (
                <span className="text-semibold text-fiord-blue" style={{ fontSize: ".8125rem" }}>{dateFormat(cell, "dd-mm-yyyy HH:MM")}</span>
            );
        }

        function eventDescriptionFormatter(cell, row) {
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell}</span>
            );
        }

        const detectedEventColumns = [{
            dataField: 'eventDate',
            align: "left",
            text: "",
            formatter: eventDateFormatter
        }, {
            dataField: 'description',
            align: "right",
            text: "",
            formatter: eventDescriptionFormatter
        }]

        const renderDeviceEvents = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={detectedEventRows}
                                columns={detectedEventColumns}
                                classes="table mb-0"
                                headerClasses="d-none"
                                bordered={false}
                                hover
                                expandRow={expandRow}
                                rowStyle={{ height: '10px' }}
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderDeviceEventsFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Brak danych</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card medium="true">
                        <CardHeader className="border-bottom">
                            <div className="d-flex align-items-center justify-content-between mr-5" >
                                <h6 className="m-0">Naruszenia</h6>
                                <div>
                                    <Button
                                        theme="info"
                                        outline
                                        className="mr-2"
                                        onClick={() => this.downloadPdfDocument()} >Wygeneruj PDF</Button>

                                    <Button
                                        theme="info"
                                        outline
                                        onClick={() => this.downloadXlsDocument()} >Wygeneruj XLS</Button>
                                </div>
                            </div>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="p-0">
                            {controlPanel && controlPanel.dictionarySupported && <ControlPanelEventFilterForm filterOptions={this.getFilterOptions()} onChangeFilter={this.updateControlPanelEvents} />}
                            {detectedEventRows.length ? renderDeviceEvents() : renderDeviceEventsFetchError()}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default withLoader(ControlPanelEvents);
