import React from "react";
import { Redirect } from 'react-router-dom'
import { Row, Col, Container } from "shards-react";
import API from "../api/AxiosConfiguration";
import * as Constants from "../constants"
import PhoneNumberVerifier from "../components/customer-registration/PhoneNumberVerifier";
import CustomerAccountActivation from "../components/customer-registration/CustomerAccountActivation";

import SocLogo from '../images/logo_SOC.png';
import ProjectLogos from '../images/loga_projektowe.png';

export class RegisterCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customerUUID: "",
            form: {
                email: '',
                phoneNumber: '',
                phoneNumberPrefix: ''
            },
            customerDetails: null,
            isMobile: false
        };

        this.fetchCustomerRegistrationDetails = this.fetchCustomerRegistrationDetails.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var customerUUID = props.match.params.uuid;

        if (customerUUID !== state.customerUUID) {
            return { customerUUID: customerUUID }
        }

        return null;
    }

    componentDidMount() {
        this.updateComponent();
    }

    fetchCustomerRegistrationDetails(uuid) {
        API.get(Constants.CUSTOMERS_URL + "/register/details/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    this.setState({
                        customerDetails: result.data
                    })
                }
            })
    }

    updateComponent() {
        const { customerUUID } = this.state;

        if (customerUUID.length > 0)
            this.fetchCustomerRegistrationDetails(customerUUID);
    }

    render() {
        const { customerUUID, customerDetails } = this.state;

        const params = {
            customerUUID: customerUUID,
            customerDetails: customerDetails,
        }

        const renderPhoneNumberActivationStage = () => (
            <PhoneNumberVerifier {...params} onSuccess={this.updateComponent} />
        )

        const renderCustomerRegistrationStage = () => (
            <CustomerAccountActivation {...params} onSuccess={this.updateComponent} />
        )

        if (customerDetails) {
            if (!customerDetails.accountActivated) {

                return (
                    <Container className="d-flex flex-column py-3" style={{ height: '100vh', position: 'relative' }}>

                        <Row style={{ flex: "0 1 150px" }}>
                            <Col xs={{ size: 8, offset: 2 }} md={{ size: 4, offset: 4 }} className="text-center px-md-5">
                                <img src={SocLogo} alt="SOC logo" className="pb-1" style={{ height: "inherit", maxWidth: "100%", objectFit: "contain" }} />
                            </Col>
                        </Row>

                        <Row className="overflow-auto pb-1" style={{ flex: "1 1 auto", minHeight: "100px" }}>
                            <Col xs={12} md={{ size: 4, offset: 4 }} className="overflow-auto">
                                {customerDetails.phoneNumberActivated ? renderCustomerRegistrationStage() : renderPhoneNumberActivationStage()}
                            </Col>
                        </Row>

                        <Row style={{ flex: "0 1 100px" }}>
                            <Col xs={12} md={{ size: 8, offset: 2 }} className="d-flex flex-column justify-content-end align-items-center">
                                <img src={ProjectLogos} alt="Loga projektowe" style={{ height: "inherit", maxWidth: "100%", objectFit: "contain" }} />
                            </Col>
                        </Row>

                    </Container>
                )
            } else {
                return <Redirect to='/login' />;
            }
        }

        return null;
    }

}
