import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button, ListGroup, ListGroupItem, FormInput } from 'shards-react';
import Select from 'react-select';
import LinkButton from './../buttons/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import { EMAIL_REGEXP } from '../../utils/Patterns';

class CustomerDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingMode: false,
            data: [],
            form: {
                email: '',
                name: '',
                surname: '',
                phoneNumber: '',
                company: '',
                accessGroup: '',
                workplace: '',
                role: ''
            },
            formErrors: {
                email: [],
                name: [],
                surname: [],
                phoneNumber: [],
                company: [],
                accessGroup: [],
                role: []
            }
        }

        this.enableEditingMode = this.enableEditingMode.bind(this);
        this.disableEditingMode = this.disableEditingMode.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var customerData = props.data;

        if (JSON.stringify(customerData) !== JSON.stringify(state.data)) {
            return {
                data: customerData,
                form: CustomerDetails.makeFormFromCustomerData(customerData)
            }
        }

        return null;
    }

    static makeFormFromCustomerData(data = []) {
        var form = {}
        data.forEach(dataItem => {
            form[dataItem.inputName] = dataItem.value;
        })

        return form;
    }

    enableEditingMode() {
        const { data, formErrors } = this.state;
        const { isEditable } = this.props;

        if (data && isEditable) {
            Object.keys(formErrors).forEach(fieldKey => formErrors[fieldKey].length = 0)
            this.setState({
                editingMode: true,
                form: CustomerDetails.makeFormFromCustomerData(data),
                formErrors: formErrors
            })
        }
    }

    disableEditingMode() {
        this.setState({
            editingMode: false
        })
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        form[event.name] = item ? item : '';
        if (event.name === "company") form.accessGroup = ""
        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { email, name, surname, phoneNumber, company, accessGroup, role } = this.state.form;

        formErrors["email"] = [];
        formErrors["name"] = [];
        formErrors["surname"] = [];
        formErrors["phoneNumber"] = [];
        formErrors["company"] = [];
        formErrors["accessGroup"] = [];
        formErrors["role"] = [];

        var errorCount = 0;

        if (!EMAIL_REGEXP.test(email)) {
            formErrors["email"].push("Adres email nie jest poprawny");
            errorCount++;
        }

        if (name.length <= 0) {
            formErrors["name"].push("Imię nie może być puste");
            errorCount++;
        }

        if (surname.length <= 0) {
            formErrors["surname"].push("Nazwisko nie może być puste");
            errorCount++;
        }

        if (phoneNumber.length <= 0) {
            formErrors["phoneNumber"].push("Numer telefonu nie może być pusty");
            errorCount++;
        }

        if (phoneNumber.length < 9) {
            formErrors["phoneNumber"].push("Niepoprawny numer telefonu");
            errorCount++;
        }

        if (!company || company.length <= 0 || company.value.length <= 0) {
            formErrors["company"].push("Firma nie może być pusta");
            errorCount++;
        }

        if (accessGroup.parentValue !== company.value) {
            formErrors["accessGroup"].push("Grupa dostępowa nie należy do wybranej firmy");
            errorCount++;
        }

        if (!accessGroup || accessGroup.length <= 0 || accessGroup.value.length <= 0) {
            formErrors["accessGroup"].push("Grupa dostępowa nie może być pusta");
            errorCount++;
        }

        if (!role || company.length <= 0 || role.value.length <= 0) {
            formErrors["role"].push("Rola nie może być pusta");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm() {
        const { form, formErrors } = this.state;
        const { uuid } = this.props

        if (this.validForm()) {
            API.put(Constants.CUSTOMERS_URL + "/" + uuid, {
                form: {
                    uuid: uuid,
                    email: form.email,
                    name: form.name,
                    surname: form.surname,
                    phoneNumber: form.phoneNumber,
                    workplace: form.workplace,
                    accessGroupUuid: form.accessGroup ? form.accessGroup.value : null,
                    roleId: form.role.value,
                }
            }).then((result) => {
                if (result.status === 201) {
                    this.props.onSuccess();
                    this.disableEditingMode();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    render() {
        const { data, editingMode } = this.state;
        const { isEditable } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const renderRequiredInputTypeField = (inputName, inputType, errors = [], options = [], parentValueRef) => {
            var inputValue = this.state.form[inputName];
            var isError = errors ? Boolean(errors.length) : false

            switch (inputType) {
                case 'select':
                    return (
                        <>
                            <Select
                                name={inputName}
                                value={inputValue}
                                onChange={this.selectChangeHandler}
                                placeholder="Wybierz"
                                options={parentValueRef ? options.filter(option => option.parentValue === parentValueRef.value) : options}
                                noOptionsMessage={() => "Brak dostępnych opcji"}
                                loadingMessage={() => "Ładowanie"}
                                className={isError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                classNamePrefix="react-select"
                                style={{ width: "100%" }}
                                isDisabled={inputName === 'company'}
                                isClearable />
                            {isError && <ul className="mb-2 form-error-message">{renderError(errors)}</ul>}
                        </>
                    )
                case 'input-numeric':
                    return (
                        <>
                            <FormInput
                                name={inputName}
                                value={inputValue || ''}
                                onChange={this.inputChangeHandler}
                                type="number"
                                invalid={isError}
                                className={isError ? "mb-0" : "mb-2"} />
                            {isError && <ul className="mb-2 form-error-message">{renderError(errors)}</ul>}
                        </>
                    )
                default:
                    return (
                        <>
                            <FormInput
                                name={inputName}
                                value={inputValue || ''}
                                onChange={this.inputChangeHandler}
                                invalid={isError}
                                className={isError ? "mb-0" : "mb-2"} />
                            {isError && <ul className="mb-2 form-error-message">{renderError(errors)}</ul>}
                        </>
                    )
            }
        }

        const renderCustomerInfoSpan = (value) => {
            var valueToPrint;
            if (typeof value === 'string' || value instanceof String)
                valueToPrint = value ? value : "-";
            else
                valueToPrint = value && value.label ? value.label : "-";

            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray">
                    {valueToPrint}
                </span>
            )
        }

        const renderCustomerDetails = () => {
            return (
                <ListGroup small flush className="list-group-small">
                    {data && data.map((item, idx) => {
                        var parentValue = item.inputName === "accessGroup" ? this.state.form.company : null;
                        return (
                            <ListGroupItem key={idx} className="d-flex px-3 mb-1" style={{ flexWrap: "wrap" }}>
                                <span className="text-semibold text-fiord-blue">{item.label}</span>
                                {editingMode && item.editable ?
                                    renderRequiredInputTypeField(item.inputName, item.inputType, this.state.formErrors[item.inputName], item.options, parentValue) :
                                    renderCustomerInfoSpan(item.value)}
                            </ListGroupItem>
                        )
                    })}
                </ListGroup>
            )
        }

        const renderFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Nie udało się pobrać danych o użytkowniku</span>
                </ListGroupItem>
            </ListGroup>
        )

        const renderEditButton = () => (
            <div className="float-right">
                <LinkButton onClick={this.enableEditingMode}>
                    <FontAwesomeIcon icon={faEdit} /> Edytuj
                </LinkButton>
            </div>
        )

        return (
            <Card className="mb-3 w-100">
                <CardHeader className="border-bottom">
                    <div className="d-inline-flex align-items-center">
                        <h6 className="m-0">Dane użytkownika</h6>
                    </div>

                    {isEditable && renderEditButton()}
                </CardHeader>

                <CardBody className="px-0 py-0">
                    {data ? renderCustomerDetails() : renderFetchError()}
                </CardBody>

                {editingMode &&
                    <CardFooter style={{ paddingTop: "10px", paddingRight: "16px", borderTop: "1px solid rgba(0,0,0,.125)" }}>
                        <Button onClick={this.disableEditingMode} outline theme="danger" className="float-right ml-1">
                            Anuluj
                        </Button>

                        <Button onClick={this.submitForm} outline theme="info" className="float-right">
                            Zapisz
                        </Button>
                    </CardFooter>}
            </Card>
        );
    }

}

export default CustomerDetails;
