import React from "react";
import { FormInput, Button } from 'shards-react';
import Select from 'react-select';
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import Countdown, { zeroPad } from "react-countdown";
import { PropTypes } from 'prop-types';

class PhoneNumberVerificationForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isPhoneNumberValidationKeyInputVisible: false,
            activationCodeSentDate: null,
            form: {
                email: '',
                phoneNumberPrefix: "+48",
                phoneNumber: '',
                phoneNumberValidationKey: ''
            },
            formErrors: {
                phoneNumberPrefix: [],
                phoneNumber: [],
                phoneNumberValidationKey: [],
            },
            formHints: {
                phoneNumberPrefix: this.props.phoneNumberPrefixes
            }
        };

        this.actions = Object.freeze({
            SEND_TEXT_MESSAGE: Object.freeze("SEND_TEXT_MESSAGE"),
            VERIFY_VALIDATION_KEY: Object.freeze("VERIFY_VALIDATION_KEY")
        })

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var customerDetails = props.customerDetails;

        var form = {};
        if (customerDetails.email !== state.form.email) {
            form = {
                ...state.form,
                email: customerDetails.email,
            }
        }

        return {
            form: {
                ...state.form,
                ...form
            }
        };
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event) => {
        var { form, formErrors } = this.state;

        if (Array.isArray(selected)) {
            form[event.name] = selected.map(selectedItem => selectedItem.value);
        } else {
            form[event.name] = selected ? selected.value : '';
        }

        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { phoneNumberPrefix, phoneNumber } = this.state.form;

        formErrors["phoneNumberPrefix"] = [];
        formErrors["phoneNumber"] = [];

        var errorCount = 0;

        if (phoneNumberPrefix.length <= 0) {
            formErrors["phoneNumberPrefix"].push("Nie wybrano numeru kierunkowego");
            errorCount++;
        }

        if (phoneNumber.length <= 0) {
            formErrors["phoneNumber"].push("Numer telefonu nie może być pusty");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm(action) {
        const { form, formErrors } = this.state;
        const { uuid } = this.props

        if (this.validForm()) {
            API.post(Constants.CUSTOMERS_URL + "/verify", {
                action: action,
                form: {
                    uuid: uuid,
                    phoneNumberPrefix: form.phoneNumberPrefix,
                    phoneNumber: form.phoneNumber,
                    phoneNumberValidationKey: form.phoneNumberValidationKey
                }
            }).then((result) => {
                if (result.status === 202) {
                    this.setState({
                        isPhoneNumberValidationKeyInputVisible: true,
                        activationCodeSentDate: new Date(result.data)
                    })
                    this.refs.countDownRef.getApi().start();
                }
                if (result.status === 200) {
                    this.props.onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }

                if (response && response.status === 425) {
                    this.setState({
                        isPhoneNumberValidationKeyInputVisible: true,
                        activationCodeSentDate: new Date(response.data)
                    });
                }

                if (response && response.status === 409) {
                    this.setState({
                        formErrors: {
                            ...formErrors,
                            phoneNumberValidationKey: ["Kod nie jest poprawny"]
                        }
                    })
                }
            });
        }
    }

    findSelectItemByValue(optionName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[optionName].find(option => option.value === value);
        if (foundOption)
            return foundOption;

        return ''
    }

    render() {
        const { isPhoneNumberValidationKeyInputVisible } = this.state;
        const { email, phoneNumberPrefix, phoneNumber, phoneNumberValidationKey } = this.state.form;

        var hasPhoneNumberPrefixError = Boolean(this.state.formErrors.phoneNumberPrefix.length);
        var hasPhoneNumberError = Boolean(this.state.formErrors.phoneNumber.length);
        var hasPhoneNumberValidationKeyError = Boolean(this.state.formErrors.phoneNumberValidationKey.length);

        const defaultSelectProps = {
            placeholder: "Wybierz lub utwórz nowy",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            isClearable: true
        }

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const renderPhoneNumberValidationKeyInput = () => (
            <div>
                <label>Kod weryfikacyjny:</label>
                <FormInput
                    name="phoneNumberValidationKey"
                    value={phoneNumberValidationKey || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasPhoneNumberValidationKeyError}
                    className={hasPhoneNumberValidationKeyError ? "mb-0" : "mb-2"}
                    type="number" />
                {hasPhoneNumberValidationKeyError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.phoneNumberValidationKey)}</ul>}
            </div>
        )

        const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                return null;
            } else {
                return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
            }
        };

        const renderMessageHasBeenSendWithTimerForResend = () => {
            const { activationCodeSentDate } = this.state;

            var currentTimestamp = Date.now();
            var endOfCountingDate = currentTimestamp + (600000 - (currentTimestamp - activationCodeSentDate.getTime()))

            return (
                <div className="text-center">
                    <p className="m-0">
                        <small>Wiadomość została wysłana, jeżeli nie dotarła, po 10 minutach można wysłać ją ponownie.</small>
                    </p>

                    <Countdown
                        date={endOfCountingDate}
                        renderer={countdownRenderer}
                        ref="countDownRef" />
                </div>
            )
        }

        return (
            <div>
                <label>Email:</label>
                <FormInput
                    name="email"
                    value={email || ''}
                    onChange={this.inputChangeHandler}
                    disabled={true}
                    className="mb-2" />

                <label>*Numer kierunkowy</label>
                <Select
                    {...defaultSelectProps}
                    name="phoneNumberPrefix"
                    value={phoneNumberPrefix ? this.findSelectItemByValue("phoneNumberPrefix", phoneNumberPrefix) : ""}
                    onChange={this.selectChangeHandler}
                    options={this.state.formHints.phoneNumberPrefix}
                    className={hasPhoneNumberPrefixError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                {hasPhoneNumberPrefixError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.phoneNumberPrefix)}</ul>}

                <label>*Numer telefonu</label>
                <FormInput
                    name="phoneNumber"
                    value={phoneNumber || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasPhoneNumberError}
                    className={hasPhoneNumberError ? "mb-0" : "mb-2"}
                    type="number" />
                {hasPhoneNumberError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.phoneNumber)}</ul>}

                {isPhoneNumberValidationKeyInputVisible && renderPhoneNumberValidationKeyInput()}
                {isPhoneNumberValidationKeyInputVisible && renderMessageHasBeenSendWithTimerForResend()}

                {!isPhoneNumberValidationKeyInputVisible &&
                    <Button
                        onClick={() => this.submitForm(this.actions.SEND_TEXT_MESSAGE)}
                        outline
                        theme="info"
                        className="mt-2 ml-1 float-right">
                        Wyślij SMS
                    </Button>
                }

                {isPhoneNumberValidationKeyInputVisible &&
                    <div>
                        <Button
                            onClick={() => this.submitForm(this.actions.VERIFY_VALIDATION_KEY)}
                            outline
                            theme="info"
                            className="mt-2 ml-1 float-right">
                            Weryfikuj
                        </Button>

                        <Button
                            onClick={() => this.submitForm(this.actions.SEND_TEXT_MESSAGE)}
                            outline
                            theme="info"
                            className="mt-2 ml-1 float-right">
                            Wyślij SMS ponownie
                        </Button>
                    </div>
                }
            </div>
        );
    }

}

export default PhoneNumberVerificationForm;

PhoneNumberVerificationForm.propTypes = {
    phoneNumberPrefixes: PropTypes.array
}

PhoneNumberVerificationForm.defaultProps = {
    phoneNumberPrefixes: [
        { label: "+93 - Afganistan", value: "+93" },
        { label: "+1907 - Alaska", value: "+1907" },
        { label: "+355 - Albania", value: "+355" },
        { label: "+213 - Algieria", value: "+213" },
        { label: "+376 - Andora", value: "+376" },
        { label: "+244 - Angola", value: "+244" },
        { label: "+599 - Antyle Holenderskie", value: "+599" },
        { label: "+966 - Arabia Saudyjska", value: "+966" },
        { label: "+54 - Argentyna", value: "+54" },
        { label: "+374 - Armenia", value: "+374" },
        { label: "+61 - Australia", value: "+61" },
        { label: "+43 - Austria", value: "+43" },
        { label: "+994 - Azerbejdżan", value: "+994" },
        { label: "+973 - Bahrajn", value: "+973" },
        { label: "+880 - Bangladesz", value: "+880" },
        { label: "+32 - Belgia", value: "+32" },
        { label: "+229 - Benin", value: "+229" },
        { label: "+375 - Białoruś", value: "+375" },
        { label: "+591 - Boliwia", value: "+591" },
        { label: "+387 - Bośnia i Hercegowina", value: "+387" },
        { label: "+267 - Botswana", value: "+267" },
        { label: "+55 - Brazylia", value: "+55" },
        { label: "+673 - Brunei", value: "+673" },
        { label: "+359 - Bułgaria", value: "+359" },
        { label: "+226 - Burkina Faso", value: "+226" },
        { label: "+257 - Burundi", value: "+257" },
        { label: "+56 - Chile", value: "+56" },
        { label: "+86 - Chiny", value: "+86" },
        { label: "+385 - Chorwacja", value: "+385" },
        { label: "+682 - Cook'a Wyspy", value: "+682" },
        { label: "+357 - Cypr", value: "+357" },
        { label: "+235 - Czad", value: "+235" },
        { label: "+420 - Czechy", value: "+420" },
        { label: "+45 - Dania", value: "+45" },
        { label: "+246 - Diego Garcia", value: "+246" },
        { label: "+253 - Dżibuti", value: "+253" },
        { label: "+20 - Egipt", value: "+20" },
        { label: "+593 - Ekwador", value: "+593" },
        { label: "+291 - Erytrea", value: "+291" },
        { label: "+372 - Estonia", value: "+372" },
        { label: "+251 - Etiopia", value: "+251" },
        { label: "+500 - Falklandy", value: "+500" },
        { label: "+679 - Fidżi", value: "+679" },
        { label: "+63 - Filipiny", value: "+63" },
        { label: "+358 - Finlandia", value: "+358" },
        { label: "+33 - Francja", value: "+33" },
        { label: "+241 - Gabon", value: "+241" },
        { label: "+220 - Gambia", value: "+220" },
        { label: "+233 - Ghana", value: "+233" },
        { label: "+350 - Gibraltar", value: "+350" },
        { label: "+30 - Grecja", value: "+30" },
        { label: "+299 - Grenlandia", value: "+299" },
        { label: "+995 - Gruzja", value: "+995" },
        { label: "+1671 - Guam", value: "+1671" },
        { label: "+592 - Gujana", value: "+592" },
        { label: "+594 - Gujana Francuska", value: "+594" },
        { label: "+224 - Gwinea", value: "+224" },
        { label: "+240 - Gwinea Równikowa", value: "+240" },
        { label: "+245 - Gwinea - Bissau", value: "+245" },
        { label: "+1808 - Hawaje", value: "+1808" },
        { label: "+34 - Hiszpania", value: "+34" },
        { label: "+31 - Holandia", value: "+31" },
        { label: "+852 - Hong Kong", value: "+852" },
        { label: "+91 - Indie", value: "+91" },
        { label: "+62 - Indonezja", value: "+62" },
        { label: "+964 - Irak", value: "+964" },
        { label: "+98 - Iran", value: "+98" },
        { label: "+353 - Irlandia", value: "+353" },
        { label: "+354 - Islandia", value: "+354" },
        { label: "+972 - Izrael", value: "+972" },
        { label: "+81 - Japonia", value: "+81" },
        { label: "+967 - Jemen", value: "+967" },
        { label: "+962 - Jordania", value: "+962" },
        { label: "+381 - Jugosławia", value: "+381" },
        { label: "+588 - Kambodża", value: "+588" },
        { label: "+237 - Kamerun", value: "+237" },
        { label: "+1 - Kanada", value: "+1" },
        { label: "+34 - Kanaryjskie Wyspy", value: "+34" },
        { label: "+974 - Katar", value: "+974" },
        { label: "+7 - Kazachstan", value: "+7" },
        { label: "+254 - Kenia", value: "+254" },
        { label: "+996 - Kirgistan", value: "+996" },
        { label: "+686 - Kiribati", value: "+686" },
        { label: "+57 - Kolumbia", value: "+57" },
        { label: "+269 - Komory", value: "+269" },
        { label: "+242 - Kongo", value: "+242" },
        { label: "+234 - Kongo Republika Demokrat.", value: "+234" },
        { label: "+82 - Korea Południowa", value: "+82" },
        { label: "+850 - Koreańska RL-D", value: "+850" },
        { label: "+506 - Kostaryka", value: "+506" },
        { label: "+53 - Kuba", value: "+53" },
        { label: "+965 - Kuwejt", value: "+965" },
        { label: "+856 - Laos", value: "+856" },
        { label: "+266 - Lesotho", value: "+266" },
        { label: "+961 - Liban", value: "+961" },
        { label: "+231 - Liberia", value: "+231" },
        { label: "+218 - Libia", value: "+218" },
        { label: "+423 - Liechtenstein", value: "+423" },
        { label: "+370 - Litwa", value: "+370" },
        { label: "+352 - Luksemburg", value: "+352" },
        { label: "+371 - Łotwa", value: "+371" },
        { label: "+389 - Macedonia", value: "+389" },
        { label: "+261 - Madagaskar", value: "+261" },
        { label: "+853 - Makau", value: "+853" },
        { label: "+265 - Malawi", value: "+265" },
        { label: "+960 - Malediwy", value: "+960" },
        { label: "+60 - Malezja", value: "+60" },
        { label: "+223 - Mali", value: "+223" },
        { label: "+356 - Malta", value: "+356" },
        { label: "+1670 - Mariany Północne (Saipan)", value: "+1670" },
        { label: "+212 - Maroko", value: "+212" },
        { label: "+692 - Marshalla Wyspy", value: "+692" },
        { label: "+222 - Mauretania", value: "+222" },
        { label: "+230 - Mauritius", value: "+230" },
        { label: "+52 - Meksyk", value: "+52" },
        { label: "+373 - Mołdawia", value: "+373" },
        { label: "+377 - Monako", value: "+377" },
        { label: "+976 - Mongolia", value: "+976" },
        { label: "+258 - Mozambik ", value: "+258" },
        { label: "+95 - Myanmar (Birma)", value: "+95" },
        { label: "+264 - Namibia", value: "+264" },
        { label: "+674 - Nauru", value: "+674" },
        { label: "+977 - Nepal", value: "+977" },
        { label: "+49 - Niemcy", value: "+49" },
        { label: "+227 - Niger", value: "+227" },
        { label: "+234 - Nigeria", value: "+234" },
        { label: "+505 - Nikaragua", value: "+505" },
        { label: "+683 - Niue", value: "+683" },
        { label: "+672 - Norfolk Wyspa", value: "+672" },
        { label: "+47 - Norwegia", value: "+47" },
        { label: "+687 - Nowa Kaledonia", value: "+687" },
        { label: "+64 - Nowa Zelandia", value: "+64" },
        { label: "+968 - Oman", value: "+968" },
        { label: "+298 - Owcze Wyspy", value: "+298" },
        { label: "+ 92 - Pakistan", value: "+ 92" },
        { label: "+680 - Palau", value: "+680" },
        { label: "+970 - Palestyna", value: "+970" },
        { label: "+507 - Panama", value: "+507" },
        { label: "+675 - Papua Nowa Gwinea", value: "+675" },
        { label: "+595 - Paragwaj", value: "+595" },
        { label: "+51 - Peru", value: "+51" },
        { label: "+689 - Polinezja Francuska", value: "+689" },
        { label: "+48 - Polska", value: "+48" },
        { label: "+351 - Portugalia", value: "+351" },
        { label: "+1787 - Portoryko", value: "+1787" },
        { label: "+27 - Republika Południowej Afryki", value: "+27" },
        { label: "+236 - Republika Środkowoafrykańska", value: "+236" },
        { label: "+262 - Reunion", value: "+262" },
        { label: "+7 - Rosja", value: "+7" },
        { label: "+40 - Rumunia", value: "+40" },
        { label: "+250 - Rwanda", value: "+250" },
        { label: "+1869 - Sain Christopher i Nevis", value: "+1869" },
        { label: "+1758 - Saint Lucia", value: "+1758" },
        { label: "+1809 - Saint Vincent", value: "+1809" },
        { label: "+677 - Salomona Wyspy", value: "+677" },
        { label: "+503 - Salwador", value: "+503" },
        { label: "+684 - Samoa", value: "+684" },
        { label: "+685 - Samoa Zachodnie", value: "+685" },
        { label: "+378 - San Marino", value: "+378" },
        { label: "+221 - Senegal", value: "+221" },
        { label: "+248 - Seszele", value: "+248" },
        { label: "+232 - Sierra Leone", value: "+232" },
        { label: "+65 - Singapur", value: "+65" },
        { label: "+421 - Słowacja", value: "+421" },
        { label: "+386 - Słowenia", value: "+386" },
        { label: "+252 - Somalia", value: "+252" },
        { label: "+94 - Sri Lanka", value: "+94" },
        { label: "+1 - Stany Zjednoczone Ameryki", value: "+1" },
        { label: "+268 - Suazi", value: "+268" },
        { label: "+249 - Sudan", value: "+249" },
        { label: "+597 - Surinam", value: "+597" },
        { label: "+963 - Syria", value: "+963" },
        { label: "+41 - Szwajcaria", value: "+41" },
        { label: "+46 - Szwecja", value: "+46" },
        { label: "+290 - Św. Heleny Wyspa", value: "+290" },
        { label: "+508 - Św. Piotra i Mikeleona Wyspy", value: "+508" },
        { label: "+239 - Św. Tomasza Wyspy", value: "+239" },
        { label: "+992 - Tadżykistan", value: "+992" },
        { label: "+66 - Tajlandia", value: "+66" },
        { label: "+886 - Tajwan", value: "+886" },
        { label: "+255 - Tanzania", value: "+255" },
        { label: "+228 - Togo", value: "+228" },
        { label: "+690 - Tokelau", value: "+690" },
        { label: "+676 - Tonga", value: "+676" },
        { label: "+216 - Tunezja", value: "+216" },
        { label: "+90 - Turcja", value: "+90" },
        { label: "+993 - Turkmenistan", value: "+993" },
        { label: "+1649 - Tursk (wyspy)", value: "+1649" },
        { label: "+688 - Tuvalu", value: "+688" },
        { label: "+256 - Uganda", value: "+256" },
        { label: "+380 - Ukraina", value: "+380" },
        { label: "+598 - Urugwaj", value: "+598" },
        { label: "+998 - Uzbekistan", value: "+998" },
        { label: "+678 - Vanuatu", value: "+678" },
        { label: "+681 - Wallis i Futuna", value: "+681" },
        { label: "+3906 - Watykan", value: "+3906" },
        { label: "+58 - Wenezuela", value: "+58" },
        { label: "+36 - Węgry", value: "+36" },
        { label: "+44 - Wielka Brytania", value: "+44" },
        { label: "+84 - Wietnam", value: "+84" },
        { label: "+39 - Włochy", value: "+39" },
        { label: "+247 - Wniebowstąpienia Wyspa", value: "+247" },
        { label: "+225 - Wybrzeże Kości Słoniowej", value: "+225" },
        { label: "+260 - Zambia", value: "+260" },
        { label: "+259 - Zanzibar", value: "+259" },
        { label: "+238 - Zielonego Przylądka Wyspy", value: "+238" },
        { label: "+263 - Zimbabwe", value: "+263" }
    ]
};