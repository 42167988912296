import React from "react";
import { Container } from "shards-react";
import Companies from "../components/companies/Companies";

class Company extends React.Component {

    render() {
        return (
            <Container fluid className="main-content-container px-4">
                <Companies />
            </Container>
        );
    }

}

export default Company;