import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "shards-react";

const InfoModal = ({ title, open, toggle, closeButtonText, children, showModal, secondButtonText, secondButtonClick, bodyClassName }) => {
    return (
        <Modal style={{ zIndex: "1080" }} size="lg" centered={true}
            open={open}
            toggle={() => { }}
            showModal={showModal}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody className={bodyClassName}>
                {children}
            </ModalBody>
            {closeButtonText &&
                <ModalFooter>
                    {secondButtonText && <Button onClick={secondButtonClick} outline theme="info" className="mb-2 mr-1 float-right">
                        {secondButtonText}
                    </Button>}
                    <Button onClick={toggle} outline theme="info" className="mb-2 mr-1 float-right">
                        {closeButtonText}
                    </Button>
                </ModalFooter>
            }
        </Modal>
    )
}

const ConfirmModal = forwardRef(({ title, open, toggle, submitAction, positiveButtonText,
    negativeButtonText, middleButtonText, middleButtonAction, children, bodyClassName }, ref) => {
    let randomId = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] && 15 >> c / 4).toString(16)
    );

    useImperativeHandle(ref, () => ({
        disablePositiveBtn(value) {
            setIsPositiveDisabled(value);
        }
    }));

    const [isPositiveDisabled, setIsPositiveDisabled] = useState(false);

    function showModal() {
        let modal = document.getElementById(randomId);
        modal.removeAttribute("aria-hidden");
        modal.setAttribute("aria-modal", true);
    }


    return (
        <Modal id={randomId} style={{ zIndex: "1080" }} size="lg" centered={true}
            open={open}
            toggle={() => { }}
            showModal={showModal}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody className={bodyClassName}>
                {children}
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle} outline theme="danger" className="mb-2 mr-1 float-right">
                    {negativeButtonText}
                </Button>

                {middleButtonText && <Button onClick={middleButtonAction}
                    outline theme="info" className="mb-2 mr-1 float-right">
                    {middleButtonText}
                </Button>}

                {positiveButtonText && <Button onClick={() => { submitAction(toggle) }} outline theme="info" className="mb-2 mr-1 float-right" disabled={isPositiveDisabled} >
                    {positiveButtonText}
                </Button>}
            </ModalFooter>
        </Modal>
    )
});

export {
    ConfirmModal,
    InfoModal,
};
