import React from "react";
import {
    Row,
    FormTextarea,
} from "shards-react";

import API from "../../../../api/AxiosConfiguration";
import * as Constants from '../../../../constants';
import * as socToast from '../../../../utils/SocToast';

class DeleteDeviceApplicationForm extends React.Component {

    constructor() {
        super();
        this.state = {
            form: {
                reason: '',
            }
        };
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    submitForm = (uuid, onSuccess) => {
        const { form } = this.state;

        API.post(Constants.PC_DEVICE_DEVICELOCK_URL + "/delete", {
            form: {
                uuid: uuid,
                reason: form.reason
            }
        }).then((result) => {
            if (result.status === 200) {
                this.props.toggle(uuid);
                onSuccess();
                socToast.success("Urządzenie zostanie usunięte", "Urządzenie zostało dezaktywowane i oczekuje na usunięcie.");
            }
        }).catch(error => {
            var response = error.response;
            if (response) {
                if (response.status === 409) {
                    socToast.error("Urządzenie nie zostało usunięte", "Przed usunięciem urządzenia należy upewnić się, że reguły, które się w nim znajdują zostały usunięte")
                }
            }
        });
    }

    render() {
        return (
            <Row className="mt-2">
                <label>Powód usunięcia (opcjonalne):</label>
                <FormTextarea onChange={this.handleChange} />
            </Row>
        );
    }
}

export default DeleteDeviceApplicationForm;