import React from "react";
import {
    Tooltip,
    Row,
    Col,
    FormInput,
    DatePicker,
    FormTextarea,
    FormCheckbox
} from "shards-react";
import AsyncSelect from 'react-select/async';
import pl from 'date-fns/locale/pl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { Store } from "../../../flux";
import { components } from 'react-select';
import { getCustomersForSelect } from '../../../api/ApiService';
import i18n from './../../../i18n';
import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'

class CategoryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== Constants.Roles.USER,
            isAdmin: Store.getUserRole() === Constants.Roles.ADMIN,
            hasEmailBeenConfigured: false,
            form: {
                name: '',
                priority: '',
                from: undefined,
                to: undefined,
                mail: false,
                sms: false,
                push: false,
                webPush: false,
                mailMessage: '',
                smsMessage: '',
                pushMessage: '',
                webPushMessage: '',
                mailUsers: [],
                manager: ''
            },
            formErrors: {
                name: [],
                priority: [],
                mailMessage: [],
                smsMessage: [],
                pushMessage: [],
                webPushMessage: [],
                manager: []
            },
            formHints: {
                priority: [],
                mailUsers: [],
            },
            tooltip: {
                mailUsersTooltip: false,
                manager: []
            },
            isManagersLoading: true,
            isOptionsLoading: true,
            isMobileView: false
        };

        this.onWindowResizeFluxListener = this.onWindowResizeFluxListener.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.dateChangeHandler = this.dateChangeHandler.bind(this);
        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
        this.fetchCategoryForm = this.fetchCategoryForm.bind(this);
        this.checkEmailConfiguration = this.checkEmailConfiguration.bind(this);
    }

    componentDidMount() {
        const { isAdminAtLeast } = this.state;
        const { uuid } = this.props;

        this.checkEmailConfiguration();
        this.fetchAvailableOptions();
        if (uuid.length) {
            this.fetchCategoryForm(uuid);
        }

        if (isAdminAtLeast) {
            this.fetchCompanyCustomers()
        }
    }

    componentWillMount() {
        Store.addChangeListener(this.onWindowResizeFluxListener);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onWindowResizeFluxListener);
    }

    clearFormHints() {
        this.setState({
            formHints: {
                ...this.state.formHints,
                priority: [],
            }
        })
    }

    fetchAvailableOptions = () => {
        API.get(Constants.NOTIFICATION_CATEGORY_URL + "/form/options").then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                    },
                    isOptionsLoading: false,
                })
            }
        });
    }

    fetchCategoryForm = (uuid) => {
        API.get(Constants.NOTIFICATION_CATEGORY_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: result.data.form,
                })
            }
        });
    }

    checkEmailConfiguration = () => {
        API.get(Constants.COMPANY_SETTINGS_EMAIL_CHECK).then((result) => {
            if (result.status === 200) {
                this.setState({
                    hasEmailBeenConfigured: result.data
                })
            }
        });
    }

    onWindowResizeFluxListener() {
        this.setState({
            ...this.state,
            isMobileView: Store.isMobileView()
        })
    }

    showTooltip(name) {
        var { tooltip } = this.state;
        tooltip[name] = !tooltip[name];
        this.setState({
            tooltip: tooltip
        });
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event) => {
        var { form, formErrors } = this.state;
        if (Array.isArray(selected)) {
            form[event.name] = selected.map(selectedItem => selectedItem.value);
        } else {
            if (event.name !== "manager") {
                form[event.name] = selected ? selected.value : '';
            } else {
                this.setState({
                    form: {
                        ...form,
                        [event.name]: selected
                    }
                });
                return;
            }
        }

        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    dateChangeHandler = (date, formName) => {
        var { form, formErrors } = this.state;
        form[formName] = date ? date.getTime() : undefined;
        formErrors[formName] = [];
        this.setState({ form: form });
    }

    checkboxChangeHandler = (e, formName) => {
        var { form } = this.state;
        form[formName] = !form[formName];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors, isAdminAtLeast, isAdmin } = this.state;
        const { name, priority, mailMessage, smsMessage, pushMessage, webPushMessage, manager } = this.state.form;
        const { uuid } = this.props;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        formErrors["name"] = [];
        formErrors["priority"] = [];
        formErrors["smsMessage"] = [];
        formErrors["mailMessage"] = [];
        formErrors["pushMessage"] = [];
        formErrors["webPushMessage"] = [];
        formErrors["manager"] = [];

        var errorCount = 0;

        if (name.length <= 0) {
            formErrors["name"].push("Nazwa nie może być pusta");
            errorCount++;
        }

        if(hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }


        if (priority.length <= 0) {
            formErrors["priority"].push("Priorytet musi zostać wybrany");
            errorCount++;
        }


        if (mailMessage.length > 150) {
            formErrors["mailMessage"].push("Maksymalna długość pojedynczej wiadomości wynosi 150 znaków");
            errorCount++;
        }

        if(hasValueUrlOrHtmlTag(mailMessage)) {
            formErrors["mailMessage"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        console.warn(`mailMessage => ${mailMessage}`)

        if (smsMessage.length > 70) {
            formErrors["smsMessage"].push("Maksymalna długość pojedynczej wiadomości wynosi 70 znaków");
            errorCount++;
        }

        if(hasValueUrlOrHtmlTag(smsMessage)) {
            formErrors["smsMessage"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }


        if (pushMessage.length > 150) {
            formErrors["pushMessage"].push("Maksymalna długość pojedynczej wiadomości wynosi 150 znaków");
            errorCount++;
        }

        if(hasValueUrlOrHtmlTag(pushMessage)) {
            formErrors["pushMessage"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }


        if (webPushMessage.length > 150) {
            formErrors["webPushMessage"].push("Maksymalna długość pojedynczej wiadomości wynosi 150 znaków");
            errorCount++;
        }

        if(hasValueUrlOrHtmlTag(webPushMessage)) {
            formErrors["webPushMessage"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (!isAdmin && !isEditingModeEnabled && isAdminAtLeast && (!manager || manager.length <= 0)) {
            formErrors["manager"].push("Użytkownik musi zostać wybrany");
            errorCount++;
        }


        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(Constants.NOTIFICATION_CATEGORY_URL + "/" + uuid, {
                    form: form
                })
            } else {
                apiPromise = API.post(Constants.NOTIFICATION_CATEGORY_URL, {
                    form: {
                        ...form,
                        manager: form.manager && form.manager.value
                    }
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201 || result.status === 200) {
                    this.props.toggle();
                    onSuccess(form.manager);
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    fetchCompanyCustomers = () => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            })
        })
    }


    render() {
        const { isAdminAtLeast, isAdmin, isMobileView, hasEmailBeenConfigured } = this.state;
        const { name, priority, from, to, mail, sms, push, webPush, mailMessage, smsMessage, pushMessage, webPushMessage, manager, mailUsers } = this.state.form;
        const { SingleValue } = components;
        const { uuid } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasPriorityError = Boolean(this.state.formErrors.priority.length);
        var hasMailMessageError = Boolean(this.state.formErrors.mailMessage.length);
        var hasSmsMessageError = Boolean(this.state.formErrors.smsMessage.length);
        var hasPushMessageError = Boolean(this.state.formErrors.pushMessage.length);
        var hasWebPushMessageError = Boolean(this.state.formErrors.webPushMessage.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);

        const isEditingModeEnabled = Boolean(uuid && uuid.length);

        const defaultSelectProps = {
            placeholder: "Wybierz",
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        const getPriorityIcon = (priority) => {
            switch (priority) {
                case "Uwaga": return (
                    <div className="mr-1">
                        <FontAwesomeIcon icon={faAngleRight} size="lg" style={{ color: "green" }} />
                    </div>
                )
                case "Błąd": return (
                    <div className="mr-1">
                        <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" style={{ color: "orange" }} />
                    </div>
                )
                case "Zdarzenie krytyczne": return (
                    <div className="mr-1">
                        <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" style={{ color: "red" }} />
                        <FontAwesomeIcon icon={faAngleRight} size="lg" style={{ color: "red", marginLeft: "-2px" }} />
                    </div>
                )
                default: return;
            }
        }

        const PriorityOptionWithIcons = props => {
            const { innerProps, innerRef, children } = props;

            const optionStyles = {
                backgroundColor: Boolean(innerRef) ? "#DEEBFF" : "transparent",
                display: "flex",
                justifyContent: "space-between"
            }

            return (
                <div ref={innerRef} {...innerProps} className="react-select-option" style={optionStyles} >
                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>{children}</div>
                    <div className="react-select-option-trash-container">
                        {getPriorityIcon(children)}
                    </div>
                </div>
            );
        };

        const ValueOption = (props) => (
            <SingleValue {...props} className="react-select-single-value">
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>{props.children}</div>
                <div className="react-select-option-trash-container">
                    {getPriorityIcon(props.children)}
                </div>
            </SingleValue>
        );

        const findSelectLabelByValue = (name, value) => {
            var hint;
            var labels = [];
            if (Array.isArray(value)) {
                value.forEach(valueArrayItem => {
                    hint = this.state.formHints[name].find(item => valueArrayItem === item.value)
                    if (hint)
                        labels.push(hint.label);
                })
            } else {
                hint = this.state.formHints[name].find(item => value === item.value)
                if (hint)
                    labels = hint.label;
            }

            return labels;
        }

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        return (
            <div>
                <Row>
                    <Col sm="12" md="6">
                        <label>*Nazwa</label>
                        <FormInput
                            name="name"
                            value={name || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasNameError}
                            className={hasNameError ? "mb-0" : "mb-2"} />
                        {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}
                    </Col>
                    <Col sm="12" md="6">
                        <label>*Priorytet</label>
                        <AsyncSelect
                            {...defaultSelectProps}
                            name="priority"
                            value={priority ? { label: findSelectLabelByValue("priority", priority), value: priority } : ""}
                            onChange={this.selectChangeHandler}
                            defaultOptions={this.state.formHints.priority}
                            components={{ Option: PriorityOptionWithIcons, SingleValue: ValueOption }}
                            className={hasPriorityError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                            loadOptions={(inputValue) => filterOptionsPromise(inputValue, "priority")} />
                        {hasPriorityError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.priority)}</ul>}
                    </Col>
                    {!isEditingModeEnabled && isAdminAtLeast &&
                        <Col sm="12" md="6">
                            <label>*Menedżer</label>
                            <AsyncSelect
                                {...defaultSelectProps}
                                name="manager"
                                value={manager}
                                onChange={this.selectChangeHandler}
                                defaultOptions={this.state.formHints.manager}
                                className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                isLoading={this.state.isManagersLoading}
                                placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")} />
                            {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                        </Col>
                    }
                </Row>
                <Row>
                    <Col sm="12" md="6">
                        <label>Aktywność alertów od godziny</label>
                        <DatePicker
                            selected={from}
                            startDate={from}
                            onChange={date => this.dateChangeHandler(date, "from")}
                            locale={pl}
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat="hh:mm aa"
                            timeIntervals={15}
                            timeCaption="Time"
                            withPortal={isMobileView}
                            className="mb-2" />
                    </Col>
                    <Col sm="12" md="6">
                        <label>Aktywność alertów do godziny</label>
                        <DatePicker
                            selected={to}
                            startDate={to}
                            onChange={date => this.dateChangeHandler(date, "to")}
                            locale={pl}
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat="hh:mm aa"
                            timeIntervals={15}
                            timeCaption="Time"
                            withPortal={isMobileView}
                            className="mb-2" />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6">
                        <label>
                            <FormCheckbox
                                name="sms"
                                checked={sms}
                                onChange={e => this.checkboxChangeHandler(e, "sms")}>Sms</FormCheckbox>
                        </label>

                        <FormTextarea
                            name="smsMessage"
                            value={smsMessage || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasSmsMessageError}
                            className={hasSmsMessageError ? "mb-0" : "mb-2"} />
                        {hasSmsMessageError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.smsMessage)}</ul>}
                        <br></br>
                    </Col>
                    <Col sm="12" md="6">
                        <label>
                            <FormCheckbox
                                name="push"
                                checked={push}
                                onChange={e => this.checkboxChangeHandler(e, "push")}>Push</FormCheckbox>
                        </label>

                        <FormTextarea
                            name="pushMessage"
                            value={pushMessage || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasPushMessageError}
                            className={hasPushMessageError ? "mb-0" : "mb-2"} />
                        {hasPushMessageError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.pushMessage)}</ul>}
                        <br></br>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6">
                        <label><FormCheckbox
                            name="mail"
                            checked={mail}
                            onChange={e => this.checkboxChangeHandler(e, "mail")}
                            disabled={!hasEmailBeenConfigured}>Email</FormCheckbox>
                        </label>

                        <FormTextarea
                            name="mailMessage"
                            value={mailMessage || ''}
                            onChange={this.inputChangeHandler}
                            disabled={!hasEmailBeenConfigured}
                            invalid={hasMailMessageError}
                            className={hasSmsMessageError ? "mb-0" : "mb-2"} />
                        {hasMailMessageError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.mailMessage)}</ul>}
                        
                        <br></br>
                        <label>Użytkownicy</label>  <i id="tooltipMailUsers" style={{ marginTop: '6px' }} className="float-right fas fa-info-circle"></i>
                        <AsyncSelect
                            {...defaultSelectProps}
                            isMulti
                            name="mailUsers"
                            value={mailUsers ? mailUsers.map(mailUser => { return { label: findSelectLabelByValue("mailUsers", mailUser), value: mailUser } }) : ""}
                            onChange={this.selectChangeHandler}
                            defaultOptions={this.state.formHints.mailUsers}
                            isDisabled={!hasEmailBeenConfigured} />
                        <Tooltip
                            className="tooltip-modal"
                            open={this.state.tooltip.mailUsersTooltip}
                            target={'#tooltipMailUsers'}
                            toggle={() => this.showTooltip("mailUsersTooltip")}>{i18n.t('mailUsersTooltip')}
                        </Tooltip>
                    </Col>
                    <Col sm="12" md="6">
                        <label>
                            <FormCheckbox
                                name="webPush"
                                checked={webPush}
                                onChange={e => this.checkboxChangeHandler(e, "webPush")}>WebPush</FormCheckbox>
                        </label>

                        <FormTextarea
                            name="webPushMessage"
                            value={webPushMessage || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasWebPushMessageError}
                            className={hasWebPushMessageError ? "mb-0" : "mb-2"} />
                        {hasWebPushMessageError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.webPushMessage)}</ul>}
                        <br></br>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CategoryForm;