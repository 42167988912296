import React from "react";
import { FormInput, Button } from 'shards-react';
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { SECURE_PASSWORD_REGEXP } from "../../../utils/Patterns";

class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                oldPassword: '',
                password: '',
                repeatPassword: '',
            },
            formErrors: {
                oldPassword: [],
                password: [],
                repeatPassword: [],
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { oldPassword, password, repeatPassword } = this.state.form;

        formErrors["oldPassword"] = [];
        formErrors["password"] = [];
        formErrors["repeatPassword"] = [];

        var errorCount = 0;

        if (oldPassword.length <= 0) {
            formErrors["oldPassword"].push("Hasło nie może być puste");
            errorCount++;
        }

        if (!SECURE_PASSWORD_REGEXP.test(password)) {
            formErrors["password"].push("Hasło musi zawierać conajmniej: jedną małą literę, " +
                "jedną dużą literę, jedną cyfrę, jeden znak specjalny, 8 znaków.");
            errorCount++;
        }

        if (password !== repeatPassword) {
            formErrors["repeatPassword"].push("Podane hasła muszą być identyczne");
            errorCount++;
        }

        if (oldPassword === password) {
            formErrors["password"].push("Stare i nowe hasła nie mogą być identyczne");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm() {
        const { form, formErrors } = this.state;
        const { resetPasswordToken } = this.props

        if (this.validForm()) {
            API.post(Constants.CUSTOMERS_URL + "/resetPassword", {
                form: {
                    resetPasswordToken: resetPasswordToken,
                    oldPassword: form.oldPassword,
                    password: form.password,
                    repeatPassword: form.repeatPassword
                }
            }).then((result) => {
                if (result.status === 200) {
                    this.props.onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    if (response.data.errors) {
                        response.data.errors.forEach(error => {
                            formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                        })
                    } else {
                        formErrors["oldPassword"].push("Wprowadzone hasło jest niepoprawne");
                    }
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    render() {
        const { oldPassword, password, repeatPassword } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const hasOldPasswordError = Boolean(this.state.formErrors.oldPassword.length);
        const hasPasswordError = Boolean(this.state.formErrors.password.length);
        const hasRepeatPasswordError = Boolean(this.state.formErrors.repeatPassword.length);

        return (
            <div>
                <label>Stare hasło:</label>
                <FormInput
                    name="oldPassword"
                    value={oldPassword || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasOldPasswordError}
                    className={hasOldPasswordError ? "mb-0" : "mb-2"}
                    type="password" />
                {hasOldPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.oldPassword)}</ul>}

                <label>Nowe hasło:</label>
                <FormInput
                    name="password"
                    value={password || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasPasswordError}
                    className={hasPasswordError ? "mb-0" : "mb-2"}
                    type="password" />
                {hasPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.password)}</ul>}

                <label>Powtórz:</label>
                <FormInput
                    name="repeatPassword"
                    value={repeatPassword || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasRepeatPasswordError}
                    className={hasRepeatPasswordError ? "mb-0" : "mb-2"}
                    type="password" />
                {hasRepeatPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.repeatPassword)}</ul>}

                <Button
                    onClick={this.submitForm}
                    outline
                    theme="info"
                    className="mt-2 ml-1 float-right">
                    Aktualizuj
                </Button>
            </div>
        );
    }

}

export default ResetPasswordForm;
