import React from "react";
import { Container} from "shards-react";


const LoginLayout = ({ children, noNavbar, noFooter }) => (
  <Container>
      {children}
  </Container>
);


export default LoginLayout;
