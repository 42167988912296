import React from "react";
import { ConfirmModal } from "../../common/Modal";
import * as Constants from '../../../constants';
import API from "../../../api/AxiosConfiguration";

class DeleteAccessGroupModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteRule = this.deleteRule.bind(this);
    }

    deleteRule(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(Constants.ACCESS_GROUPS_URL + "/" + uuid)
        .then(result => {
            if(result.status === 200) {
                onSuccess();
                toggleFunction();
            }
        })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz usunąć grupę dostępową?"
                    open={open}
                    toggle={toggle}
                    submitAction={this.deleteRule}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie wszystkich danych o grupie dostępowej.
                        Użytkownicy do niej przypisani utracą dostęp do przypisanej do niej roli dostępowych.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          
            </ConfirmModal>
        )
    }
    
}

export default DeleteAccessGroupModal;