import React, { Component } from 'react';
import { Row, Col, FormInput, Alert } from "shards-react";
import TooltippedSelect from './TooltippedSelect';
import * as AppConstants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import { Store } from '../../../flux';
import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'

const initFormAndFormErrorsState = {
    form: {
        title: '',
        warningThreshold: '',
        criticalThreshold: '',
        model: '',
        group: '',
        devices: [],
        timePeriodType: { label: 'minut', value: 'MINUTES' },
        timePeriod: { label: '10', value: '10', timePeriodType: 'MINUTES' },
        rules: '',
        dataType: '',
        components: [],
        dataPresentationType: '',
    },
    formErrors: {
        title: [],
        warningThreshold: [],
        criticalThreshold: [],
        model: [],
        group: [],
        dataType: [],
        dataPresentationType: [],
        rules: [],
        timePeriodType: [],
        timePeriod: [],
        devices: []
    }
}

const initTimePeriodHints = {
    timePeriods: [
        { label: '10', value: '10', timePeriodType: 'MINUTES' },
        { label: '30', value: '30', timePeriodType: 'MINUTES' },
        { label: '60', value: '60', timePeriodType: 'MINUTES' },

        { label: '1', value: '1', timePeriodType: 'HOURS' },
        { label: '2', value: '2', timePeriodType: 'HOURS' },
        { label: '3', value: '3', timePeriodType: 'HOURS' },
        { label: '6', value: '6', timePeriodType: 'HOURS' },
        { label: '12', value: '12', timePeriodType: 'HOURS' },

        { label: '1', value: '1', timePeriodType: 'DAYS' },
        { label: '5', value: '5', timePeriodType: 'DAYS' },
        { label: '7', value: '7', timePeriodType: 'DAYS' },
        { label: '14', value: '14', timePeriodType: 'DAYS' },
        { label: '21', value: '21', timePeriodType: 'DAYS' },
        { label: '30', value: '30', timePeriodType: 'DAYS' },

        { label: '1', value: '1', timePeriodType: 'MONTHS' },
        { label: '2', value: '2', timePeriodType: 'MONTHS' },
        { label: '3', value: '3', timePeriodType: 'MONTHS' },
    ],
    timePeriodTypes: [
        { label: 'minut', value: 'MINUTES' },
        { label: 'godzin', value: 'HOURS' },
        { label: 'dni', value: 'DAYS' },
        { label: 'miesiąc', value: 'MONTHS' }
    ],
}

const initFormHints = {
    formHints: {
        modules: [
            { label: 'Bezpieczeństwo sprzętowe', value: 'pcDevices' },
            { label: 'Bezpieczeństwo sieciowe', value: 'networkDevices' },
            { label: 'Bezpieczeństwo fizyczne', value: 'controlPanels' },
        ],
        models: [],
        groups: [],
        devices: [],
        rules: [],
        dataTypes: [],
        dataPresentationTypes: [],
        components: [],
        ...initTimePeriodHints
    }
}

class ConfigureDashboardtileUuidForm extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            ...initFormAndFormErrorsState,
            ...initFormHints,
            tileUuid: '',
            tileToEdit: '',
            activeConfigureTab: '',
            multipleDevices: false,
            isManagersLoading: true,
            showComponentAlert: false,
            isOptionsLoading: false,
            fetchedData: null,
            isFetched: false,
            firstRender: true,
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
        }

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
        this.getFormData = this.getFormData.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.findHintByValue = this.findHintByValue.bind(this);
        this.initEditFormData = this.initEditFormData.bind(this);
        this.devicesSelectChangeHandler = this.devicesSelectChangeHandler.bind(this);
    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        const { formType, uuid } = this.props;

        this._isMounted = true;
        if (formType === 'editTile') {
            API.get(`${AppConstants.DASHBOARD_TILES_URL}/${uuid}`)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            ...this.state,
                            tileToEdit: response.data,
                            activeConfigureTab: response.data.tileConfig.module
                        }, () => this.getFormData());
                    }
                }).catch(error => {
                    console.log(error);
                });

        }
    }

    componentDidUpdate(prevProps, prevState) {
        var prevDataTypeValue = prevState.form.dataType ? prevState.form.dataType : ""
        var currentDataTypeValue = this.state.form.dataType ? this.state.form.dataType : ""
        if (prevDataTypeValue.value !== currentDataTypeValue.value) {
            if (currentDataTypeValue && currentDataTypeValue.service) {
                this.fetchComponents();
            } else {
                this.setState({
                    showComponentAlert: true,
                    formHints: { ...this.state.formHints, components: [] }
                });
            }
        }
    }

    renderError(errors) {
        return errors.map((error, index) =>
            <li key={index}>{error}</li>
        )
    }

    initEditFormData() {
        const { tileToEdit, form } = this.state;

        const tempForm = {};

        const data = tileToEdit.tileConfig;

        if (data) {
            Object.keys(form).forEach(key => {
                if (key !== 'module' && key !== 'title' && key !== 'devices' &&
                    key !== 'warningThreshold' && key !== 'criticalThreshold' && key !== 'components') {
                    const option = this.findHintByValue(key, data[key], tempForm);
                    tempForm[key] = option;
                } else if (key === 'devices' && data['devices']) {
                    tempForm.devices = [];
                    data.devices.forEach(device => {
                        const option = this.findHintByValue('devices', device, tempForm);
                        tempForm.devices.push(option);
                    });
                } else if (key === 'components' && data['components']) {
                    tempForm.components = [];
                    data.components.forEach(component => {
                        const option = { label: component, value: component }
                        tempForm.components.push(option);
                    });
                };

            });

            this.setState({
                ...this.state,
                firstRender: false,
                form: {
                    ...this.state.form,
                    title: data.title,
                    warningThreshold: data.warningThreshold,
                    criticalThreshold: data.criticalThreshold,
                    ...tempForm
                }
            });
        }
    }

    fetchComponents() {
        const { devices, dataType } = this.state.form;
        const service = dataType.service;
        if (devices[0] && devices[0].value) {
            API.get(AppConstants.NETWORK_DEVICE_RULE_URL + "/components/" + devices[0].value, {
                params: {
                    service: service,
                }
            }).then((result) => {
                if (result.data.length <= 0) {
                    this.setState({
                        showComponentAlert: true,
                        formHints: { ...this.state.formHints, components: [] }
                    });
                } else {
                    this.setState({
                        showComponentAlert: false,
                        formHints: { ...this.state.formHints, components: result.data }
                    });
                }
            })
        }
    }

    clearFormHints() {

        let hints;

        hints = {
            formHints: {}
        }

        for (const key in this.state.form) {
            hints.formHints[key] = [];
        }

        this.setState({
            ...hints
        })
    }

    findHintByValue(inputName, value, tempForm) {
        const { formHints } = this.state;
        const isPlural = () => { const lastChar = inputName.slice(-1); return (lastChar === 's') ? true : false; },
            tempInputName = `${inputName}${(!isPlural()) ? 's' : ''}`;

        const foundOption = formHints[tempInputName].map(hint => {
            const tempHintKeys = Object.keys(hint);
            if (tempHintKeys.length === 3) {
                for (let i = 0; i < tempHintKeys.length; i++) {
                    if (tempHintKeys[i] !== 'label' && tempHintKeys[i] !== 'value' && tempHintKeys[i] !== 'service') {
                        if (this.state.activeConfigureTab === 'pcDevices' && inputName === 'dataType') {
                            if (hint[tempHintKeys[i]] === 'pc' && hint.value === value) return hint
                        } else if (inputName === 'rules') {
                            if (hint[tempHintKeys[i]] === tempForm[tempHintKeys[i]].value && value.includes(hint.value)) return hint
                        } else {
                            if (hint[tempHintKeys[i]] === tempForm[tempHintKeys[i]].value && hint.value === value) return hint
                        }
                    }
                }
            } else if (tempHintKeys.length > 3) {
                const flags = [];
                for (let i = 0; i < tempHintKeys.length; i++) {
                    if (tempHintKeys[i] === 'device') {
                        if (tempForm.devices.some(device => device.value === hint.device) || hint.device === null) flags.push(true);
                        else flags.push(false);
                    } else {
                        if (tempHintKeys[i] !== 'label' && tempHintKeys[i] !== 'value' && tempHintKeys[i] !== 'service') {
                            if (hint[tempHintKeys[i]] === tempForm[tempHintKeys[i]].value) flags.push(true)
                            else flags.push(false);
                        }
                    }
                }
                if (inputName === 'rules') {
                    if (!flags.includes(false) && value.includes(hint.value)) return hint;
                } else {
                    if (!flags.includes(false) && hint.value === value) return hint;
                }

            } else {
                return hint.value === value ? hint : undefined
            }

            return undefined

        }).find(option => !(option == null));

        if (foundOption == null) return "";
        return foundOption;
    }

    getDefaultThreshold() {
        const { form } = this.state;
        const { warningThreshold, criticalThreshold } = form;

        if (warningThreshold === '' && criticalThreshold === '') {
            switch (form.dataType.value) {
                case 'RAMUSAGE': {
                    return { warning: '85', critical: '95' };
                }
                case 'CPULOAD': {
                    return { warning: '85', critical: '95' };
                }
                case 'CPUTEMP': {
                    return { warning: '75', critical: '80' };
                }
                case 'RULE': {
                    return { warning: '3', critical: '10' };
                }
                default: {
                    return { warning: '', critical: '' };
                }
            }
        } else {
            return { warning: warningThreshold, critical: criticalThreshold };
        }
    }

    selectChangeHandler(selected, event, inputsToClearAfterChange) {
        var { form, formErrors } = this.state;

        var clearedElements = {}

        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        var item;
        if (Array.isArray(selected)) {
            item = selected.map(selectedItem => selectedItem.value);
        } else {
            item = selected
        }

        let thresholdDefault = this.getDefaultThreshold();



        this.setState({
            ...this.state,
            form: {
                ...form,
                warningThreshold: thresholdDefault.warning,
                criticalThreshold: thresholdDefault.critical,
                ...clearedElements,
                [event.name]: item
            },
            formErrors: {
                ...formErrors,
                [event.name]: []
            }
        }, () => {
            if (event.name === "timePeriodType") {
                let defaultOption;

                switch (selected.value) {
                    case 'MINUTES': {
                        defaultOption = { label: '10', value: '10', timePeriodType: 'MINUTES' };
                        break;
                    }
                    case 'HOURS': {
                        defaultOption = { label: '1', value: '1', timePeriodType: 'HOURS' }
                        break;
                    }
                    case 'DAYS': {
                        defaultOption = { label: '1', value: '1', timePeriodType: 'DAYS' };
                        break;
                    }
                    case 'MONTHS': {
                        defaultOption = { label: '1', value: '1', timePeriodType: 'MONTHS' };
                        break;
                    }
                    default: {
                        break;
                    }
                }

                this.setState({
                    form: {
                        ...this.state.form,
                        timePeriod: defaultOption
                    }
                })
            }
        });

    }

    devicesSelectChangeHandler = (selected, event, inputsToClearAfterChange) => {
        var clearedElements = {}
        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        const name = event.name;

        let devices;

        switch (event.action) {
            case "clear": {
                devices = {
                    form: {
                        ...this.state.form,
                        ...clearedElements,
                        [name]: ''
                    }
                }
                break;
            }
            case "select-option": {
                devices = {
                    form: {
                        ...this.state.form,
                        ...clearedElements,
                        [name]: selected
                    }
                }
                break;
            }
            case "remove-value": {
                devices = {
                    form: {
                        ...this.state.form,
                        ...clearedElements,
                        [name]: this.deleteValueFromSelectedDevices(event)
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        this.setState({
            ...devices
        }, () => {
            const { form } = this.state;

            this.setState({
                ...this.state,
                multipleDevices: form.devices.length > 1
            })
        });
    }

    deleteValueFromSelectedDevices(event) {
        return this.state.form[event.name]
            .filter(device => event.removedValue.value !== device.value)
    }

    submitForm(setTileConfig) {
        const { form, activeConfigureTab, tileToEdit } = this.state;
        const { formType } = this.props;

        let tempForm = {};
        if (this.validForm()) {

            for (const [key, value] of Object.entries(form)) {
                if (key === 'devices') {
                    tempForm['devices'] = [];
                    for (const device of form['devices']) {
                        tempForm['devices'].push(device.value);
                    }
                } else if (key === 'rules') {
                    tempForm['rules'] = [];
                    tempForm['rules'].push(value.value);
                } else if (key === 'components') {
                    tempForm['components'] = [];
                    for (const component of form['components']) {
                        tempForm['components'].push(component.value);
                    }
                } else {
                    if (value !== null && value !== "") {
                        tempForm[key] = value.value || value;
                    }
                }

            }

            tempForm['module'] = activeConfigureTab;
            tempForm.title = form.title;
            tempForm.warningThreshold = form.warningThreshold;
            tempForm.criticalThreshold = form.criticalThreshold;

            (formType === 'editTile') ? setTileConfig(tileToEdit.i, tempForm) : setTileConfig(tempForm);
        }
    }


    validForm() {
        const { form, formErrors } = this.state;
        const { title, model, group, dataType, dataPresentationType, rules, timePeriodType, timePeriod, devices } = form;


        var errorCount = 0;
        let errors;
        let tempTitleErrors = [],
            tempWarningThresholdErrors = [],
            tempCriticalThresholdErrors = [];



        errors = formErrors;

        errors['model'] = [];
        errors['group'] = [];
        errors['dataType'] = [];
        errors['dataPresentationType'] = [];
        errors['rules'] = [];
        errors['timePeriodType'] = [];
        errors['timePeriod'] = [];
        errors['devices'] = [];

        if (hasValueUrlOrHtmlTag(title)) {
            tempTitleErrors.push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (title.length < 3) {
            tempTitleErrors.push("Nazwa musi mieć co najmniej 3 znaki");
            errorCount++;
        }

        if (this.state.activeConfigureTab !== 'pcDevices' && !model) {
            errors["model"].push("To pole jest obowiązkowe!");
            errorCount++;
        }
        if (!group) {
            errors["group"].push("To pole jest obowiązkowe!");
            errorCount++;
        }
        if (!dataType) {
            errors["dataType"].push("To pole jest obowiązkowe!");
            errorCount++;
        }
        if (!dataPresentationType) {
            errors["dataPresentationType"].push("To pole jest obowiązkowe!");
            errorCount++;
        }
        if (dataType.value === 'RULE' && rules.length === 0) {
            errors["rules"].push("To pole jest obowiązkowe!");
            errorCount++;
        }

        if ((dataType.value !== 'RULE' && dataType !== '') && devices.length === 0) {
            errors["devices"].push("To pole jest wymagane dla wybranego typu danych!");
            errorCount++;
        }

        if (dataPresentationType.value === 'NUMBER') {

            if (!timePeriodType) {
                errors["timePeriodType"].push("To pole jest obowiązkowe!");
                errorCount++;
            }
            if (timePeriod === '') {
                errors["timePeriod"].push("To pole jest obowiązkowe!");
                errorCount++;
            }

        }

        if (dataPresentationType.value === 'NUMBER'
            && (this.state.form.dataType.value === 'CPULOAD' || this.state.form.dataType.value === 'RAMUSAGE'
                || this.state.form.dataType.value === 'CPUTEMP' || this.state.form.dataType.value === 'RULE')) {
            if (form.warningThreshold === '') {
                tempWarningThresholdErrors.push("To pole nie może być puste");
                errorCount++;
            }

            if (form.criticalThreshold === '') {
                tempCriticalThresholdErrors.push("To pole nie może być puste");
                errorCount++;
            }

            if (parseInt(form.warningThreshold) < 1) {
                tempWarningThresholdErrors.push("Próg nie może być niższy niż 1");
                errorCount++;
            }
            if (parseInt(form.criticalThreshold) < 1) {
                tempCriticalThresholdErrors.push("Próg nie może być niższy niż 1");
                errorCount++;
            }
        }

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...errors,
                title: tempTitleErrors,
                warningThreshold: tempWarningThresholdErrors,
                criticalThreshold: tempCriticalThresholdErrors,
            }
        });
        return !errorCount;
    }

    buildCreateDashboardForm() {
        const { form } = this.state;
        return {
            module: form.module,
        }
    }

    getFormData() {

        let formDataURL;

        // eslint-disable-next-line default-case
        switch (this.state.activeConfigureTab) {
            case 'pcDevices': {
                formDataURL = `${AppConstants.DASHBOARD_TILE_FORM_DATA_URL}/pc`;
                break;
            }
            case 'networkDevices': {
                formDataURL = `${AppConstants.DASHBOARD_TILE_FORM_DATA_URL}/network`;
                break;
            }
            case 'controlPanels': {
                formDataURL = `${AppConstants.DASHBOARD_TILE_FORM_DATA_URL}/controlpanel`;
                break;
            }
        }

        API.get(formDataURL)
            .then(response => {
                if (response.status === 200) {
                    const data = response.data;
                    this.setState({
                        ...this.state,
                        isFetched: true,
                        formHints: {
                            ...this.state.formHints,
                            models: data.models,
                            groups: data.groups,
                            devices: data.devices,
                            rules: data.rules,
                            dataTypes: data.dataTypes,
                            dataPresentationTypes: data.dataPresentationTypes
                        }
                    });
                }
            });

    }

    toggleTab(e) {
        const { activeConfigureTab } = this.state;
        const tabValue = e.currentTarget.dataset.value;

        if (activeConfigureTab !== tabValue) {

            this.setState({
                ...this.state,
                activeConfigureTab: tabValue,
                multipleDevices: false,
                form: { ...initFormAndFormErrorsState.form },
                formErrors: { ...initFormAndFormErrorsState.formErrors },
                formHints: { ...initFormHints.formHints }
            }, () => this.getFormData());

        }
    }

    getModuleMenu() {
        const { modules } = this.state.formHints;
        const { activeConfigureTab } = this.state;

        const activeBgStyle = {
            backgroundColor: '#007bff',
            color: '#fff',
        }

        const optionsNumber = modules.length,
            rows = [],
            options = [];

        let rowCounter = 0;

        for (let option = 1; option <= optionsNumber; option++) {
            options.push(<div style={activeConfigureTab === modules[option - 1].value ? activeBgStyle : null} key={modules[option - 1].value} className="configureDashboardTileFormMenuOption py-2" data-value={modules[option - 1].value} onClick={this.toggleTab}><p>{modules[option - 1].label}</p></div>);

            if (option % 3 === 0 || option === optionsNumber) {
                const isFirstRow = rows.length > 0 ? false : true;
                rows.push(React.createElement('div', { className: "configureDashboardTileFormMenuRow", key: rowCounter, style: !isFirstRow ? { borderTop: '0.1rem solid rgba(235, 235, 235, 1)' } : null }, ...options));
                rowCounter++;
                options.length = 0;
            }
        }

        return rows;
    }

    render() {
        const { formHints, formErrors, activeConfigureTab, isFetched, firstRender, form, showComponentAlert } = this.state;
        const { formType } = this.props;

        var hasModelError = Boolean(formErrors.model.length);
        var hasGroupError = Boolean(formErrors.group.length);
        var hasDataTypeError = Boolean(formErrors.dataType.length);
        var hasRuleError = Boolean(formErrors.rules.length);
        var hasDataPresentationTypeError = Boolean(formErrors.dataPresentationType.length);
        var hasTimePeriodTypeError = Boolean(formErrors.timePeriodType.length);
        var hasTimePeriodError = Boolean(formErrors.timePeriod.length);
        var haveDevicesError = Boolean(formErrors.devices.length);

        var hasTitleError = Boolean(formErrors.title.length);
        var hasWarningThresholdError = Boolean(formErrors.warningThreshold.length);
        var hasCriticalThresholdError = Boolean(formErrors.criticalThreshold.length);


        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        // const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);

        const getTitleInput = () => {
            return (
                <>
                    <label className="mt-3">Tytuł sekcji</label>
                    <FormInput
                        placeholder="Wpisz tytuł"
                        name="title"
                        value={this.state.form.title}
                        onChange={e => { this.setState({ ...this.state, form: { ...this.state.form, title: e.target.value }, formErrors: { ...this.state.formErrors, title: [] } }) }}
                        className={hasTitleError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasTitleError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.title)}</ul>}
                </>
            )
        }


        function getUniqueListBy(arr, key) {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        };


        const generateConfigureForm = () => {
            let thresholdUnit = '';


            if (this.state.form.dataPresentationType && this.state.form.dataPresentationType.value === "NUMBER"
                && (this.state.form.dataType.value === 'CPULOAD' || this.state.form.dataType.value === 'RAMUSAGE'
                    || this.state.form.dataType.value === 'CPUTEMP' || this.state.form.dataType.value === 'RULE')) {
                // eslint-disable-next-line default-case
                switch (this.state.form.dataType.value) {
                    case 'CPULOAD': {
                        thresholdUnit = 'procenty';
                        break;
                    }
                    case 'RAMUSAGE': {
                        thresholdUnit = 'procenty';
                        break;
                    }
                    case 'CPUTEMP': {
                        thresholdUnit = 'stopnie Celsjusza';
                        break;
                    }
                    case 'RULE': {
                        thresholdUnit = 'ilość wystąpień';
                        break;
                    }
                }
            }

            return (
                <Col sm="12">
                    {getTitleInput()}

                    {activeConfigureTab !== 'pcDevices' &&
                        <TooltippedSelect
                            isAsync
                            tooltip
                            label="Model"
                            tooltipContent="Model urządzenia"
                            placeholder="Wybierz model"
                            noOptionsMsg="Brak dostępnych opcji"
                            name="model"
                            value={this.state.form.model}
                            onChange={(item, event) => { this.selectChangeHandler(item, event, ['group', 'devices', 'rule', 'dataType', 'dataPresentationType']); }}
                            defaultOptions={this.state.formHints.models}
                            loadOptions={null}
                            hasError={hasModelError}
                            renderErrorFunc={() => renderError(this.state.formErrors.model)} />}

                    <TooltippedSelect
                        isAsync
                        tooltip
                        label="Grupa"
                        tooltipContent="Grupa urządzeń"
                        placeholder="Wybierz grupę"
                        noOptionsMsg="Brak dostępnych opcji"
                        name="group"
                        value={this.state.form.group}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ['devices', 'rule']); }}
                        defaultOptions={(this.state.activeConfigureTab !== 'pcDevices' && this.state.form.model.value) ? this.state.formHints.groups.filter(group => group.model === this.state.form.model.value)
                            : this.state.formHints.groups}
                        loadOptions={null}
                        isDisabled={this.state.activeConfigureTab !== 'pcDevices' && !this.state.form.model}
                        hasError={hasGroupError}
                        renderErrorFunc={() => renderError(this.state.formErrors.group)} />

                    <TooltippedSelect
                        isMulti
                        tooltip
                        helptip
                        helpContent="Niewybranie urządzenia może skutkować niedostępnością niektórych reguł"
                        label="Urządzenia"
                        tooltipContent="Urządzenia"
                        placeholder="Wybierz urządzenia"
                        noOptionsMsg="Brak dostępnych opcji"
                        name="devices"
                        value={this.state.form.devices ? this.state.form.devices : ""}
                        onChange={(item, event) => { this.devicesSelectChangeHandler(item, event, ['rule', 'dataType', 'dataPresentationType']); }}
                        defaultOptions={formHints.devices.filter(device => {
                            if (this.state.activeConfigureTab !== 'pcDevices') {
                                return device.group === this.state.form.group.value && device.model === this.state.form.model.value;
                            } else {
                                return device.group === this.state.form.group.value;
                            }
                        })}
                        isDisabled={!this.state.form.group}
                        hasError={haveDevicesError}
                        renderErrorFunc={() => renderError(this.state.formErrors.devices)} />
                    {this.state.multipleDevices && <p style={{ color: 'red', fontSize: '.9rem' }}>Niektóre typy prezentacji danych mogą być niedostępne z powodu wybrania więcej niż jednego urządzenia.</p>}

                    <TooltippedSelect
                        isAsync
                        tooltip
                        label="Rodzaj danych"
                        tooltipContent="Rodzaj danych"
                        placeholder="Wybierz rodzaj danych"
                        noOptionsMsg="Brak dostępnych opcji"
                        name="dataType"
                        value={this.state.form.dataType}
                        onChange={(item, event) => { this.selectChangeHandler(item, event, ['dataPresentationType', 'rule']); }}
                        defaultOptions={(this.state.activeConfigureTab !== 'pcDevices' && this.state.form.model.value) ? getUniqueListBy(this.state.formHints.dataTypes.filter(dataType => dataType.model === this.state.form.model.value), 'value')
                            : getUniqueListBy(this.state.formHints.dataTypes.filter(dataType => dataType.group === 'pc'), 'value')}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "dataType")}
                        isDisabled={this.state.activeConfigureTab !== 'pcDevices' && !this.state.form.model}
                        hasError={hasDataTypeError}
                        renderErrorFunc={() => renderError(this.state.formErrors.dataType)} />

                    {(this.state.form.dataType && this.state.form.dataType.value === "RULE") &&
                        <TooltippedSelect
                            isAsync
                            tooltip
                            label="Reguła"
                            tooltipContent="Reguła"
                            placeholder="Wybierz regułę"
                            noOptionsMsg="Brak dostępnych opcji"
                            name="rules"
                            value={this.state.form.rules}
                            onChange={this.selectChangeHandler}
                            defaultOptions={formHints.rules.filter(rule => {
                                if (activeConfigureTab !== 'pcDevices') {
                                    if (form.devices.length > 0) return rule.group === form.group.value && rule.model === form.model.value && (form.devices.some(device => device.value === rule.device || rule.device === null));
                                    else return rule.group === form.group.value && rule.model === form.model.value && rule.device === null;
                                } else {
                                    if (form.devices.length > 0) return rule.group === form.group.value && (form.devices.some(device => device.value === rule.device || rule.device === null));
                                    else return rule.group === form.group.value && rule.device === null;
                                }
                            })}
                            loadOptions={(inputValue) => filterOptionsPromise(inputValue, "rules")}
                            hasError={hasRuleError}
                            renderErrorFunc={() => renderError(this.state.formErrors.rules)} />}

                    {(this.state.form.dataType && this.state.form.dataType.value === "PORTBANDWIDTHIN") || (this.state.form.dataType && this.state.form.dataType.value === "PORTBANDWIDTHOUT") ?
                        <div>
                            {showComponentAlert && <Alert theme="warning" style={{ marginBottom: "0rem" }}>
                                Nie znaleziono żadnych komponentów. Prawdopodobnie urządzenie nie zostało wybrane lub nie zostały jeszcze pobrane. Spróbuj ponownie później.
                            </Alert>}
                            <TooltippedSelect
                                isMulti
                                isAsync
                                tooltip
                                label="Komponent"
                                tooltipContent="Komponent"
                                placeholder="Wybierz komponenty. Nie wybranie będzie skutkować wyświetleniem wszystkich."
                                noOptionsMsg="Brak dostępnych opcji"
                                name="components"
                                value={this.state.form.components}
                                onChange={(item, event) => { this.devicesSelectChangeHandler(item, event, ['dataPresentationType']); }}
                                defaultOptions={this.state.formHints.components}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "components")} />
                        </div>
                        : ""}

                    <TooltippedSelect
                        isAsync
                        tooltip
                        label="Rodzaj prezentacji danych"
                        tooltipContent="Rodzaj prezentacji danych"
                        placeholder="Wybierz rodzaj prezentacji danych"
                        noOptionsMsg="Brak dostępnych opcji"
                        name="dataPresentationType"
                        value={this.state.form.dataPresentationType}
                        onChange={this.selectChangeHandler}
                        isDisabled={!this.state.form.dataType}
                        defaultOptions={this.state.formHints.dataPresentationTypes.filter(dpt => dpt.dataType === this.state.form.dataType.value).filter(option => {
                            if (this.state.multipleDevices) return option.value !== 'PIE';
                            else return true;
                        })}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "dataPresentationType")}
                        hasError={hasDataPresentationTypeError}
                        renderErrorFunc={() => renderError(this.state.formErrors.dataPresentationType)} />

                    {(this.state.form.dataPresentationType && this.state.form.dataPresentationType.value === "LINE") || (this.state.form.dataType.value === 'RULE' && this.state.form.dataPresentationType.value === "NUMBER") ?
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="w-50 mr-2">
                                    <TooltippedSelect
                                        isAsync
                                        tooltip
                                        label="Okres"
                                        tooltipContent="Okres"
                                        noOptionsMsg="Brak dostępnych opcji"
                                        name="timePeriod"
                                        value={this.state.form.timePeriod}
                                        onChange={this.selectChangeHandler}
                                        defaultOptions={this.state.formHints.timePeriods.filter(timePeriod => timePeriod.timePeriodType === this.state.form.timePeriodType.value)}
                                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "timePeriodType")}
                                        hasError={hasTimePeriodError}
                                        renderErrorFunc={() => renderError(this.state.formErrors.timePeriodType)} />
                                </div>

                                <div className="w-50">
                                    <TooltippedSelect
                                        isAsync
                                        label={<span style={{ opacity: '0', pointerEvents: 'none' }}>Okres</span>}
                                        noOptionsMsg="Brak dostępnych opcji"
                                        name="timePeriodType"
                                        value={this.state.form.timePeriodType}
                                        onChange={this.selectChangeHandler}
                                        defaultOptions={this.state.formHints.timePeriodTypes}
                                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "timePeriodType")}
                                        hasError={hasTimePeriodTypeError}
                                        renderErrorFunc={() => renderError(this.state.formErrors.timePeriodType)} />
                                </div>
                            </div>
                        </>

                        : null
                    }
                    {(this.state.form.dataPresentationType && this.state.form.dataPresentationType.value === "NUMBER"
                        && (this.state.form.dataType.value === 'CPULOAD' || this.state.form.dataType.value === 'RAMUSAGE'
                            || this.state.form.dataType.value === 'CPUTEMP' || this.state.form.dataType.value === 'RULE')) ?
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="w-50 mr-2">
                                    <label className="mt-3">Próg ostrzegawczy ({thresholdUnit})</label>
                                    <FormInput
                                        name="warningThreshold"
                                        value={this.state.form.warningThreshold}
                                        onChange={e => {
                                            const val = e.target.value;

                                            if ((/^[0-9\b]+$/).test(val) || val === '')
                                                this.setState({
                                                    ...this.state,
                                                    form: {
                                                        ...this.state.form,
                                                        warningThreshold: val
                                                    },
                                                    formErrors: {
                                                        ...this.state.formErrors,
                                                        warningThreshold: []
                                                    }
                                                })
                                        }}
                                        className={hasWarningThresholdError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                                    {hasWarningThresholdError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.warningThreshold)}</ul>}
                                </div>

                                <div className="w-50">
                                    <label className="mt-3">Próg krytyczny ({thresholdUnit})</label>
                                    <FormInput
                                        name="criticalThreshold"
                                        value={this.state.form.criticalThreshold}
                                        onChange={e => {
                                            const val = e.target.value;

                                            if ((/^[0-9\b]+$/).test(val) || val === '')
                                                this.setState({
                                                    ...this.state,
                                                    form: {
                                                        ...this.state.form,
                                                        criticalThreshold: val
                                                    },
                                                    formErrors: {
                                                        ...this.state.formErrors,
                                                        criticalThreshold: []
                                                    }
                                                })
                                        }}
                                        className={hasCriticalThresholdError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                                    {hasCriticalThresholdError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.criticalThreshold)}</ul>}
                                </div>
                            </div>
                        </>

                        : null
                    }
                </Col>
            );
        }

        const filterOptionsPromise = (inputValue, optionName) => {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(this.filterOptions(inputValue, optionName));
                }, 500);
            });
        }

        const generatePickModuleInfo = () => {
            return (
                <Col>
                    <div className="mx-5 my-5" style={{ textAlign: 'center' }}>
                        <p>Wybierz moduł</p>
                    </div>
                </Col>
            );
        };

        return (
            <>
                {(formType === 'editTile' && isFetched && firstRender) && this.initEditFormData()}
                <Row>
                    <Col sm="12">
                        <div style={{ width: '100%', border: '0.1rem solid rgba(235, 235, 235, 1)' }}>
                            {this.getModuleMenu()}
                        </div>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    {activeConfigureTab ? generateConfigureForm() : generatePickModuleInfo()}
                </Row>
            </>
        );
    }
}

export default ConfigureDashboardtileUuidForm;
