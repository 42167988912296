import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner'

const LoadingSpinerComponent = (props) => {
    const { promiseInProgress } = usePromiseTracker();

    if (promiseInProgress === true) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column"
                style={{
                    height: '100%',
                    position: 'fixed',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    overflow: 'hidden',
                    background: "rgba(0,0,0,0.8)",
                    zIndex: "9999"
                }}>

                <img
                    id="main-logo"
                    className="d-inline-block align-top mr-1"
                    style={{ maxWidth: "300px" }}
                    src={require("../../images/logo_SOC.png")}
                    alt="Logo SOC Perceptus" />

                <Loader
                    type="ThreeDots"
                    color="#007bff"
                    height={100}
                    width={100}
                    visible={true} />
            </div>
        )
    }

    return null;

};

export default LoadingSpinerComponent;