import React from "react";
import PasswordChangeForm from "./forms/PasswordChangeForm";

class PasswordChange extends React.Component {

  render() {
    const {
      changeAttemptUuid
    } = this.props;

    return (
      <div>
        <h4 className="text-center">Zmiana hasła</h4>
        <PasswordChangeForm changeAttemptUuid={changeAttemptUuid} />
      </div>
    );
  }

}

export default PasswordChange;
