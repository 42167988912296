import React from "react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { Row, Col, FormInput } from "shards-react";
import Select from 'react-select';
import { EMAIL_REGEXP } from "../../../utils/Patterns";
import { Store } from "../../../flux"

const defaultSelectProps = {
    placeholder: "Wybierz",
    className: 'react-select-container mb-2',
    classNamePrefix: "react-select",
    menuPosition: "absolute",
    menuPlacement: "auto",
    noOptionsMessage: () => "Brak dostępnych opcji",
}

class ConfirmCompanyForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                email: '',
                accessGroup: '',
            },
            formErrors: {
                email: [],
                accessGroup: [],

            },
            formHints: {
                accessGroup: [],
            },
            company: Store.getSelectedCompany(),
            isOptionsLoading: true,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        form[event.name] = item ? item.value : '';
        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { email, accessGroup } = this.state.form;

        formErrors["email"] = [];
        formErrors["accessGroup"] = [];

        var errorCount = 0;

        if (!EMAIL_REGEXP.test(email)) {
            formErrors["email"].push("Adres email nie jest poprawny");
            errorCount++;
        }

        if (accessGroup.length <= 0) {
            formErrors["accessGroup"].push("Grupa dostępowa musi zostać wybrana");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;
        this.setState({
            formErrors: []
        })

        var requestBody = {
            form: form
        }

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(Constants.CUSTOMERS_URL + "/" + uuid, requestBody)
            } else {
                apiPromise = API.put(Constants.CUSTOMERS_URL, requestBody);
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    fetchCustomerOptions() {
        API.get(Constants.CUSTOMERS_URL + "/form/options/create").then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: result.data
                })
            }
        });
    }

    fetchCustomerForm(uuid) {
        API.get(Constants.CUSTOMERS_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: result.data
                })
            }
        });
    }

    updateComponent() {
        const { uuid } = this.props;
        this.fetchCustomerOptions();
        if (uuid.length) {
            this.fetchCustomerForm(uuid);
        }
    }

    findSelectItemByValue(optionName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[optionName].find(option => option.value === value);
        if (foundOption)
            return foundOption;
        return '';
    }

    render() {
        const { company } = this.state;
        const { email, accessGroup } = this.state.form;
        const { formHints } = this.state;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasEmailError = Boolean(this.state.formErrors.email.length);
        var hasAccessGroupError = Boolean(this.state.formErrors.accessGroup.length);

        return (
            <Row>
                <Col sm="6">
                    <label>*Email</label>
                    <FormInput
                        name="email"
                        value={email}
                        onChange={this.inputChangeHandler}
                        invalid={hasEmailError}
                        className={hasEmailError ? "mb-0" : "mb-2"} />
                    {hasEmailError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.email)}</ul>}
                </Col>

                <Col sm="6">
                    <label>*Grupa dostępowa</label>
                    <Select
                        {...defaultSelectProps}
                        name="accessGroup"
                        value={this.findSelectItemByValue("accessGroup", accessGroup)}
                        onChange={this.selectChangeHandler}
                        className={hasAccessGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        options={formHints.accessGroup.filter(hint => hint.parentValue === company.value)} />
                    {hasAccessGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.accessGroup)}</ul>}
                </Col>
            </Row>
        );
    }

}

export default ConfirmCompanyForm;