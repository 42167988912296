import React from "react";
import { Nav } from "shards-react";

import ChatWidget from "./ChatWidget";
import UserActions from "./UserActions";
import NotificationsWidget from "./NotificationsWidget";

export default () => (
  <Nav navbar className="border-left flex-row">
    <ChatWidget />
    <NotificationsWidget />
    <UserActions />
  </Nav>
);
