import React, { Component } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Collapse, FormCheckbox } from "shards-react";
import AddAuthenticatorTokenModal from "../components/authenticator-token/modals/AddAuthenticatorTokenModal";
import UpdateAuthenticatorTokenModal from "../components/authenticator-token/modals/UpdateAuthenticatorTokenModal.js";
import DeleteAuthenticatorTokenModal from "../components/authenticator-token/modals/DeleteAuthenticatorTokenModal.js";
import TwoFactorModal from "../components/authenticator-token/modals/TwoFactorModal.js";
import TwoFactorDeactivateModal from "../components/authenticator-token/modals/TwoFactorDeactivateModal.js"
import TrustedDevicesTable from "../components/authenticator-token/tables/TrustedDevicesTable.js"
import { Redirect } from 'react-router-dom';

import PageTitle from "../components/common/PageTitle";
import WindowSizeListener from 'react-window-size-listener';
import API from "../api/AxiosConfiguration";
import * as Constants from '../constants';
import * as socToast from '../utils/SocToast'

export default class AuthenticatorToken extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isCreateAuthenticatorTokenModalShow: false,
      isUpdateAuthenticatorTokenModalShow: false,
      isDeleteAuthenticatorTokenModalShow: false,
      isTwoFactorModalShow: false,
      isTwoFactorDeactivateModalShow: false,
      tokenState: false,
      createTokenType: "",
      updateTokenType: "",
      updateToken: {},
      deleteTokenUuid: "",
      emailTokens: [],
      smsTokens: [],
      hardwareTokens: [],
      softwareTokens: [],
      securityKeys: [],
      securityKeysOtp: [],
      isMobile: false,
      collapseEmail: false,
      collapseSms: false,
      collapseHardware: false,
      collapseSoftware: false,
      collapseSecurityKey: false,
      collapseSecurityKeyOtp: false,
      collapseTrustedDevice: false,
      acceptFunction: "",
      twoFactorOperation: "",
    };

    this.collapseEmailChange = this.collapseEmailChange.bind(this);
    this.collapseSmsChange = this.collapseSmsChange.bind(this);
    this.collapseHardwareChange = this.collapseHardwareChange.bind(this);
    this.collapseSoftwareChange = this.collapseSoftwareChange.bind(this);
    this.collapseSecurityKeyChange = this.collapseSecurityKeyChange.bind(this);
    this.collapseSecurityKeyOtpChange = this.collapseSecurityKeyOtpChange.bind(this);
    this.collapseTrustedDeviceChange = this.collapseTrustedDeviceChange.bind(this);
    this.getEmailTokens = this.getEmailTokens.bind(this);
    this.getSmsTokens = this.getSmsTokens.bind(this);
    this.getHardwareTokens = this.getHardwareTokens.bind(this);
    this.getSoftwareTokens = this.getSoftwareTokens.bind(this);
    this.getSecurityKeys = this.getSecurityKeys.bind(this);
    this.getSecurityKeysOtp = this.getSecurityKeysOtp.bind(this);
    this.emailTokensChange = this.emailTokensChange.bind(this);
    this.smsTokensChange = this.smsTokensChange.bind(this);
    this.hardwareTokensChange = this.hardwareTokensChange.bind(this);
    this.softwareTokensChange = this.softwareTokensChange.bind(this);
    this.securityKeysChange = this.securityKeysChange.bind(this);
    this.securityKeysOtpChange = this.securityKeysOtpChange.bind(this);
    this.createAuthenticatorTokenModal = this.createAuthenticatorTokenModal.bind(this);
    this.deleteAuthenticatorTokenModal = this.deleteAuthenticatorTokenModal.bind(this);
    this.updateAuthenticatorTokenModal = this.updateAuthenticatorTokenModal.bind(this);
    this.twoFactorModal = this.twoFactorModal.bind(this);
    this.toggleCreateAuthenticatorTokenModal = this.toggleCreateAuthenticatorTokenModal.bind(this);
    this.toggleUpdateAuthenticatorTokenModal = this.toggleUpdateAuthenticatorTokenModal.bind(this);
    this.toggleDeleteAuthenticatorTokenModal = this.toggleDeleteAuthenticatorTokenModal.bind(this);
    this.toggleTwoFactorDeactivateModal = this.toggleTwoFactorDeactivateModal.bind(this);
    this.twoFactorDeactivateModal = this.twoFactorDeactivateModal.bind(this);
    this.twoFactor = this.twoFactor.bind(this);
    this.toggleTwoFactorModal = this.toggleTwoFactorModal.bind(this);
    this.updateComponent = this.updateComponent.bind(this);
    this.updateTwoFactorOperation = this.updateTwoFactorOperation.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateComponent();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateTwoFactorOperation(uuid) {
    this.setState({
      twoFactorOperation: uuid,
    });
  }

  updateComponent() {
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/twoFactor').then((result) => {
      if (result.status === 200) {
        if (this._isMounted)
          this.setState({ tokenState: result.data });
      }
    });
  }

  toggleCreateAuthenticatorTokenModal() {
    if (this._isMounted)
      this.setState({
        isCreateAuthenticatorTokenModalShow: !this.state.isCreateAuthenticatorTokenModalShow,
      });
    this.getEmailTokens();
    this.getSoftwareTokens();
    this.getHardwareTokens();
    this.getSmsTokens();
    this.getSecurityKeys();
    this.getSecurityKeysOtp();
  }

  createAuthenticatorTokenModal(e, tokenType) {
    e.stopPropagation();
    if (this._isMounted)
      this.setState({
        createTokenType: tokenType
      });
    if (this._isMounted)
      this.setState({ acceptFunction: this.toggleCreateAuthenticatorTokenModal }, () => {
        this.twoFactorModal();
      });
  }

  toggleUpdateAuthenticatorTokenModal() {
    if (this._isMounted)
      this.setState({
        isUpdateAuthenticatorTokenModalShow: !this.state.isUpdateAuthenticatorTokenModalShow,
      });
    this.getEmailTokens();
    this.getSoftwareTokens();
    this.getHardwareTokens();
    this.getSmsTokens();
    this.getSecurityKeys();
    this.getSecurityKeysOtp();
  }

  updateAuthenticatorTokenModal(tokenType, updateToken) {
    if (this._isMounted)
      this.setState({
        updateTokenType: tokenType,
        updateToken: updateToken
      });
    this.toggleUpdateAuthenticatorTokenModal();
  }

  toggleDeleteAuthenticatorTokenModal() {
    if (this._isMounted)
      this.setState({
        isDeleteAuthenticatorTokenModalShow: !this.state.isDeleteAuthenticatorTokenModalShow,
      });
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/twoFactor').then((result) => {
      if (result.status === 200) {
        if (this._isMounted)
          this.setState({ tokenState: result.data });
      }
    });
    this.getEmailTokens();
    this.getSoftwareTokens();
    this.getHardwareTokens();
    this.getSmsTokens();
    this.getSecurityKeys();
    this.getSecurityKeysOtp();
  }

  deleteAuthenticatorTokenModal(deleteTokenUuid) {
    if (this._isMounted)
      this.setState({
        deleteTokenUuid: deleteTokenUuid
      });
    if (this._isMounted)
      this.setState({ acceptFunction: this.toggleDeleteAuthenticatorTokenModal }, () => {
        this.twoFactorModal();
      });
  }

  twoFactor() {
    var tokenState = !this.state.tokenState;
    API.put(Constants.AUTHENTICATOR_TOKEN_URL + '/twoFactor/' + tokenState).then((result) => {
      if (result.status === 200) {
        if (this._isMounted)
          this.setState({ tokenState: !this.state.tokenState });
      } else if (result.status === 204) {
        socToast.error("Brak tokenu", "Aby włączyć Uwierzytelnianie Dwupoziomowe musisz najpierw dodać token uwierzytelniający")
      }
    });
  }

  toggleTwoFactorModal() {
    if (this._isMounted)
      this.setState({
        isTwoFactorModalShow: !this.state.isTwoFactorModalShow,
      });
    this.getEmailTokens();
    this.getSoftwareTokens();
    this.getHardwareTokens();
    this.getSmsTokens();
    this.getSecurityKeys();
    this.getSecurityKeysOtp();
  }

  twoFactorModal() {
    this.toggleTwoFactorModal();
  }

  toggleTwoFactorDeactivateModal() {
    if (this._isMounted)
      this.setState({
        isTwoFactorDeactivateModalShow: !this.state.isTwoFactorDeactivateModalShow,
      });
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/twoFactor').then((result) => {
      if (result.status === 200) {
        if (this._isMounted)
          this.setState({ tokenState: result.data });
      }
    }).catch((error) => { socToast.error("Błąd połączenia z serverem", "Wystąpił problem z połączeniem") });
  }

  twoFactorDeactivateModal() {
    this.toggleTwoFactorDeactivateModal();
  }

  collapseEmailChange() {
    if (this._isMounted)
      this.setState({
        collapseEmail: !this.state.collapseEmail,
        collapseSms: false,
        collapseHardware: false,
        collapseSoftware: false,
        collapseSecurityKey: false,
        collapseSecurityKeyOtp: false,
        collapseTrustedDevice: false
      });
    if (!this.state.collapseEmail) {
      this.getEmailTokens();
    }
  }

  getEmailTokens() {
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/list/MAIL').then((result) => {
      if (result.status === 200) {
        this.emailTokensChange(result.data)
      }
    });
  }

  collapseSmsChange() {
    if (this._isMounted)
      this.setState({
        collapseEmail: false,
        collapseSms: !this.state.collapseSms,
        collapseHardware: false,
        collapseSoftware: false,
        collapseSecurityKey: false,
        collapseSecurityKeyOtp: false,
        collapseTrustedDevice: false
      });
    if (!this.state.collapseSms) {
      this.getSmsTokens();
    }
  }

  getSmsTokens() {
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/list/SMS').then((result) => {
      if (result.status === 200) {
        this.smsTokensChange(result.data)
      }
    });
  }

  collapseHardwareChange() {
    if (this._isMounted)
      this.setState({
        collapseEmail: false,
        collapseSms: false,
        collapseHardware: !this.state.collapseHardware,
        collapseSoftware: false,
        collapseSecurityKey: false,
        collapseSecurityKeyOtp: false,
        collapseTrustedDevice: false
      });
    if (!this.state.collapseHardware) {
      this.getHardwareTokens();
    }
  }

  getHardwareTokens() {
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/list/HARDWARE').then((result) => {
      if (result.status === 200) {
        this.hardwareTokensChange(result.data)
      }
    });
  }

  collapseSoftwareChange() {
    if (this._isMounted)
      this.setState({
        collapseEmail: false,
        collapseSms: false,
        collapseHardware: false,
        collapseSoftware: !this.state.collapseSoftware,
        collapseSecurityKey: false,
        collapseSecurityKeyOtp: false,
        collapseTrustedDevice: false
      });
    if (!this.state.collapseSoftware) {
      this.getSoftwareTokens();
    }
  }

  getSoftwareTokens() {
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/list/SOFTWARE').then((result) => {
      if (result.status === 200) {
        this.softwareTokensChange(result.data)
      }
    });
  }

  collapseSecurityKeyChange() {
    if (this._isMounted)
      this.setState({
        collapseEmail: false,
        collapseSms: false,
        collapseHardware: false,
        collapseSoftware: false,
        collapseTrustedDevice: false,
        collapseSecurityKeyOtp: false,
        collapseSecurityKey: !this.state.collapseSecurityKey
      });
    if (!this.state.collapseSecurityKey) {
      this.getSecurityKeys();
    }
  }

  getSecurityKeys() {
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/list/SECURITYKEY').then((result) => {
      if (result.status === 200) {
        this.securityKeysChange(result.data)
      }
    });
  }

  collapseTrustedDeviceChange() {
    if (this._isMounted)
      this.setState({
        collapseEmail: false,
        collapseSms: false,
        collapseHardware: false,
        collapseSoftware: false,
        collapseSecurityKey: false,
        collapseSecurityKeyOtp: false,
        collapseTrustedDevice: !this.state.collapseTrustedDevice
      });
  }

  collapseSecurityKeyOtpChange() {
    if (this._isMounted)
      this.setState({
        collapseEmail: false,
        collapseSms: false,
        collapseHardware: false,
        collapseSoftware: false,
        collapseTrustedDevice: false,
        collapseSecurityKeyOtp: !this.state.collapseSecurityKeyOtp,
        collapseSecurityKey: false
      });
    if (!this.state.collapseSecurityKeyOtp) {
      this.getSecurityKeysOtp();
    }
  }

  getSecurityKeysOtp() {
    API.get(Constants.AUTHENTICATOR_TOKEN_URL + '/list/SECURITYKEYOTP').then((result) => {
      if (result.status === 200) {
        this.securityKeysOtpChange(result.data)
      }
    });
  }

  emailTokensChange(emailTokenList) {
    if (this._isMounted)
      this.setState({
        emailTokens: emailTokenList
      });
  }

  smsTokensChange(smsTokenList) {
    if (this._isMounted)
      this.setState({
        smsTokens: smsTokenList
      });
  }

  hardwareTokensChange(hardwareTokenList) {
    if (this._isMounted)
      this.setState({
        hardwareTokens: hardwareTokenList
      });
  }

  softwareTokensChange(softwareTokenList) {
    if (this._isMounted)
      this.setState({
        softwareTokens: softwareTokenList
      });
  }

  securityKeysChange(securityKeysList) {
    if (this._isMounted)
      this.setState({
        securityKeys: securityKeysList
      });
  }

  securityKeysOtpChange(securityKeysOtpList) {
    if (this._isMounted)
      this.setState({
        securityKeysOtp: securityKeysOtpList
      });
  }

  checkboxChangeHandler() {
    if (this.state.tokenState) {
      if (this._isMounted)
        this.setState({ acceptFunction: this.toggleTwoFactorDeactivateModal }, () => {
          this.twoFactorModal();
        });
    } else {
      this.twoFactor();
    }

  }

  render() {
    let emailTokens = null;
    emailTokens = this.state.emailTokens.map(singleEmailToken => {
      return (
        <TableEmailTokenRow uuid={singleEmailToken.uuid}
          tokenState={singleEmailToken.tokenState}
          name={singleEmailToken.name}
          email={singleEmailToken.email}
          isMobile={this.state.isMobile}
          key={singleEmailToken.uuid}
          updateAuthenticatorTokenModal={this.updateAuthenticatorTokenModal}
          deleteAuthenticatorTokenModal={this.deleteAuthenticatorTokenModal} />);
    });

    let smsTokens = null;
    smsTokens = this.state.smsTokens.map(singleSmsToken => {
      return (
        <TableSmsTokenRow uuid={singleSmsToken.uuid}
          tokenState={singleSmsToken.tokenState}
          name={singleSmsToken.name}
          phoneNumber={singleSmsToken.phoneNumber}
          isMobile={this.state.isMobile}
          key={singleSmsToken.uuid}
          updateAuthenticatorTokenModal={this.updateAuthenticatorTokenModal}
          deleteAuthenticatorTokenModal={this.deleteAuthenticatorTokenModal} />);
    });

    let hardwareTokens = null;
    hardwareTokens = this.state.hardwareTokens.map(singleHardwareToken => {
      return (
        <TableHardwareTokenRow uuid={singleHardwareToken.uuid}
          tokenState={singleHardwareToken.tokenState}
          name={singleHardwareToken.name}
          tokenSerial={singleHardwareToken.tokenSerial}
          isMobile={this.state.isMobile}
          key={singleHardwareToken.uuid}
          updateAuthenticatorTokenModal={this.updateAuthenticatorTokenModal}
          deleteAuthenticatorTokenModal={this.deleteAuthenticatorTokenModal} />);
    });

    let softwareTokens = null;
    softwareTokens = this.state.softwareTokens.map(singleSoftwareToken => {
      return (
        <TableSoftwareTokenRow uuid={singleSoftwareToken.uuid}
          tokenState={singleSoftwareToken.tokenState}
          name={singleSoftwareToken.name}
          isMobile={this.state.isMobile}
          key={singleSoftwareToken.uuid}
          updateAuthenticatorTokenModal={this.updateAuthenticatorTokenModal}
          deleteAuthenticatorTokenModal={this.deleteAuthenticatorTokenModal} />);
    });

    let securityKeys = null;
    securityKeys = this.state.securityKeys.map(singleSecurityKey => {
      return (
        <TableSecurityKeyRow uuid={singleSecurityKey.uuid}
          tokenState={singleSecurityKey.tokenState}
          name={singleSecurityKey.name}
          isMobile={this.state.isMobile}
          key={singleSecurityKey.uuid}
          updateAuthenticatorTokenModal={this.updateAuthenticatorTokenModal}
          deleteAuthenticatorTokenModal={this.deleteAuthenticatorTokenModal} />);
    });

    let securityKeysOtp = null;
    securityKeysOtp = this.state.securityKeysOtp.map(singleSecurityKeyOtp => {
      return (
        <TableSecurityKeyRow uuid={singleSecurityKeyOtp.uuid}
          tokenState={singleSecurityKeyOtp.tokenState}
          name={singleSecurityKeyOtp.name}
          isMobile={this.state.isMobile}
          key={singleSecurityKeyOtp.uuid}
          updateAuthenticatorTokenModal={this.updateAuthenticatorTokenModal}
          deleteAuthenticatorTokenModal={this.deleteAuthenticatorTokenModal} />);
    });

    let action = null;
    if (this.state.isMobile) {
      action = null;
    } else {
      action =
        <th scope="col" className="border-0">
          Akcja
      </th>;
    }
    const {
      isCreateAuthenticatorTokenModalShow,
      isUpdateAuthenticatorTokenModalShow,
      isDeleteAuthenticatorTokenModalShow,
      isTwoFactorModalShow,
      isTwoFactorDeactivateModalShow
    } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <WindowSizeListener onResize={(windowSize) =>
          windowSize.windowWidth > 480 ? this.setState({ isMobile: false }) : this.setState({ isMobile: true })}>
        </WindowSizeListener>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle title="Tokeny" subtitle="Konfiguracja" className="text-sm-left mb-3" />
        </Row>
        <FormCheckbox name="tokenState" toggle checked={this.state.tokenState} onChange={(e) => this.checkboxChangeHandler()} className="mb-3"> Aktywne Uwierzytelnianie Dwupoziomowe </FormCheckbox>
        {/* Default Light Table */}
        <Row>
          <Col>
            <Card small className="mb-1">
              <CardHeader onClick={this.collapseEmailChange} className="border-bottom">
                <h6 className="mb-0 mt-1 float-left">Tokeny Email</h6>
                <Button onClick={(e) => this.createAuthenticatorTokenModal(e, "MAIL")} outline theme="info" className="float-right mb-0"> Dodaj Token </Button>
              </CardHeader>
              <Collapse open={this.state.collapseEmail}>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Status
            </th>
                        <th scope="col" className="border-0">
                          Nazwa
            </th>
                        <th scope="col" className="border-0">
                          Email
            </th>
                        {action}
                      </tr>
                    </thead>
                    <tbody>
                      {emailTokens}
                    </tbody>
                  </table>
                </CardBody>
              </Collapse>
            </Card>
            <Card small className="mb-1">
              <CardHeader onClick={this.collapseSmsChange} className="border-bottom">
                <h6 className="mb-0 mt-1 float-left">Tokeny Sms</h6>
                <Button onClick={(e) => this.createAuthenticatorTokenModal(e, "SMS")} outline theme="info" className="float-right mb-0"> Dodaj Token </Button>
              </CardHeader>
              <Collapse open={this.state.collapseSms}>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Status
            </th>
                        <th scope="col" className="border-0">
                          Nazwa
            </th>
                        <th scope="col" className="border-0">
                          Numer
            </th>
                        {action}
                      </tr>
                    </thead>
                    <tbody>
                      {smsTokens}
                    </tbody>
                  </table>
                </CardBody>
              </Collapse>
            </Card>
            <Card small className="mb-1">
              <CardHeader onClick={this.collapseHardwareChange} className="border-bottom">
                <h6 className="mb-0 mt-1 float-left">Tokeny Kartowe</h6>
                <Button onClick={(e) => this.createAuthenticatorTokenModal(e, "HARDWARE")} outline theme="info" className="float-right mb-0"> Dodaj Token </Button>
              </CardHeader>
              <Collapse open={this.state.collapseHardware}>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Status
            </th>
                        <th scope="col" className="border-0">
                          Nazwa
            </th>
                        <th scope="col" className="border-0">
                          Serial
            </th>
                        {action}
                      </tr>
                    </thead>
                    <tbody>
                      {hardwareTokens}
                    </tbody>
                  </table>
                </CardBody>
              </Collapse>
            </Card>
            <Card small className="mb-1">
              <CardHeader onClick={this.collapseSoftwareChange} className="border-bottom">
                <h6 className="mb-0 mt-1 float-left">Tokeny Programowe</h6>
                <Button onClick={(e) => this.createAuthenticatorTokenModal(e, "SOFTWARE")} outline theme="info" className="float-right mb-0"> Dodaj Token </Button>
              </CardHeader>
              <Collapse open={this.state.collapseSoftware}>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Status
            </th>
                        <th scope="col" className="border-0">
                          Nazwa
            </th>
                        {action}
                      </tr>
                    </thead>
                    <tbody>
                      {softwareTokens}
                    </tbody>
                  </table>
                </CardBody>
              </Collapse>
            </Card>
            <Card small className="mb-1">
              <CardHeader onClick={this.collapseSecurityKeyChange} className="border-bottom">
                <h6 className="mb-0 mt-1 float-left">Klucze zabezpieczające</h6>
                <Button onClick={(e) => this.createAuthenticatorTokenModal(e, "SECURITYKEY")} outline theme="info" className="float-right mb-0"> Dodaj Klucz </Button>
              </CardHeader>
              <Collapse open={this.state.collapseSecurityKey}>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Status
            </th>
                        <th scope="col" className="border-0">
                          Nazwa
            </th>
                        {action}
                      </tr>
                    </thead>
                    <tbody>
                      {securityKeys}
                    </tbody>
                  </table>
                </CardBody>
              </Collapse>
            </Card>
            <Card small className="mb-1">
              <CardHeader onClick={this.collapseSecurityKeyOtpChange} className="border-bottom">
                <h6 className="mb-0 mt-1 float-left">Klucze zabezpieczające OTP</h6>
                <Button onClick={(e) => this.createAuthenticatorTokenModal(e, "SECURITYKEYOTP")} outline theme="info" className="float-right mb-0"> Dodaj Klucz </Button>
              </CardHeader>
              <Collapse open={this.state.collapseSecurityKeyOtp}>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Status
            </th>
                        <th scope="col" className="border-0">
                          Nazwa
            </th>
                        {action}
                      </tr>
                    </thead>
                    <tbody>
                      {securityKeysOtp}
                    </tbody>
                  </table>
                </CardBody>
              </Collapse>
            </Card>
            <Card small className="mb-1">
              <CardHeader onClick={this.collapseTrustedDeviceChange} className="border-bottom">
                <h6 className="mb-0 mt-1 float-left">Urządzenia zaufane</h6>
              </CardHeader>
              <Collapse open={this.state.collapseTrustedDevice}>
                <TrustedDevicesTable />
              </Collapse>
            </Card>
          </Col>
        </Row>
        <AddAuthenticatorTokenModal open={isCreateAuthenticatorTokenModalShow} twoFactorOperation={this.state.twoFactorOperation}
          toggle={this.toggleCreateAuthenticatorTokenModal} type={this.state.createTokenType} />
        <UpdateAuthenticatorTokenModal open={isUpdateAuthenticatorTokenModalShow}
          toggle={this.toggleUpdateAuthenticatorTokenModal} type={this.state.updateTokenType} updateToken={this.state.updateToken} />
        <DeleteAuthenticatorTokenModal open={isDeleteAuthenticatorTokenModalShow} twoFactorOperation={this.state.twoFactorOperation}
          toggle={this.toggleDeleteAuthenticatorTokenModal} deleteTokenUuid={this.state.deleteTokenUuid} />
        <TwoFactorModal open={isTwoFactorModalShow} acceptFunction={this.state.acceptFunction} updateTwoFactorOperation={this.updateTwoFactorOperation}
          toggle={this.toggleTwoFactorModal} />
        <TwoFactorDeactivateModal open={isTwoFactorDeactivateModalShow} twoFactorOperation={this.state.twoFactorOperation}
          toggle={this.toggleTwoFactorDeactivateModal} />
      </Container>
    )
  }

}

export class TableEmailTokenRow extends Component {
  renderRedirect = () => {
    return <Redirect to='/details-switch' />
  }

  render() {

    let status = null;

    if (this.props.tokenState === true) {
      status = <i className="fas fa-check status-ok"></i>;
    } else {
      status = <i className="fas fa-times-circle status-error"></i>;
    }

    let buttonDetails = null;
    if (this.props.isMobile) {
      buttonDetails = null;
    } else {
      buttonDetails =
        <td>
          <Button onClick={() => this.props.updateAuthenticatorTokenModal("MAIL", this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Edytuj
      </Button>
          <Button onClick={() => this.props.deleteAuthenticatorTokenModal(this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Usuń
      </Button>
        </td>;
    }

    const clickRow = () => {
      if (this.props.isMobile) {
        window.location = '/details-switch';
      }
    }

    return (
      <tr onClick={clickRow}>
        <td>{status}</td>
        <td>{this.props.name}</td>
        <td>{this.props.email}</td>
        {buttonDetails}
      </tr>

    )
  }
}

export class TableSmsTokenRow extends Component {
  renderRedirect = () => {
    return <Redirect to='/details-switch' />
  }

  render() {

    let status = null;

    if (this.props.tokenState === true) {
      status = <i className="fas fa-check status-ok"></i>;
    } else {
      status = <i className="fas fa-times-circle status-error"></i>;
    }

    let buttonDetails = null;
    if (this.props.isMobile) {
      buttonDetails = null;
    } else {
      buttonDetails =
        <td>
          <Button onClick={() => this.props.updateAuthenticatorTokenModal("SMS", this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Edytuj
      </Button>
          <Button onClick={() => this.props.deleteAuthenticatorTokenModal(this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Usuń
      </Button>
        </td>;
    }

    const clickRow = () => {
      if (this.props.isMobile) {
        window.location = '/details-switch';
      }
    }

    return (
      <tr onClick={clickRow}>
        <td>{status}</td>
        <td>{this.props.name}</td>
        <td>{this.props.phoneNumber}</td>
        {buttonDetails}
      </tr>

    )
  }
}

export class TableHardwareTokenRow extends Component {
  renderRedirect = () => {
    return <Redirect to='/details-switch' />
  }

  render() {

    let status = null;

    if (this.props.tokenState === true) {
      status = <i className="fas fa-check status-ok"></i>;
    } else {
      status = <i className="fas fa-times-circle status-error"></i>;
    }

    let buttonDetails = null;
    if (this.props.isMobile) {
      buttonDetails = null;
    } else {
      buttonDetails =
        <td>
          <Button onClick={() => this.props.updateAuthenticatorTokenModal("HARDWARE", this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Edytuj
      </Button>
          <Button onClick={() => this.props.deleteAuthenticatorTokenModal(this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Usuń
      </Button>
        </td>;
    }

    const clickRow = () => {
      if (this.props.isMobile) {
        window.location = '/details-switch';
      }
    }

    return (
      <tr onClick={clickRow}>
        <td>{status}</td>
        <td>{this.props.name}</td>
        <td>{this.props.tokenSerial}</td>
        {buttonDetails}
      </tr>

    )
  }
}

export class TableSoftwareTokenRow extends Component {
  renderRedirect = () => {
    return <Redirect to='/details-switch' />
  }

  render() {

    let status = null;

    if (this.props.tokenState === true) {
      status = <i className="fas fa-check status-ok"></i>;
    } else {
      status = <i className="fas fa-times-circle status-error"></i>;
    }

    let buttonDetails = null;
    if (this.props.isMobile) {
      buttonDetails = null;
    } else {
      buttonDetails =
        <td>
          <Button onClick={() => this.props.updateAuthenticatorTokenModal("SOFTWARE", this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Edytuj
      </Button>
          <Button onClick={() => this.props.deleteAuthenticatorTokenModal(this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Usuń
      </Button>
        </td>;
    }

    const clickRow = () => {
      if (this.props.isMobile) {
        window.location = '/details-switch';
      }
    }

    return (
      <tr onClick={clickRow}>
        <td>{status}</td>
        <td>{this.props.name}</td>
        {buttonDetails}
      </tr>

    )
  }
}

export class TableSecurityKeyRow extends Component {
  renderRedirect = () => {
    return <Redirect to='/details-switch' />
  }

  render() {

    let status = null;

    if (this.props.tokenState === true) {
      status = <i className="fas fa-check status-ok"></i>;
    } else {
      status = <i className="fas fa-times-circle status-error"></i>;
    }

    let buttonDetails = null;
    if (this.props.isMobile) {
      buttonDetails = null;
    } else {
      buttonDetails =
        <td>
          <Button onClick={() => this.props.updateAuthenticatorTokenModal("SECURITYKEY", this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Edytuj
      </Button>
          <Button onClick={() => this.props.deleteAuthenticatorTokenModal(this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Usuń
      </Button>
        </td>;
    }

    const clickRow = () => {
      if (this.props.isMobile) {
        window.location = '/details-switch';
      }
    }

    return (
      <tr onClick={clickRow}>
        <td>{status}</td>
        <td>{this.props.name}</td>
        {buttonDetails}
      </tr>

    )
  }
}

export class TableSecurityKeyRowOtp extends Component {
  renderRedirect = () => {
    return <Redirect to='/details-switch' />
  }

  render() {

    let status = null;

    if (this.props.tokenState === true) {
      status = <i className="fas fa-check status-ok"></i>;
    } else {
      status = <i className="fas fa-times-circle status-error"></i>;
    }

    let buttonDetails = null;
    if (this.props.isMobile) {
      buttonDetails = null;
    } else {
      buttonDetails =
        <td>
          <Button onClick={() => this.props.updateAuthenticatorTokenModal("SECURITYKEYOTP", this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Edytuj
      </Button>
          <Button onClick={() => this.props.deleteAuthenticatorTokenModal(this.props.uuid)} outline theme="info" className="mb-1 mr-1">
            Usuń
      </Button>
        </td>;
    }

    const clickRow = () => {
      if (this.props.isMobile) {
        window.location = '/details-switch';
      }
    }

    return (
      <tr onClick={clickRow}>
        <td>{status}</td>
        <td>{this.props.name}</td>
        {buttonDetails}
      </tr>

    )
  }
}
