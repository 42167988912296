import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button } from "shards-react";
import NagiosConfigurationForm from './forms/NagiosConfigurationForm';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

class NagiosConfiguration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.formRef = React.createRef();
        this.onCheckConnectionAndSaveClick = this.onCheckConnectionAndSaveClick.bind(this);
    }

    onCheckConnectionAndSaveClick() {
        this.formRef.current.submitForm();
    }

    render() {
        return (
            <Card>
                <CardHeader className="border-bottom d-inline">
                    <h6 className="m-0">Konfiguracja Proxy
                        <Tooltip title="Dane konfiguracyjne do oprogramowania zbierającego informacje z urządzeń, które jest zainstalowane na dedykowanej maszynie." placement="bottom" arrow>
                            <span style={{ marginLeft: '0.5rem' }}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </span>
                        </Tooltip>
                    </h6>
                </CardHeader>
                <CardBody>
                    <NagiosConfigurationForm ref={this.formRef} />
                </CardBody>
                <CardFooter>
                    <Button onClick={this.onCheckConnectionAndSaveClick} outline theme="info" className="mb-2 mr-1 float-right">Zapisz</Button>
                </CardFooter>
            </Card>
        );
    }

}

export default NagiosConfiguration;