import React from "react";
import { ConfirmModal} from "../../common/Modal";

import UpdateAuthenticatorTokenForm from "../forms/UpdateAuthenticatorTokenForm"


export default class UpdateAuthenticatorTokenModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            updateToken
        } = this.props;

        return (
            <ConfirmModal
                title="Edycja opcji autoryzacji"
                open={open}
                toggle={toggle}
                updateToken={updateToken}
                submitAction={() => this.refs.updateAuthenticatorTokenForm.submitForm()}
                positiveButtonText="Edytuj Token"
                negativeButtonText="Anuluj">

                <UpdateAuthenticatorTokenForm toggle={toggle} updateToken={updateToken} ref="updateAuthenticatorTokenForm" />

            </ConfirmModal>
        )
    }
}
