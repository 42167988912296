import React from 'react';
import { Card, CardHeader, CardBody, Button, Row, Col, ListGroup, ListGroupItem } from 'shards-react';
import { Store } from "../../flux";
import * as AppConstants from '../../constants';
import API from "../../api/AxiosConfiguration";
import * as Constants from "../../constants";

import UploadDictionaryModal from './modals/UploadDictionaryModal';
import withLoader from '../common/hoc/withLoader';
import BootstrapTable from "react-bootstrap-table-next";

class ControlPanelDictionaries extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isUploadDictionaryModalShow: false,
            selectedDictionaryType: "",
            dictionaryRows: [],
        };

        this.toggleUploadControlPanelDictionaryModal = this.toggleUploadControlPanelDictionaryModal.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.fetchDictionaries = this.fetchDictionaries.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateComponent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateComponent() {
        this.fetchDictionaries();
    }

    fetchDictionaries() {
        const { uuid } = this.props;
        this.props.setLoading(true);
        API.post(Constants.CONTROL_PANEL_DICTIONARIES_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    var dictionariesData = result.data;
                    if (this._isMounted)
                        this.setState({
                            dictionaryRows: dictionariesData,
                        })
                }

                this.props.setLoading(false);
            })
    }

    toggleUploadControlPanelDictionaryModal(dictionaryType) {
        if (this._isMounted)
            this.setState({
                isUploadDictionaryModalShow: !this.state.isUploadDictionaryModalShow,
                selectedDictionaryType: dictionaryType,
            });
    }

    render() {
        const {
            isUploadDictionaryModalShow,
            selectedDictionaryType,
            dictionaryRows
        } = this.state;

        const {
            uuid
        } = this.props;

        const expandRow = {
            renderer: row => (
                <DictionaryDetailsContainer row={row} />
            ),
        };

        const DictionaryDetailsContainer = ({ row }) => {
            return (
                <Row className="mb-2 mx-1">
                    <Col className="mt-2" sm="12">
                        <DictionaryDetailsCard dictionary={row.dictionary} />
                    </Col>
                </Row>
            )
        }

        const DictionaryDetailsCard = ({ dictionary }) => {
            return (
                <Card className="h-100 mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        {dictionary.length ? <EventCardRows rows={dictionary} /> : renderDeviceEventsFetchError()}
                    </CardBody>
                </Card>
            )
        }

        const EventCardRows = ({ rows, withoutBottomDivider }) => {
            return rows.map((element, index) => {
                return (
                    <div className="detail-row-container">
                        <div className="small-padding">
                            <div className="details-row-content">{element}</div>
                        </div>
                        {!withoutBottomDivider && <div className="divider" />}
                    </div>
                )
            })
        }

        function dictionaryImportFormatter(cell, row) {
            return (
                <Button onClick={() => this.formatExtraData.toggleUploadControlPanelDictionaryModal(cell)} outline theme="info" className="mr-1">
                    Importuj Słownik
                </Button>
            );
        }

        const styles = {
            float: "left",
            display: "inline",
            marginTop: "8px",
            fontSize: ".8725rem"
        }

        function dictionaryTypeFormatter(cell, row) {
            return (
                <div>
                    <span className="ml-auto text-right text-semibold text-fiord-blue" style={styles}>{cell}</span>
                </div>
            );
        }

        const dictionaryTypeColumns = [{
            dataField: 'dictionaryTypeName',
            align: "left",
            text: "",
            formatter: dictionaryTypeFormatter
        }, {
            dataField: 'dictionaryType',
            align: "right",
            text: "",
            formatExtraData: this,
            formatter: dictionaryImportFormatter
        }]

        const renderDictionaries = () => (
            <Row className="mr-0 ml-0">
                <Col xs="12" className="mb-2 pr-0 pl-0">
                    <BootstrapTable
                        bootstrap4={true}
                        keyField='dictionaryType'
                        data={dictionaryRows}
                        columns={dictionaryTypeColumns}
                        classes="table mb-0"
                        headerClasses="d-none"
                        bordered={false}
                        hover
                        expandRow={expandRow}
                        rowStyle={{ height: '10px' }} />

                </Col>
            </Row>
        )

        const renderDeviceEventsFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Brak danych</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <React.Fragment>
                <Row className="mt-2 mb-2 position-relative">
                    {this.props.children}
                    <Col>
                        <Card className="mb-3">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Słowniki</h6>
                                <div className="block-handle" />
                            </CardHeader>

                            <CardBody className="p-0">
                                {dictionaryRows.length ? renderDictionaries() : renderDeviceEventsFetchError()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <UploadDictionaryModal open={isUploadDictionaryModalShow} controlPanelUuid={uuid} dictionaryType={selectedDictionaryType}
                    toggle={this.toggleUploadControlPanelDictionaryModal} onSuccess={() => this.updateComponent()} />
            </React.Fragment>
        );
    }

}

export default withLoader(ControlPanelDictionaries);
