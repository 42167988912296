import React from "react";
import {
    Row,
    Col,
    FormInput,
    DatePicker,
} from "shards-react";
import pl from 'date-fns/locale/pl';
import AsyncCreatableSelect from 'react-select/async-creatable';

import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { Store } from "../../../flux";

class ConfirmAbsenceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                firstName: '',
                lastName: '',
                fromDate: undefined,
                toDate: undefined,
                absenceType: '',
                phoneNumber: '',
                integraUsernames: '',
            },
            formErrors: {
                firstName: [],
                lastName: [],
                fromDate: [],
                toDate: []
            },
            formHints: {
                integraUsernames: [],
            },
            isMobileView: false
        };

        this.onWindowResizeFluxListener = this.onWindowResizeFluxListener.bind(this);

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.dateChangeHandler = this.dateChangeHandler.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        this.fetchIntegraUsernames();
        if (uuid.length) {
            this.fetchControlPanelApplicationForm(uuid);
        }
    }

    componentWillMount() {
        Store.addChangeListener(this.onWindowResizeFluxListener);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onWindowResizeFluxListener);
    }

    fetchControlPanelApplicationForm = (uuid) => {
        API.get(Constants.CONTROL_PANEL_ABSENCES_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: result.data
                })
            }
        });
    }

    fetchIntegraUsernames = () => {
        API.get(Constants.CONTROL_PANEL_ABSENCES_URL + "/integra/users")
            .then((result) => {
                if (result.status === 200) {
                    this.setState({
                        formHints: {
                            integraUsernames: result.data,
                        }
                    })
                }
            })
    }

    onWindowResizeFluxListener() {
        this.setState({
            ...this.state,
            isMobileView: Store.isMobileView()
        })
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    dateChangeHandler = (date, formName) => {
        var { form, formErrors } = this.state;
        form[formName] = date ? date.getTime() : undefined;
        formErrors[formName] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event, inputsToClearAfterChange) => {
        var { form, formErrors } = this.state;

        var clearedElements = {}
        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        var item;
        if (Array.isArray(selected)) {
            item = selected.map(selectedItem => selectedItem.value);
        } else {
            item = selected
        }

        this.setState({
            form: {
                ...form,
                ...clearedElements,
                [event.name]: item
            },
            formErrors: {
                ...formErrors,
                [event.name]: []
            }
        });
    }

    findSelectLabelByValue = (name, value) => {
        var hint;
        var labels = [];
        var list = this.state.formHints[name];

        if (Array.isArray(value)) {
            value.forEach(valueArrayItem => {
                hint = list.find(item => valueArrayItem === item.value)
                if (hint)
                    labels.push(hint.label);
                else
                    labels = value;
            })
        } else {
            hint = list.find(item => value === item.value)
            if (hint)
                labels = hint.label;
            else
                labels = value;
        }
        return labels;
    }

    validForm() {
        const { formErrors } = this.state;
        const { firstName, lastName, fromDate, toDate } = this.state.form;

        formErrors["firstName"] = [];
        formErrors["lastName"] = [];
        formErrors["fromDate"] = [];
        formErrors["toDate"] = [];

        var errorCount = 0;

        if (firstName.length <= 0) {
            formErrors["firstName"].push("Imię nie może być puste");
            errorCount++;
        }

        if (lastName.length <= 0) {
            formErrors["lastName"].push("Nazwisko nie może być puste");
            errorCount++;
        }

        if (!fromDate) {
            formErrors["fromDate"].push("Data nieobecności nie może być pusta");
            errorCount++;
        }

        if (!toDate) {
            formErrors["toDate"].push("Data nieobecności nie może być pusta");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;

        var requestBody = {
            form: form
        }

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(Constants.CONTROL_PANEL_ABSENCES_URL + "/" + uuid, requestBody)
            } else {
                apiPromise = API.post(Constants.CONTROL_PANEL_ABSENCES_URL, requestBody);
            }

            apiPromise.then((result) => {
                if (result.status === 201 || result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    render() {
        const { firstName, lastName, fromDate, toDate, absenceType, phoneNumber, integraUsernames } = this.state.form;
        const { isMobileView } = this.state

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const filterOptions = (inputValue, optionName) => {
            var list = this.state.formHints[optionName];

            return list.filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
        }

        var hasFirstNameError = Boolean(this.state.formErrors.firstName.length);
        var hasLastNameError = Boolean(this.state.formErrors.lastName.length);
        var hasFromDateError = Boolean(this.state.formErrors.fromDate.length);
        var hasToDateError = Boolean(this.state.formErrors.toDate.length);

        return (
            <Row className="justify-content-md-center">
                <Col sm="12" md="6">
                    <label>*Imię</label>
                    <FormInput
                        name="firstName"
                        value={firstName || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasFirstNameError}
                        className={hasFirstNameError ? "mb-0" : "mb-2"} />
                    {hasFirstNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.firstName)}</ul>}

                    <label>*Nazwisko</label>
                    <FormInput
                        name="lastName"
                        value={lastName || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasLastNameError}
                        className={hasLastNameError ? "mb-0" : "mb-2"} />
                    {hasLastNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.lastName)}</ul>}

                    <label>*Od</label>
                    <DatePicker
                        selected={fromDate}
                        startDate={fromDate}
                        onChange={date => this.dateChangeHandler(date, "fromDate")}
                        locale={pl}
                        dateFormat="dd-MM-yyyy"
                        withPortal={isMobileView}
                        invalid={hasFromDateError}
                        className={hasFromDateError ? "mb-0" : "mb-2"} />
                    {hasFromDateError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.fromDate)}</ul>}

                    <label>*Do</label>
                    <DatePicker
                        selected={toDate}
                        startDate={toDate}
                        onChange={date => this.dateChangeHandler(date, "toDate")}
                        locale={pl}
                        dateFormat="dd-MM-yyyy"
                        withPortal={isMobileView}
                        invalid={hasToDateError}
                        className={hasToDateError ? "mb-0" : "mb-2"} />
                    {hasToDateError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.toDate)}</ul>}

                    <label>Typ</label>
                    <FormInput
                        name="absenceType"
                        value={absenceType || ''}
                        onChange={this.inputChangeHandler}
                        className="mb-2" />

                    <label>Numer telefonu</label>
                    <FormInput
                        name="phoneNumber"
                        value={phoneNumber || ''}
                        onChange={this.inputChangeHandler}
                        className="mb-2" />


                    <label>Nazwa użytkownika w centrali</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        isMulti
                        name="integraUsernames"
                        value={integraUsernames ? integraUsernames.map(integraUsernames => { return { label: this.findSelectLabelByValue("integraUsernames", integraUsernames), value: integraUsernames } }) : ""}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.integraUsernames}
                        className={"react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "integraUsernames")}
                        isClearable />

                    <br></br>
                    <br></br>
                </Col>
            </Row>
        );
    }
}

export default ConfirmAbsenceForm;
