import React from "react";
import { ConfirmModal } from "../../common/Modal";

import AddMailAuthenticatorTokenForm from "../forms/AddMailAuthenticatorTokenForm"
import AddSmsAuthenticatorTokenForm from "../forms/AddSmsAuthenticatorTokenForm"
import AddHardwareAuthenticatorTokenForm from "../forms/AddHardwareAuthenticatorTokenForm"
import AddSoftwareAuthenticatorTokenForm from "../forms/AddSoftwareAuthenticatorTokenForm"
import AddSecurityKeyForm from "../forms/AddSecurityKeyForm"
import AddSecurityKeyOtpForm from "../forms/AddSecurityKeyOtpForm"

export default class AddAuthenticatorTokenModal extends React.Component {

    renderAddMail(open, toggle, twoFactorOperation) {
        return (
            <ConfirmModal
                title="Dodanie nowej opcji autoryzacji"
                open={open}
                toggle={toggle}
                submitAction={() => this.refs.addMailAuthenticatorTokenForm.submitForm()}
                positiveButtonText="Dodaj"
                negativeButtonText="Anuluj">

                <AddMailAuthenticatorTokenForm toggle={toggle} twoFactorOperation={twoFactorOperation} ref="addMailAuthenticatorTokenForm" />

            </ConfirmModal>
        )
    }

    renderAddSms(open, toggle, twoFactorOperation) {
        return (
            <ConfirmModal
                title="Dodanie nowej opcji autoryzacji"
                open={open}
                toggle={toggle}
                submitAction={() => this.refs.addSmsAuthenticatorTokenForm.submitForm()}
                positiveButtonText="Dodaj"
                negativeButtonText="Anuluj">

                <AddSmsAuthenticatorTokenForm toggle={toggle} twoFactorOperation={twoFactorOperation} ref="addSmsAuthenticatorTokenForm" />

            </ConfirmModal>
        )
    }

    renderAddHardware(open, toggle, twoFactorOperation) {
        return (
            <ConfirmModal
                title="Dodanie nowej opcji autoryzacji"
                open={open}
                toggle={toggle}
                submitAction={() => this.refs.addHardwareAuthenticatorTokenForm.submitForm()}
                positiveButtonText="Dodaj"
                negativeButtonText="Anuluj">

                <AddHardwareAuthenticatorTokenForm toggle={toggle} twoFactorOperation={twoFactorOperation} ref="addHardwareAuthenticatorTokenForm" />

            </ConfirmModal>
        )
    }

    renderAddSoftware(open, toggle, twoFactorOperation) {
        return (
            <ConfirmModal
                title="Dodanie nowej opcji autoryzacji"
                open={open}
                toggle={toggle}
                submitAction={() => this.refs.addSoftwareAuthenticatorTokenForm.submitForm()}
                positiveButtonText="Dodaj"
                negativeButtonText="Anuluj">

                <AddSoftwareAuthenticatorTokenForm toggle={toggle} twoFactorOperation={twoFactorOperation} ref="addSoftwareAuthenticatorTokenForm" />

            </ConfirmModal>
        )
    }

    renderAddSecurityKey(open, toggle, twoFactorOperation) {
        return (
            <ConfirmModal
                title="Dodanie nowej opcji autoryzacji"
                open={open}
                toggle={toggle}
                submitAction={() => this.refs.addSecurityKeyForm.submitForm()}
                positiveButtonText="Dodaj"
                negativeButtonText="Anuluj">

                <AddSecurityKeyForm toggle={toggle} twoFactorOperation={twoFactorOperation} ref="addSecurityKeyForm" />

            </ConfirmModal>
        )
    }

    renderAddSecurityKeyOtp(open, toggle, twoFactorOperation) {
        return (
            <ConfirmModal
                title="Dodanie nowej opcji autoryzacji"
                open={open}
                toggle={toggle}
                submitAction={() => this.refs.addSecurityKeyOtpForm.submitForm()}
                positiveButtonText="Dodaj"
                negativeButtonText="Anuluj">

                <AddSecurityKeyOtpForm toggle={toggle} twoFactorOperation={twoFactorOperation} ref="addSecurityKeyOtpForm" />

            </ConfirmModal>
        )
    }

    render() {
        const {
            open,
            toggle,
            type,
            twoFactorOperation
        } = this.props;

        return type === "MAIL" ?
            this.renderAddMail(open, toggle, twoFactorOperation) :
            type === "SMS" ?
                this.renderAddSms(open, toggle, twoFactorOperation) :
                type === "HARDWARE" ?
                    this.renderAddHardware(open, toggle, twoFactorOperation) :
                    type === "SOFTWARE" ?
                        this.renderAddSoftware(open, toggle, twoFactorOperation) :
                        type === "SECURITYKEYOTP" ?
                            this.renderAddSecurityKeyOtp(open, toggle, twoFactorOperation) :
                            this.renderAddSecurityKey(open, toggle, twoFactorOperation);
    }

}
