import React from "react";
import { DatePicker, FormGroup, Col, Row, FormInput, FormRadio } from "shards-react";
import Select from 'react-select';
import pl from 'date-fns/locale/pl'

import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';

class DeviceSearcherForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isFilterResultModalShow: false,
            filterAllOptionLabel: "Wszystkie",
            form: {
                searchValue: "",
                searchValueIn: "",
                deviceGroup: "Wszystkie",
                deviceType: "Wszystkie",
                manufacturer: "Wszystkie",
                model: "Wszystkie",
                startWarrantyDate: undefined,
                endWarrantyDate: undefined,
            },
            formErrors: {
                searchValue: [],
                searchValueIn: [],
            }
        };

        this.setStartWarrantyDate = this.setStartWarrantyDate.bind(this);
        this.setEndWarrantyDate = this.setEndWarrantyDate.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.validForm = this.validForm.bind(this);
    }

    setStartWarrantyDate = (date) => {
        this.setState({
            form: {
                ...this.state.form,
                startWarrantyDate: date
            }
        })
    }

    setEndWarrantyDate = (date) => {
        this.setState({
            form: {
                ...this.state.form,
                endWarrantyDate: date
            }
        })
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
        this.validForm();
    }

    selectChangeHandler = (selected, event) => {
        var { form } = this.state;
        form[event.name] = selected;
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { searchValue, searchValueIn } = this.state.form;
        const { setFormError } = this.props

        formErrors["searchValue"] = [];
        formErrors["searchValueIn"] = [];

        var errorCount = 0;
        if (searchValueIn.length > 0) {
            if (searchValue.length < 3 && searchValue.length > 0) {
                formErrors["searchValue"].push("Wyszukiwana wartość powinna składać się co najmniej z 3 znaków");
                errorCount++;
            }
        } else {
            if (searchValue.length > 0) {
                formErrors["searchValueIn"].push("Jedno z pól musi zostać wybrane");
                errorCount++;
            }
        }

        this.setState({ formErrors: formErrors });
        setFormError(Boolean(errorCount))
        return !errorCount;
    }

    submitForm = () => {
        const { form, formErrors } = this.state;

        if (this.validForm()) {
            API.post(Constants.NETWORK_DEVICE_URL + "/find", {
                form: {
                    ...form,
                    deviceGroup: form.deviceGroup ? form.deviceGroup.label : null,
                    deviceType: form.deviceType ? form.deviceType.value : null,
                    manufacturer: form.manufacturer ? form.manufacturer.label : null,
                    model: form.model ? form.model.label : null,
                }
            }).then((result) => {
                if (result.status === 200) {
                    this.props.onSuccess(result.data);
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    render() {
        const { searchValue, searchValueIn, deviceGroup, deviceType, manufacturer, model, startWarrantyDate, endWarrantyDate } = this.state.form;
        const { isMobileView, filterOptions } = this.props;

        const hasSearchValueError = Boolean(this.state.formErrors.searchValue.length);
        const hasSearchValueInError = Boolean(this.state.formErrors.searchValueIn.length);

        const defaultSelectProps = {
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            noOptionsMessage: () => "Brak dostępnych opcji",
            placeholder: this.state.filterAllOptionLabel
        }

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        return (
            <Row>
                <Col md="4">
                    <label className="filter-label" htmlFor="search-filter">Wyszukaj:</label>
                    <FormGroup>
                        <FormInput
                            name="searchValue"
                            value={searchValue || ''}
                            onChange={this.inputChangeHandler}
                            placeholder="Wpisz wyszukiwaną wartość"
                            invalid={hasSearchValueError}
                            className={hasSearchValueError ? "mb-0" : "mb-2"} />
                        {hasSearchValueError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.searchValue)}</ul>}
                    </FormGroup>

                    <FormGroup>
                        <label className="filter-label">Uwzględnij w:</label>
                        <FormRadio
                            name="searchValueIn"
                            value="name"
                            onChange={this.inputChangeHandler}
                            invalid={hasSearchValueInError}
                            checked={searchValueIn === "name"} >Nazwa urządzenia</FormRadio>
                        <FormRadio
                            name="searchValueIn"
                            value="mac"
                            onChange={this.inputChangeHandler}
                            invalid={hasSearchValueInError}
                            checked={searchValueIn === "mac"}>MAC</FormRadio>
                        <FormRadio
                            name="searchValueIn"
                            value="deviceUser"
                            onChange={this.inputChangeHandler}
                            invalid={hasSearchValueInError}
                            checked={searchValueIn === "deviceUser"}
                            className={hasSearchValueError ? "mb-0" : "mb-2"}>Użytkownik</FormRadio>
                        {hasSearchValueInError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.searchValueIn)}</ul>}
                    </FormGroup>
                </Col>
                <Col md="8">
                    <Row>
                        <Col sm="12" md="6">
                            <FormGroup>
                                <label className="filter-label" htmlFor="search-filter">Grupa:</label>
                                <Select
                                    {...defaultSelectProps}
                                    name="deviceGroup"
                                    value={deviceGroup}
                                    onChange={this.selectChangeHandler}
                                    options={filterOptions.group} />
                            </FormGroup>

                            <FormGroup>
                                <label className="filter-label" htmlFor="search-filter">Typ:</label>
                                <Select
                                    {...defaultSelectProps}
                                    name="deviceType"
                                    value={deviceType}
                                    onChange={this.selectChangeHandler}
                                    options={filterOptions.type} />
                            </FormGroup>
                        </Col>

                        <Col sm="12" md="6">
                            <FormGroup>
                                <label className="filter-label" htmlFor="search-filter">Producent:</label>
                                <Select
                                    {...defaultSelectProps}
                                    name="manufacturer"
                                    value={manufacturer}
                                    onChange={this.selectChangeHandler}
                                    options={filterOptions.manufacturer} />
                            </FormGroup>

                            <FormGroup>
                                <label className="filter-label" htmlFor="search-filter">Model:</label>
                                <Select
                                    {...defaultSelectProps}
                                    name="model"
                                    value={model}
                                    onChange={this.selectChangeHandler}
                                    options={filterOptions.model} />
                            </FormGroup>
                        </Col>

                        <Col md="12">
                            <FormGroup>
                                <label className="filter-label" htmlFor="search-filter">Okres wsparcia:</label>
                                <div className="input-daterange input-group">
                                    <DatePicker
                                        locale={pl}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="Data rozpoczęcia"
                                        selected={startWarrantyDate}
                                        onChange={this.setStartWarrantyDate}
                                        selectsStart
                                        startDate={startWarrantyDate}
                                        endDate={endWarrantyDate}
                                        withPortal={isMobileView} />

                                    <DatePicker
                                        locale={pl}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="Data zakończenia"
                                        selected={endWarrantyDate}
                                        onChange={this.setEndWarrantyDate}
                                        selectsEnd
                                        startDate={startWarrantyDate}
                                        endDate={endWarrantyDate}
                                        minDate={startWarrantyDate}
                                        withPortal={isMobileView} />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default DeviceSearcherForm;