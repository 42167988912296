import React from 'react';
import { Row, Col, FormInput, FormCheckbox } from 'shards-react';
import AsyncSelect from 'react-select/async';

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';
import * as socToast from '../../../../utils/SocToast';

const defaultSelectProps = {
    placeholder: "Wybierz",
    className: 'react-select-container mb-2',
    classNamePrefix: "react-select",
    menuPosition: "absolute",
menuPlacement: "auto",
    noOptionsMessage: () => "Brak dostępnych opcji",
}

const renderError = (errors) => {
    return errors.map((error, index) =>
        <li key={index}>{error}</li>
    )
}

class ConfirmCategoryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                threshold: 0,
                type: "",
                sensitiveContent: [],
                regexp: "",
                dictionary: ""
            },
            formErrors: {
                name: [],
                threshold: [],
                type: [],
                sensitiveContent: [],
                regexp: "",
                dictionary: ""
            },
            formHints: {
                type: [],
                sensitiveContent: [],
                dictionary: []
            },
            isOptionsLoading: false
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.checkboxArrayChangeHandler = this.checkboxArrayChangeHandler.bind(this);
        this.checkboxArrayIsChecked = this.checkboxArrayIsChecked.bind(this);

        this.fetchCategoryForm = this.fetchCategoryForm.bind(this);
        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);

        this.submitForm = this.submitForm.bind(this);
        this.validForm = this.validForm.bind(this);
        this.buildForm = this.buildForm.bind(this);

        this.RegexpForm = this.RegexpForm.bind(this);
        this.DictionaryForm = this.DictionaryForm.bind(this);
        this.SensitiveContentForm = this.SensitiveContentForm.bind(this);

        this.findHintByValue = this.findHintByValue.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;

        if (uuid.length) {
            this.fetchAvailableOptions(() => {
                this.fetchCategoryForm(uuid);
            });
        } else {
            this.fetchAvailableOptions();
        }
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event) => {
        var { form, formErrors } = this.state;
        if (event.name === "type") {
            form.sensitiveContent = []; formErrors.sensitiveContent = [];
            form.regexp = []; formErrors.regexp = [];
            form.dictionary = []; formErrors.dictionary = [];
        }

        form[event.name] = selected;
        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    checkboxArrayChangeHandler = (name, value) => {
        var { form, formErrors } = this.state;
        if (!Array.isArray(form[name])) return false;
        if (form[name].includes(value)) {
            var index = form[name].indexOf(value);
            if (index > -1) {
                form[name].splice(index, 1);
            }
        } else {
            form[name].push(value);
        }

        formErrors[name] = [];
        this.setState({ form: form });
    }

    checkboxArrayIsChecked = (name, value) => {
        var { form } = this.state;
        if (!Array.isArray(form[name])) return false;
        return form[name].includes(value)
    }

    validForm() {
        const { formErrors, formHints } = this.state;
        const { name, threshold, type, sensitiveContent, regexp, dictionary } = this.state.form;

        formErrors["name"] = [];
        formErrors["threshold"] = [];
        formErrors["type"] = [];
        formErrors["sensitiveContent"] = [];
        formErrors["regexp"] = [];
        formErrors["dictionary"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 255) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 255 znaków");
            errorCount++;
        }

        if (threshold.length < 0) {
            formErrors["threshold"].push("Próg wykrywania nie może być mniejszy od 0");
            errorCount++;
        }

        if (!formHints.type.includes(type)) {
            formErrors["type"].push("Typ musi zostać wybrany");
            errorCount++;
        } else {
            switch (type.value) {
                case "DICTIONARY":
                    if (!dictionary || !dictionary.value) {
                        formErrors["dictionary"].push("Nie wybrano żadnego elementu");
                        errorCount++;
                    }
                    break;
                case "REGULAR_EXPRESSION":
                    if (regexp.length <= 0) {
                        formErrors["regexp"].push("Wyrażenie regularne nie może być puste");
                        errorCount++;
                    }

                    try {
                        new RegExp(regexp);
                    } catch (error) {
                        formErrors["regexp"].push("Kompilacja wyrażenia regularnego nie powiodła się");
                        errorCount++;
                    }
                    break;
                case "SENSITIVE_CONTENT":
                default:
                    if (sensitiveContent.length <= 0) {
                        formErrors["sensitiveContent"].push("Nie wybrano żadnego elementu");
                        errorCount++;
                    }
            }
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildForm() {
        const { name, threshold, type, sensitiveContent, regexp, dictionary } = this.state.form;

        var contentOfType;
        switch (type.value) {
            case "DICTIONARY": contentOfType = { dictionary: dictionary.value }; break;
            case "REGULAR_EXPRESSION": contentOfType = { regexp: regexp }; break;
            case "SENSITIVE_CONTENT":
            default: contentOfType = { sensitiveContent: sensitiveContent };
        }

        return {
            name: name,
            threshold: threshold,
            type: type.value,
            ...contentOfType,
        }
    }

    submitForm(onSuccess) {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.patch(AppConstants.PC_SAFETICA_CATEGORY_CONFIGURATION_URL + "/" + uuid, {
                    form: this.buildForm()
                })
            } else {
                apiPromise = API.post(AppConstants.PC_SAFETICA_CATEGORY_CONFIGURATION_URL, {
                    form: this.buildForm()
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Kategoria została dodana.");
                }

                if (result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Kategoria została zedytowana.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            });
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchCategoryForm(uuid) {
        API.get(AppConstants.PC_SAFETICA_CATEGORY_CONFIGURATION_URL + "/" + uuid)
            .then(result => {
                this.setState({
                    form: {
                        ...this.state.form,
                        ...result.data,
                        type: this.findHintByValue("type", result.data.type),
                        dictionary: result.data.dictionary ? this.findHintByValue("dictionary", result.data.dictionary) : "",
                    }
                })
            })
    }

    fetchAvailableOptions = (callback) => {
        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.PC_SAFETICA_CATEGORY_CONFIGURATION_URL + "/form/options/create").then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                    },
                    isOptionsLoading: false,
                }, callback)
            }
        });
    }

    SensitiveContentForm() {
        var hasError = Boolean(this.state.formErrors.name.length);

        return (
            <React.Fragment>
                <div style={{ position: "absolute", top: "0", bottom: "0", overflowY: "auto" }}>
                    <label>*Wrażliwa Zawartość</label>
                    {this.state.formHints.sensitiveContent.map((option, idx) => (
                        <FormCheckbox
                            name="sensitiveContent"
                            key={idx}
                            checked={this.checkboxArrayIsChecked("sensitiveContent", option.value)}
                            onChange={() => this.checkboxArrayChangeHandler("sensitiveContent", option.value)}
                            invalid={hasError}>
                            {option.label}
                        </FormCheckbox>
                    ))}
                    {hasError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.sensitiveContent)}</ul>}
                </div>
            </React.Fragment>
        )
    }

    RegexpForm() {
        const { regexp } = this.state.form

        const handleInputChange = (event) => {
            try {
                new RegExp(event.target.value);
            } catch (error) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        regexp: ["Kompilacja wyrażenia regularnego nie powiodła się"]
                    }
                })
            }

            this.inputChangeHandler(event);
        }

        var hasRegexpError = Boolean(this.state.formErrors.regexp.length);

        return (
            <React.Fragment>
                <label>*Wyrażenie Regularne</label>
                <FormInput
                    name="regexp"
                    value={regexp || ''}
                    onChange={handleInputChange}
                    invalid={hasRegexpError}
                    className={hasRegexpError ? "mb-0" : "mb-2"} />
                {hasRegexpError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.regexp)}</ul>}
            </React.Fragment>
        )
    }

    DictionaryForm() {
        const { dictionary } = this.state.form;

        var hasError = Boolean(this.state.formErrors.dictionary.length);

        return (
            <React.Fragment>
                <label>*Słownik</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    name="dictionary"
                    value={dictionary}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.dictionary}
                    className={hasError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                {hasError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.dictionary)}</ul>}
            </React.Fragment>
        )
    }

    render() {
        const { name, threshold, type } = this.state.form;

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasThresholdError = Boolean(this.state.formErrors.threshold.length);
        var hasTypeError = Boolean(this.state.formErrors.type.length);

        return (
            <Row>
                <Col sm="12" md="6">
                    <label>*Nazwa</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>*Próg Wykrywania</label>
                    <FormInput
                        name="threshold"
                        value={threshold}
                        onChange={this.inputChangeHandler}
                        invalid={hasThresholdError}
                        className={hasThresholdError ? "mb-0" : "mb-2"}
                        type="number"
                        min="0" />
                    {hasThresholdError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.threshold)}</ul>}

                    <label>*Typ</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="type"
                        value={type}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.type}
                        className={hasTypeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasTypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.type)}</ul>}
                </Col>
                <Col sm="12" md="6">
                    {type && type.value === "SENSITIVE_CONTENT" && <this.SensitiveContentForm />}
                    {type && type.value === "REGULAR_EXPRESSION" && <this.RegexpForm />}
                    {type && type.value === "DICTIONARY" && <this.DictionaryForm />}
                </Col>
            </Row>
        );
    }

}

export default ConfirmCategoryForm;
