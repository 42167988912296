import React from "react";
import { ConfirmModal } from "../../../common/Modal";

import ConfirmDeviceApplicationForm from "../forms/ConfirmDeviceApplicationForm"

class ConfirmDeviceApplicationModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            isMobileView,
            deviceStatus,
            deviceAction
        } = this.props;

        return (
            <ConfirmModal
                title={uuid && typeof (uuid) === "string" ? "Wniosek o edycję urządzenia" : "Wniosek o dodanie urządzenia"}
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.addDeviceApplicationForm.submitForm(onSuccess); }}
                middleButtonAction={() => this.refs.addDeviceApplicationForm.submitTemplate()}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj"
                middleButtonText="Zapisz szablon">

                <ConfirmDeviceApplicationForm
                    toggle={toggle}
                    ref="addDeviceApplicationForm"
                    uuid={uuid || ""}
                    isMobileView={isMobileView}
                    deviceStatus={deviceStatus}
                    deviceAction={deviceAction}
                />

            </ConfirmModal>
        )
    }
}

export default ConfirmDeviceApplicationModal;