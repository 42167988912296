import React from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    CardFooter,
} from "shards-react";

import * as Actions from "../../utils/Actions";
import * as AppConstants from "../../constants";
import { Store } from "../../flux"
import LinkButton from './../buttons/LinkButton';

const DeviceCard = ({ name, status, action, deviceDetails, withoutButtons, isPending, isRejected, onDelete, onDetails, onAcceptDevice, onDeclineDevice }) => {
    const SocAdminActions = () => (
        <Row style={{ marginTop: "-5px" }}>
            <Col><LinkButton onClick={onAcceptDevice} className="" style={{ color: "green", textDecoration: "underline", fontSize: "small" }}>Zaakceptuj</LinkButton></Col>
            {action !== AppConstants.ACTIONS.TO_DELETE &&
                <Col><LinkButton onClick={onDeclineDevice} className="" style={{ color: "red", textDecoration: "underline", fontSize: "small" }}>Odrzuć</LinkButton></Col>}
        </Row>
    )

    const DeviceDetails = () => {
        return deviceDetails.map((element, index) => {
            if (deviceDetails && element.label)
                return (
                    <Row key={index}>
                        <Col>{element.label}</Col>
                        <Col>{element.value ? element.value : "-"}</Col>
                    </Row>
                )
            return null;
        })
    }

    const isSocAdmin = Store.getUserRole() === AppConstants.Roles.SOCADMIN;

    return (
        <div>
            <Card className="mt-2">
                <CardBody className="small-padding">
                    <CardTitle className="text-center">{name}</CardTitle>
                    <CardSubtitle className="text-center">
                        <Row>
                            <Col>
                                {Actions.getActionParameters(status, action).content}
                            </Col>
                        </Row>

                        {isPending && isSocAdmin && <SocAdminActions />}
                    </CardSubtitle>
                    <div className="device-card-details">
                        <DeviceDetails />
                    </div>
                </CardBody>
                <CardFooter>
                    {(!withoutButtons && !isPending && !isRejected) &&
                        <div>
                            <Button onClick={onDetails} outline theme="info" className="mb-2 mr-1 float-right">Szczegóły</Button>
                            <Button onClick={onDelete} outline theme="danger" className="mb-2 mr-1 float-right">Usuń</Button>
                        </div>}

                    {(!withoutButtons && (isPending || isRejected)) &&
                        <div>
                            <Button onClick={onDetails} outline theme="info" className="mb-2 mr-1 float-right">Szczegóły</Button>
                        </div>}
                </CardFooter>
            </Card>
        </div>
    )
}

export default DeviceCard;