export const URLQueryParams = (urlQueriesString) => {
  const URLQueryParams = {};

  urlQueriesString.split('?')[1].split('&').forEach(param => {
    const temp = param.split('=');
    URLQueryParams[temp[0]] = temp[1];
  })

  return URLQueryParams;
}
