import React from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
} from "shards-react";

import ConfirmDeviceRuleModal from "./modals/ConfirmDeviceRuleModal";
import RuleList from "./lists/RuleList";
import RuleFilterForm from "./forms/RuleFilterForm";
import DeleteDeviceRuleModal from "./modals/DeleteDeviceRuleModal";
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from '../../../constants';
import { getActionParameters } from "../../../utils/Actions";
import withLoader from "../../common/hoc/withLoader";

class PcDeviceRules extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            isCreateDeviceRuleShow: false,
            isDeleteRuleModalShow: false,
            deviceRules: [],
            requiredFilterOptions: {},
            selectedRuleUUID: '',
            filters: {

            },
            sortOptions: {
                sortOrder: "",
                sortField: "",
            }
        }

        this.toggleCreateRuleModal = this.toggleCreateRuleModal.bind(this);
        this.toggleDeleteRuleModal = this.toggleDeleteRuleModal.bind(this);
        this.updateDeviceRules = this.updateDeviceRules.bind(this);
        this.setSortOptions = this.setSortOptions.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleCreateRuleModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateDeviceRuleShow: !this.state.isCreateDeviceRuleShow,
                selectedRuleUUID: uuid
            })
    }

    toggleDeleteRuleModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteRuleModalShow: !this.state.isDeleteRuleModalShow,
                selectedRuleUUID: uuid
            });
    }

    fetchDeviceRules(form) {
        return API.post(AppConstants.PC_DEVICE_DEVICELOCK_RULE_URL, {
            form: form,
        }).then((result) => {
            var menuRuleInfo = result.data;
            if (this._isMounted)
                this.setState({
                    deviceRules: menuRuleInfo.rules,
                    requiredFilterOptions: menuRuleInfo.options
                });
            this.props.setLoading(false);
        })
    }

    updateDeviceRules(newFilters = {}, save = false) {
        this.props.setLoading(true);
        if (save)
            this.setState({
                filters: newFilters
            }, () => this.fetchDeviceRules(this.state.filters))
        else
            this.fetchDeviceRules(this.state.filters)
    }

    getFilterOptions() {
        const { requiredFilterOptions } = this.state;

        var deviceGroups = requiredFilterOptions.deviceGroups;
        var devices = requiredFilterOptions.devices;
        var categories = requiredFilterOptions.categories;
        var statuses = requiredFilterOptions.statuses && requiredFilterOptions.statuses.map(item => {
            return {
                label: getActionParameters(item.status, item.action).content,
                value: item
            }
        })
        var managers = requiredFilterOptions.manager;

        var hints = {
            deviceGroup: deviceGroups ? deviceGroups.map(deviceGroup => { return { label: deviceGroup, value: deviceGroup } }) : null,
            device: devices ? devices.map(device => { return { label: device, value: device } }) : null,
            category: categories ? categories.map(category => { return { label: category, value: category } }) : null,
            status: statuses,
            manager: managers
        }

        return hints;
    }

    setSortOptions(sortOptions) {
        if (this._isMounted)
            this.setState({
                sortOptions: sortOptions,
            })
    }

    render() {
        const { isCreateDeviceRuleShow, isDeleteRuleModalShow, deviceRules, selectedRuleUUID, sortOptions, filters } = this.state;
        const { deviceType } = this.props;

        const showRuleList = () => (
            <RuleList className="clearfix"
                rules={deviceRules}
                sortOptions={sortOptions}
                toggleConfirmRuleModal={this.toggleCreateRuleModal}
                toggleDeleteRuleModal={this.toggleDeleteRuleModal} 
                onAction={this.updateDeviceRules}/>
        )

        const showNoRulesFoundMessage = () => (
            <Row className="pt-2" style={{ justifyContent: "center" }}>
                <h6>Brak zdefiniowanych reguł</h6>
            </Row>
        )

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Reguły dla grup urządzeń / urządzeń</h6>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={this.toggleCreateRuleModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nową regułę
                            </Button>

                            <RuleFilterForm filterOptions={this.getFilterOptions()} onChangeFilter={this.updateDeviceRules}
                                setSortOptions={this.setSortOptions} />
                            {deviceRules.length ? showRuleList() : showNoRulesFoundMessage()}
                        </CardBody>
                    </Card>
                </Col>

                <ConfirmDeviceRuleModal open={isCreateDeviceRuleShow} onSuccess={() => this.updateDeviceRules(filters)}
                    uuid={selectedRuleUUID} toggle={this.toggleCreateRuleModal} deviceType={deviceType} />

                <DeleteDeviceRuleModal open={isDeleteRuleModalShow} onSuccess={() => this.updateDeviceRules(filters)}
                    uuid={selectedRuleUUID} toggle={this.toggleDeleteRuleModal} deviceType={deviceType} />
            </Row>
        );
    }
}

export default withLoader(PcDeviceRules);