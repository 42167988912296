import React from 'react';
import { Row, Col, FormInput } from 'shards-react';

import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';

import API from "../../../api/AxiosConfiguration";
import * as AppConstants from '../../../constants';

import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'

class ConfirmControlPanelSensorForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                group: "",
                type: "",
                location: "",
                subSensors: {},
            },
            formErrors: {
                name: [],
                group: [],
                type: [],
            },
            formHints: {
                group: [],
                type: [],
                location: [],
            },
            isOptionsLoading: true
        };

        this.clearFormSubSensors = this.clearFormSubSensors.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.subSensorInputChangeHandler = this.subSensorInputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);

        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);
        this.fetchControlPanelSensorForm = this.fetchControlPanelSensorForm.bind(this);
    }

    componentDidMount() {
        const { controlPanelUuid, uuid } = this.props;

        this.fetchAvailableOptions(controlPanelUuid, () => {
            if (uuid && uuid.length) {
                this.fetchControlPanelSensorForm(controlPanelUuid);
            }
        });
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    subSensorInputChangeHandler = (event) => {
        var { subSensors } = this.state.form;
        subSensors[event.target.name] = event.target.value;
        this.setState({
            form: {
                ...this.state.form,
                subSensors: subSensors
            }
        });
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        formErrors[event.name] = [];
        this.setState({
            form: {
                ...form,
                [event.name]: item
            }
        }, () => {
            if (event.name === "type") {
                this.clearFormSubSensors();
            }
        });
    }

    clearFormSubSensors() {
        const { form } = this.state;

        this.setState({
            form: {
                ...form,
                subSensors: {}
            }
        })
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, group, type } = this.state.form;

        formErrors["name"] = [];
        formErrors["group"] = [];
        formErrors["type"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (group == null || group.length <= 0) {
            formErrors["group"].push("Nie wybrano grupy");
            errorCount++;
        }

        if (type == null || type.length <= 0) {
            formErrors["type"].push("Nie wybrano typu urządzenia");
            errorCount++;
        }

        this.setState({ formErrors: { ...formErrors } });
        return !errorCount;
    }

    buildForm() {
        const { form } = this.state;

        return {
            ...form,
            group: form.group && form.group.value,
            type: form.type && form.type.value,
            location: form.location && form.location.value,
        }
    }

    submitForm = (onSuccess) => {
        const { controlPanelUuid } = this.props;
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var axiosConfig = {
                params: {
                    uuid: controlPanelUuid,
                }
            }

            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.CONTROL_PANEL_SENSORS_URL + "/" + uuid, {
                    action: AppConstants.ACTIONS.TO_EDIT,
                    form: this.buildForm()
                }, axiosConfig)
            } else {
                apiPromise = API.post(AppConstants.CONTROL_PANEL_SENSORS_URL, {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: this.buildForm()
                }, axiosConfig);
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 409) {
                    this.setState({
                        formErrors: {
                            ...formErrors,
                            global: [response.data.message]
                        }
                    });
                }
            });
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchAvailableOptions = (controlPanelUuid = "", callback) => {
        var axiosConfig = {
            params: {
                uuid: controlPanelUuid,
            }
        }

        API.get(AppConstants.CONTROL_PANEL_SENSORS_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                    },
                    isOptionsLoading: false,
                }, () => callback())
            }
        });
    }

    fetchControlPanelSensorForm = (controlPanelUuid = "") => {
        const { uuid } = this.props;

        var axiosConfig = {
            params: {
                uuid: controlPanelUuid,
            }
        }

        API.get(AppConstants.CONTROL_PANEL_SENSORS_URL + "/" + uuid, axiosConfig).then((result) => {
            if (result.status === 200) {
                var form = {
                    ...this.state.form,
                    ...result.data,
                    type: result.data.type ? this.findHintByValue("type", result.data.type) : "",
                    group: result.data.group ? this.findHintByValue("group", result.data.group) : "",
                    location: result.data.location ? this.findHintByValue("location", result.data.location) : "",
                }

                this.setState({ form: form })
            }
        });
    }

    render() {
        const { name, group, type, location, subSensors } = this.state.form;
        const { uuid } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasGroupError = Boolean(this.state.formErrors.group.length);
        var hasTypeError = Boolean(this.state.formErrors.type.length);

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz lub utwórz nowy",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            isClearable: true
        }

        return (
            <Row>
                <Col sm="12" md="6">
                    <label>*Nazwa</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>*Typ urządzenia</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="type"
                        value={type}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.type}
                        className={hasTypeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "type")}
                        isLoading={this.state.isOptionsLoading}
                        placeholder="Wybierz" />
                    {hasTypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.type)}</ul>}

                    <label>*Grupa urządzeń</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="group"
                        value={group}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.group}
                        className={hasGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={isEditingModeEnabled}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "group")}
                        isLoading={this.state.isOptionsLoading} />
                    {hasGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.group)}</ul>}

                    <label>Lokalizacja urządzenia</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="location"
                        value={location}
                        isValidNewOption={(inputValue) => {
                            const processedInputValue = inputValue ||  ""
                            
                            return processedInputValue.length >= 3 && processedInputValue.length <= 50 && !hasValueUrlOrHtmlTag(processedInputValue)
                        }}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.location}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "location")}
                        isLoading={this.state.isOptionsLoading} />
                </Col>

                <Col sm="12" md="6">
                    {type && type.additionalParameters &&
                        JSON.parse(type.additionalParameters).map((subSensor) => (
                            <React.Fragment key={subSensor.value}>
                                <label>{subSensor.label}</label>
                                <FormInput
                                    name={subSensor.value}
                                    value={subSensors[subSensor.value] || ""}
                                    onChange={this.subSensorInputChangeHandler}
                                    invalid={hasNameError}
                                    className={hasNameError ? "mb-0" : "mb-2"}
                                    type="number"
                                    placeholder="ID Czujnika" />
                            </React.Fragment>
                        ))
                    }
                </Col>
            </Row>
        );
    }

}

export default ConfirmControlPanelSensorForm;
