import React from "react";
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from "../../../constants";
import * as SocToast from '../../../utils/SocToast';

class DeleteAccountRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm() {
        API.get(AppConstants.PROFILE_URL + "/delete/account")
            .then(result => {
                if (result.status === 200) {
                    SocToast.success("Powodzenie", "Na twój adres email został wysłany link potwierdzający usunięcie konta.");
                }
            });
    }

    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }

}

export default DeleteAccountRequest;