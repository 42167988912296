import React from 'react';
import { Container, Row, Col, Card, CardBody } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import NotificationHistoryList from "../components/notification/NotificationHistoryList";
import { withCookies } from 'react-cookie';
import API from "../api/AxiosConfiguration";
import * as Constants from '../constants';
import ProcedureModal from "../components/notification/modals/ProcedureModal";
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

class Notification extends React.Component {

    _isMounted = false;

    constructor() {
        super();

        this.state = {
            notifications: [],
            selectedEventProcedures: '',
            isProceduresModalShow: false,
        }

        this.toggleProceduresModal = this.toggleProceduresModal.bind(this);
        this.updateNotificationHistory = this.updateNotificationHistory.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateNotificationHistory();
    }

    updateNotificationHistory() {
        API.get(Constants.NOTIFICATIONS_URL).then((result) => {
            this.setState({
                notifications: result.data,
            });
        });
    }

    toggleProceduresModal(procedure) {
        if (this._isMounted)
            this.setState({
                isProceduresModalShow: !this.state.isProceduresModalShow,
                selectedEventProcedures: procedure,
            });
    }

    render() {
        const { notifications, isProceduresModalShow, selectedEventProcedures } = this.state;

        const Title = () => {
            return (<div>Notyfikacje
                <Tooltip title="Maksymalna liczba wyświetlanych powiadomień to 200. Aby zobaczyć starsze notyfikacje należy usunąć część widocznych." placement="bottom" arrow>
                    <span>
                        <FontAwesomeIcon style={{ height: '1.5em', width: '0.5em' }} icon={faInfoCircle} />
                    </span>
                </Tooltip>
            </div>)
        }

        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle title={<Title />} className="text-sm-left mb-3" />
                </Row>
                <Row className="mt-2 mb-2">
                    <Col>
                        <Card>
                            <CardBody className="p-0 pt-1 pb-3">
                                <NotificationHistoryList history={this.props.history} notifications={notifications} proceduresModal={this.toggleProceduresModal} updateNotificationHistory={this.updateNotificationHistory} />
                            </CardBody>
                            <Row className="mr-0 ml-0">
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <ProcedureModal open={isProceduresModalShow} content={selectedEventProcedures}
                    toggle={this.toggleProceduresModal} />
            </Container>
        );
    }
}

export default withCookies(Notification);
