import React from "react";
import { Row, Col, Button } from "shards-react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    SizePerPageDropdownStandalone,
    PaginationListStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import * as SocToast from "../../../utils/SocToast";

import { translateDeviceType, translateEventType, translateEventSearchValueIn } from "../../../utils/Translations";

class ScheduledReportList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            scheduledReports: []
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.scheduledReports) !== JSON.stringify(state.scheduledReports)) {
            return {
                scheduledReports: props.scheduledReports
            }
        }

        return null;
    }

    static deleteScheduledReport(uuid, onUpdate) {
        API.delete(Constants.SCHEDULED_EVENTS_URL + "/" + uuid)
            .then(result => {
                SocToast.success("Udało się", "Usunięto automatyczny raport pomyślnie!");
                onUpdate();
            })
            .catch(error => {
                SocToast.error("Coś poszło nie tak", "Niestety nie udało się usunąć automatycznego raportu. Spróbuj później lub skontaktuj się z administracją.")
            })
    }

    render() {
        const {
            scheduledReports,
        } = this.state;

        const {
            onUpdate
        } = this.props;

        const paginationOptions = {
            custom: true,
            totalSize: scheduledReports.length
        };

        const tableColumns = () => {
            return [{
                dataField: 'deviceType',
                text: 'Typ urządzenia',
                headerClasses: 'border-0',
                headerAlign: "center",
                align: "center",
                sort: true,
                formatter: (cell, row) => {
                    return row.filters.deviceType.map((dt, index) => (
                        <div>
                            {translateDeviceType(dt)}{index !== row.filters.deviceType.length - 1 && "," }
                        </div>
                    ))
                },
                style: {verticalAlign: 'middle'}
            }, {
                dataField: 'scheduleInterval',
                text: 'Częstotliwość odświeżania',
                headerClasses: 'border-0',
                headerAlign: "center",
                align: "center",
                sort: true,
                formatter: (cell, row) => {
                    return `${cell}h`
                },
                style: {verticalAlign: 'middle'}
            }, {
                dataField: 'eventType',
                text: 'Typ zdarzenia',
                headerClasses: 'border-0',
                headerAlign: "center",
                align: "center",
                sort: true,
                formatter: (cell, row) => {
                    var eventType = row.filters.eventType;
                    return `${eventType ? translateEventType(eventType) : "Wszystkie"}`
                },
                style: {verticalAlign: 'middle'}
            }, {
                dataField: 'searchValue',
                text: 'Wyszukiwana wartość',
                headerClasses: 'border-0',
                headerAlign: "center",
                align: "center",
                sort: true,
                formatter: (cell, row) => {
                    const searchValue = row.filters.searchValue;
                    const searchValueIn = row.filters.searchValueIn;
                    return (
                        <div style={{ lineHeight: searchValueIn && searchValue ? "10px" : "inherit" }}>
                            <div>{searchValue ? searchValue : "-"}</div>
                            {searchValueIn && searchValue && <small style={{ fontSize: "8px" }}>({translateEventSearchValueIn(searchValueIn)})</small>}
                        </div>
                    )
                },
                style: {verticalAlign: 'middle'}
            }, {
                dataField: 'actions',
                text: '',
                headerClasses: 'border-0',
                headerAlign: "right",
                align: "right",
                formatter: (cell, row) => {
                    return (
                        <span>
                            <Button
                                outline
                                theme="info"
                                onClick={() => {
                                    ScheduledReportList.deleteScheduledReport(row.uuid, onUpdate)
                                }}>Usuń</Button>
                        </span>
                    )
                },
                style: {verticalAlign: 'middle'}
            }]
        }

        const BoostrapTableWithPagination = () => {
            return (
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Row>
                            <Col xs="12" className="mb-2">
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField="uuid"
                                    data={scheduledReports}
                                    columns={tableColumns()}
                                    classes="table mb-0"
                                    headerClasses="react-bootstrap-table-header"
                                    bordered={false}
                                    hover
                                    wrapperClasses="table-responsive"
                                    {...paginationTableProps} />
                            </Col>
                            <Col xs="6" className="d-flex justify-content-start">
                                <div className="ml-2 pl-3"><SizePerPageDropdownStandalone {...paginationProps} /></div>
                            </Col>
                            <Col xs="6" className="d-flex justify-content-end">
                                <div className="mr-2 pr-3"><PaginationListStandalone {...paginationProps} /></div>
                            </Col>
                        </Row>
                    )}
                </PaginationProvider>
            )
        }

        return (
            <div>
                {scheduledReports.length > 0 ? <BoostrapTableWithPagination /> :
                    <div className="text-center py-3">Nie znaleziono żadnych automatycznych raportów</div>}
            </div>
        );
    }
}

export default ScheduledReportList;