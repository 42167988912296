import React from "react";
import { Container, Row, Col } from "shards-react";

import PcData from "../components/pc-device-details/devicelock/DeviceData";
import PcEvents from "../components/pc-device-details/devicelock/DeviceEvents";

import API from "../api/AxiosConfiguration";
import * as Constants from "../constants";
import * as Actions from "../utils/Actions";
import DeviceStatus from "../components/pc-device-details/devicelock/DeviceStatus";
import ProcedureModal from "../components/pc-device-details/devicelock/modals/ProcedureModal";
import DeviceScanner from '../components/device-scanner/DeviceScanner';

class PcDeviceDeviceLockDetails extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      uuid: this.props.location.state ? this.props.location.state.uuid : "",
      device: undefined,
      deviceStatus: undefined,
      deviceStatusLabel: "",
      deviceStats: [],
      isProceduresModalShow: false,
      selectedEventProcedures: '',
      isDeviceDataLoading: false,
      isDeviceStatusLoading: false,
    }

    this.fetchDeviceReferralData = this.fetchDeviceReferralData.bind(this);
    this.toggleProceduresModal = this.toggleProceduresModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchDeviceReferralData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchDeviceReferralData() {
    const { uuid } = this.state;
    if (this._isMounted)
      this.setState({
        isDeviceDataLoading: true,
        isDeviceStatusLoading: true
      }, () => {
        API.get(Constants.PC_DEVICE_DEVICELOCK_URL + "/" + uuid)
          .then(result => {
            if (result.status === 200) {
              var device = result.data;
              var deviceStatus = Actions.getActionParameters(device.status, device.action);
              if (this._isMounted)
                this.setState({
                  device: device,
                  deviceStatus: deviceStatus,
                  isDeviceDataLoading: false,
                  isDeviceStatusLoading: false
                })
            }
          })
      })
  }

  toggleProceduresModal(procedure) {
    if (this._isMounted)
      this.setState({
        isProceduresModalShow: !this.state.isProceduresModalShow,
        selectedEventProcedures: procedure,
      });
  }

  render() {
    const { uuid, isDeviceDataLoading, isDeviceStatusLoading, device, deviceStatus, isProceduresModalShow, selectedEventProcedure } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="4" md="12" sm="12" className="mb-3">
            <DeviceStatus
              status={deviceStatus}
              isLoading={isDeviceStatusLoading} />

            <PcData
              uuid={uuid}
              data={device}
              onUpdate={this.fetchDeviceReferralData}
              isLoading={isDeviceDataLoading} />

            <DeviceScanner deviceType={Constants.DEVICE_TYPES.PC} deviceUuid={uuid} />
          </Col>
          <Col lg="8" md="12" sm="12" className="mb-3">
            <PcEvents uuid={uuid} proceduresModal={this.toggleProceduresModal} />
          </Col>
        </Row>
        <ProcedureModal open={isProceduresModalShow} content={selectedEventProcedure}
          toggle={this.toggleProceduresModal} />
      </Container>
    )
  }
}

export default PcDeviceDeviceLockDetails;
