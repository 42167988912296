import React from 'react';
import { ConfirmModal } from '../common/Modal';
import OrderDetails from './OrderDetails';
import { trackPromise } from 'react-promise-tracker';

import * as AppConstants from '../../constants';
import API from "../../api/AxiosConfiguration";

class TransactionSummaryModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: null
        };

        this.excecuteOrder = this.excecuteOrder.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.order != null) {
            if (JSON.stringify(props.order) !== JSON.stringify(state.order)) {
                return { order: props.order }
            }
        } else {
            return { order: null }
        }

        return null;
    }

    excecuteOrder() {
        const {
            order,
        } = this.state;

        trackPromise(
            API.post(AppConstants.COMPANY_SMS_PAYMENT_EXCECUTION, { ...order, callbackUrl: window.location.href })
                .then(result => {
                    if (result.status === 200) {
                        const redirectUrl = result.data;
                        window.location.assign(redirectUrl);
                    }
                })
        )
    }

    render() {
        const {
            open,
            toggle
        } = this.props;
        const { order } = this.state;

        return (
            <ConfirmModal
                title="Podsumowanie zamówienia"
                open={open}
                toggle={toggle}
                submitAction={() => this.excecuteOrder()}
                positiveButtonText="Kup"
                negativeButtonText="Anuluj">

                {order && <OrderDetails
                    products={[{
                        name: `Pakiet SMS - ${order.tariff.name}`,
                        amount: order.amount,
                        totalNetPrice: order.totalNetPrice,
                        totalGrossPrice: order.totalGrossPrice
                    }]} />}

            </ConfirmModal>
        );
    }

}

export default TransactionSummaryModal;