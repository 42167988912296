import React from "react";
import { Row, Col, Card, CardHeader, CardBody, Button, Alert } from "shards-react";
import DynamicMenu from "../menu/DynamicMenu"
import CustomerCard from "../cards/CustomerCard";
import API from "../../api/AxiosConfiguration";
import * as Constants from '../../constants';
import ConfirmCustomerModal from './modals/ConfirmCustomerModal'
import CustomerDetailsModal from './modals/CustomerDetailsModal';
import withLoader from "../common/hoc/withLoader";

class Users extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isConfirmCustomerShow: false,
            selectedCustomerUUID: "",
            accessGroups: [],
            selectedAccessGroup: {},
        };

        this.updateComponent = this.updateComponent.bind(this);
        this.toggleConfirmCustomerModal = this.toggleConfirmCustomerModal.bind(this);
        this.toggleCustomerDetailsModal = this.toggleCustomerDetailsModal.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateComponent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleConfirmCustomerModal(uuid) {
        if (this._isMounted)
            this.setState({
                isConfirmCustomerShow: !this.state.isConfirmCustomerShow,
                selectedCustomerUUID: uuid,
            });
    }

    toggleCustomerDetailsModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCustomerDetailsModalShow: !this.state.isCustomerDetailsModalShow,
                selectedCustomerUUID: uuid,
            }, () => {
                if (!this.state.isCustomerDetailsModalShow) {
                    this.updateComponent();
                }
            });
    }

    fetchCustomers(form) {
        return API.post(Constants.CUSTOMERS_URL, {
            form: form,
        })
    }

    updateComponent(filters = {}) {
        this.props.setLoading(true);
        this.fetchCustomers(filters).then((result) => {
            var accessGroups = result.data;
            if (this._isMounted)
                this.setState({
                    accessGroups: accessGroups,
                });
            this.props.setLoading(false);
        })
    }

    render() {
        const {
            accessGroups,
            selectedAccessGroup,
            isConfirmCustomerShow,
            isCustomerDetailsModalShow,
            selectedCustomerUUID
        } = this.state;

        const generateTable = (display, customers) => {
            if (display) {
                return (
                    <Row className="m-0 rounded-bottom mb-1" style={{ backgroundColor: "rgba(0,0,0,.075)" }}>
                        {customers.map((customer, idx) =>
                            <Col key={idx} md="6" lg="4" >
                                <CustomerCard
                                    id={idx}
                                    status={customer.status}
                                    withDetailsButton
                                    name={customer.name ? `${customer.name} ${customer.surname}` : "Niezdefiniowany"}
                                    customerDetails={customer.details ? customer.details : []}
                                    onDetails={() => this.toggleCustomerDetailsModal(customer.uuid)} />
                            </Col>
                        )}
                    </Row>
                );
            }
        }

        const showNoRecordsFoundMessage = () => (
            <Row className="pt-2" style={{ justifyContent: "center" }}>
                <h6>Nie odnaleziono żadnych użytkowników</h6>
            </Row>
        )

        return (
            <Row className="mt-2 position-relative">
                {this.props.children}
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Użytkownicy <small>(Podzieleni na grupy dostępowe)</small></h6>
                        </CardHeader>
                        <CardBody>

                            {
                                accessGroups.filter(accessGroup => accessGroup.uuid === "UNASSIGNED").length ?
                                    <Alert theme="warning">Wykryto użytkowników nienależących do żadnej grupy dostępowej. Zalecamy jej ustawienie, w przeciwnym razie nie uzyskają oni dostępu do głównej funkcjonalności.</Alert> :
                                    null
                            }

                            <Button onClick={this.toggleConfirmCustomerModal} outline theme="info" className="mb-2 mr-1">
                                Dodaj nowego użytkownika
                            </Button>

                            {accessGroups.filter(accessGroup => accessGroup.customers.length).map((accessGroup, idx) =>
                                <div key={idx}>
                                    <DynamicMenu
                                        title={accessGroup.name}
                                        noTitleEdit={true}
                                        downChevron={selectedAccessGroup !== accessGroup.name}
                                        onClick={() => {
                                            if (selectedAccessGroup !== accessGroup.name) {
                                                this.setState({ selectedAccessGroup: accessGroup.name })
                                            } else {
                                                this.setState({ selectedAccessGroup: "" })
                                            }
                                        }}>
                                    </DynamicMenu>
                                    {generateTable(selectedAccessGroup === accessGroup.name, accessGroup.customers)}
                                </div>
                            )}

                            {(!accessGroups.length || accessGroups.every(accessGroup => !accessGroup.customers.length)) &&
                                showNoRecordsFoundMessage()}

                        </CardBody>
                    </Card>
                </Col>

                <CustomerDetailsModal open={isCustomerDetailsModalShow} onSuccess={this.updateComponent}
                    uuid={selectedCustomerUUID} toggle={this.toggleCustomerDetailsModal} />

                <ConfirmCustomerModal open={isConfirmCustomerShow} onSuccess={this.updateComponent}
                    uuid={selectedCustomerUUID} toggle={this.toggleConfirmCustomerModal} />
            </Row>
        );
    }

}

export default withLoader(Users);