import React from 'react';
import { FormInput, Button } from 'shards-react';
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from '../../../constants';

class DeleteAccountConfirmationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                password: ""
            },
            formErrors: {
                password: []
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.validForm = this.validForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { password } = this.state.form;

        formErrors["password"] = [];

        var errorCount = 0;

        if (password.length <= 0) {
            formErrors["password"].push("Hasło nie może być puste");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm() {
        const { form, formErrors } = this.state;
        const { deleteAccountToken } = this.props

        if (this.validForm()) {
            API.post(AppConstants.PROFILE_URL + "/delete/account", {
                form: {
                    deleteAccountToken: deleteAccountToken,
                    password: form.password,
                }
            }).then((result) => {
                if (result.status === 200) {
                    this.props.onSuccess();
                    localStorage.removeItem("user");
                    localStorage.removeItem("companySelected");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    if (response.data.errors) {
                        response.data.errors.forEach(error => {
                            formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                        })
                    }
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    render() {
        const { password } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const hasPasswordError = Boolean(this.state.formErrors.password.length);


        return (
            <div>
                <label>Hasło:</label>
                <FormInput
                    name="password"
                    value={password || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasPasswordError}
                    className={hasPasswordError ? "mb-0" : "mb-2"}
                    type="password" />
                {hasPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.password)}</ul>}

                <Button
                    onClick={this.submitForm}
                    outline
                    theme="info"
                    className="mt-2 ml-1 float-right">
                    Usuń
                </Button>
            </div>
        );
    }

}

export default DeleteAccountConfirmationForm;