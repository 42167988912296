import React from "react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { Row, Col, FormInput, FormCheckbox } from "shards-react";
import { Store } from "../../../flux"

class ConfirmAccessGroupForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: '',
                deviceSecurity: false,
                networkSecurity: false,
                physicalSecurity: false,
            },
            formErrors: {
                name: [],
            },
            company: Store.getSelectedCompany(),
            isOptionsLoading: true,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    checkboxChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = !form[event.target.name];
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name } = this.state.form;

        formErrors["name"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna się składać z conajmniej 3 znaków");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;
        this.setState({
            formErrors: []
        })

        var requestBody = {
            form: form
        }

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(Constants.ACCESS_GROUPS_URL + "/" + uuid, requestBody)
            } else {
                apiPromise = API.put(Constants.ACCESS_GROUPS_URL, requestBody);
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    fetchAccessGroupForm(uuid) {
        API.get(Constants.ACCESS_GROUPS_URL + "/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: result.data
                })
            }
        });
    }

    updateComponent() {
        const { uuid } = this.props;
        if (uuid.length) {
            this.fetchAccessGroupForm(uuid);
        }
    }

    findSelectItemByValue(optionName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[optionName].find(option => option.uuid === value);
        if (foundOption)
            return {
                label: foundOption.name,
                value: foundOption.uuid
            }

        return ''
    }

    getCompanyPrivilege(privilege) {
        const { company } = this.state;
        if (company) {
            return company.privileges.find(companyPrivilege => companyPrivilege === privilege);
        }

        return null;
    }

    render() {
        const { company } = this.state
        const { name, deviceSecurity, networkSecurity, physicalSecurity } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);

        return (
            <Row>
                <Col sm="6">
                    <label>*Nazwa grupy</label>
                    <FormInput
                        name="name"
                        value={name}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                </Col>
                <Col sm="6">
                    {company &&
                        <>
                            <label>Uprawnienia do</label>
                            <div>
                                {(this.getCompanyPrivilege(Constants.Privileges.DEVICE_SECURITY_DEVICELOCK) ||
                                    this.getCompanyPrivilege(Constants.Privileges.DEVICE_SECURITY_SAFETICA)) &&
                                    <FormCheckbox
                                        name="deviceSecurity"
                                        checked={deviceSecurity}
                                        onChange={this.checkboxChangeHandler}>
                                        Bezpieczeństwo Sprzętowe
                                    </FormCheckbox>
                                }

                                {this.getCompanyPrivilege(Constants.Privileges.NETWORK_SECURITY) &&
                                    <FormCheckbox
                                        name="networkSecurity"
                                        checked={networkSecurity}
                                        onChange={this.checkboxChangeHandler}>
                                        Bezpieczeństwo Sieciowe
                                    </FormCheckbox>
                                }

                                {this.getCompanyPrivilege(Constants.Privileges.PHYSICAL_SECURITY) &&
                                    <FormCheckbox
                                        name="physicalSecurity"
                                        checked={physicalSecurity}
                                        onChange={this.checkboxChangeHandler}>
                                        Bezpieczeństwo Fizyczne
                                    </FormCheckbox>
                                }
                            </div>
                        </>
                    }
                </Col>
            </Row>
        );
    }

}

export default ConfirmAccessGroupForm;