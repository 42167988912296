import React from "react";
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import * as socToast from '../../../utils/SocToast';

const Input = props => {
    if (props.isHidden) {
        return <components.Input {...props} />;
    }
    return (
        <components.Input {...props} type="number" />
    );
};

const DropdownIndicator = props => {
    return null
};

const createOption = (label) => ({
    label,
    value: label
});

class ConfirmRuleForm extends React.Component {

    constructor() {
        super();
        this.state = {
            safeticaIdsInputValue: '',
            form: {
                safeticaIds: [],
            },
            formErrors: {
                safeticaIds: []
            },
        };
    }

    keywordsChangeHandler = (safeticaIds) => {
        var { form } = this.state;
        form["safeticaIds"] = safeticaIds;
        this.setState({ form: form });
    }

    keywordsChangeInputHandle = (safeticaIdsInputValue) => {
        const { formErrors } = this.state;
        formErrors["safeticaIds"] = [];
        this.setState({
            safeticaIdsInputValue,
            formErrors: formErrors
        });
    }

    keywordsHandleKeyDown = (event) => {
        const { safeticaIdsInputValue, form, formErrors } = this.state;
        if (!safeticaIdsInputValue) return;

        if (form["safeticaIds"] != null && form["safeticaIds"].length > 0) {
            if (form["safeticaIds"].some(safeticaId => safeticaId.label === safeticaIdsInputValue)) {
                formErrors["safeticaIds"].push("Istnieje już taki Id reguły.");
                this.setState({ formErrors: formErrors });
                return;
            }
        }

        switch (event.key) {
            case "Enter":
            case "Tab":
                if (!form["safeticaIds"]) form["safeticaIds"] = [];
                form["safeticaIds"] = [...form["safeticaIds"], createOption(safeticaIdsInputValue)];
                this.setState({
                    safeticaIdsInputValue: "",
                    form: form,
                });
                event.preventDefault();
            // no default
        }
    }

    validForm() {
        const { type } = this.props
        const { formErrors } = this.state;
        const { safeticaIds } = this.state.form;

        formErrors["safeticaIds"] = [];

        var errorCount = 0;

        if (type === Constants.ACTIONS.TO_ADD) {
            safeticaIds != null && safeticaIds.forEach(safeticaId => {
                if (safeticaId != null && (!safeticaId && safeticaId.length <= 0)) {
                    formErrors["safeticaIds"].push("Id nie zostało ustawione poprawnie");
                    errorCount++;
                }
            });
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm = (uuid, onSuccess) => {
        const { form } = this.state;

        if (this.validForm()) {
            API.post(Constants.PC_DEVICE_SAFETICA_RULE_URL + "/" + uuid + "/accept", {
                safeticaIds: form.safeticaIds && form.safeticaIds.length !== 0 ? form.safeticaIds.map(safeticaId => Number(safeticaId.label)) : null
            }).then((result) => {
                if (result.status === 200) {
                    // eslint-disable-next-line default-case
                    switch (this.props.type) {
                        case Constants.ACTIONS.TO_ADD:
                            socToast.success("Operacja przebiegła pomyślnie", "Reguła została dodana pomyślnie.");
                            break;
                        case Constants.ACTIONS.TO_EDIT:
                            socToast.success("Operacja przebiegła pomyślnie", "Reguła została zedytowana pomyślnie.");
                            break;
                        case Constants.ACTIONS.TO_DELETE:
                            socToast.success("Operacja przebiegła pomyślnie", "Reguła została usunięta pomyślnie.");
                            break;
                    }
                    this.props.toggle(uuid);
                    onSuccess();
                }
            }).catch(error => {
                var response = error.response;
                if (response) {
                    socToast.error("Błąd serwera", "Wystąpił błąd podczas wykonywania operacji.")
                }
            });
        }
    }

    render() {
        const {
            type
        } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasSafeticaIdsError = Boolean(this.state.formErrors.safeticaIds.length);

        console.log(this.state.form.safeticaIds)

        return (
            <div>
                {type === Constants.ACTIONS.TO_ADD &&
                    <div className="mt-4 mr-4">
                        <label>Id reguł w Safetica:</label>
                        <CreatableSelect
                            name="safeticaIds"
                            components={{ Input, DropdownIndicator }}
                            inputValue={this.state.safeticaIdsInputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={this.keywordsChangeHandler}
                            onInputChange={this.keywordsChangeInputHandle}
                            onKeyDown={this.keywordsHandleKeyDown}
                            placeholder="____Wpisz i naciśnij enter..."
                            value={this.state.form.safeticaIds}
                            className={hasSafeticaIdsError ? "react-select-container has-error mb-0 react-select__control" : "react-select-container mb-2"}
                            classNamePrefix="react-select" />
                        {hasSafeticaIdsError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.safeticaIds)}</ul>}
                    </div>
                }
            </div>
        );
    }
}

export default ConfirmRuleForm;