import React from "react";
import { ConfirmModal } from "../../common/Modal";
import DeviceScannerForm from './../forms/DeviceScannerForm';

class DeviceScannerModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            onSuccess,
            deviceUuid,
            deviceType
        } = this.props;

        return (
            <ConfirmModal
                title="Wniosek o przeskanowanie urządzenia"
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.deviceScannerForm.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj">

                <DeviceScannerForm deviceUuid={deviceUuid} ref="deviceScannerForm" deviceType={deviceType} toggle={toggle} />

            </ConfirmModal>
        )
    }
}

export default DeviceScannerModal;