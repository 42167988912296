import React from "react";
import { Container, Row, Col } from "shards-react";

import NetworkData from "../components/network-device-details/DeviceData";
import NetworkEvents from "../components/network-device-details/DeviceEvents";
import DeviceStatusCards from "../components/network-device-details/DeviceStatusCards";

import API from "../api/AxiosConfiguration";
import * as Constants from "../constants";
import * as Actions from "../utils/Actions";
import DeviceStatus from "../components/network-device-details/DeviceStatus";
import ProcedureModal from "../components/network-device-details/modals/ProcedureModal";
import DeviceStatusTable from "../components/network-device-details/DeviceStatusTable";
import DeviceRules from "../components/device-rules/DeviceRules";

class DeviceDetails extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      uuid: this.props.location.state ? this.props.location.state.uuid : "",
      device: undefined,
      deviceStatus: undefined,
      deviceStatusLabel: "",
      deviceStats: [],
      deviceModel: "",
      tableNames: [],
      rules: [],
      appliedFilter: {},
      requiredFilterOptions: {},
      isProceduresModalShow: false,
      selectedEventProcedures: '',
      isDeviceDataLoading: false,
      isDeviceStatusLoading: false,
      lastCheckInfo: {}
    }

    this.fetchDeviceReferralData = this.fetchDeviceReferralData.bind(this);
    this.toggleProceduresModal = this.toggleProceduresModal.bind(this);
    this.fetchDeviceRules = this.fetchDeviceRules.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchDeviceReferralData();
  }

  fetchDeviceRules(form) {
    return API.post(Constants.NETWORK_DEVICE_RULE_URL, {
        form: form,
    }).then((result) => {
        var menuRuleInfo = result.data;
        if (this._isMounted)
            this.setState({
                rules: menuRuleInfo.rules,
                requiredFilterOptions: menuRuleInfo.options
            });
    })
}

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchDeviceReferralData() {
    const { uuid } = this.state;
    if (this._isMounted)
      this.setState({
        isDeviceDataLoading: true,
        isDeviceStatusLoading: true
      }, () => {
        API.get(Constants.NETWORK_DEVICE_URL + "/" + uuid)
          .then(result => {
            if (result.status === 200) {
              var device = result.data;
              var deviceStatus = Actions.getActionParameters(device.status, device.action);
              var deviceModel = device.deviceModel;
              var tableNames = device.tableNames;
              if (this._isMounted)
                this.setState({
                  device: device,
                  appliedFilter: {
                    device: {
                      label: device.referralData.find(data => data.label === "Nazwa urządzenia").value,
                      value: device.referralData.find(data => data.label === "Nazwa urządzenia").value
                    },
                    deviceGroup: {
                      label: device.referralData.find(data => data.label === "Grupa").value,
                      value: device.referralData.find(data => data.label === "Grupa").value
                    }
                 },
                  deviceStatus: deviceStatus,
                  deviceModel: deviceModel,
                  tableNames: tableNames,
                  isDeviceDataLoading: false,
                  isDeviceStatusLoading: false,
                  lastCheckInfo: { date: device.lastCheckDate, status: device.lastCheckStatus}
                })
            }
          })
      })
  }

  toggleProceduresModal(procedures) {
    if (this._isMounted)
      this.setState({
        isProceduresModalShow: !this.state.isProceduresModalShow,
        selectedEventProcedures: procedures,
      });
  }

  render() {
    const { uuid, isDeviceDataLoading, isDeviceStatusLoading, device, deviceStatus, isProceduresModalShow, selectedEventProcedures, tableNames, deviceModel, rules, requiredFilterOptions, appliedFilter, lastCheckInfo } = this.state;

    const renderNetworkDevicePortStatusTabels = () => (
      tableNames.map((element, idx) => (<DeviceStatusTable key={idx} uuid={uuid} type={element} deviceModel={deviceModel} />))
    )

    return (
      <Container fluid className="main-content-container px-4">
        <Row className="mt-5">
          <DeviceStatusCards uuid={uuid} />
        </Row>
        <Row>
          <Col lg="4" md="12" sm="12" className="mb-3">
            <DeviceStatus
              status={deviceStatus}
              lastCheckInfo={lastCheckInfo}
              isLoading={isDeviceStatusLoading} />

            <NetworkData
              uuid={uuid}
              data={device}
              onUpdate={this.fetchDeviceReferralData}
              isLoading={isDeviceDataLoading} />
          </Col>
          <Col lg="8" md="12" sm="12" className="mb-3">
            <NetworkEvents uuid={uuid} proceduresModal={this.toggleProceduresModal} />
            {tableNames.length ? renderNetworkDevicePortStatusTabels() : ""}
          </Col>
        </Row>
        <Row>
          <Col>
            <DeviceRules rules={rules} options={requiredFilterOptions} fetchDeviceRules={this.fetchDeviceRules} appliedFilter={appliedFilter} detailsType={'networkDevice'} /> {/* DetailsType: networkDevice, controlPanel, pcDevice */}
          </Col>
        </Row>
        <ProcedureModal open={isProceduresModalShow} content={selectedEventProcedures}
          toggle={this.toggleProceduresModal} />
      </Container>
    )
  }
}

export default DeviceDetails;
