import API from "./AxiosConfiguration";
import * as AppConstants from "../constants";

export const getGlobalProperties = () => {
    return API.get(AppConstants.PROPERTIES_URL)
        .then(result => {
            if (result && result.status === 200) {
                return result.data
            }

            return Promise.reject();
        });
}

export const getAllCompanies = () => {
    return API.post(AppConstants.COMPANIES_URL)
        .then(result => {
            if (result.status === 200) {
                var companies = result.data.companies;
                return companies;
            }

            return Promise.reject();
        });
}

export const getAllDashboards = () => {
    return API.get(AppConstants.DASHBOARDS_URL)
        .then(result => {
            if(result.status === 200) {
                const dashboards = result.data;
                return dashboards;
            }

            return Promise.reject();
        });
}

export const getCustomersForSelect = (role) => {
    var axiosConfig = {
        params: {
            role: role,
        }
    }

    return API.post(AppConstants.CUSTOMERS_URL + "/company", null, axiosConfig)
        .then(result => {
            if (result.status === 200) {
                return result.data;
            }

            return Promise.reject();
        });
}

export const getDeviceGroupForm = (uuid) => {
    return API.get(AppConstants.DEVICE_GROUPS_URL + "/" + uuid).then(result => {
        if (result.status === 200) {
            var deviceGroupForm = result.data;
            return deviceGroupForm;
        }
    })
}
