import React, { Component } from 'react';
import { Row, Col, FormInput } from "shards-react";
import TooltippedSelect from './TooltippedSelect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import * as AppConstants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import { Store, Dispatcher, Constants } from '../../../flux';
import Tooltip from '@material-ui/core/Tooltip';
import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'

class AddToDashboardForm extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            tileUuid: '',
            selectedFilterFields: '',
            form: '',
            title: '',
            module: '',
            dashboard: '',
            dashboardHints: [],
            rules: [],
            timePeriodType: { label: 'minut', value: 'MINUTES' },
            timePeriod: { label: '10', value: '10', timePeriodType: 'MINUTES' },
            warningThreshold: '3',
            criticalThreshold: '10',
            dataPresentationType: '',
            dataType: 'RULE',
            dataPresentationTypeHints: [
                { label: "Liczba", value: "NUMBER" },
                { label: "Tabela (5 ostatnich)", value: "TABLE" }
            ],
            timePeriods: [
                { label: '10', value: '10', timePeriodType: 'MINUTES' },
                { label: '30', value: '30', timePeriodType: 'MINUTES' },
                { label: '60', value: '60', timePeriodType: 'MINUTES' },

                { label: '1', value: '1', timePeriodType: 'DAYS' },
                { label: '5', value: '5', timePeriodType: 'DAYS' },
                { label: '7', value: '7', timePeriodType: 'DAYS' },
                { label: '14', value: '14', timePeriodType: 'DAYS' },
                { label: '21', value: '21', timePeriodType: 'DAYS' },
                { label: '30', value: '30', timePeriodType: 'DAYS' },

                { label: '1', value: '1', timePeriodType: 'MONTHS' },
                { label: '2', value: '2', timePeriodType: 'MONTHS' },
                { label: '3', value: '3', timePeriodType: 'MONTHS' },
            ],
            timePeriodTypes: [
                { label: 'minut', value: 'MINUTES' },
                { label: 'godzin', value: 'HOURS' },
                { label: 'dni', value: 'DAYS' },
                { label: 'miesiąc', value: 'MONTHS' }
            ],
            titleErrors: [],
            warningThresholdErrors: [],
            criticalThresholdErrors: [],
            fetchedData: null,
            isFetched: false,
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
        }

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
        this.getData = this.getData.bind(this);
        this.findHintByValue = this.findHintByValue.bind(this);
        this.initEditFormData = this.initEditFormData.bind(this);
        this.onFluxChange = this.onFluxChange.bind(this);
        this.rulesSelectChangeHandler = this.rulesSelectChangeHandler.bind(this);
    }


    componentWillUnmount() {
        Store.removeUpdateDashboadListener(this.onFluxChange);
        this._isMounted = false;
    }

    onFluxChange() {
        var availableDashboards = Store.getDashboardList();

        this.setState({
            dashboardHints: availableDashboards
        })
    }
    fetchDashboards() {
        Dispatcher.dispatch({ actionType: Constants.UPDATE_DASHBOARD_LIST })
    }

    componentDidMount() {
        const { formType, uuid, rules, module } = this.props;

        this._isMounted = true;

        Store.addUpdateDashboadListener(this.onFluxChange);

        if(formType === 'addToDashboard') {
            this.setState({
                ...this.state,
                rules: rules,
                module: module
            }, () => this.fetchDashboards());
        } else if(formType === 'addToDashboardDetails') {
            API.get(`${AppConstants.DASHBOARD_TILES_URL}/${uuid}`)
            .then(response => {
                if(response.status === 200) {
                    this.setState({
                        ...this.state,
                        fetchedData: response.data,
                        module: response.data.tileConfig.module
                    }, () => this.getData());
                }
            }).catch( error => {
                console.log(error);
            });
        }
    }

    renderError(errors) {
        return errors.map((error, index) =>
            <li key={index}>{error}</li>
        )
    }

    initEditFormData() {
        const { activeConfigureTab, tileToEdit, form } = this.state;

        const tempForm = {};

        const data = tileToEdit.tileConfig;

        if(data) {
            Object.keys(form[activeConfigureTab]).forEach( key => {

                if(key !== 'module' && key !== 'title' && key !== 'devices') {
                    const option = this.findHintByValue(activeConfigureTab, key, data[key], tempForm);
                    tempForm[key] = option;
                } else if(key === 'devices') {
                    tempForm.devices = [];
                    data.devices.forEach( device => {
                        const option = this.findHintByValue(activeConfigureTab, 'devices', device, tempForm);
                        tempForm.devices.push(option);
                    });
                };


            });

            this.setState({
                ...this.state,
                firstRender: false,
                form: {
                    ...this.state.form,
                    title: data.title,
                    warningThreshold: data.warningThreshold,
                    criticalThreshold: data.criticalThreshold,
                    [activeConfigureTab]: tempForm
                }
            });
        }
    }

    clearFormHints() {
        const { activeConfigureTab } = this.state;

        let hints;

        hints = {
            formHints: {
                [activeConfigureTab]: {}
            }
        }

        for(const key in this.state.form[activeConfigureTab]){
            hints.formHints[activeConfigureTab][key] = [];
        }

        this.setState({
            ...hints
        })
    }

    findHintByValue(formModule, inputName, value, tempForm) {
        const { formHints } = this.state;

        const isPlural = () => { const lastChar = inputName.slice(-1); return (lastChar === 's') ? true : false; },
        tempInputName = `${inputName}${(!isPlural()) ? 's' : ''}`;

        const foundOption = formHints[formModule][tempInputName].map(hint => {

            const tempHintKeys = Object.keys(hint);

            if(tempHintKeys.length === 3) {

                for(let i = 0; i < tempHintKeys.length; i++) {
                    if(tempHintKeys[i] !== 'label' && tempHintKeys[i] !== 'value')
                        if(hint[tempHintKeys[i]] === tempForm[tempHintKeys[i]].value && hint.value === value) return hint
                }

            } else if(tempHintKeys.length > 3) {

                const flags = [];

                for(let i = 0; i < tempHintKeys.length; i++){
                    if(tempHintKeys[i] === 'device') {
                        if(tempForm.devices.some(device => device.value === hint.device)) flags.push(true);
                        else flags.push(false);
                    } else {
                        if(tempHintKeys[i] !== 'label' && tempHintKeys[i] !== 'value')
                            if(hint[tempHintKeys[i]] === tempForm[tempHintKeys[i]].value) flags.push(true)
                            else flags.push(false);
                    }
                }

                if(!flags.includes(false) && hint.value === value) return hint;

            } else {
                return hint.value === value ? hint : undefined
            }

            return undefined

        }).find(option => !(option == null));

        if (foundOption == null) return "";
        return foundOption;
    }

    getData() {
        const { module, rules } = this.state.fetchedData.tileConfig;

        const request = {
            module: module,
            rules: rules
        }

        API.post(AppConstants.DASHBOARD_TILES_RULES_URL, request)
        .then(response => {
            if( response.status === 200 ) {
                const data = response.data;

                this.setState({
                    ...this.state,
                    rules: data
                });
            }
        }).catch( error => {
            console.log(error);
        });
    }

    selectChangeHandler(selected, event, inputsToClearAfterChange) {

        var clearedElements = {}


        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        var item;
        if (Array.isArray(selected)) {
            item = selected.map(selectedItem => selectedItem.value);
        } else {
            item = selected
        }

        this.setState({
            ...this.state,
            ...clearedElements,
            [event.name]: item
        }, () => {;
            if(event.name === "timePeriodType") {
                let defaultOption;

                switch (selected.value) {
                    case 'MINUTES': {
                        defaultOption =  { label: '10', value: '10', timePeriodType: 'MINUTES' };
                        break;
                    }
                    case 'DAYS': {
                        defaultOption = { label: '1', value: '1', timePeriodType: 'DAYS' };
                        break;
                    }
                    case 'MONTHS': {
                        defaultOption = { label: '1', value: '1', timePeriodType: 'MONTHS' };
                        break;
                    }
                    default: {
                        break;
                    }
                }

                this.setState({
                    ...this.state,
                    timePeriod: defaultOption
                });
            }
        });

    }

    rulesSelectChangeHandler = (selected, event, inputsToClearAfterChange) => {
        const { activeConfigureTab } = this.state;

         var clearedElements = {}
        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        const name = event.name;

        let devices;

        switch (event.action) {
            case "clear": {
                devices = {
                    form: {
                        ...this.state.form,
                        [activeConfigureTab]: {
                            ...this.state.form[activeConfigureTab],
                            ...clearedElements,
                            [name]: ''
                        }
                    }
                }
                break;
            }
            case "select-option": {
                devices = {
                    form: {
                        ...this.state.form,
                        [activeConfigureTab]: {
                            ...this.state.form[activeConfigureTab],
                            ...clearedElements,
                            [name]: selected
                        }
                    }
                }
                break;
            }
            case "remove-value": {
                devices = {
                    form: {
                        ...this.state.form,
                        [activeConfigureTab]: {
                            ...this.state.form[activeConfigureTab],
                            ...clearedElements,
                            [name]: this.deleteValueFromSelectedDevices(event)
                        }
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        this.setState({
            ...devices
        }, () => {
            const { form, activeConfigureTab } = this.state;

            this.setState({
                ...this.state,
                multipleDevices: form[activeConfigureTab].devices.length > 1
            })
        });
}

    deleteValueFromSelectedDevices(event) {
        return this.state.form[this.state.activeConfigureTab][event.name]
            .filter(device => event.removedValue.value !== device.value)
    }

    submitForm(setTileConfig) {
        const {
            title,
            module,
            dashboard,
            rules,
            timePeriod,
            timePeriodType,
            warningThreshold,
            criticalThreshold,
            dataPresentationType,
            dataType } = this.state;

        let tempForm = {};

        let rulesArr = [];

        if(this.validForm()) {
            rules.forEach( rule => rulesArr.push(rule.uuid));

            tempForm.module = module;
            tempForm.title = title;
            tempForm.rules = rulesArr;
            tempForm.dataPresentationType = dataPresentationType.value;
            tempForm.dataType = dataType;
            tempForm.warningThreshold = warningThreshold;
            tempForm.criticalThreshold = criticalThreshold;
            tempForm.timePeriod = timePeriod.value;
            tempForm.timePeriodType = timePeriodType.value;
            tempForm.isFilteredRules = true;


            setTileConfig({ tileConfig: tempForm, dashboardUuid: dashboard.value });
        }
    }


    validForm() {
        const {
            warningThreshold,
            criticalThreshold,
            dataPresentationType,
            title,
            rules
        } = this.state;


        var errorCount = 0;
        let tempTitleErrors = [],
            tempWarningThresholdErrors = [],
            tempCriticalThresholdErrors = [];

            
            if (hasValueUrlOrHtmlTag(title)) {
                tempTitleErrors.push("Pole zawiera niedozwolone wyrażenia");
                errorCount++;
            }

            if( title.value === '') {
                tempTitleErrors.push("Nazwa nie może być pusta");
                errorCount++;
            }

            if( title.length < 3) {
                tempTitleErrors.push("Nazwa musi mieć co najmniej 3 znaki");
                errorCount++;
            }

            if(dataPresentationType.value === 'NUMBER') {
                if(warningThreshold === '') {
                    tempWarningThresholdErrors.push("To pole nie może być puste");
                    errorCount++;
                }

                if(criticalThreshold === '') {
                    tempCriticalThresholdErrors.push("To pole nie może być puste");
                    errorCount++;
                }

                if(parseInt(warningThreshold) < 1) {
                    tempWarningThresholdErrors.push("Próg nie może być niższy niż 1");
                    errorCount++;
                }

                if(parseInt(criticalThreshold) < 1) {
                    tempCriticalThresholdErrors.push("Próg nie może być niższy niż 1");
                    errorCount++;
                }
            }

            if(!rules.length > 0) errorCount++;

        this.setState({
            ...this.state,
            titleErrors: tempTitleErrors,
            warningThresholdErrors: tempWarningThresholdErrors,
            criticalThresholdErrors: tempCriticalThresholdErrors
        });

        return !errorCount;
    }

    render() {
        const {
            dashboard,
            rules,
            dataPresentationType,
            titleErrors,
            warningThresholdErrors,
            criticalThresholdErrors
        } = this.state;
        const { formType } = this.props;


        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
        )
    }

    const getTitleInput = () => {
        return(
            <>
                <label className="mt-3">Tytuł sekcji</label>
                <FormInput
                    placeholder="Wpisz tytuł"
                    name="title"
                    value={this.state.title}
                    onChange={ e => {
                        const processedInputValue = e.target.value ||  ""

                        const titleErrors = [];

                        if (hasValueUrlOrHtmlTag(processedInputValue)) {
                            titleErrors.push("Pole zawiera niedozwolone wyrażenia");
                        }

                        if (processedInputValue.length > 64) {
                            titleErrors.push("Nazwa powinna składać się co najmniej z 3 znaków");
                        }
                                                    
                        this.setState({ ...this.state, title: processedInputValue, titleErrors: titleErrors })
                    }}
                    className={hasTitleError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                {hasTitleError && <ul className="mb-2 form-error-message">{renderError(this.state.titleErrors)}</ul>}
            </>
        );
    }

    const filterOptionsPromise = (inputValue, optionName) => {
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterOptions(inputValue, optionName));
            }, 500);
        });
    }

    const removeSelectedRule = (uuid) => {
        const { rules } = this.state;
        const filtered = rules.filter(rule => rule.uuid !== uuid);

        this.setState({
            ...this.state,
            rules: filtered
        })
    }

    const renderFilteredRulesDetails = () => {
        const { title, module, timePeriod, timePeriodType, warningThreshold, criticalThreshold } = this.state.fetchedData.tileConfig;
        const { rules } = this.state;

        let timeUnit, moduleLabel, warningThresholdUnit, criticalThresholdUnit;

        if(parseInt(warningThreshold) % 10 < 5 && parseInt(warningThreshold) % 10 > 0) {
            warningThresholdUnit = 'naruszenia';
        } else {
            warningThresholdUnit = 'naruszeń';
        }

        if(parseInt(criticalThreshold) % 10 < 5 && parseInt(criticalThreshold) % 10 > 0) {
            criticalThresholdUnit = 'naruszenia';
        } else {
            criticalThresholdUnit = 'naruszeń';
        }

        // eslint-disable-next-line default-case
        switch(timePeriodType) {
            case 'MINUTES': {
                timeUnit = 'minut';
                break;
            }
            case 'DAYS': {
                timeUnit = parseInt(timePeriod) > 1 ? 'dni' : 'dzień';
                break;
            }
            case 'MONTHS': {
                timeUnit = parseInt(timePeriod) > 1 ? 'miesięce' : 'miesiąc';
                break;
            }
        }

        // eslint-disable-next-line default-case
        switch(module) {
            case 'pcDevices': {
                moduleLabel = 'Bezpieczeństwo sprzętowe';
                break;
            }
            case 'controlPanels': {
                moduleLabel = 'Bezpieczeństwo fizyczne';
                break;
            }
            case 'networkDevices': {
                moduleLabel = 'Bezpieczeństwo sieciowe';
                break;
            }
        }

        const fieldStyle = {
            height: '3.3rem',
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'end',
            marginBottom: '1rem',
            borderBottom: '0.1rem solid rgba(0,0,0,0.3)'
        }

        const labelStyle = {
            position: 'absolute',
            fontSize: '0.8rem',
            color: 'rgba(0,0,0,0.4)',
            top: '0',
            left: '0'
        }

        const contentStyle = {
            paddingLeft: '1rem',
            paddingBottom: '0.3rem',
            alignSelf: 'flex-end'
        }

        return(
            <>
                <div style={fieldStyle}><span style={labelStyle}>Tytuł</span><span style={contentStyle}>{title}</span></div>
                <div style={fieldStyle}><span style={labelStyle}>Moduł</span><span style={contentStyle}>{moduleLabel}</span></div>
                { this.state.fetchedData.tileConfig.dataPresentationType !== 'TABLE' && <div style={fieldStyle}><span style={labelStyle}>Okres</span><span style={contentStyle}>{timePeriod} {timeUnit}</span></div>}
                { (warningThreshold && criticalThreshold) &&
                    <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-around' } }>
                        <div style={ { ...fieldStyle, width: '50%', marginRight: '1rem' } }><span style={labelStyle}>Próg ostrzegawczy</span><span style={contentStyle}>{warningThreshold} {warningThresholdUnit}</span></div>
                        <div style={ { ...fieldStyle, width: '50%', marginLeft: '1rem' } }><span style={labelStyle}>Próg krytyczny</span><span style={contentStyle}>{criticalThreshold} {criticalThresholdUnit}</span></div>
                    </div>
                }

                <div style={ { position: 'relative', paddingTop: '1.5rem' } }>
                    <span style={labelStyle}>Reguły</span>
                    <ol>
                        {rules.map(rule => <li key={rule.uuid} style={ { marginBottom: '0.5rem' } }>{rule.name}</li>)}
                    </ol>
                </div>
            </>
        );

    }

    var hasTitleError = Boolean(titleErrors.length);
    var hasWarningThresholdError = Boolean(warningThresholdErrors.length);
    var hasCriticalThresholdError = Boolean(criticalThresholdErrors.length);


    const ruleNameStyle = {
        maxWidth: 'calc(100% - 2rem)',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }


        return (
            <>
            {formType === 'addToDashboardDetails' ?
                <Row>
                    <Col sm='12'>
                        { this.state.fetchedData && renderFilteredRulesDetails() }
                    </Col>
                </Row>
            : formType === 'addToDashboard' ?
                <Row>
                    <Col sm='12'>
                        {getTitleInput()}

                        <TooltippedSelect
                            isAsync
                            tooltip
                            label="Dashboard"
                            tooltipContent="Dashboard, do którego zostaną dodane reguły"
                            placeholder="Wybierz dashboard"
                            noOptionsMsg="Brak dostępnych opcji"
                            name="dashboard"
                            value={dashboard}
                            isDisabled={!this.state.rules.length > 0}
                            onChange={this.selectChangeHandler }
                            defaultOptions={this.state.dashboardHints}
                            loadOptions={null} />

                        <TooltippedSelect
                            isAsync
                            tooltip
                            label="Typ prezentacji danych"
                            tooltipContent="Rodzaj prezentacji, który zostanie wykorzystany do wyświetlenia danych"
                            placeholder="Wybierz typ prezentacji danych"
                            noOptionsMsg="Brak dostępnych opcji"
                            name="dataPresentationType"
                            value={dataPresentationType}
                            isDisabled={!dashboard && !this.state.rules.length > 0}
                            onChange={this.selectChangeHandler }
                            defaultOptions={this.state.dataPresentationTypeHints}
                            loadOptions={null} />

                                {(this.state.dataPresentationType && this.state.dataPresentationType.value === "NUMBER") ?
                                    <>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-50 mr-2">
                                                <label className="mt-3">Próg ostrzegawczy (ilość wystąpień)</label>
                                                <FormInput
                                                    name="warningThreshold"
                                                    value={this.state.warningThreshold}
                                                    onChange={ e => {
                                                        const val = e.target.value;

                                                        if((/^[0-9\b]+$/).test(val) || val === '')
                                                        this.setState({
                                                            ...this.state,
                                                            warningThreshold: val,
                                                            warningThresholdErrors: []
                                                        })
                                                    }}
                                                    className={hasWarningThresholdError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                                                {hasWarningThresholdError && <ul className="mb-2 form-error-message">{renderError(this.state.warningThresholdErrors)}</ul>}
                                            </div>

                                            <div className="w-50">
                                                <label className="mt-3">Próg krytyczny (ilość wystąpień)</label>
                                                <FormInput
                                                    name="criticalThreshold"
                                                    value={this.state.criticalThreshold}
                                                    onChange={ e => {
                                                        const val = e.target.value;

                                                        if((/^[0-9\b]+$/).test(val) || val === '')
                                                        this.setState({
                                                            ...this.state,
                                                            criticalThreshold: val,
                                                            criticalThresholdErrors: []
                                                        })
                                                    }}
                                                    className={hasCriticalThresholdError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                                                {hasCriticalThresholdError && <ul className="mb-2 form-error-message">{renderError(this.state.criticalThresholdErrors)}</ul>}
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-50 mr-2">
                                                <TooltippedSelect
                                                    isAsync
                                                    tooltip
                                                    label="Okres"
                                                    tooltipContent="Okres"
                                                    noOptionsMsg="Brak dostępnych opcji"
                                                    name="timePeriod"
                                                    value={this.state.timePeriod}
                                                    onChange={this.selectChangeHandler}
                                                    defaultOptions={this.state.timePeriods.filter( timePeriod => timePeriod.timePeriodType === this.state.timePeriodType.value)}
                                                    loadOptions={(inputValue) => filterOptionsPromise(inputValue, "timePeriodType")} />
                                            </div>

                                            <div className="w-50">
                                                <TooltippedSelect
                                                    isAsync
                                                    label={<span style={{opacity: '0', pointerEvents: 'none'}}>Okres</span>}
                                                    noOptionsMsg="Brak dostępnych opcji"
                                                    name="timePeriodType"
                                                    value={this.state.timePeriodType}
                                                    onChange={this.selectChangeHandler}
                                                    defaultOptions={this.state.timePeriodTypes}
                                                    loadOptions={(inputValue) => filterOptionsPromise(inputValue, "timePeriodType")} />
                                            </div>
                                        </div>

                                    </>

                                    : null
                                }

                            {!this.state.rules.length > 0 && <p style={ { color: 'red' } }>Brak wybranych reguł</p>}
                            { this.state.rules.length > 0 &&
                            <>
                                <label className="my-3">Wybrane reguły:</label>

                                <ol className="w-50">
                                {rules.map(rule => {
                                    return(
                                        <li key={rule.uuid} style={ { maxWidth: '100%',  } }>
                                            <span style={ { display: 'flex', alignItems: 'center', paddingLeft: '1rem', margin: '0', marginBottom: '0.5rem' } } >

                                                { rule.name.length > 25 ?
                                                    <Tooltip title={rule.name} placement="top" arrow>
                                                        <span style={ruleNameStyle}>{rule.name}</span>
                                                    </Tooltip>
                                                :
                                                    <span style={ruleNameStyle}>{rule.name}</span>
                                                }

                                                <FontAwesomeIcon icon={faTimes} onClick={() => removeSelectedRule(rule.uuid)} style={ { cursor: 'pointer', fontSize: '0.9rem', marginLeft: '1rem' } }/>
                                            </span>

                                        </li>
                                    );
                                })}
                                </ol>
                            </>}
                    </Col>
                </Row>
                : null
            }
            </>
        );
    }
}

export default AddToDashboardForm;
