import React from 'react';
import { Row, Col, FormInput, Alert } from 'shards-react';
import Select from 'react-select';
import { EMAIL_REGEXP } from './../../../utils/Patterns';
import { trackPromise } from 'react-promise-tracker';
import API from "../../../api/AxiosConfiguration";
import * as AppConstants from "../../../constants";
import * as EmailClientErrors from "./../../../utils/EmailClientErrors";
import * as SocToast from './../../../utils/SocToast';

class SmtpConfigurationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasEmailBeenConfigured: false,
            form: {
                email: "",
                password: "",
                smtpEncryption: "",
                smtpAddress: "",
                smtpPort: "",
                formNotFound: false,
            },
            formErrors: {
                email: [],
                password: [],
                smtpEncryption: [],
                smtpAddress: [],
                smtpPort: [],
                global: []
            },
            formHints: {
                smtpEncryption: [
                    { label: "Bez szyfrowania", value: "", port: 25 },
                    { label: "STARTTLS", value: "STARTTLS", port: 587 },
                    { label: "SSL/TLS", value: "SSL_TLS", port: 465 }
                ]
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);

        this.validForm = this.validForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.findHintByValue = this.findHintByValue.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.checkEmailConfiguration = this.checkEmailConfiguration.bind(this);
        this.fetchEmailConfigurationForm = this.fetchEmailConfigurationForm.bind(this);
        this.clearFormErrors = this.clearFormErrors.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        formErrors[event.name] = [];
        this.setState({
            form: {
                ...form,
                [event.name]: item,
                smtpPort: item.port ? item.port : this.state.form.port
            }
        });
    }

    validForm() {
        const { formErrors } = this.state;
        const {
            email,
            password,
            smtpEncryption,
            smtpAddress,
            smtpPort
        } = this.state.form;

        formErrors["email"] = [];
        formErrors["password"] = [];
        formErrors["smtpEncryption"] = [];
        formErrors["smtpAddress"] = [];
        formErrors["smtpPort"] = [];

        var errorCount = 0;

        if (!EMAIL_REGEXP.test(email)) {
            formErrors["email"].push("Podany e-mail jest niepoprawny");
            errorCount++;
        }

        if (password.length <= 0) {
            formErrors["password"].push("Hasło nie może być puste");
            errorCount++;
        }

        if (!smtpEncryption || !smtpEncryption.value || smtpEncryption.value.length <= 0) {
            formErrors["smtpEncryption"].push("Nie wybrano metody szyfrowania");
            errorCount++;
        }

        if (smtpAddress.length <= 0) {
            formErrors["smtpAddress"].push("Adres serwera SMTP nie może być pusty");
            errorCount++;
        }

        if (smtpPort.length <= 0) {
            formErrors["smtpPort"].push("Podano nieprawidłowy numer portu (1-65535).");
            errorCount++;
        }

        this.setState({ formErrors: { ...formErrors } });
        return !errorCount;
    }

    buildForm() {
        const { form } = this.state;

        return {
            ...form,
            smtpEncryption: form.smtpEncryption && form.smtpEncryption.value
        }
    }

    submitForm = (onSuccess = () => { }) => {
        const { formErrors } = this.state;
        this.clearFormErrors();

        if (this.validForm()) {
            var apiPromise = API.post(AppConstants.COMPANY_SETTINGS_EMAIL, {
                form: {
                    ...this.buildForm(),
                }
            });

            trackPromise(
                apiPromise.then((result) => {
                    if (result.status === 200) {
                        const emailClientStatus = result.data;
                        if (emailClientStatus.code !== "200") {
                            this.setState({
                                formErrors: {
                                    ...this.state.formErrors,
                                    global: [EmailClientErrors.getMessage(emailClientStatus.code)]
                                }
                            })
                        } else {
                            SocToast.success("Powodzenie", "Udało się dodać adres email. Od tej chwili możesz ustawić model powiadomień na adres email")
                        }
                    } else if (result.status === 204) {
                        this.setState({ formNotFound: true });
                    }
                }).catch((error) => {
                    var response = error.response;
                    if (response && response.status === 400) {
                        response.data.errors.forEach(error => {
                            formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                        })
                        this.setState({ formErrors: formErrors });
                    }

                    if (response && response.status === 409) {
                        this.setState({
                            formErrors: {
                                ...formErrors,
                                global: [response.data.message]
                            }
                        });
                    }
                })
            );
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    updateComponent() {
        this.checkEmailConfiguration();
        this.fetchEmailConfigurationForm();
    }

    checkEmailConfiguration() {
        API.get(AppConstants.COMPANY_SETTINGS_EMAIL_CHECK).then((result) => {
            if (result.status === 200) {
                this.setState({
                    hasEmailBeenConfigured: result.data
                })
            }
        });
    }

    fetchEmailConfigurationForm() {
        API.get(AppConstants.COMPANY_SETTINGS_EMAIL)
            .then((result) => {
                if (result.status === 200) {
                    this.setState({
                        form: {
                            ...result.data,
                            smtpEncryption: this.findHintByValue("smtpEncryption", result.data.smtpEncryption)
                        }
                    })
                }
            });
    }

    clearFormErrors() {
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                email: [],
                password: [],
                smtpEncryption: [],
                smtpAddress: [],
                smtpPort: [],
                global: []
            }
        })
    }

    render() {
        const { hasEmailBeenConfigured } = this.state;
        const { email, password, smtpEncryption, smtpAddress, smtpPort } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasGlobalError = Boolean(this.state.formErrors.global.length);
        var hasEmailError = Boolean(this.state.formErrors.email.length);
        var hasPasswordError = Boolean(this.state.formErrors.password.length);
        var hasSmtpEncryptionError = Boolean(this.state.formErrors.smtpEncryption.length);
        var hasSmtpAddressError = Boolean(this.state.formErrors.smtpAddress.length);
        var hasSmtpPortError = Boolean(this.state.formErrors.smtpPort.length);

        const defaultSelectProps = {
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            noOptionsMessage: () => "Brak dostępnych opcji",
            placeholder: "Wybierz"
        }

        return (
            <Row>
                <Col>
                    {!hasGlobalError && hasEmailBeenConfigured &&
                        <Alert theme="success">
                            <ul style={{ listStyleType: "none", paddingInlineStart: "0", marginBottom: "0" }}>
                                <li>Email został poprawnie skonfigurowany</li>
                            </ul>
                        </Alert>
                    }

                    {hasGlobalError &&
                        <Alert theme="danger">
                            <ul style={{ listStyleType: "none", paddingInlineStart: "0", marginBottom: "0" }}>
                                {renderError(this.state.formErrors.global)}
                            </ul>
                        </Alert>
                    }

                    <label>*Nazwa użytkownika SMTP</label>
                    <FormInput
                        name="email"
                        value={email || ""}
                        onChange={this.inputChangeHandler}
                        type="email"
                        invalid={hasEmailError}
                        className={hasEmailError ? "mb-0" : "mb-2"} />
                    {hasEmailError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.email)}</ul>}

                    <label>*Hasło SMTP</label>
                    <FormInput
                        name="password"
                        value={password || ""}
                        onChange={this.inputChangeHandler}
                        type="password"
                        invalid={hasPasswordError}
                        className={hasPasswordError ? "mb-0" : "mb-2"} />
                    {hasPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.password)}</ul>}

                    <label>*Szyfrowanie</label>
                    <Select
                        {...defaultSelectProps}
                        name="smtpEncryption"
                        value={smtpEncryption || ""}
                        onChange={this.selectChangeHandler}
                        options={this.state.formHints["smtpEncryption"]}
                        className={hasSmtpEncryptionError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasSmtpEncryptionError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.smtpEncryption)}</ul>}

                    <Row>
                        <Col>
                            <label>*Adres serwera SMTP</label>
                            <FormInput
                                name="smtpAddress"
                                value={smtpAddress || ""}
                                onChange={this.inputChangeHandler}
                                invalid={hasSmtpAddressError}
                                className={hasSmtpAddressError ? "mb-0" : "mb-2"} />
                            {hasSmtpAddressError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.smtpAddress)}</ul>}
                        </Col>
                        <Col>
                            <label>*Port serwera SMTP</label>
                            <FormInput
                                name="smtpPort"
                                value={smtpPort || ""}
                                onChange={this.inputChangeHandler}
                                type="number"
                                invalid={hasSmtpPortError}
                                className={hasSmtpPortError ? "mb-0" : "mb-2"} />
                            {hasSmtpPortError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.smtpPort)}</ul>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default SmtpConfigurationForm;
