import React from "react";
import { Button, FormInput } from "shards-react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { EMAIL_REGEXP } from "../../../utils/Patterns";
import GoogleReCaptcha from "../../recaptcha/GoogleReCaptcha"
import * as socToast from '../../../utils/SocToast'

class RecoveryPasswordEmailForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: ''
            },
            formErrors: {
                email: []
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.reCaptchaComponentRef = React.createRef();
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { email } = this.state.form;

        formErrors["email"] = [];

        var errorCount = 0;

        if (!EMAIL_REGEXP.test(email)) {
            formErrors["email"].push("Email nie jest poprawny");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    async submitForm() {
        const { form, formErrors } = this.state;

        let reCaptchaToken = ''
    
        try {
            reCaptchaToken = await this.reCaptchaComponentRef.current.executeCaptcha()
        } catch (error) {
            console.error(error)
        }
        
        const config = { 
            headers: { 'g-recaptcha': reCaptchaToken }
        }


        if (this.validForm()) {
            API.post(Constants.CUSTOMERS_URL + "/changePasswordRequest", {
                email: form.email,
            }, config).then((result) => {
                if (result.status === 200) {
                    this.props.onSuccess("Na wybrany wcześniej sposób odzyskiwania hasła została wysłana wiadomość z linkiem do zmiany hasła");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    if (response.data.errors) {
                        response.data.errors.forEach(error => {
                            formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                        })
                    }

                    this.setState({ formErrors: formErrors });
                } else if (response && response.status === 401) {
                    socToast.error("Błąd resetowania hasła", "")
                }
            });
        }
    }

    render() {
        const { email } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const hasEmailError = Boolean(this.state.formErrors.email.length);

        return (
            <div>
                <label>Email:</label>
                <FormInput
                    name="email"
                    value={email || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasEmailError}
                    className={hasEmailError ? "mb-0" : "mb-2"} />
                {hasEmailError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.email)}</ul>}

                <Button
                    onClick={this.submitForm}
                    outline
                    theme="info"
                    className="mt-2 ml-1 float-right">
                    Wyślij
                </Button>

                <GoogleReCaptcha ref={this.reCaptchaComponentRef} />

            </div>
        );
    }

}

export default RecoveryPasswordEmailForm;