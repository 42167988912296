import React from "react";
import { Button, Card, CardBody, CardFooter, CardHeader } from "shards-react";
import SaveArchivePasswordForm from './forms/SaveArchivePasswordForm';
import * as SocToast from '../../utils/SocToast';

class CustomerArchivePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onPasswordSave() {
        SocToast.success("Hasło ustawione", "Od tej chwili wszystkie pliki będą wymagały podanie tego hasła.")
    }

    render() {
        return (
            <Card className="mb-3 w-100">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Hasło do generowanych archiwów</h6>
                    <div className="block-handle" />
                </CardHeader>

                <CardBody className="py-1">
                    <div>Po zapisaniu wszystkie pliki, które zostaną pobrane, będą wymagały podania podanego w tej chwili hasła. Pamiętaj, zawsze możesz je zmienić.</div>
                    <SaveArchivePasswordForm
                        onSuccess={this.onPasswordSave}
                        ref="saveReportsPasswordForm" />
                </CardBody>
                <CardFooter>
                    <Button outline theme="info" className="mb-2 mr-1 float-right"
                        onClick={() => { this.refs.saveReportsPasswordForm.submitForm(); }}>
                        Zapisz
                    </Button>
                </CardFooter>
            </Card>
        );
    }

}

export default CustomerArchivePassword;