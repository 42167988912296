import React from 'react';
import { Row, Col, FormInput, FormCheckbox } from 'shards-react';
import DictionaryInput from '../../../common/dictionary-input/DictionaryInput';
import DictionaryPreview from './../../../common/dictionary-input/DictionaryList';
import DictionaryGroup from './../../../common/dictionary-input/DictionaryGroup';

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';
import * as socToast from '../../../../utils/SocToast';
import * as Patterns from '../../../../utils/Patterns'

const renderError = (errors) => {
    return errors.map((error, index) =>
        <li key={index}>{error}</li>
    )
}

class ConfirmZoneForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                secure: false,

                removableDevices: [],
                ipAddresses: [],
                paths: [],
                emails: [],
                printers: [],
                networkAddresses: []
            },
            formErrors: {
                form: [],
                name: [],
                removableDevices: [],
                ipAddresses: [],
                paths: [],
                emails: [],
                printers: [],
                networkAddresses: [],
            },
            isOptionsLoading: false,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);

        this.fetchZoneForm = this.fetchZoneForm.bind(this);

        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.validDictionaryInput = this.validDictionaryInput.bind(this);
        this.validIpAddress = this.validIpAddress.bind(this);
        this.validEmail = this.validEmail.bind(this);

        this.submitForm = this.submitForm.bind(this);
        this.validForm = this.validForm.bind(this);
        this.buildForm = this.buildForm.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;

        if (uuid.length) {
            this.fetchZoneForm(uuid);
        }
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    checkboxChangeHandler = (name) => {
        var { form, formErrors } = this.state;
        form[name] = !form[name];
        formErrors[name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, removableDevices, ipAddresses, paths, emails, printers, networkAddresses } = this.state.form;

        formErrors["name"] = [];
        formErrors["removableDevices"] = [];
        formErrors["ipAddresses"] = [];
        formErrors["paths"] = [];
        formErrors["emails"] = [];
        formErrors["printers"] = [];
        formErrors["networkAddresses"] = [];
        formErrors["form"] = []

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 255) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 255 znaków");
            errorCount++;
        }

        const countOfAllValues = removableDevices.length +
            ipAddresses.length +
            paths.length +
            emails.length +
            printers.length +
            networkAddresses.length;

        if (countOfAllValues <= 0) {
            formErrors["form"].push("Żaden z elementów nie został wybrany");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildForm() {
        const { name, secure, removableDevices, ipAddresses, paths, emails, printers, networkAddresses } = this.state.form;

        return {
            name: name,
            secure: secure,
            removableDevices: removableDevices,
            ipAddresses: ipAddresses,
            paths: paths,
            emails: emails,
            printers: printers,
            networkAddress: networkAddresses
        }
    }

    submitForm(onSuccess) {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.patch(AppConstants.PC_SAFETICA_ZONE_CONFIGURATION_URL + "/" + uuid, {
                    form: this.buildForm()
                })
            } else {
                apiPromise = API.post(AppConstants.PC_SAFETICA_ZONE_CONFIGURATION_URL, {
                    form: this.buildForm()
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Strefa została dodana poprawnie.");
                }

                if (result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Strefa została zedytowana poprawnie.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            });
        }
    }

    validDictionaryInput(name, value) {
        const { form, formErrors } = this.state;

        formErrors[name] = [];
        formErrors.form = []

        var isValid = true;

        if (isValid && !value) {
            formErrors[name].push("Nie można dodać pustego elementu");
            isValid = false;
        }

        if (isValid && value && value.length > 255) {
            formErrors[name].push("Podane element nie może być dłuższy niż 255 znaków");
            isValid = false;
        }

        if (isValid && value && form[name].find(item => item.toLowerCase() === value.toLowerCase())) {
            formErrors[name].push("Podany element już istnieje");
            isValid = false;
        }

        this.setState({ formErrors: formErrors });
        return isValid;
    }

    validIpAddress(name, value) {
        const { formErrors } = this.state;
        var isValid = true;

        if (!Patterns.IP_REGEXP.test(value)) {
            formErrors[name].push("Adres IP jest niepoprawny");
            isValid = false;
        }

        this.setState({ formErrors: formErrors });
        return isValid;
    }

    validEmail(name, value) {
        const { formErrors } = this.state;
        var isValid = true;

        if (!Patterns.EMAIL_REGEXP.test(value)) {
            formErrors[name].push("Podany e-mail jest niepoprawny");
            isValid = false;
        }

        this.setState({ formErrors: formErrors });
        return isValid;
    }

    addItem(name, value) {
        const { form } = this.state;
        form[name].push(value);
        this.setState({ form: form })
    }

    deleteItem(name, index) {
        const { form } = this.state;

        if (index > -1) {
            form[name].splice(index, 1);
            this.setState({ form: form })
        }
    }

    fetchZoneForm(uuid) {
        API.get(AppConstants.PC_SAFETICA_ZONE_CONFIGURATION_URL + "/" + uuid)
            .then(result => {
                this.setState({
                    form: {
                        ...this.state.form,
                        ...result.data,
                        removableDevices: result.data.removableDevices || [],
                        ipAddresses: result.data.ipAddresses || [],
                        paths: result.data.paths || [],
                        emails: result.data.emails || [],
                        printers: result.data.printers || [],
                        networkAddresses: result.data.networkAddresses || []
                    }
                })
            })
    }

    render() {
        const {
            name,
            secure,
            removableDevices,
            ipAddresses,
            paths,
            emails,
            printers,
            networkAddresses
        } = this.state.form;

        var hasFormError = Boolean(this.state.formErrors.form.length);
        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasRemovableDevicesError = Boolean(this.state.formErrors.removableDevices.length);
        var hasIpAddressesError = Boolean(this.state.formErrors.ipAddresses.length);
        var hasPathsError = Boolean(this.state.formErrors.paths.length);
        var hasEmailsError = Boolean(this.state.formErrors.emails.length);
        var hasPrintersError = Boolean(this.state.formErrors.printers.length);
        var hasNetworkAddressesError = Boolean(this.state.formErrors.networkAddresses.length);

        return (
            <React.Fragment>
                <Row>
                    <Col sm="12" md="6">
                        <label>*Nazwa</label>
                        <FormInput
                            name="name"
                            value={name || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasNameError}
                            className={hasNameError ? "mb-0" : "mb-2"} />
                        {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}
                    </Col>
                    <Col sm="12" md="6">
                        <label>Bezpieczna strefa?</label>
                        <FormCheckbox
                            name="secure"
                            small
                            toggle
                            checked={secure}
                            onChange={() => this.checkboxChangeHandler("secure")}>
                        </FormCheckbox>
                    </Col>
                </Row >
                <Row>
                    <Col sm="12" md="6">
                        <label>Urządzenia wymienne</label>
                        <DictionaryGroup>
                            <DictionaryInput
                                name="removableDevices"
                                onAdd={(name, value) => {
                                    const isValid = this.validDictionaryInput(name, value);
                                    isValid && this.addItem(name, value);
                                    return isValid;
                                }}
                                className={hasRemovableDevicesError ? "mb-0" : "mb-2"}
                                invalid={hasFormError} />
                            {hasRemovableDevicesError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.removableDevices)}</ul>}

                            <DictionaryPreview className="dictionary-preview"
                                name="removableDevices"
                                dictionary={removableDevices}
                                onDelete={this.deleteItem} />
                        </DictionaryGroup>

                        <label>Adresy IP</label>
                        <DictionaryGroup>
                            <DictionaryInput
                                name="ipAddresses"
                                onAdd={(name, value) => {
                                    const isValid = this.validDictionaryInput(name, value) && this.validIpAddress(name, value);
                                    isValid && this.addItem(name, value);
                                    return isValid;
                                }}
                                className={hasIpAddressesError ? "mb-0" : "mb-2"}
                                invalid={hasFormError} />
                            {hasIpAddressesError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ipAddresses)}</ul>}

                            <DictionaryPreview className="dictionary-preview"
                                name="ipAddresses"
                                dictionary={ipAddresses}
                                onDelete={this.deleteItem} />
                        </DictionaryGroup>

                        <label>Ścieżki</label>
                        <DictionaryGroup>
                            <DictionaryInput
                                name="paths"
                                onAdd={(name, value) => {
                                    const isValid = this.validDictionaryInput(name, value);
                                    isValid && this.addItem(name, value);
                                    return isValid;
                                }}
                                className={hasPathsError ? "mb-0" : "mb-2"}
                                invalid={hasFormError} />
                            {hasPathsError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.paths)}</ul>}

                            <DictionaryPreview className="dictionary-preview"
                                name="paths"
                                dictionary={paths}
                                onDelete={this.deleteItem} />
                        </DictionaryGroup>
                    </Col>
                    <Col sm="12" md="6">
                        <label>Adresy Email</label>
                        <DictionaryGroup>
                            <DictionaryInput
                                name="emails"
                                onAdd={(name, value) => {
                                    const isValid = this.validDictionaryInput(name, value) && this.validEmail(name, value);
                                    isValid && this.addItem(name, value);
                                    return isValid;
                                }}
                                className={hasEmailsError ? "mb-0" : "mb-2"}
                                invalid={hasFormError} />
                            {hasEmailsError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.emails)}</ul>}

                            <DictionaryPreview className="dictionary-preview"
                                name="emails"
                                dictionary={emails}
                                onDelete={this.deleteItem} />
                        </DictionaryGroup>

                        <label>Drukarki</label>
                        <DictionaryGroup>
                            <DictionaryInput
                                name="printers"
                                onAdd={(name, value) => {
                                    const isValid = this.validDictionaryInput(name, value);
                                    isValid && this.addItem(name, value);
                                    return isValid;
                                }}
                                className={hasPrintersError ? "mb-0" : "mb-2"}
                                invalid={hasFormError} />
                            {hasPrintersError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.printers)}</ul>}

                            <DictionaryPreview className="dictionary-preview"
                                name="printers"
                                dictionary={printers}
                                onDelete={this.deleteItem} />
                        </DictionaryGroup>

                        <label>Adresy sieciowe</label>
                        <DictionaryGroup>
                            <DictionaryInput
                                name="networkAddresses"
                                onAdd={(name, value) => {
                                    const isValid = this.validDictionaryInput(name, value);
                                    isValid && this.addItem(name, value);
                                    return isValid;
                                }}
                                className={hasNetworkAddressesError ? "mb-0" : "mb-2"}
                                invalid={hasFormError} />
                            {hasNetworkAddressesError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.networkAddresses)}</ul>}

                            <DictionaryPreview className="dictionary-preview"
                                name="networkAddresses"
                                dictionary={networkAddresses}
                                onDelete={this.deleteItem} />
                        </DictionaryGroup>
                    </Col>

                    <Col sm="12">
                        <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.form)}</ul>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

}

export default ConfirmZoneForm;