import React from "react";
import { Col, Container, Row } from "shards-react";
import ForgotPasswordForm from './../components/forgot-password/forms/ForgotPasswordForm';

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.redirectToLoginPage = this.redirectToLoginPage.bind(this);
    }

    redirectToLoginPage(message) {
        this.props.history.push({
            pathname: "/login",
            state: {
                message: message,
            }
        });
    }

    render() {
        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4 text-center">
                    <Col style={{ fontSize: "28px" }}>Zapomniane hasło</Col>
                </Row>
                <Row noGutters className="page-header py-4">
                    <Col sm="12" md="8" className="offset-md-2 text-center">
                        <p>Jeżeli zapomniałeś swojego hasła, podaj adres email, którego użyłeś do aktywacji konta.
                            Na podstawie wybranej formy odzyskiwania hasła (domyślnie email) zostanie wysłany specjalny kod,
                            dzięki któremu będziesz mógł zmienić swoje dotychczasowe hasło.</p>
                    </Col>
                </Row>

                <Row noGutters className="page-header">
                    <Col sm="12" md="6" className="offset-md-3">
                        <ForgotPasswordForm onSuccess={this.redirectToLoginPage} />
                    </Col>
                </Row>
            </Container>
        );
    }

}

export default ForgotPassword;