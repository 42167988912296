import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button } from "shards-react";
import SmtpConfigurationForm from './forms/SmtpConfigurationForm';

class EmailClientConfiguration extends React.Component {

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.onCheckConnectionAndSaveClick = this.onCheckConnectionAndSaveClick.bind(this);
    }

    onCheckConnectionAndSaveClick() {
        this.formRef.current.submitForm();
    }

    render() {
        return (
            <Card>
                <CardHeader className="border-bottom d-inline">
                    <h6 className="m-0">Konfiguracja Klienta Email (SMTP)</h6>
                </CardHeader>
                <CardBody>
                    <SmtpConfigurationForm ref={this.formRef} />
                </CardBody>
                <CardFooter>
                    <Button onClick={this.onCheckConnectionAndSaveClick} outline theme="info" className="mb-2 mr-1 float-right">Sprawdź połączenie i zapisz</Button>
                </CardFooter>
            </Card>
        );
    }

}

export default EmailClientConfiguration;