import React from 'react';
import { Row, Col, FormInput } from 'shards-react';
import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';
import * as socToast from '../../../../utils/SocToast';
import DictionaryGroup from './../../../common/dictionary-input/DictionaryGroup';
import DictionaryInput from './../../../common/dictionary-input/DictionaryInput';
import DictionaryPreview from './../../../common/dictionary-input/DictionaryList';

class ConfirmDictionaryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                words: []
            },
            formErrors: {
                name: [],
                words: []
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.fetchDeviceApplicationForm = this.fetchDeviceApplicationForm.bind(this);
        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.validDictionaryInput = this.validDictionaryInput.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.validForm = this.validForm.bind(this);
        this.buildForm = this.buildForm.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;

        if (uuid.length) {
            this.fetchDeviceApplicationForm(uuid);
        }
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, words } = this.state.form;

        formErrors["name"] = [];
        formErrors["words"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 255) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 255 znaków");
            errorCount++;
        }

        if (words.length <= 0) {
            formErrors["words"].push("Nie znaleziono żadnego słowa");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildForm() {
        const { name, words } = this.state.form;
        return {
            name: name,
            words: words
        }
    }

    submitForm(onSuccess) {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.patch(AppConstants.PC_SAFETICA_DICTIONARY_CONFIGURATION_URL + "/" + uuid, {
                    form: this.buildForm()
                })
            } else {
                apiPromise = API.post(AppConstants.PC_SAFETICA_DICTIONARY_CONFIGURATION_URL, {
                    form: this.buildForm()
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Słownik został dodany poprawnie.");
                }

                if (result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Słownik został zedytowany poprawnie.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            });
        }
    }

    validDictionaryInput(name, value) {
        const { form, formErrors } = this.state;

        formErrors[name] = [];
        formErrors.form = []

        var isValid = true;

        if (isValid && !value) {
            formErrors[name].push("Nie można dodać pustego elementu");
            isValid = false;
        }

        if (isValid && value && value.length > 255) {
            formErrors[name].push("Podane element nie może być dłuższy niż 255 znaków");
            isValid = false;
        }

        if (isValid && value && form[name].find(item => item.toLowerCase() === value.toLowerCase())) {
            formErrors[name].push("Podany element już istnieje");
            isValid = false;
        }

        this.setState({ formErrors: formErrors });
        return isValid;
    }

    addItem(name, value) {
        const { form } = this.state;
        form[name].push(value);
        this.setState({ form: form })
    }

    deleteItem(name, index) {
        const { form } = this.state;

        if (index > -1) {
            form[name].splice(index, 1);
            this.setState({ form: form })
        }
    }

    fetchDeviceApplicationForm(uuid) {
        API.get(AppConstants.PC_SAFETICA_DICTIONARY_CONFIGURATION_URL + "/" + uuid)
            .then(result => {
                this.setState({
                    form: {
                        ...this.state.form,
                        ...result.data
                    }
                })
            })
    }

    render() {
        const { name, words } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasWordsError = Boolean(this.state.formErrors.words.length);

        return (
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <label>*Nazwa</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>Słowo</label>
                    <DictionaryGroup>
                        <DictionaryInput
                            name="words"
                            onAdd={(name, value) => {
                                const isValid = this.validDictionaryInput(name, value);
                                isValid && this.addItem(name, value);
                                return isValid;
                            }}
                            className={hasWordsError ? "mb-0" : "mb-2"}
                            invalid={hasWordsError} />
                        {hasWordsError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.words)}</ul>}

                        <DictionaryPreview className="dictionary-preview"
                            name="words"
                            dictionary={words}
                            onDelete={this.deleteItem} />
                    </DictionaryGroup>
                </Col>
            </Row>
        );
    }

}

export default ConfirmDictionaryForm;