import React from "react";
import { ConfirmModal } from "../../common/Modal";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import {
    Row,
    Col,
} from "shards-react";

export default class TwoFactorDeactivateModal extends React.Component {

    render() {
        const {
            open,
            toggle,
            twoFactorOperation
        } = this.props;

        return (
            <ConfirmModal
                title="Deaktywacja Two Factor"
                open={open}
                toggle={toggle}
                submitAction={() => this.refs.twoFactorDeactivate.submitForm()}
                positiveButtonText="Wyłącz"
                negativeButtonText="Anuluj">

                <TwoFactorDeactivate toggle={toggle} twoFactorOperation={twoFactorOperation} ref="twoFactorDeactivate" />

            </ConfirmModal>
        )

    }

}

class TwoFactorDeactivate extends React.Component {

    submitForm = () => {
        API.put(Constants.AUTHENTICATOR_TOKEN_URL + '/twoFactor/' + false, {twoFactorOperation: this.props.twoFactorOperation}).then((result) => {
            if (result.status === 200) {
                this.props.toggle();
            }
        });
    }

    render() {

        return (
            <Row>
                <Col sm="12">
                    <label>Stopień bezpieczeństwa twojego konta zostanie zmniejszony. Czy na pewno chcesz wyłączyć weryfikację dwuetapową?</label>
                </Col>
            </Row>
        );
    }
}
