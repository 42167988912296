import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button, Row, Col, ListGroup, ListGroupItem } from 'shards-react';
import { Store } from "../../flux";
import * as AppConstants from '../../constants';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../api/AxiosConfiguration";
import * as Constants from "../../constants";
import { getIconByWarningStatus, getIconByActionOrStatus, getActionParameters } from '../../utils/Actions';

import ConfirmControlPanelSensorModal from './modals/ConfirmControlPanelSensorModal';
import DeleteControlPanelSensorModal from './modals/DeleteControlPanelSensorModal';
import withLoader from '../common/hoc/withLoader';
import LinkButton from './../buttons/LinkButton';

class ControlPanelSensors extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isSocAdmin: Store.getUserRole() === AppConstants.Roles.SOCADMIN,
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isCreateControlPanelSensorModalShow: false,
            isDeleteControlPanelSensorModalShow: false,
            selectedControlPanelSensorUUID: "",
            sensorsData: [],
        };

        this.toggleCreateControlPanelSensorModal = this.toggleCreateControlPanelSensorModal.bind(this);
        this.toggleDeleteControlPanelSensorModal = this.toggleDeleteControlPanelSensorModal.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.fetchSensors = this.fetchSensors.bind(this);
        this.acceptSensor = this.acceptSensor.bind(this);
        this.declineSensor = this.declineSensor.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateComponent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleCreateControlPanelSensorModal(uuid) {
        if (this._isMounted)
            this.setState({
                isCreateControlPanelSensorModalShow: !this.state.isCreateControlPanelSensorModalShow,
                selectedControlPanelSensorUUID: uuid,
            });
    }

    toggleDeleteControlPanelSensorModal(uuid) {
        if (this._isMounted)
            this.setState({
                isDeleteControlPanelSensorModalShow: !this.state.isDeleteControlPanelSensorModalShow,
                selectedControlPanelSensorUUID: uuid,
            });
    }

    updateComponent() {
        this.fetchSensors();
    }

    fetchSensors() {
        const { uuid } = this.props;
        this.props.setLoading(true);
        API.post(Constants.CONTROL_PANEL_SENSORS_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    var sensorsData = result.data;
                    if (this._isMounted)
                        this.setState({
                            sensorsData: sensorsData.sensors,
                        })
                }

                this.props.setLoading(false);
            })
    }

    acceptSensor(uuid) {
        API.get(AppConstants.CONTROL_PANEL_SENSORS_URL + "/" + uuid + "/accept", {
            params: { uuid: this.props.uuid }
        }).then(result => {
            if (result.status === 200) {
                this.updateComponent();
            }
        });
    }

    declineSensor(uuid) {
        API.get(AppConstants.CONTROL_PANEL_SENSORS_URL + "/" + uuid + "/decline", {
            params: { uuid: this.props.uuid }
        }).then(result => {
            if (result.status === 200) {
                this.updateComponent();

            }
        });
    }

    render() {
        const {
            isSocAdmin,
            isCreateControlPanelSensorModalShow,
            isDeleteControlPanelSensorModalShow,
            selectedControlPanelSensorUUID,
        } = this.state;

        const {
            uuid
        } = this.props;

        const paginationOptions = {
            custom: true,
            totalSize: this.state.sensorsData.length,
            sizePerPage: 10
        };

        const SensorDetails = ({ details }) => {
            return (
                <Row>
                    <Col sm={12}>
                        <DetailsCard uuid={details.id} details={details.details} status={details.status} action={details.action} >
                            <Button onClick={() => this.toggleDeleteControlPanelSensorModal(details.id)}
                                disabled={details.action === Constants.ACTIONS.TO_DELETE} outline theme="danger" className="mb-2 mr-1">
                                Usuń urządzenie
                            </Button>
                            <Button onClick={() => this.toggleCreateControlPanelSensorModal(details.id)}
                                disabled={details.action === Constants.ACTIONS.TO_DELETE} outline theme="info" className="mb-2 mr-1">
                                Edytuj urządzenie
                            </Button>
                        </DetailsCard>
                    </Col>
                </Row>
            )
        }

        const SocAdminActions = ({ uuid, action }) => (
            <Row>
                <Col className="text-center">
                    <LinkButton onClick={() => this.acceptSensor(uuid)} style={{ color: "green", textDecoration: "underline", fontSize: "small" }}>Zaakceptuj</LinkButton>
                </Col>

                {action !== AppConstants.ACTIONS.TO_DELETE &&
                    <Col className="text-center">
                        <LinkButton onClick={() => this.declineSensor(uuid)} style={{ color: "red", textDecoration: "underline", fontSize: "small" }}>Odrzuć</LinkButton>
                    </Col>
                }
            </Row>
        )

        const DetailsCard = ({ uuid, details, status, action, children }) => {
            const sensorStatus = getActionParameters(status, action);
            const isPending = status === AppConstants.STATUSES.PENDING;

            return (
                <Card className="h-100 mb-2">
                    <CardBody className="d-flex flex-column p-0 mt-2">
                        <CardRow title="Status" content={[sensorStatus.content]} contentColor={sensorStatus.color}>
                            {isSocAdmin && isPending && <SocAdminActions uuid={uuid} action={action} />}
                        </CardRow>
                        <CardRows rows={details.rows} />
                        <div className="ml-auto mr-0">
                            {children}
                        </div>
                    </CardBody>
                </Card>
            )
        }

        const CardRows = ({ rows }) => {
            return rows.map((element, index) => {
                return <CardRow key={index} title={element.label} content={element.value} />
            })
        }

        const CardRow = ({ title, content, contentColor, withoutBottomDivider, children }) => {
            var isArrayWithSingleValue = Array.isArray(content) && content.length === 1;

            return (
                <div className="detail-row-container">
                    <div className="small-padding">
                        <div className="details-row-title">{title}</div>
                        {isArrayWithSingleValue ? <div className="details-row-content" style={{ color: contentColor }}>{content[0] ? content[0] : "-"}</div> : <ul>{renderArrayContent(content)}</ul>}
                        {children}
                    </div>
                    {!withoutBottomDivider && <div className="divider" />}
                </div>
            )
        }

        const renderArrayContent = (content) => {
            return content.map((contentItem, idx) => {
                return <li key={idx}>{contentItem}</li>
            })
        }

        const expandRow = {
            renderer: row => (
                <SensorDetails details={row} />
            ),
        };

        const renderDeviceList = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={this.state.sensorsData}
                                columns={this.props.deviceColumns}
                                classes="table mb-0"
                                bordered={false}
                                hover
                                expandRow={expandRow}
                                rowStyle={{ height: '10px' }}
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderEmptyDeviceListMessage = () => (
            <ListGroup flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Nie znaleziono żadnych urządzeń</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <React.Fragment>
                <Row className="mt-2 mb-2 position-relative">
                    {this.props.children}
                    <Col>
                        <Card className="mb-3">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Urządzenia</h6>
                                <div className="block-handle" />
                            </CardHeader>

                            <CardBody className="pt-2 pb-0 px-0">
                                <div className="mx-1">
                                    <Button onClick={() => this.toggleCreateControlPanelSensorModal()} className="mb-2" style={{ marginLeft: "16px" }} outline theme="info">
                                        Dodaj nowe urządzenie
                                    </Button>
                                </div>

                                {this.state.sensorsData.length ? renderDeviceList() : renderEmptyDeviceListMessage()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ConfirmControlPanelSensorModal open={isCreateControlPanelSensorModalShow} controlPanelUuid={uuid} uuid={selectedControlPanelSensorUUID}
                    toggle={this.toggleCreateControlPanelSensorModal} onSuccess={() => this.updateComponent()} />
                <DeleteControlPanelSensorModal open={isDeleteControlPanelSensorModalShow} controlPanelUuid={uuid} uuid={selectedControlPanelSensorUUID}
                    toggle={this.toggleDeleteControlPanelSensorModal} onSuccess={() => this.updateComponent()} />
            </React.Fragment>
        );
    }

}

export default withLoader(ControlPanelSensors);

ControlPanelSensors.propTypes = {
    deviceColumns: PropTypes.array
}

ControlPanelSensors.defaultProps = {
    deviceColumns: [
        {
            dataField: 'action',
            headerAlign: "center",
            align: "center",
            text: "Status",
            formatter: (cell, row) => {
                return <React.Fragment>{getIconByActionOrStatus(cell)} {getIconByWarningStatus(row.warning)}</React.Fragment>;
            }
        }, {
            dataField: 'name',
            headerAlign: "left",
            align: "left",
            text: "Nazwa",
        }, {
            dataField: 'group',
            headerAlign: "center",
            align: "center",
            text: "Grupa",
        }, {
            dataField: 'type',
            headerAlign: "right",
            align: "right",
            text: "Typ",
        }]
};