import React from "react";
import {
    Row,
    Col,
    FormInput,
    FormRadio,
    DatePicker,
} from "shards-react";

import API from "../../../api/AxiosConfiguration";
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { trackPromise } from 'react-promise-tracker';

import pl from 'date-fns/locale/pl';
import * as AppConstants from '../../../constants';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IP_REGEXP, MAC_REGEXP } from './../../../utils/Patterns';
import { Store } from '../../../flux'
import { getCustomersForSelect } from '../../../api/ApiService';
import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'


class ConfirmDeviceApplicationForm extends React.Component {

    constructor() {
        super();
        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            form: {
                name: '',
                ip: '',
                mac: '',
                user: '',
                group: '',
                location: '',
                endWarranty: undefined,
                manufacturer: '',
                model: '',
                serialNumber: '',
                support: '',
                type: '',
                manager: '',
                protocol: '',

                snmpv2Community: '',

                snmpv3Password: '',
                snmpv3Username: '',
                snmpv3PrivacyProtocol: '',
                snmpv3AuthenticationProtocol: '',
                snmpv3PrivacyPassword: '',
                snmpv3SecurityLevel: ''
            },
            formErrors: {
                name: [],
                ip: [],
                mac: [],
                group: [],
                type: [],
                manufacturer: [],
                model: [],
                manager: [],
                protocol: [],
                location: [],

                snmpv2Community: [],

                snmpv3Password: [],
                snmpv3Username: [],
                snmpv3PrivacyProtocol: [],
                snmpv3AuthenticationProtocol: [],
                snmpv3PrivacyPassword: [],
                snmpv3SecurityLevel: []
            },
            formHints: {
                group: [],
                location: [],
                type: [],
                manufacturer: [],
                model: [],
                support: [],
                manager: [],

                snmpv3SecurityLevel: [
                    { label: "noAuthNoPriv", value: "noAuthNoPriv", weight: 0 },
                    { label: "authNoPriv", value: "authNoPriv", weight: 1 },
                    { label: "authPriv", value: "authPriv", weight: 2 }
                ],
                snmpv3PrivacyProtocol: [
                    { label: "DES", value: "DES" },
                    { label: "AES", value: "AES" }
                ],
                snmpv3AuthenticationProtocol: [
                    { label: "MD5", value: "MD5" },
                    { label: "SHA", value: "SHA" }
                ],
            },
            selectedTemplate: "",
            formTemplates: [],
            formTemplateErrors: [],
            isTemplatesLoading: true,
            isManagersLoading: true,
            isOptionsLoading: false,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.dateChangeHandler = this.dateChangeHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.selectTemplateChangeHandler = this.selectTemplateChangeHandler.bind(this);
        this.validForm = this.validForm.bind(this);
        this.buildSnmpConfiguration = this.buildSnmpConfiguration.bind(this);
        this.buildForm = this.buildForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.deleteCustomerTemplate = this.deleteCustomerTemplate.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        const { isAdminAtLeast } = this.state;

        if (uuid.length) {
            this.fetchAvailableOptions(uuid, () => {
                this.fetchDeviceApplicationForm(uuid);
            });
        } else {
            if (isAdminAtLeast) {
                this.fetchAvailableOptions();
                this.fetchCompanyCustomers();
            } else {
                this.fetchAvailableOptions();
            }
        }

        this.fetchCustomerTemplates();
    }

    componentDidUpdate(prevProps, prevState) {
        var prevManager = prevState.form.manager ? prevState.form.manager.value : "";
        var currentManager = this.state.form.manager ? this.state.form.manager.value : "";

        if (prevManager !== currentManager) {
            this.clearFormHints();
            this.fetchAvailableOptions();
        }
    }

    clearFormHints() {
        this.setState({
            formHints: {
                ...this.state.formHints,
                group: [],
                location: [],
                type: [],
                manufacturer: [],
                model: [],
                support: [],
            }
        })
    }

    selectTemplateChangeHandler = (template, event) => {
        const { uuid } = this.props;

        if (event.action !== "clear" && event.action !== "create-option")
            if (uuid && uuid.length) {
                this.setState({
                    form: {
                        ...this.state.form,
                        endWarranty: template.value.endWarranty,
                        manufacturer: template.value.manufacturer,
                        model: template.value.model,
                        support: template.value.support,
                        type: template.value.type,
                    }
                });
            } else {
                this.setState({
                    form: template.value
                });
            }

        this.setState({
            selectedTemplate: (event.action !== "clear") ? template.label : "",
            formTemplateErrors: []
        });
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event, inputsToClearAfterChange) => {
        var { form, formErrors } = this.state;

        var clearedElements = {}
        if (Array.isArray(inputsToClearAfterChange)) {
            inputsToClearAfterChange.forEach(inputToClear => {
                clearedElements = {
                    ...clearedElements,
                    [inputToClear]: ""
                }
            })
        }

        this.setState({
            form: {
                ...form,
                ...clearedElements,
                [event.name]: item
            },
            formErrors: {
                ...formErrors,
                [event.name]: []
            }
        });
    }

    dateChangeHandler = (date, formName) => {
        var { form, formErrors } = this.state;
        form[formName] = date ? date.getTime() : undefined;
        formErrors[formName] = [];
        this.setState({ form: form });
    }

    radioChangeHandler = (name, value) => {
        var { form, formErrors } = this.state;

        form['snmpv2Community'] = '';
        form['snmpv3Username'] = '';
        form['snmpv3Password'] = '';
        form['snmpv3PrivacyProtocol'] = '';
        form['snmpv3AuthenticationProtocol'] = '';
        form['snmpv3PrivacyPassword'] = '';
        form['snmpv3SecurityLevel'] = '';

        form[name] = value;
        formErrors[name] = [];
        this.setState({ form: form });
    }

    validTemplate() {
        const { selectedTemplate, formTemplateErrors } = this.state;

        formTemplateErrors.length = 0;

        var errorCount = 0;
        if (selectedTemplate.length <= 0) {
            formTemplateErrors.push("Nazwa szablonu nie może być pusta");
            errorCount++;
        }

        this.setState({ formTemplateErrors: errorCount ? formTemplateErrors : [] });
        return !errorCount;
    }

    validForm() {
        const { uuid } = this.props;
        const { formErrors, isAdminAtLeast, isAdmin } = this.state;
        const {
            name,
            ip,
            mac,
            manufacturer,
            model,
            type,
            group,
            manager,
            protocol,
            snmpv2Community,
            snmpv3Username,
            snmpv3Password,
            snmpv3PrivacyProtocol,
            snmpv3AuthenticationProtocol,
            snmpv3PrivacyPassword,
            snmpv3SecurityLevel,
            location
        } = this.state.form;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        formErrors["name"] = [];
        formErrors["group"] = [];
        formErrors["ip"] = [];
        formErrors["mac"] = [];
        formErrors["manager"] = [];
        formErrors["type"] = [];
        formErrors["manufacturer"] = [];
        formErrors["model"] = [];
        formErrors["location"] = [];

        formErrors["snmpv2Community"] = [];

        formErrors["snmpv3Username"] = [];
        formErrors["snmpv3Password"] = [];
        formErrors["snmpv3PrivacyProtocol"] = [];
        formErrors["snmpv3AuthenticationProtocol"] = [];
        formErrors["snmpv3PrivacyPassword"] = [];
        formErrors["snmpv3SecurityLevel"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if(hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }
        
        if(hasValueUrlOrHtmlTag(location)) {
            formErrors["location"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (group == null) {
            formErrors["group"].push("Nie wybrano grupy");
            errorCount++;
        }

        if (type.length <= 0) {
            formErrors["type"].push("Nie wybrano typu urządzenia");
            errorCount++;
        }

        if (manufacturer.length <= 0) {
            formErrors["manufacturer"].push("Nie wybrano producenta urządzenia");
            errorCount++;
        }

        if (model.length <= 0) {
            formErrors["model"].push("Nie wybrano modelu urządzenia");
            errorCount++;
        }

        if (!IP_REGEXP.test(ip)) {
            formErrors["ip"].push("Adres IP jest niepoprawny");
            errorCount++;
        }

        if (!MAC_REGEXP.test(mac)) {
            formErrors["mac"].push("Adres MAC jest niepoprawny");
            errorCount++;
        }

        if (!isEditingModeEnabled) {
            if (!isAdmin && isAdminAtLeast && (!manager || manager.length <= 0)) {
                formErrors["manager"].push("Użytkownik musi zostać wybrany");
                errorCount++;
            }
        }

        switch (protocol) {
            case AppConstants.NETWORK_DEVICE_SUPPORTED_PROTOCOLS.SNMP_V2.name:
                if (!isEditingModeEnabled && snmpv2Community.length <= 0) {
                    formErrors["snmpCommunity"].push("Community nie może być puste");
                    errorCount++;
                }
                break;
            case AppConstants.NETWORK_DEVICE_SUPPORTED_PROTOCOLS.SNMP_V3.name:
                if (!snmpv3SecurityLevel.value) {
                    formErrors["snmpv3SecurityLevel"].push("Poziom bezpieczeństwa nie został wybrany");
                    errorCount++;
                } else {
                    if (!snmpv3Username || snmpv3Username.length <= 0) {
                        formErrors["snmpv3Username"].push("Nazwa użytkownika SNMP nie może być pusta");
                        errorCount++;
                    }

                    if (snmpv3SecurityLevel.weight >= 1) {
                        if (!snmpv3AuthenticationProtocol || !snmpv3AuthenticationProtocol.value || snmpv3AuthenticationProtocol.value.length <= 0) {
                            formErrors["snmpv3AuthenticationProtocol"].push("Mechanizm uwierzytelniania musi zostać wybrany");
                            errorCount++;
                        }

                        if (!isEditingModeEnabled && (!snmpv3Password || snmpv3Password.length <= 0)) {
                            formErrors["snmpv3Password"].push("Hasło SNMP nie może być puste");
                            errorCount++;
                        }
                    }

                    if (snmpv3SecurityLevel.weight >= 2) {
                        if (!snmpv3PrivacyProtocol || !snmpv3PrivacyProtocol.value || snmpv3PrivacyProtocol.value.length <= 0) {
                            formErrors["snmpv3PrivacyProtocol"].push("Algorytm szyfrowania musi zostać wybrany");
                            errorCount++;
                        }

                        if (!isEditingModeEnabled && (!snmpv3PrivacyPassword || snmpv3PrivacyPassword.length <= 0)) {
                            formErrors["snmpv3PrivacyPassword"].push("Hasło do szyfrowanej komunikacji nie może być puste");
                            errorCount++;
                        }
                    }
                }

                break;
            default:
                formErrors["protocol"].push("Protokół nie został wybrany");
                errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildSnmpConfiguration(form) {
        var snmpConfiguration = {}
        if (form.protocol === AppConstants.NETWORK_DEVICE_SUPPORTED_PROTOCOLS.SNMP_V2.name) {
            snmpConfiguration = { snmpv2Community: form.snmpv2Community }
        } else if (form.protocol === AppConstants.NETWORK_DEVICE_SUPPORTED_PROTOCOLS.SNMP_V3.name) {
            const securityLevel = form.snmpv3SecurityLevel.weight;
            snmpConfiguration = {
                snmpv3SecurityLevel: form.snmpv3SecurityLevel,
                snmpv3Username: form.snmpv3Username
            }

            if (securityLevel >= 1) {
                snmpConfiguration = {
                    ...snmpConfiguration,
                    snmpv3AuthenticationProtocol: form.snmpv3AuthenticationProtocol,
                    snmpv3Password: form.snmpv3Password
                }
            }

            if (securityLevel >= 2) {
                snmpConfiguration = {
                    ...snmpConfiguration,
                    snmpv3PrivacyProtocol: form.snmpv3PrivacyProtocol,
                    snmpv3PrivacyPassword: form.snmpv3PrivacyPassword
                }
            }
        }

        return snmpConfiguration;
    }

    buildForm() {
        const { form } = this.state;

        form['snmpv3SecurityLevel'] = form.snmpv3SecurityLevel ? form.snmpv3SecurityLevel.value : null;
        form['snmpv3AuthenticationProtocol'] = form.snmpv3AuthenticationProtocol ? form.snmpv3AuthenticationProtocol.value : null;
        form['snmpv3PrivacyProtocol'] = form.snmpv3PrivacyProtocol ? form.snmpv3PrivacyProtocol.value : null;

        return {
            ...form,
            type: form.type && form.type.value,
            manufacturer: form.manufacturer && form.manufacturer.value,
            model: form.model && form.model.value,
            group: form.group && form.group.value,
            location: form.location && form.location.value,
            support: form.support && form.support.value,
            serialNumber: form.serialNumber,
            ...this.buildSnmpConfiguration(form)
        }
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;
        this.setState({
            formTemplateErrors: []
        })

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.put(AppConstants.NETWORK_DEVICE_URL + "/" + uuid, {
                    action: AppConstants.ACTIONS.TO_EDIT,
                    form: this.buildForm()
                })
            } else {
                apiPromise = API.put(AppConstants.NETWORK_DEVICE_URL + "/create", {
                    action: AppConstants.ACTIONS.TO_ADD,
                    form: {
                        ...this.buildForm(),
                        manager: form.manager && form.manager.value,
                    }
                });
            }

            trackPromise(
                apiPromise.then((result) => {
                    if (result.status === 201) {
                        this.props.toggle();
                        onSuccess();
                    }
                }).catch((error) => {
                    var response = error.response;
                    if (response && response.status === 400) {
                        response.data.errors.forEach(error => {
                            formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                        })
                        this.setState({ formErrors: formErrors });
                    }
                })
            )
        }
    }

    submitTemplate = () => {
        const { selectedTemplate, form, formTemplateErrors } = this.state;
        this.setState({
            formErrors: {
                name: [],
                ip: [],
                mac: [],
                group: [],
                type: [],
                manufacturer: [],
                model: [],
                manager: [],
                location: [],
                protocol: [],
                snmpv2Community: [],
                snmpv3Password: [],
                snmpv3Username: [],
                snmpv3PrivacyProtocol: [],
                snmpv3AuthenticationProtocol: [],
                snmpv3PrivacyPassword: [],
                snmpv3SecurityLevel: []
            }
        })

        if (this.validTemplate()) {
            API.put(AppConstants.NETWORK_DEVICE_TEMPLATE_URL, {
                name: selectedTemplate,
                action: AppConstants.ACTIONS.TO_ADD,
                template: form
            }).then((result) => {
                this.setState({ isTemplatesLoading: true });
                this.fetchCustomerTemplates();
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    formTemplateErrors.length = 0;
                    response.data.errors.forEach(error => {
                        formTemplateErrors.push(error.defaultMessage);
                    })
                    this.setState({ formTemplateErrors: formTemplateErrors });
                }
            });
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchDeviceApplicationForm = (uuid) => {
        API.get(AppConstants.NETWORK_DEVICE_URL + "/form/" + uuid).then((result) => {
            if (result.status === 200) {
                var responseBody = result.data;

                if (responseBody.endWarranty) {
                    responseBody.endWarranty = new Date(responseBody.endWarranty).getTime();
                }

                this.setState({
                    form: {
                        ...result.data,
                        group: this.findHintByValue("group", result.data.group),
                        type: this.findHintByValue("type", result.data.type),
                        manufacturer: this.findHintByValue("manufacturer", result.data.manufacturer),
                        model: this.findHintByValue("model", result.data.model),
                        location: this.findHintByValue("location", result.data.location),
                        support: this.findHintByValue("support", result.data.support),
                        snmpv3SecurityLevel: this.findHintByValue("snmpv3SecurityLevel", result.data.snmpv3SecurityLevel),
                        snmpv3AuthenticationProtocol: this.findHintByValue("snmpv3AuthenticationProtocol", result.data.snmpv3AuthenticationProtocol),
                        snmpv3PrivacyProtocol: this.findHintByValue("snmpv3PrivacyProtocol", result.data.snmpv3PrivacyProtocol)
                    }
                })
            }
        });
    }

    fetchCompanyCustomers = () => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            })
        })
    }

    fetchCustomerTemplates = () => {
        API.get(AppConstants.NETWORK_DEVICE_TEMPLATE_URL).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formTemplates: result.data,
                    isTemplatesLoading: false
                })
            }
        });
    }

    fetchAvailableOptions = (uuid = "", callback = () => { }) => {
        const { isAdminAtLeast } = this.state;
        const { manager } = this.state.form;

        var axiosConfig;
        if (isAdminAtLeast) {
            if (Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
                if (!((manager && manager.value) || uuid)) return;
            }

            axiosConfig = {
                params: {
                    uuid: uuid,
                    manager: manager ? manager.value : ""
                }
            }
        }

        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.NETWORK_DEVICE_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                    },
                    isOptionsLoading: false,
                }, () => { callback(); })
            }
        });
    }

    deleteCustomerTemplate = (uuid) => {
        API.delete(AppConstants.NETWORK_DEVICE_TEMPLATE_URL + `/${uuid}`).then((result) => {
            if (result.status === 200) {
                var templates = [...this.state.formTemplates];
                var index = templates.findIndex(template => template.uuid === uuid)
                if (index !== -1) {
                    templates.splice(index, 1);
                    this.setState({
                        selectedTemplate: "",
                        formTemplates: templates,
                        isTemplatesLoading: false
                    });
                }
            }
        });
    }

    render() {
        const { isAdminAtLeast, isAdmin } = this.state;
        const { uuid, isMobileView } = this.props;
        const { name,
            ip,
            mac,
            user,
            group,
            location,
            endWarranty,
            type,
            manufacturer,
            model,
            serialNumber,
            support,
            manager,
            protocol,
            snmpv2Community,
            snmpv3AuthenticationProtocol,
            snmpv3Password,
            snmpv3PrivacyPassword,
            snmpv3PrivacyProtocol,
            snmpv3SecurityLevel,
            snmpv3Username
        } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasTemplateNameError = Boolean(this.state.formTemplateErrors.length);
        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasIpError = Boolean(this.state.formErrors.ip.length);
        var hasMacError = Boolean(this.state.formErrors.mac.length);
        var hasGroupError = Boolean(this.state.formErrors.group.length);
        var hasLocationError = Boolean(this.state.formErrors.location.length);
        var hastypeError = Boolean(this.state.formErrors.type.length);
        var hasManufacturerError = Boolean(this.state.formErrors.manufacturer.length);
        var hasModelError = Boolean(this.state.formErrors.model.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);
        var hasProtocolError = Boolean(this.state.formErrors.protocol.length);
        var hasSnmpv2CommunityError = Boolean(this.state.formErrors.snmpv2Community.length);

        var hasSnmpv3AuthenticationProtocolError = Boolean(this.state.formErrors.snmpv3AuthenticationProtocol.length);
        var hasSnmpv3PasswordError = Boolean(this.state.formErrors.snmpv3Password.length);
        var hasSnmpv3PrivacyPasswordError = Boolean(this.state.formErrors.snmpv3PrivacyPassword.length);
        var hasSnmpv3PrivacyProtocolError = Boolean(this.state.formErrors.snmpv3PrivacyProtocol.length);
        var hasSnmpv3SecurityLevelError = Boolean(this.state.formErrors.snmpv3SecurityLevel.length);
        var hasSnmpv3UsernameError = Boolean(this.state.formErrors.snmpv3Username.length);


        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const TemplateOptionWithTrashComponent = props => {
            const { innerProps, innerRef, children } = props;

            const deleteItem = (event, itemName) => {
                event.stopPropagation();
                this.setState({
                    isTemplatesLoading: true
                })
                this.deleteCustomerTemplate(props.data.uuid);
                props.clearValue();
            }

            const optionStyles = {
                backgroundColor: Boolean(innerRef) ? "#DEEBFF" : "transparent",
                display: "flex",
                justifyContent: "space-between"
            }

            return (
                <div ref={innerRef} {...innerProps} className="react-select-option" style={optionStyles} >
                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>{children}</div>
                    <div className="react-select-option-trash-container">
                        {!children.startsWith("Utwórz") && <div onClick={event => deleteItem(event, children)}>
                            <FontAwesomeIcon icon={faTrash} className="react-select-option-trash-icon" />
                        </div>}
                    </div>
                </div>
            );
        };

        const filterTemplates = (inputValue) => {
            return this.state.formTemplates.filter(template =>
                template.name.toLowerCase().includes(inputValue.toLowerCase())
            ).map((template) => {
                return {
                    value: template.template,
                    label: template.name,
                    uuid: template.uuid
                }
            });
        }

        const filterTemplatesPromise = inputValue =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterTemplates(inputValue));
                }, 500);
            });

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const checkValueCompatibilityWithParent = (hint, selectedOption) => {
            try {
                return hint.parentValue === selectedOption.value
            } catch (error) {
                return false
            }
        }

        const NetworkDeviceProtocolSelector = () => {
            const protocolRadios = Object.entries(AppConstants.NETWORK_DEVICE_SUPPORTED_PROTOCOLS).map(([key, value]) => {
                const formName = "protocol";
                return (
                    <FormRadio
                        key={value.name}
                        name={formName}
                        checked={protocol === value.name}
                        onChange={() => { this.radioChangeHandler(formName, value.name); }}
                        invalid={hasProtocolError}
                        inline>
                        {value.displayName}
                    </FormRadio>
                )
            });

            return (
                <div className="text-center">
                    {protocolRadios}
                </div>
            )
        }

        const defaultSelectProps = {
            placeholder: "Wybierz lub utwórz nowy",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            isClearable: true
        }

        return (
            <React.Fragment>
                <Row>
                    <Col sm="12">
                        <label>Szablon</label>
                        <AsyncCreatableSelect
                            {...defaultSelectProps}
                            defaultOptions={this.state.formTemplates.map(item => { return { value: item.template, label: item.name, uuid: item.uuid } })}
                            onChange={this.selectTemplateChangeHandler}
                            className={hasTemplateNameError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                            isLoading={this.state.isTemplatesLoading}
                            isValidNewOption={(inputValue) => {
                                const processedInputValue = inputValue ||  ""
                                
                                return processedInputValue.length >= 3 && processedInputValue.length <= 50 && !hasValueUrlOrHtmlTag(processedInputValue)
                            }}
                            loadOptions={filterTemplatesPromise}
                            components={{ Option: TemplateOptionWithTrashComponent }} />
                        {hasTemplateNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formTemplateErrors)}</ul>}
                    </Col>

                    <Col sm="12" className="p-0">
                        <h6 className="m-0">Konfiguracja urządzenia</h6>
                        <div className="divider"></div>
                    </Col>

                    <Col sm="12" md="6">
                        <label>*Nazwa urządzenia</label>
                        <FormInput
                            name="name"
                            value={name || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasNameError}
                            disabled={isEditingModeEnabled}
                            className={hasNameError ? "mb-0" : "mb-2"} />
                        {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                        {!isEditingModeEnabled && isAdminAtLeast &&
                            <>
                                <label>*Menedżer</label>
                                <AsyncSelect
                                    {...defaultSelectProps}
                                    name="manager"
                                    value={manager}
                                    onChange={this.selectChangeHandler}
                                    defaultOptions={this.state.formHints.manager}
                                    className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                    loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")}
                                    isLoading={this.state.isManagersLoading}
                                    placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"} />
                                {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                            </>
                        }

                        <label>*Adres IP</label>
                        <FormInput
                            name="ip"
                            value={ip || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasIpError}
                            className={hasIpError ? "mb-0" : "mb-2"} />
                        {hasIpError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.ip)}</ul>}

                        <label>*Adres MAC</label>
                        <FormInput
                            name="mac"
                            value={mac || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasMacError}
                            disabled={isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected}
                            className={hasMacError ? "mb-0" : "mb-2"} />
                        {hasMacError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.mac)}</ul>}

                        <label>Użytkownik</label>
                        <FormInput
                            name="user"
                            value={user || ''}
                            onChange={this.inputChangeHandler}
                            disabled={isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected}
                            className="mb-2" />

                        <label>*Grupa</label>
                        <AsyncSelect
                            {...defaultSelectProps}
                            name="group"
                            value={group}
                            onChange={this.selectChangeHandler}
                            defaultOptions={this.state.formHints.group}
                            className={hasGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                            isDisabled={isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected}
                            loadOptions={(inputValue) => filterOptionsPromise(inputValue, "group")}
                            isLoading={this.state.isOptionsLoading}
                            placeholder="Wybierz" />
                        {hasGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.group)}</ul>}

                        <label>Lokalizacja urządzenia</label>
                        <AsyncCreatableSelect
                            {...defaultSelectProps}
                            name="location"
                            value={location}
                            isValidNewOption={(inputValue) => {
                                const processedInputValue = inputValue ||  ""
                                
                                return processedInputValue.length >= 3 && processedInputValue.length <= 50 && !hasValueUrlOrHtmlTag(processedInputValue)
                            }}
                            onChange={this.selectChangeHandler}
                            defaultOptions={this.state.formHints.location}
                            loadOptions={(inputValue) => filterOptionsPromise(inputValue, "location")}
                            isLoading={this.state.isOptionsLoading} />
                        {hasLocationError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.location)}</ul>}

                    </Col>

                    <Col sm="12" md="6">
                        <label>Data zakończenia gwarancji urządzenia</label>
                        <DatePicker
                            selected={endWarranty}
                            startDate={endWarranty}
                            onChange={date => this.dateChangeHandler(date, "endWarranty")}
                            locale={pl}
                            dateFormat="dd-MM-yyyy"
                            withPortal={isMobileView}
                            className="mb-2"
                            disabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected} />

                        <label>*Typ urządzenia</label>
                        <AsyncSelect
                            placeholder="Wybierz"
                            name="type"
                            value={type}
                            onChange={(item, event) => { this.selectChangeHandler(item, event, ["manufacturer", "model"]); }}
                            defaultOptions={this.state.formHints.type}
                            loadOptions={(inputValue) => filterOptionsPromise(inputValue, "type")}
                            isLoading={this.state.isOptionsLoading}
                            className={`react-select-container ${hastypeError ? "mb-0 has-error" : "mb-2"}`}
                            classNamePrefix="react-select"
                            menuPosition="fixed"
                            noOptionsMessage={() => "Brak dostępnych opcji"}
                            loadingMessage={() => "Ładowanie"}
                            invalid={hastypeError}
                            isDisabled={isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected} />
                        {hastypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.type)}</ul>}

                        <label>*Producent</label>
                        <AsyncSelect
                            {...defaultSelectProps}
                            placeholder="Wybierz"
                            name="manufacturer"
                            value={manufacturer}
                            onChange={(item, event) => { this.selectChangeHandler(item, event, ["model"]); }}
                            defaultOptions={this.state.formHints.manufacturer.filter(hint =>
                                checkValueCompatibilityWithParent(hint, type)
                            )}
                            isDisabled={!type || isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected}
                            loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manufacturer")}
                            invalid={hasManufacturerError}
                            className={`react-select-container ${hasManufacturerError ? "mb-0 has-error" : "mb-2"}`}
                            isLoading={this.state.isOptionsLoading} />
                        {hasManufacturerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manufacturer)}</ul>}

                        <label>*Model</label>
                        <AsyncSelect
                            {...defaultSelectProps}
                            placeholder="Wybierz"
                            name="model"
                            value={model}
                            onChange={this.selectChangeHandler}
                            defaultOptions={this.state.formHints.model.filter(hint =>
                                checkValueCompatibilityWithParent(hint, manufacturer)
                            )}
                            isDisabled={!manufacturer || isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected}
                            loadOptions={(inputValue) => filterOptionsPromise(inputValue, "model")}
                            invalid={hasModelError}
                            className={`react-select-container ${hasModelError ? "mb-0 has-error" : "mb-2"}`}
                            isLoading={this.state.isOptionsLoading} />
                        {hasModelError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.model)}</ul>}

                        <label>Numer seryjny</label>
                        <FormInput
                            name="serialNumber"
                            value={serialNumber || ''}
                            onChange={this.inputChangeHandler}
                            className="mb-2"
                            disabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected} />

                        <label>Kontakt do supportu</label>
                        <AsyncCreatableSelect
                            {...defaultSelectProps}
                            name="support"
                            value={support}
                            onChange={this.selectChangeHandler}
                            defaultOptions={this.state.formHints.support}
                            isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                            loadOptions={(inputValue) => filterOptionsPromise(inputValue, "support")}
                            isLoading={this.state.isOptionsLoading} />

                        <label>*Protokół</label>
                        <NetworkDeviceProtocolSelector />
                        {hasProtocolError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.protocol)}</ul>}
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="p-0">
                        <h6 className="m-0">Konfiguracja protokołu {protocol && AppConstants.NETWORK_DEVICE_SUPPORTED_PROTOCOLS[protocol].displayName}</h6>
                        <div className="divider"></div>
                    </Col>

                    {!protocol &&
                        <Col className="text-center">
                            Do przekrowadzenia konfiguracji protokołu, należy go wybrać w sekcji Konfiguracja urządzenia.
                        </Col>}

                    {protocol === AppConstants.NETWORK_DEVICE_SUPPORTED_PROTOCOLS.SNMP_V2.name &&
                        <React.Fragment>
                            <Col sm="12" md="6">
                                <label>*SNMP Community</label>
                                <FormInput
                                    name="snmpv2Community"
                                    value={snmpv2Community || ''}
                                    onChange={this.inputChangeHandler}
                                    type="password"
                                    invalid={hasSnmpv2CommunityError}
                                    className={hasSnmpv2CommunityError ? "mb-0" : "mb-2"} />
                                {hasSnmpv2CommunityError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.snmpv2Community)}</ul>}
                            </Col>
                        </React.Fragment>
                    }

                    {protocol === AppConstants.NETWORK_DEVICE_SUPPORTED_PROTOCOLS.SNMP_V3.name &&
                        <React.Fragment>
                            <Col sm="12" md="6">
                                <label>*Poziom bezpieczeństwa</label>
                                <Select
                                    {...defaultSelectProps}
                                    placeholder="Wybierz"
                                    name="snmpv3SecurityLevel"
                                    value={snmpv3SecurityLevel}
                                    onChange={this.selectChangeHandler}
                                    options={this.state.formHints.snmpv3SecurityLevel}
                                    invalid={hasModelError}
                                    className={`react-select-container ${hasSnmpv3SecurityLevelError ? "mb-0 has-error" : "mb-2"}`} />
                                {hasSnmpv3SecurityLevelError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.snmpv3SecurityLevel)}</ul>}

                                <label>*Algorytm szyfrowania</label>
                                <Select
                                    {...defaultSelectProps}
                                    placeholder="Wybierz"
                                    name="snmpv3PrivacyProtocol"
                                    value={snmpv3PrivacyProtocol}
                                    onChange={this.selectChangeHandler}
                                    options={this.state.formHints.snmpv3PrivacyProtocol}
                                    invalid={hasModelError}
                                    className={`react-select-container ${hasSnmpv3PrivacyProtocolError ? "mb-0 has-error" : "mb-2"}`}
                                    isDisabled={
                                        !snmpv3SecurityLevel ||
                                        !snmpv3SecurityLevel.value ||
                                        snmpv3SecurityLevel.value === 'noAuthNoPriv' ||
                                        snmpv3SecurityLevel.value === 'authNoPriv'
                                    } />
                                {hasSnmpv3PrivacyProtocolError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.snmpv3PrivacyProtocol)}</ul>}

                                <label>*Hasło do szyfrowanej komunikacji</label>
                                <FormInput
                                    name="snmpv3PrivacyPassword"
                                    value={snmpv3PrivacyPassword || ""}
                                    onChange={this.inputChangeHandler}
                                    type="password"
                                    invalid={hasSnmpv3PrivacyPasswordError}
                                    className={hasSnmpv3PrivacyPasswordError ? "mb-0" : "mb-2"}
                                    autoComplete="something-unsupported"
                                    disabled={
                                        !snmpv3SecurityLevel ||
                                        !snmpv3SecurityLevel.value ||
                                        snmpv3SecurityLevel.value === 'noAuthNoPriv' ||
                                        snmpv3SecurityLevel.value === 'authNoPriv'
                                    } />
                                {hasSnmpv3PrivacyPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.snmpv3PrivacyPassword)}</ul>}
                            </Col>

                            <Col sm="12" md="6">
                                <label>*Mechanizm uwierzytelniania</label>
                                <Select
                                    {...defaultSelectProps}
                                    placeholder="Wybierz"
                                    name="snmpv3AuthenticationProtocol"
                                    value={snmpv3AuthenticationProtocol}
                                    onChange={this.selectChangeHandler}
                                    options={this.state.formHints.snmpv3AuthenticationProtocol}
                                    invalid={hasModelError}
                                    className={`react-select-container ${hasSnmpv3AuthenticationProtocolError ? "mb-0 has-error" : "mb-2"}`}
                                    isDisabled={
                                        !snmpv3SecurityLevel ||
                                        !snmpv3SecurityLevel.value ||
                                        snmpv3SecurityLevel.value === 'noAuthNoPriv'
                                    } />
                                {hasSnmpv3AuthenticationProtocolError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.snmpv3AuthenticationProtocol)}</ul>}

                                <label>*Nazwa użytkownika SNMP</label>
                                <FormInput
                                    name="snmpv3Username"
                                    value={snmpv3Username || ""}
                                    onChange={this.inputChangeHandler}
                                    invalid={hasSnmpv3UsernameError}
                                    className={hasSnmpv3UsernameError ? "mb-0" : "mb-2"}
                                    disabled={
                                        !snmpv3SecurityLevel ||
                                        !snmpv3SecurityLevel.value
                                    } />
                                {hasSnmpv3UsernameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.snmpv3Username)}</ul>}

                                <label>*Hasło SNMP</label>
                                <FormInput
                                    name="snmpv3Password"
                                    value={snmpv3Password || ""}
                                    onChange={this.inputChangeHandler}
                                    type="password"
                                    invalid={hasSnmpv3PasswordError}
                                    className={hasSnmpv3PasswordError ? "mb-0" : "mb-2"}
                                    disabled={
                                        !snmpv3SecurityLevel ||
                                        !snmpv3SecurityLevel.value ||
                                        snmpv3SecurityLevel.value === 'noAuthNoPriv'
                                    } />

                                {hasSnmpv3PasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.snmpv3Password)}</ul>}
                            </Col>
                        </React.Fragment>
                    }
                </Row>
            </React.Fragment >
        );
    }
}

export default ConfirmDeviceApplicationForm;