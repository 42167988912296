import React from "react";
import { FormInput, Button, FormCheckbox } from "shards-react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { SECURE_PASSWORD_REGEXP } from "../../../utils/Patterns";
import Regulations from '../../../images/REGULAMIN__v.1.2.pdf';

class CustomerProfileActivationForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isPhoneNumberValidationKeyInputVisible: false,
            form: {
                email: '',
                name: '',
                surname: '',
                password: '',
                repeatPassword: '',
                regulationsAccepted: false,
            },
            formErrors: {
                name: [],
                surname: [],
                password: [],
                repeatPassword: [],
                regulationsAccepted: [],
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var customerDetails = props.customerDetails;

        var form = {};
        if (customerDetails.email !== state.form.email) {
            form = {
                ...state.form,
                email: customerDetails.email,
            }
        }

        return {
            form: {
                ...state.form,
                ...form
            },
        };
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    checkboxChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = !form[event.target.name];
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, surname, password, repeatPassword, regulationsAccepted } = this.state.form;

        formErrors["name"] = [];
        formErrors["surname"] = [];
        formErrors["password"] = [];
        formErrors["repeatPassword"] = [];
        formErrors["regulationsAccepted"] = [];

        var errorCount = 0;

        if (name.length <= 0) {
            formErrors["name"].push("Imię nie może być puste");
            errorCount++;
        }

        if (surname.length <= 0) {
            formErrors["surname"].push("Nazwisko nie może być puste");
            errorCount++;
        }

        if (!SECURE_PASSWORD_REGEXP.test(password)) {
            formErrors["password"].push("Hasło musi zawierać conajmniej: jedną małą literę, " +
                "jedną dużą literę, jedną cyfrę, jeden znak specjalny, 8 znaków.");
            errorCount++;
        }

        if (password !== repeatPassword) {
            formErrors["repeatPassword"].push("Podane hasła muszą być identyczne");
            errorCount++;
        }

        if (!regulationsAccepted) {
            formErrors["regulationsAccepted"].push("Regulamin nie został zaakceptowany");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm() {
        const { form, formErrors } = this.state;
        const { uuid } = this.props

        if (this.validForm()) {
            API.post(Constants.CUSTOMERS_URL + "/register", {
                form: {
                    uuid: uuid,
                    name: form.name,
                    surname: form.surname,
                    password: form.password,
                    repeatPassword: form.repeatPassword,
                    regulationsAccepted: form.regulationsAccepted
                }
            }).then((result) => {
                if (result.status === 201) {
                    this.props.onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors && response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    render() {
        const { email, name, surname, password, repeatPassword, regulationsAccepted } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const hasNameError = Boolean(this.state.formErrors.name.length);
        const hasSurnameError = Boolean(this.state.formErrors.surname.length);
        const hasPasswordError = Boolean(this.state.formErrors.password.length);
        const hasRepeatPasswordError = Boolean(this.state.formErrors.repeatPassword.length);
        const hasRegulationsCheckoxError = Boolean(this.state.formErrors.regulationsAccepted.length);

        return (
            <div>
                <label>*Email</label>
                <FormInput
                    name="email"
                    value={email || ''}
                    onChange={this.inputChangeHandler}
                    disabled={true}
                    className="mb-2" />

                <label>*Imię</label>
                <FormInput
                    name="name"
                    value={name || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasNameError}
                    className={hasNameError ? "mb-0" : "mb-2"} />
                {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}


                <label>*Nazwisko</label>
                <FormInput
                    name="surname"
                    value={surname || ''}
                    onChange={this.inputChangeHandler}
                    invalid={hasSurnameError}
                    className={hasSurnameError ? "mb-0" : "mb-2"} />
                {hasSurnameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.surname)}</ul>}

                <label>*Hasło</label>
                <FormInput
                    name="password"
                    value={password || ''}
                    onChange={this.inputChangeHandler}
                    type="password"
                    invalid={hasPasswordError}
                    className={hasPasswordError ? "mb-0" : "mb-2"} />
                {hasPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.password)}</ul>}

                <label>*Powtórz hasło</label>
                <FormInput
                    name="repeatPassword"
                    value={repeatPassword || ''}
                    onChange={this.inputChangeHandler}
                    type="password"
                    invalid={hasRepeatPasswordError}
                    className={hasRepeatPasswordError ? "mb-0" : "mb-2"} />
                {hasRepeatPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.repeatPassword)}</ul>}

                <FormCheckbox
                    name="regulationsAccepted"
                    checked={regulationsAccepted}
                    onChange={this.checkboxChangeHandler}
                    invalid={hasRegulationsCheckoxError}
                    className={hasRegulationsCheckoxError ? "mb-0" : "mb-2"} >
                    Akceptuje <a href={Regulations} target = "_blank">regulamin</a>
                </FormCheckbox>

                <Button
                    onClick={this.submitForm}
                    outline
                    theme="info"
                    className="mt-2 ml-1 float-right">
                    Aktualizuj
                </Button>

            </div>
        );
    }
}

export default CustomerProfileActivationForm;