import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import CustomerArchivePassword from "../components/edit-profile/CustomerArchivePassword";
import CustomerPasswordRevoveryMethod from './../components/edit-profile/CustomerPasswordRevoveryMethod';
import CustomerWebPushActivity from './../components/edit-profile/CustomerWebPushActivity';
import CustomerFacilitiesForDisabled from './../components/edit-profile/CustomerFacilitiesForDisabled';
import DeleteAccount from "../components/edit-profile/DeleteAccount";

import * as AppConstants from "../constants";
import { Store } from "../flux";

const EditProfile = () => {
  const [isSocAdmin] = useState(Store.getUserRole() === AppConstants.Roles.SOCADMIN);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Edycja profilu" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      <Row>
        <Col lg="6">
          <CustomerArchivePassword />
        </Col>
        <Col lg="6">
          <CustomerPasswordRevoveryMethod />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <CustomerWebPushActivity />
        </Col>
        <Col lg="6">
          <CustomerFacilitiesForDisabled />
        </Col>
      </Row>
      {!isSocAdmin &&
        <Row>
          <Col lg="6">
            <DeleteAccount />
          </Col>
        </Row>
      }
    </Container>
  )
};

export default EditProfile;