import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";
import * as AppConstants from "../constants";
import { getAllCompanies, getAllDashboards } from "../api/ApiService";

let _store = {
  isMobileView: false,
  menuVisible: true,
  navItems: getSidebarNavItems(),
  newMessagesCountRefreshKey: 0,
  companies: [],
  selectedCompany: '',
  selectedManager: '',
  userRole: AppConstants.Roles.USER,
  dashboardModalVisible: false,
  dashboardFormType: '',
  selectedDashboard: '',
  newDashboardName: '',
  selectedDashboardToDelete: '',
  dashboards: [],
  selectedTile: '',
  tileToForceUpdate: ''
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.SET_MOBILE_VIEW:
        this.setMobileView(payload);
        break;
      case Constants.REFRESH_NEW_MESSAGES_COUNT:
        this.refreshNewMessagesCount(payload);
        break;
      case Constants.SET_SELECTED_COMPANY:
        this.setSelectedCompany(payload);
        break;
      case Constants.SET_SELECTED_MANAGER:
        this.setSelectedManager(payload);
        break;
      case Constants.SET_SELECTED_DASHBOARD:
        this.setSelectedDashboard(payload);
        break;
      case Constants.SET_DASHBOARD_TO_DELETE:
        this.setDashboardToDelete(payload);
        break;
      case Constants.UPDATE_COMPANY_LIST:
        this.updateCompanyList();
        break;
      case Constants.UPDATE_DASHBOARD_LIST:
        this.updateDashboardList(payload);
        break;
      case Constants.SET_USER_ROLE:
        this.setUserRole(payload.role);
        break;
      case Constants.TOGGLE_DASHBOARD_MODAL:
        this.toggleDashboardModal(payload);
        break;
      case Constants.SET_NEW_DASHBOARD_NAME:
        this.setNewDashboardName(payload);
        break;
      case Constants.TILE_FORCE_UPDATE:
        this.setTileForForceUpdate(payload);
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.TOGGLE_SIDEBAR);
  }

  toggleDashboardModal(payload) {
    _store.dashboardModalVisible = !_store.dashboardModalVisible;
    _store.dashboardFormType = payload.formType;
    _store.selectedTile = payload.tile;
    this.emit(Constants.TOGGLE_DASHBOARD_MODAL);

  }

  setNewDashboardName(payload) {
    _store.newDashboardName = payload.newDashboardName;
    _store.dashboardFormType = 'createDashboard';
    _store.dashboardModalVisible = true;
    this.emit(Constants.TOGGLE_DASHBOARD_MODAL);
  }

  setTileForForceUpdate(payload) {
    _store.tileToForceUpdate = payload.uuid;
    this.emit(Constants.TILE_FORCE_UPDATE);
  }

  setDashboardToDelete(payload) {
    _store.dashboardModalVisible = !_store.dashboardModalVisible;
    _store.dashboardFormType = payload.formType;
    _store.selectedDashboardToDelete = payload.uuid;
    this.emit(Constants.SET_DASHBOARD_TO_DELETE);
    this.emit(Constants.TOGGLE_DASHBOARD_MODAL);
  }

  getDashboardToDelete() {
    return _store.selectedDashboardToDelete;
  }

  setMobileView(payload) {
    _store.isMobileView = payload.isMobileView;
    this.emit(Constants.CHANGE);
  }

  refreshNewMessagesCount() {
    _store.newMessagesCountRefreshKey = Math.random();
    this.emit(Constants.CHANGE);
  }

  setSelectedCompany(payload) {
    _store.selectedCompany = payload.selectedCompany;
    this.emit(Constants.CHANGE);

    const customer = JSON.parse(localStorage.getItem('user'));
    customer.privileges = _store.selectedCompany.privileges
    localStorage.setItem("user", JSON.stringify(customer));
  }

  setSelectedDashboard(payload) {
    _store.selectedDashboard = payload.selectedDashboard;
    this.emit(Constants.UPDATE_DASHBOARD_LIST);
  }

  setSelectedManager(payload) {
    _store.selectedManager = payload.selectedManager;
  }

  updateCompanyList() {
    getAllCompanies().then(companies => {
      var companySelectOptions = companies.map(company => { return { label: company.name, value: company.uuid, privileges: company.privileges } })
      _store.companies = companySelectOptions;
      this.emit(Constants.CHANGE);
    })
  }

  updateDashboardList(payload = {}) {
    if((_store.userRole === AppConstants.Roles.USER || _store.userRole === AppConstants.Roles.ADMIN) || _store.selectedCompany !== '') {
      getAllDashboards().then(dashboards => {
        let counter = 1;
        var dashboardSelectOptions = dashboards.map(dashboard => { const id = counter; counter++;  return { id, label: dashboard.dashboardName, value: dashboard.uniqueId, owned: dashboard.owned, shared: dashboard.shared } })
        _store.dashboards = dashboardSelectOptions;

        if(payload.dashboardName !== null && payload.dashboardName !== undefined) {
          const dashboard = _store.dashboards.find( dashboard => dashboard.label === payload.dashboardName && dashboard.owned);
          if(dashboard !== null && dashboard !== undefined)
          _store.selectedDashboard = dashboard;
        }

        if(!_store.selectedDashboard || !_store.dashboards.some(temp => temp.value === _store.selectedDashboard.value)) {
            _store.selectedDashboard = dashboardSelectOptions[0];
        }


        this.emit(Constants.UPDATE_DASHBOARD_LIST);
      })
    }
  }

  setUserRole(role) {
    _store.userRole = AppConstants.Roles[role];
  }

  isMobileView() {
    return _store.isMobileView;
  }

  getNewMessagesCount() {
    return _store.newMessagesCount;
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getDashboardModalState() {
    return _store.dashboardModalVisible;
  }

  getDashboardFormType() {
    return _store.dashboardFormType;
  }

  getNewDashboardName() {
    return _store.newDashboardName;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  getSelectedCompany() {
    return _store.selectedCompany;
  }

  getSelectedDashboard() {
    return _store.selectedDashboard;
  }

  getTileToForceUpdate() {
    return _store.tileToForceUpdate;
  }

  getSelectedTile() {
    return _store.selectedTile;
  }

  getSelectedManager() {
    return _store.selectedManager;
  }

  getCompanyList() {
    return _store.companies;
  }

  getDashboardList() {
    return _store.dashboards;
  }

  getUserRole() {
    return _store.userRole;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }

  addUpdateDashboadListener(callback) {
    this.on(Constants.UPDATE_DASHBOARD_LIST, callback);
  }

  removeUpdateDashboadListener(callback) {
    this.removeListener(Constants.UPDATE_DASHBOARD_LIST, callback);
  }

  addToggleSidebarListener(callback) {
    this.on(Constants.TOGGLE_SIDEBAR, callback);
  }

  removeToggleSidebarListener(callback) {
    this.removeListener(Constants.TOGGLE_SIDEBAR, callback);
  }

  addDashboardToDeleteChangeListener(callback) {
    this.on(Constants.SET_DASHBOARD_TO_DELETE, callback);
  }

  removeDashboardToDeleteChangeListener(callback) {
    this.removeListener(Constants.SET_DASHBOARD_TO_DELETE, callback);
  }

  addToggleDashboardModalListener(callback) {
    this.on(Constants.TOGGLE_DASHBOARD_MODAL, callback);
  }

  removeToggleDashboardModalListener(callback) {
    this.removeListener(Constants.TOGGLE_DASHBOARD_MODAL, callback);
  }

  addTileForceUpdateListener(callback) {
    this.on(Constants.TILE_FORCE_UPDATE, callback)
  }

  removeTileForceUpdateListener(callback) {
    this.removeListener(Constants.TILE_FORCE_UPDATE, callback)
  }


}

export default new Store();
