import React from "react";
import { Container } from "shards-react";

class NotFoundError extends React.Component {
    state = {}
    render() {
        const errorStyle = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexFlow: "column"
        }

        return (
            <Container fluid className="main-content-container" style={errorStyle}>
                    <h1>404</h1>
                    <p>Nie odnaleziono strony</p>
            </Container>
        );
    }
}

export default NotFoundError;