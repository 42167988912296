import React from "react";
import { ConfirmModal } from "../../common/Modal";
import * as Constants from '../../../constants';
import API from "../../../api/AxiosConfiguration";
import * as SocToast from "../../../utils/SocToast";

class DeleteCategoryModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteCategory = this.deleteCategory.bind(this);
    }

    deleteCategory(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(Constants.NOTIFICATION_CATEGORY_URL + "/" + uuid)
        .then(result => {
            if(result.status === 200) {
                onSuccess();
                toggleFunction();
            }
        }).catch(error => {
            var response = error.response;
            if (response && response.status === 409) {
                SocToast.error("Wystąpił błąd", "Przed usunięciem modelu powiadomienia upewnij się, że nie jest ona przypisana do żadnej reguły.")
                toggleFunction();
            }
        })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz usunąć model powiadomienia?"
                    open={open}
                    toggle={toggle}
                    submitAction={this.deleteCategory}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie wszystkich danych o tym modelu powiadomienia.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          
            </ConfirmModal>
        )
    }
}

export default DeleteCategoryModal;