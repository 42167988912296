import React from 'react';
import { ModalFooter, Modal, ModalHeader, ModalBody, Button, Col } from 'shards-react';
import { Row } from 'shards-react';
import CustomerStatus from './../CustomerStatus';
import CustomerDetails from './../CustomerDetails';
import CustomerLogs from './../CustomerLogs';
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { getUserReadableStatus } from './../../../utils/CustomerStatuses';
import MoveCustomerDevicesModal from './MoveCustomerDevicesModal';
import * as SocToast from "../../../utils/SocToast";
import CustomerTwoFactor from './../CustomerTwoFactor';

class CustomerDetailsModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMoveCustomerDevicesModalShow: false,
            isTwoFactorModalShow: false,
            isCustomerInvited: false,
            hasSocAdminRole: false,
            customerData: {
                toDelete: false,
                status: undefined,
                details: undefined,
                logs: []
            },
            isBlockCustomerFooterShow: false,
            isDeleteCustomerFooterShow: false,
        }

        this.updateComponent = this.updateComponent.bind(this);
        this.clearFlags = this.clearFlags.bind(this);
        this.toggleBlockCustomerFooter = this.toggleBlockCustomerFooter.bind(this);
        this.toggleDeleteCustomerFooter = this.toggleDeleteCustomerFooter.bind(this);
        this.toggleMoveCustomerDevicesModal = this.toggleMoveCustomerDevicesModal.bind(this);
        this.blockCustomer = this.blockCustomer.bind(this);
        this.deleteCustomer = this.deleteCustomer.bind(this);
        this.forcePasswordChange = this.forcePasswordChange.bind(this);
    }

    toggleBlockCustomerFooter() {
        this.setState({
            isBlockCustomerFooterShow: !this.state.isBlockCustomerFooterShow
        })
    }

    toggleDeleteCustomerFooter() {
        this.setState({
            isDeleteCustomerFooterShow: !this.state.isDeleteCustomerFooterShow
        })
    }

    toggleMoveCustomerDevicesModal() {
        this.setState({
            isMoveCustomerDevicesModalShow: !this.state.isMoveCustomerDevicesModalShow
        })
    }

    blockCustomer() {
        const { uuid } = this.props;
        API.get(Constants.CUSTOMERS_URL + "/block/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    this.toggleBlockCustomerFooter();
                    this.updateComponent();
                }
            })
    }

    deleteCustomer() {
        const { uuid, toggle } = this.props;
        API.post(Constants.CUSTOMERS_URL + "/delete/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    toggle();
                }
            })
            .catch((error) => {
                var response = error.response
                if (response.status === 409) {
                    this.toggleMoveCustomerDevicesModal();
                }
            });
    }

    forcePasswordChange() {
        const { uuid } = this.props;
        API.get(Constants.CUSTOMERS_URL + "/password/reset/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    SocToast.success("Wiadomość wysłana", "Wymuszenie zmiany hasła powiodło się.");
                } else {
                    SocToast.error("Coś poszło nie tak", "Nie udało się wymusić zmiany hasła");
                }
            })
            .catch((error) => {
                SocToast.error("Coś poszło nie tak", "Nie udało się wymusić zmiany hasła");
            });
    }

    updateComponent() {
        const { uuid } = this.props;
        API.get(Constants.CUSTOMERS_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    this.setState({
                        customerData: result.data,
                        isCustomerInvited: result.data.status === Constants.STATUSES.INVITED,
                        hasSocAdminRole: result.data.role === Constants.Roles.SOCADMIN
                    })
                }
            })
    }

    clearFlags() {
        this.setState({
            isBlockCustomerFooterShow: false,
            isDeleteCustomerFooterShow: false,
        })
    }

    render() {
        const {
            open,
            toggle,
            uuid,
        } = this.props;

        const {
            isMoveCustomerDevicesModalShow,
            customerData,
            isBlockCustomerFooterShow,
            isDeleteCustomerFooterShow,
            isCustomerInvited,
            hasSocAdminRole
        } = this.state;


        const renderBlockCustomerButton = () => (
            <Button onClick={this.toggleBlockCustomerFooter} outline theme="info" className="mb-2 mr-1 float-right">
                Zablokuj użykownika
            </Button>
        )

        const renderDeleteCustomerButton = () => (
            <Button onClick={this.toggleDeleteCustomerFooter} disabled={!customerData.toDelete} outline theme="info" className="mb-2 mr-1 float-right">
                Usuń użykownika
            </Button>
        )

        const renderDefaultActionButtons = () => (
            <>
                <div></div>
                <div>
                    <Button onClick={toggle} outline theme="info" className="mb-2 mr-1 float-right">
                        Zamknij
                    </Button>
                    {!hasSocAdminRole &&
                        <React.Fragment>
                            {isCustomerInvited || customerData.toDelete || customerData.status === Constants.STATUSES.BLOCKED ?
                                renderDeleteCustomerButton() : renderBlockCustomerButton()}
                        </React.Fragment>
                    }

                    {!isCustomerInvited && !hasSocAdminRole && !customerData.toDelete && customerData.status === Constants.STATUSES.ACCEPTED && (
                        <Button onClick={this.forcePasswordChange} outline theme="info" className="mb-2 mr-1 float-right">
                            Zresetuj hasło
                        </Button>
                    )}
                </div>
            </>
        )

        const renderBlockCustomerButtonsWithMessage = () => {
            return (
                <>
                    <div>
                        <p className="mb-2 mr-1" style={{ fontSize: "12px" }}>
                            Jesteś pewien, że chcesz zablokować tego użytkownika? Akcja jest nieodwracalna.
                            <br />
                            Po 3 dniach będzie można usunąć użytkownika
                        </p>
                    </div>
                    <div>
                        <Button onClick={this.blockCustomer} outline theme="info" className="mb-2 mr-1 float-right">
                            Tak
                        </Button>

                        <Button onClick={this.toggleBlockCustomerFooter} outline theme="info" className="mb-2 mr-1 float-right">
                            Nie
                        </Button>
                    </div>
                </>
            )
        }

        const renderDeleteCustomerButtonsWithMessage = () => {
            return (
                <>
                    <div>
                        <p className="mb-2 mr-1" style={{ fontSize: "12px" }}>
                            Jesteś pewien, że chcesz usunąć tego użytkownika? Akcja jest nieodwracalna.
                        </p>
                    </div>
                    <div>
                        <Button
                            outline
                            theme="info"
                            className="mb-2 mr-1 float-right"
                            onClick={() => { this.deleteCustomer() }}>
                            Tak
                        </Button>

                        <Button
                            outline
                            theme="info"
                            className="mb-2 mr-1 float-right"
                            onClick={() => { this.toggleDeleteCustomerFooter() }}>
                            Nie
                        </Button>
                    </div>
                </>
            )
        }

        const renderTwoFactorDeactivate = () => (
            <Row>
            <CustomerTwoFactor twoFactor={customerData.twoFactor} uuid={uuid} onSuccess={this.updateComponent}/>
            </Row>
        )

        return (
            <Modal style={{ zIndex: "1080" }} size="xl" centered={true}
                open={open}
                toggle={toggle}
                showModal={() => {
                    this.updateComponent();
                    this.clearFlags();
                }}>
                <ModalHeader>Szczegóły</ModalHeader>
                <ModalBody className="py-3">
                    <Row>
                        <Col>
                            <Row>
                                <CustomerStatus
                                    uuid={uuid}
                                    status={getUserReadableStatus(customerData.status)} />
                            </Row>
                            <Row>
                                <CustomerDetails
                                    uuid={uuid}
                                    isEditable={!isCustomerInvited && !hasSocAdminRole}
                                    data={customerData.details}
                                    onSuccess={this.updateComponent} />
                            </Row>
                            {!isCustomerInvited && !hasSocAdminRole && renderTwoFactorDeactivate()}
                        </Col>
                        <Col className="pb-3">
                            <CustomerLogs logs={customerData.logs} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="justify-content-between">
                    {
                        isBlockCustomerFooterShow ? renderBlockCustomerButtonsWithMessage() :
                            isDeleteCustomerFooterShow ? renderDeleteCustomerButtonsWithMessage() :
                                renderDefaultActionButtons()
                    }
                </ModalFooter>

                <MoveCustomerDevicesModal open={isMoveCustomerDevicesModalShow} uuid={uuid}
                    toggle={this.toggleMoveCustomerDevicesModal} />
            </Modal>
        )
    }

}

export default CustomerDetailsModal;