import React from 'react';
import { Row, Col, ListGroup, ListGroupItem, Button } from 'shards-react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinus } from '@fortawesome/free-solid-svg-icons';
import ConfirmCategoryModal from './../modals/ConfirmCategoryModal';
import DeleteCategoryModal from './../modals/DeleteCategoryModal';

class SafeticaConfigurationCategoryTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isConfirmCategoryModalShow: false,
            isDeleteCategoryModalShow: false,
            selectedCategoryUuid: '',
            categories: []
        };

        this.updateComponent = this.updateComponent.bind(this);
        this.fetchSafeticaCategories = this.fetchSafeticaCategories.bind(this);
        this.toggleConfirmCategoryModal = this.toggleConfirmCategoryModal.bind(this);
        this.toggleDeleteCategoryModal = this.toggleDeleteCategoryModal.bind(this);
    }

    componentDidMount() {
        this.updateComponent();
    }

    toggleConfirmCategoryModal(uuid = "") {
        this.setState({
            isConfirmCategoryModalShow: !this.state.isConfirmCategoryModalShow,
            selectedCategoryUuid: uuid
        })
    }

    toggleDeleteCategoryModal(uuid = "") {
        this.setState({
            isDeleteCategoryModalShow: !this.state.isDeleteCategoryModalShow,
            selectedCategoryUuid: uuid
        })
    }

    fetchSafeticaCategories() {
        return API.get(AppConstants.PC_SAFETICA_CATEGORY_CONFIGURATION_URL);
    }

    updateComponent() {
        this.props.onFetchStart();
        this.fetchSafeticaCategories()
            .then(result => {
                this.setState({
                    categories: result.data.categories
                })
                this.props.onFetchComplete();
            })
    }

    render() {
        const { categories, isConfirmCategoryModalShow, isDeleteCategoryModalShow, selectedCategoryUuid } = this.state;

        const paginationOptions = {
            custom: true,
            totalSize: categories.length,
            sizePerPage: 10
        };

        const categoryColumns = [{
            dataField: 'name',
            align: "left",
            headerAlign: "left",
            text: "Nazwa",
        }, {
            dataField: 'type',
            align: "left",
            headerAlign: "left",
            text: "Typ",
            formatter: (cell, row) => (
                AppConstants.SAFETICA_CATEGORY_TYPES[cell]
            ),
        }, {
            dataField: 'threshold',
            align: "center",
            headerAlign: "center",
            text: "Próg",
        }, {
            dataField: 'values',
            align: "right",
            headerAlign: "right",
            text: "Wartość",
            formatter: (cell, row) => (
                Array.isArray(cell) ? cell
                    .map(value => AppConstants.SAFETICA_CATEGORY_SENSITIVE_CONTENTS[value] ? AppConstants.SAFETICA_CATEGORY_SENSITIVE_CONTENTS[value] : cell)
                    .map(value => (<div>{value}</div>)) : cell
            ),
        }, {
            dataField: 'actions',
            align: "right",
            headerAlign: "right",
            text: 'Akcje',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => (
                <div className="bootstrap-table-actions-col">
                    <Button size="sm" onClick={() => this.toggleConfirmCategoryModal(row.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>

                    <Button size="sm" theme="danger" className="ml-1" onClick={() => this.toggleDeleteCategoryModal(row.id)}>
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                </div>
            ),
        }]

        const renderCategories = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={categories}
                                columns={categoryColumns}
                                classes="table mb-0"
                                bordered={false}
                                hover
                                rowStyle={{ height: '10px' }}
                                wrapperClasses="table-responsive"
                                {...paginationTableProps} />

                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderCategoriesFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <h6>Brak danych</h6>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <React.Fragment>
                <Button onClick={this.toggleConfirmCategoryModal} outline theme="info" className="mb-3 mr-1 mt-2">Dodaj kategorie</Button>
                {categories.length ? renderCategories() : renderCategoriesFetchError()}

                <ConfirmCategoryModal open={isConfirmCategoryModalShow} uuid={selectedCategoryUuid}
                    toggle={this.toggleConfirmCategoryModal} onSuccess={this.updateComponent} />
                <DeleteCategoryModal open={isDeleteCategoryModalShow} uuid={selectedCategoryUuid}
                    toggle={this.toggleDeleteCategoryModal} onSuccess={this.updateComponent} />
            </React.Fragment>
        );
    }

}

export default SafeticaConfigurationCategoryTable;