import React from 'react';
import * as AppConstants from '../../../constants';
import * as SocToast from "../../../utils/SocToast";
import API from "../../../api/AxiosConfiguration";
import { trackPromise } from 'react-promise-tracker';

class DeleteCompanyForm extends React.Component {

    constructor(props) {
        super(props);

        this.validForm = this.validForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    checkboxChangeHandler = (name) => {
        var { form } = this.state;
        form[name] = !form[name];
        this.setState({ form: form });
    }

    validForm() {
        var errorCount = 0;
        return !errorCount;
    }

    submitForm(onSuccess) {
        const { uuid } = this.props;

        if (this.validForm()) {
            trackPromise(
                API.delete(AppConstants.COMPANIES_URL + "/" + uuid).then(result => {
                    if (result.status === 200) {
                        this.props.toggle();
                        onSuccess();
                        SocToast.success("Powodzenie", "Firma została usunięta.");
                    }
                }).catch(error => {
                    var response = error.response;
                    if (response && response.status === 409) {
                        SocToast.error("Wystąpił błąd", "Przed usunięciem firmy upewnij się, że wszyscy użytkownicy zostali usunięci lub przeniesieni")
                        this.props.toggle();
                    }
                })
            )
        }
    }

    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }

}

export default DeleteCompanyForm;