import React from 'react';
import { Row, Col } from 'shards-react';

const OrderDetails = ({ products }) => (
    <Row className="justify-content-md-center">
        <Col sm="12">
            <table className="table mb-0">
                <thead className="bg-primary" style={{ color: "white" }}>
                    <tr className="text-center">
                        <th scope="col" className="border-0">Nazwa produktu</th>
                        <th scope="col" className="border-0">Ilość</th>
                        <th scope="col" className="border-0">Kwota całkowita netto</th>
                        <th scope="col" className="border-0">Kwota całkowita brutto</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, idx) =>
                        <tr key={idx} className="text-center">
                            <td>{product.name}</td>
                            <td>{product.amount}</td>
                            <td>{product.totalNetPrice.toFixed(2).replace('.', ',')} ZŁ</td>
                            <td>{product.totalGrossPrice.toFixed(2).replace('.', ',')} ZŁ</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Col>
    </Row>
)

export default OrderDetails;