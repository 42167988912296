import React, { useState, useEffect } from "react";
import Loader from 'react-loader-spinner';

export default (Component) => {
    const WithLoadingComponent = (props) => {
        const _isMounted = React.useRef(true);
        const [isLoading, setLoading] = useState(false);
        useEffect(() => {
            return () => {
                _isMounted.current = false;
            }
        }, []);

        return (
            <React.Fragment>
                <Component setLoading={(isLoading) => { if (_isMounted.current) setLoading(isLoading) }} {...props}>
                    <div style={{ position: 'absolute', top: '13px', right: '45px', zIndex: "1" }}>
                        <Loader
                            type="ThreeDots"
                            color="#007bff"
                            height={27}
                            width={27}
                            visible={isLoading} />
                    </div>
                </Component>
            </React.Fragment>
        )
    }

    return WithLoadingComponent;
}
