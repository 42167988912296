import React from "react";
import {
    Row,
    Col,
    FormInput,
    Alert
} from "shards-react";

import API from "../../../api/AxiosConfiguration";
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import * as AppConstants from '../../../constants';
import { Store } from '../../../flux'
import { getCustomersForSelect } from '../../../api/ApiService';
import FormElement from './../../../utils/form/FormElement';
import * as DeepObjectUtils from "../../../utils/DeepObjectUtils"
import { trackPromise } from 'react-promise-tracker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import hash from 'object-hash'
import { hasValueUrlOrHtmlTag } from '../../../utils/Patterns'

class ConfirmControlPanelApplicationForm extends React.Component {

    constructor(props) {
        super(props);

        this.formElements = [];

        this.state = {
            isAdminAtLeast: Store.getUserRole() !== AppConstants.Roles.USER,
            isAdmin: Store.getUserRole() === AppConstants.Roles.ADMIN,
            form: {
                name: '',
                location: '',
                group: '',
                support: '',
                manager: '',
                model: '',
                additionalParameters: {}
            },
            formErrors: {
                global: [],
                name: [],
                group: [],
                manager: [],
                model: [],
                additionalParameters: {}
            },
            formHints: {
                location: [],
                group: [],
                support: [],
                manager: [],
                model: [],
            },
            selectedTemplate: "",
            formTemplates: [],
            formTemplateErrors: [],
            isTemplatesLoading: true,
            isManagersLoading: true,
            isOptionsLoading: false,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.selectTemplateChangeHandler = this.selectTemplateChangeHandler.bind(this);
        this.fetchControlPanelApplicationForm = this.fetchControlPanelApplicationForm.bind(this);
        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this);
        this.clearFormHints = this.clearFormHints.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;
        const { isAdminAtLeast } = this.state;

        if (uuid && uuid.length) {
            this.fetchAvailableOptions(uuid, () => {
                this.fetchControlPanelApplicationForm(uuid);
            });
        } else {
            if (isAdminAtLeast) {
                this.fetchAvailableOptions();
                this.fetchCompanyCustomers();
            } else {
                this.fetchAvailableOptions();
            }
        }

        this.fetchCustomerTemplates();
    }

    componentDidUpdate(prevProps, prevState) {
        var prevManager = prevState.form.manager ? prevState.form.manager.value : "";
        var currentManager = this.state.form.manager ? this.state.form.manager.value : "";

        if (prevManager !== currentManager) {
            this.clearFormHints();
            this.fetchAvailableOptions();
        }
    }

    clearFormHints() {
        this.setState({
            formHints: {
                ...this.state.formHints,
                location: [],
                group: [],
                support: [],
                model: []
            }
        })
    }

    selectTemplateChangeHandler = (template, event) => {
        const { uuid } = this.props;

        if (event.action !== "clear" && event.action !== "create-option")
            if (uuid && uuid.length) {
                this.setState({
                    form: {
                        ...this.state.form,
                        endWarranty: template.value.endWarranty,
                        manufacturer: template.value.manufacturer,
                        model: template.value.model,
                        support: template.value.support,
                        type: template.value.type,
                    }
                });
            } else {
                this.setState({
                    form: template.value
                });
            }

        this.setState({
            selectedTemplate: (event.action !== "clear") ? template.label : "",
            formTemplateErrors: []
        });
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (item, event) => {
        var { form, formErrors } = this.state;
        formErrors[event.name] = [];
        this.setState({
            form: {
                ...form,
                [event.name]: item
            }
        });
    }

    validTemplate() {
        const { selectedTemplate, formTemplateErrors } = this.state;

        formTemplateErrors.length = 0;

        var errorCount = 0;
        if (selectedTemplate.length <= 0) {
            formTemplateErrors.push("Nazwa szablonu nie może być pusta");
            errorCount++;
        }

        this.setState({ formTemplateErrors: errorCount ? formTemplateErrors : [] });
        return !errorCount;
    }

    validForm() {
        const { formErrors, isAdminAtLeast, isAdmin } = this.state;
        const { name, group, manager, model } = this.state.form;
        const { uuid } = this.props;

        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        formErrors["name"] = [];
        formErrors["group"] = [];
        formErrors["model"] = [];
        formErrors["manager"] = [];

        var errorCount = 0;

        if (hasValueUrlOrHtmlTag(name)) {
            formErrors["name"].push("Pole zawiera niedozwolone wyrażenia");
            errorCount++;
        }

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (group == null || group.length <= 0) {
            formErrors["group"].push("Nie wybrano grupy");
            errorCount++;
        }

        if (model == null || model.length <= 0) {
            formErrors["model"].push("Nie wybrano modelu");
            errorCount++;
        }

        if (!isAdmin && !isEditingModeEnabled && isAdminAtLeast && (!manager || manager.length <= 0)) {
            formErrors["manager"].push("Użytkownik musi zostać wybrany");
            errorCount++;
        }

        this.setState({ formErrors: { ...formErrors } });
        return !errorCount;
    }

    buildForm() {
        const { form } = this.state;

        var additionalParameters = {}
        this.formElements
            .map(formElementRef => formElementRef.validAndGetValue())
            .forEach(formElementResult => additionalParameters[formElementResult.name] = formElementResult.value)

        return {
            ...form,
            group: form.group && form.group.value,
            model: form.model && form.model.value,
            support: form.support && form.support.value,
            location: form.location && form.location.value,
            additionalParameters: additionalParameters,
        }
    }

    submitForm = (onSuccess) => {
        const { form, formErrors } = this.state;
        const { uuid } = this.props;
        this.setState({
            formTemplateErrors: []
        })

        if (this.validForm()) {
            const isDynamicFormInvalid = this.formElements
                .map(formElementRef => formElementRef.valid())
                .some(input => input.isValid === false)

            if (!isDynamicFormInvalid) {
                var apiPromise;
                if (uuid && uuid.length) {
                    apiPromise = API.put(AppConstants.CONTROL_PANEL_URL + "/" + uuid, {
                        action: AppConstants.ACTIONS.TO_EDIT,
                        form: this.buildForm()
                    })
                } else {
                    apiPromise = API.put(AppConstants.CONTROL_PANEL_URL, {
                        action: AppConstants.ACTIONS.TO_ADD,
                        form: {
                            ...this.buildForm(),
                            manager: form.manager && form.manager.value
                        }
                    });
                }

                trackPromise(
                    apiPromise.then((result) => {
                        if (result.status === 201) {
                            this.props.toggle();
                            onSuccess();
                        }
                    }).catch((error) => {
                        var response = error.response;
                        if (response && response.status === 400) {

                            response.data.errors.forEach(error => {
                                const path = error.field.substring(error.field.indexOf('.') + 1)
                                DeepObjectUtils.setValue(formErrors, path, []);

                                var fieldErrors = DeepObjectUtils.getValue(formErrors, path);
                                fieldErrors.push(error.defaultMessage);
                            })
                            this.setState({ formErrors: formErrors });
                        }

                        if (response && response.status === 409) {
                            this.setState({
                                formErrors: {
                                    ...formErrors,
                                    global: [response.data.message]
                                }
                            });
                        }
                    })
                );
            }
        }
    }

    submitTemplate = () => {
        const { selectedTemplate, form, formTemplateErrors } = this.state;
        this.setState({
            formErrors: {
                global: [],
                name: [],
                group: [],
                manager: [],
                model: [],
                additionalParameters: {}
            }
        })

        this.formElements
            .map(formElementRef => formElementRef.getValue())
            .forEach(formElementResult => form.additionalParameters[formElementResult.name] = formElementResult.value)

        if (this.validTemplate()) {
            API.put(AppConstants.CONTROL_PANEL_TEMPLATE_URL, {
                name: selectedTemplate,
                action: AppConstants.ACTIONS.TO_ADD,
                template: form,
            }).then((result) => {
                this.setState({ isTemplatesLoading: true });
                this.fetchCustomerTemplates();
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    formTemplateErrors.length = 0;
                    response.data.errors.forEach(error => {
                        formTemplateErrors.push(error.defaultMessage);
                    })
                    this.setState({ formTemplateErrors: formTemplateErrors });
                }
            });
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchControlPanelApplicationForm = (uuid) => {
        API.get(AppConstants.CONTROL_PANEL_URL + "/form/" + uuid).then((result) => {
            if (result.status === 200) {
                this.setState({
                    form: {
                        ...result.data,
                        group: this.findHintByValue("group", result.data.group),
                        model: this.findHintByValue("model", result.data.model),
                        location: this.findHintByValue("location", result.data.location),
                        support: this.findHintByValue("support", result.data.support),
                    }
                })
            }
        });
    }

    fetchCompanyCustomers = () => {
        getCustomersForSelect().then(customers => {
            this.setState({
                isManagersLoading: false,
                formHints: {
                    ...this.state.formHints,
                    manager: customers
                }
            })
        })
    }

    fetchCustomerTemplates = () => {
        API.get(AppConstants.CONTROL_PANEL_TEMPLATE_URL).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formTemplates: result.data,
                    isTemplatesLoading: false
                })
            }
        });
    }

    fetchAvailableOptions = (uuid = "", callback = () => { }) => {
        const { isAdminAtLeast } = this.state;
        const { manager } = this.state.form;

        var axiosConfig;
        if (isAdminAtLeast) {
            if (Store.getUserRole() === AppConstants.Roles.SOCADMIN) {
                if (!((manager && manager.value) || uuid)) return;
            }

            axiosConfig = {
                params: {
                    uuid: uuid,
                    manager: manager ? manager.value : ""
                }
            }
        }

        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.CONTROL_PANEL_URL + "/form/options/create", axiosConfig).then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                        model: result.data.model.map(model => { model['hash'] = hash.MD5(model); return model; })
                    },
                    isOptionsLoading: false,
                }, callback)
            }
        });
    }

    render() {
        const { isAdminAtLeast, isAdmin } = this.state;
        const { name, location, group, support, manager, model } = this.state.form;
        const { uuid } = this.props;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasTemplateNameError = Boolean(this.state.formTemplateErrors.length);
        var hasGlobalError = Boolean(this.state.formErrors.global.length);
        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasGroupError = Boolean(this.state.formErrors.group.length);
        var hasModelError = Boolean(this.state.formErrors.model.length);
        var hasManagerError = Boolean(this.state.formErrors.manager.length);

        const isAdminAtLeastAndManagerIsSelected = isAdmin ? true : !isAdminAtLeast || Boolean(manager);
        var isEditingModeEnabled = Boolean(uuid && uuid.length);

        const TemplateOptionWithTrashComponent = props => {
            const { innerProps, innerRef, children } = props;

            const deleteItem = (event, itemName) => {
                event.stopPropagation();
                this.setState({
                    isTemplatesLoading: true
                })
                this.deleteCustomerTemplate(props.data.uuid);
                props.clearValue();
            }

            const optionStyles = {
                backgroundColor: Boolean(innerRef) ? "#DEEBFF" : "transparent",
                display: "flex",
                justifyContent: "space-between"
            }

            return (
                <div ref={innerRef} {...innerProps} className="react-select-option" style={optionStyles} >
                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>{children}</div>
                    <div className="react-select-option-trash-container">
                        {!children.startsWith("Utwórz") && <div onClick={event => deleteItem(event, children)}>
                            <FontAwesomeIcon icon={faTrash} className="react-select-option-trash-icon" />
                        </div>}
                    </div>
                </div>
            );
        };

        const filterTemplates = (inputValue) => {
            return this.state.formTemplates.filter(template =>
                template.name.toLowerCase().includes(inputValue.toLowerCase())
            ).map((template) => {
                return {
                    value: template.template,
                    label: template.name,
                    uuid: template.uuid
                }
            });
        }

        const filterTemplatesPromise = inputValue =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterTemplates(inputValue));
                }, 500);
            });

        const filterOptions = (inputValue, optionName) => {
            return this.state.formHints[optionName].filter(option => {
                if (typeof option === 'object' && option !== null) {
                    return option.label.toLowerCase().includes(inputValue.toLowerCase())
                } else {
                    return option.toLowerCase().includes(inputValue.toLowerCase())
                }
            }).map((option) => {
                if (typeof option === 'object' && option !== null) {
                    return option;
                } else {
                    return {
                        value: option,
                        label: option,
                    }
                }
            });
        }

        const filterOptionsPromise = (inputValue, optionName) =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterOptions(inputValue, optionName));
                }, 500);
            });

        const defaultSelectProps = {
            placeholder: "Wybierz lub utwórz nowy",
            formatCreateLabel: (inputText) => `Utwórz: "${inputText}"`,
            className: 'react-select-container mb-2',
            classNamePrefix: "react-select",
            menuPosition: "absolute",
            menuPlacement: "auto",
            noOptionsMessage: () => "Brak dostępnych opcji",
            loadingMessage: () => "Ładowanie",
            isClearable: true
        }

        return (
            <Row>
                <Col sm="12">
                    {hasGlobalError &&
                        <Alert theme="danger">
                            {this.state.formErrors.global.map((error, idx) =>
                                <div key={idx}>{error}</div>
                            )}
                        </Alert>
                    }
                </Col>

                <Col sm="12">
                    <label>Szablon</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        defaultOptions={this.state.formTemplates.map(item => { return { value: item.template, label: item.name, uuid: item.uuid } })}
                        onChange={this.selectTemplateChangeHandler}
                        isValidNewOption={(inputValue) => {
                            const processedInputValue = inputValue ||  ""
                            
                            return processedInputValue.length >= 3 && processedInputValue.length <= 64 && !hasValueUrlOrHtmlTag(processedInputValue)
                        }}
                        className={hasTemplateNameError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isLoading={this.state.isTemplatesLoading}
                        loadOptions={filterTemplatesPromise}
                        components={{ Option: TemplateOptionWithTrashComponent }} />
                    {hasTemplateNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formTemplateErrors)}</ul>}
                </Col>

                <Col sm="12" className="p-0">
                    <h6 className="m-0">Konfiguracja urządzenia</h6>
                    <div className="divider"></div>
                </Col>

                <Col sm="12" md="6">
                    <label>*Nazwa</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    {!isEditingModeEnabled && isAdminAtLeast &&
                        <>
                            <label>*Menedżer</label>
                            <AsyncSelect
                                {...defaultSelectProps}
                                name="manager"
                                value={manager}
                                onChange={this.selectChangeHandler}
                                defaultOptions={this.state.formHints.manager}
                                className={hasManagerError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                                loadOptions={(inputValue) => filterOptionsPromise(inputValue, "manager")}
                                isLoading={this.state.isManagersLoading}
                                placeholder={isAdmin ? `${JSON.parse(localStorage.getItem("user")).name} (domyślny)` : "Wybierz"} />
                            {hasManagerError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.manager)}</ul>}
                        </>
                    }

                    <label>*Grupa</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="group"
                        value={group}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.group}
                        className={hasGroupError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "group")}
                        isLoading={this.state.isOptionsLoading}
                        placeholder="Wybierz"
                    />
                    {hasGroupError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.group)}</ul>}

                    <label>*Model</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="model"
                        value={model}
                        onChange={(item, event) => { this.formElements = []; this.selectChangeHandler(item, event); }}
                        defaultOptions={this.state.formHints.model}
                        className={hasModelError ? "react-select-container has-error mb-0" : "react-select-container mb-2"}
                        isDisabled={isEditingModeEnabled || !isAdminAtLeastAndManagerIsSelected}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "model")}
                        isLoading={this.state.isOptionsLoading}
                        placeholder="Wybierz"
                    />
                    {hasModelError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.model)}</ul>}

                    <label>Lokalizacja urządzenia</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="location"
                        value={location}
                        onChange={this.selectChangeHandler}
                        isValidNewOption={(inputValue) => {
                            const processedInputValue = inputValue ||  ""
                            
                            return processedInputValue.length >= 3 && processedInputValue.length <= 50 && !hasValueUrlOrHtmlTag(processedInputValue)
                        }}
                        defaultOptions={this.state.formHints.location}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "location")}
                        isLoading={this.state.isOptionsLoading} />

                    <label>Kontakt do supportu</label>
                    <AsyncCreatableSelect
                        {...defaultSelectProps}
                        name="support"
                        value={support}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.support}
                        isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                        loadOptions={(inputValue) => filterOptionsPromise(inputValue, "support")}
                        isLoading={this.state.isOptionsLoading} />
                </Col>

                <Col sm="12" md="6">
                    {model && model.additionalParameters && JSON.parse(model.additionalParameters).map((formElement, idx) =>
                        <FormElement
                            key={model.hash + "-" + formElement.name}
                            formItem={formElement}
                            initialValue={this.state.form.additionalParameters[formElement.name]}
                            isDisabled={!isEditingModeEnabled && !isAdminAtLeastAndManagerIsSelected}
                            errors={this.state.formErrors.additionalParameters[formElement.name] && this.state.formErrors.additionalParameters[formElement.name]}
                            ref={formElementRef => this.formElements[idx] = formElementRef} />
                    )}
                </Col>
            </Row>
        );
    }
}

export default ConfirmControlPanelApplicationForm;