import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button } from "shards-react";
import API from "../../api/AxiosConfiguration";
import * as AppConstants from '../../constants';
import BuySmsPackageModal from './modals/BuySmsPackageModal';
import TransactionSummaryModal from './../payment/TransactionSummaryModal';

class SmsPackage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenBuySmsPackageModal: false,
            isOpenTransactionSummaryModal: false,
            companySmsAmount: "-",
            tariff: [],
            orderSummary: null
        };

        this.toggleBuySmsPackageModal = this.toggleBuySmsPackageModal.bind(this);
        this.toggleTransactionSummaryModal = this.toggleTransactionSummaryModal.bind(this);
        this.fetchSmsAmountOfClient = this.fetchSmsAmountOfClient.bind(this);
        this.fetchSmsPackageTariff = this.fetchSmsPackageTariff.bind(this);
    }

    componentDidMount() {
        this.fetchSmsPackageTariff();
        this.updateComponent();
    }

    toggleBuySmsPackageModal() {
        this.setState({
            isOpenBuySmsPackageModal: !this.state.isOpenBuySmsPackageModal
        })
    }

    toggleTransactionSummaryModal(orderSummary) {
        this.setState({
            isOpenTransactionSummaryModal: !this.state.isOpenTransactionSummaryModal,
            orderSummary: orderSummary
        })
    }

    updateComponent() {
        this.fetchSmsAmountOfClient();
    }

    fetchSmsAmountOfClient() {
        API.get(AppConstants.COMPANY_SETTINGS_SMS_AMOUNT)
            .then(result => {
                if (result.status === 200) {
                    this.setState({
                        companySmsAmount: result.data
                    });
                }
            })
    }

    fetchSmsPackageTariff() {
        API.get(AppConstants.COMPANY_SETTINGS_SMS_PACKAGE_TARIFF)
            .then(result => {
                if (result.status === 200) {
                    this.setState({
                        tariff: result.data
                    });
                }
            })
    }

    convertTariff(tariff) {
        return {
            text: tariff.endRange && !tariff.startRange ? `≤ ${tariff.endRange}` :
                tariff.startRange && tariff.endRange ? `${tariff.startRange} - ${tariff.endRange}` :
                    tariff.startRange && !tariff.endRange ? `≥ ${tariff.startRange}` : "...",
            price: tariff.price.toFixed(2).replace('.', ",")
        }
    }

    render() {
        const {
            isOpenBuySmsPackageModal,
            isOpenTransactionSummaryModal,
            companySmsAmount,
            tariff,
            orderSummary
        } = this.state;

        return (
            <React.Fragment>
                <Card>
                    <CardHeader className="border-bottom d-inline">
                        <h6 className="m-0">Dostępna ilość SMS</h6>
                    </CardHeader>
                    <CardBody>
                        <div className="text-center">
                            <h2>{companySmsAmount}</h2>
                            <p>Cennik pakietów SMS:</p>
                        </div>

                        <table className="table mb-0">
                            <tbody className="text-center">
                                {tariff.map(this.convertTariff).map((tariffItem, idx) =>
                                    <tr key={idx}>
                                        <td>{tariffItem.text}</td>
                                        <td>{tariffItem.price} ZŁ (netto za SMS)</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button onClick={this.toggleBuySmsPackageModal} outline theme="info" className="mb-2 mr-1 float-right">Dokup</Button>
                    </CardFooter>
                </Card>

                <BuySmsPackageModal open={isOpenBuySmsPackageModal} tariff={tariff}
                    toggle={this.toggleBuySmsPackageModal} onSuccess={(orderSummary) => {
                        this.toggleBuySmsPackageModal();
                        this.toggleTransactionSummaryModal(orderSummary);
                    }} />

                <TransactionSummaryModal open={isOpenTransactionSummaryModal} order={orderSummary}
                    toggle={() => this.toggleTransactionSummaryModal(null)} />
            </React.Fragment>
        );
    }

}

export default SmsPackage;
