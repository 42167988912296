import React from 'react';
import { ConfirmModal } from "../../common/Modal";
import ConfirmDeviceGroupForm from './../forms/ConfirmDeviceGroupForm';

class ConfirmDeviceGroupModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid,
            isMobileView,
        } = this.props;

        return (
            <ConfirmModal
                title={uuid && typeof (uuid) === "string" ? "Edytowanie grupy" : "Dodaj nową grupę urządzeń"}
                open={open}
                toggle={toggle}
                submitAction={() => { this.refs.addDeviceGroupForm.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj">

                <ConfirmDeviceGroupForm
                    toggle={toggle}
                    ref="addDeviceGroupForm"
                    uuid={uuid}
                    isMobileView={isMobileView} />

            </ConfirmModal>
        );
    }

}

export default ConfirmDeviceGroupModal;