import React from 'react'
import { FormInput } from 'shards-react';
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';

import { SECURE_PASSWORD_REGEXP } from '../../../utils/Patterns';


class SaveArchivePasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                password: ''
            },
            formErrors: {
                password: []
            }
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { password } = this.state.form;

        formErrors["password"] = [];

        var errorCount = 0;

        if (!SECURE_PASSWORD_REGEXP.test(password)) {
            formErrors["password"].push("Hasło musi zawierać conajmniej: jedną małą literę, " +
                "jedną dużą literę, jedną cyfrę, jeden znak specjalny, 8 znaków.");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    submitForm() {
        const { form, formErrors } = this.state;

        if (this.validForm()) {
            API.post(Constants.PROFILE_URL + "/archive-password", {
                form: {
                    password: form.password,
                }
            }).then((result) => {
                if (result.status === 200) {
                    this.props.onSuccess();
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    response.data.errors && response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                }
            });
        }
    }

    render() {
        const { password } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        const hasPasswordError = Boolean(this.state.formErrors.password.length);

        return (
            <div className="mt-2">
                <label>Hasło:</label>
                <FormInput
                    name="password"
                    value={password || ''}
                    onChange={this.inputChangeHandler}
                    type="password"
                    invalid={hasPasswordError}
                    className={hasPasswordError ? "mb-0" : "mb-2"} />
                {hasPasswordError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.password)}</ul>}
            </div>
        );
    }

}

export default SaveArchivePasswordForm;