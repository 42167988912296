import React from "react";
import { ConfirmModal } from "../../common/Modal";
import * as Constants from '../../../constants';
import API from "../../../api/AxiosConfiguration";

class DeleteAbsenceModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteAbsence = this.deleteAbsence.bind(this);
    }

    deleteAbsence(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(Constants.CONTROL_PANEL_ABSENCES_URL + "/" + uuid)
        .then(result => {
            if(result.status === 200) {
                onSuccess();
                toggleFunction();
            }
        })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                    title="Jesteś pewien, że chcesz usunąć nieobecność?"
                    open={open}
                    toggle={toggle}
                    submitAction={this.deleteAbsence}
                    positiveButtonText="Potwierdź"
                    negativeButtonText="Anuluj">
                        Spowoduje to usunięcie wszystkich danych o tej nieobecności.
                        Jeśli jesteś zdecydowany, kliknij "Potwierdź".
          
            </ConfirmModal>
        )
    }
}

export default DeleteAbsenceModal;