import React from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    CardFooter,
    Tooltip,
} from "shards-react";

class AccessGroupCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showHint: false,
        }

        this.toggleHintIfTitleIsTooLong = this.toggleHintIfTitleIsTooLong.bind(this);
    }


    toggleHintIfTitleIsTooLong() {
        const element = document.getElementById('name-' + this.props.id);
        if (element.clientHeight < element.scrollHeight ||
            element.clientWidth < element.scrollWidth)
            this.setState({
                showHint: !this.state.showHint
            })
    }

    render() {
        const {
            name,
            customerDetails,
            withoutButtons,
            withEditButton,
            withDeleteButton,
            withDetailsButton,
            onDelete,
            onEdit,
            onDetails,
            id,
        } = this.props;

        const separateArrayByComma = (value) => {
            return Array.isArray(value) ? value.join(", ") : value;
        }

        const Details = () => {
            return customerDetails.map((element, index) => {
                if (customerDetails && element.label)
                    return (
                        <Row key={index}>
                            <Col>{element.label}</Col>
                            <Col>{typeof element.value !== 'undefined' && element.value !== null ?
                                separateArrayByComma(element.value ? "Aktywny" : "Nieaktywny") : "-"}</Col>
                        </Row>
                    )
                return null;
            })
        }

        const threeDotsNameStyles = {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        }

        return (
            <div>
                <Card className="mt-2 mb-2">
                    <CardBody className="small-padding">
                        <Tooltip
                            open={this.state.showHint}
                            target={`#name-${id}`}
                            toggle={this.toggleHintIfTitleIsTooLong}>
                            {name}
                        </Tooltip>

                        <CardTitle id={`name-${id}`}
                            className="text-center pb-2"
                            style={threeDotsNameStyles} >{name}</CardTitle>
                        <hr className="mt-2 mb-2" />
                        <div className="device-card-details">
                            <Details />
                        </div>
                    </CardBody>
                    <CardFooter className="pb-1 px-1">
                        {(!withoutButtons && withEditButton) &&
                            <div>
                                <Button onClick={onEdit} outline theme="info" className="mb-2 mr-1 float-right">Edytuj</Button>
                            </div>}

                        {(!withoutButtons && withDeleteButton) &&
                            <div>
                                <Button onClick={onDelete} outline theme="danger" className="mb-2 mr-1 float-right">Usuń</Button>
                            </div>}

                        {(!withoutButtons && withDetailsButton) &&
                            <div>
                                <Button onClick={onDetails} outline theme="info" className="mb-2 mr-1 float-right">Szczegóły</Button>
                            </div>}
                    </CardFooter>
                </Card>
            </div>
        );
    }
}

export default AccessGroupCard;
