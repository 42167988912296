import React from 'react';
import { Row, Col, FormInput } from 'shards-react';
import MultiSelect from "@kenshooui/react-multi-select";

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';
import * as socToast from '../../../../utils/SocToast';

const multiSelectMessages = {
    searchPlaceholder: "Szukaj...",
    noItemsMessage: "Nie znaleziono kategorii aplikacji...",
    noneSelectedMessage: "Nie wybrano",
    selectedMessage: "wybrany/ch",
    selectAllMessage: "Wybierz Wszystkie",
    clearAllMessage: "Wyczyść",
}

const renderError = (errors) => {
    return errors.map((error, index) =>
        <li key={index}>{error}</li>
    )
}

class ConfirmAppCategoryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                appCategories: [],
            },
            formErrors: {
                name: [],
                appCategories: [],
            },
            formHints: {
                appCategories: [],
            },
            isOptionsLoading: false
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.multiselectChangeHandler = this.multiselectChangeHandler.bind(this);

        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);
        this.fetchAppCategoryForm = this.fetchAppCategoryForm.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;

        if (uuid.length) {
            this.fetchAvailableOptions(() => {
                this.fetchAppCategoryForm(uuid);
            });
        } else {
            this.fetchAvailableOptions();
        }
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    multiselectChangeHandler(name, selectedItems) {
        var { form, formErrors } = this.state;
        form[name] = selectedItems;
        formErrors[name] = [];
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, appCategories } = this.state.form;

        formErrors["name"] = [];
        formErrors["appCategories"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 255) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 255 znaków");
            errorCount++;
        }

        if (appCategories.length <= 0) {
            formErrors["appCategories"].push("Nie wybrano żadnej kategorii aplikacji");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildForm() {
        const { name, appCategories } = this.state.form;

        return {
            name: name,
            appCategories: appCategories.map(appCategory => appCategory.id)
        }
    }

    submitForm(onSuccess) {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.patch(AppConstants.PC_SAFETICA_APP_CATEGORY_CONFIGURATION_URL + "/" + uuid, {
                    form: this.buildForm()
                })
            } else {
                apiPromise = API.post(AppConstants.PC_SAFETICA_APP_CATEGORY_CONFIGURATION_URL, {
                    form: this.buildForm()
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Kategoria aplikacji została dodana.");
                }

                if (result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Kategoria aplikacji została zedytowana.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            });
        }
    }

    fetchAppCategoryForm(uuid) {
        const { appCategories } = this.state.formHints;

        API.get(AppConstants.PC_SAFETICA_APP_CATEGORY_CONFIGURATION_URL + "/" + uuid)
            .then(result => {
                this.setState({
                    form: {
                        ...this.state.form,
                        ...result.data,
                        appCategories: result.data.appCategories.map(selectedAppCategories => {
                            var foundAppCategory = appCategories.find(appCategory => appCategory.id === selectedAppCategories);
                            return foundAppCategory;
                        })
                    }
                })
            })
    }

    fetchAvailableOptions = (callback) => {
        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.PC_SAFETICA_APP_CATEGORY_CONFIGURATION_URL + "/form/options/create").then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                        appCategories: result.data.appCategories.map(appCategory => { return { id: appCategory.value, label: appCategory.label } })
                    },
                    isOptionsLoading: false,
                }, callback)
            }
        });
    }

    render() {
        const { name, appCategories } = this.state.form;

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasManagersError = Boolean(this.state.formErrors.appCategories.length);

        return (
            <React.Fragment>
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <label>*Nazwa</label>
                        <FormInput
                            name="name"
                            value={name || ''}
                            onChange={this.inputChangeHandler}
                            invalid={hasNameError}
                            className={hasNameError ? "mb-0" : "mb-2"} />
                        {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <label>Kategorie aplikacji</label>
                        <MultiSelect
                            name="appCategories"
                            items={this.state.formHints.appCategories}
                            selectedItems={appCategories}
                            onChange={(selectedItems) => this.multiselectChangeHandler("appCategories", selectedItems)}
                            messages={multiSelectMessages}
                            wrapperClassName={hasManagersError ? "invalid" : ""} />
                        {hasManagersError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.appCategories)}</ul>}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

}

export default ConfirmAppCategoryForm;