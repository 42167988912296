import React from "react";
import API from "../../../api/AxiosConfiguration";
import * as Constants from '../../../constants';
import { create } from '@github/webauthn-json';
import {
    Row,
    Col,
    FormInput,
    FormCheckbox,
} from "shards-react";

export default class AddSecurityKeyForm extends React.Component {

    constructor() {
        super();
        this.state = {
            stage: 1,
            form: {
                name: '',
                tokenAdditionalInfo: '',
                tokenState: true,
                tokenType: "SECURITYKEY"
            },
            formErrors: {
                name: [],
                tokenAdditionalInfo: [],
            },
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    inputTokenChangeHandler = (event) => {
        this.setState({ token: event.target.value });
    }

    inputChangeHandler = (event) => {
        var { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form: form });
    }

    checkboxChangeHandler = (event) => {
        var { form } = this.state;
        form.tokenState = !form.tokenState;
        this.setState({ form: form });
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, tokenAdditionalInfo } = this.state.form;

        formErrors["name"] = [];
        formErrors["tokenAdditionalInfo"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 32) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 32 znaków");
            errorCount++;
        }

        if (tokenAdditionalInfo.length > 100) {
            formErrors["tokenAdditionalInfo"].push("Informacje dodatkowe powinna składać się maksymalnie z 100 znaków");
            errorCount++;
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    async createCredentials(response) {
        const credential = await create({
            publicKey: response.data.publicKeyCredentialCreationOptions
        });

        try {
            // @ts-ignore
            credential.clientExtensionResults = credential.getClientExtensionResults();
        } catch (e) {
            // @ts-ignore
            credential.clientExtensionResults = {};
        }

        const credentialResponse = {
            name: this.state.form.name,
            tokenAdditionalInfo: this.state.form.tokenAdditionalInfo,
            tokenState: this.state.form.tokenState,
            tokenType: this.state.form.tokenType,
            registrationId: response.data.registrationId,
            credential
        };

        API.post(Constants.SECURITY_KEY_URL + "/finish", {twoFactorOperation: this.props.twoFactorOperation, credentialResponse: credentialResponse}, { responseType: 'text' })
            .then(response => {
                this.setState({ stage: 2 });
            })
    }

    submitForm = () => {

        if (this.state.stage === 1) {
            if (this.validForm()) {
                API.post(Constants.SECURITY_KEY_URL + "/start").then((response) => {
                    if (response.status === 200) {
                        this.createCredentials(response);
                    }
                });
            }
        } else {
            this.props.toggle();
        }
    }

    renderSucces() {
        return (
            <Row>
                <Col sm="12">
                    <label>Klucz bezpieczeństwa został dodany pomyślnie</label>
                </Col>
            </Row>
        )
    }

    renderAdd() {
        const { name, tokenAdditionalInfo, tokenState } = this.state.form;

        const renderError = (errors) => {
            return errors.map((error, index) =>
                <li key={index}>{error}</li>
            )
        }

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasTokenAdditionalInfoError = Boolean(this.state.formErrors.tokenAdditionalInfo.length);

        return (
            <Row>
                <Col sm="12">
                    <label>*Nazwa Tokenu</label>
                    <FormInput name="name" value={name || ''} onChange={this.inputChangeHandler} invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>Informacje dodatkowe</label>
                    <FormInput name="tokenAdditionalInfo" value={tokenAdditionalInfo || ''} onChange={this.inputChangeHandler} invalid={hasTokenAdditionalInfoError}
                        className={hasTokenAdditionalInfoError ? "mb-0" : "mb-2"} />
                    {hasTokenAdditionalInfoError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.tokenAdditionalInfo)}</ul>}

                    <FormCheckbox name="tokenState" toggle checked={tokenState} onChange={this.checkboxChangeHandler}> Aktywny </FormCheckbox>
                </Col>
            </Row>
        );
    }

    render() {

        return this.state.stage === 1 ?
            this.renderAdd() :
            this.renderSucces();
    }
}