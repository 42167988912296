import React, { Suspense } from "react";
import { ConfirmModal } from "../../../common/Modal";
import ConfirmPcRuleForm from "../forms/ConfirmPcRuleForm";
import { withTranslation } from 'react-i18next';

class ConfirmDeviceRuleModal extends React.Component {

    constructor(props) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            deviceType,
            uuid
        } = this.props;

        const renderConfirmFormForSpecifiedDeviceType = () => {
            return <Suspense fallback="loading"><ConfirmPcRuleForm toggle={toggle} ref={this.setRef} deviceType={deviceType} uuid={uuid} t={this.props.t}/></Suspense>
        }

        return (
            <ConfirmModal
                title="Wniosek o dodanie nowej reguły"
                open={open}
                toggle={toggle}
                submitAction={() => { this.childRef.submitForm(onSuccess); }}
                positiveButtonText="Wyślij"
                negativeButtonText="Anuluj" >

                {renderConfirmFormForSpecifiedDeviceType()}

            </ConfirmModal>
        );
    }
}

export default withTranslation()(ConfirmDeviceRuleModal);