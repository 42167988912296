/* eslint-disable no-useless-escape */
export const IP_REGEXP = new RegExp("^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$");
export const MAC_REGEXP = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");
export const EMAIL_REGEXP = new RegExp("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
export const PHONE_NUMBER_REGEXP = new RegExp("^[+]?[0-9]{9,12}$");
export const SECURE_PASSWORD_REGEXP = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
export const SECURE_STRING = new RegExp("^[+]?[0-9A-Za-z,.;?! \u00C0-\u017F]{0,}$");
export const NO_MATHING_STRING_DELIMITER_REGEXP = new RegExp("^(?:(?!('|\")).)*$");

export const getPatternByName = (name) => {
    switch (name) {
        case "IP_REGEXP": return IP_REGEXP;
        case "MAC_REGEXP": return MAC_REGEXP;
        case "EMAIL_REGEXP": return EMAIL_REGEXP;
        case "PHONE_NUMBER_REGEXP": return PHONE_NUMBER_REGEXP;
        case "SECURE_PASSWORD_REGEXP": return SECURE_PASSWORD_REGEXP;
        case "SECURE_STRING": return SECURE_STRING;
        case "NO_MATHING_STRING_DELIMITER_REGEXP": return NO_MATHING_STRING_DELIMITER_REGEXP;
        default: return null;
    }
}

export const hasValueUrlOrHtmlTag = (value) => {
    const htmlTagsRegex = (/(<[^>]*>)/g).test(value)
    const urlRegex = RegExp("(?:^|[\\W])((ht|f)tp(s?):\\/\\/|www\\.)"
        + "(([\\w\\-])+?([\\w\\-.~]+\\/?)*"
        + "[\\p{Alnum}.,%_=?&#\\-+()\\[\\]\\*$~@!:/{};']*)").test(value)
    const includesColon = `${value}`.includes(':')
    return (includesColon || htmlTagsRegex || urlRegex)
}