import React from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@material-ui/core/Tooltip';

const TooltippedSelect = ({
    label,
    tooltipContent,
    helpContent,
    placeholder,
    noOptionsMsg,
    name,
    isDisabled,
    isSearchable,
    isClearable,
    value,
    onChange,
    defaultOptions,
    loadOptions,
    hasError,
    renderErrorFunc,
    isMulti,
    isAsync,
    tooltip,
    helptip
}) => {

    const SelectMap = {
        "select": Select,
        "async": AsyncSelect
    };

    const buildSelect = () => {
        const selectProps = {
            placeholder: placeholder,
            menuPosition: 'fixed',
            noOptionsMessage: () => noOptionsMsg,
            name: name,
            isDisabled: isDisabled,
            isSearchable: isSearchable,
            isClearable: isClearable,
            value: value,
            closeMenuOnSelect: true,
            onChange: onChange,
            className: 'react-select-container',
            classNamePrefix: "react-select"
        };
        let SelectType;

        if(isAsync) SelectType = SelectMap['async'];
        else SelectType = SelectMap['select'];

        if(isMulti) {
            SelectType = SelectMap['select'];
            selectProps.isMulti = true;
            selectProps.closeMenuOnSelect = true;
        }

        if(SelectType === SelectMap['select']) {
            selectProps.options = defaultOptions;
            const flexGrow = { flexGrow: '1' };
            selectProps.style = { flexGrow };
        } else if(SelectType === SelectMap['async']) {
            selectProps.defaultOptions = defaultOptions;
            selectProps.className = hasError ? "react-select-container has-error mb-0" : "react-select-container mb-2";
            selectProps.loadOptions = loadOptions;
        }

        return (
            <>
                <label className="mt-3" style={ { display: 'flex', alignItems: 'center' } }>
                    {label}
                    { tooltip ? <Tooltip title={tooltipContent} placement="top" arrow  style={ { fontSize: '0.9rem', marginLeft: '0.5rem', cursor: 'pointer' } } >
                        <span>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                    </Tooltip> : null}
                </label>
                {helptip ?
                    <div className='mb-2' style={ { display: 'flex', alignItems: 'center' } }>
                        <SelectType { ...selectProps } />
                        <Tooltip title={helpContent} placement="top" arrow>
                            <span style={ { fontSize: '1.2rem', lineHeight: '1.2rem', marginLeft: '1rem' } }>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </span>
                        </Tooltip>
                    </div>
                 :
                  <>
                    <SelectType { ...selectProps } />
                  </> }
                  {hasError && <ul className="mb-2 form-error-message">{renderErrorFunc()}</ul>}
            </>
        );
    }

    return buildSelect();
};

export default TooltippedSelect;


TooltippedSelect.defaultProps = {
    isDisabled: false,
    isSearchable: false,
    isClearable: false,
    isMulti: false,
    isAsync: false,
    tooltip: false,
    helptip: false,
    renderErrorFunc: () => false
}
