import React from "react";
import {
    Card,
    CardBody,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    FormCheckbox
} from "shards-react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import WindowSizeListener from 'react-window-size-listener';

import dateFormat from "dateformat";

class BillingHistoryTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false

        }
    }


    render() {
        const { toggleDeleteInvoiceModal, invoices, handleCheck } = this.props;
        var invoiceColumns;

        const paginationOptions = {
            custom: true,
            totalSize: invoices.length,
            sizePerPage: 10
        };

        function textFormatter(cell, row) {
            return (
                <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>{cell}</span>
            );
        }

        function eventDateFormatter(cell, row) {
            return (
                <span className="text-semibold text-fiord-blue" style={{ fontSize: ".8125rem" }}>{dateFormat(cell, "dd-mm-yyyy")}</span>
            );
        }

        function statusFormatter(cell, row) {
            if (cell === true) {
                return (
                    <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>Opłacone</span>
                );
            } else {
                return (
                    <span className="ml-auto text-right text-semibold text-reagent-gray" style={{ fontSize: ".8125rem" }}>Nieopłacone</span>
                );
            }
        }

        function checkboxFormatter(cell, row) {
            const rowUuid = row.uuid.split(' ').join('_').split('/').join("s");
            return (
                <div>
                    <FormCheckbox
                        checked={cell}
                        onChange={e => handleCheck(e, rowUuid)}
                    ></FormCheckbox>
                </div>
            );
        }

        if (!this.state.isMobile) {
            invoiceColumns = [{
                dataField: 'uuid',
                align: "left",
                text: "Identyfikator",
                sort: true,
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: 'paymentDate',
                align: "left",
                text: "Termin Płatności",
                sort: true,
                headerClasses: "column-header",
                formatter: eventDateFormatter
            }, {
                dataField: 'description',
                align: "left",
                text: "Opis",
                sort: true,
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: 'value',
                align: "left",
                text: "Wartość brutto (PLN)",
                sort: true,
                sortFunc: (a, b, order) => {
                    if (order === 'asc') {
                        return parseFloat(b) - parseFloat(a);
                    }

                    return parseFloat(a) - parseFloat(b); // desc
                },
                headerClasses: "column-header",
                formatter: textFormatter
            }, {
                dataField: 'status',
                align: "left",
                text: "Status",
                sort: true,
                headerClasses: "column-header",
                formatter: statusFormatter
            }, {
                dataField: '',
                align: "left",
                text: "Zaznacz",
                headerClasses: "column-header",
                formatter: checkboxFormatter
            }]
        } else {
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                e.preventDefault();
                if (!(window.getSelection && window.getSelection().type === 'Range')) {
                    toggleDeleteInvoiceModal(row.uuid);
                }
            }
        };

        const renderInvoices = () => (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                    <Row className="mr-0 ml-0">
                        <Col xs="12" className="mb-2 pr-0 pl-0">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField="uuid"
                                data={invoices}
                                columns={invoiceColumns}
                                classes="table mb-0"
                                headerClasses="column-header"
                                bordered={false}
                                defaultSorted={[{ dataField: "uuid", order: "asc" }]}
                                rowStyle={{ height: '10px' }}
                                {...paginationTableProps}
                                rowEvents={this.state.isMobile ? rowEvents : {}} />
                        </Col>
                        <Col xs="6" className="d-flex justify-content-start">
                            <div><SizePerPageDropdownStandalone {...paginationProps} /></div>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                            <div><PaginationListStandalone {...paginationProps} /></div>
                        </Col>
                    </Row>
                )}
            </PaginationProvider>
        )

        const renderInvoicesError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto">
                    <span className="text-semibold text-fiord-blue">Brak danych</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <Card medium="true">
                <WindowSizeListener onResize={(windowSize) =>
                    windowSize.windowWidth > 630 ? this.setState({ isMobile: false }) : this.setState({ isMobile: true })}>
                </WindowSizeListener>

                <CardBody className="p-0">
                    {invoices.length ? renderInvoices() : renderInvoicesError()}
                </CardBody>
            </Card>
        )
    }
}

export default BillingHistoryTable;
