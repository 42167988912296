import React from "react";
import { Row, Col, Card, CardBody, CardHeader, ListGroup, ListGroupItem } from "shards-react";
import withLoader from "../common/hoc/withLoader";

class DeviceStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isLoading !== state.isLoading) {
            props.setLoading(props.isLoading);
            return {
                isLoading: props.isLoading
            }
        }

        return null;
    }

    getColor(status) {
        switch (status) {
            case "UP":
            case "Aktywny":
                return "#009900";
            case "DOWM":
            case "Nieaktywny":
                return "#ff0000";
            default:
                return "#757575";
        }
    }

    render() {
        const { status, lastCheckInfo } = this.props;

        const renderDeviceStatus = () => (
            <ListGroup small flush className="list-group-small">
              {lastCheckInfo && 
                <ListGroupItem className="d-flex px-3">
                    <h6 className="m-0 font-weight-bold" >
                        Status konfiguracji:
                        &ensp;
                        <span style={{ color: status.color }}>
                            {status.content}
                        </span>
                        <br/>

                        Status komunikacji sieciowej:
                        <br/>
                        <span style={{ color: this.getColor(lastCheckInfo.status) }}>
                            {lastCheckInfo.status}
                        </span>
                        &ensp;
                        {lastCheckInfo.date}
                    </h6>
                    <br/>
                </ListGroupItem>
              }
            </ListGroup>
        )

        const renderDeviceStatusFetchError = () => (
            <ListGroup small flush className="list-group-small text-center">
                <ListGroupItem className="d-flex px-3 m-auto without-border">
                    <span className="text-semibold text-fiord-blue">Nie udało się pobrać danych o urządzeniu</span>
                </ListGroupItem>
            </ListGroup>
        )

        return (
            <Row className="mt-2 mb-2 position-relative">
                {this.props.children}
                <Col>
                    <Card className="mb-3">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Stan urządzenia</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className=" py-0">
                            {
                                status ?
                                    renderDeviceStatus() :
                                    renderDeviceStatusFetchError()
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default withLoader(DeviceStatus);