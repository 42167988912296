import React from 'react';
import { Row, Col, FormInput, FormCheckbox } from 'shards-react';
import AsyncSelect from 'react-select/async';

import API from "../../../../api/AxiosConfiguration";
import * as AppConstants from '../../../../constants';
import * as socToast from '../../../../utils/SocToast';

const defaultSelectProps = {
    placeholder: "Wybierz",
    className: 'react-select-container mb-2',
    classNamePrefix: "react-select",
    menuPosition: "absolute",
    menuPlacement: "auto",
    noOptionsMessage: () => "Brak dostępnych opcji",
}

const renderError = (errors) => {
    return errors.map((error, index) =>
        <li key={index}>{error}</li>
    )
}

class ConfirmPolicyForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                policyType: "",
                zone: "",
                generalPolicies: [],
                zonePolicies: []
            },
            formErrors: {
                name: [],
                policyType: [],
                zone: [],
                generalPolicies: [],
                zonePolicies: []
            },
            formHints: {
                policyType: [],
                zone: [],
                generalPolicyData: [],
                zonePolicyData: []
            },
            isOptionsLoading: false
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.checkboxArrayChangeHandler = this.checkboxArrayChangeHandler.bind(this);
        this.checkboxArrayIsChecked = this.checkboxArrayIsChecked.bind(this);

        this.fetchPolicyForm = this.fetchPolicyForm.bind(this);
        this.fetchAvailableOptions = this.fetchAvailableOptions.bind(this);

        this.submitForm = this.submitForm.bind(this);
        this.validForm = this.validForm.bind(this);
        this.buildForm = this.buildForm.bind(this);

        this.findHintByValue = this.findHintByValue.bind(this);

        this.GeneralPolicyTypeForm = this.GeneralPolicyTypeForm.bind(this);
        this.ZonePolicyTypeForm = this.ZonePolicyTypeForm.bind(this);
    }

    componentDidMount() {
        const { uuid } = this.props;

        if (uuid.length) {
            this.fetchAvailableOptions(() => {
                this.fetchPolicyForm(uuid);
            });
        } else {
            this.fetchAvailableOptions();
        }
    }

    inputChangeHandler = (event) => {
        var { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    selectChangeHandler = (selected, event) => {
        var { form, formErrors } = this.state;
        if (event.name === "policyType") {
            form.generalPolicies = []; formErrors.generalPolicies = [];
            form.zonePolicies = []; formErrors.zonePolicies = [];
            form.zone = ""; formErrors.zone = [];
        }

        form[event.name] = selected;
        formErrors[event.name] = [];
        this.setState({ form: form });
    }

    checkboxArrayChangeHandler = (name, value) => {
        var { form, formErrors } = this.state;
        if (!Array.isArray(form[name])) return false;
        if (form[name].includes(value)) {
            var index = form[name].indexOf(value);
            if (index > -1) {
                form[name].splice(index, 1);
            }
        } else {
            form[name].push(value);
        }

        formErrors[name] = [];
        this.setState({ form: form });
    }

    checkboxArrayIsChecked = (name, value) => {
        var { form } = this.state;
        if (!Array.isArray(form[name])) return false;
        return form[name].includes(value)
    }

    validForm() {
        const { formErrors } = this.state;
        const { name, policyType, zone, generalPolicies, zonePolicies } = this.state.form;

        formErrors["name"] = [];

        var errorCount = 0;

        if (name.length < 3) {
            formErrors["name"].push("Nazwa powinna składać się co najmniej z 3 znaków");
            errorCount++;
        }

        if (name.length > 255) {
            formErrors["name"].push("Nazwa powinna składać się maksymalnie z 255 znaków");
            errorCount++;
        }

        if (!policyType || !AppConstants.SAFETICA_POLICY_TYPES[policyType.value]) {
            formErrors["policyType"].push("Typ aplikacji nie został wybrany");
            errorCount++;
        }

        if (policyType.value === AppConstants.SAFETICA_POLICY_TYPES.ZONED.name) {
            if (!zone || !zone.value) {
                formErrors["zone"].push("Strefa nie została wybrana");
                errorCount++;
            }

            if (!zonePolicies || zonePolicies.length <= 0) {
                formErrors["zonePolicies"].push("Co najmniej jedno pole musi zostać wybrane");
                errorCount++;
            }
        }

        if (policyType.value === AppConstants.SAFETICA_POLICY_TYPES.GENERAL.name) {
            if (!generalPolicies || generalPolicies.length <= 0) {
                formErrors["generalPolicies"].push("Co najmniej jedno pole musi zostać wybrane");
                errorCount++;
            }
        }

        this.setState({ formErrors: formErrors });
        return !errorCount;
    }

    buildForm() {
        const { name, policyType, zone, zonePolicies, generalPolicies } = this.state.form;

        var partFormDependentOnPolicyType = {}
        if (policyType.value === AppConstants.SAFETICA_POLICY_TYPES.ZONED.name) {
            partFormDependentOnPolicyType = {
                zone: zone.value,
                zonePolicies: zonePolicies
            }
        }

        if (policyType.value === AppConstants.SAFETICA_POLICY_TYPES.GENERAL.name) {
            partFormDependentOnPolicyType = {
                generalPolicies: generalPolicies
            }
        }

        return {
            name: name,
            policyType: policyType.value,
            ...partFormDependentOnPolicyType
        }
    }

    submitForm(onSuccess) {
        const { formErrors } = this.state;
        const { uuid } = this.props;

        if (this.validForm()) {
            var apiPromise;
            if (uuid && uuid.length) {
                apiPromise = API.patch(AppConstants.PC_SAFETICA_POLICY_CONFIGURATION_URL + "/" + uuid, {
                    form: this.buildForm()
                })
            } else {
                apiPromise = API.post(AppConstants.PC_SAFETICA_POLICY_CONFIGURATION_URL, {
                    form: this.buildForm()
                });
            }

            apiPromise.then((result) => {
                if (result.status === 201) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Polityka została dodana.");
                }

                if (result.status === 200) {
                    this.props.toggle();
                    onSuccess();
                    socToast.success("Powodzenie", "Polityka została zedytowana.");
                }
            }).catch((error) => {
                var response = error.response;
                if (response && response.status === 400) {
                    socToast.error("Niepoprawnie wypełniony formularz", "Popraw lub uzupełnij wymagane pola.");
                    response.data.errors.forEach(error => {
                        formErrors[error.field.split(".").pop()].push(error.defaultMessage);
                    })
                    this.setState({ formErrors: formErrors });
                } else {
                    socToast.error("Problem z połączeniem", "Spróbuj ponownie za chwilę.");
                }
            });
        }
    }

    findHintByValue(inputName, value) {
        const { formHints } = this.state;
        const foundOption = formHints[inputName].map(hint => {
            if (hint.options != null) {
                return hint.options.find(option => option.value === value)
            } else {
                return hint.value === value ? hint : undefined
            }
        }).find(option => !(option == null))

        if (foundOption == null) return "";
        return foundOption;
    }

    fetchPolicyForm(uuid) {
        API.get(AppConstants.PC_SAFETICA_POLICY_CONFIGURATION_URL + "/" + uuid)
            .then(result => {
                this.setState({
                    form: {
                        ...this.state.form,
                        ...result.data,
                        policyType: this.findHintByValue('policyType', result.data.policyType),
                        zone: result.data.zone ? this.findHintByValue('zone', result.data.zone) : '',
                    }
                })
            })
    }

    fetchAvailableOptions = (callback) => {
        this.setState({ isOptionsLoading: true })

        API.get(AppConstants.PC_SAFETICA_POLICY_CONFIGURATION_URL + "/form/options/create").then((result) => {
            if (result.status === 200) {
                this.setState({
                    formHints: {
                        ...this.state.formHints,
                        ...result.data,
                    },
                    isOptionsLoading: false,
                }, callback)
            }
        });
    }

    GeneralPolicyTypeForm() {
        var hasGeneralPoliciesError = Boolean(this.state.formErrors.generalPolicies.length);

        return (
            <React.Fragment>
                <label>*Dane</label>
                {this.state.formHints.generalPolicyData.map((option, idx) => (
                    <FormCheckbox
                        name="generalPolicies"
                        key={idx}
                        checked={this.checkboxArrayIsChecked("generalPolicies", option.value)}
                        onChange={() => this.checkboxArrayChangeHandler("generalPolicies", option.value)}
                        invalid={hasGeneralPoliciesError}>
                        {option.label}
                    </FormCheckbox>
                ))}
                {hasGeneralPoliciesError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.generalPolicies)}</ul>}
            </React.Fragment>
        )
    }

    ZonePolicyTypeForm() {
        const { zone } = this.state.form;

        var hasZoneError = Boolean(this.state.formErrors.zone.length);
        var hasZonePoliciesError = Boolean(this.state.formErrors.zonePolicies.length);

        return (
            <React.Fragment>
                <label>*Strefa</label>
                <AsyncSelect
                    {...defaultSelectProps}
                    name="zone"
                    value={zone}
                    onChange={this.selectChangeHandler}
                    defaultOptions={this.state.formHints.zone}
                    className={hasZoneError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                { hasZoneError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.zone)}</ul>}

                <label>*Dane</label>
                {this.state.formHints.zonePolicyData.map((option, idx) => (
                    <FormCheckbox
                        name="zonePolicies"
                        key={idx}
                        checked={this.checkboxArrayIsChecked("zonePolicies", option.value)}
                        onChange={() => this.checkboxArrayChangeHandler("zonePolicies", option.value)}
                        invalid={hasZonePoliciesError}>
                        {option.label}
                    </FormCheckbox>
                ))}
                {hasZonePoliciesError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.zonePolicies)}</ul>}
            </React.Fragment>
        )
    }

    render() {
        const { name, policyType } = this.state.form;

        var hasNameError = Boolean(this.state.formErrors.name.length);
        var hasPolicyTypeError = Boolean(this.state.formErrors.policyType.length);

        return (
            <Row>
                <Col sm="12" md="6">
                    <label>*Nazwa</label>
                    <FormInput
                        name="name"
                        value={name || ''}
                        onChange={this.inputChangeHandler}
                        invalid={hasNameError}
                        className={hasNameError ? "mb-0" : "mb-2"} />
                    {hasNameError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.name)}</ul>}

                    <label>*Typ aplikacji</label>
                    <AsyncSelect
                        {...defaultSelectProps}
                        name="policyType"
                        value={policyType}
                        onChange={this.selectChangeHandler}
                        defaultOptions={this.state.formHints.policyType}
                        className={hasPolicyTypeError ? "react-select-container has-error mb-0" : "react-select-container mb-2"} />
                    {hasPolicyTypeError && <ul className="mb-2 form-error-message">{renderError(this.state.formErrors.policyType)}</ul>}
                </Col>
                <Col sm="12" md="6">
                    {policyType && policyType.value === AppConstants.SAFETICA_POLICY_TYPES.GENERAL.name && <this.GeneralPolicyTypeForm />}
                    {policyType && policyType.value === AppConstants.SAFETICA_POLICY_TYPES.ZONED.name && <this.ZonePolicyTypeForm />}
                </Col>
            </Row>
        );
    }

}

export default ConfirmPolicyForm;
