import React from "react";
import { ConfirmModal } from "../../common/Modal";
import MoveCustomerDevicesForm from './../forms/MoveCustomerDevicesForm';

class MoveCustomerDevicesModal extends React.Component {

    constructor(props) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }

    setRef(input) {
        this.childRef = input;
    }

    render() {
        const {
            open,
            toggle,
            onSuccess,
            uuid
        } = this.props;

        return (
            <ConfirmModal
                title="Wykryto przypisane urządzenia"
                open={open}
                toggle={toggle}
                submitAction={() => { this.childRef.submitForm(onSuccess); }}
                positiveButtonText="Przenieś i usuń użytkownika"
                negativeButtonText="Anuluj" >

                <p>Aby usunąć użytkownika należy przenieść przypisane do niego urządzenia
                    innej osobie lub usunąć wszystkie ręcznie.</p>

                <MoveCustomerDevicesForm toggle={toggle} uuid={uuid} ref={this.setRef} />
            </ConfirmModal>
        );
    }

}

export default MoveCustomerDevicesModal;