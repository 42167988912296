import React from 'react';
import { Button, Card, CardBody, CardHeader } from 'shards-react';
import DeviceScannerModal from './modals/DeviceScannerModal';
import configuration, { PROPERTIES } from "../../config/Configuration";
import * as SocToast from '../../utils/SocToast'

class DeviceScanner extends React.Component {

    constructor(props) {
        super(props);
        this.checkIfEnabled();

        this.state = {
            componentEnabled: false,
            isScanDefiviceModalShow: false,
        };

        this.toggleScanDeviceModal = this.toggleScanDeviceModal.bind(this);
    }

    toggleScanDeviceModal() {
        this.setState({
            isScanDefiviceModalShow: !this.state.isScanDefiviceModalShow
        })
    }

    isAdminAtLeastOrAdmin() {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            var userRole = user.role;
            if (userRole === "SOCADMIN" || userRole === "ADMIN") {
                return true;
            }
        }

        return false;
    }

    checkIfEnabled() {
        configuration.getProperty(PROPERTIES.DEVICE_SCANNING_ENABLED)
            .then(property => {
                var isTrueSet = (property ? property.value === 'true' : false);
                if (isTrueSet) {
                    isTrueSet = this.isAdminAtLeastOrAdmin();
                    this.setState({
                        componentEnabled: isTrueSet,
                    })
                }
            })
    }

    onCorrectlyAddedTicket() {
        SocToast.success("Formularz skanowania urządzenia został wysłany pomyślnie", "Nie długo powinieneś otrzymać wiadomość email zawierającą status skanowania.");
    }

    render() {
        const { componentEnabled, isScanDefiviceModalShow } = this.state;
        const { deviceUuid, deviceType } = this.props;
//        if (componentEnabled) {
            return (
                <>
                    <Card className="mb-3">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Skanowanie urządzenia</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="p-0 py-1 text-center">
                            <Button onClick={this.toggleScanDeviceModal} outline theme="info" >Wyślij wniosek</Button>
                        </CardBody>
                    </Card>

                    <DeviceScannerModal open={isScanDefiviceModalShow} toggle={this.toggleScanDeviceModal} onSuccess={this.onCorrectlyAddedTicket}
                        deviceUuid={deviceUuid} deviceType={deviceType} />
                </>
            );
//        }

//        return null;
    }

}

export default DeviceScanner;