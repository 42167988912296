import React from 'react';
import { FormInput, Row, Col } from 'shards-react';
import CreatableSelect from 'react-select/creatable';
import * as Constants from '../../../constants'
import API from '../../../api/AxiosConfiguration'

const components = {
    DropdownIndicator: null,
};

class DeviceScannerForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                inputKeywords: '',
                keywords: [],
                regexp: '',
            },
            formErrors: {}
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.creatableSelectInputChange = this.creatableSelectInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    inputChangeHandler = (event) => {
        const { form, formErrors } = this.state;
        form[event.target.name] = event.target.value;
        formErrors[event.target.name] = [];
        this.setState({ form: form });
    }

    creatableSelectInputChange = (inputValue) => {
        const { form } = this.state;
        this.setState({
            form: {
                ...form,
                inputKeywords: inputValue
            }
        });
    };

    handleKeyDown = (event) => {
        const { form } = this.state;
        const { inputKeywords, keywords } = this.state.form;

        if (!inputKeywords) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                this.setState({
                    form: {
                        ...form,
                        inputKeywords: '',
                        keywords: [...keywords, { label: inputKeywords, value: inputKeywords }],
                    }
                });

                event.preventDefault();
                break;
            default: { }
        }
    };

    handleChange = (value, actionMeta) => {
        const { form } = this.state;
        this.setState({
            form: {
                ...form,
                keywords: value,
            }
        });
    };

    validForm() {
        return true;
    }

    submitForm(onSuccess) {
        const { keywords, regexp } = this.state.form;
        const { deviceUuid, deviceType, toggle } = this.props;

        if (this.validForm()) {
            API.put(Constants.DEVICES_URL + "/" + deviceType + '/scan', {
                form: {
                    deviceUuid: deviceUuid,
                    keywords: keywords.map(keyword => keyword.value),
                    regexp: regexp,
                }
            }).then((result) => {
                if (result && result.status === 201) {
                    onSuccess();
                    toggle();
                }
            })
        }
    }

    render() {
        const { inputKeywords, keywords, regexp } = this.state.form;

        return (
            <Row>
                <Col sm={12}>
                    <label>Słowa kluczowe</label>
                    <CreatableSelect
                        components={components}
                        inputValue={inputKeywords}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={this.handleChange}
                        onInputChange={this.creatableSelectInputChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder="Wpisz słowo kluczowe i zatwierdź enterem"
                        value={keywords}
                        className='react-select-container mb-2'
                        classNamePrefix="react-select" />

                    <label>Regexp</label>
                    <FormInput
                        name="regexp"
                        value={regexp || ''}
                        onChange={this.inputChangeHandler}
                        className="mb-2" />
                </Col>
            </Row>
        );
    }

}

export default DeviceScannerForm;