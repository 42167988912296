import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallCard from "../components/common/SmallCard";
import DeviceMenu from "../components/network-device/NetworkDeviceMenu";
import DeviceRules from "../components/network-device/NetworkDeviceRules";
import DeviceSearcher from "../components/network-device/NetworkDeviceSearcher";
import { Store } from "../flux";

import API from "../api/AxiosConfiguration";
import * as Constants from '../constants';

class NetworkDevice extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props)

    this.state = {
      isMobileView: false,
      devicesCondition: {
        activeDevices: 0,
        allDevices: 0
      },
      searcherOptions: {
        group: [],
        type: [],
        manufacturer: [],
        model: []
      },
      isDeviceSearcherLoading: false
    }

    this.onWindowResizeFluxListener = this.onWindowResizeFluxListener.bind(this);

    this.updateDeviceData = this.updateDeviceData.bind(this);
    this.fetchCountOfDevices = this.fetchCountOfDevices.bind(this);
    this.fetchSearchFormOptions = this.fetchSearchFormOptions.bind(this);

    this.redirectToEventHistory = this.redirectToEventHistory.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateDeviceData();
  }

  componentWillMount() {
    Store.addChangeListener(this.onWindowResizeFluxListener);
  }

  componentWillUnmount() {
    this._isMounted = false;
    Store.removeChangeListener(this.onWindowResizeFluxListener);
  }

  redirectToEventHistory() {
    const currentDate = new Date();

    var eventStartRangeDate = new Date().setHours(currentDate.getHours() - 24);
    var eventEndRangeDate = currentDate.getTime();

    this.props.history.push({
      pathname: "/network-event-history",
      state: {
        eventStartRangeDate: eventStartRangeDate,
        eventEndRangeDate: eventEndRangeDate,
      }
    });
  }

  onWindowResizeFluxListener() {
    if (this._isMounted)
      this.setState({
        ...this.state,
        isMobileView: Store.isMobileView()
      })
  }

  updateDeviceData() {
    this.fetchCountOfDevices();
    this.fetchSearchFormOptions();
  }

  fetchCountOfDevices() {
    API.get(Constants.NETWORK_DEVICE_URL + "/stats")
      .then(result => {
        if (result && result.status === 200)
          if (this._isMounted)
            this.setState({
              devicesCondition: result.data
            })
      })
  }

  fetchSearchFormOptions() {
    this.setState({ isDeviceSearcherLoading: true }, () => {
      API.get(Constants.NETWORK_DEVICE_URL + "/form/options/search")
        .then(result => {
          if (result && result.status === 200)
            if (this._isMounted)
              this.setState({
                searcherOptions: result.data,
                isDeviceSearcherLoading: false
              })
        })
    })
  }

  render() {
    const { isDeviceSearcherLoading, isMobileView, devicesCondition, searcherOptions } = this.state;
    const { siteTitle } = this.props;

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={siteTitle} subtitle="Dashboard" className="text-sm-left mb-3" />
        </Row>

        <Row>
          <Col md="6" lg="4" className="mt-2">
            <SmallCard label="Aktywne urządzenia">{devicesCondition.activeDevices}</SmallCard>
          </Col>
          <Col md="6" lg="4" className="mt-2">
            <SmallCard label="Zarejestrowane urządzenia">{devicesCondition.allDevices}</SmallCard>
          </Col>
          <Col sm={{ size: 12, offset: 0 }}
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 0 }}
            className="mt-2">
            <SmallCard label="Naruszenia reguł z ostatnich 24h">
              <Button onClick={this.redirectToEventHistory} outline theme="info">
                Wygeneruj raport
              </Button>
            </SmallCard>
          </Col>
        </Row>

        <DeviceSearcher
          history={this.props.history}
          filterOptions={searcherOptions}
          isMobileView={isMobileView}
          isLoading={isDeviceSearcherLoading} />

        <DeviceMenu
          history={this.props.history}
          isMobileView={isMobileView}
          onDeviceUpdate={this.updateDeviceData} />

        <DeviceRules history={this.props.history} />
      </Container>
    )
  }

}

export default NetworkDevice;
