import React from "react";
import { ConfirmModal } from "../../common/Modal";
import * as Constants from '../../../constants';
import API from "../../../api/AxiosConfiguration";

class DeleteProcedureModal extends React.Component {

    constructor(props) {
        super(props);

        this.deleteProcedure = this.deleteProcedure.bind(this);
    }

    deleteProcedure(toggleFunction) {
        const {
            onSuccess,
            uuid,
        } = this.props;

        API.delete(Constants.SAFETY_PROCEDURES_URL + "/" + uuid)
            .then(result => {
                if (result.status === 200) {
                    onSuccess();
                    toggleFunction();
                }
            })
    }

    render() {
        const {
            open,
            toggle,
        } = this.props;

        return (
            <ConfirmModal
                title="Jesteś pewien, że chcesz usunąć wybraną procedure?"
                open={open}
                toggle={toggle}
                submitAction={this.deleteProcedure}
                positiveButtonText="Potwierdź"
                negativeButtonText="Anuluj">
                Spowoduje to usunięcie wszystkich danych o procedurze i może mieć wpływ na przypisane reguły urządzeń.
                Jeśli jesteś zdecydowany, kliknij "Potwierdź".
            </ConfirmModal>
        )
    }
}

export default DeleteProcedureModal;
