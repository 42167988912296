import React, { useState, useEffect } from 'react';
import { Card, CardImg, CardBody, CardTitle } from 'shards-react';
import API from '../../api/AxiosConfiguration';
import * as AppConstants from '../../constants';
import defaultImage from '../../images/thumbnail_image.svg';
import Loader from 'react-loader-spinner';

const Thumbnail = ({ children, onClick, cameraUuid, locationUuid }) => {
    const [image, setImage] = useState(defaultImage);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        fetchImage();
    }, []);

    const fetchImage = () => {
        setLoading(true);
        var axiosConfig = {
            params: {
                uuid: locationUuid,
            },
            responseType: 'arraybuffer'
        }

        API.get(AppConstants.CONTROL_PANEL_CAMERAS_URL + "/" + cameraUuid + "/thumbnail", axiosConfig)
            .then(res => Buffer.from(res.data, 'binary').toString('base64'))
            .then(cameraThumbnail => {
                if (cameraThumbnail)
                    setImage('data:image/jpeg;charset=utf-8;base64, ' + cameraThumbnail)
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            });
    }

    const loadingSpinner = () => (
        <div style={{ position: 'absolute', bottom: '5%', left: '50%', transform: 'translateX(-50%)', zIndex: "1" }}>
            <Loader
                type="ThreeDots"
                color="#007bff"
                height={27}
                width={27}
                visible={isLoading} />
        </div>
    )

    return (
        <div className="thumbnail-container" onClick={onClick}>
            <Card style={{ maxWidth: "300px" }}>
                <div className="d-flex flex-column position-relative">
                    {isLoading && loadingSpinner()}
                    <CardImg src={image} style={{ background: "#AAAAAA" }} />
                </div>
                <CardBody className="py-0 text-center">
                    <CardTitle className="my-1">{children}</CardTitle>
                </CardBody>
            </Card>
        </div>
    )
}

export default Thumbnail;