import { Roles } from "../constants";
import { Privileges } from './../constants/index';

export default function () {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      group: "dashboard",
      htmlBefore: '<i class="fas fa-chart-pie"></i>',
      htmlAfter: ""
    },
    {
      title: "Bezpieczeństwo sprzętowe",
      to: "/pc-device-dl",
      group: "pcDevices",
      htmlBefore: '<i class="fas fa-desktop"></i>',
      htmlAfter: "",
      privileges: [Privileges.DEVICE_SECURITY_DEVICELOCK]
    },
    {
      title: "Bezpieczeństwo sprzętowe",
      to: "/pc-device-s",
      group: "pcDevices",
      htmlBefore: '<i class="fas fa-desktop"></i>',
      htmlAfter: "",
      privileges: [Privileges.DEVICE_SECURITY_SAFETICA]
    },
    {
      title: "Bezpieczeństwo sieciowe",
      to: "/network-device",
      group: "networkDevices",
      htmlBefore: '<i class="fas fa-network-wired"></i>',
      htmlAfter: "",
      privileges: [Privileges.NETWORK_SECURITY]
    },
    {
      title: "Bezpieczeństwo fizyczne",
      to: "/security-control-panel",
      group: "securityControlPanel",
      htmlBefore: '<i class="far fa-bell"></i>',
      htmlAfter: "",
      privileges: [Privileges.PHYSICAL_SECURITY]
    },
    {
      title: "Procedury Bezpieczeństwa",
      to: "/safety-procedures",
      group: "safetyProcedures",
      htmlBefore: '<i class="fas fa-project-diagram"></i>',
      htmlAfter: "",
    },
    {
      title: "Zarządzanie Powiadomieniami",
      to: "/notification-management",
      group: "notificationManagement",
      htmlBefore: '<i class="fas fa-comment"></i>',
      htmlAfter: ""
    },
    {
      title: "Firmy",
      to: "/companies",
      group: "companies",
      htmlBefore: '<i class="fas fa-city"></i>',
      htmlAfter: "",
      authorities: [Roles.SOCADMIN]
    },
    {
      title: "Użytkownicy",
      to: "/customers",
      group: "customers",
      htmlBefore: '<i class="far fa-id-card"></i>',
      htmlAfter: "",
      authorities: [Roles.SOCADMIN, Roles.ADMIN]
    }, {
      title: "Ustawienia Firmy",
      to: "/company/settings",
      group: "companySettings",
      htmlBefore: '<i class="fas fa-cogs"></i>',
      htmlAfter: "",
      authorities: [Roles.SOCADMIN, Roles.ADMIN]
    },
  ];
}
